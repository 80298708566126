import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import "./utils/i18n";
import App from "./App";
import { GlobalStyle } from "./globalStyles";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import { Provider, teamsTheme } from "@fluentui/react-northstar";
import { useAuthDetailStore } from "./stores/useAuthStore";
import AppPreOnboarding from "./AppPreOnboarding";
import { Toaster } from "sonner";
import Toast from "./components/Alerts";
import { useToastGlobal } from "./stores/useToastGlobal";
import TimeClock from "./features/timeclock";

const root = ReactDOM.createRoot(document.getElementById("root"));

export const queryClient = new QueryClient();

const detail = useAuthDetailStore?.getState()?.detail;

root.render(
    <QueryClientProvider client={queryClient}>
        {/* global style */}
        <GlobalStyle />
        {/* global toaster */}
        <Toaster
            position="bottom-left"
            toastOptions={{
                style: {
                    padding: "10px",
                },
            }}
        />
        <FluentProvider theme={teamsLightTheme}>
            <Provider theme={teamsTheme}>
                {/* APP */}
                {detail?.is_in_preonboarding ? (
                    <AppPreOnboarding />
                ) : (
                    <>
                        <App />
                        <TimeClock />
                    </>
                )}
            </Provider>
        </FluentProvider>
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
);

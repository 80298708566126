import React from "react";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import Absence from "../../../../assets/illustrations/time_reporting/Absence.svg";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";

const Leave = ({leaveTypeName}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    return (
        <Container column fill vAlign="center" hAlign="center" gap="gap.medium">
            <Image src={Absence} />
            <Content content={t("home.card.leave")} />
            <SubTitle content={leaveTypeName} />
        </Container>
    );
};

export default Leave;

const SubTitle = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #424242;
`;

const Content = styled(Text)`
    color: #616161;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    width: 91px;
    text-align: center;
`;

const Container = styled(Flex)`
    background-color: #fafafa;
    width: 100%;
    height: 100%;
`;

import { Flex, Text } from "@fluentui/react-northstar";
import styled, { css } from "styled-components/macro";

export const EventText = styled(Text)`
    color: inherit;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

export const EventWrapper = styled(Flex)`
    z-index: 1;

    ${({ $bg, $color, $width, $cursor }) => {
        return css`
            border-left: 4px solid ${$color};
            background: ${$bg};
            width: ${$width + "px"};
            cursor: ${$cursor ? "pointer" : "auto"};
            ${EventText} {
                color: ${$color};
            }
        `;
    }}

    border-radius: 4px;

    height: 18px;
    padding-left: 13px;
`;

import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components/macro";
import { DEVICE } from "../../../constants/responsive";

export const TaskTodoCard = ({ icon, main_text, badge_text }) => {
    return (
        <TaskCard vAlign="center">
            <IconFlex>{icon}</IconFlex>
            <CardContent vAlign="center">
                <MainText content={main_text} />
            </CardContent>
            <Badge
                vAlign="center"
                hAlign="center"
                styles={{
                    backgroundColor:
                        badge_text === 0 ? "#E8EBFA" : "rgb(255,170,68)",
                }}
            >
                <Text
                    color={!badge_text ? "var(--color-primary)" : "white"}
                    weight="bold"
                    content={badge_text}
                />
            </Badge>
        </TaskCard>
    );
};
const Badge = styled(Flex)`
    margin-inline: 10px;
    padding: 3px 5px;
    gap: 10px;
    width: 26px;
    height: 26px;

    border-radius: 16px;
`;
const TaskCard = styled(Flex)`
    padding: 0px;
    gap: 16px;
    height: 64px;
    background: #ffffff;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    @media ${DEVICE.mobileS} {
        width: 100%;
        ${Badge} {
            margin-left: auto;
        }
    }
    @media ${DEVICE.laptop} {
        width: 406px;
    }
`;
const IconFlex = styled(Flex)`
    flex-direction: row;
    align-items: center;
    padding: 0px 12px;

    width: 48px;
    height: 64px;

    background: #edf0fd;
    border-radius: 8px 0px 0px 8px;
`;
const CardContent = styled(Flex)`
    align-items: center;
`;
const MainText = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    height: 20px;
    @media ${DEVICE.mobileS} {
        width: 100%;
        display: flex;
        flex: 1;
    }
    @media ${DEVICE.laptop} {
        width: 260px;
    }
`;

import React from "react";
import { Button, Flex, Image, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BASE_URL_ILLUSTRATIONS } from "../../../constants";
import { useClickGetStarted } from "../stores/useOnboarding";
import { useAuthDetailStore } from "../../../stores/useAuthStore";

const ProcessWelcome = () => {
    const { t } = useTranslation("onboarding");
    const store_set_get_clicked = useClickGetStarted();
    const { detail } = useAuthDetailStore();

    return (
        <FlexContainer column vAlign="center" hAlign="center" gap="gap.large">
            <Image src={`${BASE_URL_ILLUSTRATIONS}/Onboarding.svg`} />
            <Flex column hAlign="center" gap="gap.small">
                <Title>{t("title", { name: detail.first_name })}</Title>
                <Description align="center">
                    {t("description", {
                        company: detail?.company?.name,
                    })}
                </Description>
            </Flex>
            <ButtonGo
                content={t("buttons.get_started")}
                primary
                onClick={() => store_set_get_clicked(true)}
            />
        </FlexContainer>
    );
};

export default ProcessWelcome;

const ButtonGo = styled(Button)`
    min-width: 280px;
`;

const Description = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #424242;
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #242424;
`;

const FlexContainer = styled(Flex)`
    max-width: 536px;
    margin: auto;
`;

import { Button, Checkbox, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useAuthDetailStore } from "../../../stores/useAuthStore";
import { _api_set_cgu_as_read } from "../api";
import {
    useOnboarding,
    useSetGotoClicked,
    useSetWelcomeScreenShowToFalse,
} from "../stores/useOnboarding";
import RenderViewPdf from "../../../utils/PdfReader";

const ProcessCGU = () => {
    const data = useOnboarding((state) => state.welcome_screen_from_back.data);
    const current_user = useAuthDetailStore((state) => state.detail);
    const store_set_welcome_to_false = useSetWelcomeScreenShowToFalse();
    const setGotoClicked = useSetGotoClicked();
    // const reset = useReset();
    const queryClient = useQueryClient();
    const [checkedCGU, setCheckedCGU] = useState(false);

    const { mutate, isLoading } = useMutation(() => _api_set_cgu_as_read());

    const handleClick = (e) => {
        e.preventDefault();
        mutate(
            {},
            {
                onSuccess: () => {
                    // clear all onboarding store
                    store_set_welcome_to_false();
                    queryClient.refetchQueries("welcome_screen");
                    setGotoClicked();
                    // reset();
                },
            }
        );
    };

    return (
        <Flex column gap="gap.large">
            <Flex column hAlign="center" gap="gap.medium">
                <Title>Conditions générales</Title>
                <Description align="center">
                    Veuillez prendre le temps de lire les conditions générales
                    de l’entreprise et les accepter. En cas de questions
                    n’hésitez pas à les poser à votre référent:
                </Description>
            </Flex>
            <Flex>
                <RenderViewPdf url={data?.cgu_url} />
                {/* <iframe
                    id="CGU"
                    title="CGU"
                    width="100%"
                    height="500px"
                    src={data?.cgu_url}
                ></iframe> */}
            </Flex>
            <Flex hAlign="center">
                <Checkbox
                    label={`J’ai lu et j’accepte les conditions générales de l’entreprise ${current_user?.company?.name}`}
                    onChange={(e, { checked }) => setCheckedCGU(checked)}
                />
            </Flex>
            <Flex hAlign="center">
                <Button
                    disabled={!checkedCGU}
                    content="Accéder au dashboard"
                    primary
                    loading={isLoading}
                    onClick={handleClick}
                />
            </Flex>
        </Flex>
    );
};

export default ProcessCGU;

const Description = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #242424;
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
`;

import React from "react";
import { RightSectionDetailDocumentAndComments } from "../ExpensesDetail";
import EvolutionComment from "../../pages/ExpensesDetailReport/EvolutionComment";
import EmbedDocument from "../../pages/ExpensesManagerMyTeams/EmbedDocument";
import { Title } from "./index.style";
import { Button, CloseIcon, Flex } from "@fluentui/react-northstar";
import PdfViewerComponent from "../../../../components/PdfViewerComponent";
import styled from "styled-components";

import { AppsListDetail20Filled } from "@fluentui/react-icons";

/**
 * @typedef {Object} PropsType
 * @property {{ description : string, date: string, type: string, user_avatar : string, user_fullname : string  }} comments - Commentaires
 * @property {string} embed - src Link to show embed
 * @property {Function} onClose - trigger when clicked on close icon
 *
 * @param {PropsType} props
 * @returns
 */
const EmbedAndCommentsCard = ({ comments, embed, onClose = () => null }) => {
    return (
        <RightSectionDetailDocumentAndComments column>
            {/* HEADER */}
            <HeaderAttachementSection vAlign="center">
                <Flex vAlign="center" fill gap="gap.small">
                    <AppsListDetail20Filled />
                    <Title>Aperçu de la dépense</Title>
                </Flex>
                <Button
                    styles={{
                        cursor: "pointer",
                        // paddingRight: "10px",
                    }}
                    onClick={onClose}
                    size="small"
                    icon={<CloseIcon size="smaller" />}
                    iconOnly
                    title="Close"
                />
            </HeaderAttachementSection>
            {comments !== undefined &&
                comments !== "" &&
                comments?.length !== 0 && (
                    <EvolutionComment comments={comments} />
                )}

            <Flex>
                {embed !== "" && embed !== undefined && (
                    <EmbedDocument content={embed} onClose={onClose} />
                    // <PdfViewerComponent document={embed} />
                )}
            </Flex>
        </RightSectionDetailDocumentAndComments>
    );
};

export default EmbedAndCommentsCard;

const HeaderAttachementSection = styled(Flex)`
    padding: 12px 20px;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
    background: var(--Light-theme-Rest-Background-Brand-Background-1, #e8ebfa);
`;

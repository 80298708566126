import { Flex, Skeleton } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const SkeletonAccordion = () => {
    return (
        <Cell
            vAlign="center"
            space="between"
            styles={{ paddingInline: "25px" }}
        >
            <Skeleton.Line width="62px" height="20px" />
            <Flex vAlign="center" styles={{ gap: "5px" }}>
                <Skeleton.Line width="16px" height="16px" />
                <Skeleton.Avatar size="smallest" />
                <Skeleton.Line width="16px" height="16px" />
            </Flex>
        </Cell>
    );
};
const SkeletonUser = () => {
    return (
        <Cell vAlign="center" hAlign="center" gap="gap.smaller">
            <Skeleton.Avatar size="smallest" />
            <Skeleton.Line width="100px" height="20px" />
        </Cell>
    );
};

const SkeletonUsers = () => {
    return (
        <Flex column gap="gap.medium">
            <Skeleton animation="pulse">
                <SkeletonAccordion />
                <SkeletonUser />
                <SkeletonUser />
                <SkeletonUser />
                <Divider />
                <SkeletonAccordion />
                <SkeletonUser />
                <SkeletonUser />
                <SkeletonUser />
                <Divider />
                <SkeletonAccordion />
                <SkeletonUser />
                <SkeletonUser />
                <SkeletonUser />
                <Divider />
                <SkeletonAccordion />
                <SkeletonUser />
                <SkeletonUser />
                <SkeletonUser />
            </Skeleton>
        </Flex>
    );
};

export default SkeletonUsers;

const Divider = styled(Flex)`
    height: 24px;
`;

const Cell = styled(Flex)`
    width: 100%;
    height: 35px;
`;

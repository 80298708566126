import React, { useState } from "react";

import {
    Button,
    CloseIcon,
    Dialog,
    DownloadIcon,
    FilterIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import FilterTimeReporting from "../FilterTimeReporting";
import styled from "styled-components";
import SliderWeek from "../../calendars/SliderWeek";

const Header = ({
    week_start,
    week_end,
    iso_week,
    onDecrementWeek,
    onIncrementWeek,
}) => {
    const [openFilter, SetOpenFilter] = useState(false);

    const FilterSubmit = (e) => {
        return console.log(e);
    };

    return (
        <Container space="between">
            <SliderWeek
                end={week_end}
                iso_week={iso_week}
                onDecrement={onDecrementWeek}
                onIncrement={onIncrementWeek}
                start={week_start}
            />
            <Flex gap="gap.large">
                <Dialog
                    open={openFilter}
                    onCancel={() => SetOpenFilter(false)}
                    // onConfirm={() => SetOpenFilter(false)}
                    cancelButton="Cancel"
                    confirmButton="Confirm"
                    header="Filtrer dans le Tableau"
                    content={<FilterTimeReporting />}
                    headerAction={{
                        icon: <CloseIcon />,
                        title: "Fermer",
                        onClick: () => SetOpenFilter(false),
                    }}
                    trigger={
                        <Button
                            onClick={() => SetOpenFilter(true)}
                            iconPosition="after"
                            icon={<FilterIcon />}
                            content="Filtrer dans le tableau"
                        />
                    }
                    footer={
                        <Flex space="between">
                            <Button
                                onClick={() => {
                                    SetOpenFilter(false);
                                }}
                                content="Annuler"
                            />
                            <Flex gap="gap.medium">
                                <Button
                                    text
                                    content={
                                        <ButtonLink content="Réinitialiser les filtres" />
                                    }
                                />
                                <Button
                                    onClick={FilterSubmit}
                                    primary
                                    content="Appliquer les filtres"
                                />
                            </Flex>
                        </Flex>
                    }
                />
            </Flex>
        </Container>
    );
};

export default Header;

const Container = styled(Flex)``;

const ButtonLink = styled(Text)`
    text-decoration: underline;
    color: #5b5fc7;
`;

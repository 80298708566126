import { Text } from "@fluentui/react-components";
import { Avatar, ChatIcon, EmailIcon, Flex } from "@fluentui/react-northstar";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";

const Rows = ({ data }) => {
    const navigate = useNavigate();
    return (
        <FlexContainer vAlign="center">
            <FlexColumn
                vAlign="center"
                gap="gap.small"
                onClick={() => navigate(`/employee/${data.id}`)}
                className="hoverable"
                fill
            >
                <Flex.Item>
                    <Avatar
                        size="smallest"
                        image={data.avatar}
                        name={data.name}
                    />
                </Flex.Item>
                <Flex.Item>
                    <TextName truncate block>
                        {data.name}
                    </TextName>
                </Flex.Item>
            </FlexColumn>
            <FlexColumn fill vAlign="center" className="hoverable">
                <TextPoste truncate>{data.poste}</TextPoste>
            </FlexColumn>
            <FlexColumn fill hideonmobile={"true"} vAlign="center">
                <TextPoste truncate>{data.manager}</TextPoste>
            </FlexColumn>
            <FlexColumn fill hideonmobile={"true"} vAlign="center">
                <TextPoste truncate>{data.agency || "/"}</TextPoste>
            </FlexColumn>
            <FlexColumn fill hideonmobile={"true"} vAlign="center">
                <TextPoste truncate>{data.phone_number}</TextPoste>
            </FlexColumn>
            <FlexColumn
                className="last"
                gap="gap.medium"
                hAlign="end"
                vAlign="center"
                fill
            >
                <Href href={`mailto:${data.email}`}>
                    <EmailIcon
                        size="medium"
                        outline
                        styles={{ color: "#5B5FC7" }}
                    />
                </Href>
                <Href href={`MSTeams:/l/chat/0/0?users=${data.email}`}>
                    <ChatIcon
                        size="medium"
                        outline
                        styles={{ color: "#5B5FC7" }}
                    />
                </Href>
            </FlexColumn>
        </FlexContainer>
    );
};

export default Rows;

const FlexColumn = styled(Flex)`
    &.last {
        max-width: 100px;
    }
    &.hoverable {
        cursor: pointer;
        width: clamp(200px, 100%, 100%);
        overflow: auto;
    }
    @media ${DEVICE.mobileS} {
        display: ${({ hideonmobile }) =>
            hideonmobile === "true" ? "none" : "inherit"};
    }
    @media ${DEVICE.mobileL} {
        display: ${({ hideonmobile }) =>
            hideonmobile === "true" ? "none" : "inherit"};
    }
    @media ${DEVICE.mobileM} {
        display: ${({ hideonmobile }) =>
            hideonmobile === "true" ? "none" : "inherit"};
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

const Href = styled.a``;

const TextPoste = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    padding: 10px;

    @media ${DEVICE.mobileS} {
        max-width: 200;
        overflow: hidden;
        white-space: nowrap;
        height: 100%;
    }
`;
const TextName = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    @media ${DEVICE.mobileS} {
        max-width: 200;
        overflow: hidden;
        white-space: nowrap;
    }
    @media ${DEVICE.tablet} {
    }
`;
const FlexContainer = styled(Flex)`
    padding: 10px 8px;
    background-color: white;
`;

import { Flex, Skeleton } from "@fluentui/react-northstar";
import React from "react";

export default function Shimmering({ width, avatar }) {
    return (
        <Skeleton animation="pulse">
            <Flex gap="gap.small">
                {avatar && <Skeleton.Shape round width="32px" height="32px" />}
                <Skeleton.Line
                    variant="rectangular"
                    width={`${width}px`}
                    height="32px"
                />
            </Flex>
        </Skeleton>
    );
}

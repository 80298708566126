import { Flex } from "@fluentui/react-northstar";
import { ProfileSection } from "./ProfileSection";
import { AbsenceSection } from "../../home/components/AbsenceSection";
import { TimeCard } from "../../home/components/TimeCard";
import { useQueryClient } from "react-query";
import { MainSectionStyle } from "../styles/responsive";

export const MainSection = ({
    me,
    monthly_activity,
    balances,
    is_pre_onboarding,
    isLoading,
}) => {
    return (
        <MainSectionStyle space="between" vAlign="center" hAlign="center">
            <ProfileSection isLoading={isLoading} me={me} />
            <TimeCard
                monthly_activity={monthly_activity}
                is_pre_onboarding={is_pre_onboarding}
            />
            <AbsenceSection
                isLoading={isLoading}
                balances={balances}
                is_pre_onboarding={is_pre_onboarding}
            />
        </MainSectionStyle>
    );
};

import React, { useState } from "react";
import {
    Checkbox,
    Divider,
    Dropdown,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";

const ClientList = ["SNCF", "AUDEMARS PIGUET", "DAREST", "PATEKPHILIPPE"];

const CollaboratorList = [
    {
        header: "Robert Tolbert",
        image: "https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/RobertTolbert.jpg",
        content: "Software Engineer",
    },
    {
        header: "Wanda Howard",
        image: "https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/WandaHoward.jpg",
        content: "UX Designer 2",
    },
    {
        header: "Tim Deboer",
        image: "https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/TimDeboer.jpg",
        content: "Principal Software Engineering Manager",
    },
    {
        header: "Amanda Brady",
        image: "https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/AmandaBrady.jpg",
        content: "Technology Consultant",
    },
    {
        header: `Ashley McCarthy`,
        image: "https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/AshleyMcCarthy.jpg",
        content: "Software Engineer 2",
    },
];

const initialState = {
    Tous: false,
    Incomplete: false,
    A_valider: false,
    Envoyer: false,
    Refuser: false,
    Valider: false,
    Testous: "tete",
};
const FilterTimeReporting = () => {
    const [checked, setchecked] = useState({ initialState });

    console.log({ checked });

    const handleChange = (e) => {
        const updateState = {
            ...checked,
            // Tous: !Tous,
            // Incomplete: !Incomplete,
            // A_valider: !A_valider,
            // Envoyer: !Envoyer,
            // Refuser: !Refuser,
            // Valider: !Valider,
        };
        setchecked(updateState);
        return console.log("hehe", updateState);
    };

    return (
        <Flex column gap="gap.medium">
            <ContentHeader content="Status" />
            <Flex>
                <Checkbox
                    onChange={() => handleChange}
                    // onChange={() => setchecked(!checked.Tous)}
                    checked={checked.Tous}
                    label="Tous"
                />
                <Checkbox checked={checked.Incomplete} label="Incomplete" />
                <Checkbox checked={checked.A_valider} label="A valider" />
                <Checkbox checked={checked.Envoyer} label="Envoyé" />
                <Checkbox checked={checked.Valider} label="Validé" />
                <Checkbox checked={checked.Refuser} label="Refuser" />
            </Flex>
            <Divider />
            <ContentHeader content="Équipe ou collaborateur" />
            <Flex column style={{ gap: "4px" }}>
                <Text content="Collaborateur" />
                <Dropdown
                    items={CollaboratorList}
                    placeholder="Selectionner un collaborateur"
                />
            </Flex>
            <Divider />
            <ContentHeader content="Client" />
            <Dropdown items={ClientList} placeholder="Selectionner un client" />
        </Flex>
    );
};

export default FilterTimeReporting;

const ContentHeader = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

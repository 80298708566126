import React from "react";
import { Flex, Loader } from "@fluentui/react-northstar";

import Img from "../../../../../../assets/illustrations/employee_folder/contrat_qualif_job.svg";
import Card from "../../../Cards";
import PersonnalInformation from "../../../Cards/CardIdentity/PersonnalInformation";
import CivilStatus from "../../../Cards/CardIdentity/CivilStatus";
import SurroundingPeople from "../../../Cards/CardIdentity/SurroundingPeople";
import IdentityDocuments from "../../../Cards/CardIdentity/IdentityDocuments";
import QualificationAndJob from "../../../Cards/CardContract/QualificationAndJob";
import { useEmployeeFolderIdUser } from "../../../../store";
import { _api_get_contract, _api_get_identity } from "../../../../api";
import { QUERY_KEY } from "../../../../constants";
import { useQuery } from "react-query";
import TimeJobAndSalary from "../../../Cards/CardContract/TimeJobAndSalary";
import DatesContract from "../../../Cards/CardContract/DatesContract";
import ContractAndAvenant from "../../../Cards/CardContract/ContractAndAvenant";

const TabContract = () => {
    const id_employee = useEmployeeFolderIdUser();

    const {
        data: contract_data,
        isLoading: is_loading_contract,
        isFetching: isFetchingContractList,
    } = useQuery(
        [QUERY_KEY + "::contract"],
        () => _api_get_contract(id_employee),
        {
            refetchOnWindowFocus: false,
        }
    );

    if (is_loading_contract) {
        return <Loader content="chargement des informations" />;
    }

    return (
        <Flex gap="gap.medium" fill space="between">
            {/* qualification & Job */}
            <QualificationAndJob contract_data={contract_data} />
            {/* Temps de travail & salaire */}
            <TimeJobAndSalary contract_data={contract_data} />

            <Flex fill styles={{ maxWidth: "440px" }} column gap="gap.large">
                {/* BEGIN CONTRACT */}
                <DatesContract contract_data={contract_data} />

                <Card column>
                    {/* documents */}
                    <Card.Body
                        content={
                            <ContractAndAvenant
                                identity_documents={contract_data?.documents}
                                fetchingContractList={isFetchingContractList}
                            />
                        }
                    />
                </Card>
            </Flex>
        </Flex>
    );
};

export default TabContract;

import React, { useEffect } from "react";

import {
    BackArrow,
    InformationalText,
    Mail,
    MSButton,
    MSLogoStyle,
    WrapperClose,
    WrapperLeft,
    WrapperMail,
} from "./index.style";

import MSLogo from "../../assets/icons/microsoft.svg";

import { BiArrowBack } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { GoBack } from "./index.logic";
import { BASE_URL } from "../../constants";
import { Stack } from "@mui/material";

const ConnectMs = ({ email = "standard@gmail.com", code }) => {
    useEffect(() => {
        document.title = "Se connecter avec Microsoft";
    }, []);
    return (
        <Stack justifyContent={"center"} alignItems={"center"} gap={"15px"}>
            <BackArrow onClick={GoBack}>
                <BiArrowBack size={19} />
            </BackArrow>
            <WrapperMail>
                <WrapperLeft>
                    <FaUserCircle size={20} color={"grey"} />
                </WrapperLeft>
                <Mail>{email}</Mail>
                <WrapperClose>
                    <AiFillCloseCircle onClick={GoBack} />
                </WrapperClose>
            </WrapperMail>
            <InformationalText>
                Votre compte administrateur vous invite à associer votre compte
                à votre email organisationnel
            </InformationalText>
            <MSButton href={`${BASE_URL}/microsoft_sign_in?code=${code}`}>
                <MSLogoStyle src={MSLogo} alt="MS icon" />
                <span>Associer mon compte microsoft</span>
            </MSButton>
        </Stack>
    );
};

export default ConnectMs;

import { Flex, Skeleton, Text } from "@fluentui/react-northstar";
import React from "react";
import { AnnualRecoveryDesc, AnnualRecoveryText, Content } from "./index.style";
import { CalendarClock24Filled } from "@fluentui/react-icons";

const AnnualRecovery = ({ hours, loading }) => {
    return (
        <Content vAlign="center">
            <CalendarClock24Filled style={{ color: "#4F52B2" }} />
            <Flex column>
                {loading ? (
                    <Skeleton animation="pulse">
                        <Skeleton.Line width="120px" height="20px" />
                    </Skeleton>
                ) : (
                    <AnnualRecoveryText content={`${hours} h`} />
                )}
                <AnnualRecoveryDesc content="Récuperation annuelle" />
            </Flex>
        </Content>
    );
};

export default AnnualRecovery;

import React from "react";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

const FlagText = ({ flag = "", content = "" }) => {
    return (
        <Flex gap="gap.smaller" vAlign="center">
            <Image src={`https://flagcdn.com/${flag}.svg`} width="30px" />
            <Label content={content} />
        </Flex>
    );
};

export default FlagText;

const Label = styled(Text)`
    color: #242424;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

import React from "react";
import { Text } from "@fluentui/react-northstar";
import { Container, ContainerFluid } from "../../../common/styles";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_DOCUMENTS } from "../constants";

const Header = () => {
    const { t } = useTranslation(I18N_NS_DOCUMENTS);
    return (
        <ContainerFluid styles={{ marginTop: "24px" }}>
            <Container column gap="gap.small">
                <Title>{t("header.title")}</Title>
                <SubTitle>{t("header.sub_title")}</SubTitle>
            </Container>
        </ContainerFluid>
    );
};

export default Header;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #242424;
`;

const SubTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #424242;
`;

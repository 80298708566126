import { Avatar, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import {
    Message,
    MessageText,
} from "../../pages/ExpensesDetailReport/ChatMessage";
import moment from "moment";
import { CalendarChatFilled } from "@fluentui/react-icons";

const CommentSection = ({ comment, date, user = { avatar: "", name: "" } }) => {
    const MOMENT_MASK_FORMAT = "DD/MM/YYYY";
    return (
        <Flex column gap="gap.small">
            <Flex gap="gap.small">
                <CalendarChatFilled fontSize={24} />
                <CommentTitle content="Commentaire(s) dépense" />
            </Flex>
            <Flex gap="gap.small" vAlign="center">
                <Avatar size="medium" image={user.avatar} name={user.name} />
                <Message fill>
                    <MessageText
                        content={
                            <Text>{`${moment(date, MOMENT_MASK_FORMAT).format(
                                MOMENT_MASK_FORMAT
                            )} : ${comment}`}</Text>
                        }
                    />
                </Message>
            </Flex>
        </Flex>
    );
};

export default CommentSection;

const CommentTitle = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
`;

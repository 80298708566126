import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Badge = styled(Flex)`
    border-radius: 4px;
    color: ${({ color }) => (color ? color : "grey")};
    padding: 4px 8px;
    border: 1px solid
        ${({ border_color }) => (border_color ? border_color : "grey")};
    background-color: ${({ bg }) => (bg ? bg : "grey")};
`;

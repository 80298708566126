import { Flex, Skeleton } from "@fluentui/react-northstar";
import React from "react";
import { Wrapper } from "../DepartmentCollapse/index.style";
import { Cell, CellFirstColumn } from "../CalendarRemoteJob/index.style";
import { useShowRemoteWeekend } from "../../store/useShowRemoteWeekend";

const SkeletonRemoteRowEvents = () => {
    const ctx_show_weekend = useShowRemoteWeekend((state) => state.show);
    return (
        <Skeleton animation="pulse">
            <Wrapper>
                <Skeleton.Line width="62px" height="20px" />
                <Flex styles={{ gap: "4px" }}>
                    <Skeleton.Line width="16px" height="16px" />
                    <Skeleton.Shape round width="16px" height="16px" />
                    <Skeleton.Line width="16px" height="16px" />
                </Flex>
            </Wrapper>
            <Flex column>
                {new Array(3).fill("").map((_, i) => {
                    return (
                        <Flex>
                            <CellFirstColumn gap="gap.small" $active={false}>
                                <Skeleton.Shape
                                    round
                                    width="20px"
                                    height="20px"
                                />
                                <Skeleton.Line width="100px" height="20px" />
                            </CellFirstColumn>
                            <Flex styles={{ position: "relative" }}>
                                {new Array(ctx_show_weekend ? 7 : 5)
                                    .fill("")
                                    .map(() => {
                                        return (
                                            <Cell
                                                column
                                                $isClickable={false}
                                                $ctx_show_weekend={
                                                    ctx_show_weekend
                                                }
                                            >
                                                <Skeleton.Line
                                                    height="100%"
                                                    width="100%"
                                                />
                                            </Cell>
                                        );
                                    })}
                            </Flex>
                        </Flex>
                    );
                })}
            </Flex>
        </Skeleton>
    );
};

export default SkeletonRemoteRowEvents;

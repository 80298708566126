import React from "react";
import { useAuthDetailStore } from "../stores/useAuthStore";
import { roles_access, ROLES_CODE } from "../constants/roles";

const useAccessTo = (page) => {
    if (!page) {
        return {
            isAccess: false,
        };
    }

    switch (page) {
        case PAGES.onboarding:
            const isRH = roles_access({
                usersPrivileges: [ROLES_CODE?.hr],
            });
            return {
                isAccess: isRH,
            };

        default:
            return {
                isAccess: false,
            };
    }
};

export default useAccessTo;

export const PAGES = {
    onboarding: "onboarding",
};

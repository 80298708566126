import {
    Button,
    FilesUploadIcon,
    Flex,
    SignatureIcon,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import { CheckmarkStarburst20Filled } from "@fluentui/react-icons";
import styled from "styled-components";
import { TASK_ACTIONS } from "../../../../constants/tasks";
export default function OnboardingActions({ dataSuivi }) {
    return (
        <Main column>
            <Flex
                gap="gap.smaller"
                vAlign="center"
                styles={{
                    background: "#EBEBEB",
                    height: "60px",
                    padding: "16px 16px 16px 16px",
                    position: "sticky",
                    top: "0",
                    zIndex: 1,
                }}
            >
                <CheckmarkStarburst20Filled color="green" />
                <Title content="Actions effectuées en onboarding" />
            </Flex>
            <Flex>
                <Card TaskList={dataSuivi?.onboarding_tasks_done} />
            </Flex>
        </Main>
    );
}

const Card = ({ TaskList }) => {
    return (
        <Tasks styles={{ padding: "0px 16px" }} fill column gap="gap.medium">
            {TaskList?.length === 0 && (
                <Flex styles={{ marginTop: "24px" }} hAlign="center">
                    <EmptyTaskSuiviText content="Aucune action n’a été effectuée" />
                </Flex>
            )}
            {TaskList?.map((task, index) => (
                <CardContainer vAlign="center" space="between" key={index}>
                    <Flex gap="gap.smaller" vAlign="center">
                        <CheckmarkStarburst20Filled color="green" />
                        <CardTitle content={task?.name} />
                    </Flex>
                    <ActionButton vAlign="center" hAlign="center">
                        {task?.icon}
                        {/* <TextAction content={task?.document_action} /> */}
                        <TextAction
                            content={
                                TASK_ACTIONS[task?.document_action - 1]?.element
                            }
                        />
                    </ActionButton>
                </CardContainer>
            ))}
        </Tasks>
    );
};

const EmptyTaskSuiviText = styled(Text)`
    color: #616161;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

const Tasks = styled(Flex)`
    overflow-y: scroll;
    padding: 0px 16px 16px 16px;
`;

const TextAction = styled(Text)`
    color: #242424;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const ActionButton = styled(Flex)`
    gap: 4px;
    width: 88px;
    border-radius: 4px;
    background: #f5f5f5;
    padding: 4px 8px;
    z-index: 0;
`;

const CardContainer = styled(Flex)`
    border-radius: 8px;
    background: #fff;
    height: 48px;
    /* overflow-y: scroll; */
    padding: 0 16px;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
`;

const Title = styled(Text)`
    /* width: 239px; */
    color: #424242;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;
const CardTitle = styled(Text)`
    overflow: hidden;
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    text-overflow: ellipsis;
    white-space: nowrap;

    /* Medium/600 */
    font-family: "Segoe UI";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;

const Main = styled(Flex)`
    /* padding: 14px 16px; */
    background-color: #f0f0f0;
    width: 447px;
    height: 320px;
    overflow-y: scroll;
    scrollbar-width: none;
`;

import {
    Button,
    CalendarIcon,
    Image,
    InfoIcon,
    PersonIcon,
    Tooltip,
} from "@fluentui/react-northstar";
import { BASE_URL_ILLUSTRATIONS } from "../../../constants";

export const I18N_NS_ADD_EMPLOYEE = "translation";

export const ADD_EMPLOYEE_STEPS = [
    {
        id: 1,
        label: "pages.add_employee.steps.about_employee",
        pathname: "/employee/add/first",
        image: (
            <Image
                src={`${BASE_URL_ILLUSTRATIONS}/New_Collab-Step1_infos.svg`}
                width="300px"
            />
        ),
        required: true,
    },
    {
        id: 2,
        label: "pages.add_employee.steps.about_employee",
        pathname: "/employee/add/second",
        image: (
            <Image
                src={`${BASE_URL_ILLUSTRATIONS}/New_Collab-Step1_infos.svg`}
                width="300px"
            />
        ),
        required: true,
    },
    {
        id: 3,
        label: "pages.add_employee.steps.role",
        pathname: "/employee/add/third",
        image: (
            <Image
                src={`${BASE_URL_ILLUSTRATIONS}/New_Collab-Step4_role.svg`}
                width="300px"
            />
        ),
        required: true,
    },
    {
        id: 4,
        label: "pages.add_employee.steps.resources",
        pathname: "/employee/add/fourth",
        image: (
            <Image
                src={`${BASE_URL_ILLUSTRATIONS}/New_Collab-Step2_stuff.svg`}
                width="300px"
            />
        ),
        required: false,
    },
    {
        id: 5,
        label: "pages.add_employee.steps.tasks",
        pathname: "/employee/add/fifth",
        image: (
            <Image
                src={`${BASE_URL_ILLUSTRATIONS}/New_Collab-Step3_tasks.svg`}
                width="300px"
            />
        ),
        required: false,
    },
];

export const QUERY_CACHE_TIME = 1000 * 60 * 60 * 24; // 24 hours by default for all stepper pages.

export const HEADER = {
    items: [
        <Button
            key="btn_1"
            styles={{ padding: "0" }}
            as="span"
            text
            content={"Nom de la Tâche"}
            size="small"
        />,
        <Button
            key="btn_2"
            styles={{ padding: "0" }}
            as="span"
            text
            icon={<CalendarIcon />}
            content={"Date d’échéance"}
            size="small"
        />,
        <>
            <Button
                styles={{ paddingLeft: "0" }}
                as="span"
                icon={<PersonIcon />}
                text
                content={"Responsable"}
                size="small"
            />
            <Tooltip
                content="Défini suivant la règle du modèle ou manuellement"
                pointing
                subtle={false}
                trigger={<InfoIcon outline />}
            />
        </>,
        "",
    ],
};

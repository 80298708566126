import {
    Attachment,
    DownloadIcon,
    FilesPdfIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import AvatarName from "../../AvatarName";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_LEAVE } from "../../../../../features/leave/constants";
import moment from "moment";
import Status from "../../../../../features/expenses/components/Badges/Status";
import StatusLeave from "../../Badges/StatusLeave";
import DatesFromTo from "../../Dates";
import TypeLeave from "./TypeLeave";
import Solde from "./Solde";
import { ICON_FROM_EXTENSION } from "../../../../../utils/functions";
import { file_size_format } from "../../../../../constants/maths";
import { DEVICE } from "../../../../../constants/responsive";
import DownloadFunction from "./DownloadFunction";

const Content = ({ leave_detail, user_details, hideDialog = () => null }) => {
    const { t } = useTranslation(I18N_NS_LEAVE);

    const requested_on = moment(leave_detail.requested_on).format("DD/MM/YYYY");
    const email_waiting_approval_by =
        leave_detail.waiting_approval_by?.at(0)?.email;
    const waiting_approval_by = leave_detail?.waiting_approval_by;
    const email_validated_validator = leave_detail.approved_by?.at(0)?.email;
    const approved_by = leave_detail?.approved_by?.at(-1);
    const status_id = leave_detail.status?.id;
    const start_date = moment(leave_detail.start_date).format("DD/MM/YYYY");
    const end_date = moment(leave_detail.end_date).format("DD/MM/YYYY");
    const type_leave = leave_detail.type;
    const balance_count_to_remove = leave_detail.balance_count_to_remove;
    const comment = leave_detail.comment;
    const balance_after_leave = leave_detail?.balance_after_leave;
    const balance_before_leave = leave_detail?.balance_before_leave;
    const documents = leave_detail?.documents;
    const last_action = leave_detail?.last_action;
    const authenticated_user_can_remind_external_manager =
        leave_detail?.authenticated_user_can_remind_external_manager;

    console.log({ authenticated_user_can_remind_external_manager });

    const OpenDocumentOnNewTab = () => {
        const fileUrl = documents.at(0).attached_doc;
        window.open(fileUrl, "_blank");
    };

    let sentence_approval_by = t(
        "timeline.dialogs.leave_request.requested_at",
        {
            requested_on,
        }
    );

    let is_external_validator = false;
    if (waiting_approval_by.length) {
        is_external_validator = waiting_approval_by.at(0).is_external_validator;

        if (is_external_validator) {
            sentence_approval_by =
                last_action?.status + " " + last_action?.date;
        }
    }

    return (
        <Flex column gap="gap.medium">
            {/* HEADER */}
            <Header space="between" fill>
                {/* left side */}
                <Flex column gap="gap.small">
                    <Label
                        content={t("timeline.dialogs.leave_request.leave_for")}
                    />
                    <AvatarName
                        image={user_details.avatar}
                        name={user_details.name}
                        size="large"
                    />
                </Flex>
                {/* right side */}
                <Flex
                    className="right_section"
                    column
                    hAlign="end"
                    gap="gap.small"
                >
                    <DateRequestedOn content={sentence_approval_by} />
                    <StatusLeave
                        user_id={user_details?.id}
                        leave_request_id={leave_detail?.id}
                        email_waiting_approval_by={email_waiting_approval_by}
                        email_validated_validator={email_validated_validator}
                        date_approuved={
                            approved_by?.validated_on ?? requested_on
                        }
                        status_id={status_id}
                        is_external_validator={is_external_validator}
                        authenticated_user_can_remind_external_manager={
                            authenticated_user_can_remind_external_manager
                        }
                        onSuccess={hideDialog}
                    />
                </Flex>
            </Header>
            {/* BODY */}
            <Flex gap="gap.medium" column>
                {/* dates */}
                <Section column>
                    <Label content={t("timeline.dialogs.leave_request.date")} />
                    <DatesFromTo date_from={start_date} date_to={end_date} />
                </Section>
                {/* type of leave */}
                <Section column>
                    <Label
                        content={t("timeline.dialogs.leave_request.leave_type")}
                    />
                    <TypeLeave
                        name={type_leave?.name}
                        color={type_leave?.color}
                        days={balance_count_to_remove}
                    />
                </Section>
                {/* Solde */}
                {status_id === 1 && type_leave?.has_balance === true && (
                    <Section column>
                        <Label
                            content={t("timeline.dialogs.leave_request.solde")}
                        />
                        <Solde
                            balance_after_leave={balance_after_leave}
                            balance_before_leave={balance_before_leave}
                        />
                    </Section>
                )}
                {/* Commentaire */}
                {comment && (
                    <Section column>
                        <Label
                            content={t(
                                "timeline.dialogs.leave_request.comment"
                            )}
                        />
                        <Comment content={comment} />
                    </Section>
                )}
                {/* Justificatif */}
                {documents?.length > 0 && (
                    <Section column>
                        <Text
                            content={t(
                                "timeline.dialogs.leave_request.justificatif"
                            )}
                        />
                        <Attachment
                            actionable
                            icon={ICON_FROM_EXTENSION(documents.at(0).name)}
                            header={documents.at(0).name}
                            description={file_size_format(
                                documents.at(0).filesize
                            )}
                            action={{
                                icon: (
                                    <DownloadIcon
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            return DownloadFunction(documents);
                                        }}
                                    />
                                ),
                                title: "Download file",
                            }}
                            onClick={OpenDocumentOnNewTab}
                        />
                    </Section>
                )}
            </Flex>
        </Flex>
    );
};

export default Content;

const Header = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 8px;
        .right_section {
            align-items: start;
        }
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        .right_section {
            align-items: end;
        }
    }
`;

const Comment = styled(Text)`
    color: #242424;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const Section = styled(Flex)`
    gap: 4px;
`;

const DateRequestedOn = styled(Text)`
    color: #242424;

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
`;

const Label = styled(Text)`
    color: #686868;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

import React from "react";
import { BASE_URL_ILLUSTRATIONS } from "../../constants";

const Illustration = ({ filename, width = "100%", height = "100%" }) => {
    return (
        <img
            src={`${BASE_URL_ILLUSTRATIONS}/${filename}`}
            alt={`illus_${filename}`}
            width={width}
            height={height}
        />
    );
};

export default Illustration;

import React from "react";
import { motion } from "framer-motion";
import { Link, useNavigate, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import {
    Avatar,
    Divider,
    Dropdown,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import { Power20Regular } from "@fluentui/react-icons";
import { logout } from "../../api/auth";
import { LANGUES } from "../../constants/locales";
import FlagText from "../Flag/FlagText";
import { useTranslation } from "react-i18next";
import SwitchLang from "../shared/SwitchLang";
import { useAuthDetailStore } from "../../stores/useAuthStore";
import { ROUTES } from "../../constants/routes";

const Drawer = ({ humberger, open, toggle }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const detail = useAuthDetailStore((state) => state.detail);
    const display_in_main_menu =
        detail?.features?.time_reporting?.display_in_main_menu;

    const _logout = async () => {
        await logout();
        navigate("/login");
        return;
    };

    const langues = LANGUES?.map((lng, index) => {
        return {
            id: index,
            header: lng?.nativeName,
            code: lng?.code,
            image: {
                src: lng.flag,
                styles: {
                    width: "30px",
                    height: "25px",
                    borderRadius: 0,
                    objectFit: "cover",
                },
            },
        };
    });

    const langue_default = langues?.filter(
        (lng) => i18n.resolvedLanguage === lng?.code
    );

    return (
        <>
            {open && (
                <>
                    <Overlay
                        onClick={(e) => toggle((state) => !state)}
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: 1,
                        }}
                        transition={{
                            duration: 0.3,
                        }}
                    />
                    <FlexStyle
                        initial={{
                            x: -200,
                        }}
                        animate={{
                            x: 0,
                        }}
                        transition={{
                            duration: 0.3,
                        }}
                        gap="gap.small"
                    >
                        {/* header */}
                        <Flex fill column space="between">
                            <Flex column>
                                <Flex
                                    styles={{
                                        paddingInline: "16px",
                                    }}
                                    vAlign="center"
                                    gap="gap.medium"
                                >
                                    <Flex>
                                        <Avatar
                                            image={detail?.avatar}
                                            name={`${detail?.first_name} ${detail?.last_name}`}
                                            size="large"
                                        />
                                    </Flex>
                                    <Flex column gap="gap.smaller">
                                        <FullName
                                            content={`${detail?.first_name} ${detail?.last_name}`}
                                        />
                                        <LinkToProfile
                                            to={`/employee/${detail?.id}`}
                                        >
                                            Voir mon profil {">"}
                                        </LinkToProfile>
                                    </Flex>
                                </Flex>
                                <Flex column styles={{ paddingInline: "26px" }}>
                                    <Divider />
                                </Flex>
                                {humberger?.map((el, index) => {
                                    const isTimeReportingNav =
                                        el?.link === ROUTES.time_reporting.home;
                                    let link = "";
                                    if (
                                        (isTimeReportingNav &&
                                            display_in_main_menu) ||
                                        el?.activated
                                    ) {
                                        link = el?.link;
                                    } else {
                                        link = "/denied";
                                    }

                                    return (
                                        <Nav
                                            end
                                            key={index}
                                            to={link}
                                            className={({
                                                isActive,
                                                isPending,
                                            }) => {
                                                return isPending
                                                    ? "pending"
                                                    : isActive
                                                    ? "active"
                                                    : "";
                                            }}
                                            $isDisabled={
                                                isTimeReportingNav
                                                    ? !display_in_main_menu
                                                    : false
                                            }
                                        >
                                            <Flex
                                                fill
                                                gap="gap.smaller"
                                                vAlign="center"
                                            >
                                                {el?.icon}
                                                <NavText>{el?.name}</NavText>
                                            </Flex>
                                        </Nav>
                                    );
                                })}
                            </Flex>
                            <Footer vAlign="center">
                                <Flex fill>
                                    <SwitchLang />
                                </Flex>
                                <Flex
                                    gap="gap.smaller"
                                    vAlign="center"
                                    onClick={_logout}
                                >
                                    <WrapperLogout
                                        vAlign="center"
                                        hAlign="center"
                                    >
                                        <Power20Regular color="var(--color-danger)" />
                                    </WrapperLogout>
                                </Flex>
                            </Footer>
                        </Flex>
                    </FlexStyle>
                </>
            )}
        </>
    );
};

export default Drawer;

const NavText = styled(Text)`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
`;

const LinkToProfile = styled(Link)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: #0067b8;
    &:hover {
        text-decoration: underline;
    }
`;

const FullName = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #242424;
`;

const WrapperLogout = styled(Flex)`
    background: #fcf4f6;
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: #f3d6d8;
    border-radius: 3px;
    width: 52px;
    height: 52px;
    cursor: pointer;
`;

const Footer = styled(Flex)`
    position: sticky;
    bottom: 0px;
    left: 0px;
    right: 0;
    margin-top: 100px;
    background-color: #f5f5f5;
`;

const Nav = styled(NavLink)`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    padding-inline: 19px;
    padding-block: 12px;

    display: flex;
    align-items: center;

    color: #484644;
    cursor: pointer;
    text-decoration: none;
    &.active {
        color: var(--color-primary);
        background-color: white;
    }
    &:hover {
        color: black;
        background-color: white;
    }
    ${({ $isDisabled }) =>
        $isDisabled
            ? css`
                  opacity: 0.3;
                  color: grey !important;
                  pointer-events: none;
                  cursor: not-allowed !important;
              `
            : css``}
`;

const Overlay = styled(motion.div)`
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99;
`;

const FlexStyle = styled(motion.div)`
    position: fixed;
    left: 0;
    top: 50px;
    bottom: 0;
    width: 230px;
    background: #f5f5f5;
    z-index: 100;
    padding: 16px 0px 0px 0px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    /* overflow: hidden; */
    &::-webkit-scrollbar {
        display: none; /* Masquer la barre dans Chrome, Safari, et Edge */
    }
`;

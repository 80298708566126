import React from "react";
import { Flex, Loader } from "@fluentui/react-northstar";
import { useQuery } from "react-query";

import Card from "../../../Cards";
import IdentityDocuments from "../../../Cards/CardIdentity/IdentityDocuments";
import { QUERY_KEY } from "../../../../constants";
import { _api_get_identity } from "../../../../api";
import { useEmployeeFolderIdUser } from "../../../../store";
import CardInformationPersonnal from "../../../Cards/CardIdentity/features/CardInformationPersonnal";
import CardInformationContact from "../../../Cards/CardIdentity/features/CardInformationContact";
import CardInformationCivilStatus from "../../../Cards/CardIdentity/features/CardInformationCivilStatus";
import CardSurroundingPeople from "../../../Cards/CardIdentity/features/CardSurroundingPeople";

const TabIdentity = () => {
    const id_employee = useEmployeeFolderIdUser();

    const { data: identity_data, isLoading: is_loading_identity } = useQuery(
        [QUERY_KEY + "::identity"],
        () => _api_get_identity(id_employee),
        {
            refetchOnWindowFocus: false,
        }
    );

    if (is_loading_identity) {
        return <Loader content="chargement des informations" />;
    }

    return (
        <Flex gap="gap.medium" fill space="between">
            <Flex fill styles={{ maxWidth: "440px" }} column gap="gap.large">
                {/* CARD INFORMATION PERSONNAL */}
                <CardInformationPersonnal identity_data={identity_data} />
                {/* INFORMATION DE CONTACT */}
                <CardInformationContact identity_data={identity_data} />
            </Flex>
            <Flex fill styles={{ maxWidth: "440px" }} column>
                <CardInformationCivilStatus identity_data={identity_data} />
            </Flex>
            <Flex fill styles={{ maxWidth: "440px" }} column gap="gap.large">
                {/* ENTOURAGE */}
                <CardSurroundingPeople identity_data={identity_data} />
                {/* DOCUMENTS IDENTITY */}
                <Card column>
                    {/* document */}
                    <Card.Body
                        content={
                            <IdentityDocuments
                                identity_documents={
                                    identity_data?.identity_documents
                                }
                            />
                        }
                    />
                </Card>
            </Flex>
        </Flex>
    );
};

export default TabIdentity;

import { Button, Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { Power20Regular } from "@fluentui/react-icons";
import { logout } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { DEVICE } from "../../../constants/responsive";

const Footer = ({ hideLogout = false }) => {
    const navigate = useNavigate();
    return (
        <FooterStyle space="between" vAlign="center">
            {/* <Flex gap="gap.small">
                <FooterLogo src={FavIconYeetch} />
                <FooterLogo src={LinkedinLogo} />
            </Flex> */}
            <FooterLinks>
                <Text content="© Yeetch 2023" />
                <LinkText content="Mentions légales" />
                <LinkText content="RGPD" />
                <LinkText content="Conditions générales d’utilisation" />
            </FooterLinks>
            <Flex>
                {!hideLogout && (
                    <Button
                        text
                        content={"Se déconnecter"}
                        icon={<Power20Regular color="var(--color-danger)" />}
                        onClick={async () => {
                            await logout();
                            navigate("/login");
                        }}
                    />
                )}
            </Flex>
        </FooterStyle>
    );
};

export default Footer;

const FooterLinks = styled(Flex)`
    gap: 16px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }
    @media ${DEVICE.mobileM} {
        flex-direction: column;
    }
    @media ${DEVICE.mobileL} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;
const LinkText = styled(Text)`
    color: #0067b8;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
const FooterLogo = styled(Image)`
    width: 20px;
    height: 20px;
`;

const FooterStyle = styled(Flex)`
    border-top: 1px solid #d1d1d1;

    height: 24px;
    padding-block: 24px;
    margin-top: 64px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        width: 100%;
        gap: 16px;
        align-items: center;
    }
    @media ${DEVICE.mobileM} {
        flex-direction: column;
        gap: 16px;
    }
    @media ${DEVICE.mobileL} {
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
        gap: 16px;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        width: 1024px;
    }
`;

import { Dropdown, Flex, Input } from "@fluentui/react-northstar";
import React from "react";
import DropdownRemote from "../DropdownRemote/DropdownRemote";
import SliderTabs from "../../../../components/shared/SliderTabs";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "../../../../common/styles";
import { TIMES_SPLIT_DAY_TO_INDEX } from "../../constants";

const Content = () => {
    const {
        control,
        setValue,
        getValues,
        trigger,
        formState: { errors },
        watch,
    } = useFormContext();

    console.log({ e: watch("start_time") });

    return (
        <Flex column gap="gap.small" styles={{ marginTop: "24px" }}>
            <Flex>
                <DropdownRemote
                    onChangeItem={(reason_value) => {
                        return setValue("reason", reason_value);
                    }}
                    defaultValues={{
                        selectedIndexItem: getValues("reason") ?? 1,
                    }}
                />
            </Flex>
            <Flex column styles={{ gap: "4px" }}>
                <div>
                    <Controller
                        control={control}
                        name="start_date"
                        render={({ field: { onChange, value, ref } }) => {
                            return (
                                <Input
                                    label="Commence le"
                                    type="date"
                                    defaultValue={getValues("start_date")}
                                    ref={ref}
                                    onChange={(e, i) => {
                                        onChange(i.value);
                                        trigger();
                                        return;
                                    }}
                                    error={errors?.start_date?.message}
                                />
                            );
                        }}
                    />
                </div>
                {errors?.start_date && (
                    <ErrorMessage content={errors.start_date.message} />
                )}
                <SliderTabs
                    tabs={["Début de journée", "Début d'après-midi"]}
                    defaultActiveIndex={getValues("start_time")}
                    onChange={(data) => {
                        return setValue("start_time", data);
                    }}
                    styles={{
                        width: "fit-content!important",
                    }}
                />
            </Flex>
            <Flex column styles={{ gap: "4px" }}>
                <div>
                    <Controller
                        control={control}
                        name="end_date"
                        render={({ field: { onChange, value, ref } }) => {
                            return (
                                <Input
                                    label="Fini le"
                                    type="date"
                                    error={errors?.end_date?.message}
                                    ref={ref}
                                    defaultValue={getValues("end_date")}
                                    onChange={(e, i) => {
                                        onChange(i.value);
                                        trigger();
                                        return;
                                    }}
                                />
                            );
                        }}
                    />
                </div>
                {errors?.end_date && (
                    <ErrorMessage content={errors.end_date.message} />
                )}
                <SliderTabs
                    tabs={["Fin de matinée", "Fin de journée"]}
                    defaultActiveIndex={getValues("end_time")}
                    onChange={(data) => {
                        return setValue("end_time", data);
                    }}
                    styles={{
                        width: "fit-content!important",
                    }}
                />
            </Flex>
        </Flex>
    );
};

export default Content;

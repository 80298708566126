import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../../constants/responsive";

export const Title = styled(Text)`
    color: #242424;

    font-size: clamp(16px, 2vw, 24px);
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
`;

export const CardExpenseRedesigned = styled(Flex)`
    /* width: 100%; */
    /* max-width: 800px; */
    /* margin: 0px auto; */
    /* border: 2px solid cyan; */
    background-color: white;
    padding: 8px 16px;
    justify-content: center;
    height: fit-content;
    /* outline: 3px solid yellow; */
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    border-radius: 8px;

    @media ${DEVICE.mobileS} {
        width: clamp(300px, 90vw, 750px);
    }
    @media ${DEVICE.mobileM} {
        width: clamp(300px, 95vw, 750px);
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
        width: clamp(300px, 55vw, 750px);
    }
    @media ${DEVICE.laptopL} {
    }
`;

export const CardExpenseTitle = styled(Text)`
    color: #242424;

    font-size: clamp(16px, 2vw, 18px);
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;

export const ColumnTitle = styled(Text)`
    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    display: flex;
    @media ${DEVICE.mobileS} {
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
        display: none;
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

export const CellDate = styled(Text)`
    color: #424242;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const CellTitle = styled(Text)`
    color: #424242;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    /* outline: 2px solid red; */
    @media ${DEVICE.mobileS} {
        width: 80px;
    }
    @media ${DEVICE.mobileL} {
        width: 120px;
    }
    @media ${DEVICE.laptop} {
        width: 120px;
    }
    @media ${DEVICE.laptopL} {
        width: 150px;
    }
`;

export const ContainerStyle = styled(Flex)`
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    /* border: 2px solid orange; */
    padding: 0px 8px;
    /* @media ${DEVICE.mobileS} {
        width: 100%;c
        max-width: auto;
        flex-direction: column;
    }

    @media ${DEVICE.mobileL} {
        width: 100%;
        max-width: auto;
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        width: 100%;
        max-width: auto;
    }
    @media ${DEVICE.laptop} {
        width: 100%;
        flex-direction: row;
    }
    @media ${DEVICE.laptopL} {
        width: 100%;
        max-width: auto;
        flex-direction: row;
    } */
`;

export const Body = styled(Flex)`
    max-width: 1440px;
    /* margin: 0 auto; */
    width: 100%;

    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 25px;
    }
    @media ${DEVICE.mobileL} {
        flex-direction: column;
        gap: 25px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        gap: 0px;
    }
    @media ${DEVICE.laptopL} {
        flex-direction: row;
        gap: 0px;
    }
`;

import { Flex } from "@fluentui/react-northstar";
import React from "react";

import { useState } from "react";
import DocumentToUpload from "./DocumentToUpload";
import DocumentUploaded from "./DocumentUploaded";
import { useQuery } from "react-query";
import { _preonboarding } from "../api";

const DocumentComponent = ({ data, isLoading, isFetching }) => {
    const [state, setstate] = useState(true);
    return (
        <Flex style={{ gap: "24px" }} column fill>
            {/* document not uploaded yet  */}
            <DocumentToUpload
                todo={data?.tasks?.todo}
                state={state}
                setstate={setstate}
                isLoading={isLoading}
                isFetching={isFetching}
                lengthTodo={data?.tasks?.todo?.length}
            />
            {/* document is uploaded (done)*/}
            <DocumentUploaded
                done={data?.tasks?.done}
                state={state}
                setstate={setstate}
                isLoading={isLoading}
                isFetching={isFetching}
                status={true}
                lengthdone={data?.tasks?.done?.length}
            />
        </Flex>
    );
};

export default DocumentComponent;

import React, { useState } from "react";
import { Dropdown } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../../constants/responsive";

export const FILTER_ITEMS = [
    { id: 4, header: "Toutes les absences" },
    { id: 1, header: "En attente de validation" },
    { id: 2, header: "Validées" },
];

const FilterList = ({
    onFilter,
    defaultValue = null,
    filterStatus,
    setFilterStatus,
}) => {
    return (
        <DropDownStyle
            fluid
            items={FILTER_ITEMS}
            value={filterStatus ?? FILTER_ITEMS[0]}
            placeholder={"Tous les statuts"}
            onChange={(e, i) => {
                setFilterStatus(i?.value);
                return onFilter({ filteredId: i?.value?.id });
            }}
        />
    );
};

export default FilterList;

const DropDownStyle = styled(Dropdown)`
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    @media ${DEVICE.mobileS} {
        width: 100px;
    }
    @media ${DEVICE.laptop} {
        width: 190px;
    }
`;

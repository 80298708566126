import {
    Button,
    CloseIcon,
    Dialog,
    Flex,
    Image,
} from "@fluentui/react-northstar";
import React from "react";
import { TextCategory } from "../ExpensesAddFormClassic";
import { DialogCategoryExpense } from "./index.style";
import Categories from "./Categories";
import useLogicCategoryExpense from "./useLogicCategoryExpense";
import styled from "styled-components";
import { DEVICE } from "../../../../constants/responsive";

/**
 * @typedef {Object} PropsType
 * @property {{ category : { icon_url : React.ReactElement | string | null, name : string, id: number } }} state
 * @property {boolean} [can_update_category]
 * @property {function} [cbCategoryChanged]
 *
 * @param {PropsType} Props
 * @returns
 */
const CategoryExpense = ({
    state,
    can_update_category = false,
    cbCategoryChanged = () => null,
}) => {
    // store categories
    const { query, selected_category, dialog } = useLogicCategoryExpense({
        selected_category: {
            id: state?.category?.id,
            name: state?.category?.name,
            icon_url: state?.category?.icon_url,
        },
        can_update_category, // pour enable la requete, on veut pas envoyer un fetch si user n'est pas autorisé a changer de catégorie
    });
    return (
        <CategoryExpenseFlex fill gap="gap.small">
            <Flex gap="gap.small" vAlign="center">
                <Image src={state?.category?.icon} alt="icon" width="40px" />
                <TextCategory truncated>{state?.category?.name}</TextCategory>
            </Flex>
            {/* show/hide the button modify category */}
            <Flex>
                {can_update_category && (
                    <DialogCategoryExpense
                        open={dialog.open}
                        header={"Changer de catégorie"}
                        trigger={
                            <Button
                                primary
                                text
                                content="Changer de catégorie"
                                onClick={() => dialog.setOpen()}
                                styles={{ textDecoration: "underline" }}
                            />
                        }
                        headerAction={{
                            content: <CloseIcon />,
                            onClick: () => dialog.setClose(),
                        }}
                        content={
                            <Categories
                                categories={query.categories}
                                isLoading={query.isLoading}
                                selected_category={selected_category}
                            />
                        }
                        onCancel={() => dialog.setClose()}
                        confirmButton={{
                            content: "Enregistrer",
                            flat: true,
                            onClick: () => {
                                dialog.setClose();
                                cbCategoryChanged(selected_category);
                                return;
                            },
                        }}
                        cancelButton={{
                            content: "Annuler",
                            flat: true,
                            onClick: () => {
                                dialog.setClose();
                            },
                        }}
                    />
                )}
            </Flex>
        </CategoryExpenseFlex>
    );
};

export default CategoryExpense;

const CategoryExpenseFlex = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.mobileM} {
        flex-direction: column;
    }
    @media ${DEVICE.mobileL} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
        justify-content: flex-start;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        align-items: center;
    }
    @media ${DEVICE.laptopL} {
        flex-direction: row;
    }
`;

import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
    ThumbLike16Regular,
    ThumbDislike16Regular,
    MoneyCalculator24Regular,
    Clock24Regular,
} from "@fluentui/react-icons";
import { I18N_NS_EXPENSE } from "../../constants";

export const LEVELS = {
    refuse: "refuse",
    approuve: "approuve",
    pending: "pending",
    recognised: "recognised",
};

const THEME = {
    [LEVELS.approuve]: {
        backgroundColor: "#6BB700",
        text: "home.badge_status_filled.approuve",
        icon: <ThumbLike16Regular />,
    },
    [LEVELS.pending]: {
        backgroundColor: "#FFAA44",
        text: "home.badge_status_filled.pending",
        icon: <Clock24Regular />,
    },
    [LEVELS.recognised]: {
        backgroundColor: "#B4009E",
        text: "home.badge_status_filled.recognised",
        icon: <MoneyCalculator24Regular />,
    },
    [LEVELS.refuse]: {
        backgroundColor: "#C4314B",
        text: "home.badge_status_filled.refuse",
        icon: <ThumbDislike16Regular />,
    },
};

const get_theme = (level) => {
    return THEME[level];
};

const StatusFilled = ({ level }) => {
    const config = get_theme(level);
    const { t } = useTranslation(I18N_NS_EXPENSE);
    return (
        <Container {...config} gap="gap.smaller" vAlign="center">
            {config?.icon}
            <Text {...config}>{t(config?.text)}</Text>
        </Container>
    );
};

export default StatusFilled;

StatusFilled.propTypes = {
    /** choose level, for theming, use GLOBAL: **LEVELS** object */
    level: PropTypes.oneOf(Object.keys(LEVELS)),
};

const Container = styled(Flex)`
    border-radius: 4px;
    width: fit-content;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: white;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 12px;
    height: 32px;
`;

import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const PercentageIcon = () => {
    return (
        <Wrapper>
            <Percent content="%" />
        </Wrapper>
    );
};

export default PercentageIcon;

const Percent = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Default-Foreground-3, #fff);
    text-align: center;

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

const Wrapper = styled(Flex)`
    width: 16px;
    height: 16px;
    /* padding: 0px 4px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: var(--spacing-s-12, 12px);
    background: var(
        --Light-theme-Rest-Foreground-Default-Foreground-2,
        #616161
    );
`;

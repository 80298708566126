import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { CellFirstColumn } from "../CalendarRemoteJob/index.style";

export const Wrapper = styled(CellFirstColumn)`
    outline: 1px solid var(--Light-theme-Rest-Border-Default-Border, #d1d1d1);
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);

    height: 35px;
    padding: 0px 4px 0px var(--spacing-m-16, 16px);
    justify-content: space-between;
    align-items: center;
`;
export const Title = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
`;

import React from "react";
import { Avatar, ContactCardIcon, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CardTree = ({ data, highlighted = false }) => {
    return (
        <Flex
            key={data?.user.id}
            gap="gap.medium"
            vAlign="center"
            fill
            id={highlighted ? "highlight" : ""}
            className={highlighted ? "highlight" : ""}
        >
            {/* avatar */}
            <Flex>
                <Avatar image={data?.user?.avatar} name={data?.label} />
            </Flex>

            <Flex
                column
                // gap="gap.small"
                fill
                style={{
                    gap: "4px",
                }}
            >
                {/* name */}

                <Link
                    to={`/employee/${data?.id}`}
                    onClick={(e) => e.stopPropagation()}
                    style={{
                        width: "100px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                        textDecoration: "none",
                    }}
                >
                    <TextName truncated>{data?.label}</TextName>
                </Link>

                <Flex
                    // gap="gap.medium"
                    column
                    style={{
                        gap: "8px",
                        width: "149px",
                    }}
                >
                    <Flex
                        style={{
                            width: "100px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "left",
                        }}
                    >
                        <TextJob truncated>{data?.user?.job_title}</TextJob>
                    </Flex>
                    <ServiceBadge fill>
                        <BadgeText content={data?.user?.department} />
                    </ServiceBadge>
                </Flex>
            </Flex>

            {/* card link to collaborator profile */}
            <Flex hAlign="end" vAlign="center" gap="gap.small">
                <Link
                    to={`/employee/${data?.id}`}
                    onClick={(e) => e.stopPropagation()}
                    style={{
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                        textDecoration: "none",
                    }}
                >
                    <ContactCardIcon
                        outline
                        styles={{ color: "var(--color-primary)" }}
                    />
                </Link>
            </Flex>
        </Flex>
    );
};

export default CardTree;

const TextName = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    color: #5b5fc7;
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;

const TextJob = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    color: #424242;
`;

const ServiceBadge = styled(Flex)`
    height: fit-content;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 100px;
    background: #f0f0f0;
    width: fit-content;
`;

const BadgeText = styled(Text)`
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    width: 100%;
`;

import { Divider, Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

import IconMouseMove from "../../../assets/illustrations/Mouse-move.svg";
import IconMouseZoom from "../../../assets/illustrations/Mouse-zoom.svg";

import IconFingerMove from "../../../assets/illustrations/Finger-move.svg";
import IconFingerZoom from "../../../assets/illustrations/Finger-zoom.svg";
import { useTranslation } from "react-i18next";
import { I18N_NS_ANNUAIRE } from "../constant";

const OrganigramTutoDialog = () => {
    const { t } = useTranslation(I18N_NS_ANNUAIRE);
    return (
        <Flex column fill gap="gap.medium">
            <Flex>
                <SubTitle
                    content={t("organigram.dialog_tuto.content.sub_title")}
                />
            </Flex>
            <Flex>
                <CategoryTitle
                    content={t("organigram.dialog_tuto.content.category_title")}
                />
            </Flex>
            <Flex vAlign="center" gap="gap.small">
                <Image src={IconMouseMove} />
                <Indication
                    content={t(
                        "organigram.dialog_tuto.content.indication_mouse_move"
                    )}
                />
            </Flex>
            <Flex vAlign="center" gap="gap.small">
                <Image src={IconMouseZoom} />
                <Indication
                    content={t(
                        "organigram.dialog_tuto.content.indication_mouse_zoom"
                    )}
                />
            </Flex>
            <Divider />
            <Flex>
                <CategoryTitle
                    content={t(
                        "organigram.dialog_tuto.content.category_title_mobile"
                    )}
                />
            </Flex>
            <Flex vAlign="center" gap="gap.small">
                <Image src={IconFingerMove} />
                <Indication
                    content={t(
                        "organigram.dialog_tuto.content.indication_finger_move"
                    )}
                />
            </Flex>
            <Flex vAlign="center" gap="gap.small">
                <Image src={IconFingerZoom} />
                <Indication
                    content={t(
                        "organigram.dialog_tuto.content.indication_finger_zoom"
                    )}
                />
            </Flex>
        </Flex>
    );
};

export default OrganigramTutoDialog;

const Indication = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #424242;
`;

const CategoryTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #242424;
`;

const SubTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #424242;
`;

import React from "react";
import {
    CloseIcon,
    CheckmarkCircleIcon,
    InfoIcon,
    Text,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { PT_CREATE_TEMPLATE } from "../constants";
import DialogModifyTemplate from "./DialogModifyTemplate";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { _api_update_template } from "../../../api/tasks/templates";
import { useState } from "react";
import FormDialogUpdateManualTaskTemplate from "./FormDialogUpdateManualTaskTemplate";
import { I18N_NS_TRANSLATION } from "../../../constants";
import { DialogStyle } from "../style";

const FormDialogModifyTemplate = ({
    hideDialogModify,
    id_template_to_modify,
    openDialogModifyTemplate,
    refetch_get_list_templates,
    setShowAlert: showGlobalAlert,
}) => {
    const { t } = useTranslation(I18N_NS_TRANSLATION);

    const [openDialogModifyTask, setOpenDialogModifyTask] = useState({
        visible: false,
        task: null,
    });

    const [showAlert, setShowAlert] = useState({
        visible: false,
        message: "",
        level: "warning",
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: {},
    });

    // use mutation to send data.
    const {
        mutate: APICallModifyTemplate,
        isLoading: isLoadingModifyTemplate,
    } = useMutation(
        (data) => {
            // API function
            return _api_update_template(data);
        },
        {
            onSuccess: (data) => {
                if (data?.success) {
                    methods.reset();
                    refetch_get_list_templates();
                    hideDialogModify();
                    showGlobalAlert((state) => ({
                        visible: true,
                        message: "Le modèle de tâches a bien été modifié !",
                        level: "success",
                        icon: <CheckmarkCircleIcon />,
                    }));
                }
                return;
            },
            onError: (data) => {
                return alert(data);
            },
        }
    );

    const onSubmit = () => {
        const { getValues } = methods;

        // data templates
        const id = getValues("id");
        const name = getValues("name");
        const description = getValues("description");
        const type = getValues("type");
        const tasks = getValues("tasks");

        if (tasks?.length === 0) {
            return setShowAlert({
                visible: true,
                message: "il faut au minimum une tâche !",
                level: "danger",
                icon: <InfoIcon />,
            });
        }

        let formated_tasks = tasks?.map((el) => {
            if (el?.manual) {
                return {
                    id: 0,
                    name: el?.name,
                    description: el?.description,
                    type,
                    category_id: el?.category_id,
                    duedate_duration: el?.duedate_duration,
                    duedate_operator: el?.duedate_operator,
                    duedate_event: el?.duedate_event,
                    task_to_validate: el?.task_to_validate,
                    validator_type: el?.validator_type,
                    validator_user_id: el?.validator_user_id,
                    assigned_to_type: el?.assigned_to_type,
                    assigned_to_user_id: el?.assigned_to_user_id,
                    document_action:
                        el?.document_action === 5 ? 0 : el?.document_action,
                    documents_template: el?.documents_template || [],
                    new_doc_ids: el?.new_doc_ids,
                };
            } else {
                return {
                    ...el,
                    document_action: el?.document_action,
                    new_doc_ids: el?.new_doc_ids,
                };
            }
        });

        const formated_data = {
            id,
            name,
            description,
            type: type ?? 1,
            task_definitions: formated_tasks,
        };
        APICallModifyTemplate(formated_data);
    };

    methods.watch(); // don't remove this line, it's used for restarting this component.

    return (
        <>
            <FormDialogUpdateManualTaskTemplate
                default_task={openDialogModifyTask?.task}
                setTemplateTasks={() => console.log("hi there !")}
                openDialogUpdateTask={openDialogModifyTask?.visible}
                setOpenDialogUpdateTask={setOpenDialogModifyTask}
                styles={{ position: "absolute" }}
                methods={methods}
            />
            <DialogStyle
                header={<Text>{t(PT_CREATE_TEMPLATE + ".modify.title")}</Text>}
                content={
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <DialogModifyTemplate
                                task_template_id={id_template_to_modify}
                                showAlert={showAlert}
                                setShowAlert={setShowAlert}
                                setOpenDialogModifyTask={
                                    setOpenDialogModifyTask
                                }
                            />
                        </form>
                    </FormProvider>
                }
                confirmButton={{
                    disabled:
                        !methods.formState.isValid ||
                        methods.getValues("tasks")?.length === 0,
                    content: "Enregistrer le modèle",
                    loading: isLoadingModifyTemplate,
                }}
                onConfirm={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return methods.handleSubmit(onSubmit)();
                }}
                cancelButton={t(PT_CREATE_TEMPLATE + ".buttons.cancel")}
                open={openDialogModifyTemplate}
                onCancel={() => hideDialogModify()}
                headerAction={<CloseIcon onClick={() => hideDialogModify()} />}
                style={{
                    width: "764px",
                }}
            />
        </>
    );
};

export default FormDialogModifyTemplate;

import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { CustomBadge } from "../../../../components/Badge";

const HeaderTasksList = ({ count }) => {
    return (
        <Flex
            gap="gap.medium"
            vAlign="center"
            styles={{
                paddingInline: "16px",
                paddingBlock: "24px",
            }}
        >
            <TitleListTasks content="Liste des tâches" />
            <CustomBadge
                bg="#3D3E78"
                color="white"
                label={count?.toString()}
                size="large"
                styles={{
                    paddingInline: "8px",
                    fontWeight: "bold",
                }}
            />
        </Flex>
    );
};

export default HeaderTasksList;

const TitleListTasks = styled(Text)`
    //styleName: Large/700;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
`;

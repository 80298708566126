import React from "react";
import { Text, Badge } from "@fluentui/react-components";
import { Avatar, Flex, Table } from "@fluentui/react-northstar";

import {
    BADGE_STATUS_TASKS,
    render_badge_action_document_by_id,
} from "../../constants/tasks";
import { AvatarName } from "./style";
import moment from "moment";
import { date } from "yup/lib/locale";
import { Warning20Filled } from "@fluentui/react-icons";

const TaskRow = ({ data, ref }) => {
    const MissedTodoTask = (data) => {
        if (
            moment(data?.due_date).format("YYYY-MM-DD") <
                moment().format("YYYY-MM-DD") &&
            data?.status === "TODO"
        ) {
            return true;
        } else {
            return false;
        }
    };

    const showError = MissedTodoTask(data);

    return (
        <Table.Row ref={ref} styles={{ cursor: "pointer" }}>
            <Table.Cell
                content={
                    data?.due_date ? (
                        <Flex
                            style={{
                                gap: "6px",
                            }}
                        >
                            {moment(data.due_date).format("DD/MM/YYYY")}
                            {showError ? <Warning20Filled /> : null}
                        </Flex>
                    ) : (
                        ""
                    )
                }
                style={{
                    maxWidth: "126px",
                    color: showError ? "#CC4A31" : "black",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineheight: "20px",
                }}
            />
            <Table.Cell
                content={BADGE_STATUS_TASKS[data.status]}
                style={{ maxWidth: "106px" }}
            />
            <Table.Cell
                content={render_badge_action_document_by_id(
                    data?.document_action
                )}
                style={{ maxWidth: "160px" }}
            />
            <Table.Cell
                content={
                    <Flex gap="gap.smaller" vAlign="center">
                        <Text
                            weight="regular"
                            truncate={true}
                            wrap={false}
                            style={{
                                width: "520px",
                                fontWeight: 600,
                                textTransform: "capitalize",
                                lineHeight: "20px",
                                fontSize: "14px",
                            }}
                        >
                            {data.name}
                        </Text>
                    </Flex>
                }
                style={{
                    maxWidth: "551px",
                }}
            />
            <Table.Cell
                content={
                    <Badge appearance="filled" color="important">
                        {data.category.name}
                    </Badge>
                }
                style={{ maxWidth: "171px" }}
            />
            <Table.Cell
                content={
                    <Flex gap="gap.small" vAlign="center">
                        <Avatar
                            name={data?.creator?.name}
                            image={data?.creator?.avatar}
                        />
                        <AvatarName>{data?.creator?.name}</AvatarName>
                    </Flex>
                }
                style={{ maxWidth: "200px" }}
            />
        </Table.Row>
    );
};

export default TaskRow;

import React from "react";
import Slot from "../Slot";

const ShowSlotsTimeClock = ({ useTimerClockSlots = [] }) => {
    const data = useTimerClockSlots.at(0).at(1);
    const shifts = data?.shifts;

    if (!shifts) return null;

    return shifts.map((shift, index) => {
        return (
            <Slot
                status={data.is_paused ? "clocktime_paused" : "clocktime_start"}
                start_time={shift.start_time}
                end_time={shift.end_time}
                duration={shift.duration_str ?? "--h--"}
                isSlotsTimeClock={true}
                isPaused={data.is_paused}
                isOnsite={shift.is_on_site}
                isLastItem={index === shifts.length - 1}
            />
        );
    });
};

export default ShowSlotsTimeClock;

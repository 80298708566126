import React from "react";
import {
    Flex,
    InfoIcon,
    Skeleton,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";

const BadgeMonthDeclaration = ({
    total,
    isLoading,
    normal_hours_count = null,
    showHourSign = true,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    let sign = parseFloat(total) >= 0 ? "+" : "";

    if (isLoading) {
        return (
            <Flex>
                <SkeletonStyle animation="pulse">
                    <Skeleton.Line width="60px" height="24px" />
                </SkeletonStyle>
            </Flex>
        );
    }

    return (
        <Wrapper sign={sign} vAlign="center">
            <WrapperText
                content={sign + total + (showHourSign ? " h" : "")}
                sign={sign}
            />
            {normal_hours_count !== 0 && normal_hours_count !== null && (
                <Tooltip
                    content={
                        <Flex column styles={{ width: "fit-content" }}>
                            <Text
                                content={`${t(
                                    "home.footer.contract_hour_by_week"
                                )} : ${normal_hours_count} h`}
                            />
                            <Text content={`${t("home.footer.rappel")}`} />
                        </Flex>
                    }
                    pointing
                    subtle={false}
                    trigger={<InfoIconStyle outline size="small" sign={sign} />}
                    position="below"
                />
            )}
        </Wrapper>
    );
};

export default BadgeMonthDeclaration;

const InfoIconStyle = styled(InfoIcon)`
    color: ${({ sign }) => (sign === "+" ? "#13a10e" : "#C4314B")};
`;

const WrapperText = styled(Text)`
    color: ${({ sign }) => (sign === "+" ? "#13a10e" : "#C4314B")};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
`;

const Wrapper = styled(Flex)`
    background: ${({ sign }) => (sign === "+" ? "#e7f2da" : "#FCF4F6")};
    border-radius: 4px;

    padding: 4px 8px;
    gap: 4px;

    /* min-width: 49px; */
    height: 24px;

    outline: 1px solid ${({ sign }) => (sign === "+" ? "#bdda9b" : "#ffbdcd")};
`;

const SkeletonStyle = styled(Skeleton)`
    border-radius: 4px;

    min-width: 49px;
    height: 24px;
`;

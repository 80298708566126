import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";

export const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    color: #242424;
`;

export const HeaderText = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #242424;
`;
export const CardTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #242424;
`;

export const CardExpense = styled(Flex)`
    background-color: white;
    /* margin: 0 24px; */
    justify-content: center;
    height: fit-content;
    /* outline: 3px solid yellow; */
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    /* width: clamp(300px, 20vw, 451px); */
    /* width: 451px; */
    @media ${DEVICE.mobileS} {
        /* margin: 0px; */
        padding: 16px 8px;
        margin: 16px 8px;
        /* width: 300px; */
    }
    @media ${DEVICE.mobileM} {
        /* margin: 16px 8px; */
        /* border: 3px solid orange; */
    }
    @media ${DEVICE.mobileL} {
        /* margin: 16px 8px; */
        /* border: 3px solid purple; */
        padding: 16px 8px;
    }
    @media ${DEVICE.tablet} {
        /* margin: 0 24px; */
        /* border: 3px solid red; */
    }
    @media ${DEVICE.laptop} {
        margin: 0px;

        /* border: 3px solid red; */
        padding: 24px 24px 40px 24px;
    }
    @media ${DEVICE.laptopL} {
        margin: 0px;
        /* width: 451px; */
        /* border: 3px solid red; */
        padding: 24px 24px 40px 24px;
    }
`;

export const Header = styled(Flex)`
    flex-direction: row;

    @media ${DEVICE.mobileS} {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const MainContainer = styled(Flex)`
    max-width: 1440px;
    margin: 0 auto;

    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 0px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
        gap: 0px;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        /* align-items: center; */
        padding: 8px;
        gap: 24px;
    }
`;

export const HistoriqueCard = styled(Flex)`
    background-color: white;
    /* margin: 0 24px; */
    justify-content: center;
    height: fit-content;
    /* outline: 3px solid yellow; */
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    /* width: clamp(300px, 20vw, 551px); */
    max-width: 451px;
    @media ${DEVICE.mobileS} {
        /* margin: 0px; */
        padding: 16px 8px;
        /* margin: 16px 8px; */
        /* width: 300px; */
    }
    @media ${DEVICE.mobileM} {
        /* margin: 16px 8px; */
        /* border: 3px solid orange; */
    }
    @media ${DEVICE.mobileL} {
        /* margin: 16px 8px; */
        /* border: 3px solid purple; */
        /* padding: 16px 8px; */
    }
    @media ${DEVICE.tablet} {
        /* margin: 0 24px; */
        /* border: 3px solid red; */
    }
    @media ${DEVICE.laptop} {
        /* margin: 0px; */

        /* border: 3px solid red; */
        padding: 24px 24px 40px 24px;
    }
    @media ${DEVICE.laptopL} {
        margin: 0px;
        /* width: 451px; */
        /* border: 3px solid red; */
        padding: 24px 24px 40px 24px;
    }
`;

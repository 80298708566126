import { useAuthDetailStore } from "../stores/useAuthStore";

export const isDelegatedToRole = (delegated_role) => {
    try {
        const detail = useAuthDetailStore.getState().detail;
        const assigned_permissions = detail.assigned_permissions;
        const isAssigned = assigned_permissions.some((role) =>
            delegated_role.includes(role.type.name)
        );
        return isAssigned;
    } catch (error) {
        console.error("🚀 DEV : ", error);
        return false;
    }
};

import React from "react";
import styled from "styled-components";
import {
    Attachment,
    FilesPdfColoredIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import { ICON_FROM_EXTENSION } from "../../../../../utils/functions";
import {
    file_size_format,
    formatFileSize,
} from "../../../../../constants/maths";
import moment from "moment";

const IdentityDocuments = ({ identity_documents }) => {
    return (
        <Flex column gap="gap.medium">
            <Title content="Documents d’identité" />
            <Flex column gap="gap.medium">
                {!identity_documents?.length ? (
                    <Text content="-" />
                ) : (
                    identity_documents?.map((document) => {
                        const icon = ICON_FROM_EXTENSION(document?.name);
                        return (
                            <Attachment
                                icon={icon}
                                header={document?.display_name}
                                description={`${formatFileSize(
                                    document?.filesize,
                                    2
                                )} - ${moment(document?.modified_at).format(
                                    "DD/MM/YYYY"
                                )}`}
                                actionable
                            />
                        );
                    })
                )}
            </Flex>
        </Flex>
    );
};

export default IdentityDocuments;

const Title = styled(Text)`
    color: #242424;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
`;

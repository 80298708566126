import { Box, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

export default function LinearProgressBar(props) {
    return (
        <MainProgressBarDiv vAlign="center" hAlign="center" gap="gap.medium">
            <Percentage
                style={{ fontSize: 16 }}
                content={`${props.percent} %`}
            />

            <EmptyProgressBar style={{ width: "100%" }}>
                <FillingProgressBar
                    style={{
                        left: props.percent - 100 + "%",
                        transition: "3s",
                    }}
                />
            </EmptyProgressBar>
        </MainProgressBarDiv>
    );
}

const EmptyProgressBar = styled(Box)`
    background-color: #e7e4da;
    border-radius: 4px;
    height: 13px;
    overflow: hidden;
    position: relative;
`;
const FillingProgressBar = styled(Box)`
    /* background-image: linear-gradient(to right, #5c5bc7, #9c77d3); */
    background-color: #5c5bc7;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    position: absolute;
`;
const Percentage = styled(Text)`
    color: #5c5bc7;
    font-size: 46px;
    width: 80px;
    font-weight: bold;
`;
const MainProgressBarDiv = styled(Flex)`
    width: 200px;
`;

import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const AmountExpense = styled(Text)`
    color: #616161;
    text-align: center;

    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
`;

export const Amount = styled(Text)`
    color: #424242;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
`;

export const RateExpenseTitle = styled(Text)`
    color: #616161;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const Rate = styled(Text)`
    color: #242424;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;

export const CustomInput = styled(Flex)`
    height: 32px;
`;

export const CustomInputCurrency = styled(Text)`
    color: #c7c7c7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

export const CustomInputAmount = styled(Text)`
    color: #c7c7c7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

export const WrapperCurrency = styled(Flex)`
    display: flex;
    width: 110px;
    padding: 0px 12px;
    border-radius: 4px 0px 0px 4px;
    border-right: 1px solid #e0e0e0;
    background: #f0f0f0;
`;

export const WrapperAmount = styled(WrapperCurrency)`
    border-right: 0px;
    border-radius: 0px 4px 4px 0px;
`;

export const Container = styled(Flex)`
    margin-bottom: 15px;
`;

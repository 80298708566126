import React, { useEffect, useReducer } from "react";
import { TextYearName, Wrapper } from "./index.style";
import {
    Button,
    ChevronEndIcon,
    ChevronStartIcon,
    Flex,
} from "@fluentui/react-northstar";
import {
    ACTION_TYPES_SLIDER_YEAR,
    INIT_SLIDE_YEAR,
    reducerSliderYear,
} from "../../reducers/reducer.slider_year";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import moment from "moment";

const SliderYears = ({ onChangeYear = () => null }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const current_year = Number(moment().format("YYYY"));
    const [state, dispatch] = useReducer(reducerSliderYear, INIT_SLIDE_YEAR);

    useEffect(() => {
        onChangeYear({
            year: state.year,
        });
    }, [state.year]);

    return (
        <Wrapper vAlign="center" gap="gap.small">
            <Flex>
                <TextYearName
                    content={t("v2.year_tracking.slider.year", {
                        year: state.year,
                    })}
                />
            </Flex>
            <Flex gap="gap.small">
                <Button
                    icon={<ChevronStartIcon />}
                    iconOnly
                    flat
                    onClick={() => {
                        return dispatch({
                            type: ACTION_TYPES_SLIDER_YEAR.decrement,
                        });
                    }}
                />
                <Button
                    icon={<ChevronEndIcon />}
                    iconOnly
                    flat
                    disabled={Number(state.year) >= current_year}
                    onClick={() => {
                        return dispatch({
                            type: ACTION_TYPES_SLIDER_YEAR.increment,
                        });
                    }}
                />
            </Flex>
        </Wrapper>
    );
};

export default SliderYears;

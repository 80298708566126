import { Avatar, Flex, Skeleton, Text } from "@fluentui/react-northstar";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { FlexProfileSection } from "../styles/responsive";
import { DEVICE } from "../../../constants/responsive";
import { ROUTES } from "../../../constants/routes";
import BadgeIcon from "../../employee_folder/components/BadgeIcon";
import { PeopleSwapFilled } from "@fluentui/react-icons";
import { isDelegatedToRole } from "../../../utils/permissions";

export const ProfileSection = ({ me, isLoading }) => {
    const navigate = useNavigate();

    const isDelegated = isDelegatedToRole(["time", "expense", "leave"]);

    return (
        <FlexProfileSection column>
            <TitleStyle content="Mon espace personnel" />
            <ProfileCard
                vAlign="center"
                styles={{ gap: "24px" }}
                onClick={() =>
                    navigate(`${ROUTES.employee_folder.custom_route(me?.id)}`)
                }
            >
                <Flex>
                    {isLoading ? (
                        <Skeleton animation="pulse">
                            <Skeleton.Avatar size="larger" />
                        </Skeleton>
                    ) : (
                        <Avatar
                            image={me?.avatar}
                            size="larger"
                            name={`${me?.first_name} ${me?.last_name}`}
                        />
                    )}
                </Flex>
                <Flex vAlign="start" gap="gap.medium" column>
                    <Flex gap="gap.small" column>
                        {isLoading ? (
                            <Skeleton animation="pulse">
                                <Skeleton.Line size="larger" height="22px" />
                            </Skeleton>
                        ) : (
                            <FullName
                                content={`${me?.first_name} ${me?.last_name}`}
                            />
                        )}

                        {isLoading ? (
                            <Skeleton animation="pulse">
                                <Skeleton.Line
                                    size="larger"
                                    height="18px"
                                    width="167px"
                                />
                            </Skeleton>
                        ) : (
                            <JobTitleText
                                content={
                                    me?.job_title ? (
                                        `${me?.job_title} `
                                    ) : (
                                        <Text
                                            styles={{ color: "gray" }}
                                            content={"Non renseigné"}
                                        />
                                    )
                                }
                            />
                        )}
                    </Flex>

                    {isDelegated && (
                        <BadgeIcon
                            icon={<PeopleSwapFilled />}
                            content={"Responsabilité(s) déléguée(s)"}
                        />
                    )}
                </Flex>
            </ProfileCard>
        </FlexProfileSection>
    );
};

const ProfileCard = styled(Flex)`
    padding: 16px 38px 16px 16px;
    gap: 24px;
    background: #ffffff;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
    :hover {
        cursor: pointer;
        box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
            0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11);
        transition: all 0.2s;

        .link {
            font-weight: 600;
            transition: all 0.2s;
        }
    }

    @media ${DEVICE.mobileS} {
        width: auto;
    }
    @media ${DEVICE.laptop} {
        /* width: auto; */
    }
`;

const TitleStyle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #242424;
`;
const FullName = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #242424;
`;
const JobTitleText = styled(Text)`
    width: 167px;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ShowMyProfilLink = styled(Flex)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0067b8;
    cursor: pointer;
    align-items: center;
`;

import React from "react";
import { Flex, Input, Loader, Text } from "@fluentui/react-northstar";
import { useQuery } from "react-query";
import styled from "styled-components";
import UserSelection from "../../Cards/UserSelection";
import { QUERY_KEY } from "../../../constants";
import { getManagers } from "../../../../../api/user";
import { useState } from "react";
import { debounce } from "../../../../../utils/global";

const _set_selection_manager = ({
    set_managers_filtred,
    setManagers,
    email_selected,
}) => {
    if (!email_selected) {
        return console.log("email_selected is invalid email");
    }
    if (typeof setManagers !== "function") {
        return console.log("setManagers is not function");
    }
    setManagers((state) => {
        const new_state = state.map((manager) => {
            return {
                ...manager,
                selected: manager.email === email_selected,
            };
        });
        return new_state;
    });
    return set_managers_filtred((state) => {
        const new_state = state.map((manager) => {
            return {
                ...manager,
                selected: manager.email === email_selected,
            };
        });
        return new_state;
    });
};

const DialogUpdateManagerContent = ({ managers, setManagers }) => {
    const [managers_filtred, set_managers_filtred] = useState(managers);

    const filter_managers = (value) => {
        const filtred_manager = managers?.filter(
            (manager) =>
                manager?.last_name?.toLowerCase().search(value) !== -1 ||
                manager?.first_name?.toLowerCase().search(value) !== -1
        );
        return set_managers_filtred(filtred_manager);
    };

    const onChange = debounce((value) => {
        return filter_managers(value);
    }, 300);

    return (
        <Flex fill column>
            <SubHeader
                content={"Liste des personnes avec le rôle de manager"}
            />
            <Flex>
                <InputSearch
                    placeholder="Rechercher une personne"
                    onChange={(_, i) => {
                        return onChange(i?.value);
                    }}
                    fluid
                />
            </Flex>
            <Wrapper column>
                {managers_filtred?.map((manager) => {
                    return (
                        <UserSelection
                            avatar={manager.avatar}
                            fullName={
                                manager.first_name + " " + manager.last_name
                            }
                            jobTitle={manager.job_title ?? " pas de job_title"}
                            selected={manager.selected}
                            onClick={() =>
                                _set_selection_manager({
                                    setManagers,
                                    set_managers_filtred,
                                    email_selected: manager.email,
                                })
                            }
                        />
                    );
                })}
            </Wrapper>
        </Flex>
    );
};

export default DialogUpdateManagerContent;

const Wrapper = styled(Flex)`
    height: 300px;
    overflow-y: scroll;
    border-bottom: 1px solid #d1d1d1;
`;

const InputSearch = styled(Input)`
    margin-block: 16px;
`;

const SubHeader = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    padding-block: 16px;
    border-bottom: 1px solid #d1d1d1;
`;

import React from "react";
import {
    Box,
    Button,
    Flex,
    Image,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import SideTitle from "./Texts/Titles";
import { DataPie20Filled } from "@fluentui/react-icons";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { CardExpense } from "../styles";
import { I18N_NS_EXPENSE, QUERY_KEY } from "../constants";
import { useQuery } from "react-query";
import moment from "moment";
import { _api_get_my_chart, _api_get_my_teams_chart } from "../api";
import { get_symbol_currency } from "../functions";

import ExpenseEmptyIcon from "../../../assets/illustrations/expenses/expense_empty.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import LegendChart from "./LegendChart";
import { format_number } from "../../../constants/maths";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartSection = ({ manager = false, showLinkRedirection = false }) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    const { data: chart, isLoading } = useQuery(
        [QUERY_KEY + "::my_chart_or_teams"],
        () => {
            if (manager) {
                return _api_get_my_teams_chart();
            } else {
                return _api_get_my_chart();
            }
        }
    );

    if (isLoading) {
        return <Loader content={"chargement des données Chart"} />;
    }

    const data = {
        labels: chart?.map((el) => el?.name),
        datasets: [
            {
                label: "Total",
                data: chart?.map((el) => el?.total),
                backgroundColor: chart?.map((el) => el?.color),
                circumference: 360,
                cutout: 70,
            },
        ],
    };

    const total = format_number(
        chart
            ?.map((el) => el?.total)
            .reduce((prev, current) => prev + current, 0)
    );

    return (
        <CardExpense
            fill
            space={"between"}
            gap="gap.medium"
            column
            hAlign="center"
        >
            <SideTitle
                content={
                    <Trans t={t} i18nKey={"home.chart.title"}>
                        Dépenses sur l’année {{ year: moment().format("YYYY") }}
                    </Trans>
                }
                icon={<DataPie20Filled />}
            />

            <Flex>
                {chart?.length ? (
                    <Flex column gap="gap.large" hAlign="center">
                        <Flex
                            styles={{
                                position: "relative",
                                width: "190px",
                                height: "190px",
                            }}
                            hAlign="center"
                            vAlign="center"
                        >
                            <Doughnut
                                data={data}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: true,
                                    aspectRatio: "1",
                                    plugins: {
                                        legend: false,
                                    },
                                }}
                            />
                            <Total
                                content={
                                    total +
                                    get_symbol_currency(chart.at(0)?.currency)
                                }
                            />
                        </Flex>
                        <Flex wrap gap="gap.medium" styles={{ rowGap: "24px" }}>
                            {chart.map((legend) => {
                                return (
                                    <LegendChart
                                        color={legend.color}
                                        name={legend.name}
                                    />
                                );
                            })}
                        </Flex>
                    </Flex>
                ) : (
                    <Flex column fill gap="gap.large" hAlign="center">
                        <Image src={ExpenseEmptyIcon} />
                        <EmptyExpenseTitle content="Aucune note de frais" />
                        {showLinkRedirection ? (
                            <Link to={ROUTES.expenses.home}>
                                <Button
                                    content="Accéder aux notes de frais"
                                    text
                                    styles={{
                                        textDecoration: "underline",
                                        color: "#5B5FC7",
                                    }}
                                />
                            </Link>
                        ) : (
                            <Text content="" />
                        )}
                    </Flex>
                )}
            </Flex>
        </CardExpense>
    );
};

export default ChartSection;

const EmptyExpenseTitle = styled(Text)`
    color: #616161;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
`;

const Total = styled(Text)`
    font-weight: 700;
    font-size: 24px;

    position: absolute;

    color: #242424;
`;

import { Button, Flex, Input, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const DependentsPersonsFields = ({
    register,
    index,
    person,
    remove_dependents_person,
}) => {
    return (
        <Flex column gap="gap.small">
            <Flex vAlign="center" space="between">
                <Title content={"Personne à charge " + (index + 1)} />
                <Button
                    content={{
                        content: "supprimer",
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        remove_dependents_person(index);
                    }}
                    styles={{
                        color: "#C4314B",
                        textDecoration: "underline",
                    }}
                    text
                    size="small"
                />
            </Flex>
            <FlexStyle gap="gap.small" column>
                <Flex gap="gap.small" fill>
                    <Input
                        {...register(`dependents_person.${index}.first_name`)}
                        label="Nom"
                        defaultValue={person?.first_name}
                        fluid
                    />
                    <Input
                        {...register(`dependents_person.${index}.last_name`)}
                        label="Prénom"
                        defaultValue={person?.last_name}
                        fluid
                    />
                </Flex>
                <Flex>
                    <Input
                        {...register(`dependents_person.${index}.birthdate`)}
                        label="Date de naissance"
                        defaultValue={person?.birthdate}
                        type="date"
                    />
                </Flex>
            </FlexStyle>
        </Flex>
    );
};

export default DependentsPersonsFields;

const Title = styled(Text)`
    color: #0078d4;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
`;

const FlexStyle = styled(Flex)`
    position: relative;
    padding-left: 8px;
    ::before {
        content: "";
        position: absolute;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: #0078d4;
        border-radius: 2px;
    }
`;

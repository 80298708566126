export const TYPES_OPERATION_AJUSTMENT = [
    { id: "INITIALIZING", name: "Initialisation par le RH" },
    { id: "MANUAL_ADD", name: "Ajout par le RH" },
    { id: "PERIOD_INCREMENT", name: "Cumul de période" },
    { id: "MANUAL_REMOVE", name: "Retrait par le RH" },
    { id: "LEAVE_REQUESTED", name: "Absence prise" },
    { id: "LEAVE_DELETED", name: "Absence Supprimée" },
];

export const TYPES_STATUS_LEAVE = [
    {
        id: 1,
        name: "En attente",
    },
    {
        id: 2,
        name: "Validées",
    },
    {
        id: 4,
        name: "Refusées",
    },
];

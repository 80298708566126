import React, { useEffect, useLayoutEffect } from "react";
import {
    AddIcon,
    Alert,
    Box,
    Button,
    Flex,
    FlexItem,
    Input,
    Text,
    TrashCanIcon,
} from "@fluentui/react-northstar";

import styled from "styled-components";

import { useFieldArray, useFormContext } from "react-hook-form";
import { DEVICE } from "../../../../../constants/responsive";

export default function DependentPerson() {
    const { register, control, getValues } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: "dependents_person", // unique name for your Field Array
        control,
    });

    return (
        <Flex column gap="gap.small" styles={{ marginTop: "16px" }}>
            {fields.map((data, index) => (
                <FlexWrapper column gap="gap.small" key={index}>
                    <Flex gap="gap.large" vAlign="center">
                        <Title>Personnes à charge {index + 1}</Title>
                        <Button
                            text
                            iconOnly
                            icon={<TrashCanIcon style={{ color: "red" }} />}
                            title={"Close"}
                            onClick={(e) => remove(index)}
                        />
                    </Flex>
                    <Responsive gap="gap.small">
                        <Input
                            name={`dependents_person.${index}.name`}
                            {...register(`dependents_person.${index}.name`)}
                            inverted
                            fluid
                            defaultValue={getValues(
                                `dependents_person.${index}.name`
                            )}
                            label={<Label>Nom & Prénom - {data.name} </Label>}
                        />
                        <Input
                            name={`dependents_person.${index}.birthdate`}
                            {...register(
                                `dependents_person.${index}.birthdate`
                            )}
                            type="date"
                            inverted
                            fluid
                            defaultValue={getValues(
                                `dependents_person.${index}.birthdate`
                            )}
                            label={<Label>Date de naissance</Label>}
                        />
                    </Responsive>
                </FlexWrapper>
            ))}
            <Button
                text
                primary
                icon={<AddIcon />}
                iconPosition="before"
                onClick={(e) => {
                    e.preventDefault();
                    append({
                        name: "",
                        birthdate: "",
                    });
                    return;
                }}
                content={"Ajouter une personne a charge"}
            />
        </Flex>
    );
}

const Title = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: #242424;
`;

const Label = styled(Text)`
    font-size: 12px;
    font-style: normal;
    padding: 4px;
    color: #616161;
`;

const FlexWrapper = styled(Flex)``;

const Responsive = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 15px;
    }
    @media ${DEVICE.mobileM} {
        flex-direction: column;
        gap: 15px;
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        gap: 25px;
    }
`;

import { Skeleton, TableCell, TableRow } from "@fluentui/react-northstar";
import React from "react";

const SkeletonLeaveList = ({ containAvatarColumn = false }) => {
    return (
        <Skeleton animation="pulse">
            {new Array(10).fill("").map((row) => {
                return (
                    <TableRow
                        styles={{
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        {containAvatarColumn && (
                            <TableCell
                                styles={{
                                    alignItems: "center",
                                }}
                            >
                                <Skeleton.Line width="150px" height="32px" />
                            </TableCell>
                        )}
                        <TableCell
                            styles={{
                                alignItems: "center",
                            }}
                        >
                            <Skeleton.Line width="150px" height="32px" />
                        </TableCell>
                        <TableCell
                            styles={{
                                justifyContent: "end",
                                alignItems: "center",
                            }}
                        >
                            <Skeleton.Line width="150px" height="32px" />
                        </TableCell>
                        <TableCell
                            styles={{
                                justifyContent: "start",
                                alignItems: "center",
                            }}
                        >
                            <Skeleton.Line width="150px" height="32px" />
                        </TableCell>
                        <TableCell
                            styles={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Skeleton.Line width="150px" height="32px" />
                        </TableCell>
                    </TableRow>
                );
            })}
        </Skeleton>
    );
};

export default SkeletonLeaveList;

import React, { useCallback, useEffect, useState } from "react";

let timeoutId;

const useCopyToClipboard = (text) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = useCallback(async () => {
        try {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            if ("clipboard" in navigator) {
                await navigator.clipboard.writeText(text);
            } else {
                document.execCommand("copy", true, text);
            }
            setCopied(true);
            timeoutId = setTimeout(() => setCopied(false), 1500);
        } catch (err) {
            console.log({ err });
        }
    }, [text]);

    useEffect(
        () => () => {
            setCopied(false);
            clearTimeout(timeoutId);
        },
        []
    );

    return [copied, copyToClipboard];
};

export default useCopyToClipboard;

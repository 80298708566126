import React from "react";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import { PlayArrow } from "@mui/icons-material";
import { styled } from "@mui/material";
import { PauseFilled, StopFilled } from "@fluentui/react-icons";
import { DEVICE } from "../../../constants/responsive";

const MenuTimeClock = ({
    isStartedToday,
    isStopped,
    isPaused,
    isLoading = {
        stop: false,
        start: false,
        pause: false,
    },
    onClickStart = () => null,
    onClickStop = () => null,
    onClickPause = () => null,
}) => {
    return (
        <Container>
            {/* POINTER */}
            {((!isStartedToday && !isPaused) || isStopped) && (
                <Flex gap="gap.small" vAlign="center">
                    <Label
                        $bg={"#E7F2DA"}
                        $color={"#237B4B"}
                        $border={"#BDDA9B"}
                    >
                        Pointer l'entrée
                    </Label>
                    <Button
                        circular
                        icon={<PlayArrow style={{ fill: "#237B4B" }} />}
                        styles={{
                            ...styles.button,
                            backgroundColor: "#E7F2DA",
                        }}
                        loading={isLoading.start}
                        onClick={onClickStart}
                    />
                </Flex>
            )}
            {/* PAUSE & SORTIE */}
            {isStartedToday && !isPaused && !isStopped && (
                <Flex column hAlign="end" gap="gap.small">
                    {/* PAUSE */}
                    <Flex gap="gap.small" vAlign="center">
                        <Label
                            $bg={"#FBF6D9"}
                            $color={"#CC4A31"}
                            $border={"#EDC2A7"}
                        >
                            Pause
                        </Label>
                        <Button
                            circular
                            icon={<PauseFilled style={{ color: "#CC4A31" }} />}
                            styles={{
                                ...styles.button,
                                backgroundColor: "#FBF6D9",
                            }}
                            loading={isLoading.pause}
                            onClick={onClickPause}
                        />
                    </Flex>
                    {/* STOP */}
                    <Flex gap="gap.small" vAlign="center">
                        <Label
                            $bg={"#FCF4F6"}
                            $color={"#C4314B"}
                            $border={"#F3D6D8"}
                        >
                            Pointer la sortie
                        </Label>
                        <Button
                            circular
                            icon={<StopFilled style={{ color: "#C4314B" }} />}
                            styles={{
                                ...styles.button,
                                backgroundColor: "#FCF4F6",
                            }}
                            loading={isLoading.stop}
                            onClick={onClickStop}
                        />
                    </Flex>
                </Flex>
            )}
            {/* REPRISE & SORTIE */}
            {isStartedToday && isPaused && (
                <Flex column hAlign="end" gap="gap.small">
                    {/* REPRISE */}
                    <Flex gap="gap.small" vAlign="center">
                        <Label
                            $bg={"#E7F2DA"}
                            $color={"#237B4B"}
                            $border={"#BDDA9B"}
                        >
                            Reprise
                        </Label>
                        <Button
                            circular
                            icon={<PlayArrow style={{ fill: "#237B4B" }} />}
                            styles={{
                                ...styles.button,
                                backgroundColor: "#E7F2DA",
                            }}
                            loading={isLoading.pause}
                            onClick={onClickPause}
                        />
                    </Flex>
                    {/* STOP */}
                    <Flex gap="gap.small" vAlign="center">
                        <Label
                            $bg={"#FCF4F6"}
                            $color={"#C4314B"}
                            $border={"#F3D6D8"}
                        >
                            Pointer la sortie
                        </Label>
                        <Button
                            circular
                            icon={<StopFilled style={{ color: "#C4314B" }} />}
                            styles={{
                                ...styles.button,
                                backgroundColor: "#FCF4F6",
                            }}
                            loading={isLoading.stop}
                            onClick={onClickStop}
                        />
                    </Flex>
                </Flex>
            )}
        </Container>
    );
};

export default MenuTimeClock;

const Container = styled(Flex)`
    transition: all 0.3s;
    @media ${DEVICE.mobileS} {
        padding-right: 5px;
    }
    @media ${DEVICE.laptop} {
        padding-right: 16px;
    }
`;
const Label = styled(Text)`
    border-radius: 4px;

    border: 1px solid ${({ $border }) => ($border ? $border : "inherit")};
    background: ${({ $bg }) => ($bg ? $bg : "inherit")};
    color: ${({ $color }) => ($color ? $color : "inherit")};

    /* Elevation/Light/Shadow 04 */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
        0px 0px 2px 0px rgba(0, 0, 0, 0.12);

    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px; /* 141.667% */
    padding-inline: 12px;
    padding-block: 4px;
`;
const styles = {
    button: {
        width: "36px",
        height: "36px",
    },
};

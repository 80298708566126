import { Flex, Text } from "@fluentui/react-northstar";
import styled, { css } from "styled-components";

export const Container = styled(Flex)`
    background: repeating-linear-gradient(
        45deg,
        ${({ striped_color }) => striped_color},
        ${({ striped_color }) => striped_color} 2px,
        ${({ background_color, is_pending_leave }) =>
                is_pending_leave
                    ? background_color + "33"
                    : background_color + "50"}
            2px,
        ${({ background_color, is_pending_leave }) =>
                is_pending_leave
                    ? background_color + "33"
                    : background_color + "50"}
            14px
    );
    ${({ is_pending_leave }) =>
        is_pending_leave
            ? css`
                  &::after {
                      content: "";
                      width: 100%;
                      position: absolute;
                      height: 100%;
                      background: repeating-linear-gradient(
                          -45deg,
                          white,
                          white 2px,
                          transparent 3px,
                          transparent 8px
                      );
                  }
              `
            : css``}
    width: 140px;
    height: ${({ is_half_day }) => (is_half_day ? "100px" : "200px")};
`;

export const Badge = styled(Flex)`
    padding: 4px;
    z-index: 2;
    gap: 4px;
    border-radius: 6px;
    background: ${({ striped_color }) => striped_color};
    height: fit-content;
    /* shadow-2 */
    box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13),
        0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11);
`;

export const BadgeText = styled(Text)`
    color: #242424;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

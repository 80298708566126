import create from "zustand";
import produce from "immer";

export const useStepperContext = create((set) => ({
    firstStep: {
        nom: "",
        prenom: "",
        email: "",
        telephone: "",
        date_arrivee: null,
        date_depart: null,
        service: null,
        manager: "",
        type_contrat: null,
        job_title: "",
        globalError: "",
        isValidForm: false, // pour afficher le button suivant sur la 1ere etape
        successData: null, // garder les informations de l'utilisateur lors du clique suivant de la 1ere etape
        isUserFromId: false, // True si un Id utilisateur est envoyé en paramètre
    },
    settingsStep: {
        groupeOffice: [],
        roles: [],
        globalError: "",
    },
    resourceStep: {
        resource: [],
        globalError: "",
    },
    tasks: {
        templates: [], // les templates selectionnées
        selectedTasks: [], // les tâches selectionnées avant envoie
        manualTasks: [], // les tâches ajoutées manuellement.
        isModifyUser: false, // voir si je suis entrain de updaté un collaborator.
        iModifiedTaskSoRefresh: 0,
        globalError: "", // affichage d'erreur specifique a la section Task.
    },
    setFirstStep: ({ ...rest }) =>
        set(
            produce((state) => {
                state.firstStep = { ...state.firstStep, ...rest };
            })
        ),
    setSettingsStep: ({ ...rest }) =>
        set(
            produce((state) => {
                state.settingsStep = { ...state.settingsStep, ...rest };
            })
        ),
    setResourceStep: ({ ...rest }) =>
        set(
            produce((state) => {
                state.resourceStep = { ...state.resourceStep, ...rest };
            })
        ),
    setTasksStep: ({ ...rest }) =>
        set(
            produce((state) => {
                state.tasks = { ...state.tasks, ...rest };
            })
        ),
    clearStepsContext: () =>
        set(
            produce((state) => {
                state.firstStep = {
                    nom: "",
                    prenom: "",
                    email: "",
                    telephone: "",
                    date_arrivee: null,
                    date_depart: null,
                    service: null,
                    manager: null,
                    type_contrat: null,
                    job_title: "",
                    globalError: "",
                    successData: null,
                    isValidForm: false,
                    isUserFromId: false,
                };
                state.settingsStep = {
                    groupeOffice: [],
                    roles: [],
                    globalError: "",
                };
                state.resourceStep = {
                    resource: [],
                    globalError: "",
                };
                state.tasks = {
                    templates: [], // les templates selectionnées
                    selectedTasks: [], // les tâches selectionnées avant envoie
                    manualTasks: [], // les tâches ajoutées manuellement.
                    isModifyUser: false,
                    iModifiedTaskSoRefresh: 0,
                    globalError: "", // affichage d'erreur specifique a la section Task.
                };
            })
        ),
}));

import React, { useState } from "react";
import {
    AddIcon,
    Box,
    Button,
    Dialog,
    Flex,
    FlexItem,
    InfoIcon,
    TableAddIcon,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import { useQuery } from "react-query";
import styled from "styled-components";
import { api_balances_detail } from "../../api";
import BalanceSection from "./BalanceSection";
import Projection from "./Projection";
import AbsenceRequest from "../absences/AbsenceRequest";

const Balances = ({ showDialoge, refetchPersonnalCall, subHeader }) => {
    const [showDialogAddLeave, setShowDialogAddLeave] = useState({
        visible: false,
        start_date: null,
        end_date: null,
    });

    const { data } = useQuery(
        ["api-balances-details"],
        () => {
            return api_balances_detail();
        },
        {
            refetchOnWindowFocus: true,
        }
    );

    return (
        <Container column fill>
            <FlexItem>
                <Flex space="between">
                    <FlexItem>
                        <Flex vAlign="center" gap="gap.small">
                            <Title content={"Mon solde de congés"} />
                            <Tooltip
                                align="start"
                                position="above"
                                autoSize="always"
                                pointing
                                subtle={false}
                                unstable_pinned
                                content={
                                    <Text
                                        content={
                                            <p>
                                                Les demandes en <b>attentes</b>{" "}
                                                ne sont pas décomptées du solde
                                            </p>
                                        }
                                    />
                                }
                                trigger={<InfoIcon outline />}
                            />
                        </Flex>
                    </FlexItem>
                    {/* Projection action  */}
                    <FlexItem>
                        <Flex gap="gap.large">
                            <Dialog
                                cancelButton="Cancel"
                                confirmButton="Confirm"
                                content="Calculer une projection"
                                header="Calculer une projection de congés"
                                trigger={<Projection />}
                            />
                            <AbsenceRequest
                                subHeader={subHeader}
                                showDialogAddLeave={showDialogAddLeave}
                                setShowDialogAddLeave={setShowDialogAddLeave}
                                isForTeams={false}
                                refetchPersonnalCall={refetchPersonnalCall}
                            />
                        </Flex>
                    </FlexItem>
                </Flex>
            </FlexItem>
            {/* balances section statistics */}
            <FlexItem>
                <BalanceSection balances={data} />
            </FlexItem>
        </Container>
    );
};

export default Balances;

const Title = styled(Text)`
    font-size: 24px;
    line-height: 32px;
    /* align: Left;  */
`;
const Container = styled(Flex)`
    height: 188px;
    /* width: 100%; */
    justify-items: center;
    border-radius: 8px;
    background-color: white;
    padding: 16px;
    margin-top: 14px;
    gap: 25px;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
`;

import React, { useState } from "react";
import {
    Attachment,
    Flex,
    Loader,
    LockIcon,
    Text,
    TextArea,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { New16Regular } from "@fluentui/react-icons";
import { ICON_FROM_EXTENSION } from "../../../../utils/functions";
import { file_size_format } from "../../../../constants/maths";
import { useMutation } from "react-query";
import { _api_delete_document, _api_read_document } from "../../api";
import useMutationReadDocument from "../../hooks/useMutationReadDocument";

const BadgeNewDocument = () => {
    return (
        <WrapperNewDocumentBadge>
            <New16Regular />
            <TextNew content="Nouveau" />
        </WrapperNewDocumentBadge>
    );
};

const AllDocument = ({ documents, refetch }) => {
    const [selected_to_delete, setSelectedToDelete] = useState(null);
    const [selected_to_read, setSelectedToRead] = useState(null);

    const { mutate: mutate_delete_document, isLoading } = useMutation((data) =>
        _api_delete_document(data)
    );

    const { mutate: mutate_read_document, isLoading: is_loading_readable } =
        useMutationReadDocument();

    return (
        <Wrapper column gap="gap.medium" fill>
            {documents.map((el, index) => (
                <AttachmentStyle
                    key={index}
                    icon={
                        is_loading_readable && selected_to_read === el?.id ? (
                            <Loader size="small" />
                        ) : (
                            ICON_FROM_EXTENSION(el.filename || el.name)
                        )
                    }
                    header={
                        <Flex
                            vAlign="center"
                            fill
                            space="between"
                            styles={{ maxWidth: "80%" }}
                        >
                            <Text truncated content={el.filename || el.name} />
                            {el?.is_new && <BadgeNewDocument />}
                        </Flex>
                    }
                    description={file_size_format(el.filesize)}
                    actionable
                    action={{
                        icon:
                            isLoading && selected_to_delete === el?.id ? (
                                <Loader />
                            ) : el?.locked ? (
                                <LockIcon />
                            ) : (
                                <TrashCanIcon />
                            ),
                        onClick: (e) => {
                            e.stopPropagation();
                            if (isLoading || el?.locked) {
                                return;
                            }
                            setSelectedToDelete(el?.id);
                            mutate_delete_document(el?.id, {
                                onSuccess: () => {
                                    refetch();
                                },
                            });
                            return;
                        },
                        title: el?.locked
                            ? "Document Protégé"
                            : "Supprimer le document",
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedToRead(el?.id);
                        mutate_read_document(el?.id, {
                            onSuccess: (data) => {
                                window.open(data?.document_url, "_blank");
                                refetch();
                            },
                        });
                    }}
                />
            ))}
        </Wrapper>
    );
};

export default AllDocument;

const TextNew = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

const WrapperNewDocumentBadge = styled(Flex)`
    border-radius: 4px;
    background-color: #ffb900;
    height: 24px;
    padding: 4px 8px;
    gap: 4px;

    position: absolute;
    right: 35px;
    top: 10px;
`;

const AttachmentStyle = styled(Attachment)`
    max-width: 100%;
`;

const Wrapper = styled(Flex)`
    margin-top: 15px;
    padding: 0px 36px 0px 0px;
`;

import React from "react";
import { Avatar, Button, Divider, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import {
    CalendarInfoRegular,
    BuildingBank16Filled,
} from "@fluentui/react-icons";
import BadgeIcon from "../../../employee_folder/components/BadgeIcon";
import moment from "moment";
import { _str_capitalize } from "../../../../utils/functions";
import { useMediaQuery } from "react-responsive";
import { DEVICE_MAX } from "../../../../constants/responsive";

const ShowEmployeeDelegated = ({
    avatar,
    fullname,
    job_title,
    department,
    start_date,
    end_date = null,
    isLoadingInterrupt = false,
    onClickInterrupt = () => null,
    onClickUpdate = () => null,
}) => {
    const isMaxMobileL = useMediaQuery({
        query: DEVICE_MAX.mobileL,
    });

    moment.locale("fr");
    const format_start_date = _str_capitalize(
        moment(start_date).format("dddd D MMMM YYYY")
    );
    const format_end_date = end_date
        ? _str_capitalize(moment(end_date).format("dddd D MMMM YYYY"))
        : null;
    return (
        <Flex column gap="gap.large">
            <Flex column={isMaxMobileL} gap="gap.medium">
                <Flex gap="gap.small" fill column>
                    <Flex gap="gap.small">
                        <div>
                            <Avatar
                                image={avatar}
                                name={fullname}
                                size="large"
                            />
                        </div>
                        <Flex column gap="gap.smaller">
                            <Flex column>
                                <Username content={fullname} />
                                <JobTitle content={job_title} />
                            </Flex>
                            <Flex>
                                <BadgeIcon
                                    icon={
                                        <BuildingBank16Filled color=" #616161" />
                                    }
                                    content={department}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex>
                    <Divider
                        size={1}
                        vertical={!isMaxMobileL}
                        styles={
                            !isMaxMobileL
                                ? styles.divider_vertical
                                : styles.divider_horizontal
                        }
                    />
                </Flex>
                <Flex fill vAlign="center" gap="gap.small">
                    <Flex>
                        <CalendarInfoRegular
                            fontSize={20}
                            style={{ color: "var(--color-primary)" }}
                        />
                    </Flex>
                    <Flex column fill>
                        <Flex fill space="between">
                            <DatesFont>Du</DatesFont>
                            <DatesFont>{format_start_date}</DatesFont>
                        </Flex>
                        {end_date && (
                            <Flex fill space="between">
                                <DatesFont>Au</DatesFont>
                                <DatesFont>{format_end_date}</DatesFont>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Flex>
            {/* buttons */}
            <Flex column gap="gap.small">
                <Button
                    flat
                    fluid
                    primary
                    content="Modifier la délégation"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onClickUpdate();
                    }}
                />
                <ButtonDelete
                    flat
                    fluid
                    content="Interrompre la délégation"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onClickInterrupt();
                    }}
                    loading={isLoadingInterrupt}
                />
            </Flex>
        </Flex>
    );
};

export default ShowEmployeeDelegated;

export const Username = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;

export const JobTitle = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px; /* 142.857% */
`;

export const DatesFont = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

export const ButtonDelete = styled(Button)`
    color: var(--color-danger);
`;

const styles = {
    divider_vertical: { height: "75px", minWidth: "5px" },
    divider_horizontal: {
        minHeight: "1px",
        width: "100%",
    },
};

import React from "react";
import { Button, Checkbox, Flex, Text } from "@fluentui/react-northstar";
import {
    ChevronRight16Filled,
    ChevronLeft16Regular,
} from "@fluentui/react-icons";
import styled from "styled-components";
import moment from "moment";

import { CustomBadge } from "../../../../components/Badge";
import { Container, ContainerFluid } from "../../../../common/styles";
import CardDay from "../cards/CardDay";
import WeekStatus from "../badges/WeekStatus";
import { formatHour } from "../../functions";
import { useShowWeekend } from "../../stores";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING, SLIDER_DIRECTION } from "../../constants";
import SliderWeek from "./SliderWeek";

export const incrementOrDecrementISOWeek = (isoWeek, year, increment) => {
    const m = moment().isoWeekYear(year).isoWeekday(1).isoWeek(isoWeek);

    if (increment) {
        m.add(1, "week");
    } else {
        m.subtract(1, "week");
    }

    return {
        iso_week: m.isoWeek(),
        year: m.isoWeekYear(),
        month_indexed: m.month(),
        dates: {
            start: m.format("DD MMMM YYYY"),
            end: m.isoWeekday(7).format("DD MMMM YYYY"),
        },
    };
};

export function getWeekStartAndEnd({ year, week }) {
    const start = moment().isoWeekYear(year).isoWeekday(1).isoWeek(week);
    const end = moment().isoWeekYear(year).isoWeekday(7).isoWeek(week);

    return {
        start: start.format("DD MMMM YYYY"),
        end: end.format("DD MMMM YYYY"),
    };
}

export const getMonthNameByWeek = ({ year, week }) => {
    const start = moment().isoWeekYear(year).isoWeekday(1).isoWeek(week);
    return start.format("MMMM");
};

export const getMonth = ({ year, week }) => {
    const start = moment().isoWeekYear(year).isoWeekday(1).isoWeek(week);
    return start.month();
};

const TODAY = moment().format("YYYY-MM-DD");

const CalendarWeek = ({
    currentMonth,
    isLoading,
    data_week,
    data_month,
    setMonth,
    month,
    refetch,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    const ctx_show_weekend = useShowWeekend((state) => state.show);
    const ctx_setShow = useShowWeekend((state) => state.setShow);

    // format the days.
    let days = [
        t("home.sections.week.days.monday"),
        t("home.sections.week.days.tuesday"),
        t("home.sections.week.days.wednesday"),
        t("home.sections.week.days.thursday"),
        t("home.sections.week.days.friday"),
    ];
    if (ctx_show_weekend) {
        days.push(
            t("home.sections.week.days.saturday"),
            t("home.sections.week.days.sunday")
        );
    }

    const { start, end } = getWeekStartAndEnd({
        year: currentMonth.year,
        week: currentMonth.iso_week,
    });

    const _decrementWeek = () => {
        return setMonth((state) => {
            const m = moment().isoWeek(state.iso_week - 1);
            const start = m.isoWeekday(1).format("DD MMMM YYYY");
            const month = m.month();
            const end = m.isoWeekday(7).format("DD MMMM YYYY");
            const week = m.isoWeek();
            const year = m.year();

            return {
                dates: {
                    start,
                    end,
                },
                iso_week: week,
                month_indexed: month,
                year,
                direction: SLIDER_DIRECTION.backward,
            };
        });
    };

    const _incrementWeek = () => {
        return setMonth((state) => {
            const m = moment().isoWeek(state.iso_week + 1);
            const start = m.isoWeekday(1).format("DD MMMM YYYY");
            const month = m.month();
            const end = m.isoWeekday(7).format("DD MMMM YYYY");
            const week = m.isoWeek();
            const year = m.year();

            return {
                dates: {
                    start,
                    end,
                },
                iso_week: week,
                month_indexed: month,
                year,
                direction: SLIDER_DIRECTION.forward,
            };
        });
    };

    return (
        <ContainerFluid>
            <Container column>
                <Header fill>
                    <HeaderLeft fill>
                        <SliderWeek
                            start={start}
                            end={end}
                            onDecrement={_decrementWeek}
                            onIncrement={_incrementWeek}
                            iso_week={currentMonth?.iso_week}
                        />
                    </HeaderLeft>
                    <Flex fill vAlign="center" hAlign="start">
                        <WeekStatus status={data_week?.status?.value} />
                    </Flex>
                    <HeaderRight fill hAlign="end" vAlign="center">
                        <Checkbox
                            label={t("home.sections.week.show_weekend")}
                            defaultChecked={ctx_show_weekend}
                            onChange={(e, { checked }) => ctx_setShow(checked)}
                            toggle
                        />
                    </HeaderRight>
                </Header>
                <WrapperWeek gap="gap.large">
                    {days.map((dayName, index) => {
                        const current_day = data_week?.days[index];

                        //* use this when the response of backend is empty => calendar not set yet to this month.
                        const current_day_number = moment()
                            .isoWeekYear(currentMonth.year)
                            .isoWeekday(index + 1)
                            .isoWeek(currentMonth.iso_week)
                            .date();

                        const hour = current_day
                            ? formatHour(current_day?.hours_count_to_display)
                            : "";

                        return (
                            <CardDay
                                key={`day_${index}`}
                                today={TODAY === current_day?.date}
                                weekValidated={
                                    // green
                                    data_week?.status?.value === 1
                                }
                                monthSent={
                                    // grey
                                    data_month?.status?.value === 1
                                }
                                monthValidated={data_month?.status?.value === 3}
                                isLoading={isLoading}
                                isOverwork={
                                    // show the warning icon
                                    current_day?.display_warning
                                }
                                isUnworked={current_day?.type.value === 3}
                                isHoliday={current_day?.type.value === 1}
                                isLeave={current_day?.type.value === 2}
                                leaveTypeName={current_day?.type.leave_name}
                                isMonthClosed={
                                    data_month?.status?.value === 1 ||
                                    data_month?.status?.value === 3
                                }
                                slotsLength={current_day?.shifts?.length} // used for removing divider when the have One Slot.
                                dayName={dayName}
                                dayHours={hour}
                                dayNumber={
                                    current_day?.date.split("-")[2] ??
                                    current_day_number
                                }
                                dayData={current_day}
                                refetch={refetch}
                                month={month}
                            />
                        );
                    })}
                </WrapperWeek>
            </Container>
        </ContainerFluid>
    );
};

export default CalendarWeek;

const WrapperWeek = styled(Flex)``;

const HeaderLeft = styled(Flex)``;

const HeaderRight = styled(Flex)``;

const Header = styled(Flex)`
    padding-block: 16px;
`;

const TextWeek = styled(Text)`
    color: #242424;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
`;

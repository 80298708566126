import React from "react";
import Card from "..";
import DatesContractContent from "./Components/DatesContractContent";

const DatesContract = ({ contract_data }) => {
    return (
        <Card column>
            <Card.Body
                content={
                    <DatesContractContent
                        start_date={contract_data?.start_date}
                        probationary_period_end_date={
                            contract_data?.probationary_period_end_date
                        }
                        seniority={contract_data?.seniority}
                        departure_date={contract_data?.departure_date}
                        isCDI={contract_data?.contract_type?.id === 0}
                    />
                }
            />
        </Card>
    );
};

export default DatesContract;

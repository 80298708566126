import React, { useDebugValue, useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "react-query";
import { Flex, Text } from "@fluentui/react-northstar";

import { Container, ContainerFluid } from "../../../../common/styles";
import Header from "../../components/manager/Header";
import Body from "../../components/manager/Body";
import Footer from "../../components/manager/Footer";
import {
    _api_approve_or_reject_timesheet,
    _api_manager_time_reporting,
} from "../../api";
import { INIT_REDUCER, STATUS_TOAST } from "../../constants";
import Toast from "../../../../components/Alerts";

const TimeReportingManager = () => {
    const [checkedListUsers, setCheckedListUsers] = useState([]);
    const [clickedOn, setClickedOn] = useState(null);

    const [month, setMonth] = useState(INIT_REDUCER);

    const [configToast, setConfigToast] = useState({
        visible: false,
        content: "",
        level: "danger",
    });

    const { data, isLoading, refetch } = useQuery(
        ["MANAGER_TIME_REPORTING", month?.month_indexed + 1],
        () =>
            _api_manager_time_reporting({
                year: month.year,
                month: month.month_indexed + 1,
            }),
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                const is_some_to_validate = data?.users
                    ?.map((user) =>
                        user?.timesheets?.map((ts) => {
                            return { ...ts, id_user: user?.id };
                        })
                    )
                    .flat()
                    ?.filter(
                        (timesheet) =>
                            timesheet?.status?.value === 1 &&
                            ![0, -1].includes(timesheet?.id)
                    );

                if (is_some_to_validate.length) {
                    setCheckedListUsers(
                        is_some_to_validate?.map((el) => el?.id_user)
                    );
                }

                return;
            },
        }
    );

    const { isLoading: mutate_is_loading, mutate } = useMutation((data) =>
        _api_approve_or_reject_timesheet(data)
    );

    const _rejectTimesheetUsers = (status) => {
        mutate(
            {
                user_ids: checkedListUsers,
                type: status,
                year: month.year,
                month: month.month_indexed + 1,
            },
            {
                onSuccess: ({ error_requests }) => {
                    if (error_requests > 0) {
                        _showToast(STATUS_TOAST.error);
                    } else {
                        if (status === STATUS_TOAST.approve) {
                            _showToast(STATUS_TOAST.approve);
                        } else if (status === STATUS_TOAST.reject) {
                            _showToast(STATUS_TOAST.reject);
                        }
                        refetch();
                    }
                },
            }
        );
        return;
    };

    const _showToast = (status) => {
        switch (status) {
            case STATUS_TOAST.approve:
                setConfigToast({
                    visible: true,
                    content: "Feuille de temps approuvée",
                    level: "success",
                });
                break;
            case STATUS_TOAST.reject:
                setConfigToast({
                    visible: true,
                    content: "Feuille de temps refusée",
                    level: "success",
                });
                break;
            case STATUS_TOAST.resent_mail:
                setConfigToast({
                    visible: true,
                    content: "e-mail renvoyé !",
                    level: "success",
                });
                break;

            default:
                setConfigToast({
                    visible: true,
                    content:
                        "L'action n'a pas pu être effectuée sur tous les choix, veuillez vérifier les statuts des collaborateurs",
                    level: "warning",
                });
                break;
        }

        return;
    };

    return (
        <ContainerFluid>
            <Container fill column>
                <Title content="Suivi mensuel de mon équipe" />
                <Content column>
                    <Toast
                        onStatusChange={() =>
                            setConfigToast({
                                visible: false,
                                content: "",
                                level: "danger",
                            })
                        }
                        level={configToast.level}
                        content={configToast.content}
                        visible={configToast.visible}
                    />
                    <Header
                        month={month}
                        setMonth={setMonth}
                        isLoading={isLoading}
                        showToast={_showToast}
                    />
                    <Body
                        users={data?.users}
                        weeks={data?.month?.weeks}
                        is_loading={isLoading}
                        setCheckedListUsers={setCheckedListUsers}
                        show_toast={_showToast}
                    />
                    <Footer
                        isRefuseDisabled={!checkedListUsers?.length}
                        isApproveDisabled={!checkedListUsers?.length}
                        onApprove={() => {
                            setClickedOn(STATUS_TOAST.approve);
                            _rejectTimesheetUsers(STATUS_TOAST.approve);
                            return;
                        }}
                        onReject={() => {
                            setClickedOn(STATUS_TOAST.reject);
                            _rejectTimesheetUsers(STATUS_TOAST.reject);
                            return;
                        }}
                        isApproving={
                            clickedOn === STATUS_TOAST.approve &&
                            mutate_is_loading
                        }
                        isRejecting={
                            clickedOn === STATUS_TOAST.reject &&
                            mutate_is_loading
                        }
                    />
                </Content>
            </Container>
        </ContainerFluid>
    );
};

export default TimeReportingManager;

const Title = styled(Text)`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    margin-block: 24px;
    color: #242424;
`;

const Content = styled(Flex)`
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.11),
        0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
    /* border: 1px solid red; */
    background-color: white;
    padding: 24px;
`;

import create from "zustand";
import produce from "immer";
import { persist } from "zustand/middleware";
import { ROLES_CODE } from "../../../constants/roles";

export const useStepper = create(
    persist(
        (set) => ({
            general_infos: null,
            general_infos_cache: null,
            roles: ROLES_CODE.employee, // default value is employee
            resources: null,
            templates: [],
            templates_last_updated: [], // this key is used to store the last state of the templates Dialog element.
            all_selected_task: [], // display table of task already calculed.
            nextButtonStatus: null, // by default its true
            disableNextButton: (bool) => {
                set(
                    produce((state) => {
                        state.nextButtonStatus = bool;
                    })
                );
            },
            setGeneralInformation: (values) => {
                set(
                    produce((state) => {
                        state.general_infos = values;
                    })
                );
            },
            setGeneralInformationCache: (values) => {
                set(
                    produce((state) => {
                        state.general_infos_cache = values;
                    })
                );
            },
            setRole: (value) => {
                set(
                    produce((state) => {
                        state.roles = value;
                    })
                );
            },
            setResources: (value) => {
                set(
                    produce((state) => {
                        state.resources = value;
                    })
                );
            },
            setTemplates: (value) => {
                set(
                    produce((state) => {
                        state.templates = value;
                    })
                );
            },
            setTemplatesLastUpdated: (value) => {
                set(
                    produce((state) => {
                        state.templates_last_updated = value;
                    })
                );
            },
            setAllSelectedTask: (value) => {
                set(
                    produce((state) => {
                        state.all_selected_task = value;
                    })
                );
            },
            resetStepperContext: () => {
                set(
                    produce((state) => {
                        state.general_infos = null;
                        state.general_infos_cache = null;
                        state.roles = ROLES_CODE.employee; // default value is employee
                        state.resources = null;
                        state.templates = [];
                        state.templates_last_updated = []; // this key is used to store the last state of the templates Dialog element.
                        state.all_selected_task = []; // display table of task already calculed.
                        state.nextButtonStatus = null;
                    })
                );
            },
        }),
        {
            name: "stepper",
        }
    )
);

/**
 * CREATE FACADE HERE
 */

/**
 * button context for the next step button.
 */
export const useNextButtonStatus = () =>
    useStepper((state) => state.nextButtonStatus);

export const useDisableNextButton = () =>
    useStepper((state) => state.disableNextButton);

/**
 * First Step, Informations Collaborator
 */
export const useGeneralInformation = () =>
    useStepper((state) => state.general_infos);
export const useSetGeneralInformation = () =>
    useStepper((state) => state.setGeneralInformation);

export const useGeneralInformationCache = () =>
    useStepper((state) => state.general_infos_cache);
export const useSetGeneralInformationCache = () =>
    useStepper((state) => state.setGeneralInformationCache);

/**
 * ROLE STEP
 */
export const useRole = () => useStepper((state) => state.roles);
export const useSetRole = () => useStepper((state) => state.setRole);

/**
 * RESOURCES
 */
export const useResources = () => useStepper((state) => state.resources);
export const useSetResources = () => useStepper((state) => state.setResources);

/**
 * TEMPLATES MODALS.
 */
export const useTemplates = () => useStepper((state) => state.templates);
export const useTemplatesLastUpdated = () =>
    useStepper((state) => state.templates_last_updated);
export const useSetTemplatesLastUpdated = () =>
    useStepper((state) => state.setTemplatesLastUpdated);

export const useSetTemplates = () => useStepper((state) => state.setTemplates);
export const useSetAllSelectedTask = () =>
    useStepper((state) => state.setAllSelectedTask);
export const useAllSelectedTask = () =>
    useStepper((state) => state.all_selected_task);

/**
 * functional SETTERS export
 */
export const f_set_resources = useStepper.getState().setResources;
export const f_set_templates = useStepper.getState().setTemplates;
export const f_set_templates_last_updated =
    useStepper.getState().setTemplatesLastUpdated;

export const f_templates = useStepper.getState().templates;
export const f_all_tasks = useStepper.getState().all_selected_task;

export const f_resetStepperContext = useStepper.getState().resetStepperContext;

/* eslint-disable no-debugger */
import React, { useEffect } from "react";
import { useState } from "react";
import {
    Button,
    CloseIcon,
    Dropdown,
    FilterIcon,
    Loader,
} from "@fluentui/react-northstar";

import { Container, ContainerFluid } from "../../../common/styles";
import { Body, DialogStyle, Header, Title } from "./index.style";
import ViewTableHR from "./components/ViewTableHR";
import DialogFilters from "./components/DialogFilters";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import { useInfiniteQuery, useQuery } from "react-query";
import { QUERY_KEY } from "../constants";
import { _api_get_list_tasks_hr } from "../../../api/tasks";
import { PROCESS } from "../../../components/Badge/TaskProcessus";
import { _isDirtyForm } from "../functions";
import { useTranslation } from "react-i18next";
import SliderTabs from "../../../components/shared/SliderTabs";
import { _getAllUsers } from "../../../api/user";
import { AVATAR_DEFAULT } from "../../../constants";
import { DropdownStyle } from "../TasksModels/DialogAddIndividualTaskContent";
import Toast from "../../../components/Alerts";

const MyCompanyList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [process, setProcess] = useState(0);

    const location = useLocation();

    const [assignedUserId, setAssignedUserId] = useState(null);
    const [toastConfig, setToastConfig] = useState({
        visible: false,
        content: "",
        level: "success",
    });

    const {
        data,
        fetchNextPage,
        isLoading,
        isFetching,
        isFetchingNextPage,
        refetch,
        error,
        hasNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: [QUERY_KEY + "::getListTasksHR"],
        queryFn: (state) => {
            return _api_get_list_tasks_hr({
                page: state?.pageParam,
                // related_user_id,
                assigned_user_id: assignedUserId,
                process: process,
            });
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.next_page_value;
        },
    });

    const {
        data: users,
        isLoading: isLoadingUsers,
        isFetching: isFetchingUsers,
    } = useQuery([QUERY_KEY + "::getFilterUsers"], () => _getAllUsers(), {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });

    useEffect(() => {
        refetch();
    }, [process, assignedUserId]);

    useEffect(() => {
        if (location.state) {
            setToastConfig({
                visible: !!Object.keys(location.state).length,
                content: location.state.message,
                level: "success",
            });
        }
    }, []);

    return (
        <ContainerFluid>
            <Container column>
                <Toast
                    visible={toastConfig.visible}
                    content={toastConfig.content}
                    level={toastConfig.level}
                    onStatusChange={() => {
                        location.state = {};
                        return setToastConfig({
                            visible: false,
                            content: "",
                            level: "warning",
                        });
                    }}
                />
                <Header fill space="between" vAlign="center">
                    <Title>Liste des tâches de l’entreprise</Title>
                    <SliderTabs
                        tabs={["Toutes", "Onboarding", "Suivi", "Offboarding"]}
                        defaultActiveIndex={0}
                        onChange={(v) => {
                            setSearchParams({
                                process: v,
                            });
                            setProcess(v);
                            return;
                        }}
                    />
                    {/* filter button */}
                    {isLoadingUsers ? (
                        <Loader labelPosition="Chargement ..." />
                    ) : (
                        <DropdownStyle
                            search
                            items={users?.map((user) => {
                                return {
                                    id: user?.id,
                                    header: user?.name,
                                    email: user?.username,
                                    content: user?.job_title,
                                    image: user?.avatar ?? AVATAR_DEFAULT,
                                };
                            })}
                            placeholder="Chercher une personne"
                            noResultsMessage="Nous n'avons pas trouvé de correspondance."
                            a11ySelectedItemsMessage="Appuyez sur la touche Delete ou Backspace pour supprimer"
                            inverted
                            loading={isLoadingUsers}
                            onChange={(e, i) => {
                                setSearchParams({
                                    assigned_user_id: i?.value?.id,
                                });
                                setAssignedUserId(i?.value?.id);
                                return;
                            }}
                        />
                    )}
                </Header>
                <Body>
                    <ViewTableHR
                        isLoading={isLoading}
                        isFetching={isFetching}
                        data={data}
                        isFetchingNextPage={isFetchingNextPage}
                        fetchNextPage={fetchNextPage}
                        onDeleteTask={() => {
                            setToastConfig((state) => {
                                return {
                                    ...state,
                                    visible: true,
                                    level: "success",
                                    content: "La tâche a bien été supprimée",
                                };
                            });

                            return;
                        }}
                    />
                </Body>
            </Container>
        </ContainerFluid>
    );
};

export default MyCompanyList;

import React from "react";
import { EditIcon, Text, TrashCanIcon } from "@fluentui/react-northstar";
import { MoreVertical24Filled } from "@fluentui/react-icons";
import { MenuStyle } from "../../../common/styles";
import Toast from "../../../components/Alerts";
import useToast from "../../../hooks/useToast";

const remove_task_from_list = (id, setTemplateTasks) => {
    setTemplateTasks((state) => {
        const filter_task = state?.filter((el) => el?.id !== id);
        return filter_task;
    });
};

const MoreMenuItem = ({ task, setTemplateTasks, setOpenDialogModifyTask }) => {
    return (
        <>
            <MenuStyle
                open={true}
                padding={"0px"}
                items={[
                    {
                        key: "manage models",
                        icon: <MoreVertical24Filled />,
                        menu: [
                            {
                                key: "modify",
                                content: "modifier la tâche",
                                icon: <EditIcon />,
                                onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    return setOpenDialogModifyTask({
                                        visible: true,
                                        task: task,
                                    });
                                },
                            },
                            {
                                key: "remove",
                                content: (
                                    <Text
                                        styles={{
                                            color: "var(--color-danger)",
                                        }}
                                    >
                                        supprimer la tâche
                                    </Text>
                                ),
                                icon: (
                                    <TrashCanIcon
                                        styles={{
                                            color: "var(--color-danger)",
                                        }}
                                    />
                                ),
                                onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    // afficher le toast.
                                    return remove_task_from_list(
                                        task?.id,
                                        setTemplateTasks
                                    );
                                },
                            },
                        ],
                    },
                ]}
            />
        </>
    );
};

export default MoreMenuItem;

// this is the main page of the expenses feature

import React, { useEffect } from "react";
import { Button, FilterIcon, Flex } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { Container, ContainerFluid } from "../../../common/styles";
import { I18N_NS_EXPENSE } from "../constants";
import {
    CardExpense,
    CardTitle,
    Title,
    Header,
    MainContainer,
} from "../styles";
import ChartSection from "./ChartSection";
import DialogCreateExpense from "./Dialogs/CreateExpenseReport/DialogCreateExpense";
import ExpensesRows from "./ExpensesRows";
import { useNavigate } from "react-router-dom";
import ExpenseGoHistoryCard from "./ExpenseGoHistoryCard";
import moment from "moment";
import { ROUTES } from "../../../constants/routes";
import {
    modelWhereIamExpenses,
    useWhereIamExpenses,
} from "../store/useWhereIam.store";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";

const ExpensesHome = () => {
    useEffect(() => {
        document.title = "Gestion des notes de frais ";
    }, []);

    const { t } = useTranslation(I18N_NS_EXPENSE);

    const setWhereIam = useWhereIamExpenses((state) => state.setWhereIam);

    useEffect(() => {
        setWhereIam({
            isEmployeePage: true,
            isManagerPage: false,
            isAccountingManager: false,
        });
    }, []);

    return (
        <ContainerFluid fill>
            <MainContainer
                fill
                style={{
                    marginBlock: "24px",
                    //  border: "2px solid green"
                }}
            >
                <Flex
                    fill
                    styles={{
                        flex: 2,
                    }}
                    column
                    vAlign="center"
                >
                    {/* BODY */}
                    <CardExpense space={"between"} gap="gap.medium" column>
                        <Header space="between" vAlign="center">
                            <CardTitle>{t("home.card_title")}</CardTitle>
                            {/* DIALOG create expense */}
                            <DialogCreateExpense />
                        </Header>
                        {/* render last 5 expenses here */}
                        <Flex>
                            <ExpensesRows />
                        </Flex>
                    </CardExpense>
                </Flex>
                {/* RIGHT SECTION */}
                <Flex fill styles={{ flex: 1 }} column gap="gap.medium">
                    <Flex>
                        <ChartSection />
                    </Flex>
                    <Flex fill>
                        <ExpenseGoHistoryCard
                            route={ROUTES.expenses.my_history.route}
                        />
                    </Flex>
                </Flex>
            </MainContainer>
        </ContainerFluid>
    );
};

export default ExpensesHome;

import { BASE_URL_API } from "../../../constants";
import fetcher from "../../../utils/fetcher";

export const _api_get_timeclock = () => {
    const url = `${BASE_URL_API}/time_reporting/badge/today`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_post_timeclock = (action_id, localisation) => {
    const url = `${BASE_URL_API}/time_reporting/badge/virtual/action/${action_id}/add-entry`;
    return fetcher(url, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            lat: localisation.latitude ?? 0,
            lng: localisation.longitude ?? 0,
        }),
    });
};

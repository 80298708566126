import { Loader } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const LoaderPage = ({ label = "Chargement ..." }) => {
    return (
        <Container>
            <Loader label={label} />
        </Container>
    );
};

export default LoaderPage;

const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    gap: 20px;
`;

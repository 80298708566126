import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const SideTitle = ({ content, icon = null }) => {
    return (
        <Flex gap="gap.small" vAlign="center">
            {icon}
            <Title>{content}</Title>
        </Flex>
    );
};

export default SideTitle;

const Title = styled(Text)`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: #000000;
`;

import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { BeachRegular, CommentFilled } from "@fluentui/react-icons";

const BadgeLeave = ({ nbr_hours }) => {
    return (
        <BadgeLeaveStyle vAlign="center" hAlign="center">
            <BadgeLeaveText content={nbr_hours} />
            <BeachRegular fontSize={12} color="#FFAA44" />
        </BadgeLeaveStyle>
    );
};
const BadgeComment = ({ nbr_comment }) => {
    return (
        <BadgeCommentStyle vAlign="center" hAlign="center">
            <BadgeCommentText content={nbr_comment} />
            <CommentFilled fontSize={8} />
        </BadgeCommentStyle>
    );
};

/**
 * @typedef {Object} PropsBadgesStatus
 * @property {string} [leave] - le total d'heures d'absence
 * @property {number} [comment] - le nombre de commentaire
 *
 * @param {PropsBadgesStatus} props
 * @returns {React.JSX}
 *
 */
const BadgesStatus = ({ leave = "", comment = 0 }) => {
    return (
        <Flex vAlign="center" hAlign="center" gap="gap.smaller">
            {leave > 0 && <BadgeLeave nbr_hours={leave} />}
            {comment > 0 && <BadgeComment nbr_comment={comment} />}
        </Flex>
    );
};

export default BadgesStatus;

// BadgeLeaveStyle
const BadgeLeaveStyle = styled(Flex)`
    height: 16px;
    padding: 2px 6px;
    gap: 2px;

    border-radius: 100px;
    border: 1px solid var(--Light-theme-Rest-Border-Yellow-Border, #f2e384);
    background: var(--Light-theme-Rest-Background-Yellow-Background-1, #fbf6d9);
`;

const BadgeLeaveText = styled(Text)`
    color: var(--Light-theme-Rest-Background-Yellow-Background, #fa4);

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
`;
// BadgeCommentStyle
const BadgeCommentStyle = styled(Flex)`
    height: 16px;
    padding: 2px 6px;
    gap: 2px;
    border-radius: 100px;
    background: var(--Light-theme-Rest-Background-Brand-Background-1, #e8ebfa);
`;

const BadgeCommentText = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
`;

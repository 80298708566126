import { AcceptIcon, Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import {
    AcceptIconStyle,
    CategoryContent,
    ImageStyle,
    TextCategory,
    WrapperCategory,
} from "./index.style";

/**
 * @typedef {Object} PropsType
 * @property {number} id - ID de la catégorie
 * @property {string} name - le nom de la catégorie
 * @property {string} icon - URL de l'icone a afficher
 * @property {boolean} [$selected] - URL de l'icone a afficher
 * @property {function} [cbChooseCategory] - sera appeller lorsqu'on change de catégorie
 *
 * @param {PropsType} props
 * @returns
 */
const Category = ({
    id,
    name,
    icon,
    $selected = false,
    cbChooseCategory = () => null,
}) => {
    return (
        <WrapperCategory
            column
            onClick={() =>
                cbChooseCategory({
                    id: id,
                    name: name,
                    icon_url: icon,
                })
            }
        >
            <CategoryContent
                vAlign="center"
                gap="gap.medium"
                fill
                $selected={$selected}
            >
                <ImageStyle src={icon} />
                <TextCategory content={name} />
                {$selected && <AcceptIconStyle />}
            </CategoryContent>
        </WrapperCategory>
    );
};

export default Category;

import React from "react";
import logo_yeetch from "../../../assets/logos/logo.svg";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";
import moment from "moment";
const HeaderPreonboarding = ({ arrival_date }) => {
    return (
        <HeaderFlex vAlign="center" fill>
            <Flex
                fill
                styles={{
                    margin: "0 auto",
                    maxWidth: "1440px",
                    padding: "0 24px",
                }}
                // hAlign="center"
                vAlign="center"
                space="between"
            >
                <Flex gap="gap.medium" fill vAlign="center">
                    <Logo src={logo_yeetch} />
                    <LogoText content="Votre espace RH" />
                </Flex>
                <LeftFlex vAlign="center" fluid>
                    <FirstDateText content="Date du premier jour" />
                    <BoldDateText
                        content={moment(arrival_date).format("D MMMM YYYY")}
                    />
                </LeftFlex>
            </Flex>
        </HeaderFlex>
    );
};

const MobileHeaderPreonboarding = ({ arrival_date }) => {
    return (
        <MainContent gap="gap.medium" vAlign="center" column>
            <Logo src={logo_yeetch} />
            <FirstDateFlex vAlign="center" space="between">
                <FirstDateText content="Date du premier jour" />
                <BoldDateText
                    content={moment(arrival_date).format("D MMMM YYYY")}
                />
            </FirstDateFlex>
        </MainContent>
    );
};

export default HeaderPreonboarding;
export { MobileHeaderPreonboarding };
const FirstDateFlex = styled(Flex)`
    border-radius: 8px;
    background: #f5f5f5;
    padding: 12px 24px;
`;

const MainContent = styled(Flex)`
    padding: 24px 16px;
    /* border: 3px dashed red; */
`;

const BoldDateText = styled(Text)`
    color: #242424;
    text-align: center;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

const LogoText = styled(Text)`
    color: #000;
    text-align: center;
    font-family: Segoe UI;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`;

const Logo = styled(Image)`
    @media ${DEVICE.mobileS} {
        width: 170px;
        height: 48px;
        /* padding-bottom: 16px; */
    }
    @media ${DEVICE.mobileL} {
        width: 170px;
    }
    @media ${DEVICE.tablet} {
        width: 173px;
        height: 56px;
    }
    @media ${DEVICE.laptop} {
        width: 173px;
        height: 56px;
    }
`;

const HeaderFlex = styled(Flex)`
    height: 126px;
    background: #fff;
    box-shadow: 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13);
`;

const LeftFlex = styled(Flex)`
    white-space: nowrap;
    /* width: 100%; */
    gap: 40px;
    padding: 12px 24px;
    background-color: #f5f5f5;
    border-radius: 8px;
`;

const FirstDateText = styled(Text)`
    color: #424242;
    text-align: center;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

import React from "react";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import HibouIcon from "../../../assets/illustrations/hibou.png";

const CustomEmptyModelTask = ({ title = "", description = "" }) => {
    return (
        <Flex column vAlign="center" hAlign="center" gap="gap.medium">
            <Image
                src={HibouIcon}
                alt="empty chouette"
                styles={{ width: "100%" }}
            />
            <TextTitle>{title}</TextTitle>
            <TextDescription>{description}</TextDescription>
        </Flex>
    );
};

export default CustomEmptyModelTask;
const TextTitle = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    color: #000000;
`;

const TextDescription = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #242424;
`;

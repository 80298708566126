import { Flex, Text } from "@fluentui/react-northstar";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const WeekText = styled(Link)`
    color: #5b5fc7;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    gap: 4px;
    width: 120px;
    padding: 4px 8px;
`;

export const WeekFlex = styled(Flex)`
    width: 120px;
    ${({ $noStyle }) =>
        $noStyle
            ? css`
                  border-left: 1px solid grey;
                  border-right: 1px solid grey;
              `
            : css`
                  background: ${({ $highlight }) =>
                      $highlight ? "#e8ebfa" : "transparent"};
                  border: 0px solid
                      var(--Light-theme-Rest-Border-Default-Border-3, #f0f0f0);
                  ${({ $highlight }) =>
                      $highlight
                          ? css`
                                box-shadow: 0px 0.15px 0.45px 0px
                                        rgba(0, 0, 0, 0.11),
                                    0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13);
                            `
                          : ""}
              `}
`;

export const DayText = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
`;

export const WrapperWeeksValues = styled(Flex)``;
export const TextWeek = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
`;

import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";

const Holiday = () => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    return (
        <Container fill vAlign="center" hAlign="center">
            <Content content={t("home.card.holiday")} />
        </Container>
    );
};

export default Holiday;

const Content = styled(Text)`
    color: #616161;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    width: 91px;
    text-align: center;
`;

const Container = styled(Flex)`
    background-color: #fafafa;
    width: 100%;
    height: 100%;
`;

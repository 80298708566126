import { _upload_file } from "../api/tasks";

export const FILE_SIZE_LIMIT = 2_500_000;

export const debounce = (cb, delay = 1000) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            cb(...args);
        }, delay);
    };
};

export const throttle = (fn, delay) => {
    let run = false;
    return function (...args) {
        if (!run) {
            fn(...args);
            run = true;
            setTimeout(() => (run = false), delay);
        }
    };
};

export const uploadedFile = async (files, cb) => {
    if (!files?.length) {
        return console.log("ya aucun fichier !!");
    }

    // limit the import to 5 files at once.
    if (files.length > 1) {
        alert("Vous avez dépasser la limite autorisée ( 1 fichier )");
        return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > FILE_SIZE_LIMIT) {
            console.log(
                "'" + file.name + "' à été retirer a cause de sa taille !"
            );
            return false;
        }
        formData.append("uploaded_files", file);
    }
    const savedFiles = await _upload_file(formData);
    cb(savedFiles.files); // save the values on the useForm
};

export const mock_sleep = (time = 2000) => {
    return new Promise((resolve, reject) => {
        const time_id = setTimeout(() => {
            resolve();
            return clearTimeout(time_id);
        }, time);
    });
};

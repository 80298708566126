export const _formatJsonAnnuaire = (data) => {
    const panel = data?.departments?.map((el) => {
        // collaborator in a list
        const users = el?.users.map((el) => {
            return {
                id: el.id,
                avatar: el.avatar,
                name: el.first_name + " " + el.last_name,
                poste: el.job_title || "/",
                email: el.email,
                agency: el.agency,
                manager: el.manager,
                phone_number: el.phone_number,
            };
        });
        return {
            id: el.id,
            title: el.name,
            users_count: el.users_count || 0,
            data: users,
        };
    });
    return panel;
};

export const _formatJsonUsers = (users) => {
    const formated_users = users?.map((el) => {
        return {
            id: el.id,
            avatar: el.avatar,
            name: el.first_name + " " + el.last_name,
            poste: el.job_title || "/",
            email: el.email,
            agency: el.agency,
            manager: el.manager,
            phone_number: el.phone_number,
        };
    });
    return formated_users;
};

import { Table, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const FromTitle = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

export const TableTasksList = styled(Table)`
    [role="columnheader"] > .ui-table__cell__content {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 31px;
        color: #616161;
    }
    [role="cell"] > .ui-table__cell__content {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 48px;
        color: #252525;
    }
    [role="row"] > :last-child {
        justify-content: flex-end;
        align-items: flex-end;
        button {
            justify-content: flex-end;
        }
    }
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
`;

export const TitleGroup = styled(Text)`
    color: #242424;
    /* Large/600 */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
`;

export const RequiredStar = styled(Text)`
    color: #c4314b;
    /* Large/600 */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    ::after {
        content: "*";
        margin-left: 6px;
    }
`;

import { Skeleton } from "@fluentui/react-northstar";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Data } from "./TimeCard";

const TimeCardSkeleton = () => {
    return (
        <>
            <Doughnut
                data={{
                    labels: ["déclaré", "sur"],
                    datasets: [
                        {
                            label: "",
                            data: [100, 0],
                            backgroundColor: ["#ededed", "#F0F0F0"],
                            borderColor: ["#ededed", "#F0F0F0"],
                            borderWidth: 1,
                        },
                    ],
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: "1",
                    plugins: {
                        legend: false,
                    },
                    circumference: 180,
                    rotation: -90,
                    cutout: "70%",
                }}
            />
            <Data hAlign="center" vAlign="center" column>
                <Skeleton
                    animation="pulse"
                    styles={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        alignItems: "center",
                    }}
                >
                    <Skeleton.Line width="100px" height="18px" />
                    <Skeleton.Line width="60px" height="18px" />
                </Skeleton>
            </Data>
        </>
    );
};

export default TimeCardSkeleton;

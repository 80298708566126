import { Loader } from "@fluentui/react-northstar";
import React from "react";
import Card from "../Card/Card";
import { DELEGATION_ROLES, I18N_NS_DELEGATION } from "../../constants";
import { useTranslation } from "react-i18next";
import moment from "moment";

const logicEachCard = (card, t) => {
    const role = DELEGATION_ROLES[card.type?.name];
    const title_card = role.title;
    const description = card.collaborator
        ? card.end
            ? t("home.card.until_date", {
                  date: moment(card.end).format("DD/MM/YYYY"),
              })
            : t("home.card.no_end_date")
        : t("home.card.no_delegation");
    const icon = role.icon;
    const avatar = card.collaborator?.avatar;
    const fullname =
        card.collaborator?.first_name + " " + card.collaborator?.last_name;

    const is_delegated = card.collaborator;
    const type = card.type?.name;
    return {
        id: card.id,
        role,
        title_card,
        description,
        icon,
        avatar,
        fullname,
        is_delegated,
        type,
    };
};

const EachListCards = ({ isLoading, data = [] }) => {
    const { t } = useTranslation(I18N_NS_DELEGATION);

    return isLoading ? (
        <Loader
            size="small"
            labelPosition="after"
            label={t("common.loading", {
                ns: "translation",
            })}
        />
    ) : (
        <>
            {data.map((card) => {
                const {
                    id,
                    title_card,
                    description,
                    icon,
                    avatar,
                    fullname,
                    is_delegated,
                    type,
                } = logicEachCard(card, t);

                return (
                    <Card
                        key={id}
                        title={title_card}
                        description={description}
                        icon={icon}
                        avatar={avatar}
                        fullname={fullname}
                        type={type}
                        isDelegated={is_delegated}
                    />
                );
            })}
        </>
    );
};

export default EachListCards;

import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Wrapper = styled(Flex)``;
export const TextYearName = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;

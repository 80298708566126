import { Button, CallIcon, Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";

export default function RhContactCard({ data }) {
    return (
        <CardContent
            // vAlign="center"
            styles={{
                marginBottom: "40px",
                paddingInline: "24px",
            }}
        >
            {/* avatar + name +poste  */}
            <Flex fill styles={{ gap: "16px" }}>
                <ImageStyle
                    name={data?.hr?.name}
                    avatar
                    src={data?.hr?.avatar}
                />
                <Flex fill styles={{ justifyContent: "space-between" }}>
                    <Flex gap="gap.smaller" fill column>
                        <NameText content={data?.hr?.name} />
                        <JobTitle content={data?.hr?.job_title} />
                    </Flex>
                    <ButtonStyle
                        onClick={() =>
                            (window.location.href = `tel:${data?.hr?.phone}`)
                        }
                        iconOnly
                        icon={<CallIcon outline />}
                    />
                </Flex>
            </Flex>
            {/* contact RH  */}
            <ContactRH gap="gap.smaller" column>
                <EmailText content={data?.hr?.email} />
                <Text content={data?.hr?.phone} />
            </ContactRH>
        </CardContent>
    );
}

const ButtonStyle = styled(Button)`
    @media ${DEVICE.mobileS} {
        display: block;
    }
    @media ${DEVICE.mobileL} {
        display: block;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
`;
const ContactRH = styled(Flex)`
    @media ${DEVICE.mobileS} {
        padding-left: 66px;
        margin-top: 8px;
    }
    @media ${DEVICE.mobileL} {
        padding-left: 66px;
        margin-top: 8px;
    }
    @media ${DEVICE.tablet} {
        padding-left: 0px;
        align-items: flex-end;
    }
`;

const CardContent = styled(Flex)`
    max-width: 500px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        padding-top: 16px;
    }
    @media ${DEVICE.mobileL} {
        flex-direction: column;
        padding-top: 16px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
        align-items: center;
    }
`;

const ImageStyle = styled(Image)`
    width: 64px;
    height: 64px;
    @media ${DEVICE.mobileS} {
        width: 48px;
        height: 48px;
    }
`;

const NameText = styled(Text)`
    color: #242424;
    font-family: Segoe UI;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
`;

const JobTitle = styled(Text)`
    /* max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis; */

    color: #242424;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
`;

const EmailText = styled(Text)`
    color: #0067b8;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
`;

import React from "react";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import {
    ChevronRight16Filled,
    ChevronLeft16Regular,
} from "@fluentui/react-icons";
import styled from "styled-components";
import BadgeMonthStatus from "../badges";

const SliderMonth = ({
    month_name,
    onDecrement,
    onIncrement,
    month,
    isLoading,
    badge_status,
}) => {
    return (
        <Flex column hAlign="center" gap="gap.small" vAlign="center">
            <Flex gap="gap.small" vAlign="center">
                <Button
                    icon={<ChevronLeft16Regular />}
                    iconOnly
                    flat
                    text
                    onClick={onDecrement}
                />
                <TextMonth content={`${month_name} ${month?.year}`} />
                <Button
                    icon={<ChevronRight16Filled />}
                    iconOnly
                    flat
                    text
                    onClick={onIncrement}
                />
            </Flex>
            <BadgeMonthStatus isLoading={isLoading} level={badge_status} />
        </Flex>
    );
};

export default SliderMonth;

const TextMonth = styled(Text)`
    color: #242424;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
`;

import React, { useEffect, useState } from "react";
import {
    ChatIcon,
    Flex,
    Text as TextTeams,
    Attachment,
    DownloadIcon,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import DetailModalTasks from "../../components/tasks/DetailModalTasks";
import { DEVICE } from "../../constants/responsive";
import {
    BADGE_STATUS_TASKS,
    render_badge_action_document_by_id,
    TASK_STATUS,
} from "../../constants/tasks";
import { ICON_FROM_EXTENSION } from "../../utils/functions";
import { _api_download_file } from "../../api/manage_document";
import CustomDropzone from "../../components/dropzone";
import moment from "moment";
import { file_size_format } from "../../constants/maths";

const ModalDetailTaskWithoutActions = ({
    data,
    savedFiles,
    setSavedFiles,
    task,
}) => {
    return (
        <DetailModalTasks open={"true"}>
            <Flex
                fill
                grow
                vAlign="center"
                gap="gap.small"
                styles={{ marginTop: "15px" }}
            >
                <Text
                    style={{
                        backgroundColor: "black",
                        fontSize: "10px",
                        fontWeight: "normal",
                        color: "white",
                        paddingInline: "4px 8px",
                        borderRadius: "15px",
                    }}
                >
                    {data.category.name}
                </Text>
            </Flex>
            <FlexRowBadge fill grow vAlign="center" gap="gap.large">
                <Flex.Item grow>
                    <DetailModalTasks.TaskType
                        content={BADGE_STATUS_TASKS[data?.status]}
                    />
                </Flex.Item>
                <Flex.Item>
                    {render_badge_action_document_by_id(data?.document_action)}
                </Flex.Item>
                <TextStyle grow>
                    <DetailModalTasks.BolderLabel
                        label="Echéance :"
                        value={data.due_date}
                    />
                </TextStyle>
            </FlexRowBadge>
            <Flex
                fill
                grow
                vAlign="center"
                gap="gap.large"
                style={{ marginBottom: "36px" }}
            >
                <Flex.Item grow>
                    <DetailModalTasks.BolderLabel
                        label="Descriptif :"
                        value={data.description || "/"}
                        breakLine
                    />
                </Flex.Item>
            </Flex>
            <FlexCreationValidation
                gap="gap.large"
                style={{ marginBottom: "0px" }}
            >
                <Flex.Item>
                    <DetailModalTasks.BolderLabel
                        label="Création :"
                        information={moment(data.created_at).format(
                            "DD-MM-YYYY"
                        )}
                        value={
                            <Flex column>
                                <Flex.Item>
                                    <DetailModalTasks.UserAvatar
                                        image={data.creator.avatar}
                                        content={data.creator?.name}
                                    />
                                </Flex.Item>
                                <Flex
                                    grow
                                    gap="gap.smaller"
                                    justifyContent="center"
                                    vAlign="center"
                                    styles={{
                                        marginTop: "6px",
                                    }}
                                >
                                    <Flex.Item>
                                        <ChatIcon size="medium" outline />
                                    </Flex.Item>
                                    <Flex.Item>
                                        <TextTeams
                                            as="a"
                                            href={`mailto:${data.creator.email}`}
                                            color="brand"
                                            content={data.creator?.email}
                                            weight="semibold"
                                            styles={{
                                                outline: "0",
                                            }}
                                        />
                                    </Flex.Item>
                                </Flex>
                            </Flex>
                        }
                        breakLine
                    />
                </Flex.Item>
                {data?.task_to_validate && (
                    <Flex.Item>
                        <DetailModalTasks.BolderLabel
                            label="Validation :"
                            value={
                                <Flex column>
                                    <Flex.Item>
                                        <DetailModalTasks.UserAvatar
                                            image={data.validator.avatar}
                                            content={data.validator?.name}
                                        />
                                    </Flex.Item>
                                    <Flex
                                        grow
                                        gap="gap.smaller"
                                        justifyContent="center"
                                        vAlign="center"
                                        styles={{
                                            marginTop: "6px",
                                        }}
                                    >
                                        <Flex.Item>
                                            <ChatIcon size="medium" outline />
                                        </Flex.Item>
                                        <Flex.Item>
                                            <TextTeams
                                                as="a"
                                                href={`mailto:${data.validator.email}`}
                                                color="brand"
                                                content={data.validator?.email}
                                                weight="semibold"
                                                styles={{
                                                    outline: "0",
                                                }}
                                            />
                                        </Flex.Item>
                                    </Flex>
                                </Flex>
                                // <DetailModalTasks.UserAvatar
                                // 	image="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/RobertTolbert.jpg"
                                // 	content={data.created_for_user.name}
                                // />
                            }
                            breakLine
                        />
                    </Flex.Item>
                )}
                {/* DISPLAY just for mobile */}
                {/* <FlexItemEcheanceMobile grow>
					<DetailModalTasks.BolderLabel
						label="Echéance :"
						value={data.due_date}
					/>
				</FlexItemEcheanceMobile> */}
            </FlexCreationValidation>
            {/* documents parts */}
            <Flex
                vAlign="center"
                gap="gap.small"
                style={{ marginTop: "18px", marginBottom: "32px" }}
            >
                {data?.documents_template?.map((el) => {
                    return (
                        <Attachment
                            header={el.filename}
                            description={file_size_format(el.filesize)}
                            icon={ICON_FROM_EXTENSION(el.filename)}
                            actionable
                            action={{
                                icon: <DownloadIcon />,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    return _api_download_file({
                                        id: el.id,
                                        filename: el.filename,
                                    });
                                },
                                title: "download",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(el.attached_doc);
                            }}
                        />
                    );
                })}
            </Flex>
            {/* if the task is required document then show dropzone */}
            {data?.document_required && (
                <Flex column gap="gap.small">
                    <Text styles={{ fontWeight: "600" }}>
                        {data?.documents.length > 0 ? "Document envoyé" : ""}
                    </Text>
                    {data?.documents?.map((el) => {
                        return (
                            <Attachment
                                header={el.name}
                                description={file_size_format(el.filesize)}
                                icon={ICON_FROM_EXTENSION(el.name)}
                                actionable
                                action={{
                                    icon: <DownloadIcon />,
                                    onClick: (e) => {
                                        e.stopPropagation();
                                        return _api_download_file({
                                            id: el.id,
                                            filename: el.filename,
                                        });
                                    },
                                    title: "download",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(el.attached_doc);
                                }}
                            />
                        );
                    })}
                </Flex>
            )}
        </DetailModalTasks>
    );
};

export default ModalDetailTaskWithoutActions;

const FlexItemEcheanceMobile = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: flex;
    }
    @media ${DEVICE.mobileM} {
        display: flex;
    }
    @media ${DEVICE.mobileL} {
        display: flex;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
    @media ${DEVICE.desktop} {
        display: none;
    }
    @media ${DEVICE.desktopL} {
        display: none;
    }
`;

const FlexCreationValidation = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 20px;
    }
    @media ${DEVICE.mobileM} {
        flex-direction: column;
        gap: 20px;
    }
    @media ${DEVICE.mobileL} {
        flex-direction: column;
        gap: 20px;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        gap: 20px;
    }
`;

const FlexRowBadge = styled(Flex)`
    @media ${DEVICE.mobileS} {
        margin-top: 16px;
    }
    @media ${DEVICE.mobileM} {
        margin-top: 16px;
    }
    @media ${DEVICE.mobileL} {
        margin-top: 16px;
    }
`;

const TextStyle = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.mobileM} {
        display: none;
    }
    @media ${DEVICE.mobileL} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: flex;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

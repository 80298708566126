import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

const MessageSent = ({ AvatarComponent, message }) => {
    return (
        <Wrapper gap="gap.small">
            {AvatarComponent}
            <Message content={message} />
        </Wrapper>
    );
};

export default MessageSent;

const Message = styled(Text)`
    background-color: white;
    border-radius: 4px;
    padding: 6px 12px;
`;

const Wrapper = styled(Flex)``;

import { Flex, Text } from "@fluentui/react-northstar";
import styled, { css } from "styled-components";
import { DEVICE } from "../../constants/responsive";
export const TextFeedback = styled(Text)`
    color: #242424;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    transition: all 300ms;
    transition-delay: 300ms;
`;
export const Wrapper = styled(Flex)``;
export const FeedbackButton = styled(Flex)`
    transition: all 300ms;
    border-radius: 4px;
    border: 1px solid #d1d1d1;
    background: #fff;

    /* shadow-8 */
    box-shadow: 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13),
        0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11);

    width: 40px;
    height: 40px;

    position: fixed;
    right: 20px;
    bottom: 20px;

    overflow: hidden;
    text-overflow: ellipsis;

    ${TextFeedback} {
        display: none;
    }
    :hover {
        cursor: pointer;
        width: 190px;
        ${TextFeedback} {
            white-space: nowrap;
            display: flex;
        }
    }
    ${({ showCard }) =>
        showCard
            ? css`
                  width: 190px;
                  ${TextFeedback} {
                      white-space: nowrap;
                      display: flex;
                  }
              `
            : ""}
`;

export const Container = styled(Flex)`
    position: fixed;
    bottom: 75px;
    right: 20px;
    height: fit-content;
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px 0px,
        rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
    @media ${DEVICE.mobileS} {
        width: calc(100vw - 40px);
    }
    @media ${DEVICE.mobileL} {
        width: 380px;
    }
    @media ${DEVICE.laptop} {
        width: 380px;
    }
`;

import { BASE_URL_API } from "../../constants";

export const _upload_file_temp = (formData) => {
    return fetch(BASE_URL_API + "/upload_file/", {
        method: "POST",
        body: formData,
        credentials: "include",
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const uploadFile = (file, setProgress, onFileUploaded) => {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                console.log("success file uploaded !");
            } else {
                console.log("error !");
            }
        }
    };
    xhr.open("POST", BASE_URL_API + "/upload_file/", true);
    var formData = new FormData();
    formData.append("uploaded_files", file);
    xhr.send(formData);

    xhr.onprogress = function (event) {
        // report progress
        setProgress((event.loaded * 100) / event.total);
    };

    xhr.onload = (e) => {
        const { files } = JSON.parse(e.target.response);
        onFileUploaded([files[0].id]);
    };
};

import React from "react";
import { useState } from "react";
import {
    Button,
    CloseIcon,
    Dialog,
    Divider,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { TeamCard } from "./TeamCard";
import { ROUTES } from "../../../constants/routes";
import CardUser from "../../../features/employee_folder/components/Cards/CardPositionEntreprise/CardUser";
import { DEVICE } from "../../../constants/responsive";

const ShowAllTeamDialog = ({ teams }) => {
    const [openDialog, setOpenDialog] = useState(false);

    // * navigate to any employee
    const navigate = useNavigate();
    const _navigateToEmployee = (id) => {
        if (isNaN(id)) {
            return console.log("is not a valid ID employee");
        }

        return navigate(ROUTES.employee_folder.custom_route(id));
    };

    return (
        <DialogStyle
            open={openDialog}
            onOpen={() => setOpenDialog(true)}
            onCancel={() => setOpenDialog(false)}
            content={{
                content: (
                    <Flex column hAlign="center" gap="gap.medium">
                        {teams?.map((el, index) => {
                            return (
                                <CardUser
                                    key={el?.id}
                                    avatar={el?.avatar}
                                    full_name={`${el?.first_name} ${el?.last_name}`}
                                    job_title={el?.job_title}
                                    hideTitle={false}
                                    onClick={() => _navigateToEmployee(el?.id)}
                                />
                            );
                        })}
                    </Flex>
                ),
                styles: {
                    overflow: "auto",
                },
            }}
            header={
                <Flex column vAlign="center">
                    <Text as="h3" content="Mon équipe" />
                    <Text
                        styles={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontStyle: "normal",
                            paddingBlock: "16px",
                        }}
                        content={`Liste des personnes managées (${teams?.length})`}
                    />
                </Flex>
            }
            headerAction={{
                icon: <CloseIcon />,
                title: "Close",
                onClick: () => setOpenDialog(false),
            }}
            footer={
                <Flex hAlign="center" column gap="gap.medium">
                    <Divider size={1} style={{ minWidth: "100%" }} />
                    <Button
                        primary
                        onClick={() => setOpenDialog(false)}
                        content={"Fermer"}
                    />
                </Flex>
            }
            trigger={<ShowAllTeamLink content="Voir toute l'équipe" />}
        />
    );
};

export default ShowAllTeamDialog;
const ShowAllTeamLink = styled(Text)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    color: #5b5fc7;
`;
const DialogStyle = styled(Dialog)`
    @media ${DEVICE.laptop} {
        max-width: 391px;
        max-height: 764px;
    }
    @media ${DEVICE.mobileS} {
        max-width: calc(100vw - 32px);
        max-height: calc(100vh - 80px);
        margin-top: 50px;
    }
`;

import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const Overlay = ({ content, Background = () => null }) => {
    return (
        <Container vAlign="center" hAlign="center" fill>
            <Background />
            <OverlayText>{content}</OverlayText>
        </Container>
    );
};

export default Overlay;

const OverlayText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    width: 80%;
    color: #242424;
`;

const Container = styled(Flex)`
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.9);
`;

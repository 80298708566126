import { BASE_URL_API } from "../../constants";
import fetcher from "../../utils/fetcher";

export const _api_approve_task = ({ task_id, assigned_user_id }) => {
    return fetcher(
        BASE_URL_API +
            "/user/" +
            assigned_user_id +
            "/task/" +
            task_id +
            "/approve",
        {
            method: "PUT",
            credentials: "include",
        }
    );
};

export const _api_refuse_task = ({ task_id, assigned_user_id }) => {
    return fetcher(
        BASE_URL_API +
            "/user/" +
            assigned_user_id +
            "/task/" +
            task_id +
            "/refuse",
        {
            method: "PUT",
            credentials: "include",
        }
    );
};

import React from "react";
import { useState } from "react";
import {
    AcceptIcon,
    Accordion,
    Button,
    CallVideoIcon,
    Flex,
    Loader,
    Text,
    ExcelColorIcon,
} from "@fluentui/react-northstar";
import moment from "moment/moment";
import styled from "styled-components";

import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { Save16Regular } from "@fluentui/react-icons";
import Header from "../../components/Accordions/Header";
import ContentEmpty from "../../components/Accordions/ContentEmpty";
import { I18N_NS_EXPENSE, QUERY_KEY } from "../../constants";
import { _api_download_excel_of_year, _api_get_history_teams } from "../../api";
import { Container, ContainerFluid } from "../../../../common/styles";
import SliderYears from "../../../../components/shared/SliderYears";
import ContentManager from "../../components/Accordions/ContentManager";
import { useNavigate } from "react-router-dom";
import { debounce } from "../../../../utils/global";

/**
 * FORMAT DATA FOR ACCORDION COMPONENT
 */
const get_content_panel = ({ currentYear, data }) => {
    return data?.months?.map((el) => {
        return {
            key: el?.month_idx,
            title: (
                <Header
                    month={el?.month_name}
                    month_index={el.month_idx}
                    currency={el?.currency}
                    total={el?.total_approved_amount}
                    year={currentYear}
                    isMonthDownloadable={el?.expense_reports?.length > 0}
                />
            ),
            content: el?.expense_reports?.length ? (
                <ContentManager content={el?.expense_reports} />
            ) : (
                <ContentEmpty />
            ),
        };
    });
};

const ExpensesReportHistoryTeams = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const navigate = useNavigate();

    const [currentYear, setCurrentYear] = useState(
        parseInt(moment().format("YYYY"))
    );

    const { data = [], isLoading } = useQuery(
        [QUERY_KEY + "::history_teams", currentYear],
        ({ queryKey }) => {
            const year = queryKey[1];
            return _api_get_history_teams({ year });
        }
    );

    const _goback = () => {
        return navigate(-1);
    };

    const _onChangeSliderYear = debounce((year) => {
        // if (year === currentYear) {
        //     return;
        // }
        console.log({ currentYear: year });
        setCurrentYear(year);
    }, 400);

    const { isLoading: is_loading_excel, refetch: refetch_export_excel } =
        useQuery(
            [QUERY_KEY + "::export_year", currentYear],
            ({ queryKey }) => {
                const year = queryKey[1];
                return _api_download_excel_of_year({ year });
            },
            {
                enabled: false,
            }
        );

    const content_panel = get_content_panel({ currentYear, data });
    const isAllyearIsEmpty = data?.months?.every(
        (month) => month?.expense_reports?.length === 0
    );

    return (
        <ContainerFluid>
            <Container
                gap="gap.large"
                style={{ marginTop: "24px", paddingInline: "12px" }}
            >
                <Wrapper fill column gap="gap.medium">
                    <Flex fill space="between">
                        <Title>{t("history.title")}</Title>
                        <Button
                            text
                            primary
                            content={t("common.back")}
                            onClick={_goback}
                        />
                    </Flex>
                    <WhiteBoard fill column gap="gap.large">
                        <Flex column gap="gap.small">
                            <SliderYears
                                disableDownloadButton={isAllyearIsEmpty}
                                onChangeYear={_onChangeSliderYear}
                                onClickExport={refetch_export_excel}
                            />
                            <AccordionStyle panels={content_panel} />
                        </Flex>
                        <Flex hAlign="center" fill>
                            <Button
                                content={
                                    <ButtonText
                                        content={t("history.download_year")}
                                    />
                                }
                                icon={<ExcelColorIcon />}
                                loading={is_loading_excel}
                                disabled={isAllyearIsEmpty}
                                onClick={() => refetch_export_excel()}
                                primary
                                flat
                                text
                            />
                        </Flex>
                    </WhiteBoard>
                </Wrapper>
            </Container>
        </ContainerFluid>
    );
};

export default ExpensesReportHistoryTeams;

export const AccordionStyle = styled(Accordion)`
    .ui-accordion__title {
        padding-bottom: 0px;
        padding-top: 0px;
    }
    .Accordion__checkbox--header {
        padding: 12px 8px 12px 12px;
    }
    .ui-accordion__title__content-wrapper > .ui-box {
        background-repeat: no-repeat;
        /* background-position-y: 5px; */
    }
    .ui-accordion__title__content-wrapper {
        background-color: #e8ebfa;
    }
`;

const WhiteBoard = styled(Flex)`
    background-color: white;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    padding: 16px 6px;
`;

const Wrapper = styled(Flex)`
    max-width: 1307px;
    padding-bottom: 40px;
`;

const Title = styled(Text)`
    font-weight: 700;
    font-size: clamp(14px, 2.5vw, 24px);
    line-height: clamp(20px, 2.5vw, 32px);
    color: #242424;
`;

const ButtonText = styled(Text)`
    font-size: clamp(12px, 2.5vw, 16px);
`;

import moment from "moment";
import * as Yup from "yup";
export const BREADCRUMB_LEAVE = [
    {
        label: "Accueil",
        href: "/",
    },
    {
        label: "Gestionnaire des absences",
        href: "/leave",
    },
];

export const MINIMUM_DATE = (min) => {
    min ? console.log({ min }) : console.log("no min");
};

export const SCHEMA = Yup.object().shape({
    LeaveType: Yup.object().required("Ce champ est obligatoire"),
    // justificatif: yup.ref().value().typeError("please").required(),
    negative_balance_alert_accepted: Yup.boolean().when("LeaveType", {
        is: (value) => {
            return value?.allow_negative_balance === true;
        },
        then: Yup.boolean()
            .transform((value, originalValue) => {
                if (!value)
                    return new Yup.ValidationError("Ce champ est obligatoire");
                return value;
            })
            .required(
                "Le champ otherField est obligatoire quand myBooleanField est vrai"
            ),
        otherwise: Yup.boolean(),
    }),
});

export const SCHEMARH = Yup.object({
    LeaveType: Yup.object().required("Ce champ est obligatoire"),
});

export const FILE_SIZE_LIMIT = 2_500_000;

export const I18N_NS_LEAVE = "leave";

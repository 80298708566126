import {
    ApprovalsAppbarIcon,
    Button,
    Flex,
    MenuButton,
    MoreIcon,
    SendIcon,
    Text,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { I18N_NS_LEAVE } from "../../../../../features/leave/constants";
import { DEVICE } from "../../../../../constants/responsive";
import {
    ButtonText,
    WrapperButton,
} from "../../../../../features/new_time_reporting/components/MyTeam/components/StatusLeaveDetail";
import { useMutation } from "react-query";
import { api_remind_leave } from "../../../../../features/leave/api";
import { useToastGlobal } from "../../../../../stores/useToastGlobal";

const get_data_depending_status = ({ status_id, date_approuved }) => {
    const { t } = useTranslation(I18N_NS_LEAVE);

    let data = {
        content: "",
        background: "#fff",
        color: "#000",
    };

    switch (status_id) {
        case 1: {
            data = {
                content: t("timeline.badges.pending"),
                background: "#FBF6D9",
                border: "#EDC2A7",
                color: "#CC4A31",
            };
            break;
        }
        case 2: {
            data = {
                content: t("timeline.badges.validated", { date_approuved }),
                border: "#BDDA9B",
                background: "#E7F2DA",
                color: "#13A10E",
            };
            break;
        }
        case 3: {
            data = {
                content: t("timeline.badges.validated", { date_approuved }),
                border: "#BDDA9B",
                background: "#E7F2DA",
                color: "#13A10E",
            };
            break;
        }

        default:
            data;
    }
    return data;
};

const getTextSentenceDependStatus = ({
    status_id,
    email_waiting_approval_by,
    email_validated_validator,
}) => {
    switch (status_id) {
        case 1: {
            return (
                <Flex gap="gap.smaller">
                    <Text content={"En attente de "} />
                    <MailTo href={`mailto:${email_waiting_approval_by}`}>
                        {email_waiting_approval_by}
                    </MailTo>
                </Flex>
            );
        }
        case 2: {
            return (
                <Flex gap="gap.smaller">
                    <Text content={"Validée par "} />
                    <MailTo href={`mailto:${email_validated_validator}`}>
                        {email_validated_validator}
                    </MailTo>
                </Flex>
            );
        }
        case 3: {
            return (
                <Flex>
                    <Text content={"Validation automatique"} />
                </Flex>
            );
        }

        default:
            return "";
    }
};

const Badge = ({ status_id, date_approuved }) => {
    const data = get_data_depending_status({
        date_approuved,
        status_id,
    });

    return (
        <CustomBadge
            vAlign="center"
            hAlign="center"
            background={data.background}
            border={data.border}
        >
            <BadgeText content={data.content} color={data.color} />
        </CustomBadge>
    );
};

const TextDependingStatus = ({
    email_waiting_approval_by,
    status_id,
    email_validated_validator,
}) => {
    const sentence = getTextSentenceDependStatus({
        status_id,
        email_validated_validator,
        email_waiting_approval_by,
    });

    return <>{sentence}</>;
};

const StatusLeave = ({
    email_waiting_approval_by = "",
    email_validated_validator = "",
    status_id,
    date_approuved,
    is_external_validator,
    user_id = null,
    leave_request_id = null,
    authenticated_user_can_remind_external_manager = false,
    onSuccess = () => null,
}) => {
    const toastConfig = useToastGlobal();

    const [open, setOpen] = useState(false);

    const { isLoading, mutate } = useMutation(
        (data) => api_remind_leave(data),
        {
            onSuccess: (data) => {
                if (data?.success) {
                    toastConfig.setShow({
                        visible: true,
                        content: "Relance envoyée avec succès",
                        level: "success",
                    });
                    // fermer la modale de détail
                    onSuccess();
                } else {
                    toastConfig.setShow({
                        visible: true,
                        content: "Erreur lors de l'envoi de la Relance",
                        level: "danger",
                    });
                }
                return setOpen(false);
            },
        }
    );

    return (
        <Container column gap="gap.smaller" hAlign="end">
            {is_external_validator ? (
                <WrapperButton vAlign="center">
                    <ApprovalsAppbarIcon outline size="smaller" />
                    <ButtonText content="En attente de validation" />
                    {authenticated_user_can_remind_external_manager && (
                        <MenuButton
                            open={open}
                            styles={{ cursor: "pointer" }}
                            onOpenChange={(e, { open }) => {
                                if (isLoading) return false;
                                if (
                                    e.target.parentElement.id !==
                                    "button_remind"
                                ) {
                                    // fermer
                                    setOpen(open);
                                }
                            }}
                            trigger={<MoreIcon size="smaller" outline />}
                            title="Open"
                            menu={[
                                <Button
                                    content="Renvoyer le mail"
                                    id="button_remind"
                                    icon={<SendIcon />}
                                    loading={isLoading}
                                    onClick={() => {
                                        mutate({
                                            id_user: user_id,
                                            leave_request_id,
                                        });
                                        return;
                                    }}
                                    text
                                />,
                            ]}
                        />
                    )}
                </WrapperButton>
            ) : (
                <Badge status_id={status_id} date_approuved={date_approuved} />
            )}
            <TextDependingStatus
                email_waiting_approval_by={email_waiting_approval_by}
                email_validated_validator={email_validated_validator}
                status_id={status_id}
            />
        </Container>
    );
};

export default StatusLeave;

const Container = styled(Flex)`
    @media ${DEVICE.mobileS} {
        align-items: flex-start;
    }
    @media ${DEVICE.laptop} {
        align-items: end;
    }
`;

const BadgeText = styled(Text)`
    color: ${({ color }) => color};
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
`;

const CustomBadge = styled(Flex)`
    border-radius: 4px;
    width: fit-content;
    border: 1px solid ${({ border }) => border};
    background-color: ${({ background }) => background};
    height: 24px;
    padding: 4px 8px;
    gap: 4px;
`;

const MailTo = styled.a`
    color: #5b5fc7;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
`;

import React from "react";
import { Button, ChevronStartIcon, Text } from "@fluentui/react-northstar";
import { Container, ContainerFluid } from "../../common/styles";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { DEVICE } from "../../constants/responsive";

const BackHeader = ({
    title = "",
    onClickBack = () => console.log("clicked"),
}) => {
    const navigate = useNavigate();
    return (
        <Wrapper>
            <WrapperMax vAlign="center" gap="gap.medium">
                {/* back button */}
                <Button
                    icon={<ChevronStartIcon />}
                    iconOnly
                    flat
                    onClick={() => navigate(-1)}
                />
                {/* title */}
                <BackHeaderTitle size="medium">{title}</BackHeaderTitle>
            </WrapperMax>
        </Wrapper>
    );
};

export default BackHeader;

const Wrapper = styled(ContainerFluid)`
    background: var(--Color-background-hover, #e8ebfa);
    @media ${DEVICE.mobileS} {
        padding-inline: 24px;
    }
    @media ${DEVICE.laptop} {
        padding-inline: 0px;
    }
`;

const WrapperMax = styled(Container)`
    padding-block: 12px;
`;

const BackHeaderTitle = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    /* Large/400 */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;

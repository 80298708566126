import React, { useState } from "react";
import {
    AddIcon,
    Button,
    Divider,
    Dropdown,
    Flex,
    Input,
    InputLabel,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import { useMutation, useQuery } from "react-query";
import TimeField from "react-simple-timefield";
import styled from "styled-components";
import moment from "moment";

import { extendMoment } from "moment-range";

import { _api_get_my_project, _api_save_slots_day } from "../../api";
import { delete_slot } from "./functions";
import PartialLeave from "./PartialLeave";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";

const SlotmodifyLogic = ({
    month,
    slots,
    itContainPartialLeave = false,
    onCancel,
    day_id,
    refetch,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const extend_moment = extendMoment(moment);

    const [shifts, setShifts] = useState(slots);

    const { data, isLoading } = useQuery(
        ["TIME_REPORTING::my_project"],
        () =>
            _api_get_my_project({
                year: month.year,
                month: month.month_indexed + 1,
            }),
        {
            refetchOnWindowFocus: false,
        }
    );

    const { mutate: mutateSaveSlots, isLoading: isSavingSlotsOfDay } =
        useMutation((data) => _api_save_slots_day(data), {
            onSuccess: (data) => {
                onCancel();
                refetch();
                return;
            },
        });

    if (isLoading) {
        return <Loader size="small" />;
    }

    // console.log({ shift: shifts });

    const projets = data?.map((el) => {
        return {
            id: el?.id,
            header: el?.code + " - " + el?.name,
        };
    });

    const _onClickDeleteSlot = ({ id }) => {
        const new_slots = delete_slot({
            slots: shifts,
            slot_id_to_remove: id,
        });

        setShifts(new_slots);
        return;
    };

    const isNewRangeBetweenSlots = ({ new_range, index }) => {
        for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i];
            const start = shift.start_hour;
            const end = shift.end_hour;

            if ((start !== "00:00:00" || end !== "00:00:00") && i !== index) {
                const new_start = new_range.start;
                const new_end = new_range.end;

                const range1 = extend_moment.range(
                    "2023-01-02T" + new_start,
                    "2023-01-02T" + new_end
                );
                const range2 = extend_moment.range(
                    "2023-01-02T" + start,
                    "2023-01-02T" + end
                );

                const isIntersect = range1.overlaps(range2, {
                    adjacent: false,
                });

                if (isIntersect) {
                    return true;
                }
            }
        }
        return false;
    };

    const _onChangeSlotField = ({ index, value, type }) => {
        const new_shifts = shifts.map((el, i) => {
            if (i === index) {
                const isNewRangeInterfering = isNewRangeBetweenSlots({
                    new_range: {
                        start: type === "field_1" ? value : el?.start_hour,
                        end: type === "field_2" ? value : el?.end_hour,
                    },
                    index,
                });

                const moment_start = moment(
                    type === "field_1" ? value : el?.start_hour,
                    "HH:mm:ss"
                );
                const moment_end = moment(
                    type === "field_2" ? value : el?.end_hour,
                    "HH:mm:ss"
                );

                const isStartAfterEnd =
                    el?.end_hour !== "00:00:00"
                        ? moment_start.isAfter(moment_end) ||
                          moment_start.format("HH:mm:ss") ===
                              moment_end.format("HH:mm:ss")
                        : false;

                const field_one_error = isStartAfterEnd
                    ? true
                    : isNewRangeInterfering;

                const field_two_error = isStartAfterEnd
                    ? true
                    : isNewRangeInterfering;

                return {
                    ...el,
                    start_hour: type === "field_1" ? value : el?.start_hour,
                    end_hour: type === "field_2" ? value : el?.end_hour,
                    errors: {
                        f1: field_one_error,
                        f2: field_two_error,
                    },
                };
            }
            return el;
        });
        setShifts(new_shifts);
        return;
    };

    const _onClickAddSlot = () => {
        return setShifts((state) => {
            return [
                ...state,
                {
                    id: new Date().getTime(), // generate some random ID
                    timesheet: projets[0],
                    start_hour: "00:00:00",
                    end_hour: "00:00:00",
                    manual: true,
                },
            ];
        });
    };

    const onChangeDropdownItem = (value, index) => {
        const new_state = shifts?.map((el, i) => {
            if (i === index) {
                return {
                    ...el,
                    timesheet: value,
                };
            }
            return el;
        });
        setShifts(new_state);
        return;
    };

    const _submitSlots = () => {
        // * verify if some slots is empty
        const is_some_empty_slots = shifts?.filter(
            (el) => el?.start_hour === "00:00:00" || el?.end_hour === "00:00:00"
        );

        if (is_some_empty_slots.length) {
            const shifts_set_errors = shifts?.map((el) => {
                if (
                    el?.start_hour === "00:00:00" ||
                    el?.end_hour === "00:00:00"
                ) {
                    return {
                        ...el,
                        errors: {
                            f1: el?.start_hour === "00:00:00",
                            f2: el?.end_hour === "00:00:00",
                        },
                    };
                }
                return el;
            });
            setShifts(shifts_set_errors);
            return;
        }

        const formated_data = shifts?.map((el) => {
            return {
                id: el?.manual ? 0 : el?.id,
                start_hour: el?.start_hour,
                end_hour: el?.end_hour,
                timesheet_id: el?.timesheet?.id,
            };
        });
        return mutateSaveSlots({ day_id, slots: formated_data });
    };

    return (
        <>
            {shifts.map((el, index) => {
                const isLeave = el?.type?.value === 1;
                if (isLeave) {
                    return;
                }
                const project_id = el?.timesheet?.id;
                const default_projets = projets?.find(
                    (el) => el?.id === project_id
                );
                return (
                    <>
                        {/* body fields */}
                        <Flex vAlign="center">
                            <TimeField
                                value={el?.start_hour}
                                input={
                                    <Input
                                        error={el?.errors?.f1}
                                        placeholder="00:00"
                                        fluid
                                        disabled={el?.locked}
                                    />
                                }
                                colon=":"
                                onChange={(e) =>
                                    _onChangeSlotField({
                                        index,
                                        value: e.target.value + ":00",
                                        type: "field_1",
                                    })
                                }
                            />
                            <Text
                                content={"-"}
                                styles={{ paddingInline: "3px" }}
                            />
                            <TimeField
                                value={el?.end_hour}
                                input={
                                    <Input
                                        error={el?.errors?.f2}
                                        fluid
                                        placeholder="00:00"
                                        disabled={el?.locked}
                                    />
                                }
                                colon=":"
                                onChange={(e) =>
                                    _onChangeSlotField({
                                        index,
                                        value: e.target.value + ":00",
                                        type: "field_2",
                                    })
                                }
                            />
                        </Flex>
                        {/* client */}
                        <Flex fill column gap="gap.small">
                            <Flex column>
                                <InputLabel content={"Code projet"} />
                                <Dropdown
                                    items={projets}
                                    defaultValue={default_projets}
                                    checkable
                                    fluid
                                    onChange={(e, i) =>
                                        onChangeDropdownItem(i?.value, index)
                                    }
                                    getA11ySelectionMessage={{
                                        onAdd: (item) =>
                                            `${item} has been selected.`,
                                    }}
                                    disabled={el?.locked}
                                />
                            </Flex>
                            {/* delete slot */}
                            <ButtonDelete
                                content={"Supprimer le créneau "}
                                onClick={() =>
                                    _onClickDeleteSlot({ id: el?.id })
                                }
                                disabled={
                                    shifts.filter((el) => el?.type?.value !== 1)
                                        ?.length === 1 || el?.locked
                                }
                                text
                            />
                        </Flex>
                        <Divider />
                    </>
                );
            })}

            {itContainPartialLeave && <PartialLeave />}

            {/* footer */}
            <Footer hAlign="center" gap="gap.small" column fill>
                <Button
                    content={t("home.card.modify.button_add_slots")}
                    icon={<AddIcon />}
                    disabled={shifts?.length >= 4}
                    onClick={_onClickAddSlot}
                    text
                    primary
                />
                <Button
                    content={t("common.validate", { ns: "translation" })}
                    onClick={_submitSlots}
                    disabled={shifts?.length === 0}
                    loading={isSavingSlotsOfDay}
                    primary
                    flat
                    fluid
                />
                <Button
                    content={t("common.cancel")}
                    flat
                    text
                    onClick={onCancel}
                />
            </Footer>
        </>
    );
};

export default SlotmodifyLogic;

const Footer = styled(Flex)`
    box-shadow: 0px -0.3px 0.9px rgba(0, 0, 0, 0.07);
    padding-top: 14px;
`;

const ButtonDelete = styled(Button)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: ${({ disabled }) => (disabled ? "#C7C7C7" : "#c4314b")};
`;

import DocIcon from "../../../assets/icons/documents/icons_documents/Avatar_doc.svg";
import JpgIcon from "../../../assets/icons/documents/icons_documents/Avatar_jpg.svg";
import PdfIcon from "../../../assets/icons/documents/icons_documents/Avatar_pdf.svg";
import PngIcon from "../../../assets/icons/documents/icons_documents/Avatar_png.svg";
import XlsIcon from "../../../assets/icons/documents/icons_documents/Avatar_xls.svg";

export const FILE_SIZE_LIMIT = 5_000_000;
export const EXPENSES_ACCEPT_EXTENSIONS = [
    "pdf",
    "jpg",
    "png",
    "jpeg",
    "tiff",
    "webp",
    "heic",
];

export const I18N_NS_DOCUMENTS = "translation";

// join the extensions files with the ICONS
export const ICON_FROM_TYPE_FILES = {
    pdf: PdfIcon,
    doc: DocIcon,
    png: PngIcon,
    xls: XlsIcon,
    xlsx: XlsIcon,
    jpg: JpgIcon,
    jpeg: JpgIcon,
};

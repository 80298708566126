import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const LegendChart = ({ color, name }) => {
    return (
        <Flex vAlign="center" gap="gap.small">
            <Rectangle color={color} />
            <Name content={name} />
        </Flex>
    );
};

export default LegendChart;

const Name = styled(Text)`
    color: #242424;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const Rectangle = styled(Flex)`
    width: 20px;
    height: 16px;
    background-color: ${({ color }) => (color ? color : "#ededed")};
`;

import React from "react";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import styled, { css } from "styled-components";

const Thumbnail = ({ image, index, selected = 1, onClick = () => null }) => {
    return (
        <Container gap="gap.small" column selected={selected} onClick={onClick}>
            <WrapperImage>
                <ImageThumbnail src={image} alt="thumbnail" />
            </WrapperImage>
            <WrapperNumber vAlign="center" hAlign="center">
                <Number content={index} />
            </WrapperNumber>
        </Container>
    );
};

export default Thumbnail;

const ImageThumbnail = styled(Image)`
    border-radius: 2px;
    width: 40px;
    height: 40px;
`;

const Number = styled(Text)`
    color: var(--color-primary);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
`;
const WrapperNumber = styled(Flex)``;
const WrapperImage = styled(Flex)``;
const Container = styled(Flex)`
    padding: 2px;
    border-radius: 4px;
    cursor: pointer;
    ${({ selected }) =>
        selected
            ? css`
                  border: 1px solid var(--color-primary);
                  background: #fff;

                  box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
                      0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
              `
            : css`
                  background: #fff;
                  box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13),
                      0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11);
                  &:hover {
                      opacity: 0.7;
                  }
              `}
`;

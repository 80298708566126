import { BASE_URL_ILLUSTRATIONS } from "../../constants";

export const QUERY_KEY = "TASKS";

export const TABS_VIEWS = { ARRAY: "array", KANBAN: "kanban" };

export const TABS_SWITCHER = {
    MY_TASKS: "MY_TASKS",
    TASK_TO_VALIDATE: "TASK_TO_VALIDATE",
    MY_COMPANY_LIST: "MY_COMPANY_LIST",
};

export const TABS_SWITCHER_AS_ARRAY = [
    TABS_SWITCHER.MY_TASKS,
    TABS_SWITCHER.TASK_TO_VALIDATE,
    TABS_SWITCHER.MY_COMPANY_LIST,
];

// dropdown items
export const DATE_REFERENCES = [
    {
        id: 1,
        header: "Date d’embauche",
    },
    {
        id: 2,
        header: "Date de départ",
    },
    {
        id: 3,
        header: "Date de fin de période d’essai",
    },
    {
        id: 4,
        header: "Date d’anniversaire professionnel",
    },
];
export const DIALOG_ADD_TASK_TO_TEMPLATE__RESPONSABLE_DROPDOWN = [
    {
        id: -1,
        header: "Moi-même",
        image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
        content: "",
    },
    {
        id: -2,
        header: "Le collaborateur",
        image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
        content: "",
    },
    {
        id: -3,
        header: "Son manager",
        image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
        content: "",
    },
    {
        id: -5,
        header: "Son RH",
        image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
        content: "",
    },
];
export const DIALOG_ADD_TASK_TO_TEMPLATE__VALIDATOR_DROPDOWN = [
    {
        id: -1,
        header: "Moi-même",
        image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
        content: "",
    },
    {
        id: -3,
        header: "Son manager",
        image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
        content: "",
    },
    {
        id: -5,
        header: "Son RH",
        image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
        content: "",
    },
];

export const ACTIONS = [
    {
        id: 1,
        header: "à envoyer",
        content: "",
        document_required: false,
        hr_document_required: false,
    },
    {
        id: 2,
        header: "à consulter",
        content: "",
        document_required: true,
        hr_document_required: true,
    },
    {
        id: 3,
        header: "à compléter",
        content: "",
        document_required: true,
        hr_document_required: true,
    },
    {
        id: 4,
        header: "à signer",
        content: "",
        document_required: true,
        hr_document_required: true,
    },
];

// translations
export const PATH_TRANSLATION = `pages.manage_modal_tasks.dialogs.manage_models`;
export const PT_CREATE_TEMPLATE = `pages.manage_modal_tasks.dialogs.add_modal`;

// validators
export const VALIDATOR_ADD_TASK_TEMPLATE = {
    name: {
        required: {
            value: true,
            message: "Le titre est obligatoire",
        },
    },
    category: {
        required: {
            value: true,
            message: "La catégorie est obligatoire",
        },
    },
    description: {
        required: {
            value: true,
            message: "Le descriptif est obligatoire",
        },
    },
    duedate_duration: {
        required: {
            value: true,
            message: "Le nombre de jours est obligatoire",
        },
        min: {
            value: 0,
            message: "Le nombre de jours doit être positif",
        },
        setValueAs: (v) => parseInt(v),
    },
    duedate_event: {
        required: {
            value: true,
            message: "La date de référence est obligatoire",
        },
    },
    assigned_to: {
        required: {
            value: true,
            message: "Le résponsable est obligatoire",
        },
    },
    validator: {
        required: {
            value: true,
            message: "Le validateur est obligatoire",
        },
    },
};

import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import CardUser from "../Cards/CardPositionEntreprise/CardUser";

const SuperviseContent = ({ users = [] }) => {
    return (
        <Flex fill column>
            <WrapperHeader>
                <Text
                    content={`Liste des personnes supervisées (${users?.length})`}
                />
            </WrapperHeader>
            <Body column hAlign="center" gap="gap.medium">
                {users?.map((user) => {
                    return (
                        <CardUser
                            key={user?.id}
                            avatar={user?.avatar}
                            full_name={`${user?.first_name} ${user?.last_name}`}
                            job_title={user?.job_title}
                            hideTitle={true}
                        />
                    );
                })}
            </Body>
        </Flex>
    );
};

export default SuperviseContent;

const Footer = styled(Flex)`
`;

const Body = styled(Flex)`
    height: 400px;
    overflow: scroll;
    padding-block: 24px;
`;

const WrapperHeader = styled(Flex)`
    padding-block: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
`;

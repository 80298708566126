import React, { useEffect } from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import { Outlet, useLocation } from "react-router-dom";

import CustomBreadCrumb from "../../components/breadcrumb";

import { I18N_NS_EXPENSE } from "./constants";

import { BREADCRUMB_HUMBERGER_GLOBAL } from "../../constants/routes/drawer";
import { get_breadcrumb_depending_page } from "./functions";
import { roles_access, ROLES_CODE } from "../../constants/roles";
import HeaderManager from "./components/Header";
import { useWhereIamExpenses } from "./store/useWhereIam.store";
import { useAuthDetailStore } from "../../stores/useAuthStore";
import { isDelegatedToRole } from "../../utils/permissions";

const Expenses = () => {
    const location = useLocation();
    const breadcrumb_config = get_breadcrumb_depending_page(location);
    const store_detail = useAuthDetailStore((state) => state.detail);
    const setWhereIam = useWhereIamExpenses((state) => state.setWhereIam);

    const isAccountingManager = store_detail?.is_accounting_manager;
    const isManager = roles_access({
        usersPrivileges: [ROLES_CODE.manager],
    });

    useEffect(() => {
        return () => {
            setWhereIam({
                isEmployeePage: true,
                isManagerPage: false,
                isAccountingManager: false,
            });
        };
    }, []);

    // voir s'il est delegue
    const isDelegatedForExpense = isDelegatedToRole(["expense"]);

    return (
        <Flex
            column
            styles={{ backgroundColor: "#f0f0f0", minHeight: "100vh" }}
        >
            <CustomBreadCrumb
                humberger={BREADCRUMB_HUMBERGER_GLOBAL}
                navList={breadcrumb_config}
                i18n_ns={I18N_NS_EXPENSE}
            />
            {/* Add this if the user is Manager */}
            {(isManager || isAccountingManager || isDelegatedForExpense) && (
                <HeaderManager />
            )}
            <Outlet />
        </Flex>
    );
};

export default Expenses;

import React from "react";
import {
    Button,
    CalendarIcon,
    Flex,
    PersonIcon,
    Table,
    TableCell,
    TableRow,
    Text,
} from "@fluentui/react-northstar";
import AvatarUser from "../../../components/Avatars";
import MoreMenuItem from "./MoreMenuItem";
import styled from "styled-components";
import { showTheRightName } from "../../../utils/functions";
import Toast from "../../../components/Alerts";
import { TASK_ACTIONS } from "../../../constants/tasks";
import { AVATAR_DEFAULT } from "../../../constants";
import moment from "moment";

const ContentConfirmationTaskList = ({
    tasks = [],
    setTemplateTasks,
    setOpenDialogModifyTask,
    showToast,
}) => {
    return (
        <>
            <Flex column fill gap="gap.medium">
                <Flex>
                    <Toast {...showToast} />
                    <TextStyle>
                        Voici les tâches attribuées au collaborateur suite au
                        paramétrage :
                    </TextStyle>
                </Flex>
                <WrapperTable>
                    <TableStyle>
                        <TableRow header>
                            <TableCell
                                styles={{
                                    flex: 0.3,
                                }}
                                content={
                                    <Button
                                        styles={{ padding: "0" }}
                                        as="span"
                                        text
                                        content={"Nom de la Tâche"}
                                        size="small"
                                    />
                                }
                            />
                            <TableCell
                                styles={{
                                    flex: 0.3,
                                }}
                                content={
                                    <Button
                                        styles={{ padding: "0" }}
                                        as="span"
                                        text
                                        icon={<CalendarIcon />}
                                        content={"Date d'échéance"}
                                        size="small"
                                    />
                                }
                            />
                            <TableCell
                                styles={{
                                    flex: 0.3,
                                }}
                                content={
                                    <Button
                                        styles={{ paddingLeft: "0" }}
                                        as="span"
                                        icon={<PersonIcon />}
                                        text
                                        content={"Responsable"}
                                        size="small"
                                    />
                                }
                            />
                            <TableCell
                                styles={{
                                    flex: 0.1,
                                }}
                                content={""}
                            />
                        </TableRow>

                        {tasks?.length > 0 &&
                            tasks?.map((el, index) => {
                                let {
                                    name,
                                    due_date,
                                    assigned_to_user,
                                    documents_template,
                                    document_action,
                                } = el;

                                document_action =
                                    document_action === 0 ? 5 : document_action;

                                return (
                                    <TableRow key={index}>
                                        <TableCell
                                            styles={{
                                                flex: 0.3,
                                            }}
                                            content={showTheRightName({
                                                name,
                                                document_required:
                                                    TASK_ACTIONS[
                                                        document_action - 1
                                                    ]?.hr_document_required,
                                                documents_template,
                                            })}
                                        />
                                        <TableCell
                                            styles={{
                                                flex: 0.3,
                                            }}
                                            content={moment(due_date).format(
                                                "DD-MM-YYYY"
                                            )}
                                        />
                                        <TableCell
                                            styles={{
                                                flex: 0.3,
                                            }}
                                            content={
                                                <AvatarUser
                                                    key={`avatar_${index}`}
                                                    name={
                                                        assigned_to_user?.name
                                                    }
                                                    image={
                                                        assigned_to_user?.avatar ||
                                                        AVATAR_DEFAULT
                                                    }
                                                    showName={true}
                                                />
                                            }
                                        />
                                        <TableCell
                                            styles={{
                                                flex: 0.1,
                                                justifyContent: "flex-end",
                                            }}
                                            content={
                                                <MoreMenuItem
                                                    setTemplateTasks={
                                                        setTemplateTasks
                                                    }
                                                    setOpenDialogModifyTask={
                                                        setOpenDialogModifyTask
                                                    }
                                                    key={`more points ${index}`}
                                                    task={el}
                                                />
                                            }
                                        />
                                    </TableRow>
                                );
                            })}
                    </TableStyle>
                </WrapperTable>
            </Flex>
        </>
    );
};

export default ContentConfirmationTaskList;

const WrapperTable = styled.div`
    width: 100%;
`;

const TextStyle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #424242;
`;
const TableStyle = styled(Table)`
    width: 100%;
`;

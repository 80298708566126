import {
    Accordion,
    Dropdown,
    Flex,
    FlexItem,
    Input,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BiRegistered } from "react-icons/bi";
import styled from "styled-components";

const template = {
    marginTop: "5px",
    marginBottom: "16px",
    marginRight: "24px",
};

export default function EditConsulting() {
    const client = [
        { key: 1, client: "SNCF" },
        { key: 2, client: "ORANGE" },
        { key: 3, client: "BIOMERIEUX" },
        { key: 4, client: "SANOFI" },
    ];

    const { control, register } = useFormContext();

    return (
        <AccordionStyle>
            <Consulting column style={template} gap="gap.medium">
                <Client column>
                    <FlexItem>
                        <Label content="Client" />
                    </FlexItem>
                    <FlexItem>
                        <Controller
                            control={control}
                            name="Clients"
                            render={({ field: { onBlur, onChange } }) => {
                                return (
                                    <DropdownStyle
                                        defaultValue={"SANOFI"}
                                        label={<Label content="Client" />}
                                        onBlur={onBlur}
                                        onChange={(_, i) =>
                                            onChange(i.value.header)
                                        }
                                        inverted
                                        items={client.map((el, index) => {
                                            return {
                                                header: el.client,
                                                key: index,
                                            };
                                        })}
                                        placeholder="Selectionner un Client"
                                        fluid
                                    />
                                );
                            }}
                        />
                    </FlexItem>
                </Client>
                <Contact column>
                    <FlexItem>
                        <Input
                            defaultValue={"Foued"}
                            {...register("ContactName")}
                            name="ContactName"
                            fluid
                            inverted
                            label={<Label content="Contact" />}
                            type="text"
                        />
                    </FlexItem>
                </Contact>
                <ContactEmail column>
                    <FlexItem>
                        <Input
                            defaultValue={"Hajer@gmail.com"}
                            name="ContactEmail"
                            {...register("ContactEmail")}
                            fluid
                            inverted
                            label={<Label content="Email du contact" />}
                            type="email"
                        />
                    </FlexItem>
                </ContactEmail>
                <Facturation column>
                    <FlexItem>
                        <Input
                            defaultValue={"650€/jours"}
                            name="Facturation"
                            {...register("Facturation")}
                            fluid
                            inverted
                            label={<Label content="Facturation" />}
                            type="text"
                        />
                    </FlexItem>
                </Facturation>
                <EndMissionDate column>
                    <FlexItem>
                        <Label content="Fin de la mission prévue" />
                    </FlexItem>
                    <FlexItem>
                        <DatepickerStyle
                            defaultValue={"2022-12-02"}
                            fluid
                            name="EndMissionDate"
                            {...register("EndMissionDate")}
                            type="date"
                        />
                    </FlexItem>
                </EndMissionDate>
            </Consulting>
        </AccordionStyle>
    );
}

const AccordionStyle = styled(Accordion)`
    .ui-accordion__title {
        padding-bottom: 0px;
    }
    .Accordion__checkbox--header {
        padding-bottom: 10px;
    }
    .ui-accordion__title__content-wrapper > .ui-box {
        background-repeat: no-repeat;
        background-position-y: 5px;
    }
`;

const Consulting = styled(Flex)``;
const Client = styled(Flex)``;
const Contact = styled(Flex)``;
const ContactEmail = styled(Flex)``;
const Facturation = styled(Flex)``;
const EndMissionDate = styled(Flex)``;
const Label = styled(Text)`
    font-size: 12px;
    font-style: normal;
    padding: 4px;
    color: #616161;
`;
const DatepickerStyle = styled(Input)`
    input {
        background-color: white;
        /* width: 254px; */
    }
`;
const DropdownStyle = styled(Dropdown)``;

import { ROLES_CODE } from ".";

const REDIRECT_ROLE = {
    [ROLES_CODE.employee]: "/me/employee/update",
    [ROLES_CODE.manager]: "/me/rh/update",
    [ROLES_CODE.hr]: "/me/rh/update",
    [ROLES_CODE.admin]: "/admin",
};

/**
 *
 * @param {Array} role_code its array of ROLE of the current user connected.
 * @returns
 */
export const profile_redirect_depending_role = (role_code = []) => {
    const isManager =
        role_code.indexOf(ROLES_CODE.manager) === -1 ? false : true;
    const isHr = role_code.indexOf(ROLES_CODE.hr) === -1 ? false : true;
    if (isHr) {
        return REDIRECT_ROLE[ROLES_CODE.hr];
    }
    if (isManager) {
        return REDIRECT_ROLE[ROLES_CODE.manager];
    }
    return REDIRECT_ROLE[ROLES_CODE.employee] || "/dashboard";
};

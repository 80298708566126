import {
    Attachment,
    Flex,
    Text,
    FilesPdfIcon,
    ChevronEndIcon,
    Loader,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
import { ICON_FROM_EXTENSION } from "../../../utils/functions";
import { formatFileSize } from "../../../constants/maths";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { DEVICE } from "../../../constants/responsive";
import { ROUTES } from "../../../constants/routes";
import { AttachmentStyle } from "../../../pages/DashboardTasks/style";
import useMutationReadDocument from "../../hr_documents/hooks/useMutationReadDocument";

export const SocietyDocuments = ({
    company_documents,
    id_folder_society = null,
}) => {
    const navigate = useNavigate();
    const [clickedDocument, setClickedDocument] = useState(null);

    const { mutate: mutate_read_document, isLoading: is_loading_readable } =
        useMutationReadDocument();

    return (
        <FlexContainer column gap="gap.medium">
            {/* styles={{ marginTop: "25px" }} */}
            <DocumentTitle content="Documents de l'entreprise" />
            {company_documents.length > 0 ? (
                <DocumentsSection vAlign="center" space={"between"}>
                    <WrapperAttachment hAlign="center" gap="gap.small">
                        {company_documents?.map((doc, index) => {
                            const icon = ICON_FROM_EXTENSION(doc?.name);
                            return (
                                <AttachmentStyle
                                    key={"doc-" + index}
                                    icon={
                                        is_loading_readable &&
                                        clickedDocument === doc?.id ? (
                                            <Loader size="smaller" />
                                        ) : (
                                            icon
                                        )
                                    }
                                    header={{
                                        content: (
                                            <Flex
                                                styles={{
                                                    width: "170px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                <Text
                                                    content={doc?.display_name}
                                                    truncated
                                                />
                                            </Flex>
                                        ),
                                    }}
                                    description={`${formatFileSize(
                                        doc?.filesize,
                                        2
                                    )} - ${moment(doc?.modified_at).format(
                                        "DD/MM/YYYY"
                                    )}`}
                                    onClick={() => {
                                        setClickedDocument(doc?.id);
                                        mutate_read_document(doc?.id, {
                                            onSuccess: (data) => {
                                                window.open(
                                                    data?.document_url,
                                                    "_blank"
                                                );
                                            },
                                        });
                                    }}
                                    actionable
                                    truncated
                                    style={{ backgroundColor: "white" }}
                                />
                            );
                        })}
                    </WrapperAttachment>
                    <Flex
                        gap="gap.small"
                        vAlign="center"
                        onClick={() => {
                            navigate(
                                `${ROUTES.rh_document}${
                                    id_folder_society
                                        ? "?doc=" + id_folder_society
                                        : ""
                                }`
                            );
                        }}
                    >
                        <ShowAllDocLink content="Voir les documents d'entreprises >" />
                    </Flex>
                </DocumentsSection>
            ) : (
                <DocumentsSection vAlign="center" hAlign="center">
                    <EmptyDataText
                        color="brand"
                        content="Aucun document disponible"
                    />
                </DocumentsSection>
            )}
        </FlexContainer>
    );
};

const EmptyDataText = styled(Text)`
    color: #616161;
`;

const DocumentsSection = styled(Flex)`
    padding: 16px;
    /* gap: 66px; */
    min-height: 80px;
    background: #ffffff;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 15px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const ShowAllDocLink = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    color: #0067b8;
`;

const DocumentTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #242424;
    @media ${DEVICE.mobileS} {
        padding: 0px 0px 0px 0px;
    }
    @media ${DEVICE.laptop} {
        padding: 0px 0px 0px 12px;
    }
`;

const FlexContainer = styled(Flex)`
    @media ${DEVICE.mobileS} {
        padding-top: 26px;
        padding-inline: 16px;
    }
    @media ${DEVICE.laptop} {
        padding-top: 0px;
        padding-inline: 0px;
    }
`;

const WrapperAttachment = styled(Flex)`
    width: 100%;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 15px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

import { Button, Flex, Input, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const EmergencyContactFields = ({
    register,
    index,
    watch,
    resetField,
    removeContact,
}) => {
    const { emergency_contacts } = watch();
    return (
        <Flex column gap="gap.small">
            <Flex vAlign="center" space="between">
                <Title content={"Contact d’urgence " + (index + 1)} />
                {index === 1 && (
                    <Button
                        content={{
                            content: "supprimer",
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeContact(index);
                            return;
                        }}
                        styles={{
                            color: "#C4314B",
                            textDecoration: "underline",
                        }}
                        text
                        size="small"
                    />
                )}
            </Flex>
            <FlexStyle gap="gap.small" column>
                <Flex gap="gap.small" fill>
                    <Input
                        {...register(`emergency_contacts.${index}.last_name`)}
                        label="Nom"
                        value={emergency_contacts?.at(index)?.last_name}
                        error={
                            index === 0 &&
                            emergency_contacts?.at(0)?.last_name === ""
                        }
                        fluid
                    />
                    <Input
                        {...register(`emergency_contacts.${index}.first_name`)}
                        label="Prénom"
                        value={emergency_contacts?.at(index)?.first_name}
                        error={
                            index === 0 &&
                            emergency_contacts?.at(0)?.first_name === ""
                        }
                        fluid
                    />
                </Flex>
                <Flex gap="gap.small" fill>
                    <Input
                        {...register(
                            `emergency_contacts.${index}.relationship_with_user`
                        )}
                        label="Lien avec cette personne"
                        value={
                            emergency_contacts?.at(index)
                                ?.relationship_with_user
                        }
                        error={
                            index === 0 &&
                            emergency_contacts?.at(0)
                                ?.relationship_with_user === ""
                        }
                        fluid
                    />
                    <Input
                        {...register(
                            `emergency_contacts.${index}.phone_number`
                        )}
                        value={emergency_contacts?.at(index)?.phone_number}
                        error={
                            index === 0 &&
                            emergency_contacts?.at(0)?.phone_number === ""
                        }
                        fluid
                        label="Numéro de téléphone"
                    />
                </Flex>
            </FlexStyle>
        </Flex>
    );
};

export default EmergencyContactFields;

const Title = styled(Text)`
    color: #0078d4;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
`;

const FlexStyle = styled(Flex)`
    position: relative;
    padding-left: 8px;
    ::before {
        content: "";
        position: absolute;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: #0078d4;
        border-radius: 2px;
    }
`;

import fetcher from "../../utils/fetcher";
import { BASE_URL_API } from "../../constants";

export const _api_create_template = (data) => {
    return fetcher(`${BASE_URL_API}/task/template/create`, {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
    });
};

export const _api_delete_template = (id) => {
    return fetcher(`${BASE_URL_API}/task/template/${id}/delete`, {
        method: "DELETE",
        credentials: "include",
    });
};

export const _api_update_template = (data) => {
    return fetcher(`${BASE_URL_API}/task/template/${data?.id}/update`, {
        method: "PUT",
        body: JSON.stringify(data),
        credentials: "include",
    });
};

export const _api_assignation_tasks_user = (tasks) => {
    return fetcher(`${BASE_URL_API}/task/create`, {
        method: "POST",
        body: JSON.stringify({
            step: "create_multiple_tasks",
            tasks,
        }),
        credentials: "include",
    });
};

import React from "react";

import { Flex, Image, LockIcon, Text } from "@fluentui/react-northstar";
import { Container, ContainerFluid } from "../../common/styles";
import styled from "styled-components";

import StaticSide from "./components/StaticSide";
import Footer from "./components/Footer";
import DynamicSide from "./components/DynamicSide";
import PasswordPage from "./components/PasswordPage";

const PreOnboarding = () => {
    return (
        <ContainerFluid column>
            <Container styles={{ gap: "111px" }} vAlign="center">
                <StaticSide />
                <PasswordPage />
            </Container>
            {/* footer  */}
            <Container hAlign="center">
                <Footer />
            </Container>
        </ContainerFluid>
    );
};

export default PreOnboarding;

import React, { useEffect, useState } from "react";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import {
    ChevronLeft24Regular,
    ChevronRight24Regular,
} from "@fluentui/react-icons";
import moment from "moment";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE } from "../../features/expenses/constants";

const CURRENT_YEAR = parseInt(moment().format("YYYY"));

const SliderYears = ({
    hideDownloadButton = false,
    disableDownloadButton = false,
    onChangeYear = () => {},
    onClickExport = () => console.log("clicked on export by year"),
}) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    const [currentYear, setCurrentYear] = useState(CURRENT_YEAR);

    // useEffect(() => {
    // }, [currentYear, onChangeYear]);

    const _decrementYear = () => {
        setCurrentYear((state) => --state);
        onChangeYear(currentYear - 1);
        return;
    };

    const _incrementYear = () => {
        setCurrentYear((state) => ++state);
        onChangeYear(currentYear + 1);
        return;
    };

    return (
        <Flex fill>
            <Flex fill column>
                <TextYear>
                    {t("history.slider.year")} {currentYear}
                </TextYear>
                {!hideDownloadButton && (
                    <Export
                        onClick={() =>
                            disableDownloadButton ? null : onClickExport()
                        }
                        disabled={disableDownloadButton}
                    >
                        {t("history.slider.export")}
                    </Export>
                )}
            </Flex>
            <Flex fill gap="gap.medium" hAlign="end">
                <Button
                    icon={<ChevronLeft24Regular />}
                    flat
                    iconOnly
                    size="small"
                    onClick={_decrementYear}
                />
                <Button
                    onClick={_incrementYear}
                    icon={<ChevronRight24Regular />}
                    disabled={CURRENT_YEAR === currentYear}
                    flat
                    iconOnly
                    size="small"
                />
            </Flex>
        </Flex>
    );
};

export default SliderYears;

const Export = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: clamp(10px, 2vw, 14px);
    line-height: 16px;
    color: ${({ disabled }) => (disabled ? "lightgrey" : "#5b5fc7")};
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

const TextYear = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    color: #424242;
`;

import React from "react";
import { Flex, Input, SearchIcon, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE } from "../../../constants";
import {
    CurrencyCode,
    CurrencyName,
    WrapperListCurrencies,
    WrapperRowCurrency,
} from "./style";
import { _api_get_categories } from "../../../api";
import { useState } from "react";

const CustomSearchIcon = (
    <SearchIcon styles={{ color: "var(--color-primary)" }} />
);

const RowCurrency = ({
    currency_code,
    currency_name,
    currency_rate,
    onSelectCurrency,
}) => {
    return (
        <WrapperRowCurrency
            fill
            onClick={() => onSelectCurrency(currency_code, currency_rate)}
        >
            <CurrencyCode content={currency_code} />
            <CurrencyName content={currency_name} />
        </WrapperRowCurrency>
    );
};

const ModalContentListCurrencies = ({
    list_currencies = [],
    onSelectCurrency = () => null,
}) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    const [sortCurrencies, setSortCurrencies] = useState(list_currencies);
    /**
     * @param {string} searchValue récuperer la valeur du champs de recherche
     * @returns
     */
    const searchCurrency = (searchValue) => {
        const filtredCurrencies = list_currencies.filter(
            (f) =>
                f.code.toLowerCase() === searchValue ||
                f.name.toLowerCase().includes(searchValue)
        );
        return setSortCurrencies(filtredCurrencies);
    };

    return (
        <Flex column>
            {/* SEARCH INPUT */}
            <Flex fill>
                <Input
                    icon={CustomSearchIcon}
                    placeholder={t(
                        "dialogs.dialog_list_currencies.placeholders.search_currency"
                    )}
                    onChange={(e) => searchCurrency(e.target.value)}
                    fluid
                />
            </Flex>
            {/* RESULTS */}
            <WrapperListCurrencies column>
                {sortCurrencies?.map((currency) => {
                    return (
                        <RowCurrency
                            key={currency.code}
                            currency_code={currency.code}
                            currency_name={currency.name}
                            currency_rate={currency.rate}
                            onSelectCurrency={onSelectCurrency}
                        />
                    );
                })}
            </WrapperListCurrencies>
        </Flex>
    );
};

export default ModalContentListCurrencies;

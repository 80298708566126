import React from "react";
import { ArrowLeftIcon, Button, Flex, Text } from "@fluentui/react-northstar";
import { CheckmarkCircle32Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";

const UpdateNewPassword = () => {
    const navigate = useNavigate();
    return (
        <Flex gap="gap.large" column>
            <Flex gap="gap.small" column>
                <Flex hAlign="center" column>
                    <Text content="Définir un mot de passe" />
                    <CheckmarkCircle32Regular style={{ color: "green" }} />
                </Flex>
                <Text content="Mot de passe défini" />
                <Text
                    content={"Vous venez de définir un nouveau mot de passe"}
                />
            </Flex>
            <Button
                // styles={{ paddingBottom: "10px" }}
                text
                icon={<ArrowLeftIcon size="small" />}
                onClick={() => navigate("/login")}
                fluid
                content="Se connecter maintenant"
            />
        </Flex>
    );
};
export default UpdateNewPassword;

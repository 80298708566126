import React, { useState } from "react";
import {
    MonthName,
    Header,
    Content,
    HoursworkedPerMonth,
    WeekDetail,
    IndisponibleText,
    HeaderDialogText,
    InProgressText,
    OpenCard,
} from "./index.style";
import {
    CloseIcon,
    ComposeIcon,
    Dialog,
    Flex,
    Skeleton,
    Text,
} from "@fluentui/react-northstar";
import { CalendarCheckmark20Regular } from "@fluentui/react-icons";

import MonthDetail from "../MonthDetail";

import { format_number } from "../../../../constants/maths";
import HourlyBadge from "../HourlyBadge";
import ContentDetailMonth from "../ContentDetailMonth/ContentDetailMonth";
import { DialogStyle } from "../../../../pages/DashboardTasks/style";
import { useQuery } from "react-query";
import { _api_get_monthly_tracking } from "../../api";

const CARD_TYPE = {
    open: {
        background: "#fff",
        headerBackground: "white",
        monthNameColor: "#616161",
        icon: <CalendarCheckmark20Regular color="#616161" />,
    },
    in_progress: {
        background: "#5b5fc7",
        headerBackground: "#5b5fc7",
        monthNameColor: "white",
        icon: null,
    },
    indisponible: {
        background: "#fff",
        headerBackground: "white",
        monthNameColor: "#C7C7C7",
        icon: null,
    },
    submited: {
        background: "#fff",
        headerBackground: "white",
        monthNameColor: "#616161",
        icon: null,
    },
};

/**
 * @typedef {Object} PropsType
 * @property {("open"|"in_progress"|"indisponible"|"submited")} status
 * @property {number} HoursWorked
 * @property {number} hourBadge
 * @property {string} month
 *
 */
/**
 * Monthly card c'est la card ou on affiche le mois, nbre d'heure travaillé,
 * badge des heures et le dialog de détail par semaine,
 * les props que j'ai besoin sont : status + HoursWorked + hourBadge + month
 * @param {PropsType} props
 * @returns {ReactNode}
 */

const MonthlyCard = ({
    month,
    HoursWorked,
    hourBadge,
    weeks,
    loading,
    status,
    month_index,
    year,
}) => {
    const {
        border,
        background,
        headerBackground,
        monthNameColor,
        opacity,
        icon,
    } = CARD_TYPE[status];

    const [openDialog, setOpenDialog] = useState(false);

    const { data, isLoading, isFetching } = useQuery(
        ["time_reporting::get_monthly_tracking", month_index],
        () => {
            return _api_get_monthly_tracking({
                month_index,
                year,
            });
        },
        {
            enabled: openDialog === true,
        }
    );

    return (
        <OpenCard
            background={background}
            opacity={opacity}
            fill
            column
            hAlign="center"
        >
            <Header
                headerBackground={headerBackground}
                border={border}
                hAlign="center"
                vAlign="center"
            >
                {loading ? (
                    <Flex vAlign="center" hAlign="center">
                        <Skeleton animation="pulse">
                            <Skeleton.Line width="100px" height="10px" />
                        </Skeleton>
                    </Flex>
                ) : (
                    <Flex vAlign="center" gap="gap.smaller">
                        {icon}
                        <MonthName
                            monthNameColor={monthNameColor}
                            content={month}
                        />
                    </Flex>
                )}
            </Header>
            {(status === "open" || status === "submited") && (
                <Flex
                    fill
                    styles={{
                        gap: "32px",
                        minHeight: "183px",
                    }}
                    hAlign="center"
                    column
                >
                    <Content column hAlign="center">
                        {loading ? (
                            <Flex hAlign="center">
                                <Skeleton animation="pulse">
                                    <Skeleton.Line width="80px" height="20px" />
                                </Skeleton>
                            </Flex>
                        ) : (
                            <HoursworkedPerMonth content={`${HoursWorked} h`} />
                        )}
                        {loading ? (
                            <Flex
                                styles={{ marginTop: "15px" }}
                                hAlign="center"
                            >
                                <Skeleton animation="pulse">
                                    <Skeleton.Line width="50px" height="30px" />
                                </Skeleton>
                            </Flex>
                        ) : (
                            <HourlyBadge
                                hourWorked={format_number(hourBadge)}
                                status={
                                    hourBadge > 0
                                        ? "positive"
                                        : hourBadge < 0
                                        ? "negative"
                                        : "zero"
                                }
                            />
                        )}
                    </Content>
                    <DialogStyle
                        open={openDialog}
                        cancelButton="Fermer"
                        onCancel={() => setOpenDialog(false)}
                        header={
                            <Flex border={border} hAlign="center" fill>
                                <Text content="Détail d'un mois" />
                            </Flex>
                        }
                        trigger={
                            loading ? (
                                <Flex
                                    styles={{ marginTop: "15px" }}
                                    hAlign="center"
                                >
                                    <Skeleton animation="pulse">
                                        <Skeleton.Line
                                            width="100px"
                                            height="10px"
                                        />
                                    </Skeleton>
                                </Flex>
                            ) : (
                                <WeekDetail
                                    onClick={() => setOpenDialog(true)}
                                    iconPosition={"after"}
                                    icon={<ComposeIcon />}
                                    text
                                    content="Détail par semaine"
                                />
                            )
                        }
                        content={
                            <ContentDetailMonth
                                special_days={data?.special_days}
                                declared_hours_count={
                                    data?.declared_hours_count
                                }
                                difference={data?.difference}
                                expected_hours_count={
                                    data?.expected_hours_count
                                }
                                weeks={data?.weeks}
                            />
                        }
                        headerAction={
                            <CloseIcon onClick={() => setOpenDialog(false)} />
                        }
                    />
                </Flex>
            )}
            <Flex
                fill
                vAlign="center"
                styles={{ gap: "32px" }}
                hAlign="center"
                column
            >
                {status === "in_progress" && (
                    <InProgressText content="mois ouvert" />
                )}
                {status === "indisponible" && (
                    <IndisponibleText content="indisponible" />
                )}
            </Flex>
        </OpenCard>
    );
};

export default MonthlyCard;

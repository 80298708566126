import React, { useEffect, useRef, useState } from "react";
import { Item, ListDropdown, TextType, Wrapper } from "./index.style";
import {
    AcceptIcon,
    AddIcon,
    ChevronDownIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import {
    Home20Regular,
    VehicleCarProfileLtr20Regular,
} from "@fluentui/react-icons";

const ITEMS = [
    {
        id: 1,
        content: <TextType content="Télétravail" />,
        icon: <Home20Regular color="var(--color-primary)" />,
    },
    {
        id: 2,
        content: <TextType content="Déplacement" />,
        icon: <VehicleCarProfileLtr20Regular color="var(--color-primary)" />,
    },
];

const DropdownRemote = ({
    onChangeItem,
    defaultValues = {
        selectedIndexItem: 1,
    },
}) => {
    const [open, setOpen] = useState(false);
    const [selectedIndexItem, setSelectedIndexItem] = useState(
        defaultValues.selectedIndexItem
    );

    const ref = useRef();

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
        return;
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const onChangeSelection = (index) => {
        setSelectedIndexItem(index);
        onChangeItem(index); // pour envoyer cote back
        return setOpen(false);
    };

    const selectedItem = ITEMS.find((el) => el.id === selectedIndexItem);

    return (
        <Wrapper column ref={ref}>
            <Item
                vAlign="center"
                styles={{ cursor: "pointer" }}
                space="between"
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <Flex vAlign="center" gap="gap.smaller">
                    {selectedItem?.icon}
                    <TextType>{selectedItem?.content}</TextType>
                </Flex>
                <ChevronDownIcon rotate={open ? 180 : 0} />
            </Item>
            {/* list */}
            {open && (
                <ListDropdown column>
                    {ITEMS.map((item, index) => {
                        return (
                            <Item
                                vAlign="center"
                                onClick={() => onChangeSelection(item.id)}
                                space="between"
                                styles={{ paddingInline: "8px!important" }}
                            >
                                <Flex vAlign="center" gap="gap.smaller">
                                    {item.icon}
                                    <TextType>{item.content}</TextType>
                                </Flex>
                                {item.id === selectedIndexItem && (
                                    <AcceptIcon
                                        size="small"
                                        styles={{
                                            color: "var(--color-primary)",
                                        }}
                                    />
                                )}
                            </Item>
                        );
                    })}
                </ListDropdown>
            )}
        </Wrapper>
    );
};

export default DropdownRemote;

import { Flex, Skeleton, TableCell, TableRow } from "@fluentui/react-northstar";
import React from "react";
import {
    FlexCellAction,
    FlexCellComment,
    FlexCellDates,
    FlexCellEmployee,
    FlexCellType,
    Row,
} from "../ajustementSolde/SoldeAjustement";

const SkeletonListAjustments = () => {
    return (
        <>
            <Skeleton animation="pulse">
                {new Array(10).fill("").map((row, i) => {
                    return (
                        <Flex key={i} column>
                            <Row space="between" vAlign="center">
                                <FlexCellDates>
                                    <Flex vAlign="center">
                                        <Skeleton.Line
                                            width="70px"
                                            height="24px"
                                        />
                                    </Flex>
                                </FlexCellDates>
                                {/* avatar + name of the collaborateur */}
                                <FlexCellEmployee>
                                    <Flex vAlign="center" gap="gap.small">
                                        <Skeleton.Shape
                                            round
                                            width="32px"
                                            height="32px"
                                        />

                                        <Skeleton.Line
                                            width="120px"
                                            height="24px"
                                        />
                                    </Flex>
                                </FlexCellEmployee>
                                <FlexCellAction>
                                    <Skeleton.Line
                                        width="150px"
                                        height="24px"
                                    />
                                </FlexCellAction>
                                <FlexCellType
                                    styles={{
                                        width: "200px",
                                    }}
                                >
                                    <Flex
                                        gap="gap.small"
                                        vAlign="center"
                                        space="between"
                                        fill
                                    >
                                        <Skeleton.Line
                                            width="90px"
                                            height="24px"
                                        />
                                    </Flex>
                                </FlexCellType>
                                <FlexCellComment
                                    hAlign="center"
                                    styles={{
                                        width: "40px",
                                    }}
                                >
                                    <Skeleton.Line
                                        width="100px"
                                        height="24px"
                                    />
                                </FlexCellComment>
                            </Row>
                        </Flex>
                    );
                })}
            </Skeleton>
        </>
    );
};

export default SkeletonListAjustments;

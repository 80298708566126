import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    /* BACKGROUNDS */
    --bg-orange : #FFB900;
    --bg-darkGrey : #EBEBEB;
    --bg-green : #6BB700;
    /* colors */
    --color-primary : #5B5FC7;
    --color-danger :  #c4314b;
    /* hvoers */
    --color-primary--hover : hsla(349, 60%, 48%, 0.93);
    /* sizes */
    --header-height : 70px;
  }

  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Segoe UI";
    /* font-family:'Baloo Bhaijaan 2', cursive; */
  }
  
  body {
    /* overflow-x:hidden; */
    min-height:100vh;
  }
  div:has(> .popup) {
    padding: 0px;
    padding-bottom: 16px;
  }
`;

import { Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const EmptyField = styled(Text)`
    color: #424242;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

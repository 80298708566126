import React from "react";
import { FlexHeader } from "./index.style";
import { Avatar, Flex } from "@fluentui/react-northstar";
import { Agency, TextUsername, Header, AvatarStyle } from "..";
import BadgeIcon from "../../../../employee_folder/components/BadgeIcon";
import StatusActionsDependRole, {
    STATUS_ROLE,
} from "../../../components/Badges/StatusActionsDependRole";
import {
    BuildingBank16Filled,
    PeopleTeam16Filled,
} from "@fluentui/react-icons";
import { useMediaQuery } from "react-responsive";
import { SIZES } from "../../../../../constants/responsive";

/**
 * @typedef {Object} PropsType
 * @property {{ first_name: string, last_name: string, avatar : string,  department: string, agency : string }} user
 * @property {{ value : string }} status
 * @property {"ACCOUNTING_MANGER" | "MANAGER" | "EMPLOYEE"} role - use the constant STATUS_ROLE
 *
 *
 * @param {PropsType} props
 * @returns
 */
const HeaderDetailExpenseReport = ({
    user,
    status,
    role,
    // isMobile = false,
}) => {
    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileS})`,
    });
    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });

    const isMobile = isMobileS || isMobileM;

    return (
        <FlexHeader hAlign="center" fill vAlign="center" space="between">
            <Header
                gap="gap.small"
                // fill
                // gap="gap.medium"

                // space="between"
            >
                {/* avatar & username */}
                <Flex gap="gap.small" vAlign="center">
                    <AvatarStyle
                        name={`${user?.first_name} ${user?.last_name}`}
                        image={user?.avatar}
                        size={isMobile ? "medium" : "large"}
                    />
                    {isMobile && (
                        <TextUsername
                            content={`${user?.first_name} ${user?.last_name}`}
                        />
                    )}
                </Flex>
                {/* departement & agence */}
                <Flex fill column>
                    {!isMobile && (
                        <TextUsername
                            content={`${user?.first_name} ${user?.last_name}`}
                        />
                    )}

                    <Agency>
                        <BadgeIcon
                            icon={<PeopleTeam16Filled color=" #616161" />}
                            content={user?.department}
                        />
                        <BadgeIcon
                            icon={<BuildingBank16Filled color=" #616161" />}
                            content={user?.agency}
                        />
                    </Agency>
                </Flex>
            </Header>
            {/* <Flex> */}
            <StatusActionsDependRole role={role} status={status?.value} />
            {/* </Flex> */}
        </FlexHeader>
    );
};

export default HeaderDetailExpenseReport;

import { BASE_URL_API } from "../../../constants";
import { useAuthStore } from "../../../stores/useAuthStore";
import fetcher from "../../../utils/fetcher";

export const _api_list_my_folders = () => {
    return fetcher(BASE_URL_API + "/document/folder/list", {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_documents = async (folder) => {
    return fetcher(BASE_URL_API + "/document/" + folder, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_delete_document = async (document_id) => {
    return fetcher(BASE_URL_API + "/document/" + document_id + "/delete", {
        method: "DELETE",
        credentials: "include",
    });
};

export const _api_read_document = async (document_id) => {
    return fetcher(
        BASE_URL_API + "/document/" + document_id + "/mark_as_read",
        {
            method: "PUT",
            credentials: "include",
        }
    );
};

export const _api_upload_document = async ({
    uploaded_files,
    folder_name = "",
}) => {
    const fd = new FormData();

    for (let i = 0; i < uploaded_files.length; i++) {
        const file = uploaded_files[i];
        fd.append("uploaded_files", file);
    }
    fd.append("folder_name", folder_name);

    const user = useAuthStore.getState().infos;

    return fetch(BASE_URL_API + "/document/upload", {
        method: "POST",
        body: fd,
        headers: {
            Authorization: `Bearer ${user?.access}`,
        },
        credentials: "include",
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (!data.success) {
                throw new Error(data.message);
            }
            return data;
        })
        .catch((error) => {
            return {
                success: false,
                message: error,
                files: [],
            };
        });
};

import {
    Button,
    ChevronStartIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ROUTES } from "../../../../constants/routes";

export default function HeaderUserDetail({ activeTab }) {
    const location = useLocation();
    const { AgencyId } = location.state || {};

    const navigate = useNavigate();
    const goBack = () => {
        return navigate(ROUTES.onboarding.home + "?tab=" + AgencyId);
    };

    return (
        <HeaderContentFlex
            HeaderContentFlexvAlign="center"
            fill
            space="between"
        >
            <Flex gap="gap.medium">
                <Button
                    onClick={goBack}
                    flat
                    iconOnly
                    icon={<ChevronStartIcon />}
                />
                <HeaderText content="Détails du collaborateur" />
            </Flex>
            <RetourButton text onClick={goBack} content={"Retour"} />
        </HeaderContentFlex>
    );
}

const HeaderContentFlex = styled(Flex)`
    padding-block: 24px;
`;

const HeaderText = styled(Text)`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`;

const RetourButton = styled(Button)`
    color: #5b5fc7;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    cursor: pointer;
`;

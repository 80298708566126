import React from "react";
import { Container, ContainerFluid } from "../../../common/styles";
import { Flex, Text } from "@fluentui/react-northstar";
import { Code, ViewCard } from "./index.style";

import StripedTypeLeave from "./StripedTypeLeave";
import AnnualRecovery from "./AnnualRecovery";
import MonthlyCard from "./MonthlyCard";

// import AnnualRecovery from "./AnnualRecovery/AnnualRecovery";
// import MonthlyCard from "./MonthlyCard/MonthlyCard";

const Components = () => {
    return (
        <ContainerFluid>
            <Container column gap="gap.large">
                <Flex styles={{ marginTop: "15px" }}>
                    <Text
                        content="La liste de tout les composants Utilisés dans le time reporting :"
                        weight="bold"
                        size="large"
                    />
                </Flex>
                <Flex column gap="gap.large">
                    <Flex column gap="gap.small">
                        <ViewCard fill gap="gap.medium" column>
                            <Code>
                                <Text
                                    content="<StripedTypeLeave background_color='lightgrey' striped_color='tomato' leave_name='yanis' />"
                                    // content="<AnnualRecovery hours='+8,50' /> "
                                    weight="bold"
                                />
                            </Code>

                            <StripedTypeLeave
                                background_color="lightgrey"
                                striped_color="tomato"
                                leave_name="yanis"
                                is_half_day={false}
                            />
                        </ViewCard>
                    </Flex>
                    <Flex column gap="gap.small">
                        <ViewCard fill gap="gap.medium" column>
                            <Code>
                                <Text
                                    content="<AnnualRecovery hours='+8,50' /> "
                                    weight="bold"
                                />
                            </Code>

                            <AnnualRecovery hours={"+8,50"} />
                        </ViewCard>
                    </Flex>
                    <Flex column gap="gap.small">
                        <ViewCard fill gap="gap.medium" column>
                            <Code>
                                <Text
                                    content="<MonthlyCard status={'open'} day={'Janvier'} HoursWorked='167.75' hourBadge={+1.55} />"
                                    weight="bold"
                                />
                            </Code>
                            <Flex space="between">
                                <MonthlyCard
                                    status={"open"}
                                    day={"Janvier"}
                                    HoursWorked="167.75"
                                    hourBadge={+1.55}
                                />
                                <MonthlyCard
                                    status={"open"}
                                    day={"Février"}
                                    HoursWorked="167.75"
                                    hourBadge={-3.25}
                                />
                                <MonthlyCard
                                    status={"open"}
                                    day={"Mars"}
                                    HoursWorked="167.75"
                                    hourBadge={0.0}
                                />
                                <MonthlyCard
                                    status={"open"}
                                    day={"Avril"}
                                    HoursWorked="167.75"
                                    hourBadge={+1.55}
                                />
                                <MonthlyCard
                                    status={"in_progress"}
                                    day={"Mai"}
                                    // HoursWorked="167.75"
                                    // hourBadge={-2.01}
                                />
                                <MonthlyCard
                                    status={"indisponible"}
                                    day={"Juin"}
                                    // HoursWorked="167.75"
                                    // hourBadge={+1.5}
                                />
                            </Flex>
                        </ViewCard>
                    </Flex>
                </Flex>
            </Container>
        </ContainerFluid>
    );
};

export default Components;

import React from "react";
import { Flex, Skeleton, Divider } from "@fluentui/react-northstar";
import styled from "styled-components";

const CardDaySkeleton = () => {
    return (
        <Wrapper column gap="gap.small" fill space="between">
            <SkeletonStyle animation="pulse">
                <CardHeader space="between" styles={{ padding: "8px" }}>
                    <Skeleton.Shape width="54px" height="48px" />
                    <Flex column space="between" hAlign="end">
                        <Skeleton.Line width="16px" height="16px" />
                        <Skeleton.Line width="33px" height="22px" />
                    </Flex>
                </CardHeader>
                <CardBody space="between" column fill>
                    <Flex fill column hAlign="center">
                        <Flex
                            gap="gap.small"
                            vAlign="center"
                            hAlign="center"
                            fill
                        >
                            <Skeleton.Line width="44px" height="20px" />
                            <Skeleton.Line width="20px" height="20px" />
                            <Skeleton.Line width="44px" height="20px" />
                        </Flex>
                        <Flex column fill gap="gap.medium" hAlign="center">
                            <Skeleton.Line width="150px" height="16px" />
                            <Divider styles={{ height: "1px", width: "90%" }} />
                        </Flex>
                    </Flex>
                    <Flex fill column hAlign="center">
                        <Flex
                            gap="gap.small"
                            vAlign="center"
                            hAlign="center"
                            fill
                        >
                            <Skeleton.Line width="44px" height="20px" />
                            <Skeleton.Line width="20px" height="20px" />
                            <Skeleton.Line width="44px" height="20px" />
                        </Flex>
                        <Flex column fill gap="gap.medium" hAlign="center">
                            <Skeleton.Line width="150px" height="16px" />
                            <Divider styles={{ height: "1px", width: "90%" }} />
                        </Flex>
                    </Flex>
                </CardBody>
            </SkeletonStyle>
        </Wrapper>
    );
};

export default CardDaySkeleton;

const SkeletonStyle = styled(Skeleton)`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const CardBody = styled(Flex)``;

const CardHeader = styled(Flex)`
    box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11),
        0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
    border-radius: 8px 8px 0px 0px;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Wrapper = styled(Flex)`
    min-height: 417px;
    width: 190px;
    background-color: #ffffff;

    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
`;

import React, { useState } from "react";
import { CloseIcon, Dialog } from "@fluentui/react-northstar";
import DialogUpdateAgencyDepartmentContent from ".";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAgencies } from "../../../../../api/helpers";
import { getServices } from "../../../../../api/user";
import { QUERY_KEY } from "../../../constants";
import { _api_update_agency_department } from "../../../api";

const DialogUpdateAgencyDepartment = ({
    open_default,
    default_agency_id,
    default_service_id,
    onClose = () => console.log("closed dialog agency & department !"),
}) => {
    const clientQuery = useQueryClient();

    const [payload, setPayload] = useState({
        agency_id: default_agency_id,
        department_id: default_service_id,
    });

    const { data: agencies, isLoading: isLoadingAgencies } = useQuery(
        [QUERY_KEY + "::agencies"],
        () => getAgencies(),
        {
            refetchOnWindowFocus: false,
        }
    );

    const { data: services, isLoading: isLoadingServices } = useQuery(
        [QUERY_KEY + "::services"],
        () => getServices(),
        {
            refetchOnWindowFocus: false,
        }
    );

    const {
        mutate: mutate_update_agency_department,
        isLoading: is_loading_mutate,
    } = useMutation((data) => _api_update_agency_department(data), {
        onSuccess: (data) => {
            if (data?.success) {
                clientQuery.refetchQueries(QUERY_KEY + "::about");
                clientQuery.refetchQueries(QUERY_KEY + "::header");
                return onClose();
            }
        },
    });

    return (
        <Dialog
            open={open_default}
            header="Modification Agence & Département"
            content={
                <DialogUpdateAgencyDepartmentContent
                    services={services}
                    agencies={agencies}
                    isLoadingAgencies={isLoadingAgencies}
                    isLoadingServices={isLoadingServices}
                    default_agency_id={default_agency_id}
                    default_service_id={default_service_id}
                    onChangeDropdownSelection={setPayload}
                />
            }
            onCancel={onClose}
            headerAction={{
                icon: <CloseIcon />,
                onClick: onClose,
            }}
            confirmButton={{
                content: "Enregistrer",
                flat: true,
                loading: is_loading_mutate,
                onClick: () =>
                    mutate_update_agency_department({
                        agency_id: payload.agency_id,
                        department_id: payload.department_id,
                    }),
            }}
            cancelButton={{
                content: "Annuler",
                flat: true,
            }}
            styles={{
                width: "391px",
            }}
        />
    );
};

export default DialogUpdateAgencyDepartment;

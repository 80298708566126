import React from "react";
import {
    Button,
    Flex,
    Loader,
    ParticipantAddIcon,
} from "@fluentui/react-northstar";
import CustomBreadCrumb from "../../../components/breadcrumb";
import { NAV_LIST } from "../constant";
import { ContainerFluid } from "../../../common/styles";
import { useQuery } from "react-query";
import DetailCollaborator from "./DetailCollaborator";
import { _getAllAgencies } from "../api";

export default function ToStartDetail() {
    const { isLoading, data } = useQuery(
        ["agency_detail"],
        () => _getAllAgencies(),
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {},
        }
    );

    if (isLoading) {
        return <Loader content="Loading ..." />;
    }

    let new_nav = [
        ...NAV_LIST,
        {
            label: "Liste des tâches",
            href: "/onboarding/detail/:id",
        },
    ];

    return (
        <ContainerFluid fill column>
            <CustomBreadCrumb navList={new_nav} />
            <DetailCollaborator />
        </ContainerFluid>
    );
}

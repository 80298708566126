import React from "react";
import { Column, Row, RowDayName } from "../../styles";
import moment from "moment";

const RowsDaysName = ({ isoWeeksAndDays, currentMonth, currentYear }) => {
    const month = moment().get("month") + 1;
    const year = moment().get("year");
    const today = moment().get("D");

    const isToday = currentYear === year && month === currentMonth + 1;
    return (
        <RowDayName nobackground={true}>
            {isoWeeksAndDays.map((week) => {
                return week.columns.map(({ day, weekday }) => {
                    return (
                        <Column
                            vAlign="center"
                            hAlign="center"
                            isToday={isToday && day === today}
                        >{`${weekday} ${day}`}</Column>
                    );
                });
            })}
        </RowDayName>
    );
};

export default RowsDaysName;

import { Button, UserFriendsIcon } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { Container, ContainerFluid } from "../../common/styles";

import { TeamSection } from "./components/TeamSection";
import { ContactKey } from "./components/ContactKey";
import { ActivitySection } from "./components/ActivitySection";
import { MainSection } from "./components/MainSection";
import { MyActivitySection } from "./components/MyActivitySection";
import { SocietyDocuments } from "./components/SocietyDocuments";
import SocietyActivity from "./components/SocietyActivity";
import { useQuery } from "react-query";
import { api_get_new_dashboard_data } from "./api";
import { ROLES_CODE } from "../../constants/roles";
import CustomBreadCrumb from "../../components/breadcrumb";
import { BREADCRUMB_HUMBERGER_GLOBAL } from "../../constants/routes/drawer";
import { BREADCRUMB_HOME } from "../home/contants";
import DisplayTasks from "../home/components/DisplayTasks";
import ChartN2F from "./components/ChartN2F";
import Onboarding from "../onboarding";
import { useNavigate } from "react-router";
import { ROUTES } from "../../constants/routes";
import { Link, useSearchParams } from "react-router-dom";
import Toast from "../../components/Alerts";
import SearchBarFromDashboard from "./components/SearchBarFromDashboard";
import Feedback from "../../components/Feedback";
import { isMobile } from "react-device-detect";
import { Main, RightSide } from "./styles/responsive";
import { PeopleAdd28Regular } from "@fluentui/react-icons";
import LoaderPage from "../../components/loaders/LoaderPage";
import styled from "styled-components";
import { DEVICE } from "../../constants/responsive";
import { useAuthDetailStore } from "../../stores/useAuthStore";
import { logout } from "../../api/auth";

const NewDashboard = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Accueil";
    }, []);

    const [searchParams, setSearchParams] = useSearchParams();
    const url_action = searchParams.get("action");
    const [configToast, setConfigToast] = useState({
        visible: url_action === "ADDED_EMPLOYEE",
        content:
            url_action === "ADDED_EMPLOYEE" &&
            "Collaborateur ajouté avec succès",
        level: "success",
    });

    const {
        data,
        isLoading: isLoadingDashboardData,
        isFetching: isFetchingDashboardData,
    } = useQuery("get_new_dashboard_data", () => api_get_new_dashboard_data(), {
        refetchOnWindowFocus: true,
    });

    // if (isLoadingDashboardData) {
    //     return <LoaderPage />;
    // }

    const Roles_management = data?.me?.roles;
    const isManager = Roles_management?.map((el) => el?.code).includes(
        ROLES_CODE?.manager
    );
    const isRH = Roles_management?.map((el) => el?.code).includes(
        ROLES_CODE?.hr
    );

    const me = data?.me;
    const monthly_activity = data?.monthly_activity;
    const balances = data?.balances;
    const activities = data?.activities;
    const team = data?.team;
    const key_contacts = data?.key_contacts;
    const team_activities = data?.team_activities;
    const expenses = data?.expenses;
    const current_status = data?.current_status;

    const is_pre_onboarding = data?.current_status?.is_pre_onboarding;

    //tasks for collaborator

    // const tasks = myDashboardInfo?.tasks;
    const tasks = data?.tasks;
    // const onboarding_steps = myDashboardInfo?.steps;
    const onboarding_steps = data?.current_status;

    const company_documents = data?.company_documents;
    return (
        <ContainerFluid column>
            <Toast
                onStatusChange={() =>
                    setConfigToast({
                        visible: false,
                        content: "",
                        level: "success",
                    })
                }
                level={configToast.level}
                content={configToast.content}
                visible={configToast.visible}
                dismissible={true}
            />
            <CustomBreadCrumb
                humberger={BREADCRUMB_HUMBERGER_GLOBAL}
                navList={BREADCRUMB_HOME}
                actions={
                    isRH && (
                        <ButtonStyle
                            content={"Ajouter un collaborateur"}
                            icon={<PeopleAdd28Regular outline />}
                            iconPosition="after"
                            onClick={() => navigate(ROUTES.employee_add)}
                            flat
                        />
                    )
                }
                searchBar={<SearchBarFromDashboard />}
            />

            <ContainerFluid
                column
                styles={{
                    backgroundColor: "#fafafa",
                    minHeight: "100vh",
                }}
            >
                <ContainerStyle hAlign="center" gap="gap.medium">
                    {/* Main Content */}
                    <Main column>
                        {/* le main Section contient profile card + activity mensuell card + Absence card  */}
                        <MainSection
                            me={me}
                            monthly_activity={monthly_activity}
                            balances={balances}
                            is_pre_onboarding={is_pre_onboarding}
                            isLoading={isLoadingDashboardData}
                        />
                        <MyActivitySection
                            isManager={isManager}
                            isRH={isRH}
                            activities={activities}
                        />
                        {isRH && (
                            <SocietyDocuments
                                company_documents={company_documents}
                                id_folder_society={data?.company_folder_id}
                            />
                        )}
                        {isManager && <TeamSection team={team} />}
                        {/* {!isManager && !isRH && ( */}

                        {!isRH && current_status?.value === 1 && (
                            <DisplayTasks
                                tasks={tasks}
                                onboarding_steps={onboarding_steps}
                            />
                        )}

                        <ContactKey
                            is_pre_onboarding={is_pre_onboarding}
                            key_contacts={key_contacts}
                        />
                    </Main>
                    {/* Right side - Team Activity + N2F  */}
                    <RightSide column gap="gap.large">
                        <ChartN2F
                            expenses={expenses}
                            isManager={isManager}
                            is_pre_onboarding={is_pre_onboarding}
                            isLoading={isLoadingDashboardData}
                        />
                        {/* <ChartSection /> */}
                        {!is_pre_onboarding && (
                            <SocietyActivity
                                title={
                                    isRH
                                        ? "Activités de l'entreprise"
                                        : "Activités de mon équipe"
                                }
                                company_activities={team_activities}
                            />
                        )}
                        {/* for manager */}
                        {isManager && (
                            <ActivitySection
                                team_activities={team_activities}
                            />
                        )}
                    </RightSide>
                </ContainerStyle>

                <Onboarding />
                <Feedback />
            </ContainerFluid>
        </ContainerFluid>
    );
};

export default NewDashboard;

const ContainerStyle = styled(Container)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
    @media ${DEVICE.laptopL} {
        flex-direction: row;
    }
`;
const ButtonStyle = styled(Button)`
    @media ${DEVICE.mobileS} {
        min-width: 30px;
        padding-inline: 8px;
        .ui-button__content {
            display: none;
        }
        .ui-box {
            margin-left: 0px;
        }
    }
    @media ${DEVICE.tablet} {
        min-width: 96px;
        padding-inline: 20px;
        .ui-button__content {
            display: block;
        }
        .ui-box {
            margin-left: 10px;
        }
    }
    @media ${DEVICE.laptop} {
        .ui-button__content {
            display: block;
        }
    }
`;

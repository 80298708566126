import React, { useState } from "react";
import styled from "styled-components";
import { Card, Title, Wrapper } from "../../styles";
import { Button, Flex, Input, InputLabel } from "@fluentui/react-northstar";
import { MuiPhoneNumberStyle } from "../../../../common/styles";
import {
    useOnboarding,
    useSetEmergencyContact,
} from "../../stores/useOnboarding";
import { useMutation } from "react-query";
import { _api_update_emergency_contact } from "../../api";

const CardEmergencyContact = () => {
    const store_set_emergency_contact = useSetEmergencyContact();
    const {
        welcome_screen_from_back: { data },
    } = useOnboarding();

    const [contact, setContact] = useState({
        name: "",
        lien: "",
        telephone: "",
        dialCode: "",
    });

    const { mutate: mutate_update_emergency_contact, isLoading } = useMutation(
        (data) => _api_update_emergency_contact(data)
    );

    const _activate_button = () => {
        if (
            contact.name === "" ||
            contact.lien === "" ||
            contact.telephone === "" ||
            contact.dialCode === "+" + contact.telephone
        ) {
            return false;
        }
        return true;
    };

    const _save_emergency_contact = () => {
        const { name, lien, telephone } = contact;
        mutate_update_emergency_contact(
            {
                name,
                phone_number: telephone,
                relationship_with_user: lien,
            },
            {
                onSuccess: () => {
                    // save the data on Store..
                    store_set_emergency_contact({
                        name,
                        phone: telephone,
                        link: lien,
                        sent: true,
                    });
                },
            }
        );
    };

    return (
        <Wrapper column>
            <Card column gap="gap.medium">
                <Title>Contact d'urgence</Title>
                <Flex column gap="gap.small">
                    <InputLabelStyle content={"Prénom & Nom"} />
                    <Input
                        placeholder=""
                        fluid
                        onChange={(e) =>
                            setContact((state) => {
                                return { ...state, name: e.target.value };
                            })
                        }
                    />
                    <InputLabelStyle
                        content={"Lien avec le contact d'urgence"}
                    />
                    <Input
                        placeholder=""
                        fluid
                        onChange={(e) =>
                            setContact((state) => {
                                return { ...state, lien: e.target.value };
                            })
                        }
                    />
                    <InputLabelStyle content={"Téléphone"} />
                    <MuiPhoneNumber
                        defaultCountry={data?.country_code?.toLowerCase()}
                        disableAreaCodes={true}
                        countryCodeEditable={false}
                        onChange={(e, i) => {
                            setContact((state) => {
                                return {
                                    ...state,
                                    telephone: e,
                                    dialCode: i.dialCode,
                                };
                            });
                        }}
                    />
                    <Flex hAlign="end">
                        <Button
                            content="Envoyer"
                            primary
                            flat
                            size="medium"
                            disabled={!_activate_button()}
                            onClick={_save_emergency_contact}
                            loading={isLoading}
                        />
                    </Flex>
                </Flex>
            </Card>
        </Wrapper>
    );
};

export default CardEmergencyContact;

const MuiPhoneNumber = styled(MuiPhoneNumberStyle)`
    background-color: rgb(243, 242, 241);
`;

const InputLabelStyle = styled(InputLabel)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #616161;
`;

import React from "react";
import { TextType, Wrapper } from "./index.style";
import { CloseIcon, Flex } from "@fluentui/react-northstar";

const HeaderContent = ({ closePopup, content = "", icon = "" }) => {
    return (
        <Wrapper fill space="between" vAlign="center">
            <Flex gap="gap.small" vAlign=" center">
                {icon}
                <TextType content={content} />
            </Flex>
            <Flex>
                <CloseIcon
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={(e, i) => {
                        e.stopPropagation();
                        e.preventDefault();
                        closePopup();
                    }}
                />
            </Flex>
        </Wrapper>
    );
};

export default HeaderContent;

import moment from "moment";
import { BASE_URL_API } from "../../../constants";
import fetcher from "../../../utils/fetcher";

export const _api_get_remotes = ({ start_date_iso_week }) => {
    return fetcher(
        `${BASE_URL_API}/working_outside/me/${start_date_iso_week}/list`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};

export const _api_get_data_event_by_id = ({ event_id }) => {
    return fetcher(`${BASE_URL_API}/working_outside/item/${event_id}/details`, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_add_day_remote = (data) => {
    const start_date = moment(data?.start_date).format("YYYY-MM-DD");
    const end_date = moment(data?.end_date).format("YYYY-MM-DD");
    const start_time = data?.start_time === 0 ? "AM-S" : "PM-S";
    const end_time = data?.end_time === 0 ? "AM-E" : "PM-E";

    return fetcher(`${BASE_URL_API}/working_outside/create`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            start_date,
            end_date,
            start_time,
            end_time,
            reason: data?.reason,
        }),
    });
};

export const _api_update_day_remote = (data) => {
    const start_date = moment(data?.start_date).format("YYYY-MM-DD");
    const end_date = moment(data?.end_date).format("YYYY-MM-DD");
    const start_time = data?.start_time === 0 ? "AM-S" : "PM-S";
    const end_time = data?.end_time === 0 ? "AM-E" : "PM-E";

    return fetcher(
        `${BASE_URL_API}/working_outside/item/${data?.event_id}/update`,
        {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify({
                start_date,
                end_date,
                start_time,
                end_time,
                reason: data?.reason,
            }),
        }
    );
};

export const _api_delete_day_remote = ({ event_id }) => {
    return fetcher(`${BASE_URL_API}/working_outside/item/${event_id}/delete`, {
        method: "DELETE",
        credentials: "include",
    });
};

import React from "react";
import { WeekText, Wrapper } from "./index.style";
import { Divider, Flex } from "@fluentui/react-northstar";
import { CONFIG_STATUS } from "./constants";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
/**
 * @typedef {Object} PropsType
 * @property {("done"|"saved"|"default")} status - status of the week or month
 * @property {number} week - isoweek number
 */

/**
 * BadgeWeek permet d'afficher le badge par semaine
 * @param {PropsType} PropsType
 * @returns {ReactNode}
 */
const BadgeWeek = ({ status, week }) => {
    const { background, icon } = CONFIG_STATUS[status];
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    return (
        <Wrapper background={background}>
            <WeekText
                content={`${t("home.sections.week.text_week")} ${week}`}
            />
            {icon && (
                <Flex>
                    <Divider vertical />
                    {icon}
                </Flex>
            )}
        </Wrapper>
    );
};

export default BadgeWeek;

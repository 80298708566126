import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    CloseIcon,
    Dialog,
    ExclamationTriangleIcon,
    Flex,
    InfoIcon,
    Loader,
} from "@fluentui/react-northstar";
import styled from "styled-components/macro";
import OrgTree from "react-org-tree";
import { useQuery } from "react-query";
import { Space } from "react-zoomable-ui";
import { useTranslation } from "react-i18next";

import { getOrgTree } from "../../../api/annuaire";
import CardTree from "./CardTree";
import AbsoluteSearchBar from "./AbsoluteSearchBar";
import { useAuthDetailStore } from "../../../stores/useAuthStore";
import { I18N_NS_ANNUAIRE, INIT_CONFIG } from "../constant";
import { ContainerFluid } from "../../../common/styles";
import OrganigramTutoDialog from "./OrganigramTutoDialog";
import { useCallback } from "react";

const Organigram = () => {
    const spaceRef = React.useRef(null);

    const { t } = useTranslation(I18N_NS_ANNUAIRE);
    const { id } = useAuthDetailStore((state) => state.detail);
    const [selectedUser, setSelectedUser] = useState(id);
    const [grab, setGrab] = useState(false);
    const [tutoDialog, setTutodialog] = useState(false); // for tutorial dialog
    const [tutoAlert, setTutoAlert] = useState(true); // for tutorial alert message

    const _show_tutorial_dialog = (bool) => {
        setTutodialog(bool);
        return;
    };

    const _onChangeUser = useCallback((value) => {
        setSelectedUser(value || id);
        return;
    });

    const _onHover = useCallback((e) => {
        if (grab === "grab") {
            return;
        }
        setGrab("grab");
        return;
    });

    const _onUpdated = useCallback((e) => {
        if (grab === "grabbing") {
            return;
        }
        setGrab("grabbing");
        return;
    });

    /**
     * get ORG TREE data from API.
     */
    const {
        data: tree,
        isLoading,
        isFetching,
        isSuccess,
    } = useQuery(
        ["getTree", selectedUser],
        () => {
            return getOrgTree(selectedUser);
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: () => {
                const timeoutId = setTimeout(() => {
                    const element = document.getElementById("highlight");
                    if (element) {
                        spaceRef.current?.viewPort?.camera.centerFitElementIntoView(
                            element,
                            {
                                elementExtraMarginForZoomInClientSpace: 1700,
                            },
                            {
                                durationMilliseconds: 1000,
                            }
                        );
                    }
                    return clearTimeout(timeoutId);
                }, 1000);
            },
        }
    );

    // if (isLoading) return <Loader label={"Chargement Organigramme .."} />;

    if (isSuccess) {
        // show error message when a loop is detected on the backend !
        if (!tree?.success) {
            return (
                <Alert
                    content={t("organigram.errors.loop")}
                    icon={<ExclamationTriangleIcon />}
                    dismissAction={{
                        "aria-label": "close",
                    }}
                    variables={{
                        oof: true,
                    }}
                />
            );
        }
    }

    return (
        <ContainerFluid>
            <AbsoluteSearchBar onChangeUser={_onChangeUser} />
            {/* tutorial dialog */}
            <Dialog
                content={<OrganigramTutoDialog />}
                open={tutoDialog}
                header={t("organigram.dialog_tuto.title")}
                confirmButton={{
                    content: t("organigram.dialog_tuto.confirm_button"),
                    flat: true,
                    size: "small",
                    onClick: () => _show_tutorial_dialog(false),
                }}
                headerAction={{
                    icon: <CloseIcon />,
                    title: t("translation:common.close"),
                    onClick: () => _show_tutorial_dialog(false),
                }}
                onCancel={() => _show_tutorial_dialog(false)}
                styles={{ width: "764px" }}
            />
            <Container hAlign="center" cursor={grab} fill>
                <div style={{ width: "720px" }}>
                    {/* alert message for tutorial */}
                    <Alert
                        content={t("organigram.alerts.tuto")}
                        icon={<InfoIcon />}
                        actions={[
                            {
                                content: t(
                                    "organigram.alerts.button_start_tuto"
                                ),
                                primary: false,
                                flat: true,
                                key: "actions_tuto",
                                onClick: () => _show_tutorial_dialog(true),
                            },
                        ]}
                        dismissible
                        visible={tutoAlert}
                        dismissAction={{
                            "aria-label": "close",
                            onClick: () => {
                                return setTutoAlert(false);
                            },
                        }}
                    />
                </div>
                <SpaceStyle
                    onHover={_onHover}
                    onUpdated={_onUpdated}
                    ref={spaceRef}
                >
                    {!isLoading && !isFetching && (
                        <OrgTree
                            data={tree?.org_chart ?? []}
                            horizontal={INIT_CONFIG.horizontal}
                            collapsable={INIT_CONFIG.collapsable}
                            labelClassName={INIT_CONFIG.labelClassName}
                            expandAll={INIT_CONFIG.expandAll}
                            renderContent={(data) => {
                                return (
                                    <CardTree
                                        data={data}
                                        highlighted={data?.id === selectedUser}
                                    />
                                );
                            }}
                            onClick={(e, data) => {
                                //todo
                                e.preventDefault();
                                e.stopPropagation();
                                console.log("clicked on => ", data);
                                // alert("clicked");
                            }}
                        ></OrgTree>
                    )}
                </SpaceStyle>
            </Container>
        </ContainerFluid>
    );
};

export default Organigram;

const SpaceStyle = styled(Space)`
    display: flex;
    justify-content: center;
    margin-top: 88px;
`;

const Container = styled(Flex)`
    position: relative;
    height: 100vh;
    padding-top: 17px;
    .bg-white {
        box-shadow: none;
        border-radius: 4px;
        padding: 8px 21px 8px 8px;
        background-color: white;
        max-width: 250px;
        width: 250px;
        border: 1px solid #d1d1d1;
        :only-child:has(.highlight) {
            border: 1px solid #9299f7;
        }
    }
    .org-tree-container {
        background-color: #f5f5f5;
    }
    .react-zoomable-ui-inner-div {
        display: flex;
        justify-content: center;
        cursor: ${({ cursor }) => cursor};
    }
`;

import React from "react";
import {
    Accordion,
    Dropdown,
    Flex,
    Input,
    Slider,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";
import SETUP_PROFILE from "../../../constants";
import { useQuery } from "react-query";
import { getContractType } from "../../../../../api/helpers";
import { Radio, RadioGroup } from "@fluentui/react-components";
import { useState } from "react";
import MessageError from "../../components/MessageError";

const REFRESH_TIME = 60 * 2000 * 60;
export default function Contrat() {
    const {
        register,
        control,
        getValues,
        setValue,
        formState: { errors },
    } = useFormContext();

    const [refresh, setRefresh] = useState(0);

    const {
        data: dataContractType,
        isLoading: isLoadingContractType,
        // isFetching: isFetchingContractType,
    } = useQuery(["getContractType"], () => getContractType(), {
        staleTime: REFRESH_TIME,
    });

    if (isLoadingContractType) return <p>Chargement...</p>;

    console.log({ errors });

    return (
        <AccordionStyle>
            <Text
                style={{ marginTop: "5px", marginBottom: "2px" }}
                weight="bold"
                content="Emploi ou qualification"
            />
            <Poste column fill>
                <Label>Poste</Label>
                <Input
                    name="job_title"
                    {...register("job_title")}
                    type={"text"}
                    inverted
                    fluid
                    defaultValue={getValues("job_title")}
                    error={errors?.job_title ? true : false}
                />
                {errors?.job_title && (
                    <MessageError error content={errors.job_title?.message} />
                )}
            </Poste>

            <ContractType column fill>
                <Label>Type de contrat</Label>
                <Controller
                    name="contract.type"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => {
                        return (
                            <Dropdown
                                defaultValue={
                                    dataContractType?.find(
                                        (el) =>
                                            el?.id ===
                                            getValues("contract.type")
                                    )?.name
                                }
                                items={dataContractType?.map((el) => el?.name)}
                                placeholder="Selectionner le type de contrat"
                                onChange={(_, i) => {
                                    // onChange(i.value);
                                    setValue("contract.type", i.value);
                                    setValue(
                                        "contract.type",
                                        dataContractType?.find(
                                            (el) => el?.name === i?.value
                                        )?.id
                                    );
                                }}
                                onBlur={onBlur}
                                inverted
                                fluid
                            />
                        );
                    }}
                />
            </ContractType>

            <Statut column>
                <Label required>Statut</Label>
                <Controller
                    name="contract.status"
                    control={control}
                    render={({ field: { onChange, onBlur } }) => {
                        return (
                            <Dropdown
                                defaultValue={
                                    SETUP_PROFILE.STATUS.find(
                                        (el) =>
                                            el?.id ===
                                            getValues("contract.status")
                                    )?.name
                                }
                                onBlur={onBlur}
                                items={SETUP_PROFILE.STATUS.map((el) => {
                                    return {
                                        key: el.id,
                                        header: el.name,
                                    };
                                })}
                                placeholder="Selectionner un statut"
                                onChange={(_, i) => {
                                    return onChange(i.value.key);
                                }}
                                fluid
                                inverted
                            />
                        );
                    }}
                />
            </Statut>
            {/* A vérifié l'eereur de la position */}
            <Position column fill>
                <Label>Position</Label>
                <Input
                    defaultValue={getValues("contract.position")}
                    name={"contract.position"}
                    {...register("contract.position")}
                    inverted
                    fluid
                    type={"text"}
                    error={errors?.contract?.position ? true : false}
                />

                {errors?.contract?.position && (
                    <MessageError
                        error
                        content={errors.contract?.position?.message}
                    />
                )}
            </Position>

            {/* A vérifié l'eereur de la coefficient */}
            <Coefficient column fill>
                <Label>Coefficient</Label>
                <Input
                    name="contract.coefficient"
                    {...register("contract.coefficient")}
                    defaultValue={getValues("contract.coefficient")}
                    inverted
                    fluid
                    type={"text"}
                    error={errors?.contract?.coefficient ? true : false}
                />
                {errors?.contract?.coefficient && (
                    <MessageError
                        error
                        content={errors?.contract?.coefficient?.message}
                    />
                )}
            </Coefficient>
            <Text
                style={{ marginTop: "25px", marginBottom: "2px" }}
                weight="bold"
                content="Dates"
            />
            <ArrivalDate column fill>
                <Label> Date d'embauche </Label>
                <DatepickerStyle
                    {...register("arrival_date")}
                    defaultValue={getValues("arrival_date")}
                    name="arrival_date"
                    type="date"
                    error={errors?.arrival_date ? true : false}
                />
                {errors?.arrival_date && (
                    <MessageError
                        error
                        content={errors?.arrival_date?.message}
                    />
                )}
            </ArrivalDate>

            <TestPeriod column fill>
                <Label>Date de fin de periode d'essai</Label>
                <DatepickerStyle
                    {...register("contract.probationary_period_end_date")}
                    defaultValue={getValues(
                        "contract.probationary_period_end_date"
                    )}
                    name="contract.probationary_period_end_date"
                    type="date"
                    error={
                        errors?.contract?.probationary_period_end_date
                            ? true
                            : false
                    }
                />
                {errors?.contract?.probationary_period_end_date && (
                    <MessageError
                        error
                        content={
                            errors?.contract?.probationary_period_end_date
                                ?.message
                        }
                    />
                )}
            </TestPeriod>

            <DateDeFinDeContrat column fill>
                <Label>Date de fin de contrat</Label>
                <DatepickerStyle
                    {...register("departure_date")}
                    defaultValue={getValues("departure_date")}
                    name="departure_date"
                    type="date"
                    inverted
                    fluid
                />
            </DateDeFinDeContrat>
            <Text
                style={{ marginTop: "25px", marginBottom: "2px" }}
                weight="bold"
                content="Temps de travail"
            />
            <PartTimePourcentage column fill>
                <Label>Taux d'activité contractuel</Label>
                <Controller
                    name="contract.part_time_percentage"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => {
                        return (
                            <Slider
                                defaultValue={getValues(
                                    "contract.part_time_percentage"
                                )}
                                fluid
                                max={"100"}
                                min="20"
                                onChange={(e, data) => {
                                    onChange(data.value);
                                    setRefresh(new Date().getTime());
                                    return;
                                }}
                                step={10}
                                onBlur={onBlur}
                                value={value}
                            />
                        );
                    }}
                />
                <Label style={{ marginTop: "2px" }}>
                    Temps partiel a :{" "}
                    <b>
                        {getValues("contract.part_time_percentage") || "20"} %
                    </b>
                </Label>
            </PartTimePourcentage>
            <PackageType column fill>
                <Label>Type de Forfait </Label>

                <Controller
                    control={control}
                    name="contract.package_type"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <RadioGroup
                            name="contract.package_type"
                            aria-labelledby="label5"
                            onChange={(_, i) => {
                                onChange(parseInt(i.value));
                            }}
                            onBlur={onBlur}
                            ref={ref}
                            defaultValue={getValues("contract.package_type")}
                        >
                            <Radio
                                label="Heures"
                                onChange={() => {
                                    setValue("contract.package_type", 1);
                                    setRefresh(new Date().getTime());
                                }}
                                value={1}
                            />
                            <Radio
                                label="Jours"
                                onChange={() => {
                                    setValue("contract.package_type", 2);
                                    setRefresh(new Date().getTime());
                                }}
                                value={2}
                            />
                        </RadioGroup>
                    )}
                />
                {getValues("contract.package_type") === 0 ? null : (
                    <>
                        {getValues("contract.package_type") === 1 && (
                            <Flex
                                style={{ marginTop: "15px" }}
                                gap="gap.small"
                                column
                                fill
                            >
                                <Input
                                    {...register(
                                        "contract.hour_count_per_week"
                                    )}
                                    defaultValue={getValues(
                                        "contract.hour_count_per_week"
                                    )}
                                    inverted
                                    fluid
                                    type={"number"}
                                    label={
                                        <Label>
                                            Heures travaillés par semaine
                                        </Label>
                                    }
                                    // error={errors?.hour_count_per_week ? true : false}
                                />
                                {/* {errors?.hour_count_per_week && (
                  <MessageError
                    error
                    content={errors.hour_count_per_week?.message}
                  />
                )} */}

                                <Input
                                    {...register(
                                        "contract.hour_payed_per_week"
                                    )}
                                    defaultValue={parseInt(
                                        getValues(
                                            "contract.hour_payed_per_week"
                                        )
                                    )}
                                    type={"number"}
                                    label={
                                        <Label>Heures payées par semaine</Label>
                                    }
                                    inverted
                                    fluid
                                    style={{ marginTop: "15px" }}
                                />
                            </Flex>
                        )}
                        {getValues("contract.package_type") === 2 && (
                            <Flex column fill>
                                <Label>Jours travaillés par ans</Label>
                                <Input
                                    name="contract.day_count_per_year"
                                    {...register("contract.day_count_per_year")}
                                    defaultValue={getValues(
                                        "contract.day_count_per_year"
                                    )}
                                    inverted
                                    fluid
                                    type={"number"}
                                />
                            </Flex>
                        )}
                    </>
                )}
            </PackageType>

            <Text
                style={{ marginTop: "25px", marginBottom: "2px" }}
                weight="bold"
                content="Rémunération"
            />

            <Salary column fill>
                <Label>Salaire brut annuel</Label>
                <Input
                    name="contract.gross_salary"
                    {...register("contract.gross_salary", {
                        required: true,
                        message: "required",
                    })}
                    defaultValue={getValues("contract.gross_salary")}
                    type={"text"}
                    inverted
                    fluid
                />
            </Salary>

            <Bonus column fill>
                <Label>Variable & primes</Label>
                {getValues("contract.bonus")}

                <Controller
                    control={control}
                    name={"contract.bonus"}
                    //   defaultValue={getValues("contract.bonus")}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Flex gap="gap.medium" vAlign="center">
                            <RadioGroup
                                defaultValue={getValues("contract.bonus")}
                            >
                                <Radio
                                    label={<Label>Oui</Label>}
                                    onBlur={onBlur} // notify when input is touched
                                    onChange={() => onChange(true)} // send value to hook form
                                    value={true}
                                />

                                <Radio
                                    label={<Label>Non</Label>}
                                    onBlur={onBlur} // notify when input is touched
                                    onChange={() => onChange(false)} // send value to hook form
                                    value={false}
                                />
                            </RadioGroup>
                        </Flex>
                    )}
                />
            </Bonus>
        </AccordionStyle>
    );
}

const AccordionStyle = styled(Accordion)`
    .ui-accordion__title {
        padding-bottom: 0px;
    }
    .Accordion__checkbox--header {
        padding-bottom: 0px;
    }
    .ui-accordion__title__content-wrapper > .ui-box {
        background-repeat: no-repeat;
        background-position-y: 5px;
    }
`;
const Label = styled(Text)`
    font-size: 12px;
    font-style: normal;
    padding: 4px;
    color: #616161;
    margin-top: 14px;
`;
const DatepickerStyle = styled(Input)`
    input {
        background-color: white;
        width: 254px;
    }
`;
const Poste = styled(Flex)``;
const Statut = styled(Flex)``;
const Position = styled(Flex)``;
const Coefficient = styled(Flex)``;
const ArrivalDate = styled(Flex)``;
const ContractType = styled(Flex)``;
const PartTimePourcentage = styled(Flex)``;
const DateDeFinDeContrat = styled(Flex)``;
const PackageType = styled(Flex)``;
const Salary = styled(Flex)``;
const Bonus = styled(Flex)``;
const TestPeriod = styled(Flex)``;

import {
    ExcelIcon,
    ExclamationTriangleIcon,
    FilesImageIcon,
    FilesPdfIcon,
    FilesPictureColoredIcon,
    Flex,
    PowerPointIcon,
    Text,
    Tooltip,
    WordIcon,
} from "@fluentui/react-northstar";
import moment from "moment";
import { ROUTES } from "../constants/routes";

const ICON_EXTENSION = {
    pdf: <FilesPdfIcon />,
    xls: <ExcelIcon />,
    xlsx: <ExcelIcon />,
    doc: <WordIcon />,
    docx: <WordIcon />,
    ppt: <PowerPointIcon />,
    pptx: <PowerPointIcon />,
};
/**
 *  this function is used when we want to ellipsis text with three dots
 * @param {{ str: string, length: number}} Object
 * @returns {string}
 */
export const str_ellipsis = ({ str, length }) => {
    if (!str) return "-";
    if (str.length > length) {
        return str.slice(0, length).concat("...");
    } else {
        return str;
    }
};

export const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

export const ARRAY_EXTENSIONS_OBJECT = [
    "pdf",
    "xls",
    "xlsx",
    "doc",
    "docx",
    "ppt",
    "pptx",
];
// verify if the extension is a valid extension.
const isValidExtension = (ext) => ARRAY_EXTENSIONS_OBJECT.indexOf(ext) !== -1;
// get extension of any file
export const getExtensionDocument = (str = "") =>
    str.slice(str.lastIndexOf(".")).replace(".", "");

export const getTypeFromExtension = (ext) => {
    const array_extensions_image = [".png", ".jpeg", ".jpg"];

    if (array_extensions_image.indexOf(ext) !== -1) {
        return "image";
    } else {
        return "pdf";
    }
};
// get Extensions JSX ICON from filename.
export const ICON_FROM_EXTENSION = (filename, size = "small") => {
    const extension = getExtensionDocument(filename).toLowerCase();
    return ICON_EXTENSION[extension] || <FilesPictureColoredIcon size={size} />;
};

export const getToday = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const f_month = month.toString().length === 2 ? month : "0" + month;

    return `${year}-${f_month}-${day}`;
};

export const showTheRightName = ({
    name,
    document_required,
    documents_template,
}) => {
    if (document_required && documents_template?.length === 0) {
        return (
            <Tooltip
                content="Document requis"
                subtle={false}
                trigger={
                    <Flex
                        vAlign="center"
                        gap="gap.small"
                        styles={{
                            cursor: "pointer",
                        }}
                    >
                        <ExclamationTriangleIcon
                            outline
                            variables={{
                                color: "rgba(196,49,75,0.9)",
                            }}
                        />
                        <Text styles={{ color: "rgba(196,49,75,0.9)" }}>
                            {name}
                        </Text>
                    </Flex>
                }
            />
        );
    }
    return name;
};

export const _str_capitalize = (string) => {
    return string.at(0).toUpperCase() + string.slice(1);
};

export const _utils_get_country_name_by_code = (code) => {
    let regionNames = new Intl.DisplayNames(["fr"], { type: "region" });
    return regionNames.of(code.toUpperCase());
};

export const _utils_get_nationality_by_code = (code) => {
    let regionNames = new Intl.DisplayNames(["fr"], { type: "language" });
    return regionNames.of(code.toUpperCase());
};

export const _is_today = ({ year, month, day }) => {
    const current_month = moment().get("month") + 1;
    const current_year = moment().get("year");
    const current_today = moment().get("D");

    const isToday =
        Number(year) === Number(current_year) &&
        Number(month) === Number(current_month) &&
        Number(day) === Number(current_today);

    return isToday;
};

export const CONFIG_SLOT = {
    default: {
        color: "#e8ebfa",
        fontColor: "#242424",
    },
    saved_or_sent: {
        color: "#EBEBEB",
        fontColor: "#242424",
    },
    validated: {
        color: "#E7F2DA",
        fontColor: "#242424",
    },
    clocktime_start: {
        color: "#EFF6FC",
        fontColor: "#242424",
    },
    clocktime_paused: {
        color: "#EFF6FC",
        fontColor: "#616161",
    },
};

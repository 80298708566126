import React from "react";
import { Flex, Input, SearchIcon } from "@fluentui/react-northstar";
import styled from "styled-components";
import { Container, ContainerFluid } from "../../../components/layouts";
import { DEVICE } from "../../../constants/responsive";

const Searchbar = ({ onChange }) => {
    return (
        <ContainerFluid fill>
            <Container fill>
                <FlexStyle hAlign="center" fill>
                    <InputStyle
                        icon={<SearchIcon />}
                        placeholder="Chercher par nom, métier ou équipe"
                        onChange={(e) => onChange(e.target.value)}
                        inverted
                        clearable
                    />
                </FlexStyle>
            </Container>
        </ContainerFluid>
    );
};

export default Searchbar;

const InputStyle = styled(Input)`
    width: 802px;
    .ui-input__input {
        width: 100%;
    }
`;

const FlexStyle = styled(Flex)`
    padding: 21px 0px;
    @media ${DEVICE.mobileS} {
        padding-inline: 10px;
    }
    @media ${DEVICE.laptop} {
        padding-inline: 0px;
    }
`;

import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import AllDocument from "../components/content/AllDocument";

export const AllDocuments = ({ activeTab, all_documents = [], refetch }) => {
    return (
        <Flex column fill>
            <RightTitle>{_str_capitalize(activeTab?.name)}</RightTitle>
            <AllDocument
                documents={all_documents}
                locked={!activeTab?.update_authorized}
                refetch={refetch}
            />
        </Flex>
    );
};

export const _str_capitalize = (string = " ") => {
    return string.at(0).toUpperCase() + string.slice(1);
};

const RightTitle = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #242424;
`;

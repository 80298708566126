import { AddIcon, ChevronDownIcon, Flex } from "@fluentui/react-northstar";
import React from "react";
import { Title, Wrapper } from "./index.style";
import DepartmentCounter from "../../../../components/Badge/DepartmentCounter";

const DepartmentCollapse = ({
    event_id,
    title,
    counter,
    closedPanel = false,
    onClickChevron = () => null,
}) => {
    return (
        <Wrapper>
            <Title truncated>{title}</Title>
            <Flex gap="gap.smaller">
                <DepartmentCounter counter={counter} />
                <ChevronDownIcon
                    rotate={closedPanel ? 0 : 180}
                    onClick={() => onClickChevron(event_id)}
                    styles={{
                        cursor: "pointer",
                    }}
                />
            </Flex>
        </Wrapper>
    );
};

export default DepartmentCollapse;

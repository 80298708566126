import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import LabelView from "../../LabelView";
import CopyText from "../../CopyText";
import FlagText from "../../../../../components/Flag/FlagText";
import SETUP_PROFILE from "../../../constants";
import {
    _utils_get_country_name_by_code,
    _utils_get_nationality_by_code,
} from "../../../../../utils/functions";

const CivilStatus = ({
    secu,
    nationality,
    birthdate,
    place_of_birth,
    country_of_birth,
    identity_card_type,
    identity_card_number,
    identity_card_expiry_date,
    marital_status,
    contract_country,
    avs,
}) => {
    const format_marital_status = SETUP_PROFILE.MARITAL_STATUS.find(
        (el) => el?.id === marital_status
    )?.name;

    const format_identity_card_type = SETUP_PROFILE.IDENTITY_CARD_TYPE.find(
        (el) => el?.id === identity_card_type
    )?.name;

    const is_fr = contract_country?.toLowerCase() === "fr";
    const is_ch = contract_country?.toLowerCase() === "ch";

    return (
        <Flex fill column gap="gap.medium">
            {is_fr && (
                <>
                    <LabelView
                        label="N° de sécurité sociale"
                        content={
                            <CopyText
                                content={secu}
                                width="100%"
                                noBorder={true}
                                noAlert
                            />
                        }
                    />
                </>
            )}
            {is_ch && (
                <>
                    <LabelView
                        label="N° AVS"
                        content={
                            <CopyText
                                content={avs}
                                width="100%"
                                noBorder={true}
                                noAlert
                            />
                        }
                    />
                </>
            )}

            <LabelView
                label="Nationalité"
                content={
                    !nationality ? (
                        "-"
                    ) : (
                        <FlagText
                            flag={nationality.toLowerCase()}
                            content={_utils_get_country_name_by_code(
                                nationality
                            )}
                        />
                    )
                }
            />
            <LabelView label="Date de naissance" content={birthdate} />
            <LabelView label="Ville de naissance" content={place_of_birth} />
            <LabelView
                label="Pays de naissance"
                content={
                    !country_of_birth ? (
                        "-"
                    ) : (
                        <FlagText
                            flag={country_of_birth.toLowerCase()}
                            content={_utils_get_country_name_by_code(
                                country_of_birth
                            )}
                        />
                    )
                }
            />
            <LabelView
                label="Type de pièce d’identité"
                content={format_identity_card_type}
            />
            <LabelView
                label="N° de pièce d’identité"
                content={
                    <CopyText
                        content={identity_card_number}
                        width="100%"
                        noBorder={true}
                        noAlert
                    />
                }
            />
            <LabelView
                label="Fin de validité de la pièce d’identité"
                content={identity_card_expiry_date}
            />
            <LabelView
                label="Situation familiale"
                content={format_marital_status}
            />
        </Flex>
    );
};

export default CivilStatus;

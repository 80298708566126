import { AddIcon, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { ColumnName } from "../../ExpensesRows";

import { CustomBadge } from "../../../../../components/Badge";
import PercentageIcon from "../../PercentageIcon/PercentageIcon";

const TheadRowsExpenses = ({ t, isMobile }) => {
    console.log("isMobile", isMobile);
    return (
        <Flex
            fill
            space="between"
            vAlign="center"
            styles={{
                borderBottom: "1px solid #D1D1D1",
                paddingBlock: "8px",
                paddingLeft: "12px",
            }}
        >
            <Flex styles={{ width: "80px" }}>
                <ColumnName content={t("home.columns.date")} />
            </Flex>
            <Flex styles={{ width: "227px" }} gap="gap.small">
                {isMobile ? (
                    <ColumnName content={"Catégorie"} />
                ) : (
                    <ColumnName content={t("home.columns.title_note")} />
                )}
            </Flex>
            {!isMobile && (
                <Flex styles={{ width: "150px" }}>
                    <Text content={"HT"} />
                </Flex>
            )}
            {isMobile ? null : (
                <Flex styles={{ width: "183px" }}>
                    <ColumnName
                        content={
                            <Flex gap="gap.smaller" vAlign="center">
                                <Text content="TVA" />
                                <PercentageIcon />
                            </Flex>
                        }
                    />

                    {/* <ColumnName content={"TVA"} /> */}
                </Flex>
            )}
            <Flex styles={{ width: "128px" }}>
                <ColumnName content={"TTC"} />
            </Flex>
            {isMobile ? null : (
                <Flex styles={{ width: "40px" }}>
                    <ColumnName content={""} />
                </Flex>
            )}
            {isMobile ? null : (
                <Flex styles={{ width: "40px" }} hAlign="end"></Flex>
            )}
        </Flex>
    );
};

export default TheadRowsExpenses;

import styled from "styled-components";
import { Flex, Text } from "@fluentui/react-northstar";

export const Container = styled(Flex)`
    width: 140px;
    padding: 8px 12px;
    gap: 8px;

    border-radius: 4px;
    border: 2px dashed var(--light-theme-rest-border-default-border-2, #e0e0e0);
    background: var(--light-theme-rest-background-default-background, #fff);
`;

export const BreakText = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

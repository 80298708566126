import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { QUERY_KEY } from "../../constants";
import {
    _api_get_list_history_paid_expenses,
    _api_get_list_to_verify_expenses,
} from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import { func_format_data_rows } from "./functions";
import useToast from "../../../../hooks/useToast";
import { useMediaQuery } from "react-responsive";
import { SIZES } from "../../../../constants/responsive";

const useLogic = () => {
    // navigation
    const navigate = useNavigate();
    // get params
    const location = useLocation();
    // affichage des ROWS
    const [rows, setRows] = useState([]);
    // state for toast
    const [show, setShow] = useToast({
        visible: location.state?.visible ?? false,
        content: location.state?.content ?? "",
        level: location.state?.level ?? "success",
    });

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileM})`,
    });
    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    const isMobileL = useMediaQuery({
        query: "(max-width: 455px)",
    });

    const isMobile = isMobileS || isMobileM || isMobileL;
    // QUERIES
    const { data, isLoading, isFetching } = useQuery(
        [QUERY_KEY + "::getListToVerifyExpenses"],
        () => {
            return _api_get_list_to_verify_expenses();
        },
        {
            onSuccess: (response) => {
                const format_rows = func_format_data_rows(
                    response,
                    navigate,
                    isMobile
                );
                setRows(format_rows);
            },
        }
    );

    // RETURNS
    return {
        rows,
        setRows,
        toast: {
            show,
            setShow,
        },
        query: { data, isLoading, isFetching },
    };
};

export default useLogic;

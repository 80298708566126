import React from "react";
import { Flex, InputLabel, Text, TextArea } from "@fluentui/react-northstar";
import styled from "styled-components";

const SetComment = ({ comment, setComment }) => {
    const onChange = (_, i) => {
        const value = i.value;
        if (value.length > 100) {
            return;
        }
        setComment(value);
    };

    return (
        <Flex column>
            <Flex column fill>
                <Flex gap="gap.small" vAlign="center">
                    <InputLabelStyle content="Ajouter un commentaire" />
                    <OptionalText>(facultatif)</OptionalText>
                </Flex>
                <TextArea fluid rows={5} value={comment} onChange={onChange} />
            </Flex>
            <Flex fill hAlign="end">
                <CountCaracters>{comment.length}/100</CountCaracters>
            </Flex>
        </Flex>
    );
};

export default SetComment;

const CountCaracters = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Default-Foreground-2, #616161);
    text-align: right;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const InputLabelStyle = styled(InputLabel)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
`;

const OptionalText = styled.span`
    color: var(--Light-theme-Rest-Foreground-Default-Foreground-2, #616161);

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
`;

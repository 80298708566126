import React from "react";
import styled from "styled-components";
import { Button, Flex } from "@fluentui/react-northstar";
import { CommentAdd20Regular } from "@fluentui/react-icons";

import { CardBody, CardHeader, Wrapper } from "./style";
import { Day } from "./CardDay";
import CardClose from "./CardClose";
import SlotmodifyLogic from "./SlotmodifyLogic";

const CardDayModify = ({
    dayData,
    dayName,
    refetch,
    month,
    itContainPartialLeave = false,
    onCancel = () => console.log("canceled"),
}) => {
    const day_number = dayData?.date?.split("-")[2];
    const slots = dayData?.shifts;
    const day_id = dayData?.id;

    return (
        <Flex styles={{ isolation: "isolate", zIndex: 2 }}>
            <Overlay onClick={onCancel} />
            <Wrapper column fill space="between" modify styles={{ zIndex: 4 }}>
                <CardHeader column space="between" backgroundColor={"white"}>
                    <Flex>
                        <Day
                            today={false}
                            day_name={dayName}
                            day_number={day_number}
                        />
                        <CardClose onClick={onCancel} />
                    </Flex>
                </CardHeader>
                <CardBody
                    space="between"
                    column
                    fill
                    styles={{ padding: "8px" }}
                    gap="gap.medium"
                    modify={true}
                >
                    <SlotmodifyLogic
                        slots={slots}
                        day_id={day_id}
                        refetch={refetch}
                        month={month}
                        itContainPartialLeave={itContainPartialLeave}
                        onCancel={onCancel}
                    />
                </CardBody>
            </Wrapper>
        </Flex>
    );
};

export default CardDayModify;

const Overlay = styled.div`
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
`;

import { BASE_URL_API } from "../../../constants";
import fetcher from "../../../utils/fetcher";

export const _api_get_delegation = () => {
    return fetcher(BASE_URL_API + "/delegated-permission/list/", {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_detail_per_type = ({ type }) => {
    return fetcher(`${BASE_URL_API}/delegated-permission/${type}/details`, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_delete_delegation = ({ id_delegation }) => {
    return fetcher(
        `${BASE_URL_API}/delegated-permission/${id_delegation}/delete`,
        {
            method: "DELETE",
            credentials: "include",
        }
    );
};

export const _api_assign_delegation = ({
    collaborator_email,
    start_date,
    end_date,
    type,
}) => {
    return fetcher(`${BASE_URL_API}/delegated-permission/${type}/update`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            collaborator_email,
            start_date: start_date,
            end_date: end_date ? end_date : null,
        }),
    });
};

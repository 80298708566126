import { BASE_URL_API } from "../../../constants";

export const _forget_my_password = (email) => {
    const url = `${BASE_URL_API}/auth/password_reset`;
    console.log("request sent: ", email);

    return fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            username: email,
        }),
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error("Error:", error);
        });
};

import produce from "immer";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useShowWeekend = create(
    persist(
        (set) => ({
            show: true,
            setShow: (bool) => {
                set(
                    produce((state) => {
                        state.show = bool;
                    })
                );
            },
        }),
        {
            name: "time_reporting_show_weekend_older",
        }
    )
);

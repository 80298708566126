import React from "react";
import { Avatar, Flex } from "@fluentui/react-northstar";

const AvatarUser = ({ name, image, showName = true, size = "medium" }) => {
    return (
        <Flex vAlign="center" gap="gap.small">
            <Flex>
                <Avatar image={image || null} name={name} size={size} />
            </Flex>
            {showName && (
                <Flex>
                    <p>{name}</p>
                </Flex>
            )}
        </Flex>
    );
};

export default AvatarUser;

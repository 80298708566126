import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
    gap: 16px;
    height: fit-content;
    width: ${({ is_holiday }) => (is_holiday ? "150px" : "fit-content")};
    border-radius: 16px;
    background: ${({ is_holiday }) => (is_holiday ? "#FBF6D9" : "#f0f0f0")};
    justify-content: center;
    padding: 4px 8px;
    margin-bottom: 8px;
`;
export const PillContent = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    text-align: center;
    font-size: ${({ is_holiday }) => (is_holiday ? "12px" : "14px")};
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
`;

import React, { useState } from "react";
import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import ListItemText from "@mui/material/ListItemText";
// import ListItem from "@mui/material/ListItem";
// import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
// import CloseIcon from "@mui/icons-material/Close";
// import DownloadIcon from "@mui/icons-material/Download";
import { useQuery } from "react-query";
import Slide from "@mui/material/Slide";

// import { DialogContent } from "@mui/material";

import FileDocuments from "./FileDocuments";
import { getDocument } from "../../../api/documents";
import {
    getExtensionDocument,
    getTypeFromExtension,
} from "../../../utils/functions";
import styled from "styled-components";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Documents(props) {
    const [open, setOpen] = useState(false);
    const [detailDocumentPreview, setDetailDocumentPreview] = useState(""); // we stock the value of the document to Preview.

    const { data, isLoading, isFetching } = useQuery("profileDocument", () =>
        getDocument()
    );

    // console.log("data is :", data[0].name);

    const handleClose = () => {
        setOpen(false);
    };

    if (isLoading || isFetching) {
        return <p>Chargenemt ...</p>;
    }

    return (
        <DocumentContainer
            style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "150px",
                // border: "5px solid red",
            }}
        >
            <>
                {data?.map((el) => {
                    return (
                        <div key={el.id}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    const extension = getExtensionDocument(
                                        el.name
                                    );
                                    const type =
                                        getTypeFromExtension(extension);
                                    setDetailDocumentPreview({
                                        url: el.attached_doc,
                                        type,
                                    });
                                    setOpen(true);
                                }}
                            >
                                Voir "{el.name}"
                            </Button>
                        </div>
                    );
                })}
            </>
            <FileDocuments
                data={detailDocumentPreview.url}
                open={open}
                handleClose={handleClose}
                Transition={Transition}
                typeDocument={detailDocumentPreview.type}
            />
        </DocumentContainer>
    );
}

export default Documents;

const DocumentContainer = styled.div`
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`;

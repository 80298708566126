import { Alert, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { LAYOUT_MAX_WIDTH } from "../../constants";

export const Header = styled(Flex)`
    padding-top: 24px;
`;
export const HeaderLeft = styled(Flex)``;
export const HeaderRight = styled(Flex)``;

export const Title = styled(Text)`
    color: #242424;

    /* Larger/400 */
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
`;

export const Body = styled(Flex)``;
export const WrapperCard = styled(Flex)`
    /* width: 1363px; */
    padding: 24px 16px;

    background: #fff;
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
`;
export const WrapperCardHeader = styled(Flex)``;
export const WrapperCardTitle = styled(Text)`
    color: #242424;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;
export const WrapperWeek = styled(Flex)`
    padding: 24px 16px;

    border-top: 4px solid #9299f7;
    background: #fff;

    box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
        0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11);
`;
export const WrapperChevronSlider = styled(Flex)`
    button.left {
        position: absolute;
        top: 50%;
        left: -50px;
    }
    button.right {
        position: absolute;
        top: 50%;
        right: -50px;
    }
`;
export const AlertFixed = styled(Alert)`
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translate(-50%);
`;

import { BASE_URL_API } from "../../../constants";
import { useAuthDetailStore, useAuthStore } from "../../../stores/useAuthStore";
import fetcher from "../../../utils/fetcher";

export const _getAllAgencies = async () => {
    return fetcher(BASE_URL_API + "/onboarding/agencies", {
        method: "GET",
        credentials: "include",
    });
};
export const _getCollaboratorByAgence = async (AgencyId) => {
    return fetcher(BASE_URL_API + `/onboarding/agency/${AgencyId}/users`, {
        method: "GET",
        credentials: "include",
    });
};
export const _startPreonboarding = async (user_id) => {
    // console.log("lancer", user_id);
    return fetcher(
        BASE_URL_API +
            `/onboarding/user/${user_id?.id_task}/step/start_preonboarding`,
        {
            method: "PATCH",
            credentials: "include",
        }
    );
};
export const _relanceWelcomeMail = async (data) => {
    return fetcher(
        BASE_URL_API +
            `/onboarding/user/${data?.id}/step/resend_activation_email`,
        {
            method: "PATCH",
            credentials: "include",
        }
    );
};
export const _changeStatusOnboarding = async (data) => {
    const change_date = data?.datefrom;
    const new_status = data?.status.id;
    const user_id = data?.user_id;

    return fetcher(
        BASE_URL_API +
            `/onboarding/user/${
                user_id?.id_user ?? user_id?.id_task
            }/step/change_status`,
        {
            method: "PATCH",
            // credentials: "include",
            headers: {
                "Content-Type": "application/json", // Définir le type de contenu
            },
            body: JSON.stringify({
                new_status, // Envoyer new_status dans le body
                change_date, // Envoyer change_date dans le body
            }),
        }
    );
};

export const _getUserDetail = async (userId) => {
    return fetcher(BASE_URL_API + `/suivi-onboarding/user/${userId}/details`, {
        method: "GET",
        credentials: "include",
    });
};

export const _renewTrialPeriod = async (data) => {
    const user = useAuthStore.getState().infos;
    const new_prob_end_date = data?.new_date;
    const user_id = data?.user_id;
    console.log({ douta: data });

    return fetcher(
        BASE_URL_API +
            `/onboarding/user/${
                user_id?.id_user ?? user_id?.id_task
            }/step/change_prob_end_date`,
        {
            method: "PATCH",
            // credentials: "include",
            headers: {
                "Content-Type": "application/json", // Définir le type de contenu
                Authorization: `Bearer ${user?.access}`,
            },
            body: JSON.stringify({
                new_prob_end_date,
            }),
        }
    );
};
export const _changeStartDate = async (data) => {
    const user = useAuthStore.getState().infos;
    const new_starting_date = data?.new_date;
    const user_id = data?.user_id;
    console.log({ douta: data });

    return fetcher(
        BASE_URL_API +
            `/onboarding/user/${
                user_id?.id_user ?? user_id?.id_task
            }/step/change_starting_date`,
        {
            method: "PATCH",
            // credentials: "include",
            headers: {
                "Content-Type": "application/json", // Définir le type de contenu
                Authorization: `Bearer ${user?.access}`,
            },
            body: JSON.stringify({
                new_starting_date,
            }),
        }
    );
};

export const _getSuiviUserDetail = async (userId) => {
    return fetcher(BASE_URL_API + `/suivi-onboarding/user/${userId}/details`, {
        method: "GET",
        credentials: "include",
    });
};

export const getAllOnboardingUser = (agencies) => {
    // return fetcher(`${BASE_URL_API}/users/search?q=${user}`, {
    //     method: "GET",
    //     credentials: "include",
    // });
    console.log({ agencies11: agencies });
    return fetcher(
        `${BASE_URL_API}/onboarding/agency/${agencies}/filter/users`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};

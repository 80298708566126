import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import CallMobile from "../../../CallMobile";

const EmergencyContacts = ({ emergency_contacts }) => {
    return (
        <Flex column fill gap="gap.medium">
            <Title content="Contacts d’urgence" />
            {!emergency_contacts?.length ? (
                <Text content="-" />
            ) : (
                emergency_contacts?.map((contact, index) => {
                    return (
                        <Flex column gap="gap.small">
                            <SubTitle
                                content={"Contact d'urgence " + (index + 1)}
                            />
                            <Flex vAlign="center" space="between">
                                <Content
                                    content={`${contact?.name} ( ${contact?.relationship_with_user} )`}
                                />
                                <CallMobile
                                    content={contact?.phone_number}
                                    width="auto"
                                    noBorder
                                />
                            </Flex>
                        </Flex>
                    );
                })
            )}
        </Flex>
    );
};

export default EmergencyContacts;

const Content = styled(Text)`
    color: #242424;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

const SubTitle = styled(Text)`
    color: #616161;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`;

const Title = styled(Text)`
    color: #242424;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
`;

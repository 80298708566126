import { Badge } from "@fluentui/react-components";
import {
    CanvasAddPageIcon,
    EditIcon,
    EyeIcon,
    Flex,
    SendIcon,
    SignatureIcon,
    Text,
} from "@fluentui/react-northstar";
import { Eye16Filled } from "@fluentui/react-icons";

import PropTypes from "prop-types";
import { CustomBadge } from "../../components/Badge";

export const ACTIONS_DOCUMENTS = {
    SEND: "SEND",
    COMPLETE: "COMPLETE",
    SIGN: "SIGN",
    VISUALIZE: "VISUALIZE",
    NONE: "NONE",
};
/**
 * @param {string} action one of "ACTIONS_DOCUMENTS" keys
 * @returns FC
 */
export const render_badge_action_document = (action) => {
    return BADGE_ACTION_DOCUMENTS[action];
};

export const get_action_document_from_back = (action_from_back) => {
    return TASK_ACTIONS[action_from_back === 0 ? 4 : action_from_back - 1]
        .element;
};

export const render_badge_action_document_by_id = (action) => {
    const array = TASK_ACTIONS.filter((el) => el.id === action);
    return array[0]?.element;
};

export const TASK_STATUS = {
    DONE: "DONE",
    IN_PORGRESS: "IN_PROGRESS",
    TODO: "TODO",
    TO_APPROVE: "TO_APPROVE",
    DONE_BG: "#61bd4f",
    IN_PROGRESS_BG: "#f2d600",
    TODO_BG: "#0079bf",
    TO_APPROVE_BG: "#000",
};

export const SORT_BY_STATUS = {
    [TASK_STATUS.TODO]: 1,
    [TASK_STATUS.TO_APPROVE]: 2,
    [TASK_STATUS.DONE]: 3,
};

export const BADGE_STATUS_TASKS = {
    [TASK_STATUS.DONE]: (
        <CustomBadge
            appearance="inverted"
            bg="var(--bg-green)"
            label="Terminée"
            size="small"
            color="white"
            rectangular={true}
        />
    ),
    [TASK_STATUS.IN_PORGRESS]: <span>in progress</span>,
    [TASK_STATUS.TO_APPROVE]: (
        <CustomBadge
            appearance="inverted"
            bg="var(--bg-darkGrey)"
            label="En attente"
            size="small"
            rectangular={true}
        />
    ),
    [TASK_STATUS.TODO]: (
        <CustomBadge
            appearance="inverted"
            bg="var(--bg-orange)"
            label="À faire"
            size="small"
            rectangular={true}
        />
    ),
};

export const BADGE_ACTION_DOCUMENTS = {
    [ACTIONS_DOCUMENTS.SIGN]: (
        <Flex gap={"gap.small"} vAlign="center">
            <SignatureIcon size="small" />
            <Text weight="regular">Signer</Text>
        </Flex>
    ),
    [ACTIONS_DOCUMENTS.COMPLETE]: (
        <Flex gap={"gap.small"} vAlign="center">
            <EditIcon size="small" />
            <Text weight="regular">Compléter</Text>
        </Flex>
    ),
    [ACTIONS_DOCUMENTS.VISUALIZE]: (
        <Flex gap={"gap.small"} vAlign="center">
            <Eye16Filled />
            <Text weight="regular">Consulter</Text>
        </Flex>
    ),
    [ACTIONS_DOCUMENTS.SEND]: (
        <Flex gap={"gap.small"} vAlign="center">
            <SendIcon size="small" />
            <Text weight="regular">Envoyer</Text>
        </Flex>
    ),
    [ACTIONS_DOCUMENTS.NONE]: (
        <Flex gap={"gap.small"} vAlign="center">
            {/* <CanvasAddPageIcon outline /> */}
            {/* <Text weight="regular">Aucun document</Text> */}
        </Flex>
    ),
};

export const TASK_ACTIONS = [
    // {
    //     id: 0,
    //     header: "Signer le document fourni",
    //     content: "",
    //     element: BADGE_ACTION_DOCUMENTS[ACTIONS_DOCUMENTS.VISUALIZE],
    //     document_required: true,
    //     hr_document_required: true,
    // },
    {
        id: 1,
        header: "Envoyer un document",
        content: "",
        element: BADGE_ACTION_DOCUMENTS[ACTIONS_DOCUMENTS.SEND],
        document_required: false,
        hr_document_required: false,
    },
    {
        id: 2,
        header: "Consulter le document fourni",
        content: "",
        element: BADGE_ACTION_DOCUMENTS[ACTIONS_DOCUMENTS.VISUALIZE],
        document_required: true,
        hr_document_required: true,
    },
    {
        id: 3,
        header: "Compléter le document fourni",
        content: "",
        element: BADGE_ACTION_DOCUMENTS[ACTIONS_DOCUMENTS.COMPLETE],
        document_required: true,
        hr_document_required: true,
    },
    {
        id: 4,
        header: "Signer le document fourni",
        content: "",
        element: BADGE_ACTION_DOCUMENTS[ACTIONS_DOCUMENTS.SIGN],
        document_required: true,
        hr_document_required: true,
    },
    {
        id: 5,
        header: "Aucun document requis",
        content: "",
        element: BADGE_ACTION_DOCUMENTS[ACTIONS_DOCUMENTS.NONE],
        document_required: false,
        hr_document_required: false,
    },
];

ACTIONS_DOCUMENTS.propTypes = PropTypes.oneOf(Object.keys(ACTIONS_DOCUMENTS));

render_badge_action_document.propTypes = {
    action: PropTypes.oneOf(Object.keys(ACTIONS_DOCUMENTS)),
};

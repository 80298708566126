import { Dialog } from "@fluentui/react-northstar";
import styled from "styled-components";

export const DialogDeclareRemote = styled(Dialog)`
    width: 440px;
    .ui-buttons {
        margin-top: 24px;
    }
    .ui-buttons > div {
        justify-content: center;
    }
`;

import {
    Avatar,
    Box,
    ChevronDownIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
import Cell from "./Cell";
import {
    RowDepartment,
    RowDepartmentText,
} from "../TimeRertingSociety/TimeReportToValidate";
import DepartmentCounter from "../../../../components/Badge/DepartmentCounter";
import { motion } from "framer-motion";

const WeekRowRH = ({
    weeklydata,
    collapsedDepartments = [],
    setCollapsedDepartments = () => null,
}) => {
    const departments = weeklydata?.departments ?? [];
    return (
        <Flex column>
            {departments.map((department) => {
                const index_department = collapsedDepartments.indexOf(
                    department.id
                );
                const chevronRotation = index_department === -1 ? 180 : 360;
                return (
                    <Flex column>
                        <RowDepartment>
                            <RowDepartmentText truncated>
                                {department?.name}
                            </RowDepartmentText>
                            <Flex gap="gap.smaller" vAlign="center">
                                <DepartmentCounter
                                    counter={department.users?.length}
                                />
                                <motion.div
                                    initial={{
                                        rotate: 0,
                                    }}
                                    animate={{
                                        rotate: chevronRotation,
                                    }}
                                    transition={{
                                        duration: 0.2,
                                    }}
                                >
                                    <ChevronDownIcon
                                        styles={{ cursor: "pointer" }}
                                        onClick={() => {
                                            index_department === -1
                                                ? setCollapsedDepartments(
                                                      (state) => {
                                                          return [
                                                              ...state,
                                                              department.id,
                                                          ];
                                                      }
                                                  )
                                                : setCollapsedDepartments(
                                                      (state) => {
                                                          return state.filter(
                                                              (e) =>
                                                                  e !==
                                                                  department.id
                                                          );
                                                      }
                                                  );
                                            return;
                                        }}
                                    />
                                </motion.div>
                            </Flex>
                        </RowDepartment>
                        <Flex column>
                            {!collapsedDepartments.includes(department.id) &&
                                department?.users?.map((user) => {
                                    const TotalEffectif =
                                        user?.week?.declared_hours_count;
                                    return (
                                        <>
                                            <Flex
                                                space="between"
                                                vAlign="center"
                                            >
                                                <LeftRow>
                                                    <Avatar
                                                        image={user?.avatar}
                                                        name={user?.name}
                                                    />
                                                    <Text
                                                        content={user?.name}
                                                    />
                                                </LeftRow>
                                                <Flex
                                                    styles={{
                                                        gap: "10px",
                                                    }}
                                                >
                                                    {user?.week?.days?.map(
                                                        (el) => {
                                                            return (
                                                                <Cell el={el} />
                                                            );
                                                        }
                                                    )}
                                                </Flex>
                                                {/* total text  */}
                                                <Flex
                                                    hAlign="center"
                                                    vAlign="center"
                                                    styles={{
                                                        width: "187px",
                                                        padding: "12px 16px",
                                                        minHeight: "64px",
                                                        background: "#fff",
                                                    }}
                                                >
                                                    <TotalText
                                                        content={`${
                                                            TotalEffectif !==
                                                                null &&
                                                            TotalEffectif !==
                                                                undefined
                                                                ? TotalEffectif
                                                                : "--,--"
                                                        } h`}
                                                    />
                                                </Flex>
                                            </Flex>
                                            <Box
                                                styles={{
                                                    height: "4px",
                                                    background: "#E8EBFA",
                                                }}
                                            />
                                        </>
                                    );
                                })}
                        </Flex>
                    </Flex>
                );
            })}
        </Flex>
    );
};

export default WeekRowRH;

const LeftRow = styled(Flex)`
    width: 330px;
    height: 65px;
    padding: 12px 16px 12px 45px;
    align-items: center;
    gap: 8px;
    background-color: #fff;
`;
const TotalText = styled(Text)`
    color: #242424;
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ROUTES } from "../../../../constants/routes";

const format_date = (date) => {
    return date.slice(5).split("-").reverse().join("-");
};

function Thead({ data_weeks }) {
    return (
        <Flex
            gap="gap.large"
            styles={{
                paddingBlock: "16px",
                marginTop: "40px",
            }}
        >
            <MembreStyle content="Membre " />
            <Flex vAlign="center" gap="gap.large">
                {data_weeks?.map((week) => {
                    const start_day = format_date(week.monday);
                    const end_day = format_date(week.sunday);
                    return (
                        <Link
                            key={week?.week_number}
                            to={ROUTES.time_reporting.week_detail.custom_route(
                                week?.week_number
                            )}
                        >
                            <DateStyle content={start_day + " / " + end_day} />
                        </Link>
                    );
                })}
            </Flex>
            <TotalStyle content="Total" />
        </Flex>
    );
}

export default Thead;

const DateStyle = styled(Text)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    text-align: center;
    text-decoration-line: underline;

    width: 120px;
    color: #5b5fc7;
    display: block;
`;

const MembreStyle = styled(Text)`
    width: 308px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
`;

const TotalStyle = styled(Text)`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
    width: 140px;
    text-align: center;
`;

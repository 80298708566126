import React from "react";
import { Text } from "@fluentui/react-northstar";

import { useTranslation } from "react-i18next";
import { TextStyle, Number, Ball, FlexStyle } from "../styles";

import PropTypes from "prop-types";
import { I18N_NS_ADD_EMPLOYEE } from "../constants";

const Step = ({
    id = 0,
    label = "",
    active = "false",
    already = "false",
    required = false,
    ...props
}) => {
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    return (
        <FlexStyle
            {...props}
            already={already}
            vAlign="center"
            gap="gap.small"
            active={active}
        >
            <Ball
                active={active}
                already={already}
                vAlign="center"
                hAlign="center"
            >
                <Number active={active} already={already}>
                    {id}
                </Number>
            </Ball>
            <TextStyle active={active} already={already}>
                {t(label)}{" "}
                {required && <Text styles={{ color: "red" }}>*</Text>}
            </TextStyle>
        </FlexStyle>
    );
};

Step.propTypes = {
    active: PropTypes.oneOf(["true", "false"]),
    already: PropTypes.oneOf(["true", "false"]),
};

export default Step;

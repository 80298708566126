import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
    border-radius: 4px;
    background: var(--light-theme-rest-background-brand-background-4, #444791);
    height: 32px;
`;

export const TextLeave = styled(Text)`
    color: var(--light-theme-rest-foreground-default-foreground-3, #fff);

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;

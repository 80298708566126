import styled from "styled-components";
import { Text } from "@fluentui/react-northstar";

export const TextAmount = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #242424;
`;

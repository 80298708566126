import { BASE_URL_API } from "../../../constants";
import fetcher from "../../../utils/fetcher";

export const _api_get_model = (type = null) => {
    const url = `${BASE_URL_API}/task/task_definition/list${
        type ? "?type=" + type : ""
    }`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_post_task_definition = ({ id_user, ids }) => {
    const url = BASE_URL_API + "/task/task_definition/list/" + id_user;
    return fetcher(url, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            ids,
        }),
    });
};

export const _api_patch_assign_task = ({ id_user, email, tasks }) => {
    return fetcher(`${BASE_URL_API}/onboarding/user/create/${id_user}`, {
        method: "PATCH",
        body: JSON.stringify({
            step: "set_onboarding_tasks",
            email,
            tasks,
        }), // The data
        credentials: "include",
    });
};

export const _api_save_steps = ({ id_user, email }) => {
    return fetcher(`${BASE_URL_API}/onboarding/user/create/${id_user}`, {
        method: "PUT",
        body: JSON.stringify({
            email,
        }), // The data
        credentials: "include",
    });
};

import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { I18N_NS_DOCUMENTS, I18N_NS_EXPENSE } from "../../constants";

const RowEmpty = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    return (
        <Flex styles={{ marginBottom: "8px" }}>
            <EmptyText>{t("report_detail.empty_exepense")}</EmptyText>
        </Flex>
    );
};

export default RowEmpty;

const EmptyText = styled(Text)`
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #484644;
`;

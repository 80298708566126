import { Checkbox, Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const WrapperChildren = styled(Flex)`
    width: 100%;
    height: 44px;
    background-color: white;
    padding-inline: 19px;
`;

export const CheckboxChildren = styled(Checkbox)`
    
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

export const CheckboxTitle = styled(Checkbox)`
    width: 100%;
`;

export const FlexWrapperTitle = styled(Flex)`
    padding: 12px 19px;
    
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    background: #e8ebfa;
`;

import React, { useState } from "react";
import {
    Button,
    CloseIcon,
    Dialog,
    DownloadIcon,
    FilterIcon,
    Flex,
} from "@fluentui/react-northstar";
import ContentFilterAjustment from "./ContentFilterAjustment";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { DialogStyle } from "../AbsenceRequest";
import { FilterTrigger } from "../RhTrigger/FilterTrigger";
import { ExportTrigger } from "../RhTrigger/ExportTrigger";
import LoadingExport from "../RhTrigger/LoadingExport";
import ExportAllSoldeTrigger from "./ExportAllSoldeTrigger";

const DialogFilterAjustment = ({
    // openDialog,
    // setOpenDialog,

    onSubmitAjustmentFilter,
    employees,
    is_loading_employees,
    leave_types,
    is_loading_types,
    refetch,
    isManager = false,
    isHR = false,
    isMobile,
    header = "Filtrer les données",
    filterData = false,
    exportData = false,
    isDownloadingExcel = false,
    exportAllSolde,
}) => {
    const methods = useFormContext();

    const [refresh, setRefresh] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [openDialog, setOpenDialog] = useState(false);

    const submitFilter = () => {
        methods.handleSubmit(onSubmitAjustmentFilter)();
        if (filterData) {
            setOpenDialog(false);
        }
        return;
    };

    const handleResetClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        methods.setValue("start_date", "");
        methods.setValue("end_date", "");

        methods.setValue("employee", []);
        methods.setValue("checkboxes", []);
        // methods.setValue("checkboxes_status", []);
        methods.setValue("type", []);
        setSearchParams({
            ud: [],
            t: [],
            a: [],
        });
        refetch();
        setRefresh(new Date().getTime());
        return;
    };

    const exportLeaves = () => {
        methods.handleSubmit(onSubmitAjustmentFilter)();
        setTimeout(() => {
            setOpenDialog(false);
        }, 1000);
        return;
    };

    // console.log({ m: methods.getValues("start_date") });

    const start_date = methods.getValues("start_date");
    const end_date = methods.getValues("end_date");
    // console.log({ openDialog });
    return (
        <Flex>
            <DialogStyle
                open={openDialog}
                header={header}
                confirmButton={{
                    content: !filterData
                        ? "Exporter les données"
                        : "Appliquer les filtres",
                    flat: true,
                    // disabled:  start_date && end_date ? false : true,
                    disabled: isDownloadingExcel
                        ? true
                        : exportAllSolde
                        ? false
                        : start_date && end_date
                        ? false
                        : true,
                    // disabled: exportAllSolde
                    //     ? false
                    //     : start_date && end_date
                    //     ? false
                    //     : true,

                    onClick: (e) => {
                        filterData ? submitFilter() : exportLeaves();
                        return;
                    },
                }}
                cancelButton={
                    filterData
                        ? {
                              content: "Tout effacer",
                              flat: true,
                              text: true,
                              primary: true,
                              styles: {
                                  textDecoration: "underline",
                                  fontWeight: 100,
                              },
                              onClick: (e) => {
                                  handleResetClick(e);
                              },
                          }
                        : null
                }
                content={
                    isDownloadingExcel ? (
                        <LoadingExport />
                    ) : (
                        <ContentFilterAjustment
                            filterData={filterData}
                            exportData={exportData}
                            employees={employees}
                            is_loading_employees={is_loading_employees}
                            leave_types={leave_types}
                            is_loading_types={is_loading_types}
                            isManager={isManager}
                            isHR={isHR}
                            refresh={refresh}
                            exportAllSolde={exportAllSolde}
                        />
                    )
                }
                trigger={
                    filterData ? (
                        <FilterTrigger
                            isMobile={isMobile}
                            searchParams={searchParams}
                            setOpenDialog={setOpenDialog}
                        />
                    ) : isHR && exportData ? (
                        <ExportTrigger
                            isMobile={isMobile}
                            searchParams={searchParams}
                            setOpenDialog={setOpenDialog}
                        />
                    ) : isHR && exportAllSolde ? (
                        <ExportAllSoldeTrigger
                            isMobile={isMobile}
                            searchParams={searchParams}
                            setOpenDialog={setOpenDialog}
                        />
                    ) : null
                }
                headerAction={{
                    icon: <CloseIcon />,
                    title: "Close",
                    onClick: (e) => {
                        setOpenDialog(false);
                    },
                }}
            />
        </Flex>
    );
};

export default DialogFilterAjustment;

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { _api_get_remotes } from "../api";
import {
    CURRENT_ISOWEEK,
    CURRENT_YEAR,
    DEFAULT_START_DATE,
    DEFAULT_TXT_WEEK_FROM_TO,
} from "../constants/logic";
import { functions, get_days_of_isoweek } from "../functions";
import { useShowRemoteWeekend } from "../store/useShowRemoteWeekend";
import { useAuthDetailStore } from "../../../stores/useAuthStore";

const useLogicRemote = () => {
    // WEEKS LOGIC
    const ctx_show_weekend = useShowRemoteWeekend((state) => state.show);

    // context useathdetail
    const detail = useAuthDetailStore((state) => state.detail);

    const [state, setState] = useState({
        iso_week: CURRENT_ISOWEEK,
        year: CURRENT_YEAR,
        week_text_from_to: DEFAULT_TXT_WEEK_FROM_TO,
        start_date_week: DEFAULT_START_DATE.isoWeekday(1).format("YYYY-MM-DD"),
        show_weekend: ctx_show_weekend,
        calendar: {
            list_days: get_days_of_isoweek({
                year: CURRENT_YEAR,
                iso_week: CURRENT_ISOWEEK,
            }),
            rows: [],
        },
    });

    const {
        data,
        isLoading,
        isFetching,
        refetch: refetchRemoteData,
    } = useQuery(
        [
            `remote::get_all_remote_data`,
            {
                iso_week: state.iso_week,
            },
        ],
        () => {
            return _api_get_remotes({
                start_date_iso_week: state.start_date_week,
            });
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: (response) => {
                // format the rows
                // TODO: vérifier les erreurs
                const rows = functions.format_response_to_rows(
                    response,
                    state.calendar.list_days
                );
                setState((prev) => {
                    return {
                        ...prev,
                        calendar: {
                            ...prev.calendar,
                            rows: rows,
                        },
                    };
                });
            },
        }
    );

    return {
        query: {
            remotes: data ?? [],
            isLoading,
            isFetching,
        },
        week: {
            iso_week: state.iso_week,
            week_text_from_to: state.week_text_from_to, // pour afficher le text dans dropdown week
            show_weekend: state.show_weekend,
            set_week: ({ start_date }) =>
                functions.set_week({
                    start_date,
                    current_iso_week: state.iso_week,
                    setState,
                    refetch: refetchRemoteData,
                }), // redirection vers n'importe quelle isoweek
            increment_week: () => functions.increment_week(setState), // incrementer la semaine
            decrement_week: () => functions.decrement_week(setState), // décrementer la semaine
            on_datepicker_change: (e, i) =>
                functions.on_datepicker_change(e, i, setState),
        },
        agency: {
            name: detail?.agency?.name, // pour afficher dans le sub header.
        },
        calendar: {
            list_days: state.calendar.list_days, // list de jours Header Calendar
            rows: state.calendar.rows ?? [], // affichage des lignes sur le calendrier
        },
    };
};

export default useLogicRemote;

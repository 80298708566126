export const NAV_LIST = [
    {
        label: "Accueil",
        href: "/",
    },
    {
        label: "Organisation",
        href: "/organisation",
    },
];

export const TABS_ANNUAIRE = {
    ANNUAIRE: "ANNUAIRE",
    ORGANIGRAMME: "ORGANIGRAM",
};

export const DEBOUNCE_DELAY = 700; // is in milliseconds

export const I18N_NS_ANNUAIRE = "annuaire";

export const INIT_CONFIG = {
    horizontal: false,
    expandAll: true,
    collapsable: true,
    labelClassName: "bg-white",
};

import React, { useState } from "react";
import styled from "styled-components";
import {
    Flex,
    Text,
    Input,
    Dropdown,
    Datepicker,
    Tooltip,
    Checkbox,
    Divider,
    TextArea,
    Button,
    FilesUploadIcon,
    FlexItem,
    Attachment,
    FilesPdfIcon,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import { useQuery } from "react-query";

import CustomDropzone from "../../../components/dropzone";
import SliderTabs from "../../../components/shared/SliderTabs";
import { DEVICE } from "../../../constants/responsive";

import {
    getTaskCategory,
    getTaskResponsable,
    _upload_file,
    getTaskResponsables,
} from "../../../api/tasks";
import { TASK_ACTIONS } from "../../../constants/tasks";
import moment from "moment";
import { useStepperContext } from "./ContextAddCollaborateur";
import { useRef } from "react";
import { FILE_SIZE_LIMIT } from "../../../features/documents/constants";
import { file_size_format } from "../../../constants/maths";
import { BASE_URL_ILLUSTRATIONS } from "../../../constants";

const uploadedFile = async (files, setSavedFiles) => {
    if (!files?.length) {
        return console.log("ya aucun fichier !!");
    }

    // limit the import to 5 files at once.
    if (files.length > 5) {
        alert(
            "Vous avez dépasser la limite autorisée ( 5 fichiers à la fois )"
        );
        return;
    }

    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > FILE_SIZE_LIMIT) {
            console.log(
                "'" + file.name + "' à été retirer a cause de sa taille !"
            );
            return false;
        }
        formData.append("uploaded_files", file);
    }
    const savedFiles = await _upload_file(formData);

    setSavedFiles((prevState) => [...prevState, ...savedFiles.files]);
};

const _showButtonUploadFileWithFile = (document_action, savedFiles) => {
    if (savedFiles.length === 1) return false;
    switch (document_action) {
        case 1:
            return false;
        case 5:
            return false;
        default:
            return true;
    }
};

const ModalAddTask = ({ form, setForm, savedFiles, setSavedFiles }) => {
    const refButtonUpload = useRef(null);
    const { firstStep } = useStepperContext((state) => state);
    const [taskRequiredValidation, setTaskRequiredValidation] = useState(false);
    const [taskRequiredAction, setTaskRequiredAction] = useState(false);
    const [action, setAction] = useState(false);
    const [sliderTab, setSliderTab] = useState(0);

    // get all categories
    const { data: dataCategory = [] } = useQuery(["getCategory"], () =>
        getTaskCategory()
    );

    // get all collaborateur users.
    let { data: dataCollaborator = [] } = useQuery(["getCollaborator"], () =>
        getTaskResponsables()
    );
    let { data: dataValidators = [] } = useQuery(["getValidators"], () =>
        getTaskResponsables()
    );

    // format the data of the collaborator for fluentui
    dataCollaborator = dataCollaborator.map((el) => {
        return {
            header: el.username,
            image: el.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: el.job_title,
        };
    });
    dataValidators = dataValidators.map((el) => {
        return {
            header: el.username,
            image: el.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: el.job_title,
        };
    });

    // format the data and add to it the current user :D
    let dataCollaboratorWithCurrentUser = [...dataCollaborator];
    dataCollaboratorWithCurrentUser = [
        {
            header: firstStep.email,
            image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: "( current user )",
        },
        ...dataCollaboratorWithCurrentUser,
    ];

    return (
        <Flex column gap="gap.medium">
            <FlexItemSlider>
                <SliderTabs
                    tabs={["onboarding", "suivi", "offboarding"]}
                    // value={sliderTab}
                    defaultActiveIndex={2}
                    onChange={(value) => setSliderTab(value)}
                />
            </FlexItemSlider>
            <Flex.Item>
                <Text>
                    Pour ajouter une tâche, remplissez les informations
                    suivantes :
                </Text>
            </Flex.Item>
            <Flex>
                <TextTitle>Consignes</TextTitle>
            </Flex>
            <Flex.Item>
                <FlexDescription>
                    <Flex.Item>
                        <Input
                            label="Titre de la tâche"
                            placeholder="Donnez un titre évocateur"
                            fluid
                            onChange={(e) =>
                                setForm({ ...form, name: e.target.value })
                            }
                        />
                    </Flex.Item>
                    <Flex.Item>
                        <DropdownStyle
                            items={dataCategory?.map((el) => el.name)}
                            placeholder="Catégorie de la tâche"
                            checkable
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            fluid
                            styles={{
                                width: "100%",
                            }}
                            onChange={(_, option) => {
                                setForm({
                                    ...form,
                                    category_id:
                                        dataCategory[option.highlightedIndex]
                                            .id,
                                });
                            }}
                        />
                    </Flex.Item>
                </FlexDescription>
            </Flex.Item>
            <Flex gap="gap.large" vAlign="end">
                <Flex.Item>
                    <Flex column fill>
                        <Input.Label>Descriptif de la tâche</Input.Label>
                        <TextArea
                            label="Descriptif de la tâche"
                            placeholder="Ajouter des indications ici"
                            fluid
                            onChange={(e) =>
                                setForm({
                                    ...form,
                                    description: e.target.value,
                                })
                            }
                        />
                    </Flex>
                </Flex.Item>
            </Flex>
            <Divider />
            <Flex>
                <TextTitle>Document (facultatif)</TextTitle>
            </Flex>
            <Flex column gap="gap.small">
                <Input.Label>Action collaborateur sur un document</Input.Label>
                <DropdownStyle
                    items={TASK_ACTIONS}
                    placeholder="Action à réaliser"
                    checkable
                    defaultValue={TASK_ACTIONS[4].header}
                    value={TASK_ACTIONS[form?.document_action - 1]?.header}
                    getA11ySelectionMessage={{
                        onAdd: (item) => `${item} has been selected.`,
                    }}
                    onChange={(_, i) => {
                        setForm({ ...form, document_action: i.value.id });
                        setSavedFiles([]);
                    }}
                    fluid
                />
                {_showButtonUploadFileWithFile(
                    form?.document_action,
                    savedFiles
                ) && (
                    <>
                        <Button
                            type="file"
                            flat
                            fluid
                            icon={<FilesUploadIcon size="small" />}
                            content="Ajouter la pièce-jointe"
                            iconPosition="before"
                            onClick={() => refButtonUpload.current.click()}
                            tinted
                        />
                        <Input
                            hidden
                            ref={refButtonUpload}
                            type="file"
                            flat
                            fluid
                            icon={<FilesUploadIcon size="small" />}
                            content="Ajouter la pièce-jointe"
                            iconPosition="before"
                            onChange={(e) =>
                                uploadedFile(e.target.files, setSavedFiles)
                            }
                        />
                    </>
                )}
                {savedFiles?.map((el) => {
                    return (
                        <Attachment
                            icon={<FilesPdfIcon />}
                            header={el.filename || el.name}
                            description={file_size_format(el.filesize)}
                            actionable
                            action={{
                                icon: <TrashCanIcon />,
                                onClick: () => setSavedFiles([]),
                                title: "Remove",
                            }}
                            onClick={() => console.log("cliqued")}
                            style={{ maxWidth: "100%" }}
                        />
                    );
                })}
            </Flex>
            <Divider />
            <Flex>
                <TextTitle>Dates</TextTitle>
            </Flex>
            <FlexDates>
                <Flex.Item>
                    <Datepicker
                        today={new Date()}
                        calendar={{
                            calendarCell: {
                                children: (ComponentType, props) =>
                                    props.today ? (
                                        <Tooltip
                                            content="TODAY!"
                                            trigger={
                                                <ComponentType {...props} />
                                            }
                                        />
                                    ) : (
                                        <ComponentType {...props} />
                                    ),
                            },
                        }}
                        inputPlaceholder="Date limite"
                        onDateChange={(_, el) =>
                            setForm({
                                ...form,
                                due_date: moment(el.value).format("YYYY-MM-DD"),
                            })
                        }
                    />
                </Flex.Item>
            </FlexDates>
            <Divider />
            <Flex>
                <TextTitle>Collaborateurs concernés</TextTitle>
            </Flex>
            <FlexConcerne>
                <Flex.Item>
                    <DropdownStyle
                        items={dataCollaboratorWithCurrentUser}
                        placeholder="Qui va effectuer la tâche ?"
                        checkable
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                        fluid
                        styles={{
                            width: "100%",
                        }}
                        onChange={(_, option) => {
                            setForm({
                                ...form,
                                assigned_to: option.value.header,
                            });
                        }}
                    />
                </Flex.Item>
                <Flex.Item>
                    <Text>pour</Text>
                </Flex.Item>
                <Flex.Item>
                    <DropdownStyle
                        items={[
                            {
                                header: firstStep.email,
                                image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
                                content: "( current user )",
                            },
                        ]}
                        defaultValue={firstStep.email}
                        placeholder="Personne impactée"
                        checkable
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                        fluid
                        onChange={(_, option) => {
                            setForm({
                                ...form,
                                related_to: option.value.header,
                            });
                        }}
                    />
                </Flex.Item>
            </FlexConcerne>
            <Flex>
                <Checkbox
                    label="Tâche à faire valider"
                    toggle
                    onChange={(e, { checked }) => {
                        setTaskRequiredValidation(checked);
                        setForm({
                            ...form,
                            task_to_validate: checked,
                            validator_email: "",
                        });
                    }}
                    checked={taskRequiredValidation}
                />
            </Flex>
            {taskRequiredValidation && (
                <Flex>
                    <DropdownStyle
                        items={dataValidators}
                        placeholder="Qui va valider la tâche ?"
                        checkable
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                        onChange={(_, i) =>
                            setForm({
                                ...form,
                                validator_email: i.value.header,
                            })
                        }
                    />
                </Flex>
            )}
        </Flex>
    );
};

export default ModalAddTask;

const FlexDropzoneMobile = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: flex;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
`;
const FlexDropzone = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: flex;
    }
`;

const DropdownStyle = styled(Dropdown)`
    @media ${DEVICE.mobileS} {
        width: 100%;
    }
    @media ${DEVICE.tablet} {
        width: auto;
    }
    @media ${DEVICE.laptop} {
        width: 100%;
        img {
            width: 34px;
            height: 34px;
        }
    }
`;

const FlexConcerne = styled(Flex)`
    align-items: center;
    gap: 16px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        ${DropdownStyle} {
            width: 100%;
        }
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const FlexDates = styled(Flex)`
    gap: 16px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const FlexItemSlider = styled(FlexItem)`
    @media ${DEVICE.mobileS} {
        margin-top: 16px;
    }
    @media ${DEVICE.tablet} {
        margin-top: 0px;
    }
    @media ${DEVICE.laptop} {
        margin-top: 0px;
    }
`;

const FlexDescription = styled(Flex)`
    gap: 16px;
    align-items: flex-end;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const TextTitle = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
`;

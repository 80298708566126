import React, { useEffect, useState } from "react";

import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import {
    Flex,
    Button,
    Dialog,
    CloseIcon,
    MenuButton,
    ExpandIcon,
    Text,
} from "@fluentui/react-northstar";
import { Tab, TabList } from "@fluentui/react-components";

import {
    ClipboardTask24Regular,
    MoreVertical24Filled,
} from "@fluentui/react-icons";

import { TABS_SWITCHER, TABS_SWITCHER_AS_ARRAY, TABS_VIEWS } from "./constants";
import {
    DialogStyle,
    FlexContainerLarge,
    FlexSubTab,
    MenuButtonStyle,
} from "./style";
import { _get_collaborator_tasks } from "../../api/tasks";
import { CustomBadge } from "../../components/Badge";
import { MenuStyle } from "../../common/styles";
import { ROUTES } from "../../constants/routes";

import Toast from "../../components/Alerts";
import LoaderPage from "../../components/loaders/LoaderPage";
import MyTasks from "./MyTasks";
import MyValidatingTasks from "./MyValidatingTasks";
import CustomBreadCrumb from "../../components/breadcrumb";
import DialogManageTasksModel from "./TasksModels";
import DialogAddIndividualTask from "./TasksModels/DialogAddIndividualTask";
import DialogUseModalTask from "./TasksModels/DialogUseModalTask";
import { roles_access, ROLES_CODE } from "../../constants/roles";
import { I18N_NS_TRANSLATION } from "../../constants";
import MyCompanyList from "./MyCompanyList";
import ButtonBreadCrumb from "../../components/buttons";
import DialogChooseAttributionTask from "./TasksModels/DialogChooseAttributionTask";
import { TASK_STATUS } from "../../constants/tasks";
import { useSearchParams } from "react-router-dom";

const DashboardTasks = () => {
    useEffect(() => {
        document.title = "Gestionnaire de tache";
    }, []);

    const { t } = useTranslation(I18N_NS_TRANSLATION);
    const [params, setParams] = useSearchParams();

    const [TabView, setTabView] = useState(TABS_VIEWS.ARRAY);
    const [openChooseAttributionTask, setOpenChooseAttributionTask] =
        useState(false);
    const [openUseModalTask, setOpenUseModalTask] = useState(false);
    const [openManageDialog, setOpenManageDialog] = useState(false);
    const [openAddIndividualTask, setOpenAddIndividualTask] = useState(false);

    const isRh = roles_access({ usersPrivileges: [ROLES_CODE.hr] });
    const isManager = roles_access({
        usersPrivileges: [ROLES_CODE.manager],
    });

    const [showAlert, setShowAlert] = useState({
        visible: null,
        message: null,
        level: null,
    });

    const [selectedValue, setSelectedValue] = useState(
        TABS_SWITCHER[params.get("tab")] ?? TABS_SWITCHER.MY_TASKS
    );

    const hideDialogManageModels = () => {
        setOpenManageDialog(false);
    };

    const onTabSelect = (event, data) => {
        setSelectedValue(data.value);
    };

    const onChange = (tab) => {
        setTabView(tab);
    };

    const _showSelectedTabs = (selectedValue) => {
        let component = null;
        switch (selectedValue) {
            case TABS_SWITCHER.MY_TASKS:
                component = (
                    <MyTasks
                        TabView={TabView}
                        onChange={onChange}
                        tasks={allTasks?.tasks}
                    />
                );

                break;
            case TABS_SWITCHER.TASK_TO_VALIDATE:
                component = (
                    <MyValidatingTasks
                        refetchGetAllTasks={refetchGetAllTasks}
                    />
                );

                break;
            case TABS_SWITCHER.MY_COMPANY_LIST:
                component = <MyCompanyList />;

                break;
        }
        return component;
    };

    const userChooseType = (type) => {
        setOpenChooseAttributionTask(false);
        switch (type) {
            case "USE_MODAL":
                setOpenUseModalTask(true);
                break;
            case "CREATE_TASK_FROM_ZERO":
                setOpenAddIndividualTask(true);
                break;
        }
    };

    /**
     * get tasks and validations tasks.
     * just for the badge number tasks
     */
    const {
        data: allTasks,
        isLoading,
        isFetching,
        refetch: refetchGetAllTasks,
    } = useQuery(["getAllTasks"], () =>
        _get_collaborator_tasks({ id_user: null })
    );

    console.log({ allTasks });

    return (
        <>
            <CustomBreadCrumb
                navList={[
                    {
                        label: t(
                            "pages.manage_modal_tasks.breadcrumb.nav_home"
                        ),
                        href: ROUTES.home,
                    },
                    {
                        label: t(
                            "pages.manage_modal_tasks.breadcrumb.nav_manage_task"
                        ),
                        href: ROUTES.task_manager,
                    },
                ]}
                actions={
                    /** SHOW ACTIONS JUST WHEN THE USER IS **HR** */
                    isRh && (
                        <Flex styles={{ marginLeft: "auto" }} vAlign="center">
                            <DialogStyle
                                header="Attribuer une tâche"
                                open={openChooseAttributionTask}
                                onCancel={() =>
                                    setOpenChooseAttributionTask(false)
                                }
                                content={
                                    <DialogChooseAttributionTask
                                        cb={userChooseType}
                                    />
                                }
                                trigger={
                                    <ButtonBreadCrumb
                                        content="Attribuer une tâche"
                                        onClick={() =>
                                            setOpenChooseAttributionTask(true)
                                        }
                                        icon={<ClipboardTask24Regular />}
                                        $hideOnMobile
                                    />
                                }
                            />
                            <ButtonBreadCrumb
                                content="Gérer les modèles de tâches"
                                onClick={() => setOpenManageDialog(true)}
                                icon={<ClipboardTask24Regular />}
                                $hideOnMobile
                            />

                            <DialogAddIndividualTask
                                setShowAlert={setShowAlert}
                                open={openAddIndividualTask}
                                setOpen={setOpenAddIndividualTask}
                            />
                            {/* UTILISER UN MODELE DE TACHE */}
                            <DialogUseModalTask
                                setShowAlert={setShowAlert}
                                open={openUseModalTask}
                                setOpen={setOpenUseModalTask}
                            />
                            {/* For Mobile */}
                            <MenuButtonStyle
                                trigger={
                                    <Button
                                        inverted
                                        icon={<MoreVertical24Filled />}
                                        iconOnly
                                    />
                                }
                                menu={[
                                    "Attribuer une tâche",
                                    "Gérer les modèles de tâches",
                                ]}
                                on="hover"
                                onMenuItemClick={(e, i) => {
                                    const value = i?.itemPosition;
                                    switch (value) {
                                        case 1:
                                            setOpenChooseAttributionTask(true);
                                            break;
                                        case 2:
                                            setOpenManageDialog(true);
                                            break;
                                    }
                                }}
                            />
                        </Flex>
                    )
                }
            />
            <Flex
                column
                grow
                styles={{
                    backgroundColor: "#F0F0F0",
                    minHeight: "100vh",
                    // paddingBottom: "50px",
                    overflowX: "auto",
                }}
            >
                {showAlert.visible && (
                    <Toast
                        visible={showAlert?.visible}
                        content={showAlert?.message}
                        level={showAlert?.level}
                        onStatusChange={() =>
                            setShowAlert({
                                visible: null,
                                message: null,
                                level: null,
                            })
                        }
                    />
                )}

                {/* ADD ALL DIALOG HERE */}
                {/* ADD TEMPLATE TASK */}
                <DialogStyle
                    content={<DialogManageTasksModel />}
                    header={t(
                        "pages.manage_modal_tasks.dialogs.manage_models.title"
                    )}
                    headerAction={
                        <CloseIcon onClick={() => hideDialogManageModels()} />
                    }
                    open={openManageDialog}
                    backdrop={true}
                    onCancel={() => hideDialogManageModels()}
                />

                {isLoading ? (
                    <LoaderPage label="Chargement des tâches" />
                ) : (
                    <>
                        <FlexContainerLarge fill grow>
                            <FlexSubTab grow fill>
                                <TabList
                                    defaultSelectedValue={
                                        TABS_SWITCHER.MY_TASKS
                                    }
                                    selectedValue={selectedValue}
                                    onTabSelect={onTabSelect}
                                    appearance="transparent"
                                >
                                    <Tab value={TABS_SWITCHER.MY_TASKS}>
                                        <Flex gap="gap.small" vAlign="center">
                                            <Text content="Mes tâches" />
                                            <CustomBadge
                                                label={allTasks?.tasks
                                                    ?.filter(
                                                        (task) =>
                                                            task?.status ===
                                                            TASK_STATUS.TODO
                                                    )
                                                    ?.length.toString()}
                                                bg={
                                                    selectedValue ===
                                                    TABS_SWITCHER.MY_TASKS
                                                        ? "var(--bg-orange)"
                                                        : "var(--bg-darkGrey)"
                                                }
                                            />
                                        </Flex>
                                    </Tab>
                                    {(isManager || isRh) && (
                                        <Tab
                                            value={
                                                TABS_SWITCHER.TASK_TO_VALIDATE
                                            }
                                        >
                                            <Flex
                                                gap="gap.small"
                                                vAlign="center"
                                            >
                                                <Text content="Tâches à valider" />
                                                <CustomBadge
                                                    label={allTasks?.tasks_to_validate?.length.toString()}
                                                    bg={
                                                        selectedValue ===
                                                        TABS_SWITCHER.TASK_TO_VALIDATE
                                                            ? "var(--bg-orange)"
                                                            : "var(--bg-darkGrey)"
                                                    }
                                                />
                                            </Flex>
                                        </Tab>
                                    )}
                                    {isRh && (
                                        <Tab
                                            value={
                                                TABS_SWITCHER.MY_COMPANY_LIST
                                            }
                                        >
                                            Entreprise
                                        </Tab>
                                    )}
                                </TabList>
                            </FlexSubTab>
                        </FlexContainerLarge>
                        {_showSelectedTabs(selectedValue)}
                    </>
                )}
            </Flex>
        </>
    );
};

export default DashboardTasks;

import { BASE_URL_API } from "../../../constants";
import { useAuthDetailStore, useAuthStore } from "../../../stores/useAuthStore";

export const _api_upload_avatar = async ({
    uploaded_files,
    id_user = null,
}) => {
    const fd = new FormData();
    fd.append("uploaded_file", uploaded_files);

    const user = useAuthStore.getState().infos;
    const user_infos = useAuthDetailStore.getState().detail;

    return fetch(
        `${BASE_URL_API}/user/${
            id_user ? id_user : user_infos.id
        }/profile/photo`,
        {
            method: "PUT",
            body: fd,
            credentials: "include",
            headers: {
                Authorization: `Bearer ${user.access}`,
            },
        }
    ).then((res) => res.json());
};

import React from "react";
import { Outlet } from "react-router-dom";
import CustomBreadCrumb from "../../components/breadcrumb";
import { useAuthDetailStore } from "../../stores/useAuthStore";
import { ROUTES } from "../../constants/routes";
import { ContainerFluid } from "../../common/styles";
import { useTranslation } from "react-i18next";
import { I18N_NS_DELEGATION } from "./constants";

const DelegationRoles = () => {
    const detail = useAuthDetailStore((state) => state.detail);
    const { t } = useTranslation(I18N_NS_DELEGATION);
    const href_to_my_profil = ROUTES.employee_folder.custom_route(detail?.id);

    return (
        <ContainerFluid column>
            <CustomBreadCrumb
                navList={[
                    {
                        href: href_to_my_profil,
                        label: t("breadcrumb.my_profil"),
                    },
                    {
                        href: ROUTES.delegation_roles.home,
                        label: t("breadcrumb.delegate_role"),
                    },
                ]}
            />
            <Outlet />
        </ContainerFluid>
    );
};

export default DelegationRoles;

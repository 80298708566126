import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
    width: 100%;
    padding-block: 8px;
`;
export const ContentText = styled(Text)`
    color: white;
    background-color: ${({ $color }) => ($color ? $color : "#616161")};
    text-align: center;

    width: calc(100% - 50px);
    border-radius: 8px;

    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
`;

import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { useQuery } from "react-query";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller } from "react-hook-form";

import MuiPhoneNumber from "material-ui-phone-number";

import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import Alert from "@mui/material/Alert";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { getManagers, getServices } from "../../../api/user";

import { useStepperContext } from "./ContextAddCollaborateur";
import moment from "moment";
import { useEffect } from "react";

const YUP_SCHEMA = yup.object().shape(
    {
        nom: yup.string().min(3).required(),
        prenom: yup.string().min(3).required(),
        email: yup.string().email().required(),
        telephone: yup
            .string()
            .min(8)
            .required("Le numéro de téléphone est obligatoire !"),
        date_arrivee: yup
            .string()
            .required()
            .test(
                "date_arrivee",
                "Veuillez saisir une date supérieur a aujourd'hui",
                (value) => {
                    return moment().diff(moment(value), "day") <= 0;
                }
            ),
        date_depart: yup
            .string()
            .nullable()
            .ensure()
            .when(["date_depart", "date_arrivee"], {
                is: (date_depart, date_arrivee) => date_depart && date_arrivee,
                then: yup
                    .string()
                    .test(
                        "date_depart",
                        "La date de départ ne doit pas être inferieur a celle d'arrivée !",
                        (value, props) => {
                            return (
                                moment(value).diff(
                                    moment(
                                        props?.from[0].value?.date_arrivee ||
                                            new Date()
                                    ),
                                    "day"
                                ) > 0
                            );
                        }
                    )
                    .required(),
            }),
        manager: yup.string().email().min(1).required(),
        service: yup.number().min(1).required(),

        job_title: yup.string().when(["job_title"], {
            is: (job_title) => job_title !== "" && job_title !== null,
            then: yup.string().nullable().notRequired(),
        }),
    },
    [
        ["date_arrivee", "date_depart"],
        ["date_depart", "date_arrivee"],
        ["date_depart", "date_depart"],
        ["job_title", "job_title"],
        ["type_contrat", "type_contrat"],
    ]
);

const GeneralInformations = () => {
    const {
        date_arrivee: ctx_date_arrivee,
        date_depart: ctx_date_depart,
        email: ctx_email,
        job_title: ctx_job_title,
        manager: ctx_manager,
        nom: ctx_nom,
        prenom: ctx_prenom,
        service: ctx_service,
        telephone: ctx_telephone,
        type_contrat: ctx_type_contrat,
        globalError,
    } = useStepperContext((state) => state.firstStep);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        getValues,
        watch,
        control,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(YUP_SCHEMA),
        defaultValues: {
            date_arrivee: ctx_date_arrivee,
            date_depart: ctx_date_depart,
            email: ctx_email,
            job_title: ctx_job_title,
            manager: ctx_manager,
            nom: ctx_nom,
            prenom: ctx_prenom,
            service: ctx_service,
            telephone: ctx_telephone,
            type_contrat: ctx_type_contrat,
        },
    });

    watch((element) => {
        // if (element.telephone) {
        // 	console.log(element);
        // }
        // if (element.telephone) {
        // 	trigger("telephone");
        // }
    });

    const onSubmit = (data) => console.log(data);
    const setFirstStep = useStepperContext((state) => state.setFirstStep);

    const {
        date_arrivee,
        date_depart,
        email,
        job_title,
        manager,
        nom,
        prenom,
        service,
        telephone,
        type_contrat,
    } = getValues();

    useEffect(() => {
        if (isValid) {
            setFirstStep({
                isValidForm: isValid,
            });
        }

        setFirstStep({
            date_arrivee,
            date_depart,
            email,
            job_title,
            manager,
            nom,
            prenom,
            service,
            telephone,
            type_contrat,
        });
    }, [
        date_arrivee,
        date_depart,
        email,
        job_title,
        manager,
        nom,
        prenom,
        service,
        telephone,
        type_contrat,
        isValid,
    ]);

    const _handleChange = (newState) => {
        setFirstStep(newState);
    };

    // fetch manager
    const { data: dataManager } = useQuery(
        ["getManagers"],
        () => getManagers(),
        {
            staleTime: 1000,
        }
    );

    // fetch service
    const { data: dataService } = useQuery(
        ["getServices"],
        () => getServices(),
        {
            staleTime: 1000,
        }
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent="center"
                alignItems={"center"}
            >
                <Stack mb={2} style={{ width: "52ch" }}>
                    {globalError && (
                        <Alert severity="error">{globalError}</Alert>
                    )}
                </Stack>

                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    display={"flex"}
                    flexDirection={"row"}
                    autoComplete="off"
                >
                    {/* forms */}
                    <TextField
                        required
                        id="outlined-nom"
                        type="text"
                        label="Nom"
                        variant="outlined"
                        defaultValue={ctx_nom || nom}
                        onChange={(e) => _handleChange({ nom: e.target.value })}
                        name="nom"
                        {...register("nom")}
                        error={errors.nom ? true : false}
                        helperText={
                            errors.nom &&
                            "Le nom doit contenir au minimum 3 caractères !"
                        }
                    />
                    <TextField
                        required
                        id="outlined-prenom"
                        type="text"
                        label="Prénom"
                        variant="outlined"
                        defaultValue={ctx_prenom || prenom}
                        onChange={(e) =>
                            _handleChange({ prenom: e.target.value })
                        }
                        name="prenom"
                        {...register("prenom")}
                        error={errors.prenom ? true : false}
                        helperText={
                            errors.prenom &&
                            "Le prénom doit contenir au minimum 3 caractères !"
                        }
                    />
                </Box>
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    display={"flex"}
                    flexDirection={"row"}
                    autoComplete="off"
                >
                    <TextField
                        required
                        id="outlined-email"
                        label="Email personnelle"
                        variant="outlined"
                        type="email"
                        defaultValue={ctx_email || email}
                        // onChange={(e) => _handleChange({ email: e.target.value })}
                        name="email"
                        {...register("email")}
                        error={errors.email ? true : false}
                        helperText={
                            errors.email &&
                            "Veuillez saisir un 'email' valide !"
                        }
                    />

                    <Controller
                        {...register("telephone")}
                        control={control}
                        name="telephone"
                        defaultValue={ctx_telephone || telephone}
                        onChange={(newValue) =>
                            _handleChange({ telephone: newValue })
                        }
                        render={({ field: { ref, ...fieldProps } }) => {
                            return (
                                <MuiPhoneNumber
                                    {...fieldProps}
                                    required
                                    id="outlined-basic"
                                    label="Numéro de téléphone"
                                    variant="outlined"
                                    type="tel"
                                    value={telephone}
                                    defaultCountry={"fr"}
                                    placeholder="Numéro de téléphone..."
                                    error={errors.telephone ? true : false}
                                    helperText={
                                        errors.telephone &&
                                        "Veuillez saisir un 'telephone' valide !"
                                    }
                                    autoFormat={true}
                                    disableAreaCodes={true}
                                />
                            );
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    display={"flex"}
                    flexDirection={"row"}
                    autoComplete="off"
                >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Controller
                            {...register("date_arrivee")}
                            control={control}
                            name="date_arrivee"
                            defaultValue={ctx_date_arrivee || date_arrivee}
                            onChange={(newValue) =>
                                _handleChange({ date_arrivee: newValue })
                            }
                            render={({ field: { ref, ...fieldProps } }) => {
                                return (
                                    <DesktopDatePicker
                                        {...fieldProps}
                                        inputRef={ref}
                                        label="Date arrivée"
                                        inputFormat="DD/MM/yyyy"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...fieldProps}
                                                required
                                                error={
                                                    errors.date_arrivee
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.date_arrivee &&
                                                    "Veuillez saisir une 'date arrivée' valide !"
                                                }
                                            />
                                        )}
                                    />
                                );
                            }}
                        />
                        <Controller
                            {...register("date_depart")}
                            control={control}
                            name="date_depart"
                            value={date_depart}
                            defaultValue={ctx_date_depart || date_depart}
                            onChange={(newValue) =>
                                _handleChange({ date_depart: newValue })
                            }
                            render={({ field: { ref, ...fieldProps } }) => {
                                return (
                                    <DesktopDatePicker
                                        {...fieldProps}
                                        inputRef={ref}
                                        label="Date de départ"
                                        inputFormat="DD/MM/yyyy"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...fieldProps}
                                                error={
                                                    errors.date_depart
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.date_depart &&
                                                    errors.date_depart.message
                                                }
                                            />
                                        )}
                                    />
                                );
                            }}
                        />
                    </LocalizationProvider>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent="space-between"
                >
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-select-contrat"
                            select
                            label="Type de contrat"
                            defaultValue={ctx_type_contrat || type_contrat}
                            onChange={(e) =>
                                _handleChange({ type_contrat: e.target.value })
                            }
                            name="type_contrat"
                            {...register("type_contrat")}
                            error={errors.type_contrat ? true : false}
                            helperText={
                                errors.type_contrat &&
                                "Veuillez saisir un 'Type de contrat' valide !"
                            }
                        >
                            <MenuItem value={1}>CDI</MenuItem>
                            <MenuItem value={2}>CDD</MenuItem>
                            <MenuItem value={3}>Intérim</MenuItem>
                        </TextField>
                    </Box>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-basic"
                            label="Profession"
                            variant="outlined"
                            type="text"
                            name="job_title"
                            defaultValue={ctx_job_title || job_title}
                            {...register("job_title")}
                            error={errors.job_title ? true : false}
                            helperText={
                                errors.job_title &&
                                "Veuillez saisir une 'Profession' valide !"
                            }
                        />
                    </Box>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent="space-between"
                >
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-select-service"
                            select
                            label="Service"
                            defaultValue={ctx_service || service}
                            onChange={(e) =>
                                _handleChange({ service: e.target.value })
                            }
                            name="service"
                            {...register("service")}
                            error={errors.service ? true : false}
                            helperText={
                                errors.service &&
                                "Veuillez saisir un 'Service' valide !"
                            }
                            required
                        >
                            {dataService?.map((el, i) => (
                                <MenuItem key={el?.id} value={el?.id}>
                                    {el?.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            required
                            id="outlined-select-manager"
                            select
                            label="Manager"
                            defaultValue={ctx_manager || manager}
                            onChange={(e) =>
                                _handleChange({ manager: e.target.value })
                            }
                            name="manager"
                            {...register("manager")}
                            error={errors.manager ? true : false}
                            helperText={
                                errors.manager &&
                                "Veuillez saisir un 'Manager' valide !"
                            }
                        >
                            {dataManager?.map((el, i) => (
                                <MenuItem value={el.email} key={i}>
                                    {`${el?.first_name} ${el?.last_name} ( ${el.email} )`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Box>
            </Box>
        </form>
    );
};

export default GeneralInformations;

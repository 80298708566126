import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useBenefeciaryStore = create(
    persist(
        (set) => ({
            benefeciary: null,
            setBenefeciary: (newBenef) => {
                set(() => ({ benefeciary: newBenef }));
            },
        }),
        {
            name: "benefeciary_remove",
        }
    )
);

import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { I18N_NS_DELEGATION, QUERY_KEY_DELEGATION } from "../constants";
import { _api_get_delegation } from "../api";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import useToast from "../../../hooks/useToast";
import { get_config_depend_param_alert } from "../functions";

const useLogicDelegationHome = () => {
    // translation
    const { t } = useTranslation(I18N_NS_DELEGATION);

    const [searchParams, setSearchParams] = useSearchParams();
    const [toast, setToast] = useToast();

    useEffect(() => {
        if (searchParams.get("alert")) {
            const { level, content } =
                get_config_depend_param_alert("assign_success");
            setToast((state) => {
                return {
                    ...state,
                    visible: true,
                    level,
                    content,
                };
            });
        }
    }, [searchParams.get("alert")]);

    // functions
    const onStatusChangeToast = () => {
        setToast((state) => ({
            ...state,
            visible: false,
        }));
        setSearchParams({});
        return;
    };

    // get the data
    const {
        data: delegation_list,
        isLoading: is_loading_delegation_list,
        isFetching,
    } = useQuery([QUERY_KEY_DELEGATION], () => {
        return _api_get_delegation();
    });

    return {
        query: {
            data: delegation_list,
            isLoading: is_loading_delegation_list || isFetching,
        },
        words: {
            t: t,
            back_header: t("home.back_header.title"),
            home_detail_title: t("home.details.title"),
        },
        toast: {
            ...toast,
            onStatusChange: onStatusChangeToast,
            setToast,
        },
    };
};

export default useLogicDelegationHome;

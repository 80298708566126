import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const DatesFromTo = ({ date_from, date_to }) => {
    return (
        <Wrapper space="between" vAlign="center">
            <Label content={date_from} />
            <Label content={"-"} />
            <Label content={date_to} />
        </Wrapper>
    );
};

export default DatesFromTo;

const Label = styled(Text)`
    color: #242424;

    /* Medium/400 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const Wrapper = styled(Flex)`
    gap: 4px;
    border-radius: 4px;
    background: #e8ebfa;
    width: 190px;
    height: 28px;
    padding: 4px 8px;
`;

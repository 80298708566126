import { Avatar, Box, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import Cell from "./Cell";

const WeekRow = ({ weeklydata }) => {
    const users = weeklydata?.users;
    return (
        <Flex column>
            {users?.map((user) => {
                const TotalEffectif = user?.week?.declared_hours_count;
                return (
                    <>
                        <Flex space="between" vAlign="center">
                            <LeftRow>
                                <Avatar
                                    image={user?.avatar}
                                    name={user?.name}
                                />
                                <Text content={user?.name} />
                            </LeftRow>
                            <Flex
                                styles={{
                                    gap: "10px",
                                }}
                            >
                                {user?.week?.days?.map((el) => {
                                    return <Cell el={el} />;
                                })}
                            </Flex>
                            {/* total text  */}
                            <Flex
                                hAlign="center"
                                vAlign="center"
                                styles={{
                                    width: "187px",
                                    padding: "12px 16px",
                                    minHeight: "64px",
                                    background: "#fff",
                                }}
                            >
                                <TotalText
                                    content={`${
                                        TotalEffectif !== null &&
                                        TotalEffectif !== undefined
                                            ? TotalEffectif
                                            : "--,--"
                                    } h`}
                                />
                            </Flex>
                        </Flex>
                        <Box
                            styles={{
                                height: "4px",
                                background: "#E8EBFA",
                            }}
                        />
                    </>
                );
            })}
        </Flex>
    );
};

export default WeekRow;

const LeftRow = styled(Flex)`
    width: 308px;
    height: 65px;
    padding: 12px 16px 12px 45px;
    align-items: center;
    gap: 8px;
    background-color: #fff;
`;
const TotalText = styled(Text)`
    color: #242424;
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

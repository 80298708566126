import { Button, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const ButtonSliderMonth = styled(Button)`
    padding-inline: 0px;
    width: 24px;
    min-width: 24px;
    height: 24px;
`;
export const FlexMonthName = styled(Flex)`
    width: 190px;
    .month_name {
        color: #424242;
        text-align: center;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        text-transform: capitalize;
    }
`;
export const CellHistoryDate = styled(Text)`
    color: #424242;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
export const TextUsername = styled(Text)`
    color: var(--color-primary);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;
export const CellAmount = styled(Text)`
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;
export const ButtonDownloadHistoryExcel = styled(Button)`
    max-width: 100%;
    text-decoration: underline;
    color: ${({ disabled }) =>
        disabled === true ? "lightgrey" : "var(--color-primary)"};
`;

import { CompoundButton } from "@fluentui/react-components";
import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const WrapperIcon = styled(Flex)`
    width: 32px;
    height: 32px;

    border-radius: 4px;

    background-color: #e8ebfa;
    color: white;
`;

export const CompoundButtonStyle = styled(CompoundButton)`
    min-width: 326px;
    justify-content: flex-start;
`;

import { useState } from "react";

/**
 * useToast
 * @typedef {Object} PropsUseToast
 * @property {boolean} visible - afficher ou pas le toast
 * @property {"danger"|"success"|"warning"} level - permet d'afficher le bon style
 * @property {string} content - le message a afficher
 * @property {function} onStatusChange - cb lorsque le status change
 *
 * @returns
 */
const useToast = (props) => {
    const onStatusChange = () => {
        return setShow((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    const [show, setShow] = useState({
        visible: props?.visible ?? false,
        level: props?.level ?? "success",
        content: props?.content ?? "",
        onStatusChange,
    });

    return [show, setShow];
};

export default useToast;

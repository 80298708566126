import { Button, Flex, Text, TextArea } from "@fluentui/react-northstar";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import styled from "styled-components";
import { _api_update_about_me } from "../../api";
import {
    useGetAboutMe,
    useGetSkippedAboutMe,
    useSetAboutMe,
    useSetSkippedAboutMe,
} from "../../stores/useOnboarding";
import { Card, Wrapper } from "../../styles";

const CardEditAboutMe = () => {
    const { t } = useTranslation("onboarding");
    const [about_me, setAboutme] = useState("");

    const store_skipped_about_me = useSetSkippedAboutMe();
    const store_get_skipped_about_me = useGetSkippedAboutMe();
    const store_set_sent_about_me = useSetAboutMe();
    const store_get_about_me = useGetAboutMe();

    const { isLoading, mutate } = useMutation((about_me) =>
        _api_update_about_me(about_me)
    );

    const post_about_me = () => {
        mutate(about_me, {
            onSuccess: () => {
                store_set_sent_about_me(about_me);
            },
        });
    };

    return (
        <Wrapper column>
            <Card column gap="gap.medium">
                <Flex column gap="gap.small">
                    <Title>À propos</Title>
                    {!store_get_skipped_about_me && !store_get_about_me.sent ? (
                        <TextArea
                            placeholder={t(
                                "section_about_me.placeholder_about_me"
                            )}
                            value={about_me}
                            styles={{
                                height: "100px",
                                lineHeight: "20px",
                            }}
                            onChange={(e, i) => setAboutme(i.value)}
                        />
                    ) : (
                        <Text>{store_get_about_me.value}</Text>
                    )}
                </Flex>
                {store_get_skipped_about_me && (
                    <div>
                        <Button
                            disabledFocusable
                            content={t(
                                "section_upload_image.buttons.skipped_step"
                            )}
                        />
                    </div>
                )}
                {!store_get_skipped_about_me && !store_get_about_me.sent ? (
                    <Flex hAlign="end" gap="gap.small">
                        <Button
                            content={"Passer cette étape"}
                            secondary
                            onClick={() => store_skipped_about_me()}
                        />
                        <Button
                            content={"Envoyer"}
                            disabled={about_me ? false : true}
                            primary
                            loading={isLoading}
                            onClick={post_about_me}
                        />
                    </Flex>
                ) : null}
            </Card>
        </Wrapper>
    );
};

export default CardEditAboutMe;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
`;

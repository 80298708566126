import { create } from "zustand";
import { persist } from "zustand/middleware";

export const modelWhereIamExpenses = {
    isEmployeePage: true, // 0
    isManagerPage: false, // 1
    isAccountingManager: false, // 2
};

export const useWhereIamExpenses = create(
    persist(
        (set, get) => ({
            ...modelWhereIamExpenses,
            getIdTabActive: () => {
                const isEmployeePage = get().isEmployeePage;
                const isManagerPage = get().isManagerPage;
                const isAccountingManager = get().isAccountingManager;
                if (isEmployeePage) return 0;
                if (isManagerPage) return 1;
                if (isAccountingManager) return 2;
            },
            setWhereIam: (newState) => {
                set(() => newState);
            },
        }),
        {
            name: "useWhereIamExpenses",
        }
    )
);

export const format_employees_list_dropdown = (list = []) => {
    const data = list.map((user) => {
        return {
            // id: user.id,
            header: user.name,
            image: user.avatar,
            content: user.job_title,
            email: user.username,
        };
    });

    return data;
};

// show alert depend on KEY params
export const get_config_depend_param_alert = (key) => {
    const KEYS = {
        assign_success: {
            level: "success",
            content: "Informations enregistrées",
        },
    };

    return KEYS[key];
};

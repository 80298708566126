/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import { Flex, Loader } from "@fluentui/react-northstar";
import { Tab, TabList } from "@fluentui/react-tabs";
import TabAbout from "./TabAbout";
import TabIdentity from "./TabIdentity";
import { useEmployeeFolderIdUser, useEmployeeFolderIsMe } from "../../../store";
import { ROLES_CODE, roles_access } from "../../../../../constants/roles";
import TabContract from "./TabContract";
import TabPlanning from "./TabPlanning";
import { Container, ContainerFluid } from "../../../../../common/styles";
import { QUERY_KEY } from "../../../constants";
import { useQuery } from "react-query";
import { useAuthDetailStore } from "../../../../../stores/useAuthStore";
import { _api_get_about_informations } from "../../../api";

const ALL_TABS = {
    about: { content: "À propos", id: "about" },
    identity: { content: "Identité", id: "identity" },
    contract: { content: "Contrat", id: "contract" },
    plan: { content: "Planning", id: "plan" },
};

const _displayTabsDependRoles = ({ isMe, isHR, isManager, manager_email }) => {
    const { detail } = useAuthDetailStore.getState();

    if (isMe || isHR || isManager) {
        const all_tabs_without_planning = [
            ALL_TABS.about,
            ALL_TABS.identity,
            ALL_TABS.contract,
        ];
        if (isManager && manager_email === detail?.email) {
            all_tabs_without_planning.push(ALL_TABS.plan);
            return all_tabs_without_planning;
        } else if (isHR) {
            all_tabs_without_planning.push(ALL_TABS.plan);
            return all_tabs_without_planning;
        }
        return all_tabs_without_planning;
    }
    return [ALL_TABS.about];
};

const Body = () => {
    const [selectedValue, setSelectedValue] = useState(ALL_TABS.about.id);

    const isMe = useEmployeeFolderIsMe();
    const isHR = roles_access({
        usersPrivileges: [ROLES_CODE.hr],
    });
    const isManager = roles_access({
        usersPrivileges: [ROLES_CODE.manager],
    });

    const onTabSelect = (event, data) => {
        setSelectedValue(data.value);
    };

    const id_employee = useEmployeeFolderIdUser();

    // * queries
    const { data, isLoading, isFetching } = useQuery(
        [QUERY_KEY + "::about", id_employee],
        () => _api_get_about_informations(id_employee),
        {
            refetchOnWindowFocus: false,
        }
    );

    if (isLoading) {
        return (
            <ContainerFluid>
                <Container>
                    <Loader
                        label="chargement des données"
                        labelPosition="end"
                    />
                </Container>
            </ContainerFluid>
        );
    }

    const tabs = _displayTabsDependRoles({
        isMe,
        isHR,
        isManager,
        manager_email: data?.manager?.email, // for planning tab
    });

    return (
        <ContainerFluid column>
            <TabList
                selectedValue={selectedValue}
                onTabSelect={onTabSelect}
                style={{
                    borderBottom: "1px solid #D1D1D1",
                    borderTop: "1px solid #D1D1D1",
                    backgroundColor: "#f5f5f5",
                }}
            >
                <Container>
                    {tabs?.map((tab) => {
                        return (
                            <Tab id={tab.id} value={tab.id}>
                                {tab.content}
                            </Tab>
                        );
                    })}
                </Container>
            </TabList>
            <Container column fill>
                <Flex
                    styles={{
                        paddingBlock: "24px",
                        backgroundColor: "#f0f0f0",
                    }}
                >
                    {selectedValue === "about" && (
                        <TabAbout
                            data={data}
                            isLoading={isLoading}
                            isFetching={isFetching}
                        />
                    )}
                    {selectedValue === "identity" && <TabIdentity />}
                    {selectedValue === "contract" && <TabContract />}
                    {selectedValue === "plan" && <TabPlanning />}
                </Flex>
            </Container>
        </ContainerFluid>
    );
};

export default Body;

import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import TimeReportingCounterBadge from "./TimeReportingCounterBadge";

// il manque du props ici a les ajouter aprés en fonction du slider tab ( props pour à ValidationError, incomplete, validé)

const TimeReportingStatus = ({ counter = 0, label = "" }) => {
    if (!label) return;
    return (
        <Flex vAlign="center" hAlign="center" styles={{ gap: "8px" }}>
            <HeaderText>
                Feuille de Temps <b>{label}</b>
            </HeaderText>
            <TimeReportingCounterBadge bg={"#5b5fc7"} content={counter} />
        </Flex>
    );
};

export default TimeReportingStatus;

const HeaderText = styled(Text)`
    color: #242424;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

import { Flex, Pill } from "@fluentui/react-northstar";
import React from "react";

const PillListEmployeeSelected = ({
    selectedEmployees = [],
    onDismiss = () => console.log("dismiss click"),
}) => {
    return (
        <Flex fill wrap>
            {selectedEmployees?.map((user) => {
                console.log({ user });
                const header = user?.header;
                return (
                    <Pill
                        key={user.id}
                        image={user?.image}
                        uid={user.id}
                        actionable
                        onDismiss={(e, data) => onDismiss(e, data, header)}
                    >
                        {user?.header}
                    </Pill>
                );
            })}
        </Flex>
    );
};

export default PillListEmployeeSelected;

import { Button, Flex, Text } from "@fluentui/react-northstar";
import React, { useEffect } from "react";
import styled from "styled-components";
import DialogModifyWeek from "../DialogModifyWeek";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import DialogModifyWeekTypeTemplate from "../DialogModifyWeekTypeTemplate";

const OverlayEditableDay = ({
    day_data,
    shifts,
    day_id,
    dispatchTypeTemplate,
    onConfirm = () => null,
    onCancel = () => null,
    onClickTrigger = () => null,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    return (
        <Wrapper vAlign="center" hAlign="center">
            <Button
                content={t("v2.home.overlay.button_update_day")}
                inverted
                onClick={() => onClickTrigger()}
            />
            {/* <DialogModifyWeekTypeTemplate
                shifts={shifts}
                day_id={day_id}
                dispatchTypeTemplate={dispatchTypeTemplate}
                trigger={
                    <Button
                        content={t("v2.home.overlay.button_update_day")}
                        inverted
                    />
                }
            /> */}
            {/* <DialogModifyWeek
                day_data={day_data}
                shifts={shifts}
                day_id={day_id}
                update_button={
                    <Button
                        content={t("v2.home.overlay.button_update_day")}
                        inverted
                    />
                }
                onConfirm={onConfirm}
                onCancel={onCancel}
            /> */}
        </Wrapper>
    );
};

export default OverlayEditableDay;

export const Wrapper = styled(Flex)`
    position: absolute;
    inset: 0;
    background-color: rgba(37, 36, 35, 0.65);
    z-index: 5;
`;

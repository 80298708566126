import {
    AppFolderIcon,
    Box,
    Button,
    CanvasAddPageIcon,
    CheckmarkCircleIcon,
    Divider,
    EyeIcon,
    FlagIcon,
    Flex,
    HandIcon,
    SendIcon,
    SignatureIcon,
    TaskListIcon,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { CustomBadge } from "../../../components/Badge";
import LinearProgressBar from "./LinearProgressBar";
import { Target20Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { get_action_document_from_back } from "../../../constants/tasks";
import moment from "moment";
import { Badge } from "@fluentui/react-components";
import { ContainerFluid } from "../../../common/styles";

export default function DisplayTasks({ tasks, onboarding_steps }) {
    const toManagementTasksPage = useNavigate();

    const totalTasks = tasks?.length;
    const tasksDone = tasks?.filter((el) => el?.task_is_done === true)?.length;
    const PercentTasksDone = Math.round((tasksDone / totalTasks) * 100);

    const style_icon_active = {
        color: "white",
    };
    const style_icon_skipped = {
        color: "#616161",
    };
    const style_icon = {
        color: "#5B5FC7",
    };

    const today = moment().format("YYYY-MM-DD");

    const format_onboarding_date = moment(
        onboarding_steps?.onboarding_start_date
    ).format("YYYY-MM-DD");

    const format_first_work_day = moment(
        onboarding_steps?.first_work_day
    ).format("YYYY-MM-DD");

    const format_probationary_period_end_date = moment(
        onboarding_steps?.probationary_period_end_date
    ).format("YYYY-MM-DD");

    const activated_onboarding = moment(format_onboarding_date).isSameOrBefore(
        today
    );

    const activated_first_day = moment(format_first_work_day).isSameOrBefore(
        today
    );

    const activated_probationary_period_end_date = moment(
        format_probationary_period_end_date
    ).isSameOrBefore(today);

    return (
        <ContainerFluid column>
            <MainTitle content="Mon onboarding" />
            <Container column>
                <SubTitle content="Dates clés" />

                <Flex
                    styles={{
                        marginLeft: "8px",
                        // marginTop: "32px",
                        position: "relative",
                        zIndex: 2,
                    }}
                    column
                    space="between"
                >
                    <Divider
                        styles={{
                            position: "absolute",
                            top: "14px",
                            width: "100%",
                            zIndex: 0,
                        }}
                    />

                    <Stepper
                        steps={[
                            {
                                id: 0,
                                icon: (
                                    <SignatureIcon
                                        size="medium"
                                        styles={
                                            activated_first_day
                                                ? style_icon
                                                : activated_onboarding
                                                ? style_icon_active
                                                : style_icon_skipped
                                        }
                                    />
                                ),
                                name: "Onboarding démarré",
                                date: format_onboarding_date,
                                activated: activated_onboarding,
                                skipped: activated_first_day,
                            },
                            {
                                id: 1,
                                icon: (
                                    <HandIcon
                                        outline
                                        size="medium"
                                        styles={
                                            activated_probationary_period_end_date
                                                ? style_icon
                                                : activated_first_day
                                                ? style_icon_active
                                                : style_icon_skipped
                                        }
                                    />
                                ),
                                name: "Premier jour",
                                date: format_first_work_day,
                                activated: activated_first_day,
                                skipped: activated_probationary_period_end_date,
                            },
                            {
                                id: 2,
                                icon: (
                                    <FlagIcon
                                        outline
                                        size="medium"
                                        styles={
                                            activated_probationary_period_end_date
                                                ? style_icon_active
                                                : style_icon_skipped
                                        }
                                    />
                                ),
                                name: "Fin de la période d'essai",
                                date: format_probationary_period_end_date,
                                activated:
                                    activated_probationary_period_end_date,
                            },
                            {
                                id: 3,
                                icon: (
                                    <Target20Regular
                                        styles={style_icon_skipped}
                                    />
                                ),
                                name: "Fin de l'onboarding",
                                date: "",
                            },
                        ]}
                    />
                </Flex>
                {tasks?.length === 0 ? null : (
                    <>
                        <Flex
                            styles={{ marginTop: "56px", marginBottom: "24px" }}
                            vAlign="center"
                            hAlign="center"
                            space="between"
                        >
                            <SubTitle
                                color="brand"
                                content="Avancement onboarding"
                            />
                            <LinearProgressBar
                                percent={
                                    isNaN(PercentTasksDone)
                                        ? 0
                                        : PercentTasksDone
                                }
                                animateTiming={1000}
                            />

                            <Button
                                onClick={() =>
                                    toManagementTasksPage(ROUTES.task_manager)
                                }
                                icon={<TaskListIcon />}
                                primary
                                content="Aller au gestionnaire de tâches"
                            />
                        </Flex>
                        {/* <Flex
                            column
                            styles={{
                                overflow: "scroll",
                                marginBottom: "25px",
                            }}
                        >
                            <TaskTodoStyle content="Tâches à réaliser" />
                            <TasksContent status={true} tasks={tasks} />
                        </Flex> */}
                    </>
                )}
            </Container>
        </ContainerFluid>
    );
}

// here

// here

const TasksContent = ({ action, tasks }) => {
    return tasks?.map((el, i) => {
        return (
            <Flex styles={{ padding: "6px" }} space="between" key={i}>
                <Flex vAlign="center" styles={{ width: "300px" }}>
                    {el?.task_is_done ? (
                        <Flex gap="gap.small" vAlign="center">
                            <Text
                                style={{
                                    textDecoration: "line-through",
                                }}
                                content={el?.name}
                            />
                            <CheckmarkCircleIcon styles={{ color: "green" }} />
                        </Flex>
                    ) : (
                        <TasksTextStyles content={el?.name} />
                    )}
                </Flex>
                <Flex vAlign="center" gap="gap.small">
                    <Flex
                        vAlign="center"
                        gap="gap.small"
                        styles={{
                            width: "150px",
                        }}
                    >
                        {get_action_document_from_back(el?.action)}
                    </Flex>

                    <Text
                        styles={{ width: "100px" }}
                        content={moment(el?.due_date)?.format("L")}
                    />
                </Flex>
                <Flex
                    gap="gap.large"
                    vAlign="center"
                    hAlign="end"
                    styles={{ width: "150px" }}
                >
                    <Badge
                        appearance="filled"
                        style={{
                            overflow: "hidden",
                            textDecoration: "ellipsis",
                            marginBlock: "4px",
                            backgroundColor: "black",
                        }}
                    >
                        <Text
                            truncated
                            styles={{ maxWidth: "100px", padding: "2px 6px" }}
                        >
                            {el?.category}{" "}
                        </Text>
                    </Badge>
                </Flex>
            </Flex>
        );
    });
};

const set_right_alignement = (i, len) => {
    switch (i) {
        case 0:
            return "start";
        case len - 1:
            return "end";

        default:
            return "center";
    }
};

const Stepper = ({ steps }) => {
    return (
        <Flex space="between">
            {steps?.map((el, i) => (
                <Flex
                    key={i}
                    column
                    hAlign={set_right_alignement(i, steps?.length)}
                    styles={{ isolation: "isolate" }}
                >
                    <StepperIcon
                        vAlign="center"
                        hAlign="center"
                        selected={el?.activated}
                        skipped={el?.skipped}
                    >
                        {el?.icon}
                    </StepperIcon>
                    <Flex column hAlign={set_right_alignement(i, steps.length)}>
                        <Substitle
                            content={el?.name}
                            selected={el?.activated}
                        />
                        <DateTitle
                            content={el?.date && moment(el?.date).format("L")}
                        />
                    </Flex>
                </Flex>
            ))}
        </Flex>
    );
};

const TaskTodoStyle = styled(Text)`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
    margin-left: 4px;
`;

const Container = styled(Flex)`
    /* padding: 24px; */
    width: 851px;
    max-height: 800px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    /* les margin a enlever a la fin  */
    padding: 24px;
`;

const SubTitle = styled(Text)`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* color: #242424; */
    margin-left: 8px;
    /* padding-top: 22px; */
`;

const MainTitle = styled(Text)`
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #242424;
    margin-bottom: 16px;
    margin-top: 24px;
`;

const StepperIcon = styled(Flex)`
    width: 32px;
    height: 32px;
    background: ${({ selected, skipped }) =>
        skipped ? "#E8EBFA" : selected ? "#5B5FC7" : "#F0F0F0"};
    border-radius: 4px;
    z-index: 2;
`;

const Substitle = styled(Text)`
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${({ selected }) => (selected ? "#5B5FC7" : "#242424")};
    font-weight: ${({ selected }) => (selected ? "600" : "400")};
`;
const DateTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #616161;
`;
const TasksTextStyles = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #252525;
`;

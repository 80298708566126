import React from "react";
import { Avatar, Button, Flex, TextArea } from "@fluentui/react-northstar";
import {
    Container,
    Row,
    RowLeft,
    RowRight,
    TextBold,
    TextDays,
    TextLight,
    TextMonth,
    TextName,
    TextTotal,
    Title,
} from "../style";
import moment from "moment";
import { STATUS } from "..";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../time_reporting/constants";

const Validate = ({
    data,
    setComment,
    comment,
    isLoadingMutate,
    status,
    setStatus,
    _validate_or_not_timesheet,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    return (
        <Container column fill>
            <Flex>
                <Title content={t("extern_manager_validation.title")} />
            </Flex>
            <Flex styles={{ marginTop: "48px" }} column gap="gap.medium">
                <Row fill>
                    <RowLeft column>
                        <TextBold
                            content={t(
                                "extern_manager_validation.validate.month_to_validate"
                            )}
                        />
                        <TextLight
                            content={t(
                                "extern_manager_validation.validate.month_to_validate_description"
                            )}
                        />
                    </RowLeft>
                    <RowRight vAlign="center" space="between">
                        <TextMonth
                            content={moment()
                                .set("month", data?.month_idx - 1)
                                .format("MMMM")}
                        />
                        <TextDays
                            content={
                                data?.days_count +
                                t("extern_manager_validation.validate.days")
                            }
                        />
                        <TextTotal content={data?.hours_count + " h"} />
                    </RowRight>
                </Row>
                <Row fill>
                    <RowLeft column>
                        <TextBold
                            content={t(
                                "extern_manager_validation.validate.user_concerned"
                            )}
                            styles={{
                                height: "44px",
                                width: "96px",
                            }}
                        />
                    </RowLeft>
                    <RowRight vAlign="center" space="between">
                        <Flex gap="gap.small" vAlign="center">
                            <Avatar
                                name={data?.user_name}
                                image={data?.user_avatar}
                                size="small"
                            />
                            <TextName content={data?.user_name} />
                        </Flex>
                    </RowRight>
                </Row>
                <Row fill>
                    <RowLeft column>
                        <TextBold
                            content={t(
                                "extern_manager_validation.validate.comment"
                            )}
                        />
                        <TextLight
                            content={t(
                                "extern_manager_validation.validate.not_mondatory"
                            )}
                        />
                    </RowLeft>
                    <RowRight vAlign="center" space="between">
                        <TextArea
                            fluid
                            placeholder={t(
                                "extern_manager_validation.validate.placeholder_explain_reject"
                            )}
                            variables={{
                                height: "92px",
                            }}
                            onChange={(_, i) => setComment(i?.value)}
                            defaultValue={comment}
                        />
                    </RowRight>
                </Row>
                <Flex
                    hAlign="center"
                    gap="gap.small"
                    styles={{ marginTop: "28px" }}
                >
                    <Button
                        flat
                        content={t(
                            "extern_manager_validation.validate.buttons.reject"
                        )}
                        variables={{ color: "#C4314B" }}
                        loading={isLoadingMutate && status === STATUS.reject}
                        onClick={() => {
                            setStatus(STATUS.reject);
                            _validate_or_not_timesheet(STATUS.reject);
                            return;
                        }}
                    />
                    <Button
                        primary
                        flat
                        loading={isLoadingMutate && status === STATUS.approve}
                        content={t(
                            "extern_manager_validation.validate.buttons.accept"
                        )}
                        onClick={() => {
                            setStatus(STATUS.approve);
                            _validate_or_not_timesheet(STATUS.approve);
                            return;
                        }}
                    />
                </Flex>
            </Flex>
        </Container>
    );
};

export default Validate;

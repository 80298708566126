import { BASE_URL_API } from "../../../constants";
import fetcher from "../../../utils/fetcher";

export const _preonboarding = async () => {
    // const { id: id_user } = useAuthDetailStore.getState().detail;
    return fetcher(`${BASE_URL_API}/preonboarding`, {
        method: "GET",
        credentials: "include",
    });
};

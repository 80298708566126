/* eslint-disable no-unreachable */
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    CloseIcon,
    Dialog,
    Flex,
    Table,
} from "@fluentui/react-northstar";

import { ClipboardTask20Regular } from "@fluentui/react-icons";

import { ALink, Ball, Number, TextPrimary } from "../styles";
import { CompoundButtonStyle, WrapperIcon } from "../styles/stepTasks";
import { ContentImportTemplates } from "./dialog";
import styled from "styled-components";
import {
    _api_patch_assign_task,
    _api_post_task_definition,
    _api_save_steps,
} from "../api";
import { useMutation } from "react-query";
import {
    f_resetStepperContext,
    f_set_templates,
    f_set_templates_last_updated,
    useAllSelectedTask,
    useGeneralInformationCache,
    useSetAllSelectedTask,
    useStepper,
} from "../stores";
import LoaderMan from "./loader";
import {
    getOutersectionTwoArrays,
    _add_task_to_selected_list,
    _format_tasks_definition_table,
} from "../utils";
import Toast from "../../../components/Alerts";
import DialogAddTask from "./dialogs/AddTask";
import { FormProvider, useForm } from "react-hook-form";
import { HEADER, I18N_NS_ADD_EMPLOYEE } from "../constants";
import { string } from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { DialogCancelConfirmation } from "../../../components/Dialogs/DialogCancelConfirmation";
import useMutationDeleteEmployee from "../hooks/useMutationDeleteEmployee";

const StepTasksAndModal = () => {
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    const navigate = useNavigate();
    const refSubmitButton = useRef(null);

    const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
    /** modal of select templates tasks */
    const [openModal, setOpenModal] = useState(false);
    const [openDialogAddTask, setOpenDialogAddTask] = useState(false);

    const [params] = useSearchParams();
    const is_refer_onboarding = params.get("refer") === "onboarding";
    const paramsString = Array.from(params.entries())
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

    const [toastConfig, setToastConfig] = useState({
        visible: false,
        content: "",
        level: "danger",
    });

    const context_step_informations = useStepper(
        (state) => state.step_personal_informations
    );
    const { isLoading: is_mutate_user, mutate: mutate_delete_user } =
        useMutationDeleteEmployee();

    const generalInformationCache = useGeneralInformationCache();
    const set_all_tasks = useSetAllSelectedTask(); // show the list of table tasks.
    const selected_tasks = useAllSelectedTask();

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            document_required: null,
            document_action: 5, // aucun document requis
            saved_files: [], // document uploaded from Dialog add task
            type: 1, // type is the value of the slidertabs on dialog add task
        },
    });

    /**
     * QUERY
     */
    const {
        data: taskDefinition,
        isLoading,
        mutate: mutateTaskDefinition,
    } = useMutation(
        () => {
            //* 1.0 compare between the two data, then send the identifiers of the selected new tasks.
            //* 1.1	extract the tasks selected on the temporary tasks
            //* 1.2 compare between the two datas (temp & already selected). then extract simply the new tasks selected!
            //* 2.0 the temporary templates Becomes permanently templates.
            //* 3.0 reset permanently tasks to empty Array.
            const templates = useStepper.getState().templates;
            const templates_selected = useStepper.getState().all_selected_task;

            //todo: [1.1]
            const extracted_tasks_from_template = templates
                ?.map((el) => el.tasks)
                .flat()
                .filter((el) => el.checked === true)
                .map((el) => el?.id);

            //todo: [1.2]
            const compared_tasks_selected_ids = templates_selected?.map(
                (el) => el?.id
            );
            const new_tasks_selected = getOutersectionTwoArrays(
                extracted_tasks_from_template,
                compared_tasks_selected_ids
            );
            // format the data to send
            const ids = new_tasks_selected;
            return _api_post_task_definition({
                ids,
            });
        },
        {
            onSuccess: (data = []) => {
                //todo: [3.0]
                //todo: save the tasks on templates and reset the updated_templates keys context.
                // save the data on local, because logically its all new tasks :D
                const f_all_tasks = useStepper.getState().all_selected_task;
                const templates = useStepper.getState().templates;
                set_all_tasks([...f_all_tasks, ...data]);
                f_set_templates_last_updated(templates);
            },
            onMutate: () => {
                setOpenModal(false);
            },
            onError: (error) => {
                setToastConfig((state) => {
                    return {
                        ...state,
                        visible: true,
                        content: error.message,
                    };
                });
            },
        }
    );
    /**
     * query save the tasks
     */
    const { mutate: create_employee, isLoading: isLoadingAddEmployee } =
        useMutation(
            "validate_creation_employee",
            (data) => {
                return _api_save_steps(data);
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        // Remove the Stepper Context.
                        f_resetStepperContext();
                        navigate(
                            is_refer_onboarding
                                ? ROUTES.onboarding.home
                                : "/dashboard?action=ADDED_EMPLOYEE"
                        );
                    }
                },
            }
        );
    /**
     * query save the tasks
     */
    const { mutate: save_tasks, isLoading: isLoadingTasks } = useMutation(
        "validate_step_tasks",
        (data) => {
            return _api_patch_assign_task(data);
        },
        {
            onSuccess: (data) => {
                if (data?.success) {
                    create_employee({
                        id_user: data?.user?.id,
                        email: data?.user?.email,
                    });
                } else {
                    setToastConfig((state) => {
                        return {
                            ...state,
                            visible: true,
                            level: "danger",
                            content:
                                "une erreur est survenue lors de la creation !",
                        };
                    });
                }
            },
        }
    );

    /**
     * FUNCTIONS
     */
    const onSubmit = async (data) => {
        //* verify if the task is document_required and the user doesnt upload a required document.
        if (data?.document_required && data?.saved_files.length === 0) {
            methods.setError("document_action", {
                type: string,
                message:
                    "Le document est obligatoire. Merci d'ajouter le document associé à cette action.",
            });
            return;
        }

        // todo: add the new task to context "all_selected_task".
        _add_task_to_selected_list(data, set_all_tasks);
        closeAddTaskResetForm();
    };

    const closeAddTaskResetForm = () => {
        methods.reset(); // reset all form task
        setOpenDialogAddTask(false);
    };

    const _closeModal = () => {
        // * assign values "last update template" key to "templates" key
        const last_templates_updated =
            useStepper.getState().templates_last_updated;
        f_set_templates(last_templates_updated);
        setOpenModal(false);
    };

    const _handleSubmit = () => {
        const f_all_tasks = useStepper.getState().all_selected_task;
        console.log({ f_all_tasks });
        // const general_infos_cache = useStepper.getState().general_infos_cache;
        const { email, id_user } =
            useStepper.getState().step_personal_informations;
        // format the data for sending it.
        const formated_data = f_all_tasks?.map((el) => {
            const warning_required_doc = [2, 3, 4].includes(
                parseInt(el?.document_action)
            );
            return {
                name: el?.name,
                description: el?.description,
                due_date: el?.due_date,
                category_id: el?.category_id || el?.category,
                assigned_to:
                    el?.assigned_to_user?.email || el?.assigned_to?.email,
                related_to:
                    el?.related_to?.email || el?.related_to?.header || email,
                document_required: el?.document_required || false,
                template_doc_ids:
                    el?.documents_template?.map((el) => el.id) || [],
                new_doc_ids: el?.saved_files?.map((el) => el?.id) || [],
                type: el?.type,
                task_to_validate: el?.task_to_validate ?? false,
                validator_email:
                    el?.who_validate?.email || el?.validator?.email || "",
                document_action:
                    el?.document_action === 5 ? 0 : el?.document_action,
                warning_required_doc:
                    (warning_required_doc && el?.saved_files?.length === 0) ||
                    (warning_required_doc &&
                        el?.documents_template?.length === 0),
            };
        });

        // verify if one task is document_required and don't uploaded document.
        const filter = formated_data.filter(
            (el) => el?.warning_required_doc === true
        );

        if (filter.length > 0) {
            return setToastConfig((state) => {
                return {
                    ...state,
                    visible: true,
                    level: "danger",
                    content: "Une/Plusieurs Tâche(s) requiert un document !",
                };
            });
        }
        save_tasks({
            id_user,
            email,
            tasks: formated_data,
        });
    };

    const list_table = _format_tasks_definition_table(selected_tasks);

    return (
        <Flex column>
            {/* Toast errors */}
            <Toast
                {...toastConfig}
                onStatusChange={() => {
                    setToastConfig((state) => {
                        return {
                            visible: false,
                            level: "danger",
                            content: "",
                        };
                    });
                    return;
                }}
            />
            {/* add loader dialog here */}
            <Dialog open={isLoading} content={<LoaderMan />} />
            {/* header text */}
            <Flex vAlign="center" gap="gap.small">
                <Ball
                    active={"true"}
                    already={"true"}
                    vAlign="center"
                    hAlign="center"
                >
                    <Number active={"true"} already={"true"}>
                        5
                    </Number>
                </Ball>
                <TextPrimary>
                    {t("pages.add_employee.step_tasks.title")}
                </TextPrimary>
            </Flex>
            {/* CTA buttons */}
            <Flex styles={{ marginTop: "24px" }} gap="gap.large">
                <Flex.Item>
                    <Dialog
                        open={openModal}
                        cancelButton={t("common.cancel")}
                        confirmButton={{
                            content: t(
                                "pages.add_employee.step_tasks.dialog.btn_add_task"
                            ),
                        }}
                        content={<ContentImportTemplates />}
                        header={t("pages.add_employee.step_tasks.dialog.title")}
                        headerAction={{
                            icon: <CloseIcon />,
                            title: t("common.close"),
                            onClick: _closeModal,
                        }}
                        onCancel={_closeModal}
                        onConfirm={() => mutateTaskDefinition()}
                        trigger={
                            <CompoundButtonStyle
                                icon={
                                    <WrapperIcon
                                        vAlign="center"
                                        hAlign="center"
                                    >
                                        <ClipboardTask20Regular
                                            style={{
                                                color: "var(--color-primary)",
                                            }}
                                        />
                                    </WrapperIcon>
                                }
                                secondaryContent={t(
                                    "pages.add_employee.step_tasks.buttons.templates.descr"
                                )}
                                onClick={() => setOpenModal(true)}
                            >
                                {t(
                                    "pages.add_employee.step_tasks.buttons.templates.header"
                                )}
                            </CompoundButtonStyle>
                        }
                    />
                </Flex.Item>
                {/* ADD TASK. */}
                <Flex vAlign="center">
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Dialog
                                header={"Ajouter une tâche"}
                                headerAction={
                                    <CloseIcon
                                        onClick={() =>
                                            setOpenDialogAddTask(false)
                                        }
                                    />
                                }
                                onCancel={() => setOpenDialogAddTask(false)}
                                cancelButton="Annuler"
                                confirmButton={{
                                    content: "Ajouter la tâche",
                                }}
                                onConfirm={() => {
                                    return refSubmitButton.current.click();
                                }}
                                content={
                                    <DialogAddTask
                                        generatedId={new Date().getTime()}
                                    />
                                }
                                open={openDialogAddTask}
                                trigger={
                                    <ALink
                                        href="#"
                                        onClick={() =>
                                            setOpenDialogAddTask(true)
                                        }
                                    >
                                        {t(
                                            "pages.add_employee.step_tasks.buttons.new_task.header"
                                        )}
                                    </ALink>
                                }
                                styles={{
                                    maxHeight: "90%",
                                    width: "700px",
                                }}
                            />
                            {/* this input is used for submitting form */}
                            <input
                                type="submit"
                                value="submit"
                                hidden
                                ref={refSubmitButton}
                            />
                        </form>
                    </FormProvider>
                </Flex>
            </Flex>
            <br />
            {/* List of the tasks selected */}
            {list_table?.length > 0 ? (
                <Flex
                    column
                    gap="gap.medium"
                    fill
                    styles={{ marginTop: "15px" }}
                >
                    <Flex>
                        <TextPrimary>
                            {t("pages.add_employee.step_tasks.lists.title")}
                        </TextPrimary>
                    </Flex>
                    <Flex fill>
                        <TableStyle
                            styles={{ width: "100%" }}
                            header={HEADER}
                            rows={list_table.reverse()}
                            aria-label="table of tasks"
                        />
                    </Flex>
                </Flex>
            ) : null}
            <Flex
                fill
                space="between"
                styles={{
                    marginTop: "40px",
                }}
            >
                <Flex>
                    <DialogCancelConfirmation
                        open={openDialogConfirmation}
                        cancelButton={{
                            content: "Annuler",
                            flat: true,
                        }}
                        confirmButton={{
                            content: "Annuler l’ajout d’un collaborateur",
                            flat: true,
                            isLoading: is_mutate_user,
                            styles: {
                                backgroundColor: "var(--color-danger)",
                            },
                        }}
                        content={
                            "Vous allez perdre toutes les données saisies sur l’ajout du collaborateur."
                        }
                        trigger={
                            <Button
                                content={{
                                    content: "Annuler l'ajout",
                                    styles: {
                                        color: "var(--color-danger)",
                                        textDecoration: "underline",
                                    },
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setOpenDialogConfirmation(true);
                                }}
                                text
                            />
                        }
                        header="Êtes-vous sûr(e) d'annuler l’ajout d’un collaborateur ?"
                        onClose={() => setOpenDialogConfirmation(false)}
                        onConfirm={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            // reset the context and other data.
                            const id_user = context_step_informations?.id_user;
                            if (id_user) {
                                mutate_delete_user(
                                    {
                                        id_user:
                                            context_step_informations?.id_user,
                                    },
                                    {
                                        onSuccess: (data) => {
                                            if (data?.success) {
                                                f_resetStepperContext();
                                                setOpenDialogConfirmation(
                                                    false
                                                );
                                                navigate(
                                                    is_refer_onboarding
                                                        ? ROUTES.onboarding.home
                                                        : ROUTES.home
                                                );
                                                return;
                                            } else {
                                                console.log(
                                                    "Une erreur est survenue !"
                                                );
                                            }
                                        },
                                        onError: (err) => {
                                            return console.log({ err });
                                        },
                                    }
                                );
                            } else {
                                setOpenDialogConfirmation(false);
                                f_resetStepperContext();
                                navigate(
                                    is_refer_onboarding
                                        ? ROUTES.onboarding.home
                                        : ROUTES.home
                                );
                            }
                            return;
                        }}
                    />
                </Flex>
                <Flex gap="gap.small">
                    <Button
                        content="Revenir à l'étape précédente"
                        onClick={() =>
                            navigate(
                                `${ROUTES.employee_add}/fourth${
                                    paramsString ? "?" + paramsString : ""
                                }`
                            )
                        }
                        secondary
                        flat
                    />
                    <Button
                        content="Ajouter un collaborateur"
                        onClick={() => _handleSubmit()}
                        loading={isLoadingAddEmployee || isLoadingTasks}
                        primary
                        flat
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default StepTasksAndModal;

const TableStyle = styled(Table)`
    [role="columnheader"] > .ui-table__cell__content {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 31px;
        color: #616161;
    }
    [role="cell"] > .ui-table__cell__content {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 48px;
        color: #252525;
    }
    [role="row"] > :last-child {
        justify-content: flex-end;
        align-items: flex-end;
        flex: 0.1;
        button {
            justify-content: flex-end;
        }
    }
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
`;

import React from "react";
import {
    Button,
    CalendarIcon,
    EditIcon,
    ExpandIcon,
    Flex,
    Image,
    InfoIcon,
    MenuButton,
    MoreIcon,
    Text,
    Tooltip,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import {
    Calculator20Regular,
    VehicleCar20Regular,
    Directions20Filled,
    CalendarChat24Regular,
    PanelRightContractFilled,
} from "@fluentui/react-icons";

import { ICON_FROM_EXTENSION, str_ellipsis } from "../../../../utils/functions";
import {
    I18N_NS_EXPENSE,
    TYPE_ENERGY,
    TYPE_FORM,
    TYPE_VEHICULE,
} from "../../constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { _api_delete_expense } from "../../api";
import { useParams } from "react-router-dom";
import moment from "moment";
import { ColumnName } from "../ExpensesRows";
import { currency_code_to_symbols } from "../../../../constants/maths";
import BadgeCounterCommentaire from "../Badges/BadgeCounterCommentaire";
import TheadRowsExpenses from "./TheadRowsExpenses/TheadRowsExpenses";
import RowExpense from "./RowExpense/RowExpense";
import { useWhereIamExpenses } from "../../store/useWhereIam.store";
import { useMediaQuery } from "react-responsive";
import { SIZES } from "../../../../constants/responsive";

const ColumnTva = ({
    tva,
    currency,
    type_form,
    distance,
    bareme = null,
    vehicle_type,
    vehicle_power_type,
}) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    const isKmForm =
        type_form === TYPE_FORM.km_ch || type_form === TYPE_FORM.km_fr;

    return (
        <>
            {isKmForm ? (
                <Tooltip
                    content={
                        <Flex column>
                            <Text
                                content={t(
                                    TYPE_VEHICULE.find(
                                        (el) => el.value === vehicle_type
                                    )?.label
                                )}
                            />
                            <Text
                                content={t(
                                    TYPE_ENERGY.find(
                                        (el) => el.value === vehicle_power_type
                                    )?.label
                                )}
                            />
                        </Flex>
                    }
                    pointing
                    subtle={false}
                    trigger={<VehicleCar20Regular />}
                    position="below"
                />
            ) : (
                <Calculator20Regular />
            )}
            <Tva>
                {isKmForm
                    ? distance
                    : currency_code_to_symbols({
                          amount: tva?.declared_amount,
                          currency,
                      })}
                {isKmForm ? "km" : ""}
            </Tva>
            <Divider>/</Divider>
            <Tva>
                {isKmForm
                    ? bareme
                    : `${tva?.value === -1 ? "Autre" : tva?.value + "%"}`}
            </Tva>
        </>
    );
};

const ColumnIcon = ({
    document,
    icon_size,
    type_form,
    departure,
    arrival,
    onClick = () => console.log("clicked"),
}) => {
    if (
        (type_form === TYPE_FORM.km_fr || type_form === TYPE_FORM.km_ch) &&
        departure !== "" &&
        arrival !== ""
    ) {
        return (
            <Tooltip
                content={`${departure} - ${arrival}`}
                trigger={<Directions20Filled style={{ cursor: "pointer" }} />}
                subtle={false}
                pointing
            />
        );
    }
    if (!document) return;
    return (
        <div
            // onClick={() => window.open(document?.attached_doc, "_blank")}
            onClick={() => onClick(document?.attached_doc)}
            style={{ cursor: "pointer" }}
        >
            {ICON_FROM_EXTENSION(document?.name, icon_size)}
        </div>
    );
};

export const MoreItem = ({
    key,
    id_expense,
    disabled = false,
    refetch_get_all_expenses,
    onClickUpdate,
    hide_delete_button = false,
}) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const [open, setOpen] = useState(false);

    const { id_expense: id_expense_report } = useParams();

    const { isLoading, mutate } = useMutation(
        (data) => _api_delete_expense(data),
        {
            onSuccess: (data) => {
                if (data?.success) {
                    setOpen(false);
                    refetch_get_all_expenses();
                }
                return;
            },
        }
    );

    const menuList = [
        <Button
            key={"update"}
            text
            icon={<EditIcon />}
            title="update"
            content={t("report_detail.update_expense")}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClickUpdate();
                return;
            }}
        />,
    ];

    if (!hide_delete_button) {
        menuList.push(
            <Button
                key={"remove"}
                text
                icon={<TrashCanIcon />}
                title="remove"
                content={t("report_detail.remove_expense")}
                styles={{ color: "red" }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    mutate({
                        id_expense,
                        id_expense_report,
                    });
                    return;
                }}
                loading={isLoading}
            />
        );
    }

    return (
        <MenuButton
            key={key}
            open={open}
            onOpenChange={(e, { open }) => {
                e.stopPropagation();
                setOpen(open);
            }}
            trigger={
                <Button
                    disabled={disabled}
                    text
                    icon={<MoreIcon />}
                    title="Menu"
                    iconOnly
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setOpen(true);
                    }}
                />
            }
            menu={menuList}
        />
    );
};

const RowsExpenseDetail = ({
    id_report,
    note_status,
    expenses,
    refetch_get_all_expenses,
    can_i_delete,
    id_expense_of_selected_comment = null,
    selectedExpense = null,
    is_title_clickable = false,
    is_employee_page = false,
    is_manager_page = false,
    is_accounting_manager = false,
    onClickTitle = () => console.log("clicked title"),
    on_click_comment = () => {
        console.log("Default click icon ..");
    },
    on_click_view_document = () => {
        return console.log("clicked on view document");
    },
}) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    const handleClickCommentButton = (e, i) => {
        e.stopPropagation();
        e.preventDefault();
        const { data_expense } = i;
        on_click_comment(data_expense);
        on_click_view_document(data_expense?.documents.at(0)?.attached_doc);
        return;
    };

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileM})`,
    });

    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    const isMobileL = useMediaQuery({
        query: `(max-width: ${SIZES.tablet})`,
    });
    const isMobile = isMobileS || isMobileM || isMobileL;

    if (is_employee_page) {
        return (
            <Flex fill column>
                <TheadRowsExpenses t={t} isMobile={isMobile} />

                {expenses?.map((el, index) => {
                    const count_messages = el?.history?.length;
                    const attached_document = el?.documents.at(0)?.attached_doc;
                    return (
                        <RowExpense
                            isMobile={isMobile}
                            el={el}
                            index={index}
                            note_status={note_status}
                            id_report={id_report}
                            is_title_clickable={is_title_clickable}
                            count_messages={count_messages}
                            attached_document={attached_document}
                            can_i_delete={can_i_delete}
                            disableMoreItem={note_status !== 0}
                            refetch_get_all_expenses={refetch_get_all_expenses}
                            onClickTitle={onClickTitle}
                            handleClickCommentButton={handleClickCommentButton}
                            selected={selectedExpense === el?.id}
                        />
                    );
                })}
            </Flex>
        );
    }
    if (is_manager_page) {
        // manager section
        return (
            <Flex fill column>
                <TheadRowsExpenses t={t} isMobile={isMobile} />

                {expenses?.map((el, index) => {
                    const count_messages = el?.history?.length;
                    const attached_document = el?.documents.at(0)?.attached_doc;

                    return (
                        <RowExpense
                            key={index}
                            el={el}
                            index={index}
                            note_status={note_status}
                            id_report={id_report}
                            is_title_clickable={is_title_clickable}
                            count_messages={count_messages}
                            attached_document={attached_document}
                            disableMoreItem={note_status !== 2}
                            handleClickCommentButton={handleClickCommentButton}
                            refetch_get_all_expenses={refetch_get_all_expenses}
                            can_i_delete={can_i_delete}
                            onClickTitle={onClickTitle}
                            isMobile={isMobile}
                        />
                    );
                })}
            </Flex>
        );
    }
    if (is_accounting_manager) {
        // accounting manager section
        return (
            <Flex fill column>
                <TheadRowsExpenses isMobile={isMobile} t={t} />
                {expenses?.map((el, index) => {
                    const count_messages = el?.history?.length;
                    const attached_document = el?.documents.at(0)?.attached_doc;

                    return (
                        <RowExpense
                            el={el}
                            index={index}
                            note_status={note_status}
                            id_report={id_report}
                            is_title_clickable={is_title_clickable}
                            count_messages={count_messages}
                            attached_document={attached_document}
                            disableMoreItem={note_status !== 3}
                            handleClickCommentButton={handleClickCommentButton}
                            refetch_get_all_expenses={refetch_get_all_expenses}
                            can_i_delete={can_i_delete}
                            onClickTitle={onClickTitle}
                        />
                    );
                })}
            </Flex>
        );
    }
};

export default RowsExpenseDetail;

const PanelRightContractFilledStyled = styled(PanelRightContractFilled)`
    min-width: 25px;
    min-height: 25px;
`;

const ButtonComment = styled(Button)`
    background-color: ${({ active }) => (active ? "#ebebeb" : "transparent")};
    min-width: 20px !important;
`;

const TextTotal = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #242424;
`;

const Divider = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    color: #ebebeb;
`;

const Tva = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #242424;
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    cursor: ${({ isCursorPointer }) => (isCursorPointer ? "pointer" : "auto")};

    color: #5b5fc7;
`;

export const Date = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #424242;
`;
const RowWrapper = styled(Flex)`
    border-bottom: 1px solid #d1d1d1;
    padding-block: 8px;
    /* border-left: 2px solid #ffb900;
    padding-left: 8px; */
`;

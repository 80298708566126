import React, { useEffect, useState } from "react";
import { Container, ContainerFluid } from "../../../../../common/styles";
import {
    Avatar,
    CloseIcon,
    Dialog,
    Flex,
    Skeleton,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import { Textbox24Filled } from "@fluentui/react-icons";
import styled from "styled-components/macro";

import { useForm, FormProvider } from "react-hook-form";
import {
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from "react-query";
import { toast as toastSonner } from "sonner";

import {
    _adjustement_employee_solde,
    _api_get_list_history_ajustement,
    _get_Adjusted_solde,
    _get_adjustement_user,
    _get_types_leave,
    _get_types_leave_for_filter,
} from "../../../api";
import ContentAjustementDialog from "./ContentAjustementDialog";
import {
    _getAllUsers,
    _get_all_employees_and_departments,
} from "../../../../../api/user";

import Toast from "../../../../../components/Alerts";
import { str_ellipsis } from "../../../../../utils/functions";
import { useLocation, useSearchParams } from "react-router-dom";
import { DEVICE } from "../../../../../constants/responsive";
import Pagination from "../../../../../components/Pagination/Pagination";
import DialogFilterAjustment from "../FilterHistoryAjustment/DialogFilterAjustment";
import moment from "moment";
import { AVATAR_DEPARTMENT_DEFAULT } from "../../../../../constants";
import SkeletonListAjustments from "../SkeletonListAjustments/SkeletonLeaveList";

const SoldeAjustement = ({ openDialog, setOpenDialog }) => {
    const [params] = useSearchParams();
    const user = params.get("user");

    const methods = useForm();

    const [searchParams, setSearchParams] = useSearchParams({
        employee: [],
        type: [],
        checkboxes: [],
        start_date: "",
        end_date: "",
        refer: "",
    });

    const methodsAjustment = useForm({
        defaultValues: {
            checkboxes: searchParams.getAll("a") ?? [],
            start_date: searchParams.get("start_date")
                ? new Date(searchParams.get("start_date"))
                : null,
            end_date: searchParams.get("end_date")
                ? new Date(searchParams.get("end_date"))
                : null,
            employee: [],
            type: searchParams.getAll("t") ?? [],
            // page: 1,
        },
    });

    const [toastConfig, setToastConfig] = useState({
        content: "",
        visible: false,
        level: "warning",
    });

    const {
        data: SoldeToEdit,
        isLoading: isLoadingSoldeToEdit,
        isFetching: isFetchingSoldeToEdit,
    } = useQuery(
        ["adjustable_leave_type", methods.watch("id_user")],
        ({ queryKey }) => {
            return _get_adjustement_user({
                id_user: queryKey?.at(1),
            });
        },
        {
            enabled: methods.watch("id_user") !== undefined,
        }
    );

    const { data: employees, isLoading: is_loading_employees } = useQuery(
        ["leave::get_list_employee"],
        () => {
            return _get_all_employees_and_departments();
        },
        {
            onSuccess: (data) => {
                const ud = searchParams.getAll("ud");
                const selected_employee_params = data?.filter((d) =>
                    ud?.includes(d?.id)
                );
                methodsAjustment.setValue(
                    "employee",
                    selected_employee_params?.map((element) => ({
                        id: element?.id,
                        header: element?.name,
                        image: element?.avatar ?? AVATAR_DEPARTMENT_DEFAULT,
                        content: element.job_title,
                    }))
                );
            },
        }
    );

    const { data: leave_types, isLoading: is_loading_types } = useQuery(
        ["leave::get_list_types_leave"],
        () => {
            return _get_types_leave_for_filter();
        },
        {
            onSuccess: (data) => {
                const t = searchParams.getAll("t");
                const selected_type = data?.filter((d) =>
                    t?.includes(d?.id + "")
                );
                methodsAjustment.setValue(
                    "type",
                    selected_type?.map((element) => ({
                        id: element?.id,
                        header: element?.name,
                        color: element?.color,
                    }))
                );
            },
        }
    );

    const {
        data: allUsers,
        isLoading: Loading_all_users,
        isFetching: Fetching_all_users,
    } = useQuery(["getAllUsers"], () => _getAllUsers(), {
        refetchOnWindowFocus: false,
    });

    const onSubmit = (data) => {
        return mutate_adjustement_solde(data);
    };

    const {
        data,
        fetchNextPage,
        isLoading,
        isFetching,
        isFetchingNextPage,
        refetch,
        error,
        hasNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: [
            "leave::getListRows",
            {
                refer: searchParams.get("refer"),
                params_string: searchParams.toString(),
            },
        ],
        queryFn: (state) => {
            return _api_get_list_history_ajustement({
                page: state?.pageParam,
                ...state.queryKey.at(1),
            });
        },
        onSuccess: () => {
            toastSonner.dismiss();
            return;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.next_page_value;
        },
    });

    const {
        mutate: mutate_adjustement_solde,
        isLoading: isLoadingMutateAdjustementSolde,
    } = useMutation(
        (data) => {
            // API function
            return _adjustement_employee_solde({ data });
        },
        {
            onSuccess: (data) => {
                if (data.success) {
                    setToastConfig((state) => {
                        return {
                            ...state,
                            visible: true,
                            content: `Solde ajusté avec succès`,
                            level: "success",
                        };
                    });
                    methods.reset();
                    toastSonner.loading("Mise à jour ...");
                    refetch();
                    // show sonner toast here
                    // fetchNextPage();
                    // queryClient.refetchQueries(["leave::getListRows"]);
                    setOpenDialog(false);
                    return;
                } else {
                    setToastConfig((state) => {
                        return {
                            ...state,
                            visible: true,
                            content: `L'utilisateur n'a pas de politique de congé!`,
                            level: "danger",
                        };
                    });
                    return setOpenDialog(false);
                }
            },
            onError: (error) => console.log("the error is" + error),
        }
    );

    const onSubmitAjustmentFilter = (data) => {
        const new_params = {};

        if (data?.start_date)
            new_params.start_date = moment(data?.start_date).format(
                "YYYY-MM-DD"
            );
        if (data?.end_date)
            new_params.end_date = moment(data?.end_date).format("YYYY-MM-DD");

        setSearchParams({
            ...new_params,
            ud: data?.employee?.map((u) => u.id),
            a: data?.checkboxes?.map((cb) => cb),
            t: data?.type?.map((t) => t.id),
        });

        return;
    };

    const last_page = data?.pages[data?.pages?.length - 1];
    const is_more_rows = last_page?.next_page_value;
    const currentPageToShow = last_page?.next_page_value
        ? (last_page?.next_page_value - 1) * 10
        : data?.pages[0]?.all_histories_count;
    const loading_adjustement_data =
        isLoadingSoldeToEdit || isFetchingSoldeToEdit;

    const no_politic_leave = SoldeToEdit?.error_message ? true : false;
    const no_politic_leave_message = SoldeToEdit?.error_message
        ? SoldeToEdit?.error_message
        : "";

    // const userData = data?.pages[0]?.histories?.find(
    //     (history) => history?.user?.id === user
    // )?.user;
    // console.log({ userData });

    // console.log({ user3232: user, employee: data?.pages?.at(0)?.histories });
    // const histories = data?.pages?.at(0)?.histories;
    // const filteredHistories = histories.filter(
    //     (entry) => entry.user.id === user
    // );
    // console.log({ filteredHistories, histories, user });
    return (
        <ContainerFluid fill>
            <Container fill column>
                <Toast
                    visible={toastConfig?.visible}
                    content={toastConfig?.content}
                    level={toastConfig?.level}
                    onStatusChange={() => {
                        return setToastConfig({
                            visible: false,
                            content: "",
                            level: "success",
                        });
                    }}
                />
                <Content column fill>
                    {/* Ajuster un solde  */}
                    <Header fill space="between" vAlign="center">
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <DialogStyle
                                    open={openDialog}
                                    onCancel={() => {
                                        methods.reset();
                                        setOpenDialog(false);
                                    }}
                                    cancelButton={{ content: "Annuler" }}
                                    confirmButton={{
                                        content: "Procéder à l’ajustement",
                                        loading:
                                            isLoadingMutateAdjustementSolde,
                                        disabled: no_politic_leave,
                                    }}
                                    onConfirm={methods.handleSubmit(onSubmit)}
                                    content={
                                        <ContentAjustementDialog
                                            SoldeToEdit={
                                                SoldeToEdit?.success === false
                                                    ? []
                                                    : SoldeToEdit
                                            }
                                            allUsers={allUsers}
                                            no_politic_leave={no_politic_leave}
                                            no_politic_leave_message={
                                                no_politic_leave_message
                                            }
                                            is_loading_adjust_solde={
                                                isLoadingSoldeToEdit ||
                                                isFetchingSoldeToEdit
                                            }
                                        />
                                    }
                                    header="Ajuster un solde de congés"
                                    headerAction={{
                                        icon: <CloseIcon />,
                                        title: "Close",
                                        onClick: () => setOpenDialog(false),
                                    }}
                                />
                            </form>
                        </FormProvider>
                    </Header>
                    {/* filter la liste des ajustements */}
                    <Flex vAlign="center" gap="gap.medium">
                        <TextTitle content={"Historique des ajustements"} />
                        <FormProvider {...methodsAjustment}>
                            <form
                                onSubmit={methodsAjustment.handleSubmit(
                                    onSubmitAjustmentFilter
                                )}
                            >
                                <DialogFilterAjustment
                                    openDialog={openDialog}
                                    setOpenDialog={setOpenDialog}
                                    onSubmitAjustmentFilter={
                                        onSubmitAjustmentFilter
                                    }
                                    employees={employees}
                                    is_loading_employees={is_loading_employees}
                                    leave_types={leave_types}
                                    is_loading_types={is_loading_types}
                                    refetch={refetch}
                                    filterData={true}
                                    exportData={false}
                                />
                            </form>
                        </FormProvider>
                    </Flex>
                    <Table column>
                        <TableHead space="between" vAlign="center">
                            <TheadDate content="Date" />
                            <Thead__Collaborator
                                content="Collaborateur"
                                styles={{
                                    width: "200px",
                                }}
                            />
                            <TheadAction
                                truncated
                                content="Opération effectuée"
                            />
                            <FlexSolde vAlign="center" space="evenly">
                                <TheadSolde truncated content="Solde ajusté" />
                                <Badge content={"Durée"} hide_J={true} />
                            </FlexSolde>
                            <TheadIcon
                                content=""
                                styles={{
                                    width: "40px",
                                    color: "#f5f5f5 !important",
                                }}
                            />
                        </TableHead>
                        {isLoading && <SkeletonListAjustments />}
                        <TableContent column>
                            {data?.pages?.map((pages, index) => {
                                return pages?.histories?.map((el, i) => {
                                    return (
                                        <Flex key={i} column>
                                            <Row
                                                space="between"
                                                vAlign="center"
                                            >
                                                <FlexCellDates>
                                                    {loading_adjustement_data ? (
                                                        <Skeleton animation="wave">
                                                            <Skeleton.Line
                                                                width="100px"
                                                                height="24px"
                                                            />
                                                        </Skeleton>
                                                    ) : (
                                                        <Flex vAlign="center">
                                                            <AvatarMobile
                                                                image={
                                                                    el?.user
                                                                        ?.avatar
                                                                }
                                                                name={
                                                                    el?.user
                                                                        ?.name
                                                                }
                                                                size="smallest"
                                                            />
                                                            <TextStyle
                                                                content={
                                                                    el?.date
                                                                }
                                                            />
                                                        </Flex>
                                                    )}
                                                </FlexCellDates>
                                                {/* avatar + name of the collaborateur */}
                                                <FlexCellEmployee>
                                                    {loading_adjustement_data ? (
                                                        <Skeleton
                                                            animation="wave"
                                                            styles={{
                                                                width: "200px",
                                                            }}
                                                        >
                                                            <Flex
                                                                vAlign="center"
                                                                gap="gap.small"
                                                            >
                                                                <Skeleton.Shape
                                                                    round
                                                                    width="32px"
                                                                    height="32px"
                                                                />

                                                                <Skeleton.Line
                                                                    width="100px"
                                                                    height="24px"
                                                                />
                                                            </Flex>
                                                        </Skeleton>
                                                    ) : (
                                                        <Flex
                                                            gap="gap.small"
                                                            vAlign="center"
                                                        >
                                                            <Avatar
                                                                image={
                                                                    el?.user
                                                                        ?.avatar
                                                                }
                                                                name={
                                                                    el?.user
                                                                        ?.name
                                                                }
                                                            />
                                                            <TextStyle
                                                                content={str_ellipsis(
                                                                    {
                                                                        length: 15,
                                                                        str: el
                                                                            ?.user
                                                                            ?.name,
                                                                    }
                                                                )}
                                                            />
                                                        </Flex>
                                                    )}
                                                </FlexCellEmployee>
                                                <FlexCellAction>
                                                    {loading_adjustement_data ? (
                                                        <Skeleton animation="wave">
                                                            <Skeleton.Line
                                                                width="100px"
                                                                height="24px"
                                                            />
                                                        </Skeleton>
                                                    ) : (
                                                        <TextStyle
                                                            content={el?.action}
                                                            truncated
                                                        />
                                                    )}
                                                </FlexCellAction>
                                                <FlexCellType
                                                    styles={{
                                                        width: "200px",
                                                    }}
                                                >
                                                    {loading_adjustement_data ? (
                                                        <Skeleton animation="wave">
                                                            <Skeleton.Line
                                                                width="100px"
                                                                height="24px"
                                                            />
                                                        </Skeleton>
                                                    ) : (
                                                        <Flex
                                                            gap="gap.small"
                                                            vAlign="center"
                                                            space="between"
                                                            fill
                                                        >
                                                            <TextStyle
                                                                truncated
                                                                content={
                                                                    el?.leave_type
                                                                }
                                                            />

                                                            <Badge
                                                                content={
                                                                    el?.change_value
                                                                }
                                                            />
                                                        </Flex>
                                                    )}
                                                </FlexCellType>
                                                <FlexCellComment
                                                    hAlign="center"
                                                    styles={{
                                                        width: "40px",
                                                    }}
                                                >
                                                    {el?.comment && (
                                                        <Tooltip
                                                            position="before"
                                                            align="start"
                                                            pointing
                                                            subtle={false}
                                                            unstable_pinned
                                                            content={
                                                                <Text
                                                                    content={
                                                                        el?.comment
                                                                    }
                                                                />
                                                            }
                                                            trigger={
                                                                <TriggerFlex>
                                                                    <Textbox24Filled color="var(--color-primary)" />
                                                                </TriggerFlex>
                                                            }
                                                        />
                                                    )}
                                                </FlexCellComment>
                                            </Row>
                                        </Flex>
                                    );
                                });
                            })}
                        </TableContent>
                    </Table>
                    <Pagination
                        buttonContentSeeMore={"Afficher plus de demandes"}
                        countMessage={"demandes affichées sur"}
                        currentCountRows={currentPageToShow}
                        isMore={is_more_rows}
                        isLoading={isLoading}
                        totalRows={data?.pages?.at(0).all_histories_count}
                        isFetchingNextPage={isFetchingNextPage}
                        funcFetchNextPage={() => fetchNextPage()}
                    />
                </Content>
            </Container>
        </ContainerFluid>
    );
};

const Badge = ({ content, hide_J }) => {
    return (
        <WrapperBadge>
            {hide_J ? (
                <TextContent content={content} />
            ) : (
                <TextContent content={`${content}j`} />
            )}
        </WrapperBadge>
    );
};

export default SoldeAjustement;

export const TitleFlex = styled(Flex)`
    /* padding-left: 24px; */

    @media ${DEVICE.mobileS} {
        padding-top: 16px;

        margin-bottom: 0px !important;
        justify-content: center;
    }
    @media ${DEVICE.tablet} {
        padding-top: 0px;
    }
    @media ${DEVICE.laptop} {
        justify-content: flex-start;
    }
`;

export const MobileText = styled(Text)`
    color: #242424;
    white-space: nowrap;
    /* Large/700 */
    /* font-family: "Segoe UI"; */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    /* padding-top: 24px; */
`;

export const TextTitle = styled(Text)`
    color: #242424;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    @media ${DEVICE.mobileS} {
        /* padding: 24px 8px; */
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
    }
`;

export const FlexCellComment = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: flex;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

const AvatarMobile = styled(Avatar)`
    @media ${DEVICE.mobileS} {
        display: flex;
        margin-right: 2px;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
`;

const FlexSolde = styled(Flex)`
    gap: 8px;
    @media ${DEVICE.mobileS} {
        width: 100%;
        max-width: 135px;
    }
    @media ${DEVICE.tablet} {
        width: 190px;
        max-width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: 190px;
        max-width: 100%;
    }
`;

const TheadAction = styled(Text)`
    @media ${DEVICE.mobileS} {
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 65px;
        text-wrap: nowrap;
    }
    @media ${DEVICE.laptop} {
        width: 216px;
    }
`;

const TheadDate = styled(Text)`
    @media ${DEVICE.mobileS} {
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 65px;
    }
    @media ${DEVICE.laptop} {
        width: 92px;
    }
`;
const TheadSolde = styled(Text)`
    @media ${DEVICE.mobileS} {
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 65px;
    }
    @media ${DEVICE.laptop} {
        width: 92px;
    }
`;
const TextContent = styled(Text)`
    color: white;
    @media ${DEVICE.mobileS} {
        font-size: 10px;
    }
    @media ${DEVICE.laptop} {
        font-size: 13px;
    }
`;
const WrapperBadge = styled(Flex)`
    border-radius: 4px;
    background: rgba(41, 40, 40, 0.9);
    @media ${DEVICE.mobileS} {
        padding: 2px 6px;
    }
    @media ${DEVICE.laptop} {
        padding: 4px 8px;
    }
`;

const DialogStyle = styled(Dialog)`
    @media ${DEVICE.mobileS} {
        width: 90%;
        .ui-dialog__footer .ui-buttons .ui-flex {
            flex-direction: column-reverse;
            gap: 8px;
        }
    }
    @media ${DEVICE.laptop} {
        width: 600px;
        .ui-dialog__footer .ui-buttons .ui-flex {
            flex-direction: row;
        }
    }
`;

const TextStyle = styled(Text)`
    color: #424242;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    @media ${DEVICE.mobileS} {
        font-size: 12px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    @media ${DEVICE.laptop} {
        font-size: 14px;
        width: fit-content;
    }
`;

export const FlexCellAction = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 100%;
        max-width: 135px;
    }
    @media ${DEVICE.tablet} {
        width: 216px;
        max-width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: 216px;
        max-width: 100%;
    }
`;

export const FlexCellType = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 100%;
        max-width: 130px;
        padding-right: 5px;
    }
    @media ${DEVICE.tablet} {
        width: 190px;
        max-width: 100%;
        padding-right: 0px;
    }
    @media ${DEVICE.laptop} {
        width: 190px;
        max-width: 100%;
        padding-right: 0px;
    }
`;

export const FlexCellDates = styled(Flex)`
    padding-left: 5px;
    @media ${DEVICE.mobileS} {
        width: fit-content;
        ${TextStyle} {
            font-size: 12px !important;
        }
    }
    @media ${DEVICE.laptop} {
        width: 92px;
        ${TextStyle} {
            width: 100px;
            font-size: 14px;
        }
    }
`;
export const FlexCellEmployee = styled(Flex)`
    width: 200px;
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

const TriggerFlex = styled(Flex)`
    &:hover {
        color: #5b5fc7; /* Couleur au survol (rouge) */
    }
`;

const Content = styled(Flex)`
    background: #fff;
    border-top: 4px solid #9299f7;
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
    @media ${DEVICE.mobileS} {
        padding: 16px 0px;
    }
    @media ${DEVICE.tablet} {
        padding: 24px 8px;
    }
`;

const Header = styled(Flex)`
    padding-inline: 6px;
`;

const TableHead = styled(Flex)`
    /* background-color: #f5f5f5; */
    border-bottom: 1px solid #f5f5f5;
    @media ${DEVICE.mobileS} {
        padding: 5px;
    }
    @media ${DEVICE.laptop} {
        padding: 16px;
    }
`;

const Table = styled(Flex)`
    gap: 8px;
`;
const TableContent = styled(Flex)`
    min-height: 50vh;
    /* overflow-y: scroll; */
`;
const Thead = styled(Text)`
    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;
const TheadIcon = styled(Thead)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: flex;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;
const Thead__Collaborator = styled(Thead)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

export const Row = styled(Flex)`
    padding-block: 10px;
    border-bottom: 1px solid #d1d1d1;
    cursor: pointer;
    &:hover {
        background-color: whitesmoke;
    }
    @media ${DEVICE.mobileS} {
        gap: 3px;
    }
    @media ${DEVICE.laptop} {
    }
`;

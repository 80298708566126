import { CloseIcon, Flex, Loader } from "@fluentui/react-northstar";
import React from "react";
import Category from "./Category";
import useLogicCategoryExpense from "./useLogicCategoryExpense";
import { ListCategories } from "./index.style";

/**
 * @typedef {Object} PropsType
 * @property {boolean} isLoading - is loading categories or not
 * @property {Array.<{ form_type: string, id : number, icon_url : string, name : string }>} categories - categories array come from useLogic
 * @property {{ id : number, update_category : function }} selected_category - selected_category => come from useLogic
 *
 * @param {PropsType} props
 * @returns
 */
const Categories = ({ isLoading, categories = [], selected_category }) => {
    console.log({ selected_category });
    return (
        <ListCategories column>
            {/* LOADING */}
            {isLoading && (
                <Loader
                    label={"Chargement des catégories"}
                    size="small"
                    labelPosition="before"
                />
            )}
            {/* affichage de toutes les catégories */}
            {categories
                .filter((f_cat) => f_cat.form_type === "classic")
                .map((cat) => {
                    return (
                        <Category
                            id={cat.id}
                            icon={cat.icon_url}
                            name={cat.name}
                            $selected={selected_category.id === cat.id}
                            cbChooseCategory={selected_category.update_category}
                        />
                    );
                })}
        </ListCategories>
    );
};

export default Categories;

import { Button, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../constants/responsive";

export const Footer = styled(Flex)`
    margin-top: 9px;
    @media ${DEVICE.mobileS} {
        padding-left: 15px;
    }
    @media ${DEVICE.tablet} {
        padding-left: 0px;
    }
`;
export const FooterCountRowsShowed = styled(Text)`
    color: #616161;

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
`;
export const ButtonShowMoreRows = styled(Button)`
    background-color: #f0f0f0;
`;

import {
    Dropdown,
    Flex,
    Input,
    Loader,
    SearchIcon,
    Text,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getAllUser } from "../api";
import { debounce } from "../../../utils/global";
import { DEBOUNCE_DELAY } from "../../../pages/Annuaire/constant";
import { AVATAR_DEFAULT, BASE_URL_API } from "../../../constants";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";

const SearchBarFromDashboard = () => {
    const navigate = useNavigate();

    const [users, setUsers] = useState("");
    const [items, setItems] = useState([]);
    const [refresh, setRefresh] = useState(null);

    const {
        data: all_users,
        isLoading,
        isFetching,
    } = useQuery(
        ["getAllUser", users],
        () => {
            return getAllUser(users);
        },
        {
            enabled: users !== "",
            refetchOnWindowFocus: false,
            onSuccess: () => {
                return setRefresh(new Date().getTime());
            },
        }
    );

    const handleChange = debounce((e) => {
        if (e?.target?.value?.length <= 2) {
            return;
        } else {
            setUsers(e.target.value);
        }
        return;
    }, DEBOUNCE_DELAY);

    useEffect(() => {
        const data = all_users?.map((el) => {
            return {
                header: el?.first_name + " " + el?.last_name,
                image: el?.avatar || AVATAR_DEFAULT,
                content: el?.job_title || "/",
                // detail: el,
                id: el?.id,
            };
        });
        setItems(data ?? []);
    }, [refresh]);

    return (
        <Flex>
            <DropdownStyled
                fluid
                search
                items={items}
                placeholder="Rechercher des personnes"
                loading={isLoading || isFetching}
                noResultsMessage={
                    all_users ? (
                        "Aucun résultat trouvé."
                    ) : (
                        <Text content="Chargement..." />
                    )
                }
                itemToString={(e) => {
                    if (e) {
                        return e?.header + " " + e?.content;
                    } else {
                        return "";
                    }
                }}
                a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                inverted
                clearable
                toggleIndicator={<SearchIcon />}
                onChangeCapture={(e) => handleChange(e)}
                // onChange={() => navigate(`/employee/${all_users[0].id}`)}
                onChange={(e, data) => {
                    if (data?.value?.id) {
                        navigate(`/employee/${data.value.id}`);
                    }
                }}
            />
        </Flex>
    );
};

export default SearchBarFromDashboard;

const DropdownStyled = styled(Dropdown)`
    @media ${DEVICE.mobileS} {
        width: 120px;
    }
    @media ${DEVICE.tablet} {
        min-width: 350px;
    }
    @media ${DEVICE.laptop} {
        min-width: 624px;
    }
`;

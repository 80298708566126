import React from "react";
import { AddIcon, Flex, Text } from "@fluentui/react-northstar";
import { ClipboardTask24Regular } from "@fluentui/react-icons";
import styled, { css } from "styled-components/macro";
import { CompoundButtonStyle } from "../../../features/addEmployee/styles/stepTasks";
import { WrapperIcon } from "../../../common/styles";
import { _api_get_model } from "../../../features/addEmployee/api";
import { useQuery } from "react-query";
import { DEVICE } from "../../../constants/responsive";

const DialogChooseAttributionTask = ({ cb }) => {
    const {
        data: models,
        isLoading,
        isFetching,
    } = useQuery(["TASKS::getModelsList"], () => {
        return _api_get_model();
    });

    return (
        <Flex column gap="gap.medium">
            <Flex>
                <TextDescription
                    content={
                        "Comment voulez attribuer une tâche ou plusieurs tâches"
                    }
                />
            </Flex>
            <WrapperCompoundButton gap="gap.small">
                <CompoundButton
                    icon={
                        <WrapperIcon vAlign="center" hAlign="center">
                            <ClipboardTask24Regular
                                style={{
                                    color: "var(--color-primary)",
                                }}
                            />
                        </WrapperIcon>
                    }
                    secondaryContent={"Choisir dans un modèle existant"}
                    onClick={() => cb("USE_MODAL")}
                    disabled={isLoading || isFetching ? true : !models?.length}
                    disabledFocusable={
                        isLoading || isFetching ? true : !models?.length
                    }
                >
                    <Text content="Depuis un modèle" weight="bold" />
                </CompoundButton>
                <CompoundButton
                    icon={
                        <WrapperIcon vAlign="center" hAlign="center">
                            <AddIcon
                                style={{
                                    color: "var(--color-primary)",
                                }}
                            />
                        </WrapperIcon>
                    }
                    secondaryContent={"Créer une tâche depuis zéro"}
                    onClick={() => cb("CREATE_TASK_FROM_ZERO")}
                >
                    <Text content="Nouvelle tâche" weight="bold" />
                </CompoundButton>
            </WrapperCompoundButton>
        </Flex>
    );
};

export default DialogChooseAttributionTask;

const CompoundButton = styled(CompoundButtonStyle)`
    display: flex;

    padding: 12px 16px;
    align-items: flex-start;
    gap: 10px;

    border-radius: 4px;
    border: 1px solid #d1d1d1;
    background: #f0f0f0;

    /* disable state css */
    ${({ disabled }) =>
        disabled
            ? css`
                  background-color: #f0f0f0;
                  border-color: #bdbdbd !important;
                  opacity: 0.5;
                  outline: 0 !important;
                  :hover,
                  :focus,
                  :active {
                      border-color: #bdbdbd;
                  }
              `
            : css``}

    @media ${DEVICE.mobileS} {
        max-width: 100%;
        min-width: 100px;
        margin-right: 0px !important;
    }
    @media ${DEVICE.laptop} {
        max-width: 294px;
        min-width: 100px;
        margin-right: auto;
    }
`;

const WrapperCompoundButton = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 15px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const TextDescription = styled(Text)`
    color: #424242;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

import React from "react";
import { Flex } from "@fluentui/react-northstar";
import LabelView from "../../LabelView";
import CallMobile from "../../CallMobile";
import CopyText from "../../CopyText";

const ContactInformations = ({
    personal_phone_number,
    phone_number,
    linkedin,
}) => {
    return (
        <Flex fill column gap="gap.medium">
            <LabelView
                label="Téléphone personnel"
                content={
                    <CallMobile
                        content={personal_phone_number}
                        width="100%"
                        noBorder={true}
                    />
                }
            />
            <LabelView
                label="Téléphone professionnel"
                content={
                    <CallMobile
                        content={phone_number}
                        width="100%"
                        noBorder={true}
                    />
                }
            />
            <LabelView
                label="Compte LinkedIn"
                content={
                    <CopyText
                        content={linkedin}
                        width="100%"
                        noBorder={true}
                        noAlert
                    />
                }
            />
        </Flex>
    );
};

export default ContactInformations;

import {
    AddIcon,
    Box,
    Button,
    ChevronEndIcon,
    Dropdown,
    Flex,
    Input,
    InputLabel,
    Loader,
    MenuButton,
    Text,
} from "@fluentui/react-northstar";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { I18N_NS_ADD_EMPLOYEE } from "../constants";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SCHEMA, SCHEMA_SECOND_STEP } from "../constants/validators";
import { Ball, ErrorMessage, InputStyle, Number, TextPrimary } from "../styles";
import { useMutation, useQuery } from "react-query";
import { getAgencies, getManagers, getServices } from "../../../api/user";
import { getPolicies, getTypeContract } from "../../../api/helpers";
import { AVATAR_DEFAULT } from "../../../constants";
import WeekWorkingDefinition from "./WeekWorkingDefinition";
import { useReducer } from "react";
import {
    INIT_REDUCER_WEEK_DIFINITION,
    reducer_week_definition,
} from "../reducers/reducer.week_definition";
import styled from "styled-components";
import { useEffect } from "react";
import moment from "moment";
import { _api_save_step_contract } from "../api";
import { ROUTES } from "../../../constants/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    f_resetStepperContext,
    useSetStepContract,
    useStepper,
} from "../stores";
import { DialogCancelConfirmation } from "../../../components/Dialogs/DialogCancelConfirmation";
import useMutationDeleteEmployee from "../hooks/useMutationDeleteEmployee";

const StepContract = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const paramsString = Array.from(params.entries())
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);

    const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
    const context_step_informations = useStepper(
        (state) => state.step_personal_informations
    );
    const context_step_contract = useStepper((state) => state.step_contract);
    const context_set_contract = useSetStepContract();

    const [state, dispatch] = useReducer(
        reducer_week_definition,
        context_step_contract
            ? {
                  ...INIT_REDUCER_WEEK_DIFINITION,
                  planning: context_step_contract?.planning,
              }
            : INIT_REDUCER_WEEK_DIFINITION
    );

    const { isLoading: is_mutate_user, mutate: mutate_delete_user } =
        useMutationDeleteEmployee();

    const IS_UPDATE_USER_ONBOARDING =
        params.get("t") === "update_onboarding" && params.get("id") !== null;

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        control,
        watch,
        setValue,
        trigger,
        clearErrors,
        getValues,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(SCHEMA_SECOND_STEP),
        defaultValues: {
            poste: context_step_contract?.poste,
            managers: context_step_contract?.managers,
            agencies: context_step_contract?.agencies,
            services: context_step_contract?.services,
            policies: context_step_contract?.policies,
            type_contrat: context_step_contract?.type_contrat,
            date_arrivee: context_step_contract?.date_arrivee,
            date_depart: context_step_contract?.date_depart,
            trial_period: context_step_contract?.trial_period,
        },
    });

    useEffect(() => {
        if (IS_UPDATE_USER_ONBOARDING) {
            trigger();
        }
    }, [IS_UPDATE_USER_ONBOARDING]);

    // get type de contrats API.
    const {
        data: type_contrat,
        isLoading: isLoadingTypeContrat,
        isFetching: isFetchingTypeContrat,
    } = useQuery(["getTypeContrat"], () => getTypeContract(), {
        refetchOnWindowFocus: false,
    });
    // get service API.
    const {
        data: services,
        isLoading: isLoadingServices,
        isFetching: isFetchingServices,
    } = useQuery(["getServices"], () => getServices(), {
        refetchOnWindowFocus: false,
    });
    // get agencies API.
    const {
        data: agencies,
        isLoading: isLoadingAgencies,
        isFetching: isFetchingAgencies,
    } = useQuery(["getAgencies"], () => getAgencies(), {
        refetchOnWindowFocus: false,
    });
    // get manager API.
    const {
        data: managers,
        isLoading: isLoadingManagers,
        isFetching: isFetchingManagers,
    } = useQuery(["getManagers"], () => getManagers(), {
        refetchOnWindowFocus: false,
    });

    // get politique API.
    const {
        data: policies,
        isLoading: isLoadingPolicies,
        isFetching: isFetchingPolicies,
    } = useQuery(
        ["getPoliciesByAgencies", watch("agencies")?.id],
        () =>
            getPolicies({
                agency_id: watch("agencies")?.id,
            }),
        {
            refetchOnWindowFocus: false,
            enabled: watch("agencies")?.id !== undefined,
        }
    );

    const { mutate: mutate_save_contract, isLoading: is_saving_contract } =
        useMutation((data) => _api_save_step_contract(data));

    const watchAll = useWatch({
        control,
        name: ["date_arrivee", "managers"],
    });
    // ***** calculate the end_date from the type contract
    const probation_period = type_contrat?.find(
        (type_contrat) => type_contrat?.id === getValues("type_contrat")?.id
    )?.probation_period;

    useEffect(() => {
        let default_end_date = null;
        const value_start_date = watchAll.date_arrivee;
        if (probation_period && value_start_date !== undefined) {
            default_end_date = moment(
                watchAll.date_arrivee !== ""
                    ? watchAll.date_arrivee
                    : new Date()
            )
                .add("month", probation_period)
                .format("YYYY-MM-DD");

            setValue("trial_period", default_end_date);
            trigger("trial_period");
        } else {
            const current_trial_period = getValues("trial_period");
            setValue(
                "trial_period",
                current_trial_period ? current_trial_period : default_end_date
            );
        }
        clearErrors("trial_period");
    }, [probation_period, watchAll.date_arrivee]);
    // ***** END calculate the end_date from the type contract

    const onSubmit = (values) => {
        const timetable = state?.planning?.map((day) => ({
            am: day?.slots?.morning,
            pm: day?.slots?.afternoon,
        }));

        mutate_save_contract(
            {
                job_title: values?.poste,
                agency_id: values?.agencies?.id,
                service_id: values?.services?.id,
                manager_email: values?.managers?.email,
                employement_contract_id: values?.type_contrat?.id,
                arrival_date:
                    values?.date_arrivee !== null
                        ? moment(values?.date_arrivee).format("YYYY-MM-DD")
                        : null,
                departure_date:
                    values?.date_depart !== null
                        ? moment(values?.date_depart).format("YYYY-MM-DD")
                        : null,
                probationary_period_end_date:
                    values?.trial_period !== null
                        ? moment(values?.trial_period).format("YYYY-MM-DD")
                        : null,
                leave_policy_id: values?.policies?.id,
                timetable,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        context_set_contract({
                            ...values,
                            planning: state?.planning,
                        });
                        return navigate(
                            `${ROUTES.employee_add}/third${
                                paramsString ? "?" + paramsString : ""
                            }`
                        );
                    }
                    return;
                },
            }
        );
        return;
    };

    const MANAGERS = managers?.map((el) => {
        return {
            key: el?.email,
            header: `${el?.first_name} ${el?.last_name}`,
            image: el?.avatar || AVATAR_DEFAULT,
            content: "",
            email: el.email,
            selected: el?.email === watchAll?.managers?.email,
        };
    });

    const AGENCIES = agencies?.map((el) => ({
        id: el.id,
        content: el.name,
        selected: watchAll?.agencies?.id === el?.id,
    }));

    return (
        <>
            <Flex vAlign="center" gap="gap.small">
                <Ball
                    active={"true"}
                    already={"true"}
                    vAlign="center"
                    hAlign="center"
                >
                    <Number active={"true"} already={"true"}>
                        2
                    </Number>
                </Ball>
                <TextPrimary>
                    {t("pages.add_employee.steps.contract.title")}
                </TextPrimary>
                <Text styles={{ color: "red" }}>*</Text>
            </Flex>
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ maxWidth: "720px" }}
            >
                <Flex
                    fill
                    gap="gap.medium"
                    styles={{ marginTop: "21px" }}
                    column
                >
                    {/* poste */}
                    <Box>
                        <InputStyle
                            {...register("poste")}
                            label={t(
                                "pages.add_employee.step_info.form.labels.poste"
                            )}
                            type="text"
                            name="poste"
                            error={errors?.poste ? true : false}
                            placeholder={"Designation du poste"}
                            inverted
                            fluid
                            defaultValue={context_step_contract?.poste}
                        />
                        {errors?.poste && (
                            <ErrorMessage content={t(errors.poste.message)} />
                        )}
                    </Box>

                    <Flex fill gap="gap.medium">
                        {/* agency */}
                        <Flex fill column>
                            <InputLabel
                                content={t(
                                    "pages.add_employee.step_info.form.labels.agency"
                                )}
                            />
                            {isLoadingAgencies || isFetchingAgencies ? (
                                <Flex gap="gap.medium" hAlign="start">
                                    <Loader
                                        label={t(
                                            "pages.add_employee.step_info.loader.messages.agency"
                                        )}
                                        labelPosition="end"
                                        size="smallest"
                                    />
                                </Flex>
                            ) : (
                                <>
                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value },
                                        }) => {
                                            console.log({ value2: value });
                                            return (
                                                <Dropdown
                                                    items={AGENCIES}
                                                    onChange={(_, i) =>
                                                        onChange(i.value)
                                                    }
                                                    placeholder={
                                                        "Choisir dans la liste"
                                                    }
                                                    onBlur={onBlur}
                                                    getA11ySelectionMessage={{
                                                        onAdd: (item) =>
                                                            `${item} has been selected.`,
                                                    }}
                                                    error={
                                                        errors?.agencies
                                                            ? true
                                                            : false
                                                    }
                                                    value={value?.content}
                                                    defaultValue={value}
                                                    inverted
                                                    checkable
                                                    fluid
                                                />
                                            );
                                        }}
                                        name="agencies"
                                    />
                                    {errors?.agencies && (
                                        <ErrorMessage
                                            content={t(errors.agencies.message)}
                                        />
                                    )}
                                </>
                            )}
                        </Flex>
                        {/* service */}
                        <Flex fill column>
                            <InputLabel
                                content={t(
                                    "pages.add_employee.step_info.form.labels.service"
                                )}
                            />
                            {isLoadingServices || isFetchingServices ? (
                                <Flex gap="gap.medium" hAlign="start">
                                    <Loader
                                        label={t(
                                            "pages.add_employee.step_info.loader.messages.services"
                                        )}
                                        labelPosition="end"
                                        size="smallest"
                                    />
                                </Flex>
                            ) : (
                                <>
                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value },
                                        }) => (
                                            <Dropdown
                                                items={services.map((el) => ({
                                                    id: el.id,
                                                    content: el.name,
                                                    selected:
                                                        value?.id === el?.id,
                                                }))}
                                                placeholder={
                                                    "Choisir dans la liste"
                                                }
                                                inverted
                                                checkable
                                                onChange={(_, i) =>
                                                    onChange(i.value)
                                                }
                                                fluid
                                                onBlur={onBlur}
                                                getA11ySelectionMessage={{
                                                    onAdd: (item) =>
                                                        `${item} has been selected.`,
                                                }}
                                                error={
                                                    errors?.services
                                                        ? true
                                                        : false
                                                }
                                                value={value?.content}
                                                defaultValue={value}
                                            />
                                        )}
                                        name="services"
                                    />
                                    {errors?.services && (
                                        <ErrorMessage
                                            content={t(errors.services.message)}
                                        />
                                    )}
                                </>
                            )}
                        </Flex>
                    </Flex>
                    <Flex fill gap="gap.medium">
                        {/* manager */}
                        <Flex fill column>
                            <InputLabel
                                content={t(
                                    "pages.add_employee.step_info.form.labels.manager"
                                )}
                            />
                            {isLoadingManagers || isFetchingManagers ? (
                                <Flex gap="gap.medium" hAlign="start">
                                    <Loader
                                        label={t(
                                            "pages.add_employee.step_info.loader.messages.managers"
                                        )}
                                        labelPosition="end"
                                        size="smallest"
                                    />
                                </Flex>
                            ) : (
                                <>
                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value },
                                        }) => {
                                            return (
                                                <Dropdown
                                                    search
                                                    fluid
                                                    items={MANAGERS}
                                                    placeholder={
                                                        "Choisir dans la liste"
                                                    }
                                                    inverted
                                                    checkable
                                                    onChange={(_, i) =>
                                                        onChange(i.value)
                                                    }
                                                    onBlur={onBlur}
                                                    getA11ySelectionMessage={{
                                                        onAdd: (item) =>
                                                            `${item} has been selected.`,
                                                    }}
                                                    value={value?.header}
                                                    error={
                                                        errors?.managers
                                                            ? true
                                                            : false
                                                    }
                                                    defaultValue={value}
                                                />
                                            );
                                        }}
                                        name="managers"
                                    />
                                    {errors?.managers && (
                                        <ErrorMessage
                                            content={t(errors.managers.message)}
                                        />
                                    )}
                                </>
                            )}
                        </Flex>
                        {/* type de contrat */}
                        <Flex fill column>
                            <InputLabel
                                content={t(
                                    "pages.add_employee.step_info.form.labels.type_de_contrat"
                                )}
                            />
                            {isLoadingTypeContrat || isFetchingTypeContrat ? (
                                <Flex gap="gap.medium" hAlign="start">
                                    <Loader
                                        label={t(
                                            "pages.add_employee.step_info.loader.messages.type_contrat"
                                        )}
                                        labelPosition="end"
                                        size="smallest"
                                    />
                                </Flex>
                            ) : (
                                <>
                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value },
                                        }) => {
                                            console.log({ value });
                                            return (
                                                <Dropdown
                                                    items={type_contrat?.map(
                                                        (el) => ({
                                                            id: el?.id,
                                                            content: el.name,
                                                            selected:
                                                                el?.id ===
                                                                value?.id,
                                                        })
                                                    )}
                                                    inverted
                                                    checkable
                                                    placeholder={
                                                        "Choisir dans la liste"
                                                    }
                                                    onChange={(e, i) => {
                                                        return onChange(
                                                            i.value
                                                        );
                                                    }}
                                                    onBlur={onBlur}
                                                    getA11ySelectionMessage={{
                                                        onAdd: (item) =>
                                                            `${item} has been selected.`,
                                                    }}
                                                    error={
                                                        errors?.type_contrat
                                                            ? true
                                                            : false
                                                    }
                                                    fluid
                                                    value={value?.content}
                                                    defaultValue={value}
                                                />
                                            );
                                        }}
                                        name="type_contrat"
                                    />
                                    {errors?.type_contrat && (
                                        <ErrorMessage
                                            content={t(
                                                errors.type_contrat.message
                                            )}
                                        />
                                    )}
                                </>
                            )}
                        </Flex>
                    </Flex>
                    {/* dates */}
                    <Flex gap="gap.large" styles={{ maxWidth: "530px" }}>
                        <Box>
                            {/* date d'arrivée */}
                            <Controller
                                control={control}
                                render={({
                                    field: { onChange, onBlur, value, ...rest },
                                }) => {
                                    return (
                                        <Input
                                            label={t(
                                                "pages.add_employee.step_info.form.labels.date_arrivee"
                                            )}
                                            type="date"
                                            onChange={(e, i) => {
                                                return onChange(i?.value);
                                            }}
                                            value={
                                                value !== undefined
                                                    ? moment(value).format(
                                                          "YYYY-MM-DD"
                                                      )
                                                    : null
                                            }
                                            defaultValue={
                                                value !== undefined
                                                    ? moment(value).format(
                                                          "YYYY-MM-DD"
                                                      )
                                                    : null
                                            }
                                            inverted
                                            fluid
                                            error={
                                                errors?.date_arrivee
                                                    ? true
                                                    : false
                                            }
                                            errorIndicator={false}
                                        />
                                    );
                                }}
                                name="date_arrivee"
                            />

                            {errors?.date_arrivee && (
                                <ErrorMessage
                                    content={t(errors.date_arrivee.message)}
                                />
                            )}
                        </Box>
                        <Box>
                            {/* fin de période d'essai */}
                            <Controller
                                control={control}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => {
                                    console.log({
                                        qsdfqsdfqsdfqsdfqsdf: value,
                                    });
                                    return (
                                        <Input
                                            label={t(
                                                "pages.add_employee.step_info.form.labels.trial_period"
                                            )}
                                            onChange={(_, i) =>
                                                onChange(i?.value)
                                            }
                                            type="date"
                                            error={
                                                errors?.trial_period
                                                    ? true
                                                    : false
                                            }
                                            errorIndicator={false}
                                            value={moment(value).format(
                                                "YYYY-MM-DD"
                                            )}
                                            defaultValue={
                                                value !== undefined
                                                    ? moment(value).format(
                                                          "YYYY-MM-DD"
                                                      )
                                                    : null
                                            }
                                            inverted
                                            fluid
                                        />
                                    );
                                }}
                                name="trial_period"
                            />
                            {errors?.trial_period && (
                                <ErrorMessage
                                    content={t(errors.trial_period.message)}
                                />
                            )}
                        </Box>
                        <Box>
                            <Input
                                label={t(
                                    "pages.add_employee.step_info.form.labels.date_depart"
                                )}
                                type="date"
                                inverted
                                fluid
                                {...register("date_depart")}
                                error={errors?.date_depart ? true : false}
                                errorIndicator={false}
                                defaultValue={
                                    context_step_contract?.date_depart !==
                                    undefined
                                        ? moment(
                                              context_step_contract?.date_depart
                                          ).format("YYYY-MM-DD")
                                        : null
                                }
                            />
                            {errors?.date_depart && (
                                <ErrorMessage
                                    content={t(errors.date_depart.message)}
                                />
                            )}
                        </Box>
                    </Flex>
                    {/* politique d'absence */}
                    <Flex gap="gap.large" styles={{ maxWidth: "213px" }}>
                        {/* type de contrat */}
                        <Flex fill column>
                            <InputLabel
                                content={t(
                                    "pages.add_employee.step_info.form.labels.policies"
                                )}
                            />
                            {isLoadingPolicies || isFetchingPolicies ? (
                                <Flex gap="gap.medium" hAlign="start">
                                    <Loader
                                        label={t(
                                            "pages.add_employee.step_info.loader.messages.policies"
                                        )}
                                        labelPosition="end"
                                        size="smallest"
                                    />
                                </Flex>
                            ) : (
                                <>
                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value },
                                        }) => {
                                            console.log({ controller: value });
                                            return (
                                                <Dropdown
                                                    disabled={!policies?.length}
                                                    items={policies?.map(
                                                        (el) => ({
                                                            id: el.id,
                                                            content: el.name,
                                                            selected:
                                                                value?.id ===
                                                                el?.id,
                                                        })
                                                    )}
                                                    placeholder={
                                                        "Choisir dans la liste"
                                                    }
                                                    onChange={(e, i) => {
                                                        return onChange(
                                                            i.value
                                                        );
                                                    }}
                                                    onBlur={onBlur}
                                                    getA11ySelectionMessage={{
                                                        onAdd: (item) =>
                                                            `${item} has been selected.`,
                                                    }}
                                                    error={
                                                        errors?.policies
                                                            ? true
                                                            : false
                                                    }
                                                    value={value?.content}
                                                    defaultValue={value}
                                                    inverted
                                                    checkable
                                                    fluid
                                                />
                                            );
                                        }}
                                        name="policies"
                                    />
                                    {errors?.policies && (
                                        <ErrorMessage
                                            content={t(errors.policies.message)}
                                        />
                                    )}
                                </>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    fill
                    gap="gap.medium"
                    column
                    styles={{ marginTop: "40px" }}
                >
                    <TitleWeekWorking content={"Activité hebdomadaire"} />
                    <WeekWorkingDefinition state={state} dispatch={dispatch} />
                </Flex>
                <Flex
                    fill
                    space="between"
                    styles={{
                        marginTop: "40px",
                    }}
                >
                    <Flex>
                        {!IS_UPDATE_USER_ONBOARDING && (
                            <DialogCancelConfirmation
                                open={openDialogConfirmation}
                                cancelButton={{
                                    content: "Annuler",
                                    flat: true,
                                }}
                                confirmButton={{
                                    content:
                                        "Annuler l’ajout d’un collaborateur",
                                    flat: true,
                                    isLoading: is_mutate_user,
                                    styles: {
                                        backgroundColor: "var(--color-danger)",
                                    },
                                }}
                                content={
                                    "Vous allez perdre toutes les données saisies sur l’ajout du collaborateur."
                                }
                                trigger={
                                    <Button
                                        content={{
                                            content: "Annuler l'ajout",
                                            styles: {
                                                color: "var(--color-danger)",
                                                textDecoration: "underline",
                                            },
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setOpenDialogConfirmation(true);
                                        }}
                                        text
                                    />
                                }
                                header="Êtes-vous sûr(e) d'annuler l’ajout d’un collaborateur ?"
                                onClose={() => setOpenDialogConfirmation(false)}
                                onConfirm={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    // reset the context and other data.
                                    const id_user =
                                        context_step_informations?.id_user;
                                    if (id_user) {
                                        mutate_delete_user(
                                            {
                                                id_user:
                                                    context_step_informations?.id_user,
                                            },
                                            {
                                                onSuccess: (data) => {
                                                    if (data?.success) {
                                                        f_resetStepperContext();
                                                        setOpenDialogConfirmation(
                                                            false
                                                        );
                                                        navigate("/dashboard");
                                                        return;
                                                    } else {
                                                        console.log(
                                                            "Une erreur est survenue !"
                                                        );
                                                    }
                                                },
                                                onError: (err) => {
                                                    return console.log({ err });
                                                },
                                            }
                                        );
                                    } else {
                                        setOpenDialogConfirmation(false);
                                        f_resetStepperContext();
                                        navigate("/dashboard");
                                    }
                                    return;
                                }}
                            />
                        )}
                        {IS_UPDATE_USER_ONBOARDING && (
                            <Button
                                content={{
                                    content: "Retour à l'onboarding",
                                    styles: {
                                        color: "var(--color-danger)",
                                        textDecoration: "underline",
                                    },
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    navigate(ROUTES.onboarding.home);
                                }}
                                text
                            />
                        )}
                    </Flex>
                    <Flex gap="gap.small">
                        <Button
                            content="Revenir à l'étape précédente"
                            onClick={() =>
                                navigate(
                                    `${ROUTES.employee_add}/first${
                                        paramsString ? "?" + paramsString : ""
                                    }`
                                )
                            }
                            secondary
                            flat
                        />
                        <Button
                            content="Passer à l'étape suivante"
                            disabled={!isValid}
                            loading={is_saving_contract}
                            primary
                            flat
                        />
                    </Flex>
                </Flex>
            </form>
        </>
    );
};

export default StepContract;
const TitleWeekWorking = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;

import { Input } from "@fluentui/react-components";
import { Box, Flex, Text } from "@fluentui/react-northstar";

import React from "react";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";
import MuiPhoneNumber from "material-ui-phone-number";

const template = {
    marginTop: "5px",
    marginBottom: "8px",
    marginRight: "24px",
};

export default function ContactUrgence({
    index,
    number,
    bordercolor = "#09347a",
}) {
    const { register, control, errors, getValues } = useFormContext();

    return (
        <Flex column fill>
            <Text
                style={{
                    marginTop: "20px",
                    marginBottom: "16px",
                    color: "#09347A",
                }}
                weight="bold"
                content={
                    <p
                        style={{
                            color: bordercolor,
                        }}
                    >
                        Contact d'urgence {number}
                    </p>
                }
            />
            <FlexStyle column bordercolor={bordercolor}>
                <Label content={"Prénom & Nom"} />
                <Input
                    rule
                    type="text"
                    name={"emergency_contacts." + index + ".name"}
                    {...register("emergency_contacts." + index + ".name")}
                    defaultValue={getValues(
                        "emergency_contacts." + index + ".name"
                    )}
                    label="Prénom & Nom"
                    inverted
                    fluid
                    style={template}
                />

                <Label content={"Téléphone"} />
                <Controller
                    control={control}
                    name={"emergency_contacts." + index + ".phone_number"}
                    render={({ field: { ...fieldProps } }) => {
                        return (
                            <MuiPhoneNumberStyle
                                style={template}
                                {...fieldProps}
                                type="tel"
                                placeholder="Numéro de téléphone..."
                                autoFormat={true}
                                defaultCountry={"fr"}
                                disableAreaCodes={true}
                                onlyCountries={[
                                    "dz",
                                    "fr",
                                    "us",
                                    "ch",
                                    "de",
                                    "it",
                                    "ca",
                                    "lu",
                                    "mc",
                                    "nl",
                                    "no",
                                    "pl",
                                    "pt",
                                    "tr",
                                ]}
                            />
                        );
                    }}
                />
                <Label content={"Lien avec le contact d'urgence"} />
                <Input
                    style={template}
                    label="Lien avec le contact d'urgence"
                    inverted
                    fluid
                    type="text"
                    {...register(
                        "emergency_contacts." +
                            index +
                            ".relationship_with_user"
                    )}
                    name={
                        "emergency_contacts." +
                        index +
                        ".relationship_with_user"
                    }
                />
            </FlexStyle>
        </Flex>
    );
}

const FlexStyle = styled(Flex)`
    position: relative;
    padding-left: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: ${({ bordercolor }) => bordercolor};
        border-radius: 2px;
    }
`;

const Label = styled(Text)`
    font-size: 12px;
    font-style: normal;
    padding: 4px;
    color: #616161;
`;

const MuiPhoneNumberStyle = styled(MuiPhoneNumber)`
    background-color: white;
    border-radius: 4px;
    .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
        border-color: rgb(91, 95, 199) !important;
    }
    .MuiInput-underline {
        padding: 2px 8px !important;
    }
    .MuiInput-underline::before {
        border: 0px !important;
    }
`;

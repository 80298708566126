import { Box, Flex, Loader, Skeleton, Text } from "@fluentui/react-northstar";
import Illustration from "../../../components/shared/Illustration";
import styled from "styled-components";
import Overlay from "./Overlay";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { TimeSectionCard } from "../../new_dashboard/styles/responsive";
import { useAuthDetailStore } from "../../../stores/useAuthStore";
import { ROUTES } from "../../../constants/routes";
import { Doughnut } from "react-chartjs-2";
import TimeCardSkeleton from "./TimeCardSkeleton";

// Overlay if pré enboarding
const ActivityOverlay = () => {
    return (
        <Flex
            column
            gap="gap.medium"
            styles={{
                position: "absolute",
                inset: "0",
                opacity: "0.08",
            }}
        >
            <TitleTime vAlign="center" hAlign="center" gap="gap.small">
                <TitleText content={"Activité mensuelle"} />
            </TitleTime>

            <TimeContent
                vAlign="center"
                hAlign="center"
                styles={{ gap: "10px" }}
            >
                <Flex hAlign="start" gap="gap.smaller" column>
                    <Text as="p" color="brand" content="Jours déclarés" />
                    <Text as="h2" color="brand" content={0} />
                    <Text
                        styles={{ width: "100px" }}
                        as="p"
                        content={`sur ${0} au total`}
                    />
                </Flex>
                <Flex hAlign="center" gap="gap.small" column>
                    <Illustration
                        filename={
                            "dashboard/Temps de présence dashboard background.svg"
                        }
                        width={"100px"}
                        height={"auto"}
                    />
                </Flex>
            </TimeContent>

            <TimeAction vAlign="center" hAlign="center">
                <Text content="Accéder à ma feuille de temps > " />
            </TimeAction>
        </Flex>
    );
};

export const TimeCard = ({ monthly_activity, is_pre_onboarding }) => {
    const navigate = useNavigate();

    const clientQuery = useQueryClient();

    const detail = useAuthDetailStore((state) => state?.detail);

    const query_state = clientQuery.getQueryState("get_new_dashboard_data");
    const isLoading =
        query_state?.status === "idle" && query_state?.dataUpdateCount === 0;

    const not_activated_for_me =
        !detail?.features?.time_reporting?.activated_for_me;

    const declared_days = monthly_activity?.declared_day_count;
    const total_declared_days = monthly_activity?.day_count_total_to_declare;
    const total = (declared_days * 100) / total_declared_days;
    // const rest_to_percent = 100 - total;
    // const rest_declared_days = total_declared_days - declared_days;

    const missed_day_to_declare = total_declared_days - declared_days;
    return (
        <TimeSectionCard
            $not_activated={not_activated_for_me}
            onClick={() =>
                !not_activated_for_me
                    ? navigate(`${ROUTES.time_reporting.home}`)
                    : null
            }
        >
            {!is_pre_onboarding && (
                <Flex column gap="gap.medium">
                    <TitleTime vAlign="center" hAlign="center" gap="gap.small">
                        <TitleText content={"Activité mensuelle"} />
                    </TitleTime>

                    <TimeContent
                        vAlign="center"
                        hAlign="center"
                        styles={{ gap: "10px" }}
                    >
                        <Flex
                            hAlign="center"
                            gap="gap.smaller"
                            column
                            styles={{
                                maxWidth: "200px",
                                position: "absolute",
                                top: "8px",
                            }}
                        >
                            {isLoading ? (
                                <TimeCardSkeleton />
                            ) : (
                                <>
                                    <Doughnut
                                        data={{
                                            labels: ["Déclaré", "Restant"],
                                            datasets: [
                                                {
                                                    label: "",
                                                    data: [
                                                        declared_days,
                                                        missed_day_to_declare,
                                                    ],
                                                    backgroundColor: [
                                                        "#5B5FC7",
                                                        "#F0F0F0",
                                                    ],
                                                    borderColor: [
                                                        "#5B5FC7",
                                                        "#F0F0F0",
                                                    ],
                                                    borderWidth: 1,
                                                },
                                            ],
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: true,
                                            aspectRatio: "1",
                                            plugins: {
                                                legend: false,
                                            },
                                            circumference: 180,
                                            rotation: -90,
                                            cutout: "70%",
                                        }}
                                    />
                                    <Data
                                        hAlign="center"
                                        vAlign="center"
                                        column
                                    >
                                        <DataBold
                                            content={`${monthly_activity?.declared_day_count} j déclarés`}
                                        />
                                        <DataLight
                                            content={`sur ${monthly_activity?.day_count_total_to_declare}`}
                                        />
                                    </Data>
                                </>
                            )}
                        </Flex>
                    </TimeContent>

                    <TimeAction
                        vAlign="center"
                        hAlign="center"
                        onClick={() =>
                            navigate(`${ROUTES.time_reporting.home}`)
                        }
                    >
                        {isLoading ? (
                            <Skeleton
                                animation="pulse"
                                styles={{
                                    justifyContent: "center",
                                    display: "flex",
                                }}
                            >
                                <Skeleton.Line width="180px" height="18px" />
                            </Skeleton>
                        ) : (
                            <Text
                                className="link"
                                content="Accéder à ma feuille de temps > "
                            />
                        )}
                    </TimeAction>
                    {not_activated_for_me && (
                        <Overlay
                            content={"Pas de déclaration du temps demandée"}
                        />
                    )}
                </Flex>
            )}
        </TimeSectionCard>
    );
};
const DataLight = styled(Text)`
    color: #242424;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 5px;
`;
const DataBold = styled(Text)`
    color: var(--color-primary);

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;
export const Data = styled(Flex)`
    position: absolute;
    bottom: 55px;
`;
const TitleText = styled(Text)`
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #242424;
`;

const TimeAction = styled(Flex)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0067b8;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 0px;
    right: 0px;
`;

const TimeContent = styled(Flex)`
    /* padding-left: 24px; */
`;

const TitleTime = styled(Flex)`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* text-transform: uppercase; */
    color: #242424;
    margin: 20px 0px 10px 0px;
`;

const TimeMainContent = styled(Box)`
    width: 316px;
    height: 200px;
`;

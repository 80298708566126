import styled from "styled-components";
// import ButtonPrimary from "../../components/buttons/ButtonPrimary";
/**
 * BUTTON MICROSOFT
 */
export const MSLogoStyle = styled.img`
    width: 20px;
`;

export const MSButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 12px;
    gap: 12px;
    background-color: white;
    font-weight: 600;
    color: #5e5e5e;
    border: 1px solid #8c8c8c;
    cursor: pointer;
    display: flex;
    width: 100%;
    transition: all 0.3s;
    text-decoration: none;
    margin-top: 15px;
    border-radius: 5px;
    span {
        font-size: 16px;
    }
    :hover {
        opacity: 0.7;
        transition: all 0.3s;
    }
`;

/**
 * STYLE OF CONNECT MS FILE.
 */
export const InformationalText = styled.span`
    color: grey;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 15px;
`;

/**
 * container for VIEW EMAIL CONNECTED ON LOGIN STEPS
 */
export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

/**
 * BACK ARROW ON LOGIN STEPS.
 */
export const BackArrow = styled.div`
    position: absolute;
    left: 40px;
    top: 60px;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s;
    &:hover {
        opacity: 1;
        transition: all 0.3s;
    }
`;
export const WrapperClose = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    opacity: 0.5;
    transition: all 0.3s;
    svg {
        cursor: pointer;
        opacity: 1;
        transition: all 0.3s;
    }
`;

export const Mail = styled.span`
    display: flex;
    flex: 1;
    font-size: 14px;
`;

export const WrapperMail = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    width: 100%;
    background-color: #ededed;
    border: 1px solid rgb(96, 94, 92);
    border-radius: 5px;
`;

export const WrapperLeft = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    gap: 8px;
    justify-content: flex-end;
    padding-right: 8px;
`;

/**
 * INIT PAS LOGIN STYLE
 */

// export const ButtonPrimaryStyle = styled(ButtonPrimary)`
//     width: 100%;
//     margin-top: 20px;
// `;

export const Hr = styled.hr`
    background-color: lightgray;
    width: 100%;
    border: 0px;
    border-top: 1px solid gray;
`;

export const Divider = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 16px;
    margin-top: 20px !important;
    span {
        color: lightgray;
        font-size: 18px;
    }
`;

export const Form = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
`;

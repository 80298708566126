import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../time_reporting/constants";
import { Container, ErrorDescription, ErrorTitle } from "../style";

import Error400 from "../../../assets/illustrations/time_reporting/400-error-bad-request.svg";

const ValidateError = () => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    return (
        <Container vAlign="center" hAlign="center" column gap="gap.small">
            <Image
                src={Error400}
                alt=""
                style={{ height: "250px", marginBottom: "30px" }}
            />
            <ErrorTitle content={t("extern_manager_validation.error.title")} />
            <ErrorDescription
                content={
                    <Flex column gap="gap.medium">
                        <Text
                            content={t(
                                "extern_manager_validation.error.first_description"
                            )}
                        />
                        <Text
                            content={t(
                                "extern_manager_validation.error.second_description"
                            )}
                        />
                    </Flex>
                }
            />
        </Container>
    );
};

export default ValidateError;

import React, { useState } from "react";
import { Flex, Image } from "@fluentui/react-northstar";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
    _api_approve_or_reject_timesheet,
    _api_externe_validation_leave,
} from "./api";

import ValidateLoader from "./components/ValidateLoader";
import ValidateSuccess from "./components/ValidateSuccess";
import ValidateError from "./components/ValidateError";
import Validate from "./components/Validate";

import ClockPng from "../../assets/illustrations/clock_leave_request.svg";
import { ImageClock, Wrapper } from "./style";

export const STATUS = {
    approve: "APPROVE",
    reject: "REJECT",
};

const ManagerValidationLeaveRequest = () => {
    const { hash } = useParams();
    const [status, setStatus] = useState(STATUS.approve);

    const { data, isLoading, isError } = useQuery(
        ["MANAGER_VALIDATION_LEAVE_REQUEST"],
        () => {
            return _api_externe_validation_leave(hash);
        },
        {
            retry: false,
            refetchOnWindowFocus: false,
        }
    );

    const {
        mutate,
        isLoading: isLoadingMutate,
        data: mutate_data,
    } = useMutation((step) => _api_approve_or_reject_timesheet({ step, hash }));

    const _validate_or_not_request_leave = (status) => {
        return mutate(status);
    };

    return (
        <Wrapper vAlign="center" hAlign="center">
            <Flex styles={{ position: "relative" }}>
                <ImageClock src={ClockPng} />
                {mutate_data?.success ? (
                    <ValidateSuccess />
                ) : isLoading ? (
                    <ValidateLoader />
                ) : isError ? (
                    <ValidateError />
                ) : (
                    <Validate
                        data={data}
                        isLoadingMutate={isLoadingMutate}
                        status={status}
                        setStatus={setStatus}
                        _validate_or_not_request_leave={
                            _validate_or_not_request_leave
                        }
                    />
                )}
            </Flex>
        </Wrapper>
    );
};

export default ManagerValidationLeaveRequest;

import React, { useState } from "react";
import {
    Attachment,
    Avatar,
    Box,
    Button,
    EyeIcon,
    FilesPdfIcon,
    Flex,
    Text,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { useMutation } from "react-query";
import { _api_approve_task, _api_refuse_task } from "../../api/tasks/approval";
import { file_size_format } from "../../constants/maths";
import { ICON_FROM_EXTENSION } from "../../utils/functions";
import { DEVICE } from "../../constants/responsive";

const Description = ({ children }) => {
    return <DescriptionStyle>{children}</DescriptionStyle>;
};

const Label = ({ children }) => {
    return <LabelStyle>{children}</LabelStyle>;
};

const Concern = ({ children }) => {
    return (
        <ConcernStyle>
            <ConcernText>Concerne : {children}</ConcernText>
        </ConcernStyle>
    );
};

const DueDate = ({ children }) => {
    return <DueDateStyle>{children}</DueDateStyle>;
};

const Badge = ({ children }) => {
    return <BadgeStyle>{children}</BadgeStyle>;
};

const Title = ({ children, ...rest }) => {
    return <TextStyle {...rest}>{children}</TextStyle>;
};

const TaskToValidate = ({ setConfigToast, task, refetchGetAllTasks }) => {
    // use mutation to send data.
    const { mutate: APICallValidateTask, isLoading: isLoadingValidation } =
        useMutation(({ task_id, assigned_user_id }) => {
            // API function
            return _api_approve_task({
                task_id: task_id,
                assigned_user_id: assigned_user_id,
            });
        });
    const { mutate: APICallRefuseTask, isLoading: isLoadingRefuse } =
        useMutation(({ task_id, assigned_user_id }) => {
            // API function
            return _api_refuse_task({
                task_id: task_id,
                assigned_user_id: assigned_user_id,
            });
        });

    const _validateTask = ({ id_task, assigned_user_id }) => {
        return APICallValidateTask(
            {
                task_id: id_task,
                assigned_user_id: assigned_user_id,
            },
            {
                onSuccess: (data) => {
                    console.log({ data });
                    if (!data.success) {
                        return setConfigToast({
                            content: "Un problème de connexion est survenu !",
                            level: "danger",
                            visible: true,
                        });
                    }
                    return setConfigToast({
                        content: "La tâche a bien été validée",
                        level: "success",
                        visible: true,
                    });
                },
                onError: (error) => {
                    return setConfigToast({
                        content: "Un problème de connexion est survenu !",
                        level: "danger",
                        visible: true,
                    });
                },
            }
        );
    };

    const _refuseTask = ({ id_task, assigned_user_id }) => {
        return APICallRefuseTask(
            {
                task_id: id_task,
                assigned_user_id: assigned_user_id,
            },
            {
                onSuccess: (data) => {
                    console.log({ data });
                    if (!data.success) {
                        return setConfigToast({
                            content: "Un problème de connexion est survenu !",
                            level: "danger",
                            visible: true,
                        });
                    }
                    return setConfigToast({
                        content: "La tâche a bien été Refusée",
                        level: "success",
                        visible: true,
                    });
                },
                onError: (error) => {
                    return setConfigToast({
                        content: "Un problème de connexion est survenu !",
                        level: "danger",
                        visible: true,
                    });
                },
            }
        );
    };

    return (
        <Card column gap="gap.small">
            <Flex vAlign="center" column>
                <Flex.Item>
                    <Card.Title truncated>{task.name}</Card.Title>
                </Flex.Item>
                <Flex.Item>
                    <Card.DueDate truncated>
                        Echéance : {task.due_date}
                    </Card.DueDate>
                </Flex.Item>
            </Flex>
            <Flex column>
                <Flex.Item>
                    <Card.Badge truncated>{task?.category?.name}</Card.Badge>
                </Flex.Item>
            </Flex>
            <Flex column>
                <Flex.Item>
                    <Card.Concern>
                        <Avatar
                            image={task?.related_to_user?.avatar}
                            name={task?.related_to_user?.name}
                            size="small"
                        />
                    </Card.Concern>
                </Flex.Item>
            </Flex>
            <Flex column>
                <Flex.Item>
                    <Card.Label>Descriptif</Card.Label>
                </Flex.Item>
                <Flex.Item>
                    <Card.Description>{task?.description}</Card.Description>
                </Flex.Item>
            </Flex>
            {/* document uploadés par le RH */}
            {task?.documents_template?.map((el, index) => {
                return (
                    <Attachment
                        key={index}
                        icon={ICON_FROM_EXTENSION(el.filename || el.name)}
                        header={
                            <WrapperTitleAttachment>
                                <Text
                                    truncated
                                    content={el.filename || el.name}
                                />
                            </WrapperTitleAttachment>
                        }
                        description={file_size_format(el.filesize)}
                        actionable
                        action={{
                            icon: <EyeIcon />,
                            onClick: (e) => {
                                e.stopPropagation();
                                window.open(el.attached_doc);
                            },
                            title: "Voir le document",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(el.attached_doc);
                        }}
                        style={{ maxWidth: "100%" }}
                    />
                );
            })}
            {/* document uploadés par le collaborateur */}
            {task?.documents?.length > 0 && (
                <Flex column gap="gap.small">
                    <Flex.Item>
                        <Card.Label>Document envoyé</Card.Label>
                    </Flex.Item>
                    {task?.documents?.map((el, index) => {
                        return (
                            <Attachment
                                key={index}
                                icon={ICON_FROM_EXTENSION(
                                    el.filename || el.name
                                )}
                                header={
                                    <WrapperTitleAttachment>
                                        <Text
                                            truncated
                                            content={el.filename || el.name}
                                        />
                                    </WrapperTitleAttachment>
                                }
                                description={file_size_format(el.filesize)}
                                actionable
                                action={{
                                    icon: <EyeIcon />,
                                    onClick: (e) => {
                                        e.stopPropagation();
                                        window.open(el.attached_doc);
                                    },
                                    title: "Voir le document",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(el.attached_doc);
                                }}
                                style={{ maxWidth: "100%" }}
                            />
                        );
                    })}
                </Flex>
            )}
            {/* ACTIONS */}
            <Flex
                row
                gap="gap.medium"
                hAlign="center"
                styles={{ marginTop: "12px", marginBottom: "10px" }}
            >
                <Button
                    content="Refuser"
                    secondary
                    loading={isLoadingRefuse}
                    onClick={() =>
                        _refuseTask({
                            id_task: task.id,
                            assigned_user_id: task?.assigned_to_user?.id,
                        })
                    }
                />
                <Button
                    primary
                    loading={isLoadingValidation}
                    content={"Valider"}
                    onClick={() =>
                        _validateTask({
                            id_task: task.id,
                            assigned_user_id: task?.assigned_to_user?.id,
                        })
                    }
                />
            </Flex>
        </Card>
    );
};

const WrapperTitleAttachment = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
`;

const DescriptionStyle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #616161;
`;

const LabelStyle = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #424242;
`;

const ConcernText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #616161;
`;

const ConcernStyle = styled(Flex)``;

const BadgeStyle = styled(Box)`
    background: #292929;
    border-radius: 100px;
    color: white;
    padding: 2px 6px;
    text-transform: capitalize;
    width: fit-content;

    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
`;

const DueDateStyle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`;

const TextStyle = styled(Text)`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-transform: capitalize;
`;

const Card = styled(Flex)`
    height: fit-content;
    background: white;
    box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11),
        0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11),
        0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
    transform: all 0.5s;
    &:hover {
        box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.11),
            0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
        transform: all 0.5s;
    }
    @media ${DEVICE.mobileS} {
        width: 300px;
    }
    @media ${DEVICE.tablet} {
        width: 320px;
    }
    @media ${DEVICE.laptop} {
        width: 320px;
    }
`;

Card.Title = Title;
Card.DueDate = DueDate;
Card.Badge = Badge;
Card.Concern = Concern;
Card.Label = Label;
Card.Description = Description;

export default TaskToValidate;

import React from "react";
import {
    Button,
    CalendarIcon,
    Flex,
    PersonIcon,
    Table,
    TableCell,
    TableRow,
} from "@fluentui/react-northstar";
import AvatarUser from "../../../components/Avatars";
import MoreMenuItem from "./MoreMenuItem";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";

const TableOfTaksAdded = ({
    tasks = [],
    setTemplateTasks,
    setOpenDialogModifyTask,
}) => {
    return (
        <WrapperTable column fill>
            <Table styles={{ width: "630px", minHeight: "155px" }}>
                <TableRow header>
                    <TableCell
                        styles={{
                            flex: 0.3,
                        }}
                        content={
                            <Button
                                styles={{ padding: "0" }}
                                as="span"
                                text
                                content={"Nom de la Tâche"}
                                size="small"
                            />
                        }
                    />
                    <TableCell
                        styles={{
                            flex: 0.3,
                        }}
                        content={
                            <Button
                                styles={{ padding: "0" }}
                                as="span"
                                text
                                icon={<CalendarIcon />}
                                content={"Règle sur la date"}
                                size="small"
                            />
                        }
                    />
                    <TableCell
                        styles={{
                            flex: 0.3,
                        }}
                        content={
                            <Button
                                styles={{ paddingLeft: "0" }}
                                as="span"
                                icon={<PersonIcon />}
                                text
                                content={"Responsable"}
                                size="small"
                            />
                        }
                    />
                    <TableCell
                        styles={{
                            flex: 0.1,
                        }}
                        content={""}
                    />
                </TableRow>

                {tasks?.length > 0 &&
                    tasks?.map((el, index) => {
                        const {
                            name,
                            duedate_duration,
                            duedate_operator,
                            duedate_event,
                            assigned_to,
                        } = el?.temp;
                        return (
                            <TableRow>
                                <TableCell
                                    styles={{
                                        flex: 0.3,
                                    }}
                                    content={name}
                                />
                                <TableCell
                                    styles={{
                                        flex: 0.3,
                                    }}
                                    content={`${duedate_duration} jours ${
                                        duedate_operator === 1
                                            ? "avant"
                                            : "après"
                                    } ${duedate_event?.header}`}
                                />
                                <TableCell
                                    styles={{
                                        flex: 0.3,
                                    }}
                                    content={
                                        <AvatarUser
                                            key={`avatar_${index}`}
                                            name={assigned_to?.header}
                                            image={assigned_to?.image}
                                            showName={true}
                                        />
                                    }
                                />
                                <TableCell
                                    styles={{
                                        flex: 0.1,
                                    }}
                                    content={
                                        <MoreMenuItem
                                            setTemplateTasks={setTemplateTasks}
                                            setOpenDialogModifyTask={
                                                setOpenDialogModifyTask
                                            }
                                            key={`more points ${index}`}
                                            task={el}
                                        />
                                    }
                                />
                            </TableRow>
                        );
                    })}
            </Table>
        </WrapperTable>
    );
};

export default TableOfTaksAdded;

export const WrapperTable = styled(Flex)`
    @media ${DEVICE.mobileS} {
        overflow-x: scroll;
        overflow-x: scroll;
        ::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
    }
    @media ${DEVICE.laptop} {
    }
`;

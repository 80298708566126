import React from "react";
import styled from "styled-components";
import { ExclamationTriangleIcon, Alert } from "@fluentui/react-northstar";

const MessageError = ({
    content = "Chargement ...",
    visible,
    level = "danger",
}) => {
    return (
        <AlertStyle
            fitted
            content={content}
            visible={visible}
            danger={level === "danger" ? true : false}
            icon={level === "danger" ? <ExclamationTriangleIcon /> : false}
        />
    );
};

export default MessageError;

const AlertStyle = styled(Alert)`
    background-color: #ebebeb;
    border: none;
    margin-left: 10px;
    padding: 0px;
    margin-top: 0px;
`;

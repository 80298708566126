import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { I18N_NS_EXPENSE } from "../../constants";

const RowCodeBudget = ({ code }) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    return (
        <Flex fill vAlign="center" space="between">
            <TitleText content={t("report.footer.code.text")} />
            <Code content={code} />
        </Flex>
    );
};

export default RowCodeBudget;

const TitleText = styled(Text)`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #242424;
`;

const Code = styled(Text)`
    color: #242424;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

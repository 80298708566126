import React from "react";
import {
    BadgeSubscription,
    Content,
    HeaderContent,
    HeaderDescription,
    Wrapper,
} from "./index.style";
import { Flex, Image } from "@fluentui/react-northstar";

const Tile = ({
    icon,
    content,
    desc,
    $selected = false,
    $disabled = false,
    $showBadge = false,
}) => {
    return (
        <Wrapper gap="gap.medium" $selected={$selected} $disabled={$disabled}>
            <Content gap="gap.medium">
                <Flex>
                    <Image src={icon} alt={`img_${content}`} />
                </Flex>
                <Flex column vAlign="center" fill>
                    <Flex vAlign="center" space="between">
                        <HeaderContent content={content} />
                        {$showBadge && (
                            <BadgeSubscription content={"Sur abonnement"} />
                        )}
                    </Flex>
                    <HeaderDescription content={desc} />
                </Flex>
            </Content>
        </Wrapper>
    );
};

export default Tile;

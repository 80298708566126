import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

// const en = require("../loc/loc.en.json");
// const fr = require("../loc/loc.fr.json");

// const resources = { en, fr };

// get the default lang of the browser.
// const userLang = navigator.language || navigator.userLanguage;

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // resources,
        debug: false,
        fallbackLng: "fr",
        ns: [
            "translation",
            "expenses",
            "leave",
            "annuaire",
            "onboarding",
            "hr_documents",
            "404",
        ],
        defaultNS: "translation",
        lng: "fr",
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;

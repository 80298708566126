import React, { useEffect, useState } from "react";
import { Button, FilterIcon, Flex, Loader } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container, ContainerFluid } from "../../../../common/styles";
import { Title, CardExpense, CardTitle, HeaderText } from "../../styles";

import { I18N_NS_EXPENSE, QUERY_KEY } from "../../constants";
import { ROUTES } from "../../../../constants/routes";
import ChartSection from "../../components/ChartSection";
import { CustomBadge } from "../../../../components/Badge";
import ExpensesRowsPendingManager from "./ExpensesRowsPendingManager";
import { _api_get_pending_expenses_manager } from "../../api";
import { useQuery } from "react-query";
import moment from "moment";
import ExpenseGoHistoryCard from "../../components/ExpenseGoHistoryCard";
import {
    modelWhereIamExpenses,
    useWhereIamExpenses,
} from "../../store/useWhereIam.store";
import Toast from "../../../../components/Alerts";
import { DEVICE } from "../../../../constants/responsive";
import styled from "styled-components";

const sortByDate = ({ data, dateSorted, setDateSorted }) => {
    let sortedList = data;
    if (!dateSorted) {
        setDateSorted(true);
        sortedList = data?.sort(
            (a, b) => moment(a.created_date) - moment(b.created_date)
        );
    } else {
        setDateSorted(false);
        sortedList = data?.sort((a, b) => {
            return moment(b.created_date) - moment(a.created_date);
        });
    }
    return sortedList;
};

const ExpensesManagerMyTeams = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    const [dateSorted, setDateSorted] = useState(0);
    const [sortedTask, setSortedTask] = useState([]);

    const setWhereIam = useWhereIamExpenses((state) => state.setWhereIam);

    const current_year = moment().format("YYYY");
    const { data: my_last_expenses_report, isLoading } = useQuery(
        [QUERY_KEY + "::manager_pending_expenses"],
        () => _api_get_pending_expenses_manager()
    );

    useEffect(() => {
        setWhereIam({
            isManagerPage: true,
            isEmployeePage: false,
            isAccountingManager: false,
        });
    }, []);

    useEffect(() => {
        setSortedTask(my_last_expenses_report);
    }, [my_last_expenses_report]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <ContainerFluid>
            <ContainerStyle gap="gap.large" style={{ marginBottom: "24px" }}>
                {/* LEFT SECTION */}
                <LeftSectionFlex
                    fill
                    styles={{
                        flex: 2,
                        marginBottom: "25px",
                    }}
                    column
                    gap="gap.medium"
                    // hAlign="center"
                >
                    <HeaderText content={"Notes de frais de mon équipe"} />
                    <CardExpense space={"between"} gap="gap.medium" column>
                        <Flex vAlign="center" space="between">
                            <Flex vAlign="center" gap="gap.small">
                                <CardTitle>Notes à valider</CardTitle>
                            </Flex>
                        </Flex>
                        {/* pending note clients */}
                        <ExpensesRowsPendingManager
                            my_last_expenses_report={sortedTask}
                            onSortedByDate={() => {
                                return sortByDate({
                                    data: sortedTask,
                                    dateSorted,
                                    setDateSorted,
                                });
                            }}
                            dateSorted={dateSorted}
                        />
                    </CardExpense>
                </LeftSectionFlex>
                {/* RIGHT SECTION */}
                <Flex
                    hAlign="center"
                    styles={{ flex: 1 }}
                    column
                    gap="gap.large"
                >
                    <ChartSection manager={true} />
                    <ExpenseGoHistoryCard
                        route={ROUTES.expenses.my_history.route}
                    />
                </Flex>
            </ContainerStyle>
        </ContainerFluid>
    );
};

export default ExpensesManagerMyTeams;

const LeftSectionFlex = styled(Flex)`
    @media ${DEVICE.laptop} {
        max-width: 60%;
    }
`;

const ContainerStyle = styled(Flex)`
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    margin-top: 24px;
    padding-inline: 6px;

    @media ${DEVICE.mobileS} {
        flex-direction: column;
        justify-content: center;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
    @media ${DEVICE.laptopL} {
    }
`;

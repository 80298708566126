// import { useNavigate } from "@react/router";
import { BASE_URL_API } from "../../constants";
import { useStepsLogin } from "../../pages/Login/Context.login";
import { useAuthDetailStore, useAuthStore } from "../../stores/useAuthStore";
import { getMyInformations } from "../user";

export const loginWithPassword = async ({ email, password }) => {
    return fetch(BASE_URL_API + "/auth/login", {
        method: "POST",
        body: JSON.stringify({
            username: email,
            password,
        }), // The data
        headers: {
            "Content-type": "application/json", // The type of data you're sending
        },
        credentials: "include",
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const login = async ({ email }) => {
    return fetch(BASE_URL_API + "/auth/check", {
        method: "POST",
        body: JSON.stringify({
            username: email,
        }), // The data
        headers: {
            "Content-type": "application/json", // The type of data you're sending
        },
        credentials: "include",
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        });
    // .catch((error) => {
    //     return error;
    // });
};

export const refreshToken = async () => {
    const setUser = useAuthStore.getState().setUser;
    const infos = useAuthDetailStore.getState();
    const setDetailUser = useAuthDetailStore.getState().setDetailUser;

    return fetch(BASE_URL_API + "/auth/refresh", {
        method: "POST",
        headers: {
            "Content-type": "application/json", // The type of data you're sending
        },
        credentials: "include",
    })
        .then(async (response) => {
            if (response.status === 401) {
                // logout !!
                return await logout();
            }
            return response.json();
        })
        .then((data) => {
            setUser(data);
            return {
                response: null,
                data,
            };
        })
        .finally(() => {
            if (infos?.detail) {
                getMyInformations().then((data) => {
                    if (data?.success) {
                        return setDetailUser(data?.user);
                    }
                });
            }
        })
        .catch((error) => {
            console.log("CATCHED => ", error);
        });
};

export const logout = async () => {
    const setUser = useAuthStore.getState().setUser;
    const showInit = useStepsLogin.getState().showInit;
    const setDetailUser = useAuthDetailStore.getState().setDetailUser;

    return fetch(BASE_URL_API + "/auth/logout", {
        method: "DELETE",
        headers: {
            "Content-type": "application/json", // The type of data you're sending
        },
        credentials: "include",
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setUser(null);
            setDetailUser(null);
            // remove all localstorage
            localStorage.clear();
            // remove sections of login pages.
            showInit();
        })
        .catch((error) => {
            console.log("CATCHED => ", error);
        });
};

import { BASE_URL_API } from "../../../constants";
import { useAuthDetailStore, useAuthStore } from "../../../stores/useAuthStore";
import fetcher from "../../../utils/fetcher";

export const _api_onboarding_upload_avatar = async ({ uploaded_files }) => {
    const fd = new FormData();
    fd.append("uploaded_file", uploaded_files);

    const user = useAuthStore.getState().infos;
    const user_infos = useAuthDetailStore.getState().detail;

    return fetch(`${BASE_URL_API}/user/${user_infos.id}/profile/photo`, {
        method: "PUT",
        body: fd,
        credentials: "include",
        headers: {
            Authorization: `Bearer ${user.access}`,
        },
    }).then((res) => res.json());
};

export const _api_welcome_screen = async () => {
    const url = BASE_URL_API + "/onboarding/welcome_screen";
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_update_emergency_contact = async (data) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    const url =
        BASE_URL_API + "/onboarding/user/" + id_user + "/emergency_contacts";
    return fetcher(url, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(data),
    });
};

export const _api_update_about_me = async (about_me) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    const url = BASE_URL_API + "/onboarding/user/" + id_user + "/about_me";
    return fetcher(url, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify({
            about_me,
        }),
    });
};

export const _api_set_cgu_as_read = async (about_me) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    const url = BASE_URL_API + "/onboarding/user/" + id_user + "/cgu";
    return fetcher(url, {
        method: "PATCH",
        credentials: "include",
    });
};

import produce from "immer";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useShowRemoteWeekend = create(
    persist(
        (set) => ({
            show: false,
            setShow: (bool) => {
                set(
                    produce((state) => {
                        state.show = bool;
                    })
                );
            },
        }),
        {
            name: "remote_show_weekend",
        }
    )
);

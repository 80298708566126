import { BASE_URL_API } from "../../../constants";

export const _api_externe_validation_leave = (hash) => {
    return fetch(BASE_URL_API + "/leave_request_approval/" + hash, {
        method: "GET",
        headers: {
            "Content-type": "application/json", // The type of data you're sending
        },
        credentials: "include",
    }).then(async (response) => {
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            throw new Error("Une erreur est survenue !");
        }
    });
};

export const _api_approve_or_reject_timesheet = ({ step, hash }) => {
    const fd = new FormData();
    fd.append("step", step.toLowerCase());
    return fetch(BASE_URL_API + "/leave_request_approval/" + hash, {
        method: "POST",
        body: fd,
    }).then(async (response) => {
        const data = await response.json();

        if (response.ok) {
            return data;
        } else {
            throw new Error("Une erreur est survenue !");
        }
    });
};

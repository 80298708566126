import moment from "moment";

export const formatHour = (hour) => {
    // Convertir l'heure en format flottant en un objet Moment
    const momentObj = moment().startOf("day").add(hour, "hours");

    // Formater l'objet Moment en une chaîne de caractères au format "Heure:minutes"
    const hour_formated = momentObj.format(`HH:mm`);

    // Retourner la chaîne de caractères formatée
    return hour_formated.replace(":", "h");
};

export const getYearMonthOfThisWeek = () => {
    const week = moment().isoWeek(); // get current week
    const m = moment().isoWeek(week); // set moment to this week
    m.isoWeekday(1).format("DD MMMM YYYY"); // set moment to the monday

    const month = m.month();
    const year = m.year();

    return {
        month,
        year,
    };
};

import React from "react";
import { Badge, BadgeText, Container } from "./index.style";
import { Flex, Text, Tooltip } from "@fluentui/react-northstar";
import { CellText } from "../WeekRow/Cell";

/**
 * @typedef {Object} PropsType
 * @property {string} background_color
 * @property {string} striped_color
 * @property {string} leave_name
 * @property {number} [hours]
 */

/**
 * StripedTypeLeaveWeek permet d'afficher les rayures des absences.(RTT, congé, etc ...)
 * @param {PropsType} props
 * @returns {ReactNode}
 */
const StripedTypeLeaveWeek = ({
    background_color = "#fcf4f6",
    striped_color = "#e6808a",
    leave_name = "",
    hours = null,
}) => {
    return (
        <Flex fill>
            <Tooltip
                trigger={
                    <Container
                        vAlign="center"
                        hAlign="center"
                        background_color={background_color}
                        striped_color={striped_color}
                    >
                        {hours && <CellText content={`${hours} h`} />}
                    </Container>
                }
                content={leave_name}
                subtle={false}
                pointing={true}
            />
        </Flex>
    );
};

export default StripedTypeLeaveWeek;

import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Content = styled(Text)`
    color: #616161;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
`;

export const Container = styled(Flex)`
    background: #ebebeb;
    margin-bottom: 4px;
    border-radius: 4px;
    padding: 4px 8px;
    min-width: fit-content;
`;

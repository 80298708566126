import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
    Avatar,
    Button,
    Flex,
    InfoIcon,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";

import { ROUTES } from "../../../../constants/routes";
import { I18N_NS_EXPENSE } from "../../constants";
import { CustomBadge } from "../../../../components/Badge";
import Counter from "../../components/Badges/Counter";
import { get_symbol_currency } from "../../functions";
import { ColumnName } from "../../components/ExpensesRows";

import {
    ArrowSortDown16Filled,
    ArrowSortUp16Filled,
} from "@fluentui/react-icons";
import Illustration from "../../../../components/shared/Illustration";
import Reinvoiceable from "../../components/Badges/Reinvoiceable";
import { useMediaQuery } from "react-responsive";
import { DEVICE, SIZES } from "../../../../constants/responsive";
import { FlexRow } from "../../components/Tables/RowExpense/RowExpense";

const ArrowSortDown = (
    <ArrowSortDown16Filled style={{ color: "var(--color-primary)" }} />
);
const ArrowSortUp = (
    <ArrowSortUp16Filled style={{ color: "var(--color-primary)" }} />
);

const CONSTANTS_COLUMN_WIDTH = {
    DATE: "56px",
    NAME_EXPENSE: "227px",
    COUNT_EXPENSES: "175px",
    REINVOICEABLE: "222px",
    AMOUNT: "142px",
};

const ExpensesRowsPendingManager = ({
    my_last_expenses_report,
    onSortedByDate,
    dateSorted,
}) => {
    const ROUTE_DETAIL_STATUS = ROUTES.expenses.manager.detail;
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const navigate = useNavigate();

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileM})`,
    });

    const isMobileM = useMediaQuery({
        query: "(max-width: 497px)",
    });

    const isMobile = isMobileS || isMobileM;

    return (
        <Flex fill column>
            <Flex
                fill
                space="between"
                vAlign="center"
                styles={{
                    borderBottom: "1px solid #D1D1D1",
                    paddingBlock: "8px",
                    paddingLeft: "10px",
                }}
            >
                <Flex
                    styles={{
                        width:
                            "calc(" + CONSTANTS_COLUMN_WIDTH.DATE + " + 10px)",
                    }}
                >
                    <ColumnName
                        onClick={() => onSortedByDate()}
                        content={
                            <Flex vAlign="center">
                                <Text content={t("home.columns.date")} />
                                {!dateSorted ? ArrowSortDown : ArrowSortUp}
                            </Flex>
                        }
                        styles={{
                            cursor: "pointer",
                        }}
                    />
                </Flex>
                <Flex
                    styles={{
                        width: CONSTANTS_COLUMN_WIDTH.NAME_EXPENSE,
                    }}
                    gap="gap.small"
                    vAlign="center"
                >
                    <ColumnName content={t("home.columns.expense_name")} />
                </Flex>
                {!isMobile && (
                    <Flex
                        styles={{
                            // border: "2px solid green ",
                            width: CONSTANTS_COLUMN_WIDTH.COUNT_EXPENSES,
                        }}
                        hAlign="center"
                    >
                        <ColumnName content={"Nb de dépenses"} />
                    </Flex>
                )}
                <Flex
                    styles={{
                        width: CONSTANTS_COLUMN_WIDTH.REINVOICEABLE,
                    }}
                    vAlign="center"
                    gap="gap.small"
                >
                    <ColumnName content="" />
                </Flex>
                <Flex
                    styles={{
                        width: CONSTANTS_COLUMN_WIDTH.AMOUNT,
                    }}
                    hAlign="end"
                >
                    <ColumnName content={t("home.columns.amount")} />
                </Flex>
            </Flex>
            {my_last_expenses_report?.length ? (
                my_last_expenses_report?.map((el, index) => {
                    const currency = get_symbol_currency(el?.currency);
                    return (
                        <LinkTo
                            to={ROUTE_DETAIL_STATUS.custom_route(
                                el?.id,
                                el?.user?.id
                            )}
                        >
                            <FlexRow
                                fill
                                gap="gap.small"
                                space="between"
                                vAlign="center"
                                styles={{
                                    paddingBlock: "16px",
                                    // border: "2px solid yellow",
                                    paddingRight: "8px",
                                }}
                                key={`ROW_${index}`}
                            >
                                <Flex
                                    styles={{
                                        width: CONSTANTS_COLUMN_WIDTH.DATE,
                                    }}
                                >
                                    <Date>
                                        {moment(el?.created_date).format(
                                            "DD/MM"
                                        )}
                                    </Date>
                                </Flex>
                                <Flex
                                    styles={{
                                        width: CONSTANTS_COLUMN_WIDTH.NAME_EXPENSE,
                                    }}
                                    gap="gap.small"
                                    vAlign="center"
                                >
                                    {!isMobile && (
                                        <div>
                                            <Avatar
                                                name={`${el?.user?.first_name} ${el?.user?.last_name}`}
                                                image={el?.user?.avatar}
                                                size="smaller"
                                            />
                                        </div>
                                    )}
                                    <LinkTo
                                        to={ROUTE_DETAIL_STATUS.custom_route(
                                            el?.id,
                                            el?.user?.id
                                        )}
                                    >
                                        {isMobile ? (
                                            <Flex
                                                vAlign="center"
                                                gap="gap.small"
                                            >
                                                <Avatar
                                                    name={`${el?.user?.first_name} ${el?.user?.last_name}`}
                                                    image={el?.user?.avatar}
                                                    size="small"
                                                />
                                                <Title
                                                    truncated
                                                    content={el?.name}
                                                />
                                            </Flex>
                                        ) : (
                                            <Title
                                                truncated
                                                content={el?.name}
                                            />
                                        )}
                                    </LinkTo>
                                </Flex>
                                {!isMobile && (
                                    <Flex
                                        styles={{
                                            // border: "2px solid green",
                                            width: CONSTANTS_COLUMN_WIDTH.COUNT_EXPENSES,
                                        }}
                                        hAlign="center"
                                    >
                                        <Counter>{el?.expenses_count}</Counter>
                                    </Flex>
                                )}
                                {!isMobile && (
                                    <Flex
                                        styles={{
                                            width: CONSTANTS_COLUMN_WIDTH.REINVOICEABLE,
                                        }}
                                        vAlign="center"
                                        hAlign="center"
                                        gap="gap.small"
                                    >
                                        {el?.reinvoiceable && (
                                            <Reinvoiceable
                                                client_name={el?.client?.name}
                                            />
                                        )}
                                    </Flex>
                                )}
                                <Flex
                                    styles={{
                                        width: CONSTANTS_COLUMN_WIDTH.AMOUNT,
                                    }}
                                    hAlign="end"
                                >
                                    <TotalExpenseManager
                                        content={`${el?.amount_total} ${currency}`}
                                    />
                                </Flex>
                            </FlexRow>
                        </LinkTo>
                    );
                })
            ) : (
                <Flex
                    fill
                    hAlign="center"
                    column
                    gap="gap.small"
                    styles={{ marginTop: "15px" }}
                >
                    <Illustration
                        filename={"expense/Note-de-frais-vide.svg"}
                        width="200px"
                    />
                    <EmptyTitle content={t("manager.home.empty.title")} />
                    <EmptySubTitle
                        content={t("manager.home.empty.sub_title")}
                    />
                    <div>
                        <Button
                            flat
                            secondary
                            content={"Voir tout l'historique"}
                            onClick={() =>
                                navigate(ROUTES.expenses.manager.history.route)
                            }
                            styles={{
                                paddingInline: "50px",
                                marginTop: "15px",
                            }}
                        />
                    </div>
                </Flex>
            )}
        </Flex>
    );
};

export default ExpensesRowsPendingManager;

const EmptySubTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 210px;
    text-align: center;
    color: #242424;
`;

const EmptyTitle = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
`;

const TotalExpenseManager = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #242424;
`;

const LinkTo = styled(Link)`
    text-decoration: none;
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    color: #5b5fc7;
    align-items: center;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 110px; /* valeur par défaut pour les écrans plus grands */

    @media ${DEVICE.mobileS} {
        max-width: 120px; /* pour les mobiles moyens */
    }
    @media ${DEVICE.mobileM} {
        max-width: 170px; /* pour les mobiles moyens */
    }
    @media ${DEVICE.mobileL} {
        max-width: 200px; /* pour les mobiles moyens */
    }
    @media ${DEVICE.tablet} {
        max-width: 150px; /* pour les mobiles moyens */
    }
    @media ${DEVICE.laptop} {
        max-width: 110px; /* pour les mobiles moyens */
    }
    @media ${DEVICE.laptopL} {
        max-width: 250px; /* pour les mobiles moyens */
    }
`;

const Date = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #424242;
`;

import { Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const LinkText = styled.a`
    color: #5b5fc7;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    cursor: pointer;
`;

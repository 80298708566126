export const BREADCRUMB_DOCUMENTS = [
    {
        label: "breadcrumb.home",
        href: "/",
    },
    {
        label: "breadcrumb.hr_documents",
        href: "/rh/documents",
    },
];

export const BREADCRUMB_HUMBERGER = [
    {
        id: 1,
        name: "Accueil",
        link: "/",
    },
    {
        id: 2,
        name: "Mes tâches",
        link: "/dashboard/tasks",
    },
    {
        id: 3,
        name: "Gestion du temps",
        link: "/manage_time",
    },
];

export const I18N_NS_DOCUMENTS = "hr_documents";

import { Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Title = styled(Text)`
    color: #424242;
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
`;

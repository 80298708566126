import React from "react";
import {
    Accordion,
    AccordionTitle,
    AccordionTitleText,
    LeftColumnCell,
    Row,
    User,
    UserName,
    WrapperUsers,
} from "../../styles";
import { Avatar, ChevronDownIcon, Flex } from "@fluentui/react-northstar";
import { str_ellipsis } from "../../../../utils/functions";
import DepartmentCounter from "../../../Badge/DepartmentCounter";
import { useState } from "react";

import { motion } from "framer-motion";

const RowsDepartmentsUsers = ({
    timeline,
    onClickUser = () => console.log("user clicked"),
    onCollapseDepartment = () => console.log("departement collapsed"),
}) => {
    const [arrayCollapsedDepartments, setArrayCollapsedDepartments] = useState(
        []
    );

    return (
        <Row column>
            <Flex column>
                {timeline?.departments?.map((department) => {
                    const index_department = arrayCollapsedDepartments.indexOf(
                        department.id
                    );

                    const chevronRotation = index_department === -1 ? 180 : 360;

                    return (
                        <>
                            <LeftColumnCell vAlign="center">
                                <Accordion space="between" fill vAlign="center">
                                    <AccordionTitle>
                                        <AccordionTitleText
                                            content={str_ellipsis({
                                                str: department.name,
                                                length: 19,
                                            })}
                                            title={department.name}
                                        />
                                    </AccordionTitle>
                                    <Flex gap="gap.small">
                                        <DepartmentCounter
                                            counter={department.users_count}
                                        />
                                        <motion.div
                                            initial={{
                                                rotate: 0,
                                            }}
                                            animate={{
                                                rotate: chevronRotation,
                                            }}
                                            transition={{
                                                duration: 0.2,
                                            }}
                                        >
                                            <ChevronDownIcon
                                                // rotate={chevronRotation}
                                                onClick={() => {
                                                    index_department === -1
                                                        ? setArrayCollapsedDepartments(
                                                              (state) => {
                                                                  return [
                                                                      ...state,
                                                                      department.id,
                                                                  ];
                                                              }
                                                          )
                                                        : setArrayCollapsedDepartments(
                                                              (state) => {
                                                                  return state.filter(
                                                                      (e) =>
                                                                          e !==
                                                                          department.id
                                                                  );
                                                              }
                                                          );
                                                    onCollapseDepartment(
                                                        department.id
                                                    );
                                                    return;
                                                }}
                                                styles={{
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </motion.div>
                                    </Flex>
                                </Accordion>
                            </LeftColumnCell>
                            <WrapperUsers column>
                                {!arrayCollapsedDepartments.includes(
                                    department.id
                                )
                                    ? department?.users?.map((user) => {
                                          //   const username = str_ellipsis({
                                          //       str: user?.name,
                                          //       length: 15,
                                          //   });
                                          return (
                                              <User
                                                  fill
                                                  gap="gap.small"
                                                  vAlign="center"
                                                  key={user.id}
                                                  title={user?.name}
                                                  onClick={() =>
                                                      onClickUser({
                                                          visible: true,
                                                          user: {
                                                              id: user.id,
                                                              name: user?.name,
                                                              avatar: user.avatar,
                                                          },
                                                      })
                                                  }
                                              >
                                                  <Avatar
                                                      size="smallest"
                                                      name={user?.name}
                                                      image={user.avatar}
                                                  />
                                                  <UserName
                                                      content={user?.name}
                                                  />
                                              </User>
                                          );
                                      })
                                    : null}
                            </WrapperUsers>
                        </>
                    );
                })}
            </Flex>
        </Row>
    );
};

export default RowsDepartmentsUsers;

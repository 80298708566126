import React from "react";
import { BreakText, Container } from "./index.style";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";

const Break = ({ duration }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    return (
        <Container hAlign="center">
            <BreakText content={`${t("v2.home.pills.break")} : ${duration}`} />
        </Container>
    );
};

export default Break;

import { Accordion, Box, Button, teamsTheme } from "@fluentui/react-northstar";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PANELS } from "./Panels";
import * as yup from "yup";
import { _api_update_profile } from "../../../../api/profile";
import { useMutation, useQueryClient } from "react-query";
import { formating_json_for_updating_profile } from "./utils";

const SCHEMA = yup.object({
    phone_number: yup.string().nullable(),
    // about_me: yup.string().min(3, "Minimum 3 caractères"),
    first_name: yup.string().required("Ce champ est obligatoire"),
    last_name: yup.string().required("Ce champ est obligatoire"),
    birth_name: yup.string().required("Ce champ est obligatoire"),
    personal_phone_number: yup
        .string()
        .min(12, "Veuillez saisir un numéro de téléphone valide")
        .required("Ce champ est obligatoire"),
    address: yup.string().typeError("Veuillez saisir votre adresse"),
    secu: yup.string().nullable(),
    // .min(10, "Format incorrecte")
    // .max(13, "Format incorrecte"),
    job_title: yup.string().required("Ce champ est obligatoire"),
    arrival_date: yup
        .date("Date n'est pas valide")
        .typeError("Date n'est pas valide")
        .required("Ce champ est obligatoire"),
    contract: yup.object({
        coefficient: yup.string().nullable(),
        position: yup.string().nullable(),
        probationary_period_end_date: yup
            .date("Date n'est pas valide")
            .typeError("Date n'est pas valide")
            .required("Ce champ est obligatoire"),
        role: yup.string().required("Ce champ est obligatoire"),
    }),
    manager: yup.object({
        email: yup.string().required("Ce champ est obligatoire"),
    }),
    department: yup.object({
        name: yup.string().required("Ce champ est obligatoire"),
    }),
    agency: yup.object({
        name: yup.string().required("Ce champ est obligatoire"),
    }),

    // Voir les derniers lien que hajer m'a envoyé, il ya quelque bug de message d'erreur a retravaillé !!!
});

const TabsContent = ({ user }) => {
    const queryClient = useQueryClient();

    const methods = useForm({
        resolver: yupResolver(SCHEMA),
        mode: "all",
        defaultValues: user,
    });

    const {
        getValues,
        setError,
        control,
        formState: { errors, isValid },
    } = methods;

    const navigate = useNavigate();

    const {
        mutate,
        isLoading: isLoadingMutation,
        isFetching: isFetchingProfileDataAfterModify,
    } = useMutation(["updateProfile"], async (data) => {
        const format_json = formating_json_for_updating_profile(data);
        await _api_update_profile(format_json);
        queryClient.invalidateQueries(["getUserInformationForModify"]);
        return navigate(`/employee/${data?.id}?alert=true`);
    });

    if (isFetchingProfileDataAfterModify) {
        return console.log("isFetchingProfileDataAfterModify");
    }

    const onSubmit = (data) => {
        const values = getValues();
        mutate(values);
    };

    const BackToProfile = () => {
        return navigate("/me");
    };

    return (
        <FormProvider {...methods} theme={teamsTheme}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Accordionn
                    user={user}
                    defaultActiveIndex={[1]}
                    panels={PANELS}
                    key={PANELS.map((el) => el.key)}
                    expandiconposition="end"
                />
                <Action>
                    <Button onClick={BackToProfile} tinted content="Annuler" />
                    <Button
                        type="submit"
                        content="Enregistrer"
                        primary
                        loading={isLoadingMutation}
                    />
                </Action>
            </form>
        </FormProvider>
    );
};

export default TabsContent;

const Accordionn = styled(Accordion)`
    background-color: #ebebeb;
    > .ui-box {
        background-image: none;
    }
    .ui-accordion__title__content-wrapper {
        grid-template-columns: 1fr;
        > .ui-box:first-child {
            display: none;
        }
    }
    background-color: #ebebeb;
    max-width: 875px;
    margin: 0 auto;
`;

const Action = styled(Box)`
    display: flex;
    gap: 16px;
    justify-content: center;
    background-color: #ebebeb;

    max-width: 875px;
    margin: 0 auto;
    padding: 20px;
`;

import React from "react";
import { TextLeave, Wrapper } from "./index.style";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { useTranslation } from "react-i18next";
import { isMorning } from "../../../../utils/dates";

const SlotLeave = ({ start_time = null, end_time = null }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    let content = "";
    if (start_time && end_time) {
        content = isMorning(start_time)
            ? "v2.home.dialogs.morning"
            : "v2.home.dialogs.afternoon";
    }
    return (
        <Wrapper gap="gap.small" vAlign="center" hAlign="center">
            <TextLeave
                content={`${t("v2.home.dialogs.texts.week")} ${t(content)}`}
            />
        </Wrapper>
    );
};

export default SlotLeave;

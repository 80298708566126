import React from "react";
import { ContainerFluid } from "../../../../common/styles";
import {
    Body,
    CardExpenseRedesigned,
    CardExpenseTitle,
    ContainerStyle,
    Title,
} from "./index.style";
import { CustomBadge } from "../../../../components/Badge";
import { Flex, Loader, Table } from "@fluentui/react-northstar";
import { header } from "./constants";
import HistoryPaidExpenses from "../../components/HistoryPaidExpenses/HistoryPaidExpenses";
import { _api_get_list_to_verify_expenses } from "../../api";
import EmptyExpensesToPaid from "./EmptyExpensesToPaid";
import useLogic from "./useLogic";
import Toast from "../../../../components/Alerts";
import { SIZES } from "../../../../constants/responsive";
import { useMediaQuery } from "react-responsive";

const ExpensesAccountingManagerHome = () => {
    // IMPORT LOGIC
    const { rows, query, toast } = useLogic();

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileM})`,
    });
    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    const isMobileL = useMediaQuery({
        query: "(max-width: 450px)",
    });

    const isMobile = isMobileS || isMobileM || isMobileL;

    return (
        <ContainerFluid>
            <ContainerStyle styles={{ marginTop: "24px" }}>
                <Flex>
                    <Toast
                        visible={toast.show.visible}
                        content="Note de frais approuvée"
                        level="success"
                        onStatusChange={() => {
                            toast.setShow((prev) => ({
                                ...prev,
                                visible: false,
                            }));
                        }}
                    />
                </Flex>
                <Body gap="gap.large" styles={{ marginTop: "24px" }}>
                    {/* LEFT SECTION */}
                    <Flex column gap="gap.medium">
                        <Title>Notes de frais de l'entreprise</Title>
                        <CardExpenseRedesigned column gap="gap.medium">
                            {/* title + badge */}
                            <Flex vAlign="center" gap="gap.small">
                                <CardExpenseTitle>
                                    Notes à payer
                                </CardExpenseTitle>
                                <CustomBadge
                                    bg="orange"
                                    color="white"
                                    label={query.data?.length?.toString()}
                                    size="smaller"
                                />
                            </Flex>
                            {/* TABLE EXPENSES */}

                            {query.isLoading ? (
                                <Loader label={"chargement des notes ..."} />
                            ) : (
                                <Table
                                    header={!isMobile && header}
                                    rows={rows}
                                    aria-label="list"
                                />
                            )}

                            {rows.length === 0 && <EmptyExpensesToPaid />}
                        </CardExpenseRedesigned>
                    </Flex>
                    {/* RIGHT SECTION */}

                    <HistoryPaidExpenses />
                </Body>
            </ContainerStyle>
        </ContainerFluid>
    );
};

export default ExpensesAccountingManagerHome;

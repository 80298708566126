import React from "react";

import {
    Avatar,
    Box,
    Breadcrumb,
    ChevronEndIcon,
    Flex,
    Input,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../../constants/responsive";
import { useRef } from "react";
import { useAuthDetailStore } from "../../../../stores/useAuthStore";
import { useState } from "react";
import { _api_upload_avatar } from "../../../../api/user/profile";

const _get_name_user = ({ me = {}, user = {} }) => {
    // debugger;
    const { first_name: first_name_user, last_name: last_name_user } = user;

    const { first_name, last_name } = me;

    if (first_name_user) {
        return first_name_user + " " + last_name_user;
    }

    return first_name + " " + last_name;
};

const HeaderProfil = ({ header_description, me_avatar, user_avatar }) => {
    const refInput = useRef(null);

    const [avatar, setAvatar] = useState();

    const profile_avatar = me_avatar?.user?.avatar ?? user_avatar?.user?.avatar;

    const profile_name = _get_name_user({
        me: me_avatar?.user,
        user: user_avatar?.user,
    });

    const setDetailAvatar = useAuthDetailStore.getState().setDetailAvatar;

    const _changeMyAvatar = async (e) => {
        const files = e.target.files;
        if (!files.length) return;
        const file = files[0];

        if (user_avatar?.user) {
            const response = await _api_upload_avatar({
                uploaded_files: file,
                id_user: user_avatar?.user?.id,
            });
            if (response.success) {
                setAvatar(response?.avatar);
                setDetailAvatar(response.avatar);
            }
            return;
        }

        const response = await _api_upload_avatar({
            uploaded_files: file,
        });

        if (response.success) {
            setAvatar(response.avatar);
            setDetailAvatar(response.avatar);
        }
    };

    return (
        <Box>
            <ToolbarStyle>
                <Flex column>
                    <Breadcrumb aria-label="breadcrumb">
                        <Breadcrumb.Item>
                            <Breadcrumb.Link
                                style={{
                                    color: "white",
                                    textDecoration: "none",

                                    fontSize: "14px",
                                    marginRight: "-6px",
                                }}
                                href="/dashboard"
                            >
                                Accueil
                            </Breadcrumb.Link>
                        </Breadcrumb.Item>

                        <ChevronEndIcon
                            style={{
                                color: "white",
                            }}
                            size="medium"
                        />
                        <Breadcrumb.Item
                            onClick={() =>
                                console.log("navigate to mon profile")
                            }
                            style={{
                                cursor: "pointer",
                                color: "white",
                                textDecoration: "none",
                                fontSize: "14px",
                                marginLeft: "-6px",
                            }}
                        >
                            <Breadcrumb.Link
                                style={{
                                    color: "white",
                                    textDecoration: "none",

                                    fontSize: "14px",
                                    marginRight: "-6px",
                                }}
                                href="/me"
                            >
                                Mon profile
                            </Breadcrumb.Link>
                        </Breadcrumb.Item>
                        <ChevronEndIcon
                            style={{
                                color: "white",
                            }}
                            size="medium"
                        />
                        <Breadcrumb.Item
                            style={{
                                color: "white",
                                textDecoration: "none",
                                fontWeight: "bold",
                                marginLeft: "-6px",
                            }}
                        >
                            Modifier
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Flex>
            </ToolbarStyle>
            <Header column gap="gap.small">
                <Flex.Item>
                    <Text weight="bold" content={"Modification du profil"} />
                </Flex.Item>
                <Flex.Item>
                    <Text
                        style={{ color: "#616161" }}
                        weight="regular"
                        content={header_description}
                    />
                </Flex.Item>
            </Header>
            <AvatarSection>
                <Flex.Item>
                    <Avatar
                        size={"larger"}
                        name={profile_name}
                        image={avatar || profile_avatar}
                    />
                </Flex.Item>
                <Flex.Item>
                    <Box>
                        <Input
                            type="file"
                            hidden
                            ref={refInput}
                            onChange={_changeMyAvatar}
                            accept="image/*"
                        />
                        <Text
                            color="brand"
                            content="Changer la photo"
                            styles={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={() => refInput.current.click()}
                        />
                    </Box>
                </Flex.Item>
            </AvatarSection>
        </Box>
    );
};

export default HeaderProfil;

const ToolbarStyle = styled(Box)`
    background-color: #5b5fc7;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
`;

const AvatarSection = styled(Flex)`
    @media ${DEVICE.mobileS} {
        background-color: #ebebeb;
        flex-direction: row;
        gap: 24px;
        padding: 0px 16px 16px 16px;
        align-items: center;
        justify-content: flex-start;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
        /* border: 1px solid yellow; */
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
        max-width: 876px;
        margin: 0 auto;
    }
`;

const Header = styled(Flex)`
    @media ${DEVICE.mobileS} {
        background-color: #ebebeb;
        flex-direction: column;
        gap: 8px;
        padding: 16px;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
        /* border: 1px solid yellow; */
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
        max-width: 876px;
        margin: 0 auto;
    }
`;

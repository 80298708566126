import {
    ArrowRightIcon,
    Button,
    Divider,
    Flex,
    Image,
    Input,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { DEVICE } from "../../constants/responsive";

import Logo_yeetch from "../../assets/logos/logo.svg";
import { MSButton, MSLogoStyle } from "./index.style";
import MSLogo from "../../assets/icons/microsoft.svg";
const LoginMsConnect = () => {
    return (
        <RightContent vAlign="center" column hAlign="center">
            <LoginBody column hAlign="center" vAlign="center">
                <LogoFlex gap="gap.medium">
                    <Image src={Logo_yeetch} />
                    <LogoText content="Yeetch" alt="logo yeetch" />
                </LogoFlex>
                <MSconnectFlex column>
                    <MSconnectTitle content="Se connecter avec Microsoft®" />

                    <MSconnectText
                        content={
                            <span>
                                Pour bénéficier{" "}
                                <b
                                    style={{
                                        color: "#5B5FC7",
                                    }}
                                >
                                    {" "}
                                    maximum de fonctionnalités
                                </b>
                            </span>
                        }
                    />
                    <MSButton href={``}>
                        <MSLogoStyle src={MSLogo} alt="MS icon" />
                        <span style={{ textTransform: "uppercase" }}>
                            Se connecter avec Microsoft
                        </span>
                    </MSButton>
                </MSconnectFlex>
            </LoginBody>

            <Flex styles={{ width: "315px" }}>
                <Divider
                    styles={{ width: "100%" }}
                    content={
                        <Text
                            styles={{
                                marginInline: "-10px",
                                color: "#616161",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "16px ",
                            }}
                            as="p"
                            content="Ou"
                        />
                    }
                />
            </Flex>
            <Flex column gap="gap.medium" styles={{ width: "315px" }}>
                <TextStyle content="Se connecter avec son email" />
                <Flex column>
                    <ConnectionGroup fill column>
                        {/* INPUT EMAIL */}
                        <Flex column fill>
                            <Input
                                // styles={{ marginTop: "12px" }}
                                fluid
                                name="email"
                                type="email"
                                label={
                                    <Text
                                        styles={{
                                            color: "#616161",
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            lineHeight: "16px",
                                        }}
                                        content="Email"
                                    />
                                }
                                // onChange={(e) =>
                                //     setEmail(e.target.value)
                                // }
                                // value={email}
                                // error={error ? true : false}
                                // ref={refEmail}
                            />
                            {/* <ErrorMessage content={error} /> */}
                        </Flex>
                        {/* Button se connecter avec email */}
                        <Flex fill vAlign="center" hAlign="center" column>
                            <Button
                                fluid
                                // disabled={
                                //     validator.isEmail(email)
                                //         ? false
                                //         : true
                                // }
                                styles={{ paddingInline: "8px" }}
                                // onClick={handleSubmit}
                                // loading={
                                //     isLoading || isFetching
                                //         ? true
                                //         : false
                                // }
                                primary
                                content={
                                    <Text
                                        style={{ textTransform: "uppercase" }}
                                        content="Se connecter avec son email"
                                    />
                                }
                                icon={<ArrowRightIcon size="small" />}
                                iconPosition="after"
                            />
                        </Flex>
                    </ConnectionGroup>
                </Flex>
            </Flex>
            <Footer>
                <Flex>
                    <FooterText content="Politique de confidentialité" />
                    <Divider vertical size={1} styles={{ height: "16px" }} />
                    <FooterText content="Assistance" />
                </Flex>
                <FooterSign content="© YEETCH 2023" />
            </Footer>
        </RightContent>
    );
};

export default LoginMsConnect;

const Footer = styled(Flex)`
    gap: 40px;
`;
const FooterSign = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #616161;
`;

const FooterText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0067b8;
`;

const TextStyle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #424242;
`;

const MSconnectText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* color: #242424; */
`;

const MSconnectTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #424242;
`;
const MSconnectFlex = styled(Flex)`
    gap: 16px;
`;

const LogoText = styled(Text)`
    font-family: "Fredoka";
    font-style: normal;
    font-weight: 500;
    font-size: 33px;
    line-height: 40px;
    letter-spacing: -0.085em;
    color: #000000;
`;
const LogoFlex = styled(Flex)``;

const LoginBody = styled(Flex)`
    gap: 48px;
`;

const RightContent = styled(Flex)`
    min-width: 50vw;
    height: 100vh;

    gap: 40px;
`;

const ConnectionGroup = styled(Flex)`
    @media ${DEVICE.mobileS} {
        gap: 14px;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.laptop} {
    }
`;

import { Avatar, Box, Divider, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { format_number } from "../../../../../constants/maths";
import BadgeMonthDeclaration from "../../badges/BadgeMonthDeclaration";
import WeekSecondRow from "./WeekSecondRow";

const WeekTableContent = ({ users }) => {
    return (
        <Flex column gap="gap.medium">
            {users?.map((el) => {
                if (!el?.timesheets) return;
                const { id, first_name, last_name, avatar, timesheets } = el;
                const head = timesheets.at(0);
                return (
                    <Box key={id}>
                        <Flex gap="gap.large">
                            <Flex
                                gap="gap.small"
                                vAlign="center"
                                styles={{
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    width: "200px",
                                }}
                            >
                                <Avatar
                                    size="smaller"
                                    image={avatar}
                                    name={`${first_name} ${last_name}`}
                                />
                                <Text content={`${first_name} ${last_name}`} />
                            </Flex>
                            {head?.values.map((value, i) => {
                                return <DataTable content={`${value} h`} />;
                            })}
                            <Flex vAlign="center" gap="gap.small">
                                <TotalText
                                    fontWeight="bold"
                                    content={`${head?.total?.value} h`}
                                />
                                <BadgeMonthDeclaration
                                    total={format_number(head?.total?.diff)}
                                    showHourSign={false}
                                />
                            </Flex>
                        </Flex>
                        {el?.timesheets?.map((timesheet) => {
                            return (
                                <Flex
                                    styles={{
                                        paddingBlock: "5px",
                                        marginTop: "5px",
                                    }}
                                >
                                    <WeekSecondRow
                                        key={el?.id}
                                        timesheet={timesheet}
                                    />
                                </Flex>
                            );
                        })}

                        <Divider />
                    </Box>
                );
            })}
        </Flex>
    );
};

export default WeekTableContent;

const DataTable = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    width: 120px;
`;

const TotalText = styled(Text)`
    font-weight: bold;
`;

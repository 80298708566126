import { Pill } from "@fluentui/react-northstar";
import styled from "styled-components";

import PropTypes from "prop-types";
import { DEVICE } from "../../constants/responsive";

export const CustomBadge = ({
    size = "small",
    label = "",
    bg = "var(--bg-orange)",
    appearence = "filled",
    color = "black",
    rectangular = false,
    isMobileS = false,
    ...props
}) => {
    // console.log("isMobileS", isMobileS);
    return (
        <PillStyle
            isMobileS={isMobileS}
            rectangular={rectangular}
            appearence={appearence}
            content={label}
            size={size}
            bg={bg}
            color={color}
            {...props}
        />
    );
};

CustomBadge.propTypes = {
    /** set the size of element */
    size: PropTypes.oneOf(["small", "smaller", "medium"]),
    /** set the content of element */
    label: PropTypes.string,
    /** set the background color of element */
    bg: PropTypes.string,
    /** set the color of the text element */
    color: PropTypes.string,
    appearence: PropTypes.oneOf(["filled", "inverted", "outline"]),
    /** set the shape of element */
    rectangular: PropTypes.bool,
};

const PillStyle = styled(Pill)`
    /* min-width: ${({ isMobileS }) => (isMobileS ? "80px" : "fit-content")}; */
    min-width: fit-content;
    /* width: 30px; */
    background-color: ${({ bg }) => bg};
    color: ${({ color }) => color};
    /* justify-content: center;
    align-items: center; */
    /* border: 3px solid red; */
    margin-left: ${({ isMobileS }) => (isMobileS ? "-10px" : "0px")};
    height: ${({ isMobileS }) => (isMobileS ? "20px" : "30px")};

    &:hover {
        min-width: fit-content;
        background-color: ${({ bg }) => bg};
        color: ${({ color }) => color};
    }
`;

import { Button, Flex } from "@fluentui/react-northstar";
import styled, { css } from "styled-components";
import { DEVICE } from "../../../constants/responsive";

export const Main = styled(Flex)`
    @media ${DEVICE.mobileS} {
        margin-top: 20px;
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        margin-top: 55px;
        width: 852px;
        gap: 40px;
    }
`;

export const MainSectionStyle = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 100%;
        flex-direction: column;
        gap: 24px;
        padding-inline: 16px;
    }
    @media ${DEVICE.laptop} {
        width: 852px;
        flex-direction: row;
        gap: 24px;
        padding-inline: 0px;
    }
`;

export const FlexProfileSection = styled(Flex)`
    @media ${DEVICE.mobileS} {
        gap: 16px;
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        gap: 49px;
    }
`;

export const TimeSectionCard = styled(Flex)`
    position: relative;
    height: 200px;
    min-width: 234px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    transition: box-shadow 0.2s;
    ${({ $not_activated }) =>
        !$not_activated
            ? css`
                  :hover {
                      cursor: pointer;
                      box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
                          0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11);
                      transition: all 0.2s;

                      .link {
                          font-weight: 600;
                          transition: all 0.2s;
                      }
                  }
              `
            : css``}
    @media ${DEVICE.mobileS} {
        width: 100%;
        justify-content: center;
    }
    @media ${DEVICE.laptop} {
        width: 234px;
    }
`;

export const AbsenceSectionCard = styled(Flex)`
    position: relative;
    height: 202px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    /* background-image: url("https://asenditbackenddevstorage.blob.core.windows.net/static/illustration/dashboard/Absence%20dashboard%20background.svg"); */
    padding-bottom: 12px;
    cursor: pointer;
    transition: all 0.2s;
    :hover {
        box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
            0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11);
        transition: all 0.2s;

        .link {
            font-weight: 600;
            transition: all 0.2s;
        }
    }
    @media ${DEVICE.mobileS} {
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: 234px;
    }
`;

export const RightSide = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 100%;
        padding-top: 0px;
        padding-inline: 16px;
    }
    @media ${DEVICE.laptop} {
        width: auto;
        padding-top: 55px;
    }
`;

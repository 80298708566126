import { Flex, Input, InputLabel, Text } from "@fluentui/react-northstar";
import MuiPhoneNumber from "material-ui-phone-number";
import styled from "styled-components";

export const TextStyle = styled(Text)`
    font-style: normal;
    font-weight: ${({ active, already }) =>
        active === "true" ? "700" : already === "true" ? "400" : "100"};
    font-size: 14px;
    line-height: 20px;

    color: ${({ active, already }) =>
        active === "true"
            ? "#242424"
            : already === "true"
            ? "#242424"
            : "#979593"};
`;

export const Ball = styled(Flex)`
    background-color: ${({ active, already }) =>
        active === "true"
            ? "#5b5fc7"
            : already === "true"
            ? "white"
            : "#FAF9F8"};
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;

export const FlexStyle = styled(Flex)`
    :hover {
        cursor: ${({ active, already }) =>
            active === "true"
                ? "pointer"
                : already === "true"
                ? "pointer"
                : "unset"};
        ${TextStyle} {
            font-weight: ${({ active, already }) =>
                active === "true" ? "700" : already === "true" ? "600" : "100"};
        }
        ${Ball} {
            background-color: ${({ active }) =>
                active === "true" ? "#5b5fc7" : "#FAF9F8"};
        }
    }
`;

export const Number = styled.span`
    font-style: normal;
    font-weight: ${({ active, already }) =>
        active === "true" ? "700" : already === "true" ? "400" : "100"};
    font-size: 14px;
    line-height: 20px;

    color: ${({ active, already }) =>
        active === "true" ? "white" : already === "true" ? "black" : "#979593"};
`;

export const TextPrimary = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    color: #242424;
`;

export const InputStyle = styled(Input)`
    max-width: 600px;
`;

export const MuiPhoneNumberStyle = styled(MuiPhoneNumber)`
    background-color: white;
    width: 100%;
    max-width: 600px;
    border-radius: 4px;
    border: ${({ error }) =>
        error ? "1px solid var(--color-danger) !important" : "0px !important"};
    overflow: hidden !important;

    .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
        border-color: var(--color-primary) !important;
    }

    .MuiInput-underline {
        padding: 2px 8px !important;
    }

    .MuiInput-underline::before {
        border: 0px !important;
    }

    .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
        animation: none !important;
        transition: none !important;
    }
    .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
        transition: none !important;
    }
`;

export const ErrorMessage = styled(InputLabel)`
    color: var(--color-danger);
`;

export const ALink = styled.a`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #5b5fc7;
`;

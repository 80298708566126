import React, { useState } from "react";
import { Button, Flex, Input, InputLabel } from "@fluentui/react-northstar";

import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";

import Card from "../..";
import ContactInformations from "../ContactInformations";
import IllustrationContact from "../../../../../../assets/illustrations/employee_folder/Infos_de_contact.svg";
import { useEmployeeFolderIsMe } from "../../../../store";
import {
    _api_update_my_personal_information,
    _api_update_personal_information,
} from "../../../../api";
import { QUERY_KEY } from "../../../../constants";
import { ErrorMessage } from "../../../../../addEmployee/styles";
import { MuiPhoneNumberStyle } from "../../../../../../common/styles";

const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;

const CardInformationContact = ({ identity_data }) => {
    const clientQuery = useQueryClient();
    const is_me = useEmployeeFolderIsMe();
    const [modeUpdate, setModeUpdate] = useState(false);

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        reset,
    } = useForm({
        mode: "onChange",
        defaultValues: {},
    });

    const {
        mutate: mutate_update_personnal_information,
        isLoading: is_loading_mutate,
    } = useMutation((data) => _api_update_my_personal_information(data));

    const _setModeUpdate = (bool) => {
        if (!bool) {
            reset();
        }
        return setModeUpdate(bool);
    };

    const onSubmit = (data) => {
        mutate_update_personnal_information(
            {
                phone_number: data?.phone_number,
                personal_phone_number: data?.personal_phone_number,
                linkedin_profile: data?.linkedin,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        clientQuery.resetQueries([QUERY_KEY + "::identity"]);
                        clientQuery.refetchQueries(QUERY_KEY + "::header");
                        _setModeUpdate(false);
                    }
                    return;
                },
            }
        );
        return console.log({ data });
    };

    if (modeUpdate) {
        return (
            <Card column>
                <Card.EditTitle
                    content={"Modification des informations personnelles"}
                    onClose={() => _setModeUpdate(false)}
                />
                <Card.Body
                    content={
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex column gap="gap.medium">
                                <Flex column gap="gap.small">
                                    <InputLabel
                                        content={"Téléphone personnel"}
                                    />
                                    <Controller
                                        control={control}
                                        defaultValue={
                                            identity_data?.personal_phone_number
                                        }
                                        render={({
                                            field: { onChange, onBlur },
                                        }) => (
                                            <MuiPhoneNumberEmployee
                                                defaultCountry={"fr"}
                                                disableAreaCodes={true}
                                                onChange={onChange}
                                                value={
                                                    identity_data?.personal_phone_number
                                                }
                                                onBlur={onBlur}
                                                error={
                                                    errors?.personal_phone_number
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                        name="personal_phone_number"
                                    />
                                </Flex>
                                <Flex column gap="gap.small">
                                    <InputLabel
                                        content={"Téléphone professionnel"}
                                    />
                                    <Controller
                                        control={control}
                                        defaultValue={
                                            identity_data?.phone_number
                                        }
                                        render={({
                                            field: { onChange, onBlur },
                                        }) => (
                                            <MuiPhoneNumberEmployee
                                                defaultCountry={"fr"}
                                                disableAreaCodes={true}
                                                value={
                                                    identity_data?.phone_number
                                                }
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={
                                                    errors?.phone_number
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                        name="phone_number"
                                    />
                                </Flex>
                                <Flex column>
                                    <Input
                                        {...register("linkedin", {
                                            pattern: {
                                                value: urlRegex,
                                                message:
                                                    "Ce champs est mal formaté",
                                            },
                                        })}
                                        error={errors?.linkedin?.message}
                                        label="Compte LinkedIn"
                                        defaultValue={identity_data?.linkedin}
                                        fluid
                                    />
                                    <ErrorMessage
                                        content={errors?.linkedin?.message}
                                    />
                                </Flex>
                                <Flex hAlign="center" gap="gap.medium">
                                    <Button
                                        content={"Annuler"}
                                        onClick={() => _setModeUpdate(false)}
                                        flat
                                    />
                                    <Button
                                        content={
                                            "Enregistrer les modifications"
                                        }
                                        loading={is_loading_mutate}
                                        primary
                                        flat
                                    />
                                </Flex>
                            </Flex>
                        </form>
                    }
                />
            </Card>
        );
    }

    return (
        <Card column>
            {/* <Card.Illustration
                image={IllustrationContact}
                positionTop={10}
                canEdit={is_me}
                
            /> */}
            <Card.Title
                content={"Informations de contact"}
                canEdit={is_me}
                onEdit={() => {
                    if (!is_me) {
                        return;
                    }
                    setModeUpdate(true);
                }}
            />
            {/* info perso */}
            <Card.Body
                content={
                    <ContactInformations
                        personal_phone_number={
                            identity_data?.personal_phone_number
                        }
                        phone_number={identity_data?.phone_number}
                        linkedin={identity_data?.linkedin}
                    />
                }
            />
        </Card>
    );
};

export default CardInformationContact;

const MuiPhoneNumberEmployee = styled(MuiPhoneNumberStyle)`
    background-color: #f0f0f0;
`;

import React from "react";
import { Loader } from "@fluentui/react-northstar";
import { Container } from "../style";

const ValidateLoader = () => {
    return (
        <Container vAlign="center" hAlign="center" styles={{ zIndex: 3 }}>
            <Loader size="larger" />
        </Container>
    );
};

export default ValidateLoader;

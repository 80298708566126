import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const DayContainer = styled(Flex)`
    padding: 8px;
    background-color: ${({ today }) => (today ? "#5b5fc7" : "white")};
    border-radius: 8px 8px 0px 0px;
`;

export const HoursContainer = styled(Flex)`
    padding: 8px;
`;

export const Client = styled(Text)`
    color: #616161;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
`;

export const SlotTime = styled(Text)`
    font-weight: 700;
    font-size: 14px;
    color: #242424;
`;

export const CardBody = styled(Flex)`
    max-height: ${({ modify }) => (modify ? "fit-content" : "353px!important")};
    overflow-y: scroll;
    scrollbar-width: thin; // for firefox
    // for others
    ::-webkit-scrollbar {
        width: 12px;
    }
`;

export const HoursContent = styled(Text)`
    color: #242424;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
`;

export const DayNumber = styled(Text)`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${({ today }) => (today ? "white" : "#424242")};
`;

export const DayName = styled(Text)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${({ today }) => (today ? "white" : "#424242")};
`;

export const CardHeader = styled(Flex)`
    box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11),
        0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
    border-radius: 8px 8px 0px 0px;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const Wrapper = styled(Flex)`
    height: ${({ modify }) => (modify ? "fit-content" : "417px")};
    min-height: 417px;
    width: 190px;
    background-color: #ffffff;

    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    border-radius: 8px;
`;

export const TextLeave = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
`;

export const WrapperSlot = styled(Flex)`
    background: #e8ebfa;
    border-radius: 16px;
    padding: 8px 12px;
`;

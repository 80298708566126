import { create } from "zustand";
import { persist } from "zustand/middleware";
import produce from "immer";

export const useAuthStore = create(
    persist(
        (set) => ({
            infos: null, // save only the "access" key, its contain the token of the user
            // login
            setUser: (newState) => {
                set(() => ({ infos: newState }));
            },
        }),
        {
            name: "auth",
        }
    )
);

export const useAuthDetailStore = create(
    persist(
        (set) => ({
            detail: null,
            // login
            setDetailUser: (newState) => {
                set(() => ({ detail: newState }));
            },
            setDetailAvatar: (newAvatar) => {
                set(
                    produce((state) => {
                        state.detail.avatar = newAvatar;
                    })
                );
            },
        }),
        {
            name: "detail",
        }
    )
);

import { BASE_URL_API } from "../../constants";
import fetcher from "../../utils/fetcher";

export const api_get_template_list = async () => {
    return fetcher(`${BASE_URL_API}/task/template/list`, {
        method: "GET",
        credentials: "include",
    });
};

export const api_get_tasks_of_template = async ({ task_template_id }) => {
    return fetcher(`${BASE_URL_API}/task/template/${task_template_id}`, {
        method: "GET",
        credentials: "include",
    });
};

import styled from "styled-components";
import { Flex, Text } from "@fluentui/react-northstar";

export const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    color: #252423;
`;

export const Card = styled(Flex)`
    background: #ffffff;
    padding: 16px;
    border: 1px solid #edebe9;
    border-radius: 2px;
`;

export const Wrapper = styled(Flex)`
    background: #e8ebfa;
    border-radius: 4px;
    padding: 12px;
    gap: 12px;
    align-self: flex-end;
    width: 480px;
    /* min-height: 212px; */
`;

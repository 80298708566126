import React from "react";
import { Button, Flex, Image, Text } from "@fluentui/react-northstar";
import styled from "styled-components/macro";
import CustomBreadCrumb from "../../components/breadcrumb";
import { BASE_URL_ILLUSTRATIONS } from "../../constants";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const NotFound = ({ hide_drawer = false, homeLink = "/" }) => {
    const navigate = useNavigate();
    const { t } = useTranslation("404");

    return (
        <>
            <CustomBreadCrumb
                hide_drawer={hide_drawer}
                navList={[
                    {
                        label: t("breadcrumb.home"),
                        href: homeLink,
                    },
                    { label: t("breadcrumb.not_found"), href: "/404" },
                ]}
            />

            <Flex
                column
                vAlign="center"
                hAlign="center"
                gap="gap.medium"
                grow
                fill
                styles={{
                    paddingTop: "50px",
                }}
            >
                <Image src={`${BASE_URL_ILLUSTRATIONS}/404.svg`} />
                <Flex column hAlign="center" gap="gap.small">
                    <Label>{t("hero.title")}</Label>
                    <Message>
                        <Trans i18nKey="hero.description" t={t}>
                            Le contenu que vous cherchez ne se trouve pas ici.{" "}
                            <br />
                            Cela peut être dû à de nombreuses raisons, dans tous
                            les cas nous vous invitons à revenir à <br />
                            l’accueil à l’aide du bouton ci-dessous.
                        </Trans>
                    </Message>
                    <Button
                        content={t("hero.button.back")}
                        primary
                        flat
                        size="large"
                        styles={{
                            marginTop: "30px",
                            width: "280px",
                            height: "32px",
                        }}
                        onClick={() => navigate("/")}
                    />
                </Flex>
            </Flex>
        </>
    );
};

export default NotFound;

const Message = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #242424;
`;

const Label = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
`;

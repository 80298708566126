import React from "react";
import { CustomBadge } from "../../../../components/Badge";

export const STATUS_ROLE = {
    accounting_manager: "ACCOUNTING_MANGER",
    manager: "MANAGER",
    employee: "EMPLOYEE",
};

const COLOR_DEPEND_ROLE = {
    to_validate: {
        [STATUS_ROLE.employee]: {
            content: "À valider",
            bg: "#F0F0F0",
            color: "#242424",
        },
        [STATUS_ROLE.manager]: {
            content: "À valider",
            bg: "#FFAA44",
            color: "white",
        },
        [STATUS_ROLE.accounting_manager]: {
            content: "",
            bg: "white",
            color: "white",
        },
    },
    to_verify: {
        [STATUS_ROLE.employee]: {
            content: "À vérifier",
            bg: "#F0F0F0",
            color: "#242424",
        },
        [STATUS_ROLE.manager]: {
            content: "À vérifier",
            bg: "#F0F0F0",
            color: "#242424",
        },
        [STATUS_ROLE.accounting_manager]: {
            content: "À vérifier",
            bg: "#FFAA44",
            color: "white",
        },
    },
    approved: {
        [STATUS_ROLE.employee]: {
            content: "Approuvée",
            bg: "#E7F2DA",
            color: "#13A10E",
            border: "#BDDA9B",
        },
        [STATUS_ROLE.manager]: {
            content: "Approuvée",
            bg: "#E7F2DA",
            color: "#13A10E",
            border: "#BDDA9B",
        },
        [STATUS_ROLE.accounting_manager]: {
            content: "Approuvée",
            bg: "#E7F2DA",
            color: "#13A10E",
            border: "#BDDA9B",
        },
    },
    refused: {
        [STATUS_ROLE.employee]: {
            content: "Refusée",
            bg: "#FCF4F6",
            color: "#C4314B",
            border: "#F3D6D8",
        },
        [STATUS_ROLE.manager]: {
            content: "Refusée",
            bg: "#FCF4F6",
            color: "#C4314B",
            border: "#F3D6D8",
        },
        [STATUS_ROLE.accounting_manager]: {
            content: "Refusée",
            bg: "#FCF4F6",
            color: "#C4314B",
            border: "#F3D6D8",
        },
    },
};

/**
 * @typedef {Object} TypeProps
 * @property {number} status // status id of report
 * @property {string} role // use STATUS_ROLE constant
 *
 * @param {TypeProps} Props
 * @returns
 */
const StatusActionsDependRole = ({ status, role }) => {
    let configButton = {
        content: "",
        color: "",
        bg: "",
        border: "transparent",
    };
    switch (status) {
        case 1: {
            // approuvée
            const current = COLOR_DEPEND_ROLE.approved[role];
            configButton.content = current.content;
            configButton.bg = current.bg;
            configButton.color = current.color;
            configButton.border = current.border;
            break;
        }
        case 2: {
            // a valider
            const current = COLOR_DEPEND_ROLE.to_validate[role];
            configButton.content = current.content;
            configButton.bg = current.bg;
            configButton.color = current.color;
            break;
        }
        case 3: {
            // a verifier
            const current = COLOR_DEPEND_ROLE.to_verify[role];
            configButton.content = current.content;
            configButton.bg = current.bg;
            configButton.color = current.color;
            break;
        }
        case 4: {
            // refusée
            const current = COLOR_DEPEND_ROLE.refused[role];
            configButton.content = current.content;
            configButton.bg = current.bg;
            configButton.color = current.color;
            configButton.border = current.border;
            break;
        }

        default:
            break;
    }
    return (
        <CustomBadge
            bg={configButton.bg}
            color={configButton.color}
            label={configButton.content}
            rectangular
            style={{
                border: `2px solid ${configButton.border}`,
            }}
        />
    );
};

export default StatusActionsDependRole;

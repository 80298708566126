import { MoreIcon } from "@fluentui/react-northstar";
import { ROUTES } from "../../../constants/routes";

/**
 * STRINGS
 */
export const I18N_NS_EXPENSE = "expenses";
export const QUERY_KEY = "EXPENSES";

/**
 * OBJECTS
 */
export const BREADCRUMB_EXPENSE_DEFAULT = [
    {
        label: "breadcrumb.home",
        href: ROUTES.home,
    },
    {
        label: "breadcrumb.expenses",
        href: ROUTES.expenses.home,
    },
];
export const BREADCRUMB_EXPENSE_DETAIL_PAGE = [
    {
        label: <MoreIcon />,
        href: "#",
        icon: true,
    },
    {
        label: "breadcrumb.expenses",
        href: ROUTES.expenses.home,
    },
    {
        label: "Détail",
        href: ROUTES.expenses.detail,
    },
];
export const BREADCRUMB_HUMBERGER_LOCAL = [
    {
        id: 1,
        name: "Accueil",
        link: "/",
    },
    {
        id: 2,
        name: "Mes tâches",
        link: "/dashboard/tasks",
    },
    {
        id: 3,
        name: "Gestion du temps",
        link: "/manage_time",
    },
];

export const VALIDATORS_ADD_NOTE_REPORT = {
    title: {
        required: {
            value: true,
            message: "home.dialog_add_note.errors.title.required",
        },
    },
    client: {
        required: {
            value: true,
            message: "home.dialog_add_note.errors.client.required",
        },
    },
};

export const VALIDATORS_ADD_EXPENSE = {
    title: {
        required: {
            value: true,
            message: "forms.classic.errors.title.required",
        },
        minLength: {
            value: 3,
            message: "forms.classic.errors.title.minLength",
        },
        maxLength: {
            value: 30,
            message: "forms.classic.errors.title.maxLength",
        },
    },
    date: {
        required: {
            value: true,
            message: "forms.classic.errors.date.required",
        },
    },
    montant_ttc: {
        required: {
            value: true,
            message: "forms.classic.errors.montant_ttc.required",
        },
        min: {
            value: 0,
            message: "forms.classic.errors.montant_ttc.min",
        },
        validate: {
            validateNumber: (v) =>
                !isNaN(v) || "forms.classic.errors.montant_ttc.required",
        },
    },
    montant_tva: {
        required: {
            value: true,
            message: "forms.classic.errors.montant_tva.required",
        },
    },
    comment: {
        maxLength: {
            value: 200,
            message: "forms.classic.errors.comment.maxLength",
        },
    },
    taux_tva: {
        required: {
            value: true,
            message: "forms.classic.errors.taux_tva.required",
        },
    },
};

export const VALIDATORS_ADD_EXPENSE_KM_CH = {
    title: {
        required: {
            value: true,
            message: "forms.km_ch.errors.title.required",
        },
        minLength: {
            value: 3,
            message: "forms.km_ch.errors.title.minLength",
        },
        maxLength: {
            value: 30,
            message: "forms.km_ch.errors.title.maxLength",
        },
    },
    date: {
        required: {
            value: true,
            message: "forms.km_ch.errors.date.required",
        },
    },
    departure: {
        required: {
            value: true,
            message: "forms.km_ch.errors.departure.required",
        },
    },
    arrival: {
        required: {
            value: true,
            message: "forms.km_ch.errors.arrival.required",
        },
    },
    distance: {
        required: {
            value: true,
            message: "forms.km_ch.errors.distance.required",
        },
        validate: {
            positive: (value) =>
                parseInt(value) > 0 || "forms.km_ch.errors.distance.positive",
        },
    },
    comment: {
        maxLength: {
            value: 200,
            message: "forms.km_ch.errors.comment.maxLength",
        },
    },
};

export const VALIDATORS_ADD_EXPENSE_KM_FR = {
    title: {
        required: {
            value: true,
            message: "forms.km_fr.errors.title.required",
        },
        minLength: {
            value: 3,
            message: "forms.km_fr.errors.title.minLength",
        },
        maxLength: {
            value: 30,
            message: "forms.km_fr.errors.title.maxLength",
        },
    },
    date: {
        required: {
            value: true,
            message: "forms.km_fr.errors.date.required",
        },
    },
    departure: {
        required: {
            value: true,
            message: "forms.km_fr.errors.departure.required",
        },
    },
    arrival: {
        required: {
            value: true,
            message: "forms.km_fr.errors.arrival.required",
        },
    },
    distance: {
        required: {
            value: true,
            message: "forms.km_fr.errors.distance.required",
        },
        validate: {
            positive: (value) =>
                parseInt(value) > 0 || "forms.km_ch.errors.distance.positive",
        },
    },
    power: {
        required: {
            value: true,
            message: "forms.km_fr.errors.power.required",
        },
    },
    comment: {
        maxLength: {
            value: 200,
            message: "forms.km_fr.errors.comment.maxLength",
        },
    },
};

export const TYPE_FORM = {
    classic: "classic",
    km_fr: "km_fr",
    km_ch: "km_ch",
};

export const TYPE_ENERGY = [
    {
        key: 1,
        value: "therm",
        name: "therm",
        label: "forms.km_fr.radios.label.thermic",
        defaultChecked: true,
    },
    {
        key: 2,
        value: "elec",
        name: "elec",
        label: "forms.km_fr.radios.label.electric",
    },
];

export const POWER_LIST_AUTO = [
    {
        id: 1,
        header: "3 CV et moins",
        value: 3,
    },
    {
        id: 2,
        header: "4 CV",
        value: 4,
    },
    {
        id: 3,
        header: "5 CV",
        value: 5,
    },
    {
        id: 4,
        header: "6 CV",
        value: 6,
    },
    {
        id: 5,
        header: "7 CV et plus",
        value: 7,
    },
];

export const POWER_LIST_MOTO = [
    {
        id: 1,
        header: "1 ou 2 CV",
        value: 1,
    },
    {
        id: 2,
        header: "3 a 5 CV",
        value: 4,
    },
    {
        id: 3,
        header: "Plus de 5 CV",
        value: 6,
    },
];

export const TYPE_VEHICULE = [
    {
        id: 1,
        value: "auto",
        label: "forms.km_fr.type_vehicule.auto",
        dropdown_power: {
            show: true,
            values: POWER_LIST_AUTO,
        },
    },
    {
        id: 2,
        value: "moto",
        label: "forms.km_fr.type_vehicule.moto",
        dropdown_power: {
            show: true,
            values: POWER_LIST_MOTO,
        },
    },
    {
        id: 3,
        value: "cyclo",
        label: "forms.km_fr.type_vehicule.cyclo",
        dropdown_power: {
            show: false,
            values: [],
        },
    },
];

export const CURRENCIES = [
    {
        content: "€ (Euro - EUR)",
        value: "EUR",
    },
    {
        content: "CHF (Swiss franc - CHF)",
        value: "CHF",
    },
    {
        content: "$ (US dollar - USD)",
        value: "USD",
    },
];

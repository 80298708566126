import { Flex, Text } from "@fluentui/react-northstar";
import styled, { css } from "styled-components";

export const Wrapper = styled(Flex)`
    border-radius: 4px;
    background: #fff;

    width: 240px;
    height: 60px;

    padding: 2px;

    /* shadow-2 */
    box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13),
        0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11);

    ${({ $selected }) =>
        $selected
            ? css`
                  background: linear-gradient(to bottom, #7bb3eb, #787bd2);
              `
            : css`
                  background: none;
              `}
    ${({ $disabled }) =>
        $disabled
            ? css`
                  opacity: 0.3;
                  cursor: not-allowed;
              `
            : css`
                  opacity: 1;
                  cursor: default;
              `}
`;

export const Content = styled(Flex)`
    background-color: white;
    padding: 14px 10px;
    border-radius: 4px;
    width: 100%;
`;

export const HeaderContent = styled(Text)`
    color: #242424;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
`;

export const HeaderDescription = styled(Text)`
    color: #616161;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

export const BadgeSubscription = styled(Text)`
    color: #5b5fc7;

    display: flex;
    height: 16px;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 2px;

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    border-radius: 100px;
    background: #e8ebfa;
`;

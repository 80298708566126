import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { format_number } from "../../../../../constants/maths";

const HOUR_COUNTER_STATUS = {
    positive: {
        border: "white",
        bg: "#E7F2DA",
        color: "#13A10E",
    },
    negative: {
        border: "#F3D6D8",
        bg: "#FCF4F6",
        color: "#C4314B",
    },
};

const HourCounter = ({ addionalHourWorkedBadge, prefix = "" }) => {
    const fn_hours = format_number(parseFloat(addionalHourWorkedBadge));
    const isPositive = parseFloat(addionalHourWorkedBadge) >= 0;
    const { border, bg, color } = isPositive
        ? HOUR_COUNTER_STATUS.positive
        : HOUR_COUNTER_STATUS.negative;

    const content = isPositive ? "+ " + fn_hours : fn_hours;
    return (
        <HourCounterFlex hAlign="center" border={border} bg={bg}>
            <HourCounterText color={color} content={content + prefix} />
        </HourCounterFlex>
    );
};

export default HourCounter;

const HourCounterFlex = styled(Flex)`
    min-width: 39px;
    padding: 2px 4px;
    border-radius: 2px;
    background: ${({ bg }) => (bg ? bg : "")};
    border: 1px solid ${({ border }) => (border ? border : "")};
    border-radius: 4px;
`;
const HourCounterText = styled(Text)`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${({ color }) => (color ? color : "")};
`;

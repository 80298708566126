import {
    Alert,
    Divider,
    ExclamationTriangleIcon,
    Flex,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import React from "react";
import {
    CardDetailWeek,
    ContractualHours,
    Dates,
    DeclaredMonth,
    Graph,
    Hours,
    Label,
    Left,
    Right,
    Title,
    TotalHours,
    WrapperCards,
} from "../DialogSubmitMonth/index.style";
import { useTranslation } from "react-i18next";
import {
    BAR_GRAPH_MONTH_SUBMIT,
    I18N_NS_TIME_REPORTING,
} from "../../constants";
import moment from "moment";
import BadgeWeek from "../BadgeWeek";
import HourCounter from "../MyTeam/HourBadge/HourCounter";
import { CustomBadge } from "../../../../components/Badge";

const ContentDetailMonth = ({
    special_days = [],
    weeks = [],
    declared_hours_count = 0,
    expected_hours_count = 0,
    difference = 0,
    isExistsPendingLeaveRequest = false,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    const total_special_days = special_days?.reduce((prev, curr) => {
        return prev + curr.value;
    }, 0);

    const percent = (declared_hours_count * 100) / expected_hours_count;
    const height = (percent * BAR_GRAPH_MONTH_SUBMIT) / 100;

    return (
        <Flex column gap="gap.large">
            <Flex styles={{ marginTop: "2rem" }} gap="gap.large" fill>
                {isExistsPendingLeaveRequest && (
                    <Alert
                        content="Des absences non-validées sont présentes sur votre feuille de temps"
                        icon={<ExclamationTriangleIcon />}
                        visible
                        warning
                        fluid
                        styles={{
                            width: "100%",
                        }}
                    />
                )}
            </Flex>
            <Flex gap="gap.large">
                <Left column gap="gap.medium">
                    <Title
                        content={t(
                            "v2.home.dialogs.contents.section_titles.by_week"
                        )}
                    />
                    <WrapperCards wrap fill gap="gap.medium">
                        {weeks?.map((week) => {
                            const week_number = week.week_number;
                            const difference = week.difference;
                            const declared_hours_count =
                                week.declared_hours_count;
                            const monday = moment(week.monday).format("DD/MM");
                            const sunday = moment(week.sunday).format("DD/MM");
                            return (
                                <CardDetailWeek column>
                                    <Flex space="between" fill>
                                        <BadgeWeek
                                            status={"default"}
                                            week={week_number}
                                        />
                                        <HourCounter
                                            addionalHourWorkedBadge={difference}
                                            prefix=" h"
                                        />
                                    </Flex>
                                    <Flex space="between" fill>
                                        <Dates
                                            content={`${monday} - ${sunday}`}
                                        />
                                        <TotalHours
                                            content={`${declared_hours_count} h`}
                                        />
                                    </Flex>
                                </CardDetailWeek>
                            );
                        })}
                    </WrapperCards>
                </Left>
                <Divider vertical />
                <Right column gap="gap.large">
                    <Title
                        content={t(
                            "v2.home.dialogs.contents.section_titles.per_month"
                        )}
                    />
                    <Flex column gap="gap.medium">
                        {/* graphs */}
                        <Flex gap="gap.medium" vAlign="end">
                            <Graph primary progress={height} />
                            <Graph progress={BAR_GRAPH_MONTH_SUBMIT} />
                        </Flex>
                        <Flex gap="gap.large" vAlign="center">
                            <DeclaredMonth column>
                                <Hours content={`${declared_hours_count} h`} />
                                <Label
                                    content={t("v2.home.labels.declared_hours")}
                                />
                            </DeclaredMonth>
                            <ContractualHours column>
                                <Hours content={`${expected_hours_count} h`} />
                                <Label
                                    content={t(
                                        "v2.home.labels.hours_contractuals"
                                    )}
                                />
                            </ContractualHours>
                        </Flex>
                        <Flex gap="gap.smaller">
                            <Text content="Récupération" />
                            <HourCounter
                                addionalHourWorkedBadge={difference}
                                prefix=" h"
                            />
                        </Flex>
                    </Flex>
                </Right>
            </Flex>
            <Flex column gap="gap.small">
                <Flex gap="gap.medium">
                    {special_days?.map((day) => {
                        if (day.is_homeoffice_travel) {
                            // teletravail ou deplacement
                            const sentence = `Pour plus de détail, voir l’onglet de télétravail`;
                            return (
                                <Tooltip
                                    pointing
                                    subtle={false}
                                    content={sentence}
                                    position="after"
                                    align="center"
                                    trigger={
                                        <CustomBadge
                                            bg={day.color}
                                            color="black"
                                            size="medium"
                                            label={
                                                <Flex
                                                    vAlign="center"
                                                    gap="gap.small"
                                                >
                                                    <Text content={day.name} />
                                                    <CustomBadge
                                                        bg="black"
                                                        color="white"
                                                        size="smaller"
                                                        label={`${day.value} j`}
                                                        rectangular={true}
                                                        style={{
                                                            borderRadius: "4px",
                                                        }}
                                                    />
                                                </Flex>
                                            }
                                            rectangular={true}
                                            style={{
                                                borderRadius: "4px",
                                            }}
                                        />
                                    }
                                />
                            );
                        } else {
                            return (
                                <CustomBadge
                                    bg={day.color}
                                    color="black"
                                    size="medium"
                                    label={
                                        <Flex vAlign="center" gap="gap.small">
                                            <Text content={day.name} />
                                            <CustomBadge
                                                bg="black"
                                                color="white"
                                                size="smaller"
                                                label={`${day.value} j`}
                                                rectangular={true}
                                                style={{
                                                    borderRadius: "4px",
                                                }}
                                            />
                                        </Flex>
                                    }
                                    rectangular={true}
                                    style={{
                                        borderRadius: "4px",
                                    }}
                                />
                            );
                        }
                    })}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ContentDetailMonth;

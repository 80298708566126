import React from "react";
import { Flex, PersonIcon, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../constants/responsive";

/**
 * @param {{ counter: number }} counter number of users
 * @returns {React.JSX}
 */
const DepartmentCounter = ({ counter }) => {
    return (
        <Wrapper vAlign="center">
            <PersonIcon filled size="small" />
            <Counter content={counter} />
        </Wrapper>
    );
};

export default DepartmentCounter;

const Wrapper = styled(Flex)`
    padding: 2px 6px;
    border-radius: 4px;
    background: #fbf6d9;
    gap: 2px;
`;

const Counter = styled(Text)`
    color: #424242;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
`;

import {
    ApprovalsAppbarIcon,
    Button,
    Flex,
    MenuButton,
    MoreIcon,
    SendIcon,
    Text,
} from "@fluentui/react-northstar";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

/**
 * @typedef {Object} PropsStatusLeaveDetail
 * @property {number} user_id
 * @property {string} date - date
 * @property {string} email - email
 * @property {boolean} isLoading
 * @property {function} [onAcceptOrRefuse]
 *
 * @param {PropsStatusLeaveDetail} props
 * @returns
 */
const StatusLeaveDetail = ({
    date,
    email,
    user_id,
    isLoading,
    onAcceptOrRefuse = () => null,
}) => {
    const [open, setOpen] = useState(false);
    return (
        <Flex column hAlign="end" styles={{ gap: "8px" }}>
            <SentAtDate
                content={`Envoyée le ${moment(date).format("DD/MM/YYYY")}`}
            />
            <WrapperButton vAlign="center">
                <ApprovalsAppbarIcon outline size="smaller" />
                <ButtonText content="En attente de validation" />
                <MenuButton
                    open={open}
                    styles={{ cursor: "pointer" }}
                    onOpenChange={(e, { open }) => {
                        if (isLoading) return;
                        setOpen(open);
                    }}
                    trigger={<MoreIcon size="smaller" outline />}
                    title="Open"
                    menu={[
                        <Button
                            content="Renvoyer le mail"
                            icon={<SendIcon />}
                            loading={isLoading}
                            onClick={() => {
                                onAcceptOrRefuse({
                                    type: "remind",
                                    users: [user_id],
                                    message: "Relance envoyée avec succès",
                                });
                            }}
                            text
                        />,
                    ]}
                />
            </WrapperButton>
            <Flex vAlign="center" styles={{ gap: "4px" }}>
                <EmailText content="En attente de " />
                <Email content={email} />
            </Flex>
        </Flex>
    );
};

export default StatusLeaveDetail;

const Email = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Brand-Foreground, #5b5fc7);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
`;

const EmailText = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

export const ButtonText = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
`;

export const WrapperButton = styled(Flex)`
    height: 25px;
    border-radius: 4px;
    border: 1px solid var(--Light-theme-Rest-Border-Default-Border, #d1d1d1);

    height: 24px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    background: var(
        --Light-theme-Rest-Background-Default-Background-3,
        #f0f0f0
    );
`;

const SentAtDate = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
`;

import React, { useState } from "react";
import {
    AddIcon,
    Button,
    CloseIcon,
    Dialog,
    DialogFooter,
    Flex,
    Loader,
    MenuButton,
    MoreIcon,
    SendIcon,
    Text,
} from "@fluentui/react-northstar";
import { Comment16Filled, Comment20Regular } from "@fluentui/react-icons";
import BadgeMonthDeclaration from "../badges/BadgeMonthDeclaration";
import styled from "styled-components";
import { format_number } from "../../../../constants/maths";
import { useMutation } from "react-query";
import { _api_resend_email_for_approval } from "../../api";
import { STATUS_TOAST } from "../../constants";

const SecondRow = ({
    relance,
    content,
    comment,
    status,
    data,
    total,
    code,
    id_user,
    id,
    show_toast,
}) => {
    const { mutate, isLoading } = useMutation(
        (data) => _api_resend_email_for_approval(data),
        {
            onSuccess: (data) => {
                if (data?.success) {
                    return show_toast(STATUS_TOAST.resent_mail);
                }
            },
        }
    );

    return (
        <Flex gap="gap.large" vAlign="center">
            {/* code projet + status Button + more icon   */}
            <Flex
                gap="gap.small"
                space="between"
                styles={{ width: "303px" }}
                vAlign="center"
            >
                <Flex vAlign="center" gap="gap.medium">
                    {id === -1 ? (
                        <CodeProjet content={"Absence"} />
                    ) : (
                        <CodeProjet content={code && `${code} (${content}) `} />
                    )}
                </Flex>
                {/* Menu button to relance sending data to the client if envoyer  */}
                <Flex hAlign="end" gap="gap.small">
                    <StatusTimeSheet status={status} comment={comment} />
                    {relance && (
                        <Flex>
                            {isLoading ? (
                                <Loader size="smallest" />
                            ) : (
                                <MenuButton
                                    style={{ cursor: "pointer" }}
                                    trigger={<MoreIcon rotate={90} />}
                                    menu={[
                                        <Button
                                            text
                                            content={"Renvoyer au client"}
                                            loading={isLoading}
                                            icon={<SendIcon outline />}
                                            onClick={() =>
                                                mutate({
                                                    id,
                                                    id_user,
                                                })
                                            }
                                        />,
                                    ]}
                                    on="click"
                                />
                            )}
                        </Flex>
                    )}
                </Flex>
            </Flex>
            {/* contenu de tableau second row  */}
            <Flex
                gap="gap.large"
                styles={{
                    paddingBlock: "14px",
                    backgroundColor: "#FAFAFA",
                    textAlign: "center",
                }}
            >
                {data?.map((el, i) => {
                    return (
                        <Flex
                            key={i}
                            styles={{
                                width: "120px",
                                paddingLeft: "10px",
                            }}
                            hAlign="center"
                            vAlign="center"
                            gap="gap.smaller"
                        >
                            <Text content={format_number(el) + " h"} />
                        </Flex>
                    );
                })}
            </Flex>
            {/* le total column */}
            <Flex
                hAlign="center"
                styles={{
                    width: "160px",
                    paddingBlock: "12px",
                    fontWeight: "bold",
                }}
            >
                <Text
                    content={
                        <Flex hAlign="center" vAlign="center" gap="gap.smaller">
                            <Text content={format_number(total) + " h"} />
                        </Flex>
                    }
                />
            </Flex>
        </Flex>
    );
};

export default SecondRow;

const StatusTimeSheet = ({ status, comment = "" }) => {
    switch (status) {
        case 0:
            return (
                <Text
                    styles={{
                        border: "1px solid #edc2a7",
                        backgroundColor: "#FBF6D9",
                        color: "#CC4A31",
                        padding: "3px 8px",
                        borderRadius: "4px",
                        fontWeight: "600",
                        fontSize: "12px",
                        lineHeight: "16px",
                    }}
                    content={"Incomplet"}
                />
            );
        case 1:
            return (
                <Text
                    styles={{
                        border: "1px solid #d1d1d1",
                        backgroundColor: "#EBEBEB ",
                        color: "#424242",
                        padding: "3px 8px",
                        borderRadius: "4px",
                    }}
                    content={"À valider"}
                />
            );
        case 2:
            return (
                <Text
                    styles={{
                        border: "1px solid #B4009E",
                        backgroundColor: "#E1BEDD",
                        color: "#B4009E",
                        padding: "3px 8px",
                        borderRadius: "4px",
                    }}
                    content={"Envoyé"}
                />
            );
        case 3:
            return (
                <Text
                    styles={{
                        border: "1px solid #bdda9b",
                        backgroundColor: "#E7F2DA",
                        color: "#13A10E",
                        padding: "3px 8px",
                        borderRadius: "4px",
                    }}
                    content={"validé"}
                />
            );

        case 4:
            return (
                <Dialog
                    header="Commentaire"
                    content={comment}
                    confirmButton={"Fermer"}
                    trigger={
                        <Flex
                            vAlign="center"
                            gap="gap.small"
                            styles={{
                                border: "1px solid #f3d6d8",
                                backgroundColor: "#FCF4F6",
                                color: "#C4314B",
                                padding: "3px 8px",
                                borderRadius: "4px",
                            }}
                        >
                            <Text content={"Refusé"} />
                            <Comment16Filled styles={{ cursor: "pointer" }} />
                        </Flex>
                    }
                />
            );

        default:
            <Text content={"default"} />;
            break;
    }

    return <Text content={status} />;
};

const CodeProjet = styled(Text)`
    width: 149px;
`;

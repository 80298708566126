import React, { useState, useRef, useCallback } from "react";
import {
    Button,
    Checkbox,
    CloseIcon,
    Dialog,
    Dropdown,
    Flex,
    Input,
    InputLabel,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE, QUERY_KEY } from "../../../../constants";
import { useMutation, useQuery } from "react-query";
import {
    _api_approve_expense,
    _api_approve_expense_accounting_manager,
    _api_get_clients,
    _api_get_code_budget,
} from "../../../../api";
import { TextAmount } from "./styles";

const AcceptAnotherAmountExpense = ({
    id_report,
    id_user,
    amount,
    currency,
    isAccountingManager = false,
    reinvoiceable = false,
    client = null,
    onAccept = () => {},
}) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const commentRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [codeSelected, setCodeSelected] = useState(null);
    const [amountApproved, setAmountApproved] = useState(amount);
    const [reinvoiceableToggle, setReinvoiceableToggle] =
        useState(reinvoiceable);
    const [clientSelected, setClientSelected] = useState(client ?? null);

    const _openDialog = () => {
        setCodeSelected(null);
        setAmountApproved(amount);
        return setOpen(true);
    };

    const _closeDialog = () => {
        setAmountApproved(amount);
        setCodeSelected(null);
        setReinvoiceableToggle(reinvoiceable);
        setClientSelected(client ?? null);
        return setOpen(false);
    };

    const _confirmApproveExpense = () => {
        const comment = commentRef.current.value;
        const code_budget = codeSelected?.id;
        if (isAccountingManager) {
            mutateAccountingManager({
                id_user,
                id_expense_report: id_report,
                comment,
                code_budget,
                amount_to_approve: parseFloat(amountApproved),
            });
        } else {
            mutate({
                id_user,
                id_expense_report: id_report,
                comment,
                code_budget,
                amount_to_approve: parseFloat(amountApproved),
                reinvoiceable,
                client_id: clientSelected?.id ?? null,
            });
        }

        return;
    };

    // avoir la liste des clients *
    const { data: clients, isLoading: is_loading_clients } = useQuery(
        QUERY_KEY + "::clients",
        () => _api_get_clients(),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            onSuccess: (data) => {},
            enabled: !isAccountingManager,
        }
    );

    const {
        data: code_budget,
        isLoading: isLoadingCodeBudget,
        isFetching: isFetchingCodeBudget,
    } = useQuery(
        [QUERY_KEY + "::code_budgetaire"],
        () =>
            _api_get_code_budget({
                id_user,
            }),
        {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                if (data?.length) {
                    const default_object_code = data?.find(
                        (cb) => cb.default_object_code
                    );
                    setCodeSelected({
                        id: default_object_code?.id,
                        header: `${default_object_code?.name} ( ${default_object_code?.code} )`,
                        code: default_object_code?.code,
                        selected: default_object_code?.default_object_code,
                    });
                }
                return;
            },
        }
    );

    const codes = code_budget?.map((el) => {
        return {
            id: el?.id,
            header: `${el?.name} ( ${el?.code} )`,
            code: el?.code,
            selected: codeSelected?.id === el?.id,
        };
    });

    const clients_formated = clients?.map((el) => {
        return {
            id: el?.id,
            header: `${el?.name}`,
            selected: el?.id === clientSelected?.id,
        };
    });

    const default_budget = codes?.find((el) => el?.selected);

    const { mutate, isLoading } = useMutation(
        (data) => _api_approve_expense(data),
        {
            onSuccess: (data) => {
                if (data?.success) {
                    return onAccept([data, null]);
                } else {
                    return onAccept([null, data?.message]);
                }
            },
        }
    );

    const {
        mutate: mutateAccountingManager,
        isLoading: isLoadingAccountingManager,
    } = useMutation((data) => _api_approve_expense_accounting_manager(data), {
        onSuccess: (data) => {
            if (data?.success) {
                return onAccept([data, null]);
            } else {
                return onAccept([null, data?.message]);
            }
        },
    });

    return (
        <Dialog
            open={open}
            cancelButton={{
                content: t("common.cancel"),
                flat: true,
            }}
            confirmButton={{
                loading: isLoading || isLoadingAccountingManager,
                content: t("common.confirm"),
                flat: true,
            }}
            content={
                <Flex
                    fill
                    column
                    gap="gap.medium"
                    styles={{ marginTop: "15px", paddingBottom: "15px" }}
                >
                    <Flex column fill>
                        <InputLabel
                            content={t(
                                "manager.detail.dialogs.accept.fields.total"
                            )}
                        />
                        <TextAmount content={amount + " " + currency} />
                    </Flex>
                    <Input
                        label={t(
                            "manager.detail.dialogs.accept.amount_reimbursed"
                        )}
                        defaultValue={amountApproved}
                        onChange={(e) => setAmountApproved(e.target.value)}
                    />
                    <Flex column fill>
                        {isLoadingCodeBudget || isFetchingCodeBudget ? (
                            <Loader />
                        ) : (
                            codes?.length > 0 && (
                                <Flex column fill>
                                    <InputLabel
                                        content={t(
                                            "manager.detail.dialogs.accept.fields.code_budgetaire"
                                        )}
                                    />
                                    <Dropdown
                                        items={codes}
                                        defaultValue={default_budget}
                                        onChange={(e, i) =>
                                            setCodeSelected(i?.value)
                                        }
                                        checkable
                                        getA11ySelectionMessage={{
                                            onAdd: (item) =>
                                                `${item} has been selected.`,
                                        }}
                                    />
                                </Flex>
                            )
                        )}
                    </Flex>
                    <Input
                        label={t("manager.detail.dialogs.accept.comment")}
                        ref={commentRef}
                        fluid
                        clearable
                    />
                    {/* toggle refacturable client */}
                    <Checkbox
                        label="Re-facturable au client"
                        toggle
                        defaultChecked={reinvoiceableToggle}
                        onChange={(e, i) => setReinvoiceableToggle(i?.checked)}
                    />
                    {/* les clients a facturer */}
                    {reinvoiceableToggle && (
                        <Flex column styles={{ position: "relative" }}>
                            <InputLabel content={"Client à facturer"} />
                            {is_loading_clients ? (
                                <Loader />
                            ) : (
                                <Dropdown
                                    items={clients_formated}
                                    defaultValue={clients_formated?.find(
                                        (el) => el.selected
                                    )}
                                    onChange={(e, i) =>
                                        setClientSelected(i?.value)
                                    }
                                    checkable
                                    fluid
                                    getA11ySelectionMessage={{
                                        onAdd: (item) =>
                                            `${item} has been selected.`,
                                    }}
                                />
                            )}
                        </Flex>
                    )}
                </Flex>
            }
            header={t("manager.detail.dialogs.approve_wnother_amount.title")}
            headerAction={{
                icon: <CloseIcon />,
                title: t("common.close"),
                onClick: _closeDialog,
            }}
            onCancel={_closeDialog}
            onConfirm={_confirmApproveExpense}
            closeOnOutsideClick={false}
            trigger={
                <Button
                    content={
                        <Text styles={{ textDecoration: "underline" }}>
                            {t("manager.detail.approbation.other_amount")}
                        </Text>
                    }
                    onClick={_openDialog}
                    primary
                    text
                />
            }
        />
    );
};

export default AcceptAnotherAmountExpense;

import React from "react";
import {
    Button,
    CalendarIcon,
    Flex,
    PersonIcon,
    Table,
    TableCell,
    TableRow,
} from "@fluentui/react-northstar";
import AvatarUser from "../../../components/Avatars";
import MoreMenuItem from "./MoreMenuItem";
import MoreMenuItemModifyTemplate from "./MoreMenuItemModifyTemplate";
import { useFormContext } from "react-hook-form";
import { AVATAR_DEFAULT } from "../../../constants";
import { WrapperTable } from "./TableOfTaksAdded";

const _getRegleDate = (duedate_str, el) => {
    if (duedate_str) {
        return duedate_str;
    }

    if (el?.temp?.duedate_duration) {
        return `${el?.temp?.duedate_duration} jours ${
            parseInt(el?.temp?.duedate_operator) === 1 ? "avant" : "après"
        } ${el?.temp?.duedate_event?.header}`;
    }
};

const TableOfTasksForModify = ({
    tasks = [],
    setTemplateTasks,
    setOpenDialogModifyTask,
    setToastConfig,
}) => {
    const { setValue } = useFormContext();

    return (
        <WrapperTable column fill>
            <Table styles={{ width: "630px", minHeight: "155px" }}>
                <TableRow header>
                    <TableCell
                        content={
                            <Button
                                styles={{ padding: "0" }}
                                as="span"
                                text
                                content={"Nom de la Tâche"}
                                size="small"
                            />
                        }
                    />
                    <TableCell
                        content={
                            <Button
                                styles={{ padding: "0" }}
                                as="span"
                                text
                                icon={<CalendarIcon />}
                                content={"Règle sur la date"}
                                size="small"
                            />
                        }
                    />
                    <TableCell
                        content={
                            <Button
                                styles={{ padding: "0" }}
                                as="span"
                                icon={<PersonIcon />}
                                text
                                content={"Responsable"}
                                size="small"
                            />
                        }
                    />
                    <TableCell
                        styles={{
                            maxWidth: "50px",
                        }}
                        content={""}
                    />
                </TableRow>

                {tasks?.length > 0 &&
                    tasks?.map((el, index) => {
                        const {
                            name,
                            duedate_str,
                            assigned_to_name,
                            assigned_to_avatar,
                        } = el;
                        return (
                            <TableRow fill key={el?.id}>
                                <TableCell content={name} />
                                <TableCell
                                    content={_getRegleDate(duedate_str, el)}
                                />
                                <TableCell
                                    content={
                                        <AvatarUser
                                            key={`avatar_${index}`}
                                            name={
                                                assigned_to_name ||
                                                el?.temp?.assigned_to
                                                    ?.content ||
                                                el?.temp?.assigned_to?.header
                                            }
                                            image={
                                                assigned_to_avatar ||
                                                el?.temp?.assigned_to?.image ||
                                                AVATAR_DEFAULT
                                            }
                                            showName={true}
                                        />
                                    }
                                />
                                <TableCell
                                    styles={{
                                        maxWidth: "50px",
                                    }}
                                    content={
                                        <MoreMenuItemModifyTemplate
                                            key={`more points ${index}`}
                                            task={el}
                                            setTemplateTasks={setTemplateTasks}
                                            setValue={setValue}
                                            setOpenDialogModifyTask={
                                                setOpenDialogModifyTask
                                            }
                                            setToastConfig={setToastConfig}
                                        />
                                    }
                                />
                            </TableRow>
                        );
                    })}
            </Table>
        </WrapperTable>
    );
};

export default TableOfTasksForModify;

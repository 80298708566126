import {
    Attachment,
    Button,
    Checkbox,
    CloseIcon,
    Dialog,
    Divider,
    Dropdown,
    FilesUploadIcon,
    Flex,
    Input,
    Loader,
    Text,
    TextArea,
} from "@fluentui/react-northstar";
import React from "react";
import Balances from "../balances/Balances";
import styled from "styled-components";
import Toast from "../../../../components/Alerts";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ErrorMessage } from "../../../addEmployee/styles";
import SliderTabs from "../../../../components/shared/SliderTabs";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { FILE_SIZE_LIMIT, SCHEMA } from "../../constants";
import { useState } from "react";
import { refetchTeamBadge, util_format_start_and_end_time } from "../../utils";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    api_add_new_leave,
    api_get_leaves_list,
    api_personal_calendar_detail,
} from "../../api";
import { _getAllUsers } from "../../../../api/user";
import { AVATAR_DEFAULT } from "../../../../constants";
import { useRef } from "react";
import { _upload_file_temp } from "../../../../api/uploads";
import { file_size_format } from "../../../../constants/maths";
import { ICON_FROM_EXTENSION } from "../../../../utils/functions";
import { Container, ContainerFluid } from "../../../../common/styles";
import CollabCalendar from "./CollabCalendar";
import { DatesOfAbsence, DialogStyle, DropdownStyle } from "./AbsenceRequest";
import { DEVICE } from "../../../../constants/responsive";

const MIN_DATE_TODAY = moment().format("YYYY-MM-DD");

const MyAbsence = ({ isRH, subHeader, holidayList }) => {
    const ref_add_document = useRef(null);
    const queryClient = useQueryClient();
    const [showDialog, setShowDialog] = useState({
        visible: false,
        start_date: null,
    });
    const [activeTab, setActiveTab] = useState(0);
    const [toastConfig, setToastConfig] = useState({
        content: "",
        visible: false,
        level: "warning",
    });

    const methods = useForm({
        resolver: yupResolver(SCHEMA),
        mode: "all",
        defaultValues: {
            justificatif: [],
        },
    });

    // Liste de type d'absence request
    const { data: types } = useQuery(
        "fetching_type_dabsence",
        () => api_get_leaves_list(),
        {
            refetchOnWindowFocus: false,
        }
    );
    // end Liste de type d'absence request

    // liste de tt les collaborateur fonction pour deposer une demande d'absence pour kelk1
    const {
        isLoading: Loading_for_users,
        data: allUsers,
        isFetching: Fetching_for_users,
    } = useQuery(["getAllUsers"], () => _getAllUsers(), {
        refetchOnWindowFocus: false,
    });
    // end
    const {
        register,
        control,
        formState: { errors, isSubmitting, isLoading: isl, isValid },
        setValue,
        getValues,
        setError,
        trigger,
        watch,
    } = methods;

    const data_type_leave = types?.leave_types?.map((el) => {
        return {
            id: el?.id,
            key: el?.id,
            header: el?.name,
            document_required: el?.document_required,
            allow_mid_day: el?.allow_mid_day,
            allow_negative_balance: el?.allow_negative_balance,
            attachment_type: el?.attachment_type,
        };
    });

    // attachement document
    const inputFileChanged = async (e) => {
        const { files } = e.target;

        if (!files?.length) {
            return console.log("ya aucun fichier !!");
        }

        // limit the import to 5 files at once.
        if (files.length > 1) {
            alert("Vous avez dépasser la limite autorisée ( 1 fichier )");
            return;
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size > FILE_SIZE_LIMIT) {
                console.log(
                    "'" + file.name + "' à été retirer a cause de sa taille !"
                );
                return false;
            }
            formData.append("uploaded_files", file);
        }

        const response = await _upload_file_temp(formData);
        if (response?.success) {
            setValue("justificatif", response?.files);
        }
        return;
    };

    const show_dialog = ({ start_date = "", end_date = "" }) => {
        const end_dateo = moment(end_date)
            .subtract(1, "days")
            .format("YYYY-MM-DD");

        if (start_date && end_dateo) {
            setValue("start_date", start_date);
            setValue("end_date", end_dateo);
            trigger(["start_date", "end_date"]);
        }
        setShowDialog({
            visible: true,
            start_date,
            end_date: end_dateo,
        });

        return;
    };

    const cancel_dialog = () => {
        setShowDialog({
            visible: false,
            start_date: null,
            end_date: null,
        });
        methods.reset();
        return;
    };

    const { mutate: mutate_request, isLoading } = useMutation((data) => {
        // API function
        return api_add_new_leave(data);
    });

    const onSubmit = (data) => {
        if (data.start_date > data.end_date) {
            return setError("end_date", {
                type: "manual",
                message: "la date de fin doit étre aprés la date de début.",
            });
        }

        if (isLoading) {
            return console.log("Already sending ....");
        }

        if (
            LeaveType_watcher.attachment_type === 2 &&
            watch_justificatif?.length === 0
        ) {
            setToastConfig((state) => {
                return {
                    ...state,
                    visible: true,
                    content: "Justificatif d'absence manquant !",
                    level: "danger",
                };
            });
            return;
        }

        const {
            comment,
            end_date,
            end_time,
            start_date,
            start_time,
            justificatif,
            leave_user,
            negative_balance_alert_accepted,
        } = data;

        // eslint-disable-next-line no-unreachable
        const { start_time: start_time_formated, end_time: end_time_formated } =
            util_format_start_and_end_time({
                start: start_time,
                end: end_time,
            });

        const { id: leave_type_id } = methods.getValues("LeaveType");
        mutate_request(
            {
                leave_type_id: leave_type_id,
                start_date: moment(start_date).format("YYYY-MM-DD"),
                start_time: start_time_formated,
                end_date: moment(end_date).format("YYYY-MM-DD"),
                end_time: end_time_formated,
                comment,
                uploaded_files: justificatif?.map((el) => el?.id),
                leave_user: leave_user?.id,
                negative_balance_alert_accepted,
            },
            {
                onSuccess: (data, e) => {
                    console.log({ success: data });
                    if (data?.success) {
                        // queryClient.refetchQueries(["myPersonalCalendar"]);
                        cancel_dialog();
                        setToastConfig((state) => {
                            return {
                                ...state,
                                visible: true,
                                content: data?.message,
                                level: "success",
                            };
                        });
                        queryClient.refetchQueries(["myPersonalCalendar"]);
                        queryClient.refetchQueries(["api-balances-details"]);
                    } else {
                        setToastConfig((state) => {
                            return {
                                ...state,
                                visible: true,
                                content: data?.message,
                                level: "danger",
                            };
                        });
                    }
                },
            }
        );
    };

    const confirm_dialog = () => {
        methods.handleSubmit(onSubmit)();
        refetchTeamBadge(queryClient);
        return;
    };

    const LeaveType_watcher = watch("LeaveType");
    const watch_justificatif = watch("justificatif");
    const watch_starting_date = watch("start_date");
    const watch_ending_date = watch("end_date");
    const watch_negative_balance_alert_accepted = watch(
        "negative_balance_alert_accepted"
    );

    // VALUES
    const isMandatory = LeaveType_watcher?.attachment_type === 2;
    const isOptional = LeaveType_watcher?.attachment_type === 1;
    return (
        <ContainerFluid fill column>
            {/* Ajouter une absence CTA */}
            <Flex>
                <Toast
                    visible={toastConfig?.visible}
                    content={toastConfig?.content}
                    level={toastConfig?.level}
                    onStatusChange={() => {
                        return setToastConfig({
                            visible: false,
                            content: "",
                            level: "warning",
                        });
                    }}
                />
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <DialogStyle
                            open={showDialog?.visible}
                            onCancel={cancel_dialog}
                            onConfirm={confirm_dialog}
                            content={
                                <Flex column gap="gap.medium">
                                    {/* isRH */}
                                    {isRH && subHeader === 2 && (
                                        <Flex column gap="gap.small">
                                            <Text
                                                weight="semilight"
                                                content="Personne concerné"
                                            />
                                            <Controller
                                                name="leave_user"
                                                control={control}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value,
                                                    },
                                                }) => {
                                                    return (
                                                        <Dropdown
                                                            fluid
                                                            items={allUsers?.map(
                                                                (el, index) => {
                                                                    return {
                                                                        header: el?.name,
                                                                        content:
                                                                            el?.job_title,
                                                                        email: el?.email,
                                                                        image:
                                                                            el?.avatar ||
                                                                            AVATAR_DEFAULT,
                                                                        key: index,
                                                                        id: el?.id,
                                                                    };
                                                                }
                                                            )}
                                                            placeholder="Quelles est la personne concerné ?"
                                                            onChange={(
                                                                _,
                                                                i
                                                            ) => {
                                                                onChange(
                                                                    i.value
                                                                );
                                                                return;
                                                            }}
                                                            error={
                                                                errors?.leave_user
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </Flex>
                                    )}

                                    <RaisonOfAbsence column gap="gap.smaller">
                                        <Label content="Type d'absence" />
                                        <div
                                            style={{
                                                height: "35px",
                                            }}
                                        >
                                            <Controller
                                                name="LeaveType"
                                                control={control}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value,
                                                    },
                                                }) => {
                                                    return (
                                                        <DropdownStyle
                                                            styles={{
                                                                position:
                                                                    "fixed",
                                                                zIndex: 2,
                                                            }}
                                                            fluid
                                                            items={
                                                                data_type_leave
                                                            }
                                                            placeholder={
                                                                <p
                                                                    style={{
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    Quelle est
                                                                    la cause de
                                                                    l'absence ?
                                                                </p>
                                                            }
                                                            onChange={(
                                                                _,
                                                                i
                                                            ) => {
                                                                setValue(
                                                                    "LeaveType",
                                                                    i.value
                                                                );
                                                                onChange(
                                                                    i.value
                                                                );
                                                                setValue(
                                                                    "justificatif",
                                                                    []
                                                                );
                                                                return;
                                                            }}
                                                            error={
                                                                errors?.LeaveType
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                        {errors?.LeaveType && (
                                            <ErrorMessage
                                                content={
                                                    errors?.LeaveType?.message
                                                }
                                            />
                                        )}
                                        {/* Justification */}
                                        {[1, 2].includes(
                                            LeaveType_watcher?.attachment_type
                                        ) &&
                                        watch_justificatif?.length === 0 ? (
                                            <>
                                                <Input
                                                    {...register(
                                                        "justificatif"
                                                    )}
                                                    fluid
                                                    primary
                                                    type="file"
                                                    accept="image/*, .pdf"
                                                    ref={ref_add_document}
                                                    styles={{
                                                        width: "280px",
                                                    }}
                                                    onChange={inputFileChanged}
                                                    hidden
                                                />
                                                <Label
                                                    content={`Justificatif d'absence ${
                                                        isOptional
                                                            ? "(optionnel)"
                                                            : ""
                                                    }`}
                                                />
                                                <Button
                                                    content="Ajouter un justificatif d'absence"
                                                    tinted
                                                    icon={<FilesUploadIcon />}
                                                    onClick={() =>
                                                        ref_add_document.current.click()
                                                    }
                                                />

                                                <ErrorMessage
                                                    content={
                                                        errors?.justificatif
                                                            ?.message
                                                    }
                                                />
                                            </>
                                        ) : watch_justificatif?.length !== 0 ? (
                                            <Attachment
                                                size="small"
                                                action={{
                                                    icon: <CloseIcon />,
                                                    onClick: () =>
                                                        setValue(
                                                            "justificatif",
                                                            []
                                                        ),
                                                    title: "Close",
                                                }}
                                                header={
                                                    watch_justificatif?.at(0)
                                                        ?.filename
                                                }
                                                description={
                                                    file_size_format(
                                                        watch_justificatif?.at(
                                                            0
                                                        )?.filesize
                                                    ) + "o"
                                                }
                                                icon={ICON_FROM_EXTENSION(
                                                    watch_justificatif?.at(0)
                                                        ?.filename
                                                )}
                                                actionable
                                                progress={100}
                                            />
                                        ) : null}
                                    </RaisonOfAbsence>

                                    <DatesOfAbsence>
                                        <Flex column gap="gap.smaller">
                                            <Label content="Commence le" />
                                            <DatepickerStyle
                                                {...register("start_date")}
                                                defaultValue={
                                                    watch_starting_date
                                                }
                                                style={{
                                                    marginBottom: "8px",
                                                }}
                                                type="date"
                                            />

                                            {LeaveType_watcher?.allow_mid_day && (
                                                <Controller
                                                    name="start_time"
                                                    control={control}
                                                    defaultValue={0}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            onBlur,
                                                            value,
                                                            name,
                                                        },
                                                    }) => {
                                                        return (
                                                            <SliderTabs
                                                                name={name}
                                                                tabs={[
                                                                    "Début de matinée",
                                                                    "Début d'après-midi",
                                                                ]}
                                                                onChange={(i) =>
                                                                    onChange(i)
                                                                }
                                                                defaultActiveIndex={
                                                                    0
                                                                }
                                                            />
                                                        );
                                                    }}
                                                />
                                            )}
                                        </Flex>
                                        <Flex column gap="gap.smaller">
                                            <Label content="Fini le " />
                                            <DatepickerStyle
                                                // name="end_date"
                                                {...register("end_date")}
                                                defaultValue={watch_ending_date}
                                                style={{
                                                    marginBottom: "8px",
                                                }}
                                                type="date"
                                                iconPosition={"end"}
                                                min={watch_starting_date}
                                                max="2030-12-31"
                                            />

                                            <Flex fill>
                                                {LeaveType_watcher?.allow_mid_day && (
                                                    <Controller
                                                        name="end_time"
                                                        control={control}
                                                        defaultValue={1}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                value,
                                                            },
                                                        }) => {
                                                            return (
                                                                <SliderTabs
                                                                    tabs={[
                                                                        "Fin de matinée",
                                                                        "Fin de journée",
                                                                    ]}
                                                                    onChange={(
                                                                        i
                                                                    ) =>
                                                                        onChange(
                                                                            i
                                                                        )
                                                                    }
                                                                    defaultActiveIndex={
                                                                        1
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </Flex>
                                        </Flex>
                                    </DatesOfAbsence>

                                    <Flex column gap="gap.small">
                                        <Label
                                            content={"Commentaire (facultatif)"}
                                        />
                                        <Controller
                                            name="comment"
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <TextArea
                                                        {...field}
                                                        placeholder={
                                                            "Ajouter un commentaire ... "
                                                        }
                                                        rows={2}
                                                        fluid
                                                    />
                                                );
                                            }}
                                        />
                                    </Flex>
                                    {LeaveType_watcher?.allow_negative_balance && (
                                        <Flex gap="gap.medium">
                                            <Controller
                                                name="negative_balance_alert_accepted"
                                                control={control}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        ...rest
                                                    },
                                                }) => {
                                                    return (
                                                        <Checkbox
                                                            {...rest}
                                                            onChange={(
                                                                e,
                                                                { checked }
                                                            ) =>
                                                                onChange(
                                                                    checked
                                                                )
                                                            }
                                                            label="J’ai pris connaissance qu'en cas de solde de congés négatif et de départ de l'entreprise, une retenue sur salaire pourra être effectuée."
                                                        />
                                                    );
                                                }}
                                            />{" "}
                                        </Flex>
                                    )}
                                </Flex>
                            }
                            confirmButton={{
                                content: "Envoyer",
                                loading: isLoading,
                                disabled: !isValid ? true : false,
                            }}
                            cancelButton={"Annuler"}
                            header={<Text content="Ajouter une absence" />}
                            headerAction={{
                                icon: <CloseIcon />,
                                title: "Close",
                                onClick: () => {
                                    cancel_dialog();
                                    setShowDialog({
                                        visible: false,
                                        start_date: null,
                                        end_date: null,
                                    });
                                },
                            }}
                        />
                    </form>
                </FormProvider>
                {/* Action  */}
                <Flex
                    fill
                    style={{
                        padding: "14px 0px",
                        // padding: "24px 0px",
                    }}
                ></Flex>
            </Flex>
            <Container fill hAlign="center" vAlign="center">
                <CollabCalendar
                    holidayList={holidayList}
                    subHeader={subHeader}
                    setToastConfig={setToastConfig}
                    cancel_dialog={cancel_dialog}
                    showDialoge={show_dialog}
                />
            </Container>
        </ContainerFluid>
    );
};

export default MyAbsence;

const RaisonOfAbsence = styled(Flex)``;
const DatepickerStyle = styled(Input)`
    @media ${DEVICE.mobileS} {
        input {
            width: 100%;
        }
    }
    @media ${DEVICE.laptop} {
        input {
            width: 280px;
        }
    }
`;

const Label = styled(Text)`
    color: #616161;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

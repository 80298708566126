import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    CloseIcon,
    Dialog,
    DownloadIcon,
    Flex,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { useQuery } from "react-query";

import {
    Organization24Regular,
    Notebook24Regular,
} from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import CustomBreadCrumb from "../../components/breadcrumb";
import AccordionList from "./components/AccordionList";
import { DEBOUNCE_DELAY, NAV_LIST, TABS_ANNUAIRE } from "./constant";
import { downloadFile, getAnnuaire } from "../../api/annuaire";
import { DEVICE } from "../../constants/responsive";
import ListUsers from "./components/ListUsers";
import { _formatJsonAnnuaire, _formatJsonUsers } from "./utils";
import { debounce } from "../../utils/global";
import Organigram from "./components/Organigram";
import { Container, ContainerFluid } from "../../common/styles";
import SubHeader from "../../components/breadcrumb/SubHeader";
import Searchbar from "./components/Searchbar";
import EmployeeExporterRegister from "./components/EmployeeExporterRegister";
import { ROLES_CODE, roles_access } from "../../constants/roles";

const initialState = {
    personal: {
        secu: false,
        id_type: false,
        id_number: false,
        nationality: false,
        birthdate: false,
        gender: false,
        address: false,
    },
    contract: {
        job: false,
        contract_type: false,
        start_date: false,
        end_date: false,
        department: false,
    },
};

const Annuaire = () => {
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);

    const [checkboxes, setCheckboxes] = useState(initialState);
    const [viewFormer, setViewFormer] = useState(0);
    const [path, setPath] = useState("");

    useEffect(() => {
        document.title = "Annuaire de l'entreprise";
    }, []);

    const { t } = useTranslation("translation");
    const [TabView, setTabView] = useState(TABS_ANNUAIRE.ANNUAIRE);
    const [query, setQuery] = useState("");
    /**
     * get annuaire data from API.
     */
    const { data: annuaire, isLoading } = useQuery(
        ["getAnnuaire", query],
        () => {
            return getAnnuaire(query);
        },
        {
            enabled: TabView === TABS_ANNUAIRE.ANNUAIRE,
        }
    );

    const { isLoading: isDownloadingFile, refetch: refetchDownload } = useQuery(
        ["ANNUAIRE::download_file", path],
        () => {
            return downloadFile(path);
        },
        {
            // enabled: path !== "",
            enabled: false,
        }
    );

    const onChange = debounce((value) => {
        if (value) {
            setQuery(value);
        } else {
            setQuery("");
        }
    }, DEBOUNCE_DELAY);

    /**
     * format JSON for displaying items
     */
    const panels = _formatJsonAnnuaire(annuaire);

    const isHR = roles_access({
        usersPrivileges: [ROLES_CODE.hr],
    });

    return (
        <Box
            styles={{
                minHeight: "100vh",
                backgroundColor: "#F5F5F5",
            }}
        >
            {/* breadcrumb */}
            <CustomBreadCrumb
                navList={NAV_LIST}
                actions={
                    isHR &&
                    TabView === TABS_ANNUAIRE.ANNUAIRE && (
                        <Dialog
                            open={openDownloadDialog}
                            onOpen={() => setOpenDownloadDialog(true)}
                            onCancel={() => {
                                setOpenDownloadDialog(false);
                                setCheckboxes(initialState);
                            }}
                            onConfirm={() => {
                                const personal = checkboxes.personal;
                                const contract = checkboxes.contract;
                                const personal_query = Object.entries(personal)
                                    .filter((el) => el[1] === true)
                                    .map((el) => "&q=" + el[0])
                                    .join("");
                                const contract_query = Object.entries(contract)
                                    .filter((el) => el[1] === true)
                                    .map((el) => "&q=" + el[0])
                                    .join("");

                                const path = `${(
                                    personal_query + contract_query
                                ).replace("&", "?")}`;
                                const andOrSeparator =
                                    path === ""
                                        ? `?view_former=${
                                              viewFormer ? "1" : "0"
                                          }`
                                        : `&view_former=${
                                              viewFormer ? "1" : "0"
                                          }`;
                                setPath(path + andOrSeparator);
                                setTimeout(() => {
                                    refetchDownload();
                                }, 300);
                                setOpenDownloadDialog(false);
                                setCheckboxes(initialState);
                                return;
                            }}
                            confirmButton={{
                                content: "Confirmer",
                                loading: isDownloadingFile,
                            }}
                            cancelButton="Annuler"
                            content={
                                <EmployeeExporterRegister
                                    checkboxes={checkboxes}
                                    setCheckboxes={setCheckboxes}
                                    viewFormer={viewFormer}
                                    setViewFormer={setViewFormer}
                                />
                            }
                            header="Exporter le registre du personnel"
                            headerAction={{
                                icon: <CloseIcon />,
                                title: "Close",
                                onClick: () => {
                                    setOpenDownloadDialog(false);
                                    setCheckboxes(initialState);
                                },
                            }}
                            trigger={
                                <Button
                                    iconPosition="after"
                                    icon={<DownloadIcon />}
                                    inverted
                                    content="Exporter le registre du personnel"
                                />
                            }
                        />
                    )
                }
            />
            <SubHeader
                menu={[
                    {
                        content: t("common.sub_header.annuaire"),
                        icon: <Notebook24Regular />,
                        value: TABS_ANNUAIRE.ANNUAIRE,
                    },
                    {
                        content: t("common.sub_header.organigram"),
                        icon: <Organization24Regular />,
                        value: TABS_ANNUAIRE.ORGANIGRAMME,
                    },
                ]}
                setTabView={setTabView}
            />
            {/* searchbar */}

            {TabView === TABS_ANNUAIRE.ANNUAIRE && (
                <Searchbar onChange={onChange} />
            )}

            <ContainerFluid column styles={{ position: "relative" }}>
                <Container column>
                    {TabView === TABS_ANNUAIRE.ANNUAIRE ? (
                        <Wrapper column>
                            {/* table annuaire */}
                            <FlexStyle>
                                <Column>
                                    <Text>Nom</Text>
                                </Column>
                                <Column>
                                    <Text>Poste</Text>
                                </Column>
                                <Column hideonmobile={"true"}>
                                    <Text>Manager</Text>
                                </Column>
                                <Column hideonmobile={"true"}>
                                    <Text>Agence</Text>
                                </Column>
                                <Column hideonmobile={"true"}>
                                    <Text>N° professionnel</Text>
                                </Column>
                                <Column className="last">
                                    <Text></Text>
                                </Column>
                            </FlexStyle>
                            {/* accordion */}
                            {isLoading && (
                                <Loader label={"Chargement des données"} />
                            )}
                            {annuaire?.users?.length > 0 && (
                                <ListUsers
                                    users={_formatJsonUsers(annuaire.users)}
                                />
                            )}
                            {!isLoading && (
                                <AccordionList panels={panels} query={query} />
                            )}
                        </Wrapper>
                    ) : (
                        <Organigram />
                    )}
                </Container>
            </ContainerFluid>
        </Box>
    );
};

export default Annuaire;

const Wrapper = styled(Flex)`
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 0px 0px;
    /* margin-top: 64px; */
`;

const TabStyle = styled.div`
    padding-inline: 20px;
`;

const FlexStyle = styled(Flex)`
    background-color: #3d3e78;
    color: white;
    border-radius: 4px 4px 0px 0px;
    padding-inline: 16px;
`;

const Column = styled.div`
    flex: 1;
    padding: 8px 10px;
    &.last {
        max-width: 100px;
    }
    @media ${DEVICE.mobileS} {
        display: ${({ hideonmobile }) =>
            hideonmobile === "true" ? "none" : "inherit"};
    }
    @media ${DEVICE.mobileL} {
        display: ${({ hideonmobile }) =>
            hideonmobile === "true" ? "none" : "inherit"};
    }
    @media ${DEVICE.mobileM} {
        display: ${({ hideonmobile }) =>
            hideonmobile === "true" ? "none" : "inherit"};
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

import { PresenceBadge } from "@fluentui/react-components";
import {
    Button,
    ChevronEndIcon,
    Flex,
    MenuButton,
    Table,
    Text,
} from "@fluentui/react-northstar";
import moment from "moment";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { ACTION_TYPES_WEEK_DIFINITION } from "../../reducers/reducer.week_definition";

const items = [
    {
        badge: <PresenceBadge status="unknown" />,
        content: (
            <Flex vAlign="center" gap="gap.smaller">
                <PresenceBadge status="unknown" />
                <Text content="Repos temps partiel" />
            </Flex>
        ),
    },
    {
        badge: <PresenceBadge />,
        content: (
            <Flex vAlign="center" gap="gap.smaller">
                <PresenceBadge />
                <Text content="Travaillé" />
            </Flex>
        ),
    },
    {
        badge: <PresenceBadge status="out-of-office" />,
        content: (
            <Flex vAlign="center" gap="gap.smaller">
                <PresenceBadge status="out-of-office" />
                <Text content="Repos hebdomadaire" />
            </Flex>
        ),
    },
];

const WeekWorkingDefinition = ({ state, dispatch, show_legend = true }) => {
    const row_one = {
        key: 1,
        items: [
            <Text content="Matin" align="end" className="morning" />,
            ...state.planning.map((slot) => (
                <MenuButton
                    trigger={
                        <Button
                            icon={<ChevronEndIcon rotate="90" />}
                            iconPosition="after"
                            content={items[slot?.slots?.morning]?.badge}
                            aria-label="Click button"
                            onClick={(e, i) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            styles={{
                                minWidth: "56px",
                                width: "56px",
                                padding: "0px",
                            }}
                        />
                    }
                    menu={items.map((item) => item.content)}
                    on="click"
                    onMenuItemClick={(e, i) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch({
                            type: ACTION_TYPES_WEEK_DIFINITION.set_day_slot,
                            payload: {
                                slot_type: "morning",
                                day_id: slot.id,
                                value: i.index,
                            },
                        });
                        return;
                    }}
                />
            )),
        ],
    };
    const row_two = {
        key: 2,
        items: [
            <ColumnDaySplit content="Après-midi" className="afternoon" />,
            ...state.planning.map((slot) => (
                <MenuButton
                    styles={{
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    trigger={
                        <Button
                            icon={<ChevronEndIcon rotate="90" />}
                            iconPosition="after"
                            content={items[slot?.slots?.afternoon]?.badge}
                            aria-label="Click button"
                            onClick={(e, i) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            styles={{
                                minWidth: "56px",
                                width: "56px",
                                padding: "0px",
                            }}
                        />
                    }
                    menu={items.map((item) => item.content)}
                    on="click"
                    onMenuItemClick={(e, i) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch({
                            type: ACTION_TYPES_WEEK_DIFINITION.set_day_slot,
                            payload: {
                                slot_type: "afternoon",
                                day_id: slot.id,
                                value: i.index,
                            },
                        });
                        return;
                    }}
                />
            )),
        ],
    };

    return (
        <Flex column gap="gap.medium">
            <TableStyle
                compact
                header={{
                    items: ["", ...state.weeks],
                }}
                rows={[row_one, row_two]}
            />
            {show_legend && (
                <Legend column gap="gap.smaller">
                    <LegendTitle content="Légende" />
                    <Flex gap="gap.small">{items.map((e) => e.content)}</Flex>
                </Legend>
            )}
        </Flex>
    );
};

export default WeekWorkingDefinition;

const LegendTitle = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;
const Legend = styled(Flex)`
    padding: 8px 12px;
    width: fit-content;

    border-radius: 4px;
    border: 1px solid var(--light-theme-rest-border-default-border, #d1d1d1);
    background: var(
        --light-theme-rest-background-default-background-3,
        #f0f0f0
    );
`;
const TableStyle = styled(Table)`
    background-color: transparent;
    [role="row"]:nth-child(2) [role="cell"]:first-child,
    [role="row"]:nth-child(3) [role="cell"]:first-child {
        justify-content: end;
        padding-top: 5px;
    }
    [role="row"]:nth-child(1) [role="columnheader"] {
        font-weight: bold;
        justify-content: center;
    }
    [role="cell"] {
        justify-content: center;
    }
    .ui-table__row {
        background-color: transparent;
        border-bottom: transparent;
        :hover {
            border-color: transparent;
        }
    }
    .ui-table__cell {
        width: 88px;
    }
`;
const ColumnDaySplit = styled(Text)`
    color: var(--light-theme-rest-foreground-default-foreground-2, #616161);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const HeaderBadge = ({ currency = "", total = null }) => {
    return (
        <Container>
            <ContainerText>
                {total ?? "-,--"} {currency}
            </ContainerText>
        </Container>
    );
};

export default HeaderBadge;

const ContainerText = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    color: #ffffff;
`;

const Container = styled(Flex)`
    width: fit-content;
    background: #5b5fc7;
    border-radius: 4px;
    padding: 4px 8px;
`;

import { Button, Dialog, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";

export const Title = styled(Text)`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* padding: 14px 0px 26px 0px; */
`;

export const Header = styled(Flex)`
    gap: 16px;
    padding: 16px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        align-items: flex-start;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        align-items: center;
    }
`;

export const Body = styled(Flex)`
    background-color: white;
`;

export const TextCategory = styled(Text)`
    color: #252525;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
`;

export const DialogStyle = styled(Dialog)`
    width: 764px;
    padding: 28px 32px 32px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

export const LinkResetFilters = styled(Button)`
    color: #5b5fc7;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
`;

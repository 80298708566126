import React from "react";
import styled from "styled-components";

import {
    Box,
    Chip,
    FormControlLabel,
    Grid,
    Tooltip,
    Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PanToolIcon from "@mui/icons-material/PanTool";

const label = { inputProps: { "aria-label": "Row task chekbox" } };

const TaskRow = ({
    data,
    manualTask = false,
    onClick = null,
    _update_selected_tasks,
}) => {
    return (
        <>
            <BoxStyled alignItems={"center"} sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    {...label}
                                    icon={<AddCircleOutlineIcon />}
                                    checkedIcon={<RemoveCircleIcon />}
                                    onChange={(e) =>
                                        _update_selected_tasks(e, data.id)
                                    }
                                    checked={data.checked}
                                />
                            }
                        />
                        <span>{data.name}</span>
                    </Grid>
                    <Grid item xs={6} onClick={onClick}>
                        <Typography variant="caption">
                            {data.description}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems="center"
                        gap={1}
                        onClick={onClick}
                    >
                        {data?.category_name && (
                            <Chip
                                variant="outlined"
                                style={{
                                    borderColor: data.category_bg_color,
                                    color: data.category_bg_color,
                                }}
                                label={data.category_name}
                            />
                        )}
                    </Grid>
                </Grid>
            </BoxStyled>
            <Hr />
        </>
    );
};

export default TaskRow;

const Hr = styled.hr`
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgb(226, 232, 240);
`;

const BoxStyled = styled(Box)`
    --tw-shadow: 0 1px 3px 0rgba (0, 0, 0, 0.1), 0 1px 2px 0rgba (0, 0, 0, 0.06);
    --tw-shadow-colored: 0 1px 3px 0var (--tw-shadow-color),
        0 1px 2px 0 var(--tw-shadow-color);
    display: flex;
    width: 100%;
    padding: 12px 20px;
    transition: all 0.3s;
    &:hover {
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        transition: all 0.3s;
    }
`;

import { Grid, styled, Typography } from "@mui/material";
import React from "react";
import MoodBadIcon from "@mui/icons-material/MoodBad";

import HighLightCard from "../../Dashboard/MainContent/HighLightCard";
import DetailsTable from "./DetailsTable";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import HailIcon from "@mui/icons-material/Hail";
import DateRangeIcon from "@mui/icons-material/DateRange";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
export default function OnBordingContainer() {
    return (
        <GridContainer
            container
            spacing={2}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            item
        >
            {/* left side */}
            <Grid
                style={{
                    display: "flex",
                    // border: "1px solid blue",
                }}
                item
                xs={8}
                lg={8}
                md={8}
            >
                <LeftBox>
                    <DetailsTable />
                </LeftBox>
            </Grid>

            {/* right side */}
            <GridStyle item xs={4}>
                <BoxStyle>
                    <Typography
                        variant="subtitle1"
                        style={{
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            color: "rgba(0, 0, 0, 0.87)",
                        }}
                    >
                        Chiffres clés
                    </Typography>
                    <HighLightCard
                        icon={
                            <MoodBadIcon
                                style={{
                                    backgroundColor: "#03A9F4",
                                    borderRadius: "50%",
                                    color: "white",
                                    padding: "10px",
                                    width: "48px",
                                    height: "48px",
                                }}
                                fontSize="small"
                            />
                        }
                        trending={
                            <TrendingUpIcon
                                style={{ color: "#4caf50", marginRight: "6px" }}
                            />
                        }
                        text={"+12,3 (+3) "}
                        number={"40"}
                        BottomText={"TURNOVER"}
                    />
                    <HighLightCard
                        icon={
                            <HailIcon
                                style={{
                                    backgroundColor: "#FF9800",
                                    borderRadius: "50%",
                                    color: "white",
                                    padding: "10px",
                                    width: "48px",
                                    height: "48px",
                                }}
                                fontSize="small"
                            />
                        }
                        trending={
                            <ArrowRightAltOutlinedIcon
                                style={{ color: "grey", marginRight: "6px" }}
                            />
                        }
                        text={"+ 0 % (+0)"}
                        number={"23"}
                        BottomText={"OFFBOARDING"}
                    />
                    <HighLightCard
                        icon={
                            <DateRangeIcon
                                style={{
                                    backgroundColor: "#00BCD4",
                                    borderRadius: "50%",
                                    color: "white",
                                    padding: "10px",
                                    width: "48px",
                                    height: "48px",
                                }}
                                fontSize="small"
                            />
                        }
                        trending={
                            <TrendingUpIcon
                                style={{ color: "#4caf50", marginRight: "6px" }}
                            />
                        }
                        text={"+28 % (+9)"}
                        number={"34"}
                        BottomText={"JOURS DE CONGÉS DU MOIS"}
                    />

                    <HighLightCard
                        icon={
                            <GroupAddIcon
                                style={{
                                    backgroundColor: "#673AB7",
                                    borderRadius: "50%",
                                    color: "white",
                                    padding: "10px",
                                    width: "48px",
                                    height: "48px",
                                }}
                                fontSize="small"
                            />
                        }
                        trending={
                            <TrendingUpIcon
                                style={{ color: "#4caf50", marginRight: "6px" }}
                            />
                        }
                        text={"+12,3 (+3) "}
                        number={"56"}
                        BottomText={"ONBOARDING"}
                    />
                </BoxStyle>
            </GridStyle>
        </GridContainer>
    );
}

const GridContainer = styled("Grid")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.up("xs")]: {
        maxWidth: "100%",
        margin: "24px",
    },
    [theme.breakpoints.up("lg")]: {
        maxWidth: "1440px",
        margin: "0 auto",
    },
}));

const BoxStyle = styled("Box")(({ theme }) => ({
    width: "376px",
    height: "784px",
    background: "#FFFFFF",
    borderRadius: "8px",
    padding: "16px 40px 40px 40px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    [theme.breakpoints.up("xs")]: {
        display: "none",
    },
    [theme.breakpoints.up("sm")]: {
        display: "none",
    },
    [theme.breakpoints.up("md")]: {
        display: "none",
    },
    [theme.breakpoints.up("lg")]: {
        display: "flex",
    },
}));

const GridStyle = styled("Grid")(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        display: "none",
    },
    [theme.breakpoints.up("sm")]: {
        display: "none",
    },
    [theme.breakpoints.up("md")]: {
        display: "none",
    },
    [theme.breakpoints.up("lg")]: {
        display: "flex",
    },
}));
const LeftBox = styled("Box")(({ theme }) => ({
    width: "892px",
    height: "785px",
    background: "#FFFFFF",
    borderRadius: "8px",
    padding: "0px 24px 24px 24px",
    [theme.breakpoints.up("xs")]: {
        margin: "24px",
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "100%",
    },
    [theme.breakpoints.up("md")]: {
        display: "flex",
        justifyContent: "space-between",
        // border: "1px solid red",
    },
    [theme.breakpoints.up("lg")]: {
        width: "892px",
    },
}));

import { Avatar, Flex } from "@fluentui/react-northstar";
import moment from "moment";
import { TYPE_NAVIGATION_MONTH, constants } from "./constants";
import { CellDate, CellTitle } from "./index.style";
import { CustomBadge } from "../../../../components/Badge";
import Reinvoiceable from "../../components/Badges/Reinvoiceable";
import { currency_code_to_symbols } from "../../../../constants/maths";
import {
    CellAmount,
    CellHistoryDate,
    TextUsername,
} from "../../components/HistoryPaidExpenses/index.style";
import { ROUTES } from "../../../../constants/routes";
import { click } from "@testing-library/user-event/dist/click";

export const func_format_data_rows = (response, navigate, isMobile) => {
    return response?.map((report) => {
        const navigateTo = () =>
            navigate(
                ROUTES.expenses.accounting_manager.detail.custom_route(
                    report.id,
                    report.user.id
                )
            );
        return {
            key: report.id,
            items: [
                {
                    content: (
                        <CellDate>
                            {isMobile
                                ? moment(
                                      report.created_date,
                                      "DD/MM/YYYY"
                                  ).format("DD/MM")
                                : report.created_date}
                        </CellDate>
                    ),
                    styles: {
                        maxWidth: constants.WIDTH_COLUMN_DATE,
                        // border: "2px solid pink",
                    },
                },
                {
                    content: (
                        <Flex vAlign="center" gap="gap.small" fill>
                            <Avatar
                                name={report.user.full_name}
                                image={report.user.avatar}
                                size="small"
                            />
                            <CellTitle
                                className="cell_title"
                                onClick={() => navigateTo()}
                                truncated
                            >
                                {report.name}
                            </CellTitle>
                        </Flex>
                    ),
                    styles: {
                        maxWidth: constants.WIDTH_COLUMN_TITLE,
                    },
                },
                {
                    content: !isMobile && (
                        <CustomBadge
                            bg="black"
                            color="white"
                            label={report.expenses}
                            size="smaller"
                        />
                    ),
                    styles: {
                        maxWidth: constants.WIDTH_COLUMN_COUNT_EXPENSES,
                        justifyContent: "end",
                    },
                },
                {
                    content: isMobile ? null : report.reinvoiceable ? (
                        <Reinvoiceable client_name={report?.client?.name} />
                    ) : null,
                    styles: {
                        maxWidth: constants.WIDTH_COLUMN_REBILL,
                        justifyContent: "center",
                    },
                },
                {
                    content: (
                        <Flex className="cell_amount">
                            {currency_code_to_symbols({
                                amount: report.amount,
                                currency: report.currency,
                            })}
                        </Flex>
                    ),
                    styles: {
                        maxWidth: constants.WIDTH_COLUMN_AMOUNT,
                        justifyContent: "end",
                    },
                },
            ],
            styles: {
                cursor: "pointer",
                borderLeft: "4px solid transparent",
                ":hover": {
                    borderLeft:
                        "4px solid var(--Light-theme-Rest-Border-Brand-Border-1, #9299F7)",
                    background:
                        "var(--Light-theme-Rest-Background-Default-Background-2, #F5F5F5)",
                },
                ":hover .cell_amount": {
                    color: "var(--color-primary)",
                },
                ":hover .cell_title": {
                    color: "var(--color-primary)",
                },
            },
            onClick: () => navigateTo(),
        };
    });
};

export const func_format_data_history_rows = (response, navigate) => {
    return response?.map((report) => {
        const format_date = moment(report.date, "MM/YYYY").format("MM/YY");
        const format_currency = currency_code_to_symbols({
            amount: report.amount,
            currency: report.currency,
        });
        const navigateTo = () => {
            return navigate(
                ROUTES.expenses.accounting_manager.detail.custom_route(
                    report.id,
                    report.user_id
                )
            );
        };
        return {
            key: report.id,
            items: [
                {
                    content: <CellHistoryDate>{format_date}</CellHistoryDate>,
                    styles: {
                        maxWidth: "45px",
                    },
                },
                {
                    content: (
                        <Flex
                            vAlign="center"
                            gap="gap.small"
                            onClick={navigateTo}
                            styles={{
                                cursor: "pointer",
                            }}
                        >
                            <Avatar
                                name={report.name}
                                image={report.user_avatar}
                                size="smallest"
                            />
                            <TextUsername content={report.name} />
                        </Flex>
                    ),
                    styles: {
                        maxWidth: "310px",
                        width: "310px",
                    },
                },
                {
                    content: (
                        <CellAmount
                            className="cell_amount"
                            content={format_currency}
                        />
                    ),
                    styles: {
                        maxWidth: "66px",
                    },
                },
            ],
            styles: {
                borderLeft: "4px solid transparent",
                ":hover": {
                    borderLeft:
                        "4px solid var(--Light-theme-Rest-Border-Brand-Border-1, #9299F7)",
                    background:
                        "var(--Light-theme-Rest-Background-Default-Background-2, #F5F5F5)",
                },
                ":hover .cell_amount": {
                    color: "var(--color-primary)",
                },
            },
        };
    });
};

// forward/backward  month & year
// increment/decrement MONTH SLIDER HISTORY
export const navigation_month_to = (
    type_navigation,
    monthNavigation,
    setMonthNavigation
) => {
    const moment_current_state = moment()
        .set("month", monthNavigation.month_index - 1)
        .set("year", monthNavigation.year);

    const increment_decrement_month =
        type_navigation === TYPE_NAVIGATION_MONTH.forward ? 1 : -1;

    const moment_new_state = moment_current_state.add(
        increment_decrement_month,
        "month"
    );

    setMonthNavigation((prev) => ({
        month_index: moment_new_state.month() + 1,
        year: moment_new_state.year(),
    }));
};

// get month and year format Janvier 2024 (MMMM YYYY)
export const get_monthname_and_year = (monthNavigation) => {
    return moment()
        .set("month", monthNavigation.month_index - 1)
        .set("year", monthNavigation.year)
        .format("MMMM YYYY");
};

import {
    Box,
    Breadcrumb,
    ChevronEndIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";

export default function HeaderProfilManager({ header_description }) {
    return (
        <Box>
            <ToolbarStyle>
                <Flex column>
                    <Breadcrumb aria-label="breadcrumb">
                        <Breadcrumb.Item>
                            <Breadcrumb.Link
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "14px",
                                    marginRight: "-6px",
                                }}
                                href="/dashboard"
                            >
                                Accueil
                            </Breadcrumb.Link>
                        </Breadcrumb.Item>

                        <ChevronEndIcon
                            style={{
                                color: "white",
                            }}
                            size="medium"
                        />
                        <Breadcrumb.Item
                            onClick={() =>
                                console.log("navigate to mon profile")
                            }
                            style={{
                                cursor: "pointer",
                                color: "white",
                                textDecoration: "none",
                                fontSize: "14px",
                                marginLeft: "-6px",
                            }}
                        >
                            <Breadcrumb.Link
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                    
                                    fontSize: "14px",
                                    marginRight: "-6px",
                                }}
                                href="/annuaire"
                            >
                                Annuaire
                            </Breadcrumb.Link>
                        </Breadcrumb.Item>
                        <ChevronEndIcon
                            style={{
                                color: "white",
                            }}
                            size="medium"
                        />
                        <Breadcrumb.Item
                            style={{
                                color: "white",
                                textDecoration: "none",
                                fontWeight: "bold",
                                marginLeft: "-6px",
                            }}
                        >
                            Modifier le profil
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Flex>
            </ToolbarStyle>
            <Header column gap="gap.small">
                <Flex.Item>
                    <Text weight="bold" content={"Modification du profil"} />
                </Flex.Item>
                <Flex.Item>
                    <Text
                        style={{ color: "#616161" }}
                        weight="regular"
                        content={header_description}
                    />
                </Flex.Item>
            </Header>
        </Box>
    );
}

const ToolbarStyle = styled(Box)`
    background-color: #5b5fc7;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
`;

const Header = styled(Flex)`
    @media ${DEVICE.mobileS} {
        background-color: #ebebeb;
        flex-direction: column;
        gap: 8px;
        padding: 16px;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
        /* border: 1px solid yellow; */
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
        max-width: 876px;
        margin: 0 auto;
    }
`;

import produce from "immer";
import { create } from "zustand";

export const useToastGlobal = create((set) => ({
    visible: false,
    level: "success",
    content: "",
    onChangeStatus: () => {
        set(
            produce((state) => {
                state.visible = false;
            })
        );
    },
    setShow: (json) => {
        set(
            produce((state) => {
                state.visible = json.visible;
                state.content = json.content;
                state.level = json.level;
            })
        );
    },
}));

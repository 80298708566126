import React from "react";
import { Flex, Loader, SaveIcon, Table, Text } from "@fluentui/react-northstar";
import { ChevronLeftRegular, ChevronRightRegular } from "@fluentui/react-icons";

import { CardExpense, HistoriqueCard } from "../../styles";
import { CardExpenseTitle } from "../../pages/ExpensesAccountingManagerHome/index.style";
import {
    ButtonDownloadHistoryExcel,
    ButtonSliderMonth,
    FlexMonthName,
} from "./index.style";
import useLogicHistory from "../../pages/ExpensesAccountingManagerHome/useLogicHistory";
import EmptyHistoryAccountingManager from "../EmptyHistoryAccountingManager/EmptyHistoryAccountingManager";

const HistoryPaidExpenses = () => {
    const {
        query_history,
        can_navigate,
        navigation_month_clicks,
        month_and_year_name,
        history,
    } = useLogicHistory();

    return (
        <HistoriqueCard
            column
            gap="gap.medium"
            fill
            // styles={{ maxWidth: "451px" }}
        >
            <Flex column fill vAlign="center" hAlign="center" gap="gap.large">
                {/* TITLE */}
                <CardExpenseTitle>
                    Historiques des notes de frais payées
                </CardExpenseTitle>
                {/* slider month */}
                <Flex vAlign="center">
                    <ButtonSliderMonth
                        secondary
                        flat
                        disabled={!can_navigate.backward}
                        onClick={navigation_month_clicks.backward}
                    >
                        <ChevronLeftRegular />
                    </ButtonSliderMonth>
                    <FlexMonthName hAlign="center">
                        <Text
                            className="month_name"
                            content={month_and_year_name}
                        />
                    </FlexMonthName>
                    <ButtonSliderMonth
                        secondary
                        flat
                        disabled={!can_navigate.forward}
                        onClick={navigation_month_clicks.forward}
                    >
                        <ChevronRightRegular />
                    </ButtonSliderMonth>
                </Flex>
                {/* TABLE */}
                <Flex fill hAlign="center">
                    {query_history.isLoadingHistory ? (
                        <Loader
                            size="small"
                            label="chargement de l'historique ..."
                        />
                    ) : query_history.isEmpty ? (
                        <EmptyHistoryAccountingManager />
                    ) : (
                        <Table
                            rows={query_history.data_history}
                            aria-label="list"
                            styles={{ width: "100%" }}
                        />
                    )}
                </Flex>
                {/* BUTTON EXPORT EXCEL */}
                <ButtonDownloadHistoryExcel
                    text
                    icon={<SaveIcon />}
                    iconPosition="before"
                    content={"Télécharger les dépenses du mois sur Excel"}
                    disabled={query_history.isEmpty}
                    loading={history.loading}
                    onClick={history.download_excel}
                />
            </Flex>
        </HistoriqueCard>
    );
};

export default HistoryPaidExpenses;

import { Button, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const OpenCard = styled(Flex)`
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13),
        0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11);
    width: 190px;
    height: 224px;
    opacity: ${({ opacity }) => (opacity ? opacity : "100%")};
`;

export const Header = styled(Flex)`
    /* margin: 8px; */
    min-height: 44px;
    width: 190px;
    padding: 8px;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid ${({ border }) => (border ? border : "yelow")};
    background: ${({ headerBackground }) =>
        headerBackground ? headerBackground : "yellow"};

    /* shadow-2 */
    box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13),
        0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11);
`;

export const MonthName = styled(Text)`
    text-transform: capitalize;
    color: ${({ monthNameColor }) =>
        monthNameColor ? monthNameColor : "yellow"};
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    /* height: 33px; */
    /* padding: 0px 16px; */
`;

export const IndisponibleText = styled(Text)`
    color: #616161;

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    opacity: 50%;
`;
export const InProgressText = styled(Text)`
    color: #242424;

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
`;

export const Content = styled(Flex)`
    width: 131px;
    margin-top: 20px;
    gap: 12px;
`;
export const HoursworkedPerMonth = styled(Text)`
    color: #242424;

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
`;
export const WeekDetail = styled(Button)`
    color: #0067b8;
    text-align: center;

    /* Medium/400 */

    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px; /* 142.857% */
    &:hover {
        color: #0067b8;
    }
`;
export const HeaderDialogText = styled(Text)`
    color: #424242;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`;

import React, { useEffect, useState } from "react";
import {
    AddIcon,
    Button,
    Dialog,
    Divider,
    Flex,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { ContainerFluid, Container } from "../../../common/styles";
import { I18N_NS_EXPENSE, QUERY_KEY, TYPE_FORM } from "../constants";
import RowEmpty from "./Tables/Row";
import { ROUTES } from "../../../constants/routes";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import Toast from "../../../components/Alerts";
import { useMutation, useQuery } from "react-query";
import {
    _api_cancel_note,
    _api_get_detail_report,
    _api_send_note,
} from "../api";
import { ReceiptAdd24Regular } from "@fluentui/react-icons";
import RowsExpenseDetail from "./Tables/RowsExpenseDetail";
import EvolutionComment from "../pages/ExpensesDetailReport/EvolutionComment";
import EmbedDocument from "../pages/ExpensesManagerMyTeams/EmbedDocument";
import Reinvoiceable from "./Badges/Reinvoiceable";
import EmbedAndCommentsCard from "./EmbedAndCommentsCard/EmbedAndCommentsCard";
import { DEVICE, SIZES } from "../../../constants/responsive";
import { useMediaQuery } from "react-responsive";
import { max } from "moment";

const INIT_TOAST = {
    visible: false,
    content: "",
    level: "warning",
};

const INIT_COMMENT = {
    id_expense: null,
    title_expense: "",
    content: "",
};

const ExpensesDetail = () => {
    const navigate = useNavigate();

    const { t } = useTranslation(I18N_NS_EXPENSE);
    const { id_expense } = useParams(); // ici id expense is id report

    const [searchParams, setSearchParams] = useSearchParams();
    const [dialogCancelExpense, setDialogCancelExpense] = useState(false); // false
    const [embed, setEmbed] = useState(null);

    const [toggleComment, setToggleComment] = useState(false);
    const [comments, setComments] = useState([]); // used for toggle between comments card.
    const [selectedExpense, setSelectedExpense] = useState(null);

    const [configToast, setConfigToast] = useState({
        visible: searchParams.get("toast") === "expense_added",
        content: t("report_detail.success_add"),
        level: "success",
    });

    const {
        data: detail,
        isLoading,
        refetch: refetch_get_all_expenses,
    } = useQuery([QUERY_KEY, id_expense], ({ queryKey }) => {
        const id_expense = queryKey[1];
        return _api_get_detail_report({ id_expense_report: id_expense });
    });

    const { isLoading: is_loading_send_note, mutate: mutate_send_note } =
        useMutation((data) => _api_send_note(data), {
            onSuccess: (data) => {
                if (data?.success) {
                    navigate(ROUTES.expenses.home + "?toast=NOTE_SENT");
                    return;
                }
            },
        });

    const { isLoading: is_loading_cancel_note, mutate: mutate_cancel_note } =
        useMutation((data) => _api_cancel_note(data), {
            onSuccess: (data) => {
                if (data?.success) {
                    navigate(ROUTES.expenses.home + "?toast=NOTE_CANCELED");
                    return;
                }
            },
        });

    const goto_add_category = () => {
        return navigate(
            ROUTES.expenses.home +
                "/" +
                ROUTES.expenses.add_expense.custom_route(
                    ROUTES.expenses.add_expense.route,
                    id_expense
                )
        );
    };

    const _openDialogCancelExpense = () => {
        setDialogCancelExpense(true);
        return;
    };

    const _closeDialogCancelExpense = () => {
        setDialogCancelExpense(false);
        return;
    };

    const on_click_view_document = (url_document) => {
        // setComment(INIT_COMMENT);
        setEmbed(url_document);
        return;
    };

    const handle_click_comment = (expense) => {
        const { comment: expense_comment, id, name, histories } = expense;
        // setEmbed(null);
        // if (id === comment?.id_expense) {
        //     // clicked on the same line
        //     setComment(INIT_COMMENT);
        //     return;
        // }

        // setComment({
        //     id_expense: id,
        //     content: expense_comment,
        //     title_expense: name,
        // });
        setComments(histories);
        setSelectedExpense(id);
        return;
    };

    const LENGTH_EXPENSES = detail?.expense_report?.expenses?.length;
    const IS_CAN_SEND = detail?.expense_report?.status?.value === 0;
    const REPORT_STATUS = detail?.expense_report?.status?.value;

    const id_report = detail?.expense_report?.id;
    const user = detail?.expense_report?.user;

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    const isMobile = isMobileM || isMobileS;

    return (
        <ContainerFluid>
            <ContainerStyle gap="gap.large">
                <Wrapper fill column gap="gap.medium">
                    <Toast
                        {...configToast}
                        onStatusChange={() => setConfigToast(INIT_TOAST)}
                        key={"toast"}
                    />
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Body column>
                            <Flex
                                styles={{
                                    paddingInline: "8px",
                                    marginTop: "24px",
                                }}
                                vAlign="center"
                                space="between"
                            >
                                <Flex gap="gap.large" vAlign="center">
                                    <Title>
                                        {detail?.expense_report?.name}
                                    </Title>
                                    {detail.expense_report.reinvoiceable && (
                                        <Reinvoiceable
                                            client_name={
                                                detail?.expense_report?.client
                                                    ?.name
                                            }
                                        />
                                    )}
                                </Flex>
                                <Dialog
                                    open={dialogCancelExpense}
                                    header={t(
                                        "dialogs.dialog_cancel_report.title"
                                    )}
                                    content={t(
                                        "dialogs.dialog_cancel_report.content"
                                    )}
                                    onCancel={_closeDialogCancelExpense}
                                    cancelButton={t(
                                        "dialogs.dialog_cancel_report.button_cancel"
                                    )}
                                    confirmButton={{
                                        content: t(
                                            "dialogs.dialog_cancel_report.button_confirm"
                                        ),
                                        flat: true,
                                        loading: is_loading_cancel_note,
                                        styles: {
                                            backgroundColor:
                                                "var(--color-danger)",
                                            ":hover": {
                                                backgroundColor:
                                                    "var(--color-primary--hover)",
                                            },
                                        },
                                    }}
                                    onConfirm={() => {
                                        mutate_cancel_note({
                                            id_expense_report: id_expense,
                                        });
                                        return;
                                    }}
                                    trigger={
                                        <Text
                                            styles={{
                                                cursor: "pointer",
                                                fontWeight: 600,
                                            }}
                                            color="brand"
                                            content={t("common.cancel")}
                                            onClick={_openDialogCancelExpense}
                                            text
                                            primary
                                        />
                                    }
                                />
                            </Flex>
                            <WhiteBoard fill column>
                                <Flex>
                                    {LENGTH_EXPENSES === 0 ? (
                                        <RowEmpty />
                                    ) : (
                                        <RowsExpenseDetail
                                            isMobile={isMobile}
                                            expenses={
                                                detail?.expense_report?.expenses
                                            }
                                            id_report={
                                                detail?.expense_report?.id
                                            }
                                            note_status={REPORT_STATUS}
                                            refetch_get_all_expenses={
                                                refetch_get_all_expenses
                                            }
                                            can_i_delete={IS_CAN_SEND}
                                            id_expense_of_selected_comment={
                                                null
                                            }
                                            selectedExpense={selectedExpense}
                                            is_employee_page={true}
                                            is_title_clickable={true}
                                            onClickTitle={({
                                                id_expense,
                                                form_type,
                                                id_report,
                                            }) => {
                                                const expense_status =
                                                    detail?.expense_report
                                                        ?.status?.value;

                                                // en cours de création
                                                if (expense_status === 0) {
                                                    return navigate(
                                                        ROUTES.expenses.update_form.custom_route(
                                                            id_report,
                                                            id_expense,
                                                            TYPE_FORM[form_type]
                                                        )
                                                    );
                                                }
                                            }}
                                            on_click_view_document={
                                                on_click_view_document
                                            }
                                            on_click_comment={
                                                handle_click_comment
                                            }
                                        />
                                    )}
                                </Flex>
                                {!LENGTH_EXPENSES && <Divider />}
                                {isMobile ? (
                                    <Flex hAlign="center">
                                        <Button
                                            flat
                                            icon={
                                                <ReceiptAdd24Regular color="#5B5FC7" />
                                            }
                                            styles={{ marginTop: "24px" }}
                                            onClick={goto_add_category}
                                            content={
                                                <p
                                                    style={{
                                                        color: "#5B5FC7",
                                                        fontSize: "14px",
                                                        lineHeight: "19px",
                                                        fontWeight: "700",
                                                    }}
                                                >
                                                    {t(
                                                        "report_detail.link_add_expense"
                                                    )}
                                                </p>
                                            }
                                        />
                                    </Flex>
                                ) : (
                                    <Flex column gap="gap.large">
                                        <Flex>
                                            <Button
                                                primary
                                                text
                                                size="small"
                                                content={t(
                                                    "report_detail.link_add_expense"
                                                )}
                                                onClick={goto_add_category}
                                                icon={<AddIcon />}
                                            />
                                        </Flex>
                                        <Flex gap="gap.medium" hAlign="center">
                                            <Button
                                                flat
                                                content={t("common.cancel")}
                                                onClick={
                                                    _openDialogCancelExpense
                                                }
                                            />
                                            <Button
                                                content={
                                                    IS_CAN_SEND
                                                        ? t(
                                                              "common.send_report"
                                                          )
                                                        : t(
                                                              "report_detail.expense_already_sent"
                                                          )
                                                }
                                                disabled={
                                                    !LENGTH_EXPENSES ||
                                                    !IS_CAN_SEND
                                                }
                                                loading={is_loading_send_note}
                                                onClick={() =>
                                                    mutate_send_note({
                                                        id_expense_report:
                                                            id_expense,
                                                    })
                                                }
                                                flat
                                                primary
                                            />
                                        </Flex>
                                    </Flex>
                                )}
                            </WhiteBoard>
                            {isMobile && (
                                <Flex
                                    fill
                                    styles={{
                                        backgroundColor: "white",
                                        marginTop: "200px",
                                        padding: "16px",
                                        boxShadow:
                                            "0px 2.4px 7.4px 0px rgba(0, 0, 0, 0.18), 0px 12.8px 28.8px 0px rgba(0, 0, 0, 0.22)",
                                    }}
                                    hAlign="center"
                                >
                                    <Button
                                        fluid
                                        content={
                                            IS_CAN_SEND
                                                ? t("common.send_report")
                                                : t(
                                                      "report_detail.expense_already_sent"
                                                  )
                                        }
                                        disabled={
                                            !LENGTH_EXPENSES || !IS_CAN_SEND
                                        }
                                        loading={is_loading_send_note}
                                        onClick={() =>
                                            mutate_send_note({
                                                id_expense_report: id_expense,
                                            })
                                        }
                                        flat
                                        primary
                                    />
                                </Flex>
                            )}
                        </Body>
                    )}
                </Wrapper>
                {/* {(comment?.content || embed) && (
                    <RightSectionDetailDocumentAndComments>
                        <EvolutionComment
                            comment={comment?.content}
                            title_expense={comment?.title_expense}
                        />
                        <EmbedDocument
                            content={embed}
                            onClose={() => {
                                setEmbed(null);
                            }}
                        />
                    </RightSectionDetailDocumentAndComments>
                )} */}
                <Flex fill styles={{ marginTop: "30px" }}>
                    {(comments?.length || embed) && (
                        <EmbedAndCommentsCard
                            comments={comments}
                            embed={embed}
                            user={{
                                username: `${user?.first_name} ${user?.last_name}`,
                                avatar: user?.avatar,
                            }}
                            onClose={() => {
                                setEmbed(null);
                                setComments([]);
                                setSelectedExpense(null);
                            }}
                        />
                    )}
                </Flex>
            </ContainerStyle>
        </ContainerFluid>
    );
};

export default ExpensesDetail;

const Body = styled(Flex)`
    /* width: 100%; */
    width: 850px;
    max-width: 100%;
`;

const ContainerStyle = styled(Flex)`
    margin-top: 24px;
    max-width: 1440px;
    margin: 0 auto;
    /* width: 100%; */
    @media ${DEVICE.mobileS} {
        width: 100%;
        max-width: auto;
        flex-direction: column;
    }
    @media ${DEVICE.mobileL} {
        width: 100%;
        max-width: auto;
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        width: 100%;
        max-width: auto;
        flex-direction: column;
    }
    @media ${DEVICE.laptop} {
        width: 100%;
        max-width: auto;
        flex-direction: row;
    }
`;

const WhiteBoard = styled(Flex)`
    background-color: white;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    padding: 16px 16px 32px 16px;
    width: 100%;
`;

const Wrapper = styled(Flex)`
    /* width: 850px; */
    max-width: 850px;
    /* border: 3px solid green; */
    height: fit-content;

    /* width: clamp(500px, 60%, 850px); */
    /* @media ${DEVICE.mobileS} {
        width: clamp(100%, 100%, 850px);
    }
    @media ${DEVICE.mobileM} {
        width: clamp(100%, 100%, 850px);
    }
    @media ${DEVICE.mobileL} {
        width: clamp(100%, 100%, 850px);
    }
    @media ${DEVICE.tablet} {
        width: clamp(500px, 60%, 850px);
    }
    @media ${DEVICE.laptop} {
        width: 1250px;
    }
    @media ${DEVICE.laptopL} {
        width: clamp(500px, 60%, 850px);
    } */
`;

const Title = styled(Text)`
    color: black;
    text-transform: capitalize;

    @media ${DEVICE.mobileS} {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 24px;
        /* margin-left: 16px; */
        /* padding-left: 8px; */
        white-space: nowrap;
    }
    @media ${DEVICE.laptop} {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
`;

export const RightSectionDetailDocumentAndComments = styled(Flex)`
    padding-top: 16px;
    /* gap: 16px; */
    height: fit-content;
    border-radius: 8px;
    background: white;
    /* width: 200px; */
    /* width: clamp(100%, 2vw, 200px); */
    /* shadow-8 */
    /* max-width: 100%; */
    box-shadow: 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13);
    @media ${DEVICE.mobileS} {
        width: 100%;
        padding-inline: 16px;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
        /* width: 100%; */
    }
    @media ${DEVICE.tablet} {
    }
    @media ${DEVICE.laptop} {
        padding-inline: 0px;
        padding-top: 0px;
    }
`;

import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { TeamCard } from "./TeamCard";
import ShowAllTeamDialog from "./ShowAllTeamDialog";
import { useQueries } from "react-query";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CardUser from "../../../features/employee_folder/components/Cards/CardPositionEntreprise/CardUser";
import { DEVICE } from "../../../constants/responsive";

export const TeamSection = ({ team }) => {
    // * navigate to any employee
    const navigate = useNavigate();
    const _navigateToEmployee = (id) => {
        if (isNaN(id)) {
            return console.log("is not a valid ID employee");
        }

        return navigate(ROUTES.employee_folder.custom_route(id));
    };

    return (
        <FlexContainer column gap="gap.medium">
            <HeaderTeamSection space="between" vAlign="center">
                <MyTeamText content={`Mon équipe (${team?.length})`} />
                {/* see all my team dialog */}
                {team?.length > 6 && <ShowAllTeamDialog teams={team} />}
            </HeaderTeamSection>

            {team?.length > 0 ? (
                <MainTeamSection fill column>
                    <FlexCardUsers
                        fill
                        wrap
                        styles={{
                            columnGap: "44px",
                        }}
                    >
                        {team?.slice(0, 6)?.map((el) => {
                            return (
                                <CardUser
                                    key={el?.id}
                                    avatar={el?.avatar}
                                    full_name={`${el?.first_name} ${el?.last_name}`}
                                    job_title={el?.job_title}
                                    hideTitle={false}
                                    onClick={() => _navigateToEmployee(el?.id)}
                                />
                            );
                        })}
                    </FlexCardUsers>
                </MainTeamSection>
            ) : (
                <MainTeamSection hAlign="center">
                    <EmptyDataText
                        color="brand"
                        content="Aucune personne identifiée sous votre responsabilité"
                    />
                </MainTeamSection>
            )}
        </FlexContainer>
    );
};

const FlexContainer = styled(Flex)`
    @media ${DEVICE.mobileS} {
        margin-top: 24px;
        padding-inline: 16px;
    }
    @media ${DEVICE.laptop} {
        margin-top: 0px;
        padding-inline: 0px;
    }
`;

const EmptyDataText = styled(Text)`
    color: #616161;
`;

const HeaderTeamSection = styled(Flex)`
    /* @media ${DEVICE.mobileS} {
        padding: 0px 16px 0px 16px;
    }
    @media ${DEVICE.laptop} {
        padding: 0px 0px 0px 12px;
    } */
    /* margin-top: 30px; */
`;
const MyTeamText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #242424;
`;

const MainTeamSection = styled(Flex)`
    padding: 24px 16px;
    min-height: 216px;
    gap: 24px;
    background: #ffffff;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    /* @media ${DEVICE.laptop} {
        margin: 0px;
    }
    @media ${DEVICE.mobileS} {
        margin-inline: 16px;
    } */
`;

const FlexCardUsers = styled(Flex)`
    @media ${DEVICE.mobileS} {
        justify-content: center;
    }
    @media ${DEVICE.laptop} {
        justify-content: left;
    }
`;

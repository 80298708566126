import { Accordion, Box, Button, teamsTheme } from "@fluentui/react-northstar";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import styled from "styled-components";
import * as yup from "yup";
import { queryClient } from "../../../..";
import { _api_update_profile } from "../../../../api/profile";
import { PANELS } from "./Panels";
import { formating_json_for_updating_profile_for_employee } from "../../ForCollaborator/EditProfil/utils/employee";

export default function TabsContentEmployee({ user }) {
    const SCHEMA = yup.object({
        phone_number: yup
            .string()
            .min(12, "Le numéro n'est pas complet")
            .required("Ce champ est obligatoire"),
    });

    const methods = useForm({
        resolver: yupResolver(SCHEMA),
        mode: "all",
        defaultValues: user,
    });

    const { getValues, errors } = methods;
    const navigate = useNavigate();

    const { mutate, isLoading: isLoadingMutation } = useMutation(
        ["updateProfile"],
        async (data) => {
            const format_json =
                formating_json_for_updating_profile_for_employee(data);
            await _api_update_profile(format_json);
            queryClient.invalidateQueries(["getUserCurrent"]);
            return navigate("/me?alert=true");
        }
    );

    const onSubmit = (data) => {
        let values = getValues();
        values.id = data?.id;
        return mutate(values);
    };

    const BackToProfile = () => {
        return navigate("/me");
    };

    return (
        <FormProvider {...methods} theme={teamsTheme}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Accordionn
                    user={user}
                    defaultActiveIndex={[0, 1]}
                    panels={PANELS}
                    key={PANELS.map((el) => el.key)}
                    expandiconposition="end"
                />
                <Action>
                    <Button onClick={BackToProfile} tinted content="Annuler" />

                    <Button
                        type="submit"
                        content="Enregistrer"
                        primary
                        loading={isLoadingMutation}
                    />
                </Action>
            </form>
        </FormProvider>
    );
}

const Accordionn = styled(Accordion)`
    background-color: #ebebeb;
    > .ui-box {
        background-image: none;
    }
    .ui-accordion__title__content-wrapper {
        grid-template-columns: 1fr;
        > .ui-box:first-child {
            display: none;
        }
    }
    background-color: #ebebeb;
    max-width: 875px;
    margin: 0 auto;
`;

const Action = styled(Box)`
    display: flex;
    gap: 16px;
    justify-content: center;
    background-color: #ebebeb;

    max-width: 875px;
    margin: 0 auto;
    padding: 20px;
`;

import { BASE_URL_API } from "../../../constants";

export const _api_externe_validation = (hash) => {
    return fetch(BASE_URL_API + "/time_reporting/approval/" + hash, {
        method: "GET",
        headers: {
            "Content-type": "application/json",
        },
        credentials: "include",
    }).then(async (response) => {
        const data = await response.json();

        if (response.ok) {
            return data;
        } else {
            throw new Error("Error Baby 🎉");
        }
    });
};

export const _api_approve_or_reject_timesheet = ({ step, hash, comment }) => {
    return fetch(BASE_URL_API + "/time_reporting/approval/" + hash, {
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            step: step,
            comment: comment,
        }),
        credentials: "include",
    }).then(async (response) => {
        const data = await response.json();

        if (response.ok) {
            return data;
        } else {
            throw new Error("Error Baby 🎉");
        }
    });
};

import { Box, Button, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { get_symbol_currency } from "../../../expenses/functions";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { format_number } from "../../../../constants/maths";
import LegendChart from "../../../expenses/components/LegendChart";

const AsUserN2F = ({ expenses_user, total_as_user, expenses, ShowLink }) => {
    const navigate = useNavigate();

    return (
        <Flex column fill hAlign="center" gap="gap.small">
            <Flex
                styles={{
                    position: "relative",
                    width: "190px",
                    height: "190px",
                }}
                hAlign="center"
                vAlign="center"
            >
                <Doughnut
                    data={expenses_user}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        aspectRatio: "1",
                        plugins: {
                            legend: false,
                        },
                    }}
                />
                <Total
                    content={
                        format_number(total_as_user) +
                        " " +
                        get_symbol_currency(expenses?.as_user[0]?.currency)
                    }
                />
            </Flex>
            <Flex
                wrap
                gap="gap.medium"
                styles={{
                    rowGap: "24px",
                }}
            >
                {expenses?.as_user?.map((legend, i) => {
                    return (
                        <LegendChart
                            key={i}
                            color={legend.color}
                            name={legend.name}
                        />
                    );
                })}
            </Flex>
            <Flex styles={{ marginTop: "24px" }}>
                {ShowLink && (
                    <Show_N2F_Link
                        onClick={() => navigate(ROUTES.expenses.home)}
                        text
                        content="Aller aux notes de frais >"
                    />
                )}
            </Flex>
        </Flex>
    );
};

export default AsUserN2F;

export const Total = styled(Text)`
    font-weight: 700;
    font-size: 24px;

    position: absolute;

    color: #242424;
`;

const Show_N2F_Link = styled(Text)`
    color: #0067b8;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
`;

import React, { useState } from "react";
import {
    Button,
    Checkbox,
    ChevronDownIcon,
    ChevronEndIcon,
    ChevronStartIcon,
    DateRangeType,
    Datepicker,
    Flex,
} from "@fluentui/react-northstar";
import { CustomBadge } from "../../../../components/Badge";
import { TextSelectedWeek, WrapperCalendarWeek } from "../../index.style";
import { useShowRemoteWeekend } from "../../store/useShowRemoteWeekend";

/**
 * @typedef {Object} PropsType
 * @property {string} labelDropdown - sert a afficher le texte dans le dropdown calendar
 * @property {number} isoWeekNumber - sert a afficher le numero de la semaine iso selectionnee
 * @property {function} [onIncrementWeek] - est appellee au moment du clique sur increment week
 * @property {function} [onDecrementWeek] - est appellee au moment du clique sur decrement week
 * @property {function} [onDatePickerChange] - est appellee au moment ou on change la semaine dans le calendrier
 *
 *
 * @param {PropsType} param0
 * @returns
 */
const CalendarActionsHeader = ({
    labelDropdown,
    isoWeekNumber,
    onIncrementWeek = () => null,
    onDecrementWeek = () => null,
    onDatePickerChange = () => null,
}) => {
    const [open, setOpen] = useState(false);

    const ctx_set_show = useShowRemoteWeekend((state) => state.setShow);
    const ctx_show = useShowRemoteWeekend((state) => state.show);

    return (
        <Flex vAlign="center" gap="gap.medium" fill>
            <Flex styles={{ gap: "4px" }}>
                <Button
                    icon={<ChevronStartIcon />}
                    iconOnly
                    flat
                    disabled={false}
                    onClick={onDecrementWeek}
                />
                <Button
                    icon={<ChevronEndIcon />}
                    iconOnly
                    flat
                    disabled={false}
                    onClick={onIncrementWeek}
                />
            </Flex>
            <Flex>
                <CustomBadge
                    bg="#e0e0e0"
                    label={`Semaine ${isoWeekNumber}`}
                    rectangular
                />
            </Flex>
            <Flex styles={{ marginLeft: "40px" }}>
                <Datepicker
                    dateRangeType={DateRangeType.Week}
                    onDateChange={(e, i) => onDatePickerChange(e, i)}
                    defaultSelectedDate={new Date()}
                    today={new Date()}
                    buttonOnly
                    popup={{
                        open,
                        onOpenChange: (e, i) => {
                            return setOpen(i.open);
                        },
                        trigger: (
                            <WrapperCalendarWeek
                                vAlign="center"
                                gap="gap.small"
                            >
                                <TextSelectedWeek>
                                    {labelDropdown}
                                </TextSelectedWeek>
                                <ChevronDownIcon rotate={open ? 180 : 0} />
                            </WrapperCalendarWeek>
                        ),
                    }}
                />
            </Flex>
            <Flex hAlign="end" styles={{ marginLeft: "auto" }}>
                <Checkbox
                    label="Afficher le weekend"
                    toggle
                    defaultChecked={ctx_show}
                    onChange={(_, i) => {
                        return ctx_set_show(i.checked);
                    }}
                />
            </Flex>
        </Flex>
    );
};

export default CalendarActionsHeader;

import React from "react";
import { Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE } from "../../constants";

const ContentEmpty = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    return <TextStyle>{t("history.accordion.empty")}</TextStyle>;
};

export default ContentEmpty;

const TextStyle = styled(Text)`
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    padding: 10px;

    color: #242424;
`;

import {
    Button,
    ErrorIcon,
    Flex,
    Skeleton,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { DEVICE, SIZES } from "../../../constants/responsive";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Card = ({
    action,
    actionText,
    icon,
    ButtonIcon,
    isPrimary,
    isLoading,
    isFetching,
    due_date,
    status,
    el,
}) => {
    const isDuedateAfterToday = moment().isAfter(moment(due_date), "day");
    const navigate = useNavigate();

    const isMobile = useMediaQuery({
        query: `(max-width:500px)`,
    });

    const Chimmering = ({ width }) => {
        // console.log("size", size);
        return (
            <Skeleton>
                <Skeleton.Button
                    // size="small"
                    animation={"wave"}
                    styles={{
                        borderRadius: "4px",
                        width: width ? width + "px" : "20px",
                        height: "20px",
                    }}
                />
            </Skeleton>
        );
    };

    return (
        <CardStyle
            onClick={() => {
                return navigate(
                    // "/dashboard/tasks/:id_task/user/:id_user/detail"
                    `/dashboard/tasks/${el?.id}/user/${el?.assigned_to_user.id}/detail`,
                    { state: { key: true } }
                );
            }}
            space="between"
            vAlign="center"
        >
            {!isMobile ? (
                <Flex
                    fill
                    space="between"
                    vAlign="center"
                    // gap="gap.small"
                >
                    <Flex vAlign="center">
                        <Flex styles={{ width: "24px", marginRight: "8px" }}>
                            {isLoading || isFetching ? (
                                <Skeleton>
                                    <Skeleton.Text
                                        size="medium"
                                        style={{ width: "20px" }}
                                    />
                                </Skeleton>
                            ) : !status ? (
                                isDuedateAfterToday && (
                                    <ErrorIcon
                                        subtle={false}
                                        pointing
                                        position="after"
                                        align="start"
                                        content={`Date d'échéance : ${due_date}`}
                                        trigger={icon}
                                    />
                                )
                            ) : (
                                icon
                            )}
                        </Flex>
                        <Flex fill vAlign="center" style={{ gap: "24px" }}>
                            {isLoading || isFetching ? (
                                <Skeleton>
                                    <Skeleton.Text
                                        size="large"
                                        styles={{ width: "120px" }}
                                    />
                                </Skeleton>
                            ) : (
                                <CardText truncated content={actionText} />
                            )}
                        </Flex>
                    </Flex>

                    {isLoading || isFetching ? (
                        <Skeleton>
                            <Skeleton.Button
                                siz="small"
                                animation={"wave"}
                                styles={{ borderRadius: "4px" }}
                            />
                        </Skeleton>
                    ) : action ? (
                        <ButtonStyle
                            tinted
                            icon={ButtonIcon}
                            content={action}
                            flat
                            primary={isPrimary}
                            size="small"
                        />
                    ) : null}
                </Flex>
            ) : (
                // version mobile
                <Flex space="between" vAlign="center" fill>
                    <Flex vAlign="center" gap="gap.small">
                        {/* chimmering  */}
                        <Flex>
                            {isLoading || isFetching ? (
                                <Chimmering width={80} />
                            ) : action ? (
                                <ButtonMobile
                                    tinted
                                    icon={ButtonIcon}
                                    content={action}
                                    flat
                                    primary={isPrimary}
                                    size="small"
                                />
                            ) : (
                                icon
                            )}
                        </Flex>

                        {/* actionText */}
                        <Flex>
                            {isLoading || isFetching ? (
                                <Chimmering width={200} />
                            ) : (
                                <CardText truncated content={actionText} />
                            )}
                        </Flex>
                    </Flex>
                    <Flex styles={{ paddingRight: "12px" }}>
                        {isDuedateAfterToday && (
                            <ErrorIcon
                                subtle={false}
                                pointing
                                position="after"
                                align="start"
                                content={`Date d'échéance : ${due_date}`}
                                trigger={icon}
                            />
                        )}
                    </Flex>
                </Flex>
            )}
        </CardStyle>
    );
};

export default Card;

const ButtonMobile = styled(Button)`
    background-color: #e8ebfa;
    width: 100px;
`;

const ButtonStyle = styled(Button)`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    min-width: 110px;
    padding-inline: 8px;
    border: 0;
    background-color: #f5f5f5;
    color: #242424;
`;

const CardText = styled(Text)`
    overflow: hidden;

    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    white-space: nowrap;

    text-overflow: ellipsis;

    @media ${DEVICE.mobileS} {
        width: 100px;
    }
    @media ${DEVICE.mobileM} {
        width: 160px;
    }
    @media ${DEVICE.tablet} {
        width: clamp(100px, 100%, 300px);
    }
`;

const CardStyle = styled(Flex)`
    border: 1px solid #d1d1d1;
    min-height: 48px;
    background-color: white;
    border-radius: 8px;

    @media ${DEVICE.mobileS} {
        box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
            0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
        padding-inline: 8px;
        width: 100%;
        /* width: 280px; */
    }
    @media ${DEVICE.mobileM} {
        /* width: calc(100vw - 32px); */
        padding-inline: 8px;
        /* width: 340px; */
        width: 100%;
    }
    @media ${DEVICE.mobileL} {
        box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
            0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
        padding-inline: 8px;
        /* width: 370px; */
        width: 100%;
    }
    @media ${DEVICE.tablet} {
        box-shadow: none;
        width: 100%;
        padding-inline: 8px;
    }
    @media ${DEVICE.laptop} {
        /* width: 444px; */
        width: 100%;
        padding-inline: 16px;
    }
`;

import React from "react";
import { ArrowRightIcon, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_LEAVE } from "../../../../../features/leave/constants";

const Solde = ({ balance_after_leave, balance_before_leave }) => {
    const { t } = useTranslation(I18N_NS_LEAVE);

    return (
        <Flex gap="gap.medium" vAlign="center">
            <DaysBefore
                content={
                    balance_before_leave +
                    " " +
                    t("timeline.dialogs.leave_request.day")
                }
            />
            <ArrowRightIcon size="small" />
            <DaysAfter
                content={
                    balance_after_leave +
                    " " +
                    t("timeline.dialogs.leave_request.day")
                }
            />
        </Flex>
    );
};

export default Solde;

const Days = styled(Text)`
    color: #5b5fc7;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;

const DaysBefore = styled(Days)`
    ::after {
        content: "avant";
        color: #424242;

        font-size: 10px;
        position: relative;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        top: 2px;
        left: 5px;
    }
`;

const DaysAfter = styled(Days)`
    ::after {
        content: "après";
        color: #424242;

        font-size: 10px;
        position: relative;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        top: 2px;
        left: 5px;
    }
`;

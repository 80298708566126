import React from "react";
import { Button, CloseIcon, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { AppsListDetail20Filled } from "@fluentui/react-icons";
import { I18N_NS_EXPENSE } from "../../constants";
import { useTranslation } from "react-i18next";
import ChatMessage from "./ChatMessage";
import { DEVICE } from "../../../../constants/responsive";

const EvolutionCard = ({
    history,
    amount_approved,
    currency,
    setToggleComment,
}) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    return (
        <Container>
            <Flex column hAlign="center" fill>
                <Flex
                    space="between"
                    fill
                    styles={{
                        backgroundColor: "#E8EBFA",
                        padding: "12px 20px",
                    }}
                >
                    <Flex gap="gap.small" vAlign="center">
                        <AppsListDetail20Filled />
                        <HeaderCommentCard
                            content={t("report.evolution.title")}
                        />
                    </Flex>
                    <Button
                        onClick={() => setToggleComment(false)}
                        size="small"
                        icon={<CloseIcon size="smaller" />}
                        iconOnly
                        title="Close"
                    />
                </Flex>
                <Flex
                    styles={{ padding: "24px 16px" }}
                    column
                    fill
                    gap="gap.medium"
                >
                    {history?.map((el) => {
                        const {
                            user: { avatar, first_name, last_name },
                            date,
                            status: { value: status_value },
                            comment,
                        } = el;

                        return (
                            <ChatMessage
                                avatar={avatar}
                                date={date}
                                full_name={first_name + " " + last_name}
                                status={status_value}
                                comment={comment} // for refuse note
                                amount_approved={
                                    status_value === 1 ? amount_approved : null
                                } // this for approval note
                                currency={currency} // this for approval note
                            />
                        );
                    })}
                </Flex>
            </Flex>
        </Container>
    );
};

export default EvolutionCard;

const Container = styled(Flex)`
    /* outline: 2px solid orange; */
    max-width: 480px;
    height: fit-content;

    background: #ffffff;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    /* margin-inline: 8px; */

    @media ${DEVICE.mobileS} {
        /* width: 300px; */
        width: 100%;

        margin-inline: 10px;
    }
    @media ${DEVICE.laptop} {
        width: 340px;
    }
    @media ${DEVICE.laptopL} {
        width: 480px;
    }
`;

const HeaderCommentCard = styled(Text)`
    color: #424242;
    font-family: Segoe UI;
    font-size: clamp(14px, 2vw, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: clamp(20px, 2vw, 24px);
`;

import React from "react";
import styled, { css } from "styled-components/macro";
import {
    Alert,
    Attachment,
    Button,
    Checkbox,
    Divider,
    Dropdown,
    ExclamationTriangleIcon,
    FilesPdfIcon,
    FilesUploadIcon,
    Flex,
    Input,
    RadioGroup,
    Text,
    TextArea,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import { DEVICE } from "../../../constants/responsive";
import { Controller, useFormContext } from "react-hook-form";
import {
    DATE_REFERENCES,
    DIALOG_ADD_TASK_TO_TEMPLATE__RESPONSABLE_DROPDOWN,
    DIALOG_ADD_TASK_TO_TEMPLATE__VALIDATOR_DROPDOWN,
    VALIDATOR_ADD_TASK_TEMPLATE,
} from "../constants";
import { ErrorMessage } from "../../../common/styles";
import { useQuery } from "react-query";
import {
    getListManagerAndHrOnAgency,
    getTaskCategory,
    getTaskResponsable,
    getTaskResponsables,
    getTaskValidators,
} from "../../../api/tasks";
import { BASE_URL_ILLUSTRATIONS } from "../../../constants";
import { useState } from "react";
import { TASK_ACTIONS } from "../../../constants/tasks";
import { file_size_format } from "../../../constants/maths";
import { uploadedFile } from "../../../utils/global";
import { useRef } from "react";
import { RequiredStar, TitleGroup } from "./style";

const DialogAddManualTaskTemplate = ({ setTemplateTasks }) => {
    const refButtonUpload = useRef(null);
    const [_, setRefresh] = useState(false);

    const {
        register,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        getValues,
    } = useFormContext();

    // get all categories
    const { data: dataCategory = [] } = useQuery(
        ["getCategory"],
        () => getTaskCategory(),
        {
            refetchOnWindowFocus: false,
        }
    );
    let { data: dataCollaborator = [] } = useQuery(
        ["getCollaborator"],
        () => getTaskResponsables(),
        {
            refetchOnWindowFocus: false,
        }
    );
    // get all validators users.
    let { data: dataValidators = [], isLoading: isLoadingValidators } =
        useQuery(
            ["getValidatorsTaskModels"],
            () => getListManagerAndHrOnAgency(),
            {
                refetchOnWindowFocus: false,
            }
        );

    // format the data of the collaborator for fluentui
    dataCollaborator = dataCollaborator.map((el) => {
        return {
            id: el?.id,
            header: el.name,
            image: el.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: el.job_title,
            username: el?.username,
        };
    });
    dataValidators = dataValidators.map((el) => {
        return {
            id: el?.id,
            header: el.name,
            image: el.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: el.job_title,
            username: el?.username,
        };
    });

    dataCollaborator.unshift(
        ...DIALOG_ADD_TASK_TO_TEMPLATE__RESPONSABLE_DROPDOWN
    );

    dataValidators.unshift(...DIALOG_ADD_TASK_TO_TEMPLATE__VALIDATOR_DROPDOWN);

    return (
        <Flex column gap="gap.small">
            <Flex.Item>
                <Text>
                    Pour ajouter une tâche, remplissez les informations
                    suivantes :
                </Text>
            </Flex.Item>
            <Flex>
                <TitleGroup content={`1 - Informations sur la tâche`} />
                <RequiredStar />
            </Flex>
            <Flex fill column>
                <Input
                    label="Titre de la tâche"
                    {...register("name", VALIDATOR_ADD_TASK_TEMPLATE.name)}
                    placeholder="Donnez un titre évocateur"
                    styles={{ marginBottom: "2px" }}
                    fluid
                    error={errors?.name ? true : false}
                />
                <ErrorMessage content={errors?.name?.message} />
            </Flex>
            <Flex fill vAlign="center" styles={{ alignSelf: "center" }} column>
                <Label>Catégorie de la tâche</Label>
                <Controller
                    control={control}
                    rules={VALIDATOR_ADD_TASK_TEMPLATE.category}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <DropdownStyle
                            checkable
                            items={dataCategory?.map((el) => el?.name)}
                            placeholder="Catégorie de la tâche"
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onBlur={onBlur} // notify when input is touched
                            onChange={(_, option) =>
                                onChange(
                                    dataCategory[option.highlightedIndex].id
                                )
                            } // send value to hook form
                            checked={value}
                            ref={ref}
                            error={errors?.category_id ? true : false}
                        />
                    )}
                    name="category_id"
                />
                {errors?.category_id && (
                    <ErrorMessage content={errors?.category_id.message} />
                )}
            </Flex>
            <Flex gap="gap.large" vAlign="end">
                <Flex.Item>
                    <Flex column fill>
                        <Input.Label>
                            Consignes pour accomplir la tâche
                        </Input.Label>
                        <TextArea
                            label="Consignes pour accomplir la tâche"
                            placeholder="Ajouter des indications ici"
                            {...register(
                                "description",
                                VALIDATOR_ADD_TASK_TEMPLATE.description
                            )}
                            error={errors?.description ? true : false}
                            fluid
                        />
                        <ErrorMessage content={errors?.description?.message} />
                    </Flex>
                </Flex.Item>
            </Flex>
            <Divider />
            <Flex>
                <TitleGroup
                    content={`2 - Que doit faire le collaborateur sur un document ? (facultatif)`}
                />
            </Flex>
            <Flex column gap="gap.small">
                <Input.Label>Action demandée au collaborateur</Input.Label>
                <Controller
                    control={control}
                    rules={VALIDATOR_ADD_TASK_TEMPLATE?.document_action}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <DropdownStyle
                            items={TASK_ACTIONS}
                            placeholder="Aucune action demandée"
                            defaultValue={TASK_ACTIONS[4]}
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onChange={(_, i) => {
                                const {
                                    document_required,
                                    id: document_action,
                                } = i?.value;
                                setValue("document_action", document_action);
                                setValue(
                                    "document_required",
                                    document_required
                                );
                                clearErrors("document_action");
                                setRefresh(new Date().getTime());
                                return;
                            }}
                            ref={ref}
                            onBlur={onBlur}
                            error={errors?.document_action ? true : false}
                            checkable
                        />
                    )}
                    name="document_action"
                />
                {errors?.document_action && (
                    <ErrorMessage content={errors?.document_action.message} />
                )}
                {getValues("document_required") &&
                    getValues("saved_files").length === 0 && (
                        <Flex column gap="gap.medium">
                            <Button
                                type="file"
                                icon={<FilesUploadIcon size="small" />}
                                content="Ajouter la pièce-jointe"
                                iconPosition="before"
                                onClick={() => refButtonUpload.current.click()}
                                tinted
                            />
                            <Input
                                ref={refButtonUpload}
                                type="file"
                                icon={<FilesUploadIcon size="small" />}
                                content="Ajouter la pièce-jointe"
                                iconPosition="before"
                                onChange={(e) =>
                                    uploadedFile(e.target.files, (args) => {
                                        setValue("saved_files", args);
                                        clearErrors("document_action");
                                        setRefresh(new Date().getTime());
                                        return;
                                    })
                                }
                                hidden
                            />
                            <Alert
                                icon={<ExclamationTriangleIcon />}
                                content="Pour les documents nominatifs, veuillez les ajouter à postériori"
                            />
                        </Flex>
                    )}
                {/* view the document */}
                {getValues("saved_files")?.map((el) => {
                    return (
                        <Attachment
                            icon={<FilesPdfIcon />}
                            header={el.filename || el.name}
                            description={file_size_format(el.filesize)}
                            actionable
                            action={{
                                icon: <TrashCanIcon />,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    setValue("saved_files", []);
                                    setRefresh(new Date().getTime());
                                },
                                title: "Remove",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            style={{ maxWidth: "100%" }}
                        />
                    );
                })}
            </Flex>
            <Divider />
            <Flex>
                <TitleGroup content={`3 - Date planifiée`} />
                <RequiredStar />
            </Flex>
            <FlexWrapperDates space="between">
                <Flex column>
                    <Input.Label>Date de référence</Input.Label>
                    <Controller
                        control={control}
                        rules={VALIDATOR_ADD_TASK_TEMPLATE.duedate_event}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                checkable
                                items={DATE_REFERENCES}
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                onBlur={onBlur}
                                onChange={(_, option) => {
                                    onChange(option?.value);
                                }}
                                checked={value}
                                ref={ref}
                                error={errors?.duedate_event ? true : false}
                                fluid
                            />
                        )}
                        name="duedate_event"
                    />
                    <ErrorMessage content={errors?.duedate_event?.message} />
                </Flex>
                <Flex column>
                    <Input.Label>Lien avec la date de référence</Input.Label>
                    <RadioGroup
                        onCheckedValueChange={(e, { value }) => {
                            setValue("duedate_operator", value);
                        }}
                        fluid
                        items={[
                            {
                                key: "1",
                                label: "Avant",
                                value: "1",
                            },
                            {
                                key: "2",
                                label: "Après",
                                value: "2",
                            },
                        ]}
                        defaultCheckedValue={"1"}
                    />
                </Flex>
                <Flex column styles={{ width: "160px" }}>
                    <Controller
                        control={control}
                        rules={VALIDATOR_ADD_TASK_TEMPLATE.duedate_duration}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => (
                            <Input
                                type="number"
                                label="Nombre de jours"
                                // onKeyDown={(e, i) => {
                                //     const value = parseInt(
                                //         getValues("duedate_duration")
                                //     );

                                //     if (e.key === "-" || e.key === "+") {
                                //         setValue("duedate_duration", value);
                                //         return onChange(value);
                                //     } else {
                                //         const new_value = parseInt(e.key);
                                //         if (!isNaN(new_value)) {
                                //             return onChange(
                                //                 e.target.value + "" + e.key
                                //             );
                                //         }
                                //     }
                                // }}
                                onChange={(e) => {
                                    console.log({ e });
                                    const value = parseInt(
                                        getValues("duedate_duration")
                                    );

                                    if (
                                        e.nativeEvent.data === "-" ||
                                        e.nativeEvent.data === "+"
                                    ) {
                                        setValue("duedate_duration", value);
                                        return onChange(value);
                                    } else {
                                        return onChange(e.target.value);
                                    }
                                }}
                                min="0"
                                error={errors?.duedate_duration ? true : false}
                                value={value}
                                name={name}
                                ref={ref}
                                clearable
                                fluid
                            />
                        )}
                        name="duedate_duration"
                    />
                    <ErrorMessage content={errors?.duedate_duration?.message} />
                </Flex>
            </FlexWrapperDates>
            <Divider />
            <Flex>
                <TitleGroup content={`4 - Personnes concernées`} />
                <RequiredStar />
            </Flex>
            <Flex column>
                <Input.Label>Qui va effectuer la tâche ?</Input.Label>
                <Controller
                    control={control}
                    rules={VALIDATOR_ADD_TASK_TEMPLATE.assigned_to}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <DropdownStyle
                            search
                            placeholder="Qui va effectuer la tâche ?"
                            checkable
                            items={dataCollaborator}
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onBlur={onBlur}
                            onChange={(_, option) => {
                                onChange(option?.value);
                            }}
                            checked={value}
                            ref={ref}
                            error={errors?.assigned_to ? true : false}
                            styles={{
                                width: "100%",
                            }}
                        />
                    )}
                    name="assigned_to"
                />
                <ErrorMessage content={errors?.assigned_to?.message} />
            </Flex>
            <Flex>
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <Checkbox
                            label="Tâche à faire valider"
                            onChange={(e, { checked }) => {
                                onChange(checked);
                                setRefresh(new Date().getTime());
                            }}
                            toggle
                            ref={ref}
                            onBlur={onBlur}
                        />
                    )}
                    name="task_to_validate"
                />
            </Flex>
            {getValues("task_to_validate") === true && (
                <Flex column>
                    <Input.Label>
                        Qui va valider la réalisation de la tâche ?
                    </Input.Label>
                    <Controller
                        control={control}
                        rules={VALIDATOR_ADD_TASK_TEMPLATE.validator}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                placeholder={
                                    "Qui va valider la réalisation de la tâche ?"
                                }
                                search
                                checkable
                                items={dataValidators.filter(
                                    (el) => el?.id !== -2
                                )}
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                onBlur={onBlur}
                                onChange={(_, option) => {
                                    onChange(option?.value);
                                }}
                                checked={value}
                                ref={ref}
                                error={errors?.validator ? true : false}
                                styles={{
                                    width: "100%",
                                }}
                            />
                        )}
                        name="validator"
                    />
                    <ErrorMessage content={errors?.validator?.message} />
                </Flex>
            )}
        </Flex>
    );
};

export default DialogAddManualTaskTemplate;

const Label = styled(Text)`
    -webkit-text-size-adjust: 100%;
    font-size: 0.875rem;
    text-align: left;
    color: rgb(17, 16, 15);
    unicode-bidi: isolate;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;

    display: block;
    transition: all 0.2s ease 0s;
    line-height: 1rem;
    margin-bottom: 0.25rem;
`;

export const DropdownStyle = styled(Dropdown)`
    ${({ positionFixed }) =>
        positionFixed
            ? css`
                  ul[role="listbox"] {
                      width: auto;
                  }
              `
            : css``}
    @media ${DEVICE.mobileS} {
        width: 100%;
        .ui-dropdown__container {
            width: 100%;
        }
    }
    @media ${DEVICE.tablet} {
        width: min-content;
        .ui-dropdown__container {
            width: auto;
        }
    }
    @media ${DEVICE.laptop} {
        width: 100%;
        .ui-dropdown__container {
            width: 100%;
        }
    }
`;

const FlexDescription = styled(Flex)`
    gap: 16px;
    align-items: flex-start;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const TextTitle = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
`;
export const FlexWrapperDates = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 12px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

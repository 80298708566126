import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const WeekName = styled(Text)`
    color: #424242;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
export const HourWorked = styled(Text)`
    color: #4f52b2;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
export const TotalText = styled(Text)`
    color: #252424;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;
export const TotalHourText = styled(Text)`
    color: #4f52b2;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;
export const InProgressHeader = styled(Flex)`
    border-radius: 8px 8px 0px 0px;
    background: var(--light-theme-rest-background-brand-background, #5b5fc7);
    height: 40px;
    /* shadow-2 */
    box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13),
        0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11);
`;
export const InProgressMonthName = styled(Text)`
    color: var(--light-theme-rest-foreground-default-foreground-3, #fff);
    text-align: center;

    /* Large/700 */

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;

export const OpenCard = styled(Flex)`
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13),
        0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11);
    width: 190px;
    height: fit-content;
`;

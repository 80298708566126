import { Content } from "./style";

export const steps = [
    {
        target: "#apply_type_week",
        title: "Semaine type",
        content: (
            <Content>
                <span>
                    Facilitez votre déclaration du mois en appliquant votre
                    semaine type. Une semaine type est une semaine complète avec
                    des créneaux pour chaque jour.
                </span>
            </Content>
        ),
        disableBeacon: true,
        placement: "right",
    },
    {
        target: "#modify_type_week",
        title: "Gestion de la semaine type",
        content: (
            <Content>
                <span>
                    Vous pouvez configurer votre semaine type à tout moment et
                    définir vos heures en fonction de votre rythme.
                </span>
            </Content>
        ),
        disableBeacon: true,
    },
    {
        target: "#card_1",
        title: "Modification d’un jour",
        content: (
            <Content>
                <span>
                    Vous pouvez modifier n’importe quel jour en cliquant sur le
                    jour pour ensuite ajouter, retirer ou modifier des créneaux
                </span>
            </Content>
        ),
        disableBeacon: true,
        placement: "right",
    },
    {
        target: ".save_or_modify_week",
        title: "Enregistrer la semaine",
        content: (
            <Content>
                <span>
                    Pour déclarer un mois vous devez enregistrer chaque semaine
                    du mois.
                    <br />
                    <br />
                    Pensez bien à cliquer sur le bouton{" "}
                    <b>Enregistrer la semaine</b> pour que votre déclaration
                    soit retenue
                </span>
            </Content>
        ),
        disableBeacon: true,
        placement: "left",
    },
    {
        target: "#slider_weeks",
        title: "Navigation par semaine",
        content: (
            <Content>
                <span>
                    Suivez l’avancement de votre déclaration par semaine et
                    naviguez plus facilement.
                </span>
            </Content>
        ),
        disableBeacon: true,
        placement: "right",
        spotlightPadding: 20,
    },
    {
        target: "#submit_month",
        title: "Déclaration du mois",
        content: (
            <Content>
                <span>
                    Pour déclarer le mois il faut déclarer toutes les semaines
                    du mois.
                    <br />
                    <br />
                    Une fois le mois envoyé pour validation à votre hiérarchie
                    les créneaux ne seront plus modifiables.
                </span>
            </Content>
        ),
        disableBeacon: true,
        placement: "left",
    },
];

import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import HeaderPreonboarding from "./HeaderPreonboarding";
import styled from "styled-components";

const PreonboardingTaskDetail = () => {
    return (
        <Flex column>
            <HeaderPreonboarding />
            <LeftSide>
                <Flex column>
                    <Text content={"Copie du passeport"} />
                    <Text content={"Identité & admin"} />
                </Flex>
            </LeftSide>
        </Flex>
    );
};

export default PreonboardingTaskDetail;

const LeftSide = styled(Flex)``;

import { Flex, Skeleton } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const HEIGHT = 34.7 * 4 + "px";

const SkeletonRows = () => {
    return (
        <Flex column gap="gap.medium">
            <Skeleton animation="pulse">
                <Skeleton.Line height={HEIGHT} />
                <Divider />
                <Skeleton.Line height={HEIGHT} />
                <Divider />
                <Skeleton.Line height={HEIGHT} />
                <Divider />
                <Skeleton.Line height={HEIGHT} />
            </Skeleton>
        </Flex>
    );
};

export default SkeletonRows;

const Divider = styled(Flex)`
    height: 24px;
`;

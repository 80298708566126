import moment from "moment";
import { TIMES_SPLIT_DAY } from "../constants";
import {
    _api_add_day_remote,
    _api_delete_day_remote,
    _api_get_data_event_by_id,
} from "../api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToastRemote } from "../store/useToastRemote";

/**
 * @typedef {Object} PropsTypeContent
 * @property {number} event_id - id de l'event
 * @property {import("../components/PopupContent").Dates} dates
 * @property {import("../components/PopupContent").Times} times
 *
 * @param {PropsTypeContent} props
 * @returns
 */
const useLogicRemotePopupContent = ({ event_id, dates, times }) => {
    // useQueryClient
    const queryClient = useQueryClient();

    // context toast alert
    const ctx_toast = useToastRemote();

    // constantes
    const format_start_str =
        moment(dates.start).format("ddd DD/MM/YYYY") +
        " - " +
        TIMES_SPLIT_DAY[times.start];
    const format_end_str =
        moment(dates.end).format("ddd DD/MM/YYYY") +
        " - " +
        TIMES_SPLIT_DAY[times.end];

    // delete an Event remote
    const { data, isLoading, mutate } = useMutation(({ event_id }) =>
        _api_delete_day_remote({
            event_id,
        })
    );

    // fetch data, event
    const {
        data: info_event,
        isLoading: is_loading_info_event,
        refetch: refecth_info_event,
    } = useQuery(["remote::get_data_event_by_id", event_id], () => {
        return _api_get_data_event_by_id({ event_id });
    });

    // FUNCTIONS
    const handleClickDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        mutate(
            { event_id },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        ctx_toast.setShow({
                            visible: true,
                            content: "Votre déclaration à bien été supprimée",
                            level: "success",
                        });
                        //refetch all data
                        queryClient.refetchQueries(
                            "remote::get_all_remote_data"
                        );

                        return;
                    } else {
                        ctx_toast.setShow({
                            visible: true,
                            content: data?.message,
                            level: "danger",
                        });
                        return;
                    }
                },
            }
        );
        return;
    };

    return {
        mutate: {
            data,
            isLoading,
            call_api_delete_event: mutate,
            handleClickDelete,
        },
        query: {
            isLoading: is_loading_info_event,
            data: info_event,
            refetch: refecth_info_event,
        },
        strings: {
            format_start_str,
            format_end_str,
        },
    };
};

export default useLogicRemotePopupContent;

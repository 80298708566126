import {
    AddIcon,
    Button,
    ChevronDownIcon,
    EditIcon,
    ErrorIcon,
    EyeFriendlierIcon,
    Flex,
    Image,
    SendIcon,
    TaskListIcon,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
import Card from "./Card";
import EmptyTodoTask from "../img/EmptyTodoTask.png";
import {
    ACTIONS_DOCUMENTS,
    BADGE_ACTION_DOCUMENTS,
    TASK_ACTIONS,
    TASK_STATUS,
} from "../../../constants/tasks";
import DialogDetailTask from "../../../pages/DashboardTasks/DialogDetailTask";
import Toast from "../../../components/Alerts";
import { useQueryClient } from "react-query";
import { DEVICE } from "../../../constants/responsive";
import { useNavigate } from "react-router-dom";
import {
    DocumentBulletListClock24Filled,
    CheckmarkStarburst20Filled,
} from "@fluentui/react-icons";

const DocumentToUpload = ({
    state,
    setstate,
    todo,
    isLoading,
    isFetching,
    lengthTodo,
}) => {
    const [configToast, setConfigToast] = useState({
        visible: false,
        content: "",
        level: "warning",
    });

    return (
        <ToUploadFlex column>
            <HeaderToDo fill vAlign="center" space="between">
                <Toast
                    level={configToast.level}
                    content={configToast.content}
                    visible={configToast.visible}
                    onStatusChange={() =>
                        setConfigToast({
                            visible: false,
                            content: "",
                            level: "warning",
                        })
                    }
                />
                <LeftHeaderTodo vAlign="center">
                    <Flex
                        styles={{
                            background: "#5B5FC7",
                            padding: "6px",
                            borderRadius: "4px",
                        }}
                    >
                        <TaskListIcon size="large" />
                    </Flex>
                    <HeaderTextTodo content="Action à effectuer" />
                </LeftHeaderTodo>
                <RightHeader vAlign="center">
                    <Badge vAlign="center" hAlign="center">
                        {todo?.length}
                    </Badge>
                    {state ? (
                        <ChevronDownIcon
                            onClick={() => setstate(!state)}
                            outline
                            style={{
                                color: "white",
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                            }}
                            rotate={180}
                        />
                    ) : (
                        <ChevronDownIcon
                            onClick={() => setstate(!state)}
                            outline
                            style={{
                                color: "white",
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                            }}
                        />
                    )}
                </RightHeader>
            </HeaderToDo>
            {state ? (
                <ContentTodo column>
                    <Flex
                        column
                        hAlign="center"
                        fill
                        style={{
                            padding: "16px",
                            gap: "8px",
                        }}
                    >
                        {lengthTodo === 0 ? (
                            <Flex
                                gap="gap.medium"
                                fill
                                vAlign="center"
                                column
                                hAlign="center"
                            >
                                <Image src={EmptyTodoTask} />
                                <Title content="Toutes les actions ont été effectuées" />
                                <DescriptionText content="Vous receverez un mail en cas d'autres actions à faire" />
                            </Flex>
                        ) : (
                            todo?.map((el) => {
                                return (
                                    <Card
                                        el={el}
                                        isPrimary={true}
                                        ButtonIcon={null}
                                        action={
                                            TASK_ACTIONS.find(
                                                (action) =>
                                                    action.id ===
                                                    el?.document_action
                                            )?.element
                                        }
                                        actionText={el?.name}
                                        icon={
                                            <CheckmarkStarburst20Filled
                                                style={{
                                                    color: "green",
                                                }}
                                            />
                                        }
                                        due_date={el?.due_date}
                                        isLoading={isLoading}
                                        isFetching={isFetching}
                                        status={status}
                                    />
                                );
                                // return (
                                //     <DialogDetailTask
                                //         trigger={
                                //             <Card
                                //                 isPrimary={true}
                                //                 ButtonIcon={null}
                                //                 action={
                                //                     TASK_ACTIONS.find(
                                //                         (action) =>
                                //                             action.id ===
                                //                             el?.document_action
                                //                     )?.element
                                //                 }
                                //                 actionText={el?.name}
                                //                 icon={<ErrorIcon />}
                                //                 due_date={el?.due_date}
                                //                 isLoading={isLoading}
                                //                 isFetching={isFetching}
                                //             />
                                //         }
                                //         hideCreationAndValidation={true}
                                //         key={el?.id}
                                //         task={{
                                //             name: el?.name,
                                //             category: {
                                //                 name: el?.category?.name,
                                //             },
                                //             description: el?.description,
                                //             document_required:
                                //                 el?.document_required,
                                //             due_date: el?.due_date,
                                //             status: TASK_STATUS.TODO,
                                //             document_action:
                                //                 el?.document_action,
                                //             documents: el?.documents,
                                //             documents_template:
                                //                 el?.documents_template,
                                //             id: el?.id,
                                //             assigned_to_user: {
                                //                 id: el?.assigned_to_user?.id,
                                //             },
                                //             task_to_validate:
                                //                 el?.task_to_validate,
                                //         }}
                                //         onSuccess={({ data, message }) => {
                                //             setConfigToast({
                                //                 visible: true,
                                //                 content: message,
                                //                 level: "success",
                                //             });

                                //             queryClient.refetchQueries([
                                //                 "preonboarding_information",
                                //             ]);
                                //         }}
                                //         onError={({ message }) => {
                                //             setConfigToast({
                                //                 visible: true,
                                //                 content: message,
                                //                 level: "danger",
                                //             });
                                //         }}
                                //     />
                                // );
                            })
                        )}
                    </Flex>
                </ContentTodo>
            ) : null}
        </ToUploadFlex>
    );
};

export default DocumentToUpload;

const ToUploadFlex = styled(Flex)`
    @media ${DEVICE.mobileS} {
        padding-inline: 12px;
    }
    @media ${DEVICE.mobileL} {
        padding-inline: 0px;
    }
`;

const DescriptionText = styled(Text)`
    color: #242424;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 250px;
`;

const Title = styled(Text)`
    color: #000;
    text-align: center;
    width: 250px;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;

const RightHeader = styled(Flex)`
    gap: 14px;
`;

const ContentTodo = styled(Flex)`
    overflow-y: scroll;
    height: 541px;
    background-color: #e8ebfa;
`;
const HeaderToDo = styled(Flex)`
    background: #2f2f4a;
    height: 60px;
    @media ${DEVICE.mobileS} {
        padding: 16px;
    }
    @media ${DEVICE.tablet} {
        padding: 0px 16px 0px 32px;
    }
    @media ${DEVICE.laptop} {
        padding: 0px 16px 0px 32px;
    }
`;

const HeaderTextTodo = styled(Text)`
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;
const LeftHeaderTodo = styled(Flex)`
    color: white;
    gap: 12px;
`;
const Badge = styled(Flex)`
    height: 24px;
    width: 24px;
    background-color: #ffb900;
    border-radius: 100%;
    color: white;
    font-weight: bold;
`;

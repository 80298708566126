import React from "react";
import { Flex, Skeleton } from "@fluentui/react-northstar";
import {
    CardBody,
    CardHeader,
    Container,
    HoursText,
    WrapperHours,
} from "../CardWeek/index.style";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { useShowWeekend } from "../../stores";

const CardWeekSkeleton = () => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const ctx_show_weekend = useShowWeekend((state) => state.show);
    const new_array = new Array(ctx_show_weekend ? 7 : 5).fill("");
    return new_array?.map((e) => {
        return (
            <Skeleton animation="pulse">
                <Container column styles={{ width: "190px" }}>
                    <CardHeader
                        is_today={false}
                        vAlign="center"
                        space="between"
                    >
                        <Skeleton.Line width="62px" height="24px" />
                    </CardHeader>
                    <Flex hAlign="center" styles={{ height: "80px" }}>
                        <WrapperHours vAlign="end" hAlign="center">
                            <Skeleton.Line width="45px" height="32px" />
                            <HoursText
                                content={t("v2.home.labels.hours_count")}
                            />
                        </WrapperHours>
                    </Flex>
                    <CardBody fill hAlign="center" column gap="gap.large">
                        <Skeleton.Line
                            width="140px"
                            height="60px"
                            styles={{ borderRadius: "16px" }}
                        />
                        <Skeleton.Line
                            width="140px"
                            height="32px"
                            styles={{ borderRadius: "4px" }}
                        />
                        <Skeleton.Line
                            width="140px"
                            height="60px"
                            styles={{ borderRadius: "16px" }}
                        />
                    </CardBody>
                </Container>
            </Skeleton>
        );
    });
};

export default CardWeekSkeleton;

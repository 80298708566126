import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const PROCESS = [
    {
        id: 1,
        content: "Onboarding",
    },
    {
        id: 2,
        content: "Suivi",
    },
    {
        id: 3,
        content: "Offboarding",
    },
];

const TaskProcessus = ({ process_id, minWidth = "fit-content" }) => {
    // find the content with the process_id
    const { content } = PROCESS.find((process) => process.id === process_id);
    return (
        <Wrapper minWidth={minWidth}>
            <Label content={content} />
        </Wrapper>
    );
};

export default TaskProcessus;

const Label = styled(Text)`
    color: #4f52b2;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
`;

const Wrapper = styled(Flex)`
    border-radius: 4px;
    border: 1px solid #fff;
    background: #e8ebfa;

    height: 20px;
    width: ${({ minWidth }) => (minWidth ? minWidth : "auto")};
    padding: 0px 8px;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

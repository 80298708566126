import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const WrapperContent = styled(Flex)`
    width: 328px;
`;
export const TextUsername = styled(Text)`
    color: #000;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;
export const TextDates = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

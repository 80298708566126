import {
    ArrowRightIcon,
    Button,
    Dialog,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
import FooterActions from "../FooterActions";
import { useParams } from "react-router-dom";
import Toast from "../../../../components/Alerts";

export default function SuiviAction() {
    const [toastConfig, setToastConfig] = useState({
        content: "",
        visible: false,
        level: "warning",
    });
    const stopPreonboarding = () => {
        alert("stop preonboarding");
    };
    const id = useParams();

    // console.log({ udoh: id?.id_user });

    return (
        <FlexStyled fill hAlign="end">
            <Toast
                visible={toastConfig?.visible}
                content={toastConfig?.content}
                level={toastConfig?.level}
                onStatusChange={() => {
                    return setToastConfig({
                        visible: false,
                        content: "",
                        level: "warning",
                    });
                }}
            />
            <FooterActions
                activeTab={4}
                setToastConfig={setToastConfig}
                user_id={id}
                badgeText="Suivi"
            />
        </FlexStyled>
    );
}

const Label = styled(Text)``;

const FlexStyled = styled(Flex)`
    border-bottom: 1px solid #d1d1d1;
    background: #fff;
    box-shadow: 0px -1.2px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px -6.4px 14.4px 0px rgba(0, 0, 0, 0.13);
    padding: 24px;
    margin-block: 32px;
`;

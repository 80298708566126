import styled from "styled-components";

export const Content = styled.div`
    padding-block: 16px;
`;
export const Buttons = styled.div`
    display: flex;
    /* justify-content: flex-end; */
`;

export const WrapperTooltip = styled.div`
    background-color: var(--color-primary);
    max-width: 280px;
    min-height: 190px;
    color: white;
    padding: 16px;
`;
export const WrapperTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;
export const TooltipTitle = styled.span`
    color: #fff;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
`;
export const Hr = styled.hr`
    opacity: 0.1;
    border-color: var(--Primitive-White, #fff);
`;
export const WrapperBalls = styled.div`
    display: flex;
    justify-content: center;
    gap: 4px;
`;
export const Ball = styled.span`
    color: ${({ $active }) => ($active ? "white" : "rgba(255,255,255,0.5)")};
`;
export const WrapperBallsAndNext = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
`;

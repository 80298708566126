import { Button, Checkbox, Flex, Text } from "@fluentui/react-northstar";

import React, { useEffect, useState } from "react";

const EmployeeExporterRegister = ({
    checkboxes,
    setCheckboxes,
    viewFormer,
    setViewFormer,
}) => {
    const handleSelectAllCheckboxes = (_, i) => {
        const isChecked = i.checked;

        const isPersonalClick = i?.role === "SELECT_ALL_PERSONAL";

        setCheckboxes((state) => {
            const keys = Object.keys(
                isPersonalClick ? state.personal : state.contract
            );
            const personal = {};
            const contract = {};
            const new_state = {
                ...state,
            };

            for (let i = 0; i < keys.length; i++) {
                const element = keys[i];

                if (isPersonalClick) {
                    personal[element] = isChecked;
                    new_state.personal = personal;
                } else {
                    contract[element] = isChecked;
                    new_state.contract = contract;
                }
            }
            return new_state;
        });
    };

    const handleUniqueCheckboxes = (event, i) => {
        const isChecked = i?.checked;
        const isPersonalClick = i?.role === "SELECT_ALL_PERSONAL";
        if (isPersonalClick) {
            return setCheckboxes((state) => {
                return {
                    ...state,
                    personal: {
                        ...state.personal,
                        [i?.name]: isChecked,
                    },
                };
            });
        }

        setCheckboxes((state) => {
            return {
                ...state,
                contract: {
                    ...state.contract,
                    [i?.name]: isChecked,
                },
            };
        });
        return;
    };

    const isEverySelected = Object.entries(checkboxes.personal).every(
        (value) => value[1] === true
    );

    const isSomeSelected = Object.entries(checkboxes.personal).some(
        (value) => value[1] === true
    );

    const isEverySelectedContract = Object.entries(checkboxes.contract).every(
        (value) => value[1] === true
    );

    const isSomeSelectedContract = Object.entries(checkboxes.contract).some(
        (value) => value[1] === true
    );

    return (
        <Flex column gap="gap.medium">
            <Text content="Choisir les informations à inclure dans l’export :" />
            <Checkbox
                label="Inclure les anciens salariés"
                toggle
                onChange={(e, i) => setViewFormer(i?.toggle)}
            />
            <Flex styles={{ width: "100%" }}>
                <Flex column styles={{ width: "50%" }}>
                    <Checkbox
                        checked={
                            isEverySelected
                                ? true
                                : isSomeSelected
                                ? "mixed"
                                : false
                        }
                        onChange={(e, i) => handleSelectAllCheckboxes(e, i)}
                        label={<b>Information personnel</b>}
                        role="SELECT_ALL_PERSONAL"
                    />
                    <Checkbox checked disabled label="Nom" />
                    <Checkbox checked disabled label="Prénom" />
                    <Checkbox
                        defaultValue={checkboxes.personal.secu}
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        checked={checkboxes.personal.secu}
                        label="N° de sécurité sociale ou AVS"
                        name="secu"
                        role="SELECT_ALL_PERSONAL"
                    />
                    <Checkbox
                        defaultValue={checkboxes.personal.id_type}
                        value={checkboxes.personal.id_type}
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        checked={checkboxes.personal.id_type}
                        label="Type de pièce d’identité"
                        name={"id_type"}
                        role="SELECT_ALL_PERSONAL"
                    />
                    <Checkbox
                        defaultValue={checkboxes.personal.id_number}
                        value={checkboxes.personal.id_number}
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        checked={checkboxes.personal.id_number}
                        label="N° de pièce d’identité"
                        name={"id_number"}
                        role="SELECT_ALL_PERSONAL"
                    />
                    <Checkbox
                        defaultValue={checkboxes.personal.nationality}
                        value={checkboxes.personal.nationality}
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        checked={checkboxes.personal.nationality}
                        label="Nationalité"
                        name={"nationality"}
                        role="SELECT_ALL_PERSONAL"
                    />
                    <Checkbox
                        defaultValue={checkboxes.personal.birthdate}
                        value={checkboxes.personal.birthdate}
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        checked={checkboxes.personal.birthdate}
                        label="Date de naissance"
                        name={"birthdate"}
                        role="SELECT_ALL_PERSONAL"
                    />
                    <Checkbox
                        value={checkboxes.personal.gender}
                        defaultValue={checkboxes.personal.gender}
                        checked={checkboxes.personal.gender}
                        label="Sexe"
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        name="gender"
                        role="SELECT_ALL_PERSONAL"
                    />
                    <Checkbox
                        value={checkboxes.personal.address}
                        defaultValue={checkboxes.personal.address}
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        checked={checkboxes.personal.address}
                        label="Adresse"
                        name="address"
                        role="SELECT_ALL_PERSONAL"
                    />
                </Flex>
                <Flex column styles={{ width: "50%" }}>
                    <Checkbox
                        checked={
                            isEverySelectedContract
                                ? true
                                : isSomeSelectedContract
                                ? "mixed"
                                : false
                        }
                        onChange={(e, i) => handleSelectAllCheckboxes(e, i)}
                        label={<b>Information Contractuel</b>}
                        role="SELECT_ALL_CONTRACT"
                    />
                    <Checkbox
                        label="Emploi et qualification"
                        defaultValue={checkboxes.contract.job}
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        checked={checkboxes.contract.job}
                        name="job"
                        role="SELECT_ALL_CONTRACT"
                    />

                    <Checkbox
                        label="Type de contrat"
                        defaultValue={checkboxes.contract.contract_type}
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        checked={checkboxes.contract.contract_type}
                        name="contract_type"
                        role="SELECT_ALL_CONTRACT"
                    />
                    <Checkbox
                        label="Date d’embauche"
                        defaultValue={checkboxes.contract.start_date}
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        checked={checkboxes.contract.start_date}
                        name="start_date"
                        role="SELECT_ALL_CONTRACT"
                    />
                    <Checkbox
                        label="Date de départ"
                        defaultValue={checkboxes.contract.end_date}
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        checked={checkboxes.contract.end_date}
                        name="end_date"
                        role="SELECT_ALL_CONTRACT"
                    />
                    <Checkbox
                        label="Service ou département"
                        defaultValue={checkboxes.contract.department}
                        onChange={(e, i) => handleUniqueCheckboxes(e, i)}
                        checked={checkboxes.contract.department}
                        name="department"
                        role="SELECT_ALL_CONTRACT"
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default EmployeeExporterRegister;

export const file_size_format = (number) => {
    return new Intl.NumberFormat("fr-FR", {
        notation: "compact",
        compactDisplay: "short",
    }).format(number);
};

export const format_number = (number) => {
    return new Intl.NumberFormat("fr-FR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(number);
};
/**
 * cette fonction permet de traduire un code 3 lettres d'une devise en Symbole de la devise
 * @param {{ amount: number, currency: string }} currencyObject
 * @returns {number}
 */
export const currency_code_to_symbols = ({ amount, currency }) => {
    if (isNaN(amount)) return "";
    return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency,
    }).format(amount);
};

export const formatFileSize = (bytes, decimalPoint) => {
    if (bytes == 0) return "0 o";
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ["Octets", "Ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const WrapperRowCurrency = styled(Flex)`
    padding: 8px 0px 8px 12px;
    align-items: flex-start;
    gap: 40px;
    cursor: pointer;
    height: 40px;
`;

export const WrapperListCurrencies = styled(Flex)`
    height: 300px;
    overflow-y: scroll;
`;

export const CurrencyCode = styled(Text)`
    color: #242424;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 50px;
`;

export const CurrencyName = styled(Text)`
    color: #242424;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

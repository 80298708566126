import {
    Button,
    ChevronEndIcon,
    Flex,
    Image,
    Skeleton,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components/macro";
import { get_symbol_currency } from "../../expenses/functions";
import ExpenseEmptyIcon from "../../../assets/illustrations/expenses/expense_empty.svg";
import { useState } from "react";
import SliderTabs from "../../../components/shared/SliderTabs";
import moment from "moment";
import { Container, ContainerFluid } from "../../../common/styles";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import AsUserN2F from "./N2fChart/AsUserN2F";
import AsManagerN2F from "./N2fChart/AsManagerN2F";
import EmptyN2F from "./N2fChart/EmptyN2F";
import Overlay from "../../home/components/Overlay";
import { DEVICE } from "../../../constants/responsive";

const OverlayChart = ({
    isManager,
    is_pre_onboarding,
    expenses,
    expenses_user,
    total_as_user,
}) => {
    return (
        <Flex
            gap="gap.medium"
            styles={{
                position: "absolute",
                inset: "0",
                opacity: "0.08",
            }}
        >
            <Flex
                space={"between"}
                gap="gap.medium"
                hAlign="center"
                column
                styles={{ width: "500px" }}
            >
                {isManager && !is_pre_onboarding && (
                    <SliderTabs
                        tabs={["Mes dépenses", "Mon équipe"]}
                        value={0}
                        defaultActiveIndex={0}
                    />
                )}

                {!isManager && !is_pre_onboarding && (
                    <ExpensesTitleStyle
                        content={`Mes dépenses sur l'année ${moment().year()}`}
                    />
                )}
                <EmptyN2F
                    ExpenseEmptyIcon={ExpenseEmptyIcon}
                    ShowLink={false}
                    ShowText={false}
                />
            </Flex>
        </Flex>
    );
};

const ChartN2F = ({
    expenses,
    isManager,
    is_pre_onboarding,
    isLoading = false,
}) => {
    const [expensesData, SetExpensesData] = useState(0);

    if (isLoading) {
        return (
            <CardExpense
                space={"between"}
                gap="gap.medium"
                hAlign="center"
                column
            >
                <SkeletonStyle animation="pulse">
                    <Skeleton.Line width="220px" height="30px" />
                    <Skeleton.Shape round width="120px" height="120px" />
                    <Flex
                        wrap
                        gap="gap.small"
                        hAlign="center"
                        styles={{ rowGap: "12px" }}
                    >
                        <Flex gap="gap.smaller">
                            <Skeleton.Line width="26px" height="26px" />
                            <Skeleton.Line width="126px" height="26px" />
                        </Flex>
                        <Flex gap="gap.smaller">
                            <Skeleton.Line width="26px" height="26px" />
                            <Skeleton.Line width="126px" height="26px" />
                        </Flex>
                        <Flex gap="gap.smaller">
                            <Skeleton.Line width="26px" height="26px" />
                            <Skeleton.Line width="126px" height="26px" />
                        </Flex>
                        <Flex gap="gap.smaller">
                            <Skeleton.Line width="26px" height="26px" />
                            <Skeleton.Line width="126px" height="26px" />
                        </Flex>
                        <Skeleton.Button size="smaller" width="126px" />
                    </Flex>
                </SkeletonStyle>
            </CardExpense>
        );
    }

    const expenses_user = {
        labels: expenses?.as_user?.map((el) => el?.name),
        datasets: [
            {
                label: "Total",
                data: expenses?.as_user?.map((el) => el?.total),
                backgroundColor: expenses?.as_user?.map((el) => el?.color),
                circumference: 360,
                cutout: 65,
            },
        ],
    };
    const expenses_Manager = {
        labels: expenses?.as_manager?.map((el) => el?.name),
        datasets: [
            {
                label: "Total",
                data: expenses?.as_manager?.map((el) => el?.total),
                backgroundColor: expenses?.as_manager?.map((el) => el?.color),
                circumference: 360,
                cutout: 65,
            },
        ],
    };

    const total_as_user = expenses?.as_user
        ?.map((el) => el?.total)
        .reduce((prev, current) => prev + current, 0);
    const total_as_manager = expenses?.as_manager
        ?.map((el) => el?.total)
        .reduce((prev, current) => prev + current, 0);

    return (
        <CardExpense space={"between"} gap="gap.medium" column hAlign="center">
            {isManager && !is_pre_onboarding && (
                <SliderTabs
                    tabs={["Mes dépenses", "Mon équipe"]}
                    value={0}
                    defaultActiveIndex={0}
                    onChange={(e) => SetExpensesData(e)}
                />
            )}
            {expensesData === 0 && (
                <ExpensesTitleStyle
                    content={`Mes dépenses sur l'année ${moment().year()}`}
                />
            )}
            {expensesData === 1 && (
                <ExpensesTitleStyle
                    content={`Dépenses de l'équipe sur l'année ${moment().year()}`}
                />
            )}
            {/* {!isManager && !is_pre_onboarding && (
            )} */}
            <FlexBody gap="gap.small" column hAlign="center">
                {expenses?.as_user?.length && expensesData === 0 ? (
                    <AsUserN2F
                        expenses_user={expenses_user}
                        total_as_user={total_as_user}
                        expenses={expenses}
                        ShowLink={true}
                        activeTab={0}
                    />
                ) : expenses?.as_manager?.length && expensesData === 1 ? (
                    <AsManagerN2F
                        expenses_Manager={expenses_Manager}
                        total_as_manager={total_as_manager}
                        expenses={expenses}
                        ShowLink={true}
                        activeTab={1}
                    />
                ) : !is_pre_onboarding && expenses?.as_user?.length === 0 ? (
                    <EmptyN2F
                        ExpenseEmptyIcon={ExpenseEmptyIcon}
                        ShowText={true}
                        ShowLink={true}
                    />
                ) : (
                    <Overlay
                        content={"Bientôt disponible"}
                        Background={OverlayChart}
                    />
                )}
            </FlexBody>
        </CardExpense>
    );
};

export default ChartN2F;

const LinkStyle = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0067b8;
`;

const LinkToN2F = styled(Flex)`
    cursor: pointer;
`;

const CardExpense = styled(Flex)`
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    padding: 24px 24px 30px 24px;
    border-radius: 8px;
    background-color: white;
    min-height: 350px;
    @media ${DEVICE.mobileS} {
        /* margin-top: 0px; */
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: 445px;
        /* margin-top: 55px; */
    }
`;

const Total = styled(Text)`
    font-weight: 700;
    font-size: 24px;

    position: absolute;
    top: 34%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: fit-content;

    color: #242424;
`;

const ExpensesTitleStyle = styled(Text)`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
const FlexBody = styled(Flex)`
    min-height: 250px;
    position: relative;
    width: 100%;
`;
const SkeletonStyle = styled(Skeleton)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
`;

import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
} from "@mui/material";
import React, { useState } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import BookmarkRemoveOutlinedIcon from "@mui/icons-material/BookmarkRemoveOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import HomeIcon from "@mui/icons-material/Home";
import useSidebar from "./Context.sideBar";

function SideBar(props) {
    const navigate = useNavigate();

    const activeNav = useSidebar((state) => state.activeNav);
    const setActiveNav = useSidebar((state) => state.setActiveNav);
    const toggleSideBar = useSidebar((state) => !state.toggleSideBar);

    const _handleClick = (id, label = "home") => {
        setSideBar((prevState) => {
            return prevState.map((el) => {
                if (el.id === id) {
                    return { ...el, nested: !el.nested };
                } else {
                    return el;
                }
            });
        });
    };

    const initialState = [
        {
            id: 1,
            name: "Dashboard",
            title: "Dashboard",
            icon: <WidgetsOutlinedIcon title="Dashboard " />,
            nested: activeNav === "home" ? true : false,
            nestedChilds: (
                //useNavigation
                <Collapse
                    key={"collapse1"}
                    in={true}
                    timeout="auto"
                    unmountOnExit
                    onClick={() => navigate("/")}
                >
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Home"
                                style={{
                                    color:
                                        activeNav === "home" ? "#1565c0" : "",
                                }}
                                onClick={() => {
                                    // setRefresher((refresher) => refresher++);
                                    setActiveNav("home");
                                }}
                            />
                        </ListItemButton>
                    </List>
                </Collapse>
            ),
            onClick: () => _handleClick(1),
        },
        {
            id: 2,
            name: "Dossier RH",
            title: "Dossier RH",
            icon: <FolderSharedOutlinedIcon />,
            nested: activeNav === "rh" ? true : false,
            nestedChilds: (
                <Collapse
                    key={"collapse2"}
                    in={true}
                    timeout="auto"
                    unmountOnExit
                    onClick={() => navigate("/addCollaborator")}
                >
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Details Rh"}
                                style={{
                                    color: activeNav === "rh" ? "#1769aa" : "",
                                }}
                                onClick={() => {
                                    setActiveNav("rh");
                                    // setRefresher((refresher) => refresher++);
                                }}
                            />
                        </ListItemButton>
                    </List>
                </Collapse>
            ),
            onClick: () => _handleClick(2),
        },
        {
            id: 3,
            name: "Documents",
            title: "Documents",
            icon: <CreateNewFolderOutlinedIcon />,
            nested: false,
            nestedChilds: (
                <Collapse
                    key={"collapse3"}
                    in={true}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary="Details" />
                        </ListItemButton>
                    </List>
                </Collapse>
            ),
            onClick: () => _handleClick(3),
        },
        {
            id: 4,
            name: "On boarding",
            title: "On boarding",
            icon: <BookmarkAddOutlinedIcon />,
            nested: false,
            nestedChilds: (
                <Collapse
                    onClick={() => navigate("/onboarding")}
                    key={"collapse4"}
                    in={true}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary="Onboarding" />
                        </ListItemButton>
                    </List>
                </Collapse>
            ),
            onClick: () => _handleClick(4),
        },
        {
            id: 5,
            name: "Off boarding",
            title: "Off boarding",
            icon: <BookmarkRemoveOutlinedIcon />,
            nested: false,
            nestedChilds: (
                <Collapse
                    key={"collapse5"}
                    in={true}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary="Off" />
                        </ListItemButton>
                    </List>
                </Collapse>
            ),
            onClick: () => _handleClick(5),
        },
        {
            id: 6,
            name: "Absences",
            title: "Absences",
            icon: <RuleOutlinedIcon />,
            nested: false,
            nestedChilds: (
                <Collapse
                    key={"collapse6"}
                    in={true}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary="calendrier" />
                        </ListItemButton>
                    </List>
                </Collapse>
            ),

            onClick: () => _handleClick(6),
        },
        {
            id: 7,
            name: "Time Reporting",
            title: "Time Reporting",
            icon: <AccessTimeOutlinedIcon />,
            nested: false,

            nestedChilds: (
                <Collapse
                    key={"collapse7"}
                    in={true}
                    timeout="auto"
                    unmountOnExit
                    title="Time ?"
                >
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary="Time" />
                        </ListItemButton>
                    </List>
                </Collapse>
            ),
            onClick: () => _handleClick(7),
        },
        {
            id: 8,
            name: "Carriéres",
            title: "Carriéres",
            icon: <AutoGraphOutlinedIcon />,
            nested: false,
            nestedChilds: (
                <Collapse
                    key={"collapse8"}
                    in={true}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary="Process" />
                        </ListItemButton>
                    </List>
                </Collapse>
            ),
            onClick: () => _handleClick(8),
        },
        {
            id: 9,
            name: "Rapports",
            title: "Rapports",
            icon: <DonutSmallOutlinedIcon />,
            nested: false,
            nestedChilds: (
                <Collapse
                    key={"collapse9"}
                    in={true}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary="KPI" />
                        </ListItemButton>
                    </List>
                </Collapse>
            ),
            onClick: () => _handleClick(9),
        },
    ];

    const [sidebar, setSideBar] = useState(initialState);

    const setToggleActiveNav = useSidebar((state) => state.setToggleActiveNav);

    return (
        <Box
            sx={{
                marginTop: "70px",

                // minWidth: { md: open ? "230px" : "68px" },
                width: toggleSideBar ? "270px" : "68px",
                height: "calc(100vh - 68px)",
            }}
            style={{ backgroundColor: "#f5f5f5", top: "68.45px" }}
            position="sticky"
        >
            <div>
                <Box
                    style={{
                        marginLeft: toggleSideBar ? "220px" : "8px",
                    }}
                >
                    <IconButton
                        onClick={() => setToggleActiveNav(!toggleSideBar)}
                    >
                        <Responsive>
                            <MenuIcon />
                        </Responsive>
                    </IconButton>
                </Box>
            </div>
            <List style={{ height: "100%" }}>
                {sidebar.map((el) => {
                    const nestedChilds = el.nestedChilds
                        ? el.nestedChilds
                        : null;
                    return (
                        <div key={"wrapper_" + el.id}>
                            <ListItem
                                key={el.id}
                                disablePadding
                                onClick={el.onClick}
                                title={el.title}
                            >
                                <ListItemButton>
                                    <ListItemIcon>{el.icon}</ListItemIcon>
                                    <ListItemText
                                        primary={toggleSideBar ? el.name : ""}
                                    />
                                    {el.nestedChilds && toggleSideBar ? (
                                        el.nested ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        )
                                    ) : null}
                                </ListItemButton>
                            </ListItem>
                            {toggleSideBar ? el.nested && nestedChilds : null}
                        </div>
                    );
                })}
                <ListItem
                    style={{ position: "absolute", bottom: "5%" }}
                    disablePadding
                    onClick={() => alert("Paramétrage")}
                >
                    <ListItemButton>
                        <ListItemIcon>
                            <AssessmentOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>
                            {" "}
                            {toggleSideBar ? "Paramètrage" : ""}
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );
}

export default SideBar;

const Responsive = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        visibility: "hidden",
    },
    [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
    },
    [theme.breakpoints.up("md")]: {
        visibility: "visible",
    },
    [theme.breakpoints.up("lg")]: {
        visibility: "visible",
    },
}));

import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Container = styled(Flex)`
    background: repeating-linear-gradient(
        45deg,
        ${({ striped_color }) => striped_color},
        ${({ striped_color }) => striped_color} 2px,
        ${({ background_color }) => background_color} 2px,
        ${({ background_color }) => background_color} 14px
    );
    height: 36px;
    width: 100%;
`;

export const Badge = styled(Flex)`
    padding: 4px;

    gap: 16px;
    border-radius: 6px;
    background: ${({ striped_color }) => striped_color};
    height: fit-content;
    /* shadow-2 */
    box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13),
        0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11);
`;

export const BadgeText = styled(Text)`
    color: #242424;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

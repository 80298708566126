import { Navigate } from "react-router-dom";
import { roles_access, ROLES_CODE } from "../../constants/roles";

const AccessHR = ({ children }) => {
    const isHR = roles_access({
        usersPrivileges: [ROLES_CODE.hr],
    });
    if (isHR) {
        return children;
    } else {
        return <Navigate to={"/denied"} />;
    }
};

export default AccessHR;

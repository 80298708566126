import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

const LabelView = ({ label = "", content = "-" }) => {
    return (
        <Flex fill>
            {/* left section */}
            <Flex fill column gap="gap.smaller">
                <Label content={label} />
                <Content content={!content ? "-" : content} />
            </Flex>
        </Flex>
    );
};

export default LabelView;

const Content = styled(Text)`
    color: #242424;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

const Label = styled(Text)`
    color: #616161;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`;

import { Button, FilterIcon } from "@fluentui/react-northstar";

export const FilterTrigger = ({ setOpenDialog, isMobile, searchParams }) => {
    return (
        <Button
            iconPosition="after"
            icon={<FilterIcon />}
            content={
                isMobile
                    ? "Filtrer"
                    : searchParams.size === 0
                    ? "Filtrer les résultats"
                    : `Modifier les filtres`
            }
            flat
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpenDialog(true);
            }}
        />
    );
};

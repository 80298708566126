import { Flex, Skeleton, Text, Tooltip } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components/macro";
import HourCounter from "./HourCounter";
import { Beach16Regular } from "@fluentui/react-icons";

const HourBadge = ({
    timeWorked,
    addionalHourWorkedBadge,
    loading,
    index = "",
    highlightedColumn,
    total_leaves,
}) => {
    return loading ? (
        <Skeleton animation="pulse">
            <Skeleton.Line
                styles={{
                    minWidth: "28px",
                    minHeight: "24px",
                }}
            />
        </Skeleton>
    ) : (
        <Cell
            hAlign="center"
            vAlign="center"
            data-num={index}
            className={highlightedColumn === index ? "cell highlight" : "cell"}
        >
            {/* <Beach16Regular /> */}
            <HourText
                content={`${timeWorked !== null ? timeWorked : "--,--"} h`}
            />
            {addionalHourWorkedBadge ? (
                <HourCounter
                    status={
                        addionalHourWorkedBadge > 0 ? "positive" : "negative"
                    }
                    addionalHourWorkedBadge={addionalHourWorkedBadge}
                />
            ) : null}

            {/* {total_leaves > 0 && (
                <Tooltip
                    trigger={<Beach16Regular />}
                    subtle={false}
                    content={` Congés : ${total_leaves} h`}
                    pointing
                />
            )} */}
        </Cell>
    );
};

export default HourBadge;

const Cell = styled(Flex)`
    min-width: 120px;
    height: 36px;
    padding: 8px;
    /* border-radius: 4px; */
    /* border: 1px solid #c5cbfa; */
    gap: 8px;
    height: 100%;
    background: #fafafa;
`;
const HourText = styled(Text)`
    color: #242424;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

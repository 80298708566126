import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Title = styled(Text)`
    color: #242424;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
`;

export const SubHeader = styled(Flex)`
    padding: 12px 24px;
    /* border: 2px solid yellow; */
    background-color: #e8ebfa;
`;

export const SubHeaderText = styled(Text)`
    color: #242424;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;

export const IllustrationText = styled(Text)`
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    width: 250px;
    text-align: center;
`;

export const IllustrationSubText = styled(Text)`
    width: 250px;
    color: #242424;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

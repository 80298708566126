/* eslint-disable no-unreachable */
import React, { useEffect } from "react";
import { Dialog } from "@fluentui/react-northstar";
import { FormProvider, useForm } from "react-hook-form";

import DialogUpdateManualTaskTemplateModify from "./DialogUpdateManualTaskTemplateModify";
import { AVATAR_DEFAULT, BASE_URL_ILLUSTRATIONS } from "../../../constants";
import { string } from "prop-types";
import DialogUpdateManualTaskConfirmationTemplate from "./DialogUpdateManualTaskTemplateModify";
import { TASK_ACTIONS } from "../../../constants/tasks";
import { DialogStyle } from "../style";

const DialogModifyTaskConfirmationTasks = ({
    default_task,
    setTemplateTasks,
    setOpenDialogUpdateTask,
    openDialogUpdateTask,
}) => {
    console.log({ default_task });

    const document_action = default_task?.useform_data
        ? default_task?.useform_data?.document_action
        : default_task?.document_action === 0
        ? 5
        : default_task?.document_action;

    const hr_document_required =
        TASK_ACTIONS[document_action - 1]?.hr_document_required;

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            id: default_task?.id,
            type: default_task?.type,
            name: default_task?.name,
            category: default_task?.category_id,
            description: default_task?.description,
            due_date: default_task?.due_date,
            assigned_to: {
                id: default_task?.assigned_to_user?.id,
                header: default_task?.assigned_to_user?.email,
                image: default_task?.assigned_to_user?.avatar || AVATAR_DEFAULT,
                content: default_task?.assigned_to_user?.desc,
                name:
                    default_task?.assigned_to_user?.username ||
                    default_task?.assigned_to_user?.name,
            },
            related_to: {
                id: default_task?.assigned_to_user?.id,
                header: default_task?.assigned_to_user?.email,
                image: default_task?.assigned_to_user?.avatar || AVATAR_DEFAULT,
                content: default_task?.assigned_to_user?.desc,
                name:
                    default_task?.assigned_to_user?.username ||
                    default_task?.assigned_to_user?.name,
            },
            task_to_validate: default_task?.task_to_validate,
            who_validate: default_task?.validator,
            saved_files: default_task?.documents_template,
            document_action,
            document_required: hr_document_required,
            template_doc_ids: default_task?.documents_template.map(
                (el) => el?.id
            ),
        },
    });

    const onSubmit = (data) => {
        const {
            id,
            type,
            name,
            category,
            description,
            due_date,
            assigned_to,
            related_to,
            task_to_validate,
            who_validate,
            saved_files,
            document_action,
            document_required,
        } = data;

        if (task_to_validate && !Object.keys(who_validate).length) {
            return methods.setError(
                "who_validate",
                {
                    type: string,
                    message: "ce champ est obligatoire",
                },
                {
                    shouldFocus: true,
                }
            );
        }

        if (saved_files?.length === 0 && document_required) {
            methods.setError(
                "document_action",
                {
                    type: string,
                    message:
                        "Le document est obligatoire. Merci d'ajouter le document associé à cette action.",
                },
                {
                    shouldFocus: true,
                }
            );
            return;
        }

        // we need to format the data for display him inside the table.
        const format_data = {
            id,
            name,
            description,
            due_date,
            category_id: category,
            type: type,
            document_required,
            document_action,
            task_to_validate,
            validator: who_validate ?? "",
            assigned_to_user: {
                id: assigned_to?.id,
                email: assigned_to?.username,
                avatar: assigned_to?.image,
                name: assigned_to?.name,
                header: assigned_to?.header,
            },
            documents_template: saved_files,
            related_to,
            useform_data: {
                // used just for datas to send to the back !
                ...data,
            },
        };

        setTemplateTasks((state) => {
            return state.map((el) => {
                if (el?.id === data?.id) {
                    return {
                        ...el,
                        ...format_data,
                    };
                }
                return el;
            });
        });
        hideDialog();
    };

    const hideDialog = () => {
        setOpenDialogUpdateTask(false);
    };

    useEffect(() => {
        if (
            default_task?.documents_template?.length === 0 &&
            (default_task?.document_action === 3 ||
                default_task?.document_action === 2 ||
                default_task?.document_action === 4)
        ) {
            methods.setError(
                "document_action",
                {
                    type: string,
                    message:
                        "Le document est obligatoire. Merci d'ajouter le document associé à cette action.",
                },
                {
                    shouldFocus: true,
                }
            );
            return;
        }
    }, []);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <DialogStyle
                    open={openDialogUpdateTask}
                    header={"Modifier une tâche"}
                    confirmButton={"Modifier la tâche"}
                    onConfirm={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        methods.handleSubmit(onSubmit)();
                    }}
                    onCancel={hideDialog}
                    cancelButton={"Annuler"}
                    content={<DialogUpdateManualTaskConfirmationTemplate />}
                    styles={{ width: "764px" }}
                />
            </form>
        </FormProvider>
    );
};

export default DialogModifyTaskConfirmationTasks;

import { Flex, Text } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";
import { get_symbol_currency } from "../../../expenses/functions";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { format_number } from "../../../../constants/maths";
import LegendChart from "../../../expenses/components/LegendChart";
import { Total } from "./AsUserN2F";

const AsManagerN2F = ({
    expenses_Manager,
    total_as_manager,
    expenses,
    ShowLink,
}) => {
    const navigate = useNavigate();

    return (
        <Flex column fill hAlign="center" gap="gap.small">
            <Flex
                styles={{
                    position: "relative",
                    width: "190px",
                    height: "190px",
                }}
                hAlign="center"
                vAlign="center"
            >
                <Doughnut
                    data={expenses_Manager}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        aspectRatio: "1",
                        plugins: {
                            legend: false,
                        },
                    }}
                />
                <Total
                    content={
                        format_number(total_as_manager) +
                        " " +
                        get_symbol_currency(expenses?.as_manager[0]?.currency)
                    }
                />
            </Flex>
            <Flex
                wrap
                gap="gap.medium"
                styles={{
                    rowGap: "24px",
                }}
            >
                {expenses.as_manager.map((legend) => {
                    return (
                        <LegendChart color={legend.color} name={legend.name} />
                    );
                })}
            </Flex>
            <Flex styles={{ marginTop: "24px" }}>
                {ShowLink && (
                    <Show_N2F_Link
                        onClick={() => {
                            navigate(
                                ROUTES.expenses.home +
                                    "/" +
                                    ROUTES.expenses.manager.home.route,
                                {
                                    state: {
                                        tab: 1,
                                    },
                                }
                            );
                            return;
                        }}
                        text
                        content="Dépense de l'équipe >"
                    />
                )}
            </Flex>
        </Flex>
    );
};

export default AsManagerN2F;

const Show_N2F_Link = styled(Text)`
    color: #0067b8;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
`;

import {
    Flex,
    MenuButton,
    MoreIcon,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { Comment20Filled } from "@fluentui/react-icons";

export const BADGE_STATUS = {
    toValidate: {
        value: 1,
        border: "#EBEBEB",
        background: "#EBEBEB",
        content: "A valider",
        color: "##D1D1D1",
        label: "toValidate",
    },
    Incomplete: {
        value: 0,
        border: "#EDC2A7",
        background: "#FBF6D9",
        content: "Incomplet",
        color: "#CC4A31",
        label: "Incomplete",
    },
    Validated: {
        value: 3,
        border: "#BDDA9B",
        background: "#E7F2DA",
        content: "Validé",
        color: "#13A10E",
        label: "Validated",
    },
    Refused: {
        value: 2,
        border: "#F3D6D8",
        background: "#FCF4F6",
        content: "Refusé",
        color: "#C4314B",
        icons: true,
        label: "Refused",
    },
    Sent: {
        value: 4,
        border: "#DB98CC",
        background: "#E1BEDD",
        content: "Envoyé",
        color: "#B4009E",
        icons: false,
        label: "Sent",
    },
};

/**
 * @typedef {Object} PropsType
 * @property {("toValidate"|"Incomplete"|"Validated"|"Refused"|"Sent")} status
 *
 */
/**
 * BadgeStatus c'est un composant qui affiche les différents badges pour le time reporting avec leur border, background couleur respectif
 * tous ce qu'il a besoin ce badge c'est le status props ( toValidate | Incomplete | Validated | Refused )
 * @param {PropsType} props
 * @returns {ReactNode}
 */

const BadgeStatus = ({ status, comment }) => {
    const { background, content, color, border, icons, value } = status;

    return (
        <Flex vAlign="center">
            <Badge
                hAlign="center"
                border={border}
                background={background}
                color={color}
            >
                <Text content={content} />

                {comment && icons && (
                    <Tooltip content={comment} trigger={<Comment20Filled />} />
                )}
            </Badge>
            {/* {value === 4 && (
                <MenuButton
                    position="above"
                    trigger={
                        <MoreIcon styles={{ cursor: "pointer" }} rotate={90} />
                    }
                    content="Click"
                    aria-label="Click button"
                    menu={["Relancer"]}
                    on="click"
                />
            )} */}
        </Flex>
    );
};

export default BadgeStatus;

const Badge = styled(Flex)`
    /* border: 2px solid purple; */
    padding: 4px 8px;
    border-radius: 4px;
    background: ${({ background }) => (background ? background : "")};
    color: ${({ color }) => (color ? color : "")};
    border: 1px solid ${({ border }) => (border ? border : "")};
    gap: 4px;
    min-width: 82px;
`;

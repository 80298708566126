import { CheckmarkStarburst16Regular } from "@fluentui/react-icons";
import { SaveIcon } from "@fluentui/react-northstar";

export const CONFIG_STATUS = {
    done: {
        background: "#E7F2DA",
        icon: <CheckmarkStarburst16Regular />,
    },
    saved: {
        background: "#EBEBEB",
        icon: <SaveIcon outline />,
    },
    default: {
        background: "#EBEBEB",
        icon: null,
    },
};

import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE } from "../../constants";

const RowTotalRefused = ({ total, currency }) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    return (
        <Flex fill vAlign="center" space="between">
            <TotalTextFooterRefused content={"Pas de remboursement"} />
            <TotalFooterRefused content={total + " " + currency} />
        </Flex>
    );
};

export default RowTotalRefused;

const TotalTextFooterRefused = styled(Text)`
    color: #c4314b;
    font-family: Segoe UI;
    font-size: clamp(14px, 2vw, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: clamp(20px, 2vw, 24px);
`;
const TotalFooterRefused = styled(Text)`
    color: #c4314b;
    font-family: Segoe UI;
    font-size: clamp(14px, 2vw, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: clamp(20px, 2vw, 24px);
`;

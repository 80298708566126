import React from "react";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import {
    ChevronRight16Filled,
    ChevronLeft16Regular,
} from "@fluentui/react-icons";
import styled from "styled-components";
import { useEffect } from "react";
import { useReducer } from "react";
import {
    ACTION_TYPES_SLIDER_WEEK,
    INIT_SLIDE_WEEK,
    reducerSliderWeek,
} from "../../../reducers/reducer.slider_week";
import moment from "moment";
import { CustomBadge } from "../../../../../components/Badge";

const CURRENT_YEAR = Number(moment().get("year"));

const SliderWeeks = ({
    default_iso_week,
    default_year,
    onChangeWeek = () => null,
}) => {
    const [state, dispatch] = useReducer(reducerSliderWeek, {
        iso_week: Number(default_iso_week),
        year: Number(default_year),
        start_date_week: null,
    });

    // useEffect(() => {
    //     dispatch({
    //         type: ACTION_TYPES_SLIDER_WEEK.init,
    //         payload: {
    //             iso_week: Number(default_iso_week),
    //         },
    //     });
    // }, []);
    

    useEffect(() => {
        if (!state.iso_week) {
            return;
        }
        onChangeWeek(state);
    }, [state]);

    const is_current_week =
        state.iso_week === moment().isoWeek() && state.year === CURRENT_YEAR;

    return (
        <Flex column vAlign="center" hAlign="center">
            <Flex gap="gap.small" vAlign="center">
                <Button
                    icon={<ChevronLeft16Regular />}
                    iconOnly
                    flat
                    text
                    onClick={() => {
                        return dispatch({
                            type: ACTION_TYPES_SLIDER_WEEK.decrement,
                        });
                    }}
                />
                <TextMonth content={`Semaine ${state.iso_week}`} />
                <Button
                    icon={<ChevronRight16Filled />}
                    iconOnly
                    flat
                    text
                    onClick={() => {
                        return dispatch({
                            type: ACTION_TYPES_SLIDER_WEEK.increment,
                        });
                    }}
                />
            </Flex>
            <Flex>
                {is_current_week && (
                    <CustomBadge
                        appearence="filled"
                        bg="var(--color-primary)"
                        color="white"
                        label="semaine en cours"
                        size="smaller"
                    />
                )}
            </Flex>
        </Flex>
    );
};

export default SliderWeeks;

const TextMonth = styled(Text)`
    color: #242424;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
`;

import moment from "moment";
import { getYearMonthOfThisWeek } from "../functions";

const { month, year } = getYearMonthOfThisWeek();

export const CURRENT_MONTH_NAME = moment().month("MMMM").format("MMMM");
export const CURRENT_MONTH_INDEX = month;
export const CURRENT_YEAR = year;
export const CURRENT_WEEK = moment().isoWeek();

export const ACTION_TYPES = {
    increment_week: "increment_week",
    decrement_week: "decrement_week",
    toggle_mode_editable: "toggle_mode_editable",
    toggle_weekend: "toggle_weekend",
    mode_update_week_template: "mode_update_week_template",
    goto_week: "goto_week",
};
export const INIT_REDUCER_WEEK = {
    iso_week: CURRENT_WEEK,
    year: CURRENT_YEAR,
    month_indexed: CURRENT_MONTH_INDEX,
    dates: {
        start: moment().isoWeekday(1).format("DD MMMM YYYY"),
        end: moment().isoWeekday(7).format("DD MMMM YYYY"),
    },
    start_date_week: moment().isoWeekday(1).format("YYYY-MM-DD"),
    mode_editable_activated: false,
    mode_update_week_template: false,
    toggle_weekend: false,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.goto_week: {
            const m = moment()
                .set("year", state.year)
                .isoWeek(action.payload.week);
            const month_iso = m.isoWeekday(1);
            const start_date_week = month_iso.format("YYYY-MM-DD");
            const start = month_iso.format("DD MMMM YYYY");
            const month = m.month();
            const end = m.isoWeekday(7).format("DD MMMM YYYY");
            const week = m.isoWeek();
            const year = month_iso.year();

            return {
                ...state,
                dates: {
                    start,
                    end,
                },
                iso_week: week,
                month_indexed: month,
                year,
                start_date_week,
                mode_editable_activated: false,
            };
        }
        case ACTION_TYPES.increment_week: {
            const m = moment()
                .set("year", state.year)
                .isoWeek(state.iso_week + 1);
            const month_iso = m.isoWeekday(1);
            const start_date_week = month_iso.format("YYYY-MM-DD");
            const start = month_iso.format("DD MMMM YYYY");
            const month = m.month();
            const end = m.isoWeekday(7).format("DD MMMM YYYY");
            const week = m.isoWeek();
            const year = month_iso.year();

            return {
                ...state,
                dates: {
                    start,
                    end,
                },
                iso_week: week,
                month_indexed: month,
                year,
                start_date_week,
                mode_editable_activated: false,
            };
        }
        case ACTION_TYPES.decrement_week: {
            const m = moment()
                .set("year", state.year)
                .isoWeek(state.iso_week - 1);

            const month_iso = m.isoWeekday(1);
            const year = month_iso.year();
            const start_date_week = month_iso.format("YYYY-MM-DD");

            const start = m.isoWeekday(1).format("DD MMMM YYYY");
            const month = m.month();
            const end = m.isoWeekday(7).format("DD MMMM YYYY");
            const week = m.isoWeek();

            return {
                ...state,
                dates: {
                    start,
                    end,
                },
                iso_week: week,
                month_indexed: month,
                year,
                start_date_week,
                mode_editable_activated: false,
            };
        }
        case ACTION_TYPES.toggle_mode_editable: {
            return {
                ...state,
                mode_editable_activated: action.payload.value,
            };
        }
        case ACTION_TYPES.toggle_weekend: {
            return {
                ...state,
                toggle_weekend: action.payload.value,
            };
        }
        case ACTION_TYPES.mode_update_week_template: {
            return {
                ...state,
                mode_update_week_template: action.payload.value,
            };
        }

        default:
            break;
    }
};

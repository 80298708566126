import React from "react";
import {
    ButtonShowMoreRows,
    Footer,
    FooterCountRowsShowed,
} from "./index.style";
import { Flex, Skeleton } from "@fluentui/react-northstar";

const Pagination = ({
    currentCountRows,
    totalRows,
    countMessage,
    isFetchingNextPage,
    isMore,
    isLoading = false,
    buttonContentSeeMore,
    funcFetchNextPage = () => null,
}) => {
    return (
        <Footer column gap="gap.medium">
            {isLoading ? (
                <Skeleton animation="pulse">
                    <Flex gap="gap.small" column>
                        <Skeleton.Line width="150px" />
                        <Skeleton.Line width="200px" height="32px" />
                    </Flex>
                </Skeleton>
            ) : (
                <>
                    <FooterCountRowsShowed
                        content={`${currentCountRows} ${countMessage} ${totalRows}`}
                    />
                    {isMore && (
                        <div>
                            <ButtonShowMoreRows
                                content={buttonContentSeeMore}
                                loading={isFetchingNextPage}
                                onClick={() => funcFetchNextPage()}
                                flat
                            />
                        </div>
                    )}
                </>
            )}
        </Footer>
    );
};

export default Pagination;

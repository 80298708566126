import React from "react";
import { styled as styledd } from "@mui/material/styles";

import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import { IconButton, Typography, Avatar } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { Box } from "@mui/system";
import { getAllCollaborator } from "../../../api/user";
import nouveauArrivantImg from "../../../assets/imgStatic/Photo.jpg";

function OnboardingEnCours(props) {
    const navigate = useNavigate();

    const { data, isLoading, isFetching } = useQuery(["getCollaborator"], () =>
        getAllCollaborator()
    );

    console.log("data", data);
    return (
        <ContainerStyle sx={{}}>
            {data?.results?.map((res, i) => (
                <Row
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    key={i}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "4px 0px",
                    }}
                    onClick={() =>
                        res.status === -1
                            ? navigate("/addCollaborator?id=" + res.id)
                            : navigate(`/profileOnboarding/${res.id}`)
                    }
                >
                    {/* edit button */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        style={{ gap: 20, width: "300px" }}
                    >
                        <IconButton aria-label="Edit" component="span">
                            <ModeEditOutlinedIcon />
                        </IconButton>
                        <Avatar alt="Remy Sharp" src={nouveauArrivantImg} />
                        {/* res.name */}
                        <Typography variant="subtitle2">
                            {res.first_name} {res.last_name}
                        </Typography>
                    </Box>

                    <Typography
                        variant="body2"
                        style={{
                            width: "160px",
                        }}
                    >
                        Ressource
                        <IconButton>
                            {res?.resources_count === 0 ? (
                                <HighlightOffOutlinedIcon
                                    // onClick={() => alert("Delete button")}
                                    style={{ color: "red" }}
                                />
                            ) : (
                                <CheckCircleOutlineIcon
                                    // onClick={() => alert("yes button")}
                                    style={{ color: "green" }}
                                />
                            )}
                            {/* <HighlightOffOutlinedIcon /> */}
                        </IconButton>
                    </Typography>

                    <IconButton
                        style={{
                            width: "100px",
                        }}
                    >
                        <Typography
                            variant="body2"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap="10px"
                        >
                            {res?.status === -1 ? (
                                <>
                                    Pending{" "}
                                    <HighlightOffOutlinedIcon
                                        style={{ color: "red" }}
                                    />
                                </>
                            ) : (
                                <>
                                    Validé{" "}
                                    <CheckCircleOutlineIcon
                                        style={{ color: "green" }}
                                    />
                                </>
                            )}
                        </Typography>
                        {/* <HighlightOffOutlinedIcon /> */}
                    </IconButton>

                    {/* res.arrival_date */}
                    <Typography variant="body2" style={{ width: "220px" }}>
                        <IconButton onClick={() => alert("Arrivée le ")}>
                            <CalendarMonthOutlinedIcon />
                        </IconButton>
                        Arrivée le : {res.arrival_date}
                    </Typography>
                </Row>
            ))}
        </ContainerStyle>
    );
}

export default OnboardingEnCours;

const Row = styled.div`
    background-color: white;

    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background-color: #ededed;
        transition: all 0.3s;
    }
    @media (max-width: 599px) {
        width: 800px;
    }
`;

const ContainerStyle = styledd("div")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        // border: "2px solid pink",
    },
    [theme.breakpoints.down("sm")]: {
        // border: "2px solid yellow",
        overflowX: "scroll",
        width: "100%",
    },
    [theme.breakpoints.up("md")]: {
        // border: "2px solid blue",
    },
    [theme.breakpoints.up("lg")]: {
        // border: "2px solid green",
        justifyContent: "center",
        width: "100%",
    },
}));

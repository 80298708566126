import React from "react";
import { CheckmarkCircleIcon, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components/macro";
import { DEVICE } from "../../../../constants/responsive";
import { Clock16Regular, DismissCircle16Regular } from "@fluentui/react-icons";

export const LeaveStatusBadge = (el) => {
    switch (el) {
        case 2:
            return (
                <GreenBadge vAlign="center" hAlign="center">
                    <WrapperIcon>
                        <CheckmarkCircleIcon
                            size="smaller"
                            outline
                            styles={{ color: "#13A10E" }}
                        />
                    </WrapperIcon>
                    <BadgeText content="Validée" />
                </GreenBadge>
            );

        case 1:
            return (
                <WaitingBadge vAlign="center" hAlign="center">
                    <WrapperIcon>
                        <Clock16Regular
                            style={{ fontSize: "12px", color: "#CC4A31" }}
                        />
                    </WrapperIcon>
                    <WaitingBadgeText content="En attente de validation" />
                </WaitingBadge>
            );

        case 3:
            return (
                <GreenBadge vAlign="center" hAlign="center">
                    <WrapperIcon>
                        <CheckmarkCircleIcon
                            size="smaller"
                            outline
                            styles={{ color: "#13A10E" }}
                        />
                    </WrapperIcon>
                    <BadgeText content="Validée" />
                </GreenBadge>
            );
        case 4:
            return (
                <RefusedBadge vAlign="center" hAlign="center">
                    <WrapperIcon>
                        <DismissCircle16Regular
                            style={{ fontSize: "12px", color: "#C4314B" }}
                        />
                    </WrapperIcon>
                    <RefusedBadgeText content="Refusée" />
                </RefusedBadge>
            );

        default:
            break;
    }
};

const WrapperIcon = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    @media ${DEVICE.mobileS} {
        display: flex;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
`;

const BadgeText = styled(Text)`
    color: #13a10e;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    width: 100%;

    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: block;
    }
`;

const GreenBadge = styled(Flex)`
    height: 24px;
    gap: 4px;
    background-color: #e7f2da;
    border: 1px solid #bdda9b;
    padding: 4px 8px;
    border-radius: 4px;
    @media ${DEVICE.mobileS} {
        min-width: 32px;
    }
    @media ${DEVICE.laptop} {
        min-width: 190px;
    }
`;

const WaitingBadge = styled(Flex)`
    height: 24px;
    gap: 4px;
    background-color: #fbf6d9;
    border: 1px solid #edc2a7;
    padding: 4px 8px;
    border-radius: 4px;
    @media ${DEVICE.mobileS} {
        min-width: 32px;
    }
    @media ${DEVICE.laptop} {
        min-width: 190px;
    }
`;

const WaitingBadgeText = styled(BadgeText)`
    color: #cc4a31;
`;

const RefusedBadgeText = styled(BadgeText)`
    color: #c4314b;
`;
const RefusedBadge = styled(Flex)`
    height: 24px;
    gap: 4px;
    background-color: #fcf4f6;
    border: 1px solid rgba(196, 49, 75, 0.8);
    padding: 4px 8px;
    border-radius: 4px;
    @media ${DEVICE.mobileS} {
        min-width: 32px;
    }
    @media ${DEVICE.laptop} {
        min-width: 190px;
    }
`;

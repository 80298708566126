import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useQuery, useQueryClient } from "react-query";
import IconButton from "@mui/material/IconButton";
import { FaUserCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { GoBack } from "./index.logic";
import InputAdornment from "@mui/material/InputAdornment";
import { Link, useNavigate } from "react-router-dom";
import validator from "validator";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { loginWithPassword } from "../../api/auth";
import { useAuthStore, useAuthDetailStore } from "../../stores/useAuthStore";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { FormControl, InputLabel, OutlinedInput, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    ArrowLeftIcon,
    Button,
    CloseIcon,
    Divider,
    EyeIcon,
    EyeSlashIcon,
    Flex,
    Form,
    InfoIcon,
    Input,
    Text,
} from "@fluentui/react-northstar";
import { color } from "@mui/system";
import { DEVICE } from "../../constants/responsive";
import { ErrorMessage } from "../../common/styles";

const QueryKey = "askPasswordAPI";

const AskPassword = ({ email }) => {
    useEffect(() => {
        document.title = "Mot de passe";
    }, []);

    const clientQuery = useQueryClient();
    const navigate = useNavigate();
    const setUser = useAuthStore((state) => state.setUser);
    const setDetailUser = useAuthDetailStore((state) => state.setDetailUser);

    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword = (event) => {
        event.preventDefault();
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    //   const [password, setPassword] = useState("");
    const refPassword = useRef();
    const [error, setError] = useState("");

    const {
        isFetching,
        isLoading,
        error: errorApi,
        data,
        refetch,
    } = useQuery(
        [QueryKey],
        () =>
            loginWithPassword({
                email,
                password: values.password,
            }),
        {
            enabled: false,
        }
    );

    if (errorApi) {
        console.log("error => ", errorApi);
    }

    if (data?.success === true) {
        delete data.success;
        clientQuery.resetQueries(QueryKey).then(() => {
            // save user data here
            setUser({
                access: data.access_token,
            });
            setDetailUser(data.user); // save the data of the user inside a localstorage.
            if (data?.user?.is_in_preonboarding) {
                return navigate("/welcome-preonboarding?refer=login");
            }
            navigate("/dashboard?refer=login");
        });
    }

    if (data?.success === false) {
        setError("Veuillez vérifier vos identifiants.");
        clientQuery.resetQueries(QueryKey).then(() => {});
    }

    // functions
    const handleClick = async (e) => {
        e.preventDefault();
        const isPassword = validator.isLength(values.password, { min: 3 });
        if (!isPassword) {
            refPassword?.current?.focus();
            return setError(
                "Le mot de passe doit contenir plus de 3 caractères"
            );
        }
        setError("");
        refetch();
    };

    return (
        <Flex styles={{ gap: "93px" }} column>
            <PasswordBlock
                onSubmit={handleClick}
                style={{ width: "315px" }}
                gap="gap.medium"
                column
            >
                <Form>
                    <Flex gap="gap.medium" column>
                        {/* email */}
                        <Flex fill>
                            <Input
                                label="Email"
                                value={email}
                                fluid
                                icon={
                                    <CloseIcon size="small" onClick={GoBack} />
                                }
                                content={email}
                                disabled
                            />
                        </Flex>
                        {/* mdp */}
                        <Flex column>
                            <Input
                                fluid
                                label="Mot de passe"
                                placeholder="Votre mot de passe"
                                type={
                                    values?.showPassword ? "text" : "password"
                                }
                                value={values?.password}
                                onChange={handleChange("password")}
                                autoFocus
                                icon={
                                    values?.showPassword ? (
                                        <EyeIcon
                                            styles={{ cursor: "pointer" }}
                                            onClick={handleClickShowPassword}
                                        />
                                    ) : (
                                        <EyeSlashIcon
                                            styles={{ cursor: "pointer" }}
                                            onClick={handleClickShowPassword}
                                        />
                                    )
                                }
                            />

                            <Flex hAlign="end" fill>
                                <Text
                                    styles={{
                                        cursor: "pointer",
                                        color: "#5B5FC7",
                                        textDecoration: "underline",
                                    }}
                                    onClick={() =>
                                        navigate("/forget_password", {
                                            state: { email },
                                        })
                                    }
                                    content={"Mot de passe oublié"}
                                />
                            </Flex>
                        </Flex>
                        {error && <ErrorMessage content={error} />}
                    </Flex>

                    {/* Button */}
                    <Flex
                        hAlign="center"
                        vAlign="center"
                        gap="gap.medium"
                        column
                    >
                        <Button
                            fluid
                            styles={{ width: "315px" }}
                            disabled={values.password ? false : true}
                            loading={isLoading || isFetching ? true : false}
                            onClick={handleClick}
                            flat
                            primary
                            content="SE CONNECTER AVEC SON EMAIL"
                        />
                        <Button
                            text
                            icon={<ArrowLeftIcon size="small" />}
                            onClick={GoBack}
                            flat
                            content="Changer de mode de connexion"
                        />
                    </Flex>
                </Form>
                {/* <Footer space="between" styles={{ gap: "8px" }}>
                    <Flex>
                        <FooterText content="Politique de confidentialité" />
                        <Divider
                            vertical
                            size={1}
                            styles={{ height: "16px" }}
                        />
                        <FooterText content="Assistance" />
                    </Flex>
                    <FooterSign content="© YEETCH 2023" />
                </Footer> */}
            </PasswordBlock>
        </Flex>
    );
};

export default AskPassword;

const Footer = styled(Flex)`
    /* gap: 40px; */
`;
const FooterSign = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #616161;
`;

const FooterText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0067b8;
`;

const PasswordBlock = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 250px;
    }
    @media ${DEVICE.mobileM} {
        width: 100%;
    }
    @media ${DEVICE.mobileL} {
        /* width: 320px; */
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: 100%;
    }
`;

// const FooterText = styled(Flex)`
//     font-weight: 600;
//     font-size: 12px;
//     line-height: 17px;
//     color: #242424;

//     @media ${DEVICE.mobileS} {
//         justify-content: center;
//     }
//     @media ${DEVICE.mobileM} {
//     }
//     @media ${DEVICE.mobileL} {
//     }
//     @media ${DEVICE.laptop} {
//         /* margin-bottom: 121px;
//     margin-top: 45px; */
//         gap: 4px;
//         justify-content: center;
//         align-items: center;
//     }
// `;

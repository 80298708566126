import React, { useEffect, useState } from "react";
import { BuildingIcon, Dropdown, Flex } from "@fluentui/react-northstar";
import {
    AVATAR_DEFAULT,
    AVATAR_DEPARTMENT_DEFAULT,
} from "../../../../constants";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

const SearchBarEmployeeList = ({
    users = [],
    isLoading = false,
    onChangeUser = () => null,
}) => {
    const [params, setSearchParams] = useSearchParams();

    if (isLoading) return "Chargement ...";

    const USERS = users.map((user) => {
        return {
            id: user?.id,
            header: user?.name,
            image: user?.avatar
                ? user.avatar
                : user?.is_department
                ? AVATAR_DEPARTMENT_DEFAULT
                : AVATAR_DEFAULT,
            is_department: user?.is_department,
        };
    });

    const default_value = USERS.find((el) => el?.id == params.get("id"));

    const removeParams = (paramsToRemove) => {
        // Créer une copie des paramètres actuels
        const newSearchParams = new URLSearchParams(params);

        // Supprimer les paramètres spécifiés
        paramsToRemove.forEach((param) => {
            newSearchParams.delete(param);
        });

        // Mettre à jour les paramètres dans l'URL
        setSearchParams(newSearchParams);
    };

    return (
        <Flex fill>
            <DropdownStyle
                fluid
                search
                items={USERS}
                defaultSearchQuery={default_value?.header}
                defaultValue={default_value}
                placeholder="Rechercher..."
                noResultsMessage="Nous n'avons pas trouvé de correspondance."
                a11ySelectedItemsMessage="Appuyez sur la touche Delete ou Backspace pour supprimer"
                clearable
                onChange={(e, i) => {
                    const search_value = i.searchQuery;
                    if (search_value === "") {
                        // supprimer les params liés à l'utilisateur
                        removeParams(["id", "type"]);
                    }

                    const id_user_selected = i?.value?.id;
                    const type = i?.value?.is_department
                        ? "department"
                        : "user";
                    onChangeUser(id_user_selected, type);
                    return;
                }}
            />
        </Flex>
    );
};

export default SearchBarEmployeeList;

const DropdownStyle = styled(Dropdown)`
    min-width: 100%;
`;

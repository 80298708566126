import React from "react";
import { Button, DownloadIcon, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import HeaderBadge from "./HeaderBadge";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE, QUERY_KEY } from "../../constants";
import { useQuery } from "react-query";
import { _api_download_excel_of_month } from "../../api";
import { get_symbol_currency } from "../../functions";
import { useMediaQuery } from "react-responsive";
import { SIZES } from "../../../../constants/responsive";

const Header = ({
    month = "",
    month_index,
    year,
    currency,
    total,
    isMonthDownloadable = false,
    showButtonDownloadByMonth = true,
}) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    const {
        isLoading: is_loading_excel_month,
        isFetching: is_fetching_excel_month,
        refetch: refetch_export_excel_month,
    } = useQuery(
        [QUERY_KEY + "::export_month", month_index],
        ({ queryKey }) => {
            const month_index = queryKey[1];
            return _api_download_excel_of_month({
                month_index: month_index,
                year,
            });
        },
        {
            enabled: false,
        }
    );

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileM})`,
    });

    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    const isMobile = isMobileS || isMobileM;

    return (
        <Flex
            key={"title_"}
            vAlign="center"
            styles={{
                justifyContent: "space-between",
                padding: "12px 8px 12px 12px",
                // maxWidth: "100%",
            }}
        >
            <Flex fill gap="gap.small" vAlign="center" space="between">
                <Flex styles={{ gap: "0px" }} gap="gap.small">
                    <HeaderTitle>{month}</HeaderTitle>
                    <Flex>
                        {showButtonDownloadByMonth && (
                            <ButtonDownloadExcel
                                loading={
                                    is_loading_excel_month ||
                                    is_fetching_excel_month
                                }
                                disabled={!isMonthDownloadable}
                                content={
                                    isMobile
                                        ? null
                                        : t("history.accordion.export")
                                }
                                icon={<DownloadIcon size="small" />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    refetch_export_excel_month();
                                    return;
                                }}
                                text
                                primary
                            />
                        )}
                    </Flex>
                </Flex>
                <Flex>
                    <HeaderBadge
                        total={total}
                        currency={get_symbol_currency(currency)}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Header;

const ButtonDownloadExcel = styled(Button)`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    /* color: #5b5fc7; */
`;

const HeaderTitle = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    /* max-width: 150px; */
    width: clamp(30px, 10vw, 150px);
    display: flex;
    align-items: center;

    color: #242424;
    text-transform: capitalize;
`;

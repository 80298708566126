import { Accordion, Flex, Text } from "@fluentui/react-northstar";
import MuiPhoneNumber from "material-ui-phone-number";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import MessageError from "../../ForCollaborator/components/MessageError";

export default function IdentityProfilEmployee() {
    const {
        register,
        control,
        getValues,

        formState: { errors },
    } = useFormContext();
    return (
        <AccordionStyle>
            <PersonalPhoneNumber column>
                <Text
                    style={{ marginBottom: "16px" }}
                    weight="bold"
                    content="Informations générales"
                />
                <Label content={"Téléphone Personnel"} />
                <Controller
                    name="personal_phone_number"
                    defaultValue={getValues("personal_phone_number")}
                    control={control}
                    required
                    render={({ field: { ...fieldProps } }) => {
                        return (
                            <MuiPhoneNumberStyle
                                {...fieldProps}
                                type="tel"
                                defaultCountry={"de"}
                                autoFormat={true}
                                disableAreaCodes={true}
                                onlyCountries={[
                                    "dz",
                                    "fr",
                                    "us",
                                    "ch",
                                    "de",
                                    "it",
                                    "ca",
                                    "lu",
                                    "mc",
                                    "nl",
                                    "no",
                                    "pl",
                                    "pt",
                                    "tr",
                                    "tn",
                                ]}
                            />
                        );
                    }}
                    error={errors?.personal_phone_number ? true : false}
                />
                {errors?.personal_phone_number && (
                    <MessageError
                        error
                        content={errors?.personal_phone_number?.message}
                    />
                )}
            </PersonalPhoneNumber>
        </AccordionStyle>
    );
}

const AccordionStyle = styled(Accordion)`
    .ui-accordion__title {
        padding-bottom: 0px;
    }
    .Accordion__checkbox--header {
        padding-bottom: 0px;
    }
    .ui-accordion__title__content-wrapper > .ui-box {
        background-repeat: no-repeat;
        background-position-y: 5px;
    }
`;

const PersonalPhoneNumber = styled(Flex)``;

const MuiPhoneNumberStyle = styled(MuiPhoneNumber)`
    background-color: white;
    border-radius: 4px;
    .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
        border-color: rgb(91, 95, 199) !important;
    }
    .MuiInput-underline {
        padding: 2px 8px !important;
    }
    .MuiInput-underline::before {
        border: 0px !important;
    }
`;

const Label = styled(Text)`
    font-size: 12px;
    font-style: normal;
    padding: 4px;
    color: #616161;
`;

import {
    Button,
    CloseIcon,
    Dialog,
    ExcelColorIcon,
    Flex,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import { DialogStyle } from "../../../DashboardTasks/style";
import ContentExportOnboarding from "./ContentExportOnboarding";
import { useMutation, useQuery } from "react-query";
import { _get_all_employees_and_departments } from "../../../../api/user";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { _api_download_file } from "../../../../utils/fetcher";
import { BASE_URL_API } from "../../../../constants";
import { generate_tabs_onboarding } from "../../constant";
import Toast from "../../../../components/Alerts";
import useToast from "../../../../hooks/useToast";

const ExportOnboarding = ({ agency_id }) => {
    const [open, setOpen] = useState(false); // ouvrir ou fermer le Dialog

    const [selectedEmployeesOrDepartments, setSelectedEmployeesOrDepartments] =
        useState([]); // list de la selection user/departement

    const methodsExport = useForm({
        defaultValues: {
            checkboxes_status: [],
            employee: [],
        },
    });

    const watching = useWatch({
        control: methodsExport.control,
        exact: ["employee", "checkboxes_status"],
    });

    const [toastConfig, setToastConfig] = useToast();

    const { mutate: download_file, isLoading: isDownloadingExcelFile } =
        useMutation(
            ({
                queryStringStatus = "",
                queryStringUsers = "",
                agency_id = "",
            }) => {
                let url = `${BASE_URL_API}/onboarding/agency/${agency_id}/export`;

                if (queryStringUsers) {
                    url = url + "?" + queryStringUsers;
                }

                if (queryStringStatus) {
                    url = queryStringUsers
                        ? url + "&" + queryStringStatus
                        : url + "?" + queryStringStatus;
                }

                console.log({ url });

                return _api_download_file({
                    url,
                    cbOnEndDownload: (data, error, message) => {
                        if (error) {
                            setToastConfig((state) => ({
                                ...state,
                                visible: true,
                                content: message
                                    ? message
                                    : "Une erreur s'est produite lors du téléchargement du fichier",
                                level: "danger",
                            }));
                            return;
                        }
                        if (data) {
                            methodsExport.reset();
                            func_toggle_show_dialog(false);
                            setToastConfig((state) => ({
                                ...state,
                                visible: true,
                                content:
                                    "Votre export Excel a bien été téléchargé",
                                level: "success",
                            }));
                            return;
                        }
                    },
                });
            }
        );

    const func_toggle_show_dialog = (boolean) => {
        setOpen(boolean);
        return;
    };

    const onSelectUserOrDepartment = (args) => {
        return setSelectedEmployeesOrDepartments(args);
    };

    const onDeselectUserOrDeparment = (id) => {
        return methodsExport.setValue(
            "employee",
            methodsExport.getValues("employee").filter((el) => el?.id !== id)
        );
    };

    const { data: employees } = useQuery(
        ["onboarding::GET::list_employees_and_departments"],
        () => {
            return _get_all_employees_and_departments();
        }
    );

    const onSubmitExportExcel = (data) => {
        const { tabs_array } = generate_tabs_onboarding();

        const get_string_from_ids = data?.checkboxes_status.map(
            (checkbox_id) =>
                tabs_array.find((tab) => tab.id === checkbox_id)?.backend_string
        );

        const queryStringStatus = get_string_from_ids
            .map((status) => `s=${status}`)
            .join("&");

        const queryStringUsers = data?.employee
            .map((user_department) => `ud=${user_department.id}`)
            .join("&");

        download_file({ queryStringStatus, queryStringUsers, agency_id });
        return;
    };

    const disable_button_export = watching.checkboxes_status.length === 0;

    return (
        <Flex>
            <Toast
                {...toastConfig}
                onStatusChange={() =>
                    setToastConfig((state) => ({
                        ...state,
                        visible: false,
                    }))
                }
            />
            <FormProvider {...methodsExport}>
                <form
                    onSubmit={methodsExport.handleSubmit(onSubmitExportExcel)}
                >
                    <DialogStyle
                        open={open}
                        header="Exporter les données pour Excel"
                        confirmButton={{
                            content: "Exporter les données",
                            flat: true,
                            disabled: disable_button_export,
                            loading: isDownloadingExcelFile,
                        }}
                        onConfirm={() =>
                            methodsExport.handleSubmit(onSubmitExportExcel)()
                        }
                        onCancel={() => func_toggle_show_dialog(false)}
                        content={
                            <ContentExportOnboarding
                                employees={employees}
                                selectedEmployeesOrDepartments={
                                    selectedEmployeesOrDepartments
                                }
                                onSelectUserOrDepartment={
                                    onSelectUserOrDepartment
                                }
                                onDeselectUserOrDeparment={
                                    onDeselectUserOrDeparment
                                }
                            />
                        }
                        headerAction={{
                            icon: <CloseIcon />,
                            onClick: () => func_toggle_show_dialog(false),
                        }}
                        trigger={
                            <Button
                                icon={<ExcelColorIcon />}
                                flat
                                content="Exporter"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    func_toggle_show_dialog(true);
                                }}
                            />
                        }
                    />
                </form>
            </FormProvider>
        </Flex>
    );
};

export default ExportOnboarding;

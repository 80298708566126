import {
    Checkbox,
    Dropdown,
    Flex,
    InputLabel,
    Text,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    DropdownEmployees,
    WrapperCheckboxes,
} from "../../../../features/leave/components/absences/FilterHistoryAjustment/index.style";
import {
    AVATAR_DEFAULT,
    AVATAR_DEPARTMENT_DEFAULT,
} from "../../../../constants";
import PillListEmployeeSelected from "../../../../features/leave/components/absences/FilterHistoryAjustment/PillListEmployeeSelected";
import { Controller, useFormContext } from "react-hook-form";
import { generate_tabs_onboarding } from "../../constant";

const ContentExportOnboarding = ({
    employees = [],
    selectedEmployeesOrDepartments = [],
    onSelectUserOrDepartment = () => null,
    onDeselectUserOrDeparment = () => null,
}) => {
    const {
        control,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useFormContext();

    const watch_employees = watch("employee");
    const watch_checkboxes_status = watch("checkboxes_status");

    const { tabs_array } = generate_tabs_onboarding();

    const addOrRemoveCheckedStatus = (id, checked) => {
        if (!checked) {
            // uncheck it
            const new_state = watch_checkboxes_status.filter(
                (cb_id) => cb_id !== id
            );
            setValue("checkboxes_status", new_state);
        } else {
            // check it
            const new_array = [...watch_checkboxes_status, id];
            setValue("checkboxes_status", new_array);
        }
        return;
    };

    const selectAllOrDeselectAllStatus = (e, { checked }) => {
        if (checked) {
            setValue(
                "checkboxes_status",
                tabs_array.map((e) => e?.id)
            );
            return;
        } else {
            setValue("checkboxes_status", []);
            return;
        }
    };
    // lorsqu'on retire un departement ou employé
    const onDismiss = (_, data) => {
        const uid = data.uid;
        onDeselectUserOrDeparment(uid);
    };

    // formater les datas des departement et employees
    const format_employees = () => {
        return employees
            ?.map((empl) => ({
                id: empl?.id,
                header: empl?.name,
                image: empl?.is_department
                    ? AVATAR_DEPARTMENT_DEFAULT
                    : empl?.avatar
                    ? empl?.avatar
                    : AVATAR_DEFAULT,
                content: empl.job_title,
            }))
            .sort((a, b) => b.id.localeCompare(a.id));
    };

    const formated_employees = format_employees();

    return (
        <Container column gap="gap.smaller">
            {/* title */}
            <Flex>
                <Title content="Critères de l'export" />
            </Flex>
            {/* dropdown */}
            <Flex column>
                <InputLabel
                    content={"Collaborateur(s) ou département(s) concerné(s)"}
                />
                <Controller
                    name="employee"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => {
                        return (
                            <DropdownEmployees
                                items={formated_employees}
                                placeholder={"Tous les collaborateurs"}
                                noResultsMessage="Aucun résultat."
                                positionFixed={true}
                                renderSelectedItem={() => null}
                                onChange={(e, i) => {
                                    onChange(i?.value);
                                }}
                                value={value}
                                ref={ref}
                                multiple
                                search
                            />
                        );
                    }}
                />
                <PillListEmployeeSelected
                    selectedEmployees={watch_employees}
                    onDismiss={onDismiss}
                />
            </Flex>
            {/* statut des collaborateurs */}
            <Flex column styles={{ marginTop: "16px" }}>
                <InputLabel content={"Statut(s) des collaborateurs"} />
                <Flex column>
                    <Checkbox
                        label="Toutes"
                        onChange={selectAllOrDeselectAllStatus}
                        checked={
                            watch_checkboxes_status.length === tabs_array.length
                                ? true
                                : watch_checkboxes_status.length > 0
                                ? "mixed"
                                : false
                        }
                    />
                    <WrapperCheckboxes gap="gap.small">
                        {tabs_array?.map((status) => {
                            return (
                                <Checkbox
                                    key={status.id}
                                    onChange={(e, { checked }) =>
                                        addOrRemoveCheckedStatus(
                                            status.id,
                                            checked
                                        )
                                    }
                                    checked={watch_checkboxes_status.includes(
                                        status.id
                                    )}
                                    label={status.label}
                                />
                            );
                        })}
                    </WrapperCheckboxes>
                </Flex>
            </Flex>
        </Container>
    );
};

export default ContentExportOnboarding;

const Container = styled(Flex)`
    padding-top: 16px;
`;

const Title = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
`;

import {
    Button,
    EyeIcon,
    Flex,
    Image,
    Input,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import PassewordImg from "../img/PassewordImg.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { _CheckValidationToken } from "../api";
import { ErrorMessage } from "../../../common/styles";
import UpdateNewPassword from "./UpdateNewPassword";
// import UpdateNewPassword from "./UpdateNewPassword";

const PasswordPage = ({
    handleSubmit,
    error,
    refPassword,
    password,
    ChangePassword,
    globalError,
    progress,
    rules,
    CPassword,
    refCPassword,
    setCPassword,
    isLoading,
    isFetching,
    showImage,
    SuccessPasswordUpadte,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const HandlePassword = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <Content column>
            {showImage && (
                <Flex hAlign="center">
                    <ImagePasseword src={PassewordImg} />
                </Flex>
            )}

            {globalError && (
                <>
                    <Flex>
                        {globalError && <ErrorMessage content={globalError} />}
                    </Flex>
                    <br />
                    <Button
                        styles={{ textDecoration: "none" }}
                        as="a"
                        href="/login"
                        title="Go to Login"
                        variant="outlined"
                    >
                        Retour
                    </Button>
                </>
            )}
            {SuccessPasswordUpadte ? (
                <UpdateNewPassword />
            ) : (
                !globalError && (
                    <form onSubmit={handleSubmit}>
                        <Flex column styles={{ gap: "24px" }}>
                            <Flex styles={{ gap: "24px" }} column>
                                {/* passeword input */}
                                <MdpInput column>
                                    <HeaderText content="Créer un mot de passe" />
                                    <Flex column>
                                        <Input
                                            // clearable
                                            fluid
                                            label="Mot de passe"
                                            placeholder="Votre mot de passe"
                                            autoFocus
                                            icon={
                                                <EyeIcon
                                                    onClick={() =>
                                                        HandlePassword()
                                                    }
                                                />
                                            }
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            error={error ? true : false}
                                            ref={refPassword}
                                            // value={password}
                                            onChange={ChangePassword}
                                        />
                                        <InputProgress progress={progress} />
                                    </Flex>
                                </MdpInput>
                                {/* confirm passeword */}
                                <ConfirmMdp fill column>
                                    <Input
                                        clearable
                                        fluid
                                        label="Confirmer le mot de passe"
                                        // placeholder="Votre mot de passe"

                                        icon={
                                            <EyeIcon onClick={HandlePassword} />
                                        }
                                        onChange={(e) =>
                                            setCPassword(e.target.value)
                                        }
                                        placeholder="Confirmez votre mot de passe"
                                        type="password"
                                        value={CPassword}
                                        error={error ? true : false}
                                        helperText={error}
                                        ref={refCPassword}
                                    />
                                    {password !== CPassword && (
                                        <ErrorMessage content="Les deux mots de passe ne sont pas identique" />
                                    )}
                                </ConfirmMdp>
                                {/* condition password  */}
                                <PswFlex column>
                                    <Text content="Condition à respecter :" />
                                    {/* {rules.show_errors && ( */}
                                    <ErrorText fill column>
                                        <li>
                                            <TextStatus
                                                status={rules.length.error}
                                                content={rules.length.message}
                                            />
                                        </li>
                                        <li>
                                            <TextStatus
                                                status={rules.lowercase.error}
                                                content={
                                                    rules.lowercase.message
                                                }
                                            />
                                        </li>
                                        <li>
                                            <TextStatus
                                                status={rules.uppercase.error}
                                                content={
                                                    rules.uppercase.message
                                                }
                                            />
                                        </li>
                                        <li>
                                            <TextStatus
                                                status={rules.number.error}
                                                content={rules.number.message}
                                            />
                                        </li>
                                        <li>
                                            <TextStatus
                                                status={rules.symbol.error}
                                                content={rules.symbol.message}
                                            />
                                        </li>
                                    </ErrorText>
                                </PswFlex>
                            </Flex>

                            <Flex>
                                <Button
                                    // onClick={validatePasseword}
                                    fluid
                                    content="VALIDER LE MOT DE PASSE"
                                    primary
                                    loading={
                                        isLoading || isFetching ? true : false
                                    }
                                    flat
                                    disabled={
                                        progress !== 100 ||
                                        CPassword !== password
                                    }
                                    onClick={
                                        CPassword !== password
                                            ? null
                                            : handleSubmit
                                    }
                                />
                            </Flex>
                        </Flex>
                    </form>
                )
            )}
        </Content>
    );
};

export default PasswordPage;
const ErrorText = styled(Flex)`
    margin-left: 25px;
`;

const TextStatus = styled(Text)`
    color: ${({ status }) => (status ? "var(--color-danger)" : "#21ad21")};
`;

const Content = styled(Flex)`
    width: 315px;
    /* height: 596px; */
    gap: 40px;
`;
const ImagePasseword = styled(Image)`
    max-height: 100px;
`;
const ConfirmMdp = styled(Flex)``;

const HeaderText = styled(Text)`
    color: #5b5fc7;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

const MdpInput = styled(Flex)`
    gap: 16px;
`;

const PswConditionText = styled.li`
    color: #424242;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 10px;
`;

const PswFlex = styled(Flex)`
    gap: 8px;
`;
const InputProgress = styled.div`
    width: ${({ progress }) => progress + "%"};
    height: 8px;
    background-color: #75e675;
    border-radius: 0px 0px 4px 4px;
    transition: width 0.3s;
`;

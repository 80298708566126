import {
    Box,
    Button,
    Flex,
    Image,
    Text,
    Divider,
    FlexItem,
} from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import {
    Copy24Regular,
    AttachArrowRight24Regular,
    ChevronUp24Regular,
    ChevronDown24Filled,
} from "@fluentui/react-icons";
import { DROPDOWN_COUNTRIES_FORMAT } from "../../../../../constants/countries";
import { useState } from "react";
import { Link } from "@fluentui/react-components";
import { DEVICE } from "../../../../../constants/responsive";

const CIVILITY = {
    1: "Homme",
    2: "Femme",
    3: "Autre",
};

const MARITAL_STATUS = {
    1: "Célibataire",
    2: "Marié(e)",
    3: "Veuf(ve)",
    4: "Divorcé(e)",
};

export default function Identity({ me }) {
    const [show, setShow] = useState(false);
    const [personelInfo, setPersonalInfo] = useState(true);
    const [etatCivil, setEtatCivil] = useState(false);

    const showEtatCivil = () => {
        setEtatCivil(true);
    };
    const hideEtatCivil = () => {
        setEtatCivil(false);
    };

    const showPersonalInfo = () => {
        setPersonalInfo(true);
    };
    const hidePersonalInfo = () => {
        setPersonalInfo(false);
    };

    const showContactUrgence = () => {
        setShow(true);
    };

    const hideContactUrgence = () => {
        setShow(false);
    };

    const copyToClipboard = (email) => {
        navigator.clipboard?.writeText(email);
    };

    const list_countries = DROPDOWN_COUNTRIES_FORMAT();

    const my_country = list_countries.filter(
        (el) => el.detail.code.toLowerCase() === me?.nationality
    )[0]?.detail;

    return (
        <Container>
            <Flex
                vAlign="center"
                style={{ width: "100%", justifyContent: "space-between" }}
            >
                <FlexItem>
                    <Title
                        weight="semibold"
                        content="Informations personnelles"
                    />
                </FlexItem>
                <FlexItem>
                    {personelInfo ? (
                        <Button
                            content={"Masquer"}
                            iconPosition="after"
                            icon={<ChevronUp24Regular />}
                            text
                            primary
                            onClick={hidePersonalInfo}
                            size="medium"
                            inline="true"
                        />
                    ) : (
                        <Button
                            iconPosition="after"
                            icon={<ChevronDown24Filled />}
                            text
                            primary
                            onClick={showPersonalInfo}
                            size="medium"
                            inline="true"
                            content={"Afficher"}
                        />
                    )}
                </FlexItem>
            </Flex>
            <Flex gap="gap.medium">
                {personelInfo ? (
                    <Flex gap="gap.small" column>
                        <FlexItem>
                            <Label content="Civilité" />
                        </FlexItem>
                        <FlexItem>
                            <Data content={CIVILITY[me?.gender]} />
                        </FlexItem>
                        <FlexItem>
                            <Label content="Prénom" />
                        </FlexItem>
                        <FlexItem>
                            <Data content={me?.first_name} />
                        </FlexItem>
                        <FlexItem>
                            <Label content="Nom d'usage" />
                        </FlexItem>
                        <FlexItem>
                            <Data content={me?.last_name} />
                        </FlexItem>
                        <FlexItem>
                            <Label content="Nom de naissance" />
                        </FlexItem>
                        <FlexItem>
                            <Data content={me?.birth_name} />
                        </FlexItem>
                        <FlexItem>
                            <Label content="Adresse" />
                        </FlexItem>
                        <FlexItem>
                            <Data content={me?.address || " --- "} />
                        </FlexItem>
                        <FlexItem>
                            <Label content="Téléphone personnel" />
                        </FlexItem>
                        <FlexItem>
                            <Data
                                content={me?.personal_phone_number || " --- "}
                            />
                        </FlexItem>
                        <FlexItem>
                            <Label content="Email personnel" />
                        </FlexItem>
                        <EmailSection vAlign="center">
                            <Text
                                styles={{
                                    paddingRight: "18px",
                                    paddingTop: "-10px",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                }}
                                content={me?.email_perso}
                                color="brand"
                            />

                            {/* copy to clipboard  */}
                            <Button
                                onClick={copyToClipboard(me?.email_perso)}
                                text
                                circular
                                iconOnly
                                icon={<Copy24Regular />}
                            />
                        </EmailSection>
                    </Flex>
                ) : null}
            </Flex>
            <Divider
                style={{
                    width: "100%",
                }}
            />

            <Flex
                vAlign="center"
                style={{ width: "100%", justifyContent: "space-between" }}
            >
                <FlexItem>
                    <Title content={"Etat Civil"} />
                </FlexItem>
                <FlexItem>
                    {etatCivil ? (
                        <Button
                            content={"Masquer"}
                            iconPosition="after"
                            icon={<ChevronUp24Regular />}
                            text
                            primary
                            onClick={hideEtatCivil}
                            size="medium"
                            inline="true"
                        />
                    ) : (
                        <Button
                            iconPosition="after"
                            icon={<ChevronDown24Filled />}
                            text
                            primary
                            onClick={showEtatCivil}
                            size="medium"
                            inline="true"
                            content={"Afficher"}
                        />
                    )}
                </FlexItem>
            </Flex>
            {etatCivil ? (
                <Flex gap="gap.medium" column>
                    <FlexItem>
                        <Label content="N° de sécurité sociale ou AVS" />
                    </FlexItem>
                    <FlexItem>
                        <Data content={me?.secu || "Non rensigné"} />
                    </FlexItem>
                    <FlexItem>
                        <Label content="Nationalité" />
                    </FlexItem>
                    <FlexItem>
                        <Flex gap="gap.medium" vAlign="center">
                            {my_country?.name && (
                                <Image
                                    style={{ width: "32px", height: "32px" }}
                                    avatar
                                    src={my_country?.image}
                                />
                            )}
                            <Data content={my_country?.name || "---"} />
                        </Flex>
                    </FlexItem>
                    <FlexItem>
                        <Label content="Date de naissance" />
                    </FlexItem>
                    <FlexItem>
                        <Data content={me?.birthdate || " --- "} />
                    </FlexItem>
                    <FlexItem>
                        <Label content="Lieu de naissance" />
                    </FlexItem>
                    <FlexItem>
                        <Data content={me?.place_of_birth || " --- "} />
                    </FlexItem>
                    <FlexItem>
                        <Label content="Situation familiale" />
                    </FlexItem>
                    <FlexItem>
                        <Data
                            content={
                                MARITAL_STATUS[me.marital_status] || " --- "
                            }
                        />
                    </FlexItem>
                </Flex>
            ) : null}

            <Divider
                style={{
                    width: "100%",
                }}
            />

            <Flex
                vAlign="center"
                style={{
                    justifyContent: "space-between",

                    width: "100%",
                }}
            >
                <Text weight="semibold" content="Personnes à charge" />
                {show ? (
                    <Button
                        content={"Masquer"}
                        iconPosition="after"
                        icon={<ChevronUp24Regular />}
                        text
                        primary
                        onClick={hideContactUrgence}
                        size="medium"
                        inline="true"
                    />
                ) : (
                    <Button
                        iconPosition="after"
                        icon={<ChevronDown24Filled />}
                        text
                        primary
                        onClick={showContactUrgence}
                        size="medium"
                        inline="true"
                        content={"Afficher"}
                    />
                )}
            </Flex>
            {show ? (
                <>
                    {!me.dependents_person?.length && <p>Non renseigné</p>}

                    {me?.dependents_person?.map((el, i) => {
                        return (
                            <Box key={i}>
                                <Flex
                                    style={{
                                        gap: "16px",
                                        width: "100%",
                                    }}
                                >
                                    <Flex.Item>
                                        <Text
                                            style={{ width: "175px" }}
                                            content={el.name}
                                        />
                                    </Flex.Item>
                                    <Flex.Item>
                                        <Text
                                            color="#616161"
                                            content={`${el.birthdate}`}
                                        />
                                    </Flex.Item>
                                </Flex>
                            </Box>
                        );
                    })}
                </>
            ) : null}
        </Container>
    );
}

const EmailSection = styled(Flex)`
    justify-content: center;

    width: 100%;
    gap: 580px;
    @media ${DEVICE.mobileS} {
        gap: 20px;
    }
    @media ${DEVICE.mobileM} {
        gap: 80px;
    }
    @media ${DEVICE.mobileL} {
        gap: 130px;
    }
    @media ${DEVICE.tablet} {
        gap: 470px;
    }
    @media ${DEVICE.laptop} {
        gap: 580px;
    }
`;
const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    //gap is 16 in figma
    gap: 10px;
    padding-inline: 2px;
    margin: 9px 16px 9px 16px;
    /* border: 1px solid red; */
`;
const Label = styled(Text)`
    color: #686868;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
`;
const Title = styled(Text)`
    color: #242424;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
`;
const Data = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #252525;
    font-style: normal;
`;

import React from "react";
import { TextDates, TextUsername, WrapperContent } from "./index.style";
import HeaderContent from "./HeaderContent/HeaderContent";
import {
    Avatar,
    Button,
    Divider,
    Flex,
    Loader,
} from "@fluentui/react-northstar";
import { CalendarInfo20Regular } from "@fluentui/react-icons";
import DialogDeclareRemoteDay from "../DialogDeclareRemoteDay/DialogDeclareRemoteDay";
import useLogicRemotePopupContent from "../../hooks/useLogicRemotePopupContent";

/**
 * @typedef {Object} Dates
 * @property {string} start
 * @property {string} end
 *
 * @typedef {Object} Times
 * @property {string} start
 * @property {string} end
 *
 *
 * @typedef {Object} PropsType
 * @property {number} event_id - id de l'event
 * @property {{ icon: React.JSX, content : string, reason_id : number | null }} title
 * @property {{ name: string, avatar : string }} user
 * @property {Dates} dates
 * @property {Times} times
 * @property {boolean} can_update_or_delete - show or not buttons actions
 *
 * @param {PropsType} Props
 * @returns
 */
const PopupContent = ({
    closePopup = () => null,
    event_id,
    title,
    user,
    dates,
    times,
    can_update_or_delete,
}) => {
    const { strings, mutate, query } = useLogicRemotePopupContent({
        event_id,
        dates,
        times,
    });

    return (
        <WrapperContent
            // ! class obligatoire ne pas retirer
            className="popup"
            column
            data-event-id={event_id}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            {/* header */}
            <HeaderContent
                closePopup={closePopup}
                content={title.content}
                icon={title.icon}
            />
            {/* body */}
            <Flex styles={{ marginTop: "16px", paddingInline: "16px" }} column>
                <Flex column gap="gap.small">
                    <Flex gap="gap.smaller" vAlign="center">
                        <Avatar
                            name={user.name}
                            image={user.avatar}
                            size="medium"
                        />
                        <TextUsername content={user.name} />
                    </Flex>
                    <Divider />
                    <Flex fill gap="gap.smaller" vAlign="center">
                        <Flex>
                            <CalendarInfo20Regular
                                color={"var(--color-primary)"}
                            />
                        </Flex>
                        <Flex fill gap="gap.large" space="between">
                            <Flex column gap="gap.smaller">
                                <TextDates content="Début" />
                                <TextDates content="Fin" />
                            </Flex>
                            <Flex fill column gap="gap.smaller">
                                <TextDates content={strings.format_start_str} />
                                <TextDates content={strings.format_end_str} />
                            </Flex>
                        </Flex>
                    </Flex>
                    <Divider />
                </Flex>
                {/* buttons */}
                {can_update_or_delete && (
                    <Flex
                        fill
                        gap="gap.smaller"
                        hAlign="end"
                        styles={{ marginTop: "32px" }}
                    >
                        <Button
                            onClick={mutate.handleClickDelete}
                            disabled={mutate.isLoading}
                            secondary
                            flat
                        >
                            {mutate.isLoading ? "Suppression.." : "Supprimer"}
                        </Button>
                        <DialogDeclareRemoteDay
                            isForUpdatingDay={true}
                            updateData={{
                                start_date: query?.data?.start?.date,
                                start_time: query?.data?.start?.time,
                                end_date: query?.data?.end?.date,
                                end_time: query?.data?.end?.time,
                                reason: query?.data?.reason?.value,
                            }}
                            event_id={event_id}
                            onClose={() => {
                                return query.refetch();
                            }}
                            trigger={
                                <Button flat primary loading={true}>
                                    Modifier
                                </Button>
                            }
                        />
                    </Flex>
                )}
            </Flex>
        </WrapperContent>
    );
};

export default PopupContent;

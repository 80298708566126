import React from "react";
import {
    AddIcon,
    Button,
    CloseIcon,
    EditIcon,
    Flex,
    Image,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";

const Card = ({ children, ...props }) => {
    return <Wrapper {...props}>{children}</Wrapper>;
};

const EditTitle = ({ content, onClose }) => {
    return (
        <WrapperEditTitle space="between" vAlign="center">
            <WrapperEditTitleText content={content} />
            <CloseIcon
                styles={{ color: "white", cursor: "pointer" }}
                onClick={onClose}
            />
        </WrapperEditTitle>
    );
};

const Illustration = ({
    image,
    positionTop = 0,
    canEdit,
    onEdit = () => console.log("edit enabled !"),
}) => {
    return (
        <WrapperIllustration
            className={canEdit ? "editable" : ""}
            positionTop={positionTop}
            onClick={onEdit}
        >
            <Image src={image} width="100%" />
        </WrapperIllustration>
    );
};

const Title = ({
    content,
    canEdit,
    onEdit = () => console.log("edit enabled !"),
}) => {
    return (
        <WrapperTitle fill space="between" vAlign="center">
            <TitleContent content={content} />
            {canEdit && (
                <Button
                    icon={<EditIcon outline />}
                    flat
                    iconOnly
                    secondary
                    onClick={onEdit}
                />
            )}
        </WrapperTitle>
    );
};

const Body = ({ content, pd0 }) => {
    return <TextBody content={content} />;
};

Card.Illustration = Illustration;
Card.Title = Title;
Card.Body = Body;
Card.EditTitle = EditTitle;

export default Card;

const TextBody = styled(Text)`
    padding: 24px;
    color: #424242;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

const TitleContent = styled(Text)`
    color: #424242;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
`;

const WrapperTitle = styled(Flex)`
    background: #e8ebfa;
    padding: 12px 16px;
`;

const Wrapper = styled(Flex)`
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    background: #ffffff;
`;

const WrapperIllustration = styled(Flex)`
    height: 152px;
    position: relative;
    :hover.editable {
        ::after {
            transition: all 0.2s;
            content: "";
            position: absolute;
            inset: 0;
            border: 1px solid white;
            background-color: rgba(37, 36, 35, 0.65);
        }
        ::before {
            z-index: 2;
            content: "Modifier les informations";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            color: white;
            border: 1px solid #d1d1d1;
            border-radius: 4px;
            padding: 6px 12px;
            cursor: pointer;
        }
    }
    img {
        object-fit: cover;
        object-position: 0px ${({ positionTop }) => positionTop + "px"};
    }
`;

export const WrapperEditTitle = styled(Flex)`
    background: #2f2f4a;
    padding: 12px 16px;
`;

export const WrapperEditTitleText = styled(Text)`
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
`;

import React, { useEffect, useRef } from "react";
import ViewSDKClient from "./ViewSDKClient";
const RenderViewPdf = ({ url }) => {
    useEffect(() => {
        loadPDF();
    }, []);

    const loadPDF = () => {
        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            viewSDKClient.previewFile(
                "pdf-div",
                {
                    defaultViewMode: "FIT_WIDTH",
                    showAnnotationTools: false,
                    showLeftHandPanel: false,
                    showPageControls: false,
                    showDownloadPDF: false,
                    showPrintPDF: false,
                },
                url
            );
        });
    };

    return (
        <div className="mt-28" style={{ width: "100%" }}>
            <div
                style={{ height: "100vh" }}
                id="pdf-div"
                className="full-window-div border border-gray-100 h-screen"
                // eslint-disable-next-line react/no-unknown-property
                // onDocumentLoad={loadPDF()}
            ></div>
        </div>
    );
};
export default RenderViewPdf;

import React, { useState } from "react";
import { Container, ContainerFluid } from "../../../common/styles";
import OnboardingSearchBar from "./OnboardingSearchBar";
import Content from "./Content";
import { Flex } from "@fluentui/react-northstar";

export default function MainContentOnboarding({ AgencyId }) {
    const [userIdSelected, setUserIdSelected] = useState(null);
    // console.log({ userIdSelected });
    return (
        <ContainerFluid fill>
            <Container fill column>
                {/* search bar  */}
                <Flex
                    styles={{
                        paddingBlock: "14px",
                    }}
                    hAlign="center"
                >
                    <OnboardingSearchBar
                        setUserIdSelected={setUserIdSelected}
                        AgencyId={AgencyId}
                    />
                </Flex>
                {/* Main content page  */}
                <Content
                    userIdSelected={userIdSelected}
                    AgencyId={AgencyId}
                    setUserIdSelected={setUserIdSelected}
                />
            </Container>
        </ContainerFluid>
    );
}

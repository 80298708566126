import React from "react";
import { Button, Flex, Image, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BASE_URL_ILLUSTRATIONS } from "../../../constants";
import { useSetGotoClicked } from "../stores/useOnboarding";
import { useQueryClient } from "react-query";

const ProcessCongratulation = () => {
    const { t } = useTranslation("onboarding");
    const setGotoClicked = useSetGotoClicked();
    const queryClient = useQueryClient();

    const _goto_dashboard_or_cgu = () => {
        // if (!store_welcome_screen?.cgu_read) {
        // 	return setGotoClicked();
        // }
        queryClient.refetchQueries("welcome_screen");
        setGotoClicked();
        // queryClient.removeQueries("welcome_screen");
        return;
    };

    return (
        <FlexContainer column vAlign="center" hAlign="center" gap="gap.large">
            <Image src={`${BASE_URL_ILLUSTRATIONS}/Thumb-up.svg`} />
            <Flex column hAlign="center" gap="gap.small">
                <Title>{t("section_congratulation.title")}</Title>
                <Description align="center">
                    {t("section_congratulation.description")}
                </Description>
            </Flex>
            <ButtonGo
                content={t("section_congratulation.button_text")}
                primary
                onClick={() => _goto_dashboard_or_cgu()}
            />
        </FlexContainer>
    );
};

export default ProcessCongratulation;

const ButtonGo = styled(Button)`
    min-width: 280px;
`;

const Description = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #424242;
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #242424;
`;

const FlexContainer = styled(Flex)`
    max-width: 536px;
    margin: auto;
`;

import React from "react";
import {
    Avatar,
    CheckmarkCircleIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";

const UserSelection = ({
    avatar = null,
    fullName = "",
    jobTitle = "",
    selected = false,
    onClick = () => console.log("clicked on => " + fullName),
}) => {
    return (
        <Container
            gap="gap.small"
            vAlign="center"
            selected={selected}
            onClick={() => {
                if (selected) {
                    return console.log("already selected !");
                }
                return onClick();
            }}
        >
            <div>
                <Avatar
                    size="large"
                    name={fullName.toUpperCase()}
                    image={avatar}
                />
            </div>
            <Flex fill>
                <Flex column fill>
                    <FullName selected={selected} content={fullName} />
                    <JobTitle content={jobTitle} />
                </Flex>
                <Flex vAlign="center" hAlign="end">
                    {selected && (
                        <CheckmarkCircleIcon styles={{ color: "#5B5FC7" }} />
                    )}
                </Flex>
            </Flex>
        </Container>
    );
};

export default UserSelection;

const JobTitle = styled(Text)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
`;

const FullName = styled(Text)`
    color: ${({ selected }) => (selected ? "#5B5FC7" : "#616161")};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
`;

const Container = styled(Flex)`
    --active-color: #ebebeb;
    cursor: pointer;
    padding: 8px 10px;
    background-color: ${({ selected }) =>
        selected ? "var(--active-color)" : "white"};
    :hover {
        background-color: var(--active-color);
    }
`;

import React from "react";
import { Badge, BadgeText, Container } from "./index.style";
import { Clock20Regular } from "@fluentui/react-icons";

/**
 * @typedef {Object} PropsType
 * @property {string} background_color
 * @property {string} striped_color
 * @property {string} leave_name
 * @property {boolean} is_half_day
 * @property {boolean} is_pending_leave - pour afficher le style de pending_leave
 */

/**
 * StripedTypeLeave permet d'afficher les rayures des absences.(RTT, congé, etc ...)
 * @param {PropsType} props
 * @returns {ReactNode}
 */
const StripedTypeLeave = ({
    background_color = "#fcf4f6",
    striped_color = "#e6808a",
    leave_name = "",
    is_half_day = false,
    is_pending_leave = true,
}) => {
    return (
        <Container
            vAlign="center"
            hAlign="center"
            background_color={background_color}
            striped_color={striped_color}
            is_half_day={is_half_day}
            is_pending_leave={is_pending_leave}
        >
            <Badge
                striped_color={striped_color}
                is_pending_leave={is_pending_leave}
                vAlign="center"
            >
                <Clock20Regular />
                <BadgeText content={leave_name} />
            </Badge>
        </Container>
    );
};

export default StripedTypeLeave;

import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

const DependentsPersons = ({ dependents_person }) => {
    return (
        <Flex column fill gap="gap.medium">
            <Title content="Personnes à charge" />
            {!dependents_person?.length ? (
                <Text content="-" />
            ) : (
                dependents_person?.map((person) => {
                    return (
                        <Flex space="between">
                            <Content content={`${person?.name}`} />
                            <Content content={person?.birthdate} />
                        </Flex>
                    );
                })
            )}
        </Flex>
    );
};

export default DependentsPersons;

const Content = styled(Text)`
    color: #242424;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

const Title = styled(Text)`
    color: #242424;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
`;

import { useState } from "react";
import { useQuery } from "react-query";
import { QUERY_KEY } from "../../constants";
import {
    _api_export_excel_history_accounting_manager,
    _api_get_list_history_paid_expenses,
} from "../../api";
import {
    func_format_data_history_rows,
    get_monthname_and_year,
    navigation_month_to,
} from "./functions";
import { INIT_MONTH_NAVIGATION, TYPE_NAVIGATION_MONTH } from "./constants";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const useLogicHistory = () => {
    const navigate = useNavigate();

    // STATES
    const [data, setData] = useState([]); // list of rows
    const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);
    const [monthNavigation, setMonthNavigation] = useState(
        INIT_MONTH_NAVIGATION
    ); // state month and year for slider

    // QUERIES
    const { isLoading: isLoadingHistory, isFetching: isFetchingHistory } =
        useQuery(
            [
                QUERY_KEY + "::getListHistoryPaidExpenses",
                {
                    month_index: monthNavigation.month_index,
                    year: monthNavigation.year,
                },
            ],
            (query) => {
                // TODO : send MONTH_INDEX and YEAR args
                return _api_get_list_history_paid_expenses(
                    query.queryKey.at(1)
                );
            },
            {
                onSuccess: (response) => {
                    if (Array.isArray(response)) {
                        const format_rows = func_format_data_history_rows(
                            response,
                            navigate
                        );
                        setData(format_rows);
                    } else {
                        setData([]);
                    }
                },
            }
        );

    // RETURNS
    return {
        query_history: {
            data_history: data,
            isLoadingHistory,
            isFetchingHistory,
            isEmpty: data.length === 0,
        },
        can_navigate: {
            backward: true,
            forward:
                monthNavigation.month_index === moment().month() + 1
                    ? false
                    : true,
        },
        navigation_month_clicks: {
            forward: () =>
                navigation_month_to(
                    TYPE_NAVIGATION_MONTH.forward,
                    monthNavigation,
                    setMonthNavigation
                ),
            backward: () =>
                navigation_month_to(
                    TYPE_NAVIGATION_MONTH.backward,
                    monthNavigation,
                    setMonthNavigation
                ),
        },
        month_and_year_name: get_monthname_and_year(monthNavigation),
        history: {
            loading: isDownloadingExcel,
            download_excel: async () => {
                setIsDownloadingExcel(true);
                await _api_export_excel_history_accounting_manager({
                    month_index: monthNavigation.month_index,
                    year: monthNavigation.year,
                });
                setIsDownloadingExcel(false);
            },
        },
    };
};

export default useLogicHistory;

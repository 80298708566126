import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const LEVELS_TOTAL = {
    info: "info",
    refuse: "refuse",
    approuve: "approuve",
};

const THEME = {
    [LEVELS_TOTAL.info]: {
        textColor: "#616161",
    },
    [LEVELS_TOTAL.refuse]: {
        textColor: "#C4314B",
    },
    [LEVELS_TOTAL.approuve]: {
        textColor: "#13A10E",
    },
};

const init_config = (level) => {
    return THEME[level];
};

const TotalExpense = ({ content, level = LEVELS_TOTAL.info }) => {
    const config = init_config(level);
    return (
        <Container {...config} level={level}>
            {content}
        </Container>
    );
};

export default TotalExpense;

const Container = styled(Text)`
    white-space: nowrap;
    margin-inline: 6px;
    font-weight: ${({ level }) =>
        level === LEVELS_TOTAL.info ? "400" : "700"};
    font-size: 12px;
    line-height: 16px;

    text-align: right;
    color: ${({ textColor }) => textColor};
`;

import { Radio, RadioGroup } from "@fluentui/react-components";
import {
    Accordion,
    Box,
    Dropdown,
    Flex,
    Input,
    InputLabel,
    Text,
    TextArea,
} from "@fluentui/react-northstar";
import MuiPhoneNumber from "material-ui-phone-number";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import {
    DROPDOWN_COUNTRIES_FORMAT,
    getCountryNameByCode,
} from "../../../../../constants/countries";

import SETUP_PROFILE from "../../../constants";
import MessageError from "../../components/MessageError";
import DependentPerson from "./DependentPerson";

const Identity = () => {
    const list_countries = DROPDOWN_COUNTRIES_FORMAT();
    const {
        register,
        control,
        getValues,
        formState: { errors },
    } = useFormContext();

    return (
        <AccordionStyle>
            <InformationPersonnel gap="gap.small" column fill>
                <Text
                    style={{ marginTop: "5px", marginBottom: "16px" }}
                    weight="bold"
                    content="Informations personnelles"
                />
                <Civility column fill>
                    <Label content="Civilité " />
                    <Controller
                        control={control}
                        name="gender"
                        render={({ field: { onChange, onBlur, ref } }) => (
                            <RadioGroup
                                onChange={(_, e) => onChange(parseInt(e.value))}
                            >
                                {SETUP_PROFILE.GENDER.map((el) => {
                                    return (
                                        <Radio
                                            checked={
                                                el?.id === getValues("gender")
                                            }
                                            key={el.id}
                                            label={el.name}
                                            value={el.id}
                                            onBlur={onBlur}
                                            ref={ref}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        )}
                    />
                </Civility>
                <FirstName column>
                    <Label>Prénom</Label>
                    <Input
                        {...register("first_name")}
                        defaultValue={getValues("first_name")}
                        name="first_name"
                        type={"text"}
                        fluid
                        inverted
                        error={errors.first_name ? true : false}
                    />
                    {errors?.first_name && (
                        <MessageError
                            error
                            content={errors.first_name?.message}
                        />
                    )}
                </FirstName>
                <LastName column>
                    <Label>Nom d'usage</Label>
                    <Input
                        {...register("last_name")}
                        defaultValue={getValues("last_name")}
                        type="text"
                        name="last_name"
                        inverted
                        fluid
                        error={errors.last_name ? true : false}
                    />
                    {errors?.last_name && (
                        <MessageError
                            error
                            content={errors.last_name?.message}
                        />
                    )}
                </LastName>
                <BirthName column>
                    <Label>Nom de naissance</Label>
                    <Input
                        type={"text"}
                        name="birth_name"
                        {...register("birth_name")}
                        defaultValue={getValues("birth_name")}
                        inverted
                        fluid
                        error={errors?.birth_name ? true : false}
                    />
                    {errors?.birth_name && (
                        <MessageError
                            error
                            content={errors?.birth_name?.message}
                        />
                    )}
                </BirthName>
                <Flex column fill>
                    <Label>Adresse complète</Label>
                    <TextArea
                        label="Adresse complète"
                        fluid
                        defaultValue={getValues("address")}
                        {...register("address")}
                        error={errors?.address ? true : false}
                        inverted
                    />
                    {errors?.address && (
                        <MessageError content={errors?.address?.message} />
                    )}
                </Flex>
                <PersonalPhoneNumber column>
                    <Label content={"Téléphone Personnel"} />
                    <Controller
                        name="personal_phone_number"
                        defaultValue={getValues("personal_phone_number")}
                        control={control}
                        required
                        render={({ field: { ...fieldProps } }) => {
                            return (
                                <MuiPhoneNumberStyle
                                    {...fieldProps}
                                    type="tel"
                                    defaultCountry={"de"}
                                    autoFormat={true}
                                    disableAreaCodes={true}
                                    onlyCountries={[
                                        "dz",
                                        "fr",
                                        "us",
                                        "ch",
                                        "de",
                                        "it",
                                        "ca",
                                        "lu",
                                        "mc",
                                        "nl",
                                        "no",
                                        "pl",
                                        "pt",
                                        "tr",
                                        "tn",
                                    ]}
                                />
                            );
                        }}
                        error={errors?.personal_phone_number ? true : false}
                    />
                    {errors?.personal_phone_number && (
                        <MessageError
                            error
                            content={errors?.personal_phone_number?.message}
                        />
                    )}
                </PersonalPhoneNumber>
                <Text
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                    weight="bold"
                    content="Etat civil"
                />
                <Secu column>
                    <Label>N° de sécurité sociale ou AVS</Label>
                    <Input
                        name="secu"
                        type={"number"}
                        {...register("secu")}
                        defaultValue={getValues("secu")}
                        inverted
                        fluid
                        error={errors.secu ? true : false}
                    />
                    {errors?.secu && (
                        <MessageError error content={errors?.secu?.message} />
                    )}
                </Secu>
                <Nationality column>
                    <Label>Nationalité</Label>
                    <Controller
                        control={control}
                        name="nationality"
                        render={({ field: { onBlur, onChange, ref } }) => {
                            return (
                                <DropdownStyleEN
                                    defaultValue={getCountryNameByCode(
                                        getValues("nationality")
                                    )}
                                    items={list_countries}
                                    onBlur={onBlur}
                                    placeholder="Selectionner votre Nationalité"
                                    name="nationality"
                                    onChange={(_, e) => {
                                        onChange(e.value.detail.code);
                                    }}
                                    fluid
                                    inverted
                                    ref={ref}
                                />
                            );
                        }}
                    />
                </Nationality>
                <BirthDate column>
                    <Label>Date de naissance</Label>
                    <DatepickerStyle
                        type="date"
                        name="birthdate"
                        {...register("birthdate")}
                        defaultValue={getValues("birthdate")}
                    />
                </BirthDate>
                <BirthPlace column>
                    <Label>Lieux de Naissance</Label>
                    <Input
                        name="place_of_birth"
                        {...register("place_of_birth")}
                        defaultValue={getValues("place_of_birth")}
                        type={"text"}
                        inverted
                        fluid
                        // error={errors.place_of_birth ? true : false}
                    />
                    {/* {errors?.place_of_birth && (
            <MessageError error content={errors.place_of_birth?.message} />
          )} */}
                </BirthPlace>
                <MaritalStatus column>
                    <Label>Situation familiale</Label>
                    <Controller
                        name="marital_status"
                        control={control}
                        render={({ field: { onBlur, onChange, value } }) => {
                            return (
                                <DropdownStyle
                                    defaultValue={
                                        SETUP_PROFILE.MARITAL_STATUS.find(
                                            (el) =>
                                                el?.id ===
                                                getValues("marital_status")
                                        )?.name
                                    }
                                    onBlur={onBlur}
                                    items={SETUP_PROFILE.MARITAL_STATUS.map(
                                        (el) => {
                                            return {
                                                key: el.id,
                                                header: el.name,
                                            };
                                        }
                                    )}
                                    onChange={(_, i) => {
                                        return onChange(i.value.key);
                                    }}
                                    fluid
                                    inverted
                                />
                            );
                        }}
                    />
                </MaritalStatus>
            </InformationPersonnel>
            <Text
                style={{ marginTop: "25px" }}
                weight="bold"
                content="Personnes à charge "
            />
            <DependentPerson />
        </AccordionStyle>
    );
};

export default Identity;

const AccordionStyle = styled(Accordion)`
    .ui-accordion__title {
        padding-bottom: 0px;
    }
    .Accordion__checkbox--header {
        padding-bottom: 0px;
    }
    .ui-accordion__title__content-wrapper > .ui-box {
        background-repeat: no-repeat;
        background-position-y: 5px;
    }
`;

const InformationPersonnel = styled(Flex)``;
const FirstName = styled(Flex)``;
const LastName = styled(Flex)``;
const Civility = styled(Flex)``;
const BirthName = styled(Flex)``;
const PersonalPhoneNumber = styled(Flex)``;
const Secu = styled(Flex)``;
const Nationality = styled(Flex)``;
const BirthDate = styled(Flex)``;
const BirthPlace = styled(Flex)``;
const MaritalStatus = styled(Flex)``;
// const PieceIdentity = styled(Flex)``;

const Label = styled(Text)`
    font-size: 12px;
    font-style: normal;
    padding: 4px;
    color: #616161;
`;

const MuiPhoneNumberStyle = styled(MuiPhoneNumber)`
    background-color: white;
    border-radius: 4px;
    .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
        border-color: rgb(91, 95, 199) !important;
    }
    .MuiInput-underline {
        padding: 2px 8px !important;
    }
    .MuiInput-underline::before {
        border: 0px !important;
    }
`;
const DropdownStyleEN = styled(Dropdown)`
    .ui-dropdown__item {
        padding-block: 0px;
    }
    .ui-dropdown__item__image {
        margin: 0 5px 0px 5px;
    }
`;

const DatepickerStyle = styled(Input)`
    input {
        background-color: white;
        width: 254px;
    }
`;
const DropdownStyle = styled(Dropdown)``;

import { BASE_URL_API } from "../../constants";
import { useAuthStore } from "../../stores/useAuthStore";
import fetcher from "../../utils/fetcher";

export const getAnnuaire = async (query) => {
    const rest = query !== "" ? "?q=" + query : "";
    let url = BASE_URL_API + "/company/directory" + rest;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const downloadFile = async (path) => {
    const user = useAuthStore.getState().infos;
    return fetch(`${BASE_URL_API}/users/download${path}`, {
        headers: {
            Authorization: `Bearer ${user?.access}`,
        },
        method: "GET",
        credentials: "include",
    })
        .then(async (res) => {
            const contentDisposition = res.headers.get("Content-Disposition");
            console.log("Content-Disposition header:", contentDisposition);

            // Extract filename from Content-Disposition header
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            let filename = "user_format_export.xlsx"; // Default filename
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, "");
            }

            const blob = await res.blob();
            return { blob, filename };
        })
        .then(({ blob, filename }) => {
            let a = document.createElement("a");
            if (window.URL && window.URL.createObjectURL) {
                let objectUrl = window.URL.createObjectURL(blob);
                a.href = objectUrl;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(objectUrl);
            } else {
                // Fallback for browsers that do not support createObjectURL
                // You may need to handle this case differently based on your requirements
                alert(
                    "Your browser does not support this feature. Please try another browser."
                );
            }
        })
        .catch((error) => {
            console.error("Error fetching or processing data:", error);
            // Handle error as needed
        });
};

export const getOrgTree = async (user) => {
    let url = BASE_URL_API + "/company/org_chart/user/" + user + "?full=true";
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

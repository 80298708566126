import moment from "moment";

export const ACTION_TYPES_SLIDER_WEEK = {
    init: "init",
    increment: "increment",
    decrement: "decrement",
};

export const INIT_SLIDE_WEEK = {
    iso_week: null,
    year: null,
    start_date_week: null,
};

export const reducerSliderWeek = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES_SLIDER_WEEK.init: {
            const payload_week = action.payload.iso_week;
            console.log({ state });
            const m = moment().set("year", state.year).isoWeek(payload_week);
            const month_iso = m.isoWeekday(1);
            const start_date_week = month_iso.format("YYYY-MM-DD");
            const year = m.year();
            return {
                ...state,

                iso_week: payload_week,
                year,

                start_date_week,
            };
        }
        case ACTION_TYPES_SLIDER_WEEK.increment: {
            const m = moment()
                .set("year", state.year)
                .isoWeek(state.iso_week + 1);
            const month_iso = m.isoWeekday(1);
            const start_date_week = month_iso.format("YYYY-MM-DD");
            const year = month_iso.year();
            const week = m.isoWeek();
            return {
                ...state,
                iso_week: week,
                start_date_week: start_date_week,
                year,
            };
        }
        case ACTION_TYPES_SLIDER_WEEK.decrement: {
            const m = moment()
                .set("year", state.year)
                .isoWeek(state.iso_week - 1);
            const month_iso = m.isoWeekday(1);
            const year = month_iso.year();
            const start_date_week = month_iso.format("YYYY-MM-DD");
            const week = m.isoWeek();

            return {
                ...state,
                iso_week: week,
                start_date_week: start_date_week,
                year,
            };
        }

        default:
            return state;
    }
};

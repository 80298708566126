import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { Container } from "../../../../../common/styles";
import { DEVICE } from "../../../../../constants/responsive";

export const BorderInfo = styled(Flex)`
    min-width: 44px;
    height: 92px;

    border-radius: 0px var(--radius-xl-16, 16px) var(--radius-xl-16, 16px) 0px;
    background: var(
        --Light-theme-Rest-Background-Default-Background-3,
        #f0f0f0
    );
`;

export const Body = styled(Container)`
    /* padding-block: 24px; */
`;

export const Detail = styled(Flex)``;
export const DetailTitle = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );
    font-family: "Baloo Bhaijaan 2";
    font-style: normal;
    font-size: clamp(18px, 2vw, 24px);
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    position: relative;
    padding-right: 5px;
    width: fit-content;
    &::before {
        content: "";
        width: 100%;
        height: 8px;
        flex-shrink: 0;
        border-radius: 35px;
        background: var(--Color-background-hover, #e8ebfa);
        position: absolute;
        bottom: 3px;
    }
`;
export const UL = styled.ul`
    padding-left: 22px;
    li {
        color: var(
            --light-theme-rest-foreground-default-foreground-242424,
            #242424
        );
        font-size: clamp(14px, 2vw, 18px);
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
    }
`;
// CARDS
export const WrapperCards = styled(Container)`
    padding-left: clamp(0px, 4vw, 60px);
    gap: 48px;
    @media ${DEVICE.mobileS} {
        justify-content: center;
    }
    @media ${DEVICE.mobileM} {
        justify-content: center;
    }
    @media ${DEVICE.mobileL} {
        justify-content: start;
    }
`;

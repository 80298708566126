import React from "react";
import { CalendarEdit24Regular } from "@fluentui/react-icons";
import {
    Attachment,
    Button,
    Checkbox,
    CloseIcon,
    Dialog,
    Divider,
    Dropdown,
    FilesUploadIcon,
    Flex,
    Input,
    Loader,
    Text,
    TextArea,
} from "@fluentui/react-northstar";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ErrorMessage } from "../../../addEmployee/styles";
import styled from "styled-components";
import SliderTabs from "../../../../components/shared/SliderTabs";
import { ICON_FROM_EXTENSION } from "../../../../utils/functions";
import { yupResolver } from "@hookform/resolvers/yup";
import { FILE_SIZE_LIMIT, SCHEMA, SCHEMARH } from "../../constants";
import { util_format_start_and_end_time } from "../../utils";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { api_add_new_leave, api_get_leaves_list } from "../../api";
import { useState } from "react";
import { _getAllUsers } from "../../../../api/user";
import { AVATAR_DEFAULT } from "../../../../constants";
import { useRef } from "react";
import { _upload_file_temp } from "../../../../api/uploads";
import { file_size_format } from "../../../../constants/maths";
import moment from "moment";
import Toast from "../../../../components/Alerts";
import { DEVICE } from "../../../../constants/responsive";

const AbsenceRequest = ({
    showDialogAddLeave,
    setShowDialogAddLeave,
    refetchPersonnalCall,
    isForTeams,
    subHeader,
}) => {
    const ref_add_document2 = useRef(null);

    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(1);
    const [selectedUser, setSelectedUser] = useState(null);

    const [toastConfig, setToastConfig] = useState({
        content: "",
        visible: false,
        level: "warning",
    });

    const methods = useForm({
        resolver: yupResolver(subHeader === 2 ? SCHEMARH : SCHEMA),
        mode: "onChange",
        defaultValues: {
            justificatif: [],
        },
    });

    const {
        register,
        control,
        formState: { errors, isValid },
        setValue,
        watch,
        setError,
    } = methods;

    const cancel_dialog = () => {
        setSelectedUser("");
        methods.reset();
        methods.clearErrors();
        setShowDialogAddLeave((state) => {
            return { ...state, visible: false };
        });

        return;
    };

    const open_dialog = (e) => {
        e.preventDefault();
        return setShowDialogAddLeave({
            visible: true,
            start_date: null,
            end_date: null,
        });
    };

    const confirm_dialog = () => {
        methods.handleSubmit(onSubmit)();
        return;
    };

    // attachement document
    const inputFileChanged = async (e) => {
        const { files } = e.target;

        if (!files?.length) {
            return console.log("ya aucun fichier !!");
        }

        // limit the import to 5 files at once.
        if (files.length > 1) {
            alert("Vous avez dépasser la limite autorisée ( 1 fichier )");
            return;
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size > FILE_SIZE_LIMIT) {
                console.log(
                    "'" + file.name + "' à été retirer a cause de sa taille !"
                );
                return false;
            }
            formData.append("uploaded_files", file);
        }
        const response = await _upload_file_temp(formData);
        if (response?.success) {
            setValue("justificatif", response?.files);
        }
        return;
    };

    const { mutate: mutate_leave_request, isLoading: loading_mutate } =
        useMutation((data) => {
            // API function
            return api_add_new_leave(data);
        });

    const queryClient = useQueryClient();

    const onSubmit = (data) => {
        const { allow_leave_request_from_date } = data.LeaveType;

        if (loading_mutate) {
            return console.log("Already sending ....");
        }
        if (LeaveType_watcher?.document_required) {
            if (
                watch_justificatif?.length === 0 &&
                LeaveType_watcher?.attachment_type === 2
            ) {
                setToastConfig((state) => {
                    return {
                        ...state,
                        visible: true,
                        content: "Justificatif d'absence manquant !",
                        level: "danger",
                    };
                });
                return;
            }
        }

        const start = moment(data?.start_date).format("YYYY-MM-DD");
        const end = moment(data?.end_date).format("YYYY-MM-DD");

        if (
            allow_leave_request_from_date !== null &&
            start &&
            subHeader === 2
        ) {
            if (end < start) {
                setError("end_date", {
                    type: "manual",
                    message: `La date de fin ne peut pas être avant le ${moment(
                        start
                    ).format("DD-MM-YYYY")}`,
                });
                return;
            }
        }

        const {
            comment,
            end_date,
            end_time,
            start_date,
            start_time,
            justificatif,
            leave_user,
            negative_balance_alert_accepted,
        } = data;

        const { start_time: start_time_formated, end_time: end_time_formated } =
            util_format_start_and_end_time({
                start: start_time,
                end: end_time,
            });

        const { id: leave_type_id } = methods.getValues("LeaveType");
        mutate_leave_request(
            {
                leave_type_id: leave_type_id,
                start_date: moment(start_date).format("YYYY-MM-DD"),
                start_time: start_time_formated,
                end_date: moment(end_date).format("YYYY-MM-DD"),
                end_time: end_time_formated,
                comment,
                uploaded_files: justificatif?.map((el) => el?.id),
                leave_user: leave_user?.id,
                negative_balance_alert_accepted,
            },
            {
                onSuccess: (data, e) => {
                    if (data?.success) {
                        queryClient.refetchQueries(["allSocietyLeave"]);
                        cancel_dialog();
                        setToastConfig((state) => {
                            return {
                                ...state,
                                visible: true,
                                content: data?.message,
                                level: "success",
                            };
                        });
                        refetchPersonnalCall();
                    } else {
                        setToastConfig((state) => {
                            return {
                                ...state,
                                visible: true,
                                content: data?.message,
                                level: "danger",
                            };
                        });
                    }
                },
            }
        );
    };

    const {
        isLoading: isLoadingUsers,
        data: allUsers,
        isFetching: Fetching_for_users,
    } = useQuery(["getAllUsers"], () => _getAllUsers(), {});

    const { data: types, isLoading: isLoadingTypeLeaves } = useQuery(
        selectedUser || subHeader !== 2
            ? ["fetching_type_dabsence_For_RH", selectedUser?.id]
            : "fetching_type_dabsence",
        () => {
            if (selectedUser) {
                return api_get_leaves_list(selectedUser.id);
            } else {
                return api_get_leaves_list();
            }
        },
        {
            enabled: !!selectedUser || subHeader !== 2,
            onSuccess: (data) => {},
        }
    );

    if (isLoadingUsers || isLoadingTypeLeaves) {
        <Loader />;
    }

    const data_type_leave = types?.leave_types?.map((el) => {
        return {
            id: el?.id,
            key: el?.id,
            header: el?.name,
            document_required: el?.document_required,
            allow_mid_day: el?.allow_mid_day,
            allow_negative_balance: el?.allow_negative_balance,
            attachment_type: el?.attachment_type,
            allow_leave_request_from_date: el?.allow_leave_request_from_date,
        };
    });

    const LeaveType_watcher = watch("LeaveType");
    const watch_justificatif = watch("justificatif");
    const watch_starting_date = watch("start_date");

    const watch_ending_date = watch("end_date");

    const handleUserChange = (selectedValue) => {
        setSelectedUser(selectedValue);
    };

    return (
        <Wrapper>
            <Toast
                visible={toastConfig?.visible}
                content={toastConfig?.content}
                level={toastConfig?.level}
                onStatusChange={() => {
                    return setToastConfig({
                        visible: false,
                        content: "",
                        level: "success",
                    });
                }}
            />
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    style={{ width: "100%" }}
                >
                    <DialogStyle
                        open={showDialogAddLeave.visible}
                        onCancel={cancel_dialog}
                        onConfirm={confirm_dialog}
                        trigger={
                            <ButtonStyle
                                onClick={open_dialog}
                                icon={
                                    <CalendarEdit24Regular
                                        bordered
                                        circular
                                        size="smallest"
                                    />
                                }
                                primary
                                content="Créer une absence"
                            />
                        }
                        confirmButton={{
                            content: "Envoyer",
                            loading: loading_mutate,
                            disabled:
                                !isValid ||
                                !watch_starting_date ||
                                !watch_ending_date
                                    ? true
                                    : false,
                        }}
                        cancelButton={"Annuler"}
                        header={<Text content="Demander une absence" />}
                        headerAction={{
                            icon: <CloseIcon />,
                            title: "Close",
                            onClick: () => {
                                cancel_dialog();
                                setShowDialogAddLeave({
                                    visible: false,
                                    start_date: null,
                                    end_date: null,
                                });
                            },
                        }}
                        content={
                            <Flex column gap="gap.medium">
                                {/* isRH */}
                                {isForTeams && (
                                    <Flex column gap="gap.small">
                                        <Label content="Personne concerné" />
                                        <Controller
                                            name="leave_user"
                                            control={control}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                },
                                            }) => {
                                                return (
                                                    <Dropdown
                                                        search
                                                        fluid
                                                        items={allUsers?.map(
                                                            (el, index) => {
                                                                return {
                                                                    header: el?.name,
                                                                    content:
                                                                        el?.job_title,
                                                                    email: el?.email,
                                                                    image:
                                                                        el?.avatar ||
                                                                        AVATAR_DEFAULT,
                                                                    key: index,
                                                                    id: el?.id,
                                                                };
                                                            }
                                                        )}
                                                        placeholder="Quelles est la personne concerné ?"
                                                        onChange={(_, i) => {
                                                            onChange(i.value);
                                                            handleUserChange(
                                                                i.value
                                                            );
                                                            return;
                                                        }}
                                                        error={
                                                            errors?.leave_user
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                        <ErrorMessage
                                            content={
                                                errors?.leave_user?.message
                                            }
                                        />
                                    </Flex>
                                )}
                                <RaisonOfAbsence column gap="gap.smaller">
                                    <Label content="Type d'absence" />
                                    <div
                                        style={{
                                            height: "35px",
                                        }}
                                    >
                                        <Controller
                                            name="LeaveType"
                                            control={control}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                },
                                            }) => {
                                                return (
                                                    <DropdownStyle
                                                        styles={{
                                                            position: "fixed",
                                                            zIndex: 2,
                                                        }}
                                                        fluid
                                                        items={data_type_leave}
                                                        placeholder={
                                                            <p
                                                                style={{
                                                                    fontWeight:
                                                                        "400",
                                                                }}
                                                            >
                                                                Quelle est la
                                                                cause de
                                                                l'absence ?
                                                            </p>
                                                        }
                                                        onChange={(_, i) => {
                                                            console.log(
                                                                "type dabsence"
                                                            );
                                                            onChange(i.value);
                                                            setValue(
                                                                "justificatif",
                                                                []
                                                            );

                                                            return;
                                                        }}
                                                        error={
                                                            errors?.LeaveType
                                                                ? true
                                                                : false
                                                        }
                                                        disabled={
                                                            !selectedUser &&
                                                            subHeader === 2
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                    {errors?.LeaveType?.message && (
                                        <ErrorMessage
                                            content={errors?.LeaveType?.message}
                                        />
                                    )}
                                    {LeaveType_watcher?.attachment_type !== 0 &&
                                        (watch_justificatif?.length === 0 ? (
                                            <Flex>
                                                <Input
                                                    {...register(
                                                        "justificatif",
                                                        {
                                                            required: true,
                                                            message:
                                                                "Required is true",
                                                        }
                                                    )}
                                                    fluid
                                                    primary
                                                    type="file"
                                                    accept="image/*, .pdf"
                                                    ref={ref_add_document2}
                                                    styles={{
                                                        width: "280px",
                                                    }}
                                                    onChange={inputFileChanged}
                                                    hidden
                                                />
                                                {LeaveType_watcher?.document_required && (
                                                    <Button
                                                        content="Ajouter un justificatif d'absence "
                                                        tinted
                                                        icon={
                                                            <FilesUploadIcon />
                                                        }
                                                        onClick={() =>
                                                            ref_add_document2.current.click()
                                                        }
                                                    />
                                                )}
                                                <ErrorMessage
                                                    content={
                                                        errors?.justificatif
                                                            ?.message
                                                    }
                                                />
                                            </Flex>
                                        ) : watch_justificatif?.length !== 0 ? (
                                            <Attachment
                                                size="small"
                                                action={{
                                                    icon: <CloseIcon />,
                                                    onClick: () => {
                                                        setValue(
                                                            "justificatif",
                                                            []
                                                        );
                                                    },
                                                    title: "Close",
                                                }}
                                                header={
                                                    watch_justificatif?.at(0)
                                                        ?.filename
                                                }
                                                description={
                                                    file_size_format(
                                                        watch_justificatif?.at(
                                                            0
                                                        )?.filesize
                                                    ) + "o"
                                                }
                                                icon={ICON_FROM_EXTENSION(
                                                    watch_justificatif?.at(0)
                                                        ?.filename
                                                )}
                                                actionable
                                                progress={100}
                                            />
                                        ) : null)}
                                </RaisonOfAbsence>

                                <DatesOfAbsence>
                                    <Flex column gap="gap.smaller">
                                        <Label content="Commence le" />
                                        <DatepickerStyle
                                            {...register("start_date", {
                                                required: true,
                                            })}
                                            defaultValue={
                                                watch_starting_date ??
                                                showDialogAddLeave?.start_date
                                            }
                                            style={{
                                                marginBottom: "8px",
                                            }}
                                            type="date"
                                            error={
                                                errors.start_date ? true : false
                                            }
                                        />
                                        {errors?.start_date && (
                                            <Text
                                                color="red"
                                                size="small"
                                                content={
                                                    errors?.start_date?.message
                                                }
                                            />
                                        )}
                                        {LeaveType_watcher?.allow_mid_day && (
                                            <Controller
                                                name="start_time"
                                                defaultValue={0}
                                                control={control}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value,
                                                        name,
                                                    },
                                                }) => {
                                                    return (
                                                        <SliderTabs
                                                            name={name}
                                                            tabs={[
                                                                "Début de matinée",
                                                                "Début d'après-midi",
                                                            ]}
                                                            onChange={(i) => {
                                                                onChange(i);
                                                                setStartTime(i);
                                                            }}
                                                            defaultActiveIndex={
                                                                startTime
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                    </Flex>
                                    <Flex column gap="gap.smaller">
                                        <Label content="Fini le " />
                                        <DatepickerStyle
                                            name="end_date"
                                            {...register("end_date")}
                                            defaultValue={
                                                watch_starting_date ??
                                                showDialogAddLeave?.end_date
                                            }
                                            style={{
                                                marginBottom: "8px",
                                            }}
                                            type="date"
                                            iconPosition={"end"}
                                            min={watch_starting_date}
                                            error={
                                                errors?.end_date ? true : false
                                            }
                                        />
                                        {errors?.end_date && (
                                            <Text
                                                color="red"
                                                size="small"
                                                content={
                                                    errors?.end_date?.message
                                                }
                                            />
                                        )}

                                        {LeaveType_watcher?.allow_mid_day && (
                                            <Controller
                                                name="end_time"
                                                control={control}
                                                defaultValue={1}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value,
                                                    },
                                                }) => {
                                                    return (
                                                        <SliderTabs
                                                            tabs={[
                                                                "Fin de matinée",
                                                                "Fin de journée",
                                                            ]}
                                                            onChange={(i) => {
                                                                onChange(i);
                                                                setEndTime(i);
                                                            }}
                                                            defaultActiveIndex={
                                                                endTime
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                    </Flex>
                                </DatesOfAbsence>

                                <Flex column gap="gap.small">
                                    <Label
                                        content={"Commentaire (facultatif)"}
                                    />
                                    <Controller
                                        name="comment"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <TextArea
                                                    {...field}
                                                    placeholder={
                                                        "Ajouter un commentaire ... "
                                                    }
                                                    rows={2}
                                                    fluid
                                                />
                                            );
                                        }}
                                    />
                                </Flex>
                                {LeaveType_watcher?.allow_negative_balance &&
                                    !isForTeams && (
                                        <Flex gap="gap.medium">
                                            <Controller
                                                name="negative_balance_alert_accepted"
                                                control={control}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        ...rest
                                                    },
                                                }) => {
                                                    return (
                                                        <Checkbox
                                                            {...rest}
                                                            onChange={(
                                                                e,
                                                                { checked }
                                                            ) =>
                                                                onChange(
                                                                    checked
                                                                )
                                                            }
                                                            label="J’ai pris connaissance qu'en cas de solde de congés négatif et de départ de l'entreprise, une retenue sur salaire pourra être effectuée."
                                                        />
                                                    );
                                                }}
                                            />
                                        </Flex>
                                    )}
                            </Flex>
                        }
                    />
                </form>
            </FormProvider>
        </Wrapper>
    );
};

export default AbsenceRequest;

const Wrapper = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: auto;
    }
`;

export const DropdownStyle = styled(Dropdown)`
    @media ${DEVICE.mobileS} {
        width: 80%;
    }
    @media ${DEVICE.laptop} {
        width: 700px;
    }
`;

export const DialogStyle = styled(Dialog)`
    @media ${DEVICE.mobileS} {
        width: 90%;
        div.ui-dialog__footer > div > div {
            flex-direction: column-reverse;
            gap: 8px;
        }
    }
    @media ${DEVICE.mobileL} {
        width: 90%;
        div.ui-dialog__footer > div > div {
            flex-direction: row;
        }
    }
    @media ${DEVICE.laptop} {
        width: 764px;
        div.ui-dialog__footer > div > div {
            flex-direction: row;
        }
    }
`;

const ButtonStyle = styled(Button)`
    @media ${DEVICE.mobileS} {
        min-width: 100%;
    }
    @media ${DEVICE.laptop} {
        min-width: 100%;
    }
`;

const RaisonOfAbsence = styled(Flex)``;
export const DatesOfAbsence = styled(Flex)`
    @media ${DEVICE.mobileS} {
        gap: 15px;
        flex-direction: column;
    }
    @media ${DEVICE.laptop} {
        gap: 64px;
        flex-direction: row;
    }
`;
const DatepickerStyle = styled(Input)`
    input {
        width: 280px;
    }
`;

const Label = styled(Text)`
    color: #616161;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

import moment from "moment";
import { ROUTES } from "../../../constants/routes";
import { getYearMonthOfThisWeek } from "../functions";

const { year, month } = getYearMonthOfThisWeek();

/**
 * STRINGS
 */
export const I18N_NS_TIME_REPORTING = "time_reporting";
export const CURRENT_MONTH_NAME = moment().month("MMMM").format("MMMM");
export const CURRENT_MONTH_INDEX = month;
export const CURRENT_YEAR = year;
export const CURRENT_WEEK = moment().isoWeek();
/**
 * OBJECTS
 */
export const PROGRESS_COLORS = {
    green: "#13A10E",
    red: "#C4314B",
    blue: "#5B5FC7",
};

export const SLIDER_DIRECTION = {
    forward: "forward",
    backward: "backward",
};

export const INIT_REDUCER = {
    iso_week: CURRENT_WEEK,
    year: CURRENT_YEAR,
    month_indexed: CURRENT_MONTH_INDEX,
    dates: {
        start: moment().isoWeekday(1).format("DD MMMM YYYY"),
        end: moment().isoWeekday(7).format("DD MMMM YYYY"),
    },
    direction: null,
};

export const BREADCRUMB_TIME_REPORTING_DEFAULT = [
    {
        label: "breadcrumb.home",
        href: ROUTES.home,
    },
    {
        label: "breadcrumb.time_reporting",
        href: ROUTES.time_reporting.home,
    },
];
// for status of months
export const STATUS_MONTH = {
    in_progress: {
        type: "in_progress",
        content: "home.sections.month.status.in_progress",
        style: {
            backgroundColor: "#5B5FC7",
            color: "#fff",
        },
    },
    validated: {
        type: "validated",
        content: "home.sections.month.status.validated",
        style: {
            backgroundColor: "#E7F2DA",
            color: "#13A10E",
        },
    },
    rectification: {
        type: "rectification",
        content: "home.sections.month.status.rectification",
        style: {
            backgroundColor: "#FFAA44",
            color: "#fff",
        },
    },
    sended: {
        type: "sended",
        content: "home.sections.month.status.sended",
        style: {
            backgroundColor: "#EBEBEB",
            color: "#424242",
        },
    },
    default: {
        type: "default",
        content: "/",
        style: {
            backgroundColor: "white",
            color: "white",
        },
    },
};
export const PROPS_TYPE_STATUS_MONTH = [
    "in_progress",
    "in_progress",
    "in_progress",
    "in_progress",
];

export const STATUS_TOAST = {
    reject: "reject",
    approve: "approve",
    error: "warning",
    resent_mail: "resent_mail",
};

import React from "react";
import { Avatar, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

const AvatarName = ({ name = "", image = "", size = "medium" }) => {
    return (
        <Flex gap="gap.small" vAlign="center">
            <Avatar name={name} image={image} size={size} />
            <Name content={name} />
        </Flex>
    );
};

export default AvatarName;

const Name = styled(Text)`
    color: #424242;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;

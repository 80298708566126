import { useStepper } from "../stores";
import { getDaysOfWeek } from "../utils";

const DAYS_WEEK = getDaysOfWeek();

export const ACTION_TYPES_WEEK_DIFINITION = {
    init_planning: "init_planning",
    set_day_slot: "set_day_slot",
};

export const INIT_REDUCER_WEEK_DIFINITION = {
    weeks: DAYS_WEEK,
    planning: DAYS_WEEK.map((day, index) => ({
        id: index,
        day_name: day,
        slots: {
            morning: index > 4 ? 2 : 1,
            afternoon: index > 4 ? 2 : 1,
        },
    })),
};

export const reducer_week_definition = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES_WEEK_DIFINITION.set_day_slot: {
            const new_state = state?.planning?.map((day) => {
                if (day.id === action.payload.day_id) {
                    const new_value = action.payload.value;
                    return {
                        ...day,
                        slots: {
                            morning:
                                action.payload.slot_type === "morning"
                                    ? new_value
                                    : day.slots.morning,
                            afternoon:
                                action.payload.slot_type === "afternoon"
                                    ? new_value
                                    : day.slots.afternoon,
                        },
                    };
                }
                return day;
            });
            return {
                ...state,
                planning: new_state,
            };
        }
        case ACTION_TYPES_WEEK_DIFINITION.init_planning: {
            return { ...state, planning: action.payload.planning };
        }
        default:
            return state;
    }
};

import { Button, Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import styled from "styled-components";

const EmptyN2F = ({ ExpenseEmptyIcon, ShowLink, ShowText }) => {
    return (
        <Flex column fill gap="gap.large" hAlign="center">
            <Image src={ExpenseEmptyIcon} />

            {ShowText && <EmptyExpenseTitle content="Aucune note de frais" />}

            {/* <Link to={ROUTES.expenses.home}>
                <Button
                    content="Accéder aux notes de frais"
                    text
                    styles={{
                        textDecoration: "underline",
                        color: "#5B5FC7",
                    }}
                />
            </Link> */}
            {ShowLink && (
                <Flex>
                    <LinkToN2F vAlign="center" to={ROUTES.expenses.home}>
                        <LinkStyle content="Aller aux notes de frais >" />
                    </LinkToN2F>
                </Flex>
            )}
        </Flex>
    );
};

export default EmptyN2F;

const EmptyExpenseTitle = styled(Text)`
    color: #616161;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
`;

const LinkStyle = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0067b8;
`;

const LinkToN2F = styled(Link)`
    cursor: pointer;
`;

import React from "react";
import {
    Button,
    CloseIcon,
    Dialog,
    Dropdown,
    Flex,
    InfoIcon,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import { Calculator24Regular } from "@fluentui/react-icons";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getListMonths, lastDayOfTheMonth } from "../../utils";
import { useQuery, useQueryClient } from "react-query";
import { myProjectionCalcul } from "../../api";
import styled from "styled-components";
import { DropdownFixed } from "../../../../common/styles";
import { colors } from "@mui/material";
import { DEVICE } from "../../../../constants/responsive";

function Projection() {
    const [open, setOpen] = useState(false);
    const [relunch, setRelunch] = useState();

    // date projection test
    const list_months = getListMonths();

    const methods = useForm({
        resolver: yupResolver(SCHEMA),
        mode: "onSubmit",
        defaultValues: {
            projectionMonth: null,
        },
    });

    const {
        formState: { isValid },
        setValue,
        watch,
    } = methods;

    /**
     * Projection data from backEnd
     */
    const projection = methods.getValues("projectionMonth");
    const queryClient = useQueryClient();

    const {
        data: projectionData,
        refetch: api_my_projection,
        isLoading: isLoadingProjectionData,
    } = useQuery(
        ["myProjectionCalcul", projection?.code_month, projection?.code_year],
        () => myProjectionCalcul(projection),
        {
            enabled: false,
        }
    );

    const onSubmit = () => {
        if (!watch_projection_month) {
            return alert("hi i not submiting..... !");
        }
        api_my_projection();
        return;
    };

    const reset = () => {
        queryClient.resetQueries("myProjectionCalcul", {
            exact: false,
        });
        return;
    };

    /**
     * en of the Projection data from backEnd
     */
    const confirm_dialog = () => {
        methods.handleSubmit(onSubmit)();
        return;
    };
    const cancel_dialog = () => {
        setOpen(false);
        setRelunch(false);
        methods.resetField("projectionMonth");
        reset();
        return;
    };

    const onclick_relunch = () => {
        setRelunch(!relunch);
        methods.resetField("projectionMonth");
        reset();
        return;
    };
    const onclick_lunch = () => {
        setRelunch(!relunch);
        methods.handleSubmit(onSubmit)();
        return;
    };

    const watch_projection_month = watch("projectionMonth");

    return (
        <DialogProjection
            open={open}
            onCancel={cancel_dialog}
            // onConfirm={confirm_dialog}
            header={
                <Flex>
                    {relunch ? (
                        <Flex vAlign="center" gap="gap.smaller">
                            <div>
                                {`Calcul au ${lastDayOfTheMonth(
                                    watch_projection_month?.code_month - 1,
                                    watch_projection_month?.code_year
                                )}  ${watch_projection_month?.header}`}
                            </div>
                        </Flex>
                    ) : (
                        "Calculer un solde prévisionnel"
                    )}
                </Flex>
            }
            headerAction={{
                icon: <CloseIcon />,
                title: "Close",
                onClick: cancel_dialog,
            }}
            content={
                <Flex column>
                    {projectionData?.year ? (
                        <ProjectionDetail projectionData={projectionData} />
                    ) : (
                        <Flex
                            style={{
                                marginBlock: "10px 40px",
                                gap: "4px",
                                // position: "block",
                            }}
                            column
                        >
                            <Text
                                as="p"
                                content="Solde en fin de mois (1 an maximum)"
                            />
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(onSubmit)}>
                                    <Flex fluid column>
                                        <Controller
                                            name="projectionMonth"
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                },
                                            }) => {
                                                return (
                                                    <DropdownMonth
                                                        className="dropdownstyle"
                                                        fluid
                                                        items={list_months}
                                                        placeholder="Choisir un mois dans la liste"
                                                        onChange={(_, i) => {
                                                            setValue(
                                                                "projectionMonth",
                                                                i.value
                                                            );
                                                            return;
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Flex>
                                </form>
                            </FormProvider>
                        </Flex>
                    )}
                </Flex>
            }
            footer={
                <Footer hAlign="end">
                    <ProjectionAction
                        projection_data={projectionData}
                        onclick_relunch={onclick_relunch}
                        onclick_lunch={onclick_lunch}
                        cancel_dialog={cancel_dialog}
                        first_step_valid={watch_projection_month}
                        relunch={relunch}
                        isLoadingProjectionData={isLoadingProjectionData}
                    />
                </Footer>
            }
            trigger={
                <ButtonStyle
                    text
                    icon={
                        <Calculator24Regular
                            bordered
                            circular
                            size="smallest"
                        />
                    }
                    content="Calculer un solde prévisionnel"
                    onClick={() => setOpen(true)}
                />
            }
        />
    );
}

export default Projection;

const Footer = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column-reverse;
        gap: 8px;
        align-items: center;
        width: 100%;
        button {
            margin-right: 0px !important;
            width: 100%;
        }
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        button {
            margin-right: 20px;
            width: auto;
        }
    }
`;

const DropdownMonth = styled(Dropdown)`
    position: fixed;
    z-index: 5;
    @media ${DEVICE.mobileS} {
        width: 80%;
    }
    @media ${DEVICE.laptop} {
        width: 700px;
    }
`;

const DialogProjection = styled(Dialog)`
    gap: 10px;
    overflow-y: hidden;

    @media ${DEVICE.mobileS} {
        width: 90%;
    }
    @media ${DEVICE.laptop} {
        width: 804px;
    }
`;

export const ProjectionAction = ({
    projection_data,
    onclick_lunch,
    onclick_relunch,
    cancel_dialog,
    first_step_valid,
    relunch,
    isLoadingProjectionData,
}) => {
    return (
        <Footer hAlign="end" gap="gap.small">
            <Button content="Fermer" onClick={cancel_dialog} />
            <Button
                loading={isLoadingProjectionData}
                primary
                disabled={!first_step_valid ? true : false}
                content={
                    projection_data ? " Relancer un calcul" : "Lancer le calcul"
                }
                onClick={projection_data ? onclick_relunch : onclick_lunch}
            />
        </Footer>
    );
};

export const ProjectionDetail = ({ projectionData }) => {
    return (
        <Flex column gap="gap.large">
            <MessageInformation>
                <MITitle>Méthode de calcul</MITitle>
                <p>
                    Le résultat affiché est votre{" "}
                    <b className="primary">solde calculé</b>
                </p>
                <Second>
                    <b className="primary">Solde calculé</b> = les jours acquis
                    à la date choisie - ( les absences validées<sup>1</sup> &
                    les absences en attente
                    <sup>2</sup> )
                </Second>
                <br />
                <List>
                    <li>
                        les absences validées (passées, en cours ou à venir)
                        n’apparaissent pas
                    </li>
                    <li>
                        <span className="danger">
                            les absences en attente de validation{" "}
                        </span>
                        sont en dessous; elles sont soustraites du{" "}
                        <b className="primary">solde calculé</b>
                    </li>
                </List>
            </MessageInformation>
            <Flex
                hAlign="start"
                style={{
                    marginTop: "10px",
                    maxWidth: "100%",
                    flexWrap: "wrap",
                }}
            >
                {projectionData?.balances?.map((el, index) => {
                    return (
                        <Card column gap="gap.small" key={"card_" + index}>
                            <Flex gap="gap.small" vAlign="center" column>
                                <Title content={el?.value} />
                                <Description content={el?.leave_name} />
                                {el?.pending_value && (
                                    <span
                                        style={{
                                            color: "var(--color-danger)",
                                        }}
                                    >
                                        {`${el?.pending_value}j en attente`}
                                    </span>
                                )}
                            </Flex>
                        </Card>
                    );
                })}
            </Flex>
        </Flex>
    );
};

const Second = styled.p`
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
`;

const MITitle = styled(Text)`
    color: #424242;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;

const List = styled.ol`
    counter-reset: li;
    li {
        list-style: none;
    }
    li::before {
        counter-increment: li;
        content: counter(li) ": ";
    }
    li {
        color: #616161;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
`;

const MessageInformation = styled(Flex)`
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    background: #e8ebfa;
    padding: 12px 16px;
    .primary {
        color: var(--color-primary);
        font-weight: bold;
    }
    .danger {
        color: #cc4a31;
    }
`;

const Description = styled(Text)`
    color: #424242;
    width: 120px;

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    color: var(--color-primary);
`;

const Card = styled(Flex)`
    border-left: 2px solid #f0f0f0;
    padding: 8px 4px 8px 16px;
    align-items: stretch;
    height: fit-content;
    margin: 8px 16px 8px 0px;
`;

export const SCHEMA = yup.object({
    projectionMonth: yup.object().required("this is required"),
});

const ButtonStyle = styled(Button)`
    color: var(--light-theme-rest-foreground-brand-foreground, #5b5fc7);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
`;

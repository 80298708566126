import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE } from "../../constants";

const RowTotal = ({ total, currency }) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    return (
        <Flex
            // styles={{ paddingInline: "26px" }}
            fill
            vAlign="center"
            space="between"
        >
            <TotalText content={t("report.total.amount.text")} />
            <Total content={total + " " + currency} />
        </Flex>
    );
};

export default RowTotal;

const TotalText = styled(Text)`
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #242424;
`;

const Total = styled(TotalText)``;

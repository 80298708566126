import React from "react";
import { Divider, Flex } from "@fluentui/react-northstar";
import { Title } from "./index.style";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../../constants";
import SlotTimeInput from "../../SlotTimeInput";
import AddSlot from "../../AddSlot";
import { ACTION_TYPES_SLOTS } from "../../../reducers/reducer.slots";
import { extendMoment } from "moment-range";
import moment from "moment";
import SlotLeave from "../../SlotLeave";
import SlotTimeInputWeekType from "../../SlotTimeInputWeekType";

const Content = ({ shifts, dispatch }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const extend_moment = extendMoment(moment);

    // remove the BREAK slot
    const shifts_worked = shifts?.filter((shift) => shift?.type?.value !== -1);

    const is_start_after_end = (shift) => {
        const moment_start = moment(shift?.start_time, "HH:mm:ss");
        const moment_end = moment(shift?.end_time, "HH:mm:ss");

        const isStartAfterEnd =
            shift.end_time !== "00:00:00"
                ? moment_start.isAfter(moment_end) ||
                  moment_start.format("HH:mm:ss") ===
                      moment_end.format("HH:mm:ss")
                : false;

        if (isStartAfterEnd) {
            return true;
        }
    };

    const isIntersect = (new_shifts, shift, index) => {
        // verifier les deux field sur la meme ligne.
        const isStartAfterEnd = is_start_after_end(shift);
        if (isStartAfterEnd) {
            return true;
        }
        // verifier que cette ligne n'est pas intersect avec les autres champs.
        const isNewRangeInterfering = isNewRangeBetweenSlots({
            new_range: {
                start: shift.start_time,
                end: shift.end_time,
            },
            index,
            shifts: new_shifts,
        });
        return isNewRangeInterfering;
    };

    const _onChangeSlotField = ({ id, index, value, type }) => {
        // update directly, set new values
        const new_shifts = shifts_worked.map((shift, i) => {
            if (id === shift?.id) {
                return {
                    ...shift,
                    start_time: type === "field_1" ? value : shift?.start_time,
                    end_time: type === "field_2" ? value : shift?.end_time,
                };
            } else {
                return shift;
            }
        });

        console.log({ new_shifts });

        // parcourir les new_shifts pour comparer chaque ligne au autres lignes
        const shifts_with_errors = new_shifts.map((shift, j) => {
            const is_collision = isIntersect(new_shifts, shift, j);

            return {
                ...shift,
                errors: {
                    f1: is_collision,
                    f2: is_collision,
                },
            };
        });

        dispatch({
            type: ACTION_TYPES_SLOTS.set_shifts,
            payload: {
                shifts: shifts_with_errors,
            },
        });
    };

    const isNewRangeBetweenSlots = ({ new_range, index, shifts }) => {
        const collisions = [];
        for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i];
            const start = shift.start_time;
            const end = shift.end_time;

            if ((start !== "00:00:00" || end !== "00:00:00") && i !== index) {
                const new_start = new_range.start;
                const new_end = new_range.end;

                const range1 = extend_moment.range(
                    "2023-01-02T" + new_start,
                    "2023-01-02T" + new_end
                );
                const range2 = extend_moment.range(
                    "2023-01-02T" + start,
                    "2023-01-02T" + end
                );

                const isIntersect =
                    range1.overlaps(range2, {
                        adjacent: false,
                    }) ||
                    range2.overlaps(range1, {
                        adjacent: false,
                    });

                if (isIntersect) {
                    collisions.push(i);
                }
            }
        }
        return collisions.length !== 0;
    };

    return (
        <Flex column gap="gap.large">
            <Flex styles={{ marginTop: "16px" }}>
                <Title
                    content={t(
                        "v2.home.dialogs.contents.titles.slots_of_the_day"
                    )}
                />
            </Flex>
            {/* SLOTS */}
            <Flex gap="gap.medium" column styles={{ marginBottom: "16px" }}>
                {shifts_worked?.map((shift, index) => {
                    const isLeave = shift?.type?.value === 1;
                    const shift_id = shift?.id;
                    if (isLeave) {
                        return (
                            <SlotLeave
                                start_time={shift.start_time}
                                end_time={shift.end_time}
                            />
                        );
                    }
                    return (
                        <>
                            <SlotTimeInputWeekType
                                key={shift_id}
                                index={index}
                                shift_id={shift_id}
                                start_time={shift.start_time}
                                end_time={shift.end_time}
                                onDelete={() => {
                                    return dispatch({
                                        type: ACTION_TYPES_SLOTS.delete_slot,
                                        payload: {
                                            id: shift_id,
                                        },
                                    });
                                }}
                                onChangeSlot={_onChangeSlotField}
                                error_start_time={shift?.errors?.f1}
                                error_end_time={shift?.errors?.f2}
                            />
                            <Divider />
                        </>
                    );
                })}
                <AddSlot
                    onClick={() => {
                        return dispatch({
                            type: ACTION_TYPES_SLOTS.add_new_slot,
                        });
                    }}
                    disabled={shifts_worked?.length >= 4}
                />
            </Flex>
        </Flex>
    );
};

export default Content;

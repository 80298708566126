import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/auth/PrivateRoute";
import WelcomePreonboarding from "./features/pre_onboarding/components/WelcomePreonboarding";
import NotFound from "./pages/404";
import GeneralDetailTask from "./pages/DashboardTasks/Pages/GeneralDetailTask";
import Login from "./pages/Login";

const AppPreOnboarding = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* EMPLOYEE FOLDER */}
                <Route
                    path={"/welcome-preonboarding"}
                    element={
                        <PrivateRoute>
                            <WelcomePreonboarding />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/dashboard/tasks/:id_task/user/:id_user/detail"
                    element={
                        // <PrivateRoute>
                        <GeneralDetailTask />
                        // </PrivateRoute>
                    }
                />
                <Route path="/login" element={<Login />} />

                <Route
                    path="*"
                    element={
                        <NotFound
                            hide_drawer={true}
                            homeLink="/welcome-preonboarding"
                        />
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default AppPreOnboarding;

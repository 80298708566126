export const _get_str_status = (id) => {
    switch (id) {
        case 1:
            return "On-boarding";
        case 2:
            return "Suivi";
        case 3:
            return "Off-boarding";
        default:
            return "";
    }
};

import React, { useState } from "react";
import {
    Button,
    CloseIcon,
    Dialog,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import SuperviseContent from "./SuperviseContent";

const Supervise = ({ header = "", users }) => {
    const [open, setOpen] = useState(false);
    const _openDialog = () => {
        setOpen(true);
    };
    const _closeDialog = () => {
        setOpen(false);
    };
    return (
        <Dialog
            open={open}
            onCancel={_closeDialog}
            header={header}
            headerAction={{
                icon: <CloseIcon />,
                onClick: _closeDialog,
            }}
            footer={{
                children: () => {
                    return (
                        <Flex hAlign="center" fill>
                            <Button
                                onClick={_closeDialog}
                                flat
                                primary
                                content="Fermer"
                            />
                        </Flex>
                    );
                },
            }}
            content={<SuperviseContent users={users} />}
            trigger={
                <TextLink
                    content="Voir tous les profils"
                    onClick={_openDialog}
                />
            }
            styles={{
                width: "390px",
            }}
        />
    );
};

export default Supervise;

const TextLink = styled(Text)`
    color: #6264a7;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
`;

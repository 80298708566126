import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Flex,
    Input,
    InputLabel,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    Ball,
    ErrorMessage,
    InputStyle,
    MuiPhoneNumberStyle,
    Number,
    TextPrimary,
} from "../styles";
import { I18N_NS_ADD_EMPLOYEE } from "../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { SCHEMA } from "../constants/validators";
import { useMutation, useQuery } from "react-query";
import { createUser, modifyCollaborator } from "../../../api/user";
import {
    f_resetStepperContext,
    useSetResources,
    useSetStepContract,
    useSetStepPersonalInformations,
    useSetStepRole,
    useStepper,
} from "../stores";
import { Routes, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { DialogCancelConfirmation } from "../../../components/Dialogs/DialogCancelConfirmation";
import useToast from "../../../hooks/useToast";
import Toast from "../../../components/Alerts";
import { useAuthDetailStore } from "../../../stores/useAuthStore";
import useMutationDeleteEmployee from "../hooks/useMutationDeleteEmployee";
import { _api_get_user_onboarding } from "../api";
import moment from "moment";
import { getDaynameByIndex } from "../utils";
import { ROLES_CODE } from "../../../constants/roles";
import { get_url_depend_refer } from "../../../utils/functions";

const StepGeneralInformation = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    const [refresh, setRefresh] = useState(null);

    const auth_detail_store = useAuthDetailStore((state) => state.detail);

    const { isLoading: is_mutate_user, mutate: mutate_delete_user } =
        useMutationDeleteEmployee();

    const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);

    const [configToast, setConfigToast] = useToast();

    const store_set_step_informations = useSetStepPersonalInformations(); // pour la 1ere etape - info perso
    const context_set_contract = useSetStepContract(); // pour l'etape contract*
    const context_set_role = useSetStepRole(); // pour la section ROLE
    const context_set_resources = useSetResources(); //

    const context_step_informations = useStepper(
        (state) => state.step_personal_informations
    );

    // get data from URL,
    // si y'a un parametre t=update_onboarding alors qlq veut modifier un collaborateur en Onboarding
    const [params] = useSearchParams();
    // je veux avoir les params en String pour les envoyer dans le navigate plus tard pour les autres pages
    const paramsString = Array.from(params.entries())
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
    const IS_UPDATE_USER_ONBOARDING =
        params.get("t") === "update_onboarding" && params.get("id") !== null;

    const is_refer_onboarding = params.get("refer") === "onboarding";

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        control,
        setValue,
        trigger,
        watch,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(SCHEMA),
        defaultValues: {
            last_name: context_step_informations?.last_name ?? "",
            used_name: context_step_informations?.used_name ?? "",
            email: context_step_informations?.email ?? "",
            name: context_step_informations?.name ?? "",
            telephone: context_step_informations?.telephone ?? "",
        },
    });

    const {
        data: info_user_onboarding,
        isLoading: is_loading_data_user_onboarding,
    } = useQuery(
        ["get::user_onboarding"],
        () => {
            return _api_get_user_onboarding({
                id_pending_user: params.get("id"),
            });
        },
        {
            enabled: IS_UPDATE_USER_ONBOARDING,
            onSuccess: (data) => {
                const response = data?.user;
                if (data?.success) {
                    // ici, si tout va bien, on save les données en context zustand
                    store_set_step_informations({
                        email: response?.email,
                        last_name: response?.first_name,
                        name: response?.birth_name,
                        telephone: response?.personal_phone_number,
                        used_name: response?.last_name,
                        id_user: response?.id,
                    });

                    setValue("last_name", response?.first_name);
                    setValue("used_name", response?.last_name);
                    setValue("email", response?.email);
                    setValue("name", response?.birth_name);
                    setValue("telephone", response?.personal_phone_number);
                    trigger();

                    // pour l'étape contract.
                    const planning_format = response?.timetable?.map(
                        (time, index) => {
                            return {
                                id: index,
                                day_name: getDaynameByIndex(index),
                                slots: {
                                    morning: time?.am,
                                    afternoon: time?.pm,
                                },
                            };
                        }
                    );
                    // utiliser le meme JSON que dans le zustand.
                    const json_contract = {
                        date_depart: response?.departure_date,
                        date_arrivee: response?.arrival_date,
                        poste: response?.job_title,
                        trial_period:
                            response?.contract?.probationary_period_end_date,
                        agencies: {
                            id: response?.agency?.id,
                            content: response?.agency?.name,
                        },
                        services: {
                            id: response?.department?.id,
                            content: response?.department?.name,
                        },
                        managers: {
                            key: response?.manager?.email,
                            header:
                                response?.manager?.first_name +
                                " " +
                                response?.manager?.last_name,
                            image: response?.manager?.avatar,
                            content: "",
                            email: response?.manager?.email,
                        },
                        type_contrat: {
                            id: response?.contract?.type?.id,
                            content: response?.contract?.type?.name,
                        },
                        policies: {
                            id: response?.leave_policy?.id,
                            content: response?.leave_policy?.name,
                        },
                        planning: planning_format,
                    };
                    // TODO: demander a patrick pk y a pas des détails dans le contrat
                    context_set_contract(json_contract);
                    // maintenant remplir les datas de la step ROLE
                    const role_format = {
                        [ROLES_CODE.employee.toLowerCase()]: true,
                        [ROLES_CODE.hr.toLowerCase()]: response?.roles?.some(
                            (role) => role.code === ROLES_CODE.hr
                        ),
                        [ROLES_CODE.manager.toLowerCase()]:
                            response?.roles?.some(
                                (role) => role.code === ROLES_CODE.manager
                            ),
                    };
                    context_set_role(role_format);
                    // STEP RESOURCES
                    const resources_format =
                        response?.ressources_per_category?.map((res) => {
                            const is_some_sub_category_are_checked =
                                res.resources?.some((res) => res.is_assigned);
                            const is_every_sub_category_are_checked =
                                res.resources?.every((res) => res.is_assigned);
                            return {
                                ...res,
                                checked: is_some_sub_category_are_checked
                                    ? "mixed"
                                    : is_every_sub_category_are_checked
                                    ? true
                                    : false,
                                resources: res.resources.filter(
                                    (resource) => resource?.is_assigned
                                ),
                            };
                        });
                    context_set_resources(resources_format);
                    setRefresh(new Date().getTime());
                } else {
                    console.error(
                        "Error contactez Yanis le magicien 🪄🪄🪄♠️♦️🃏",
                        data
                    );
                }
            },
        }
    );

    /**
     * query add new employee
     */
    const { mutate: add_new_employee, isLoading } = useMutation(
        "validate_step_one",
        (data) => {
            return createUser(data);
        }
    );
    // update the employee
    const { mutate: update_employee, isLoading: updating_employee } =
        useMutation("revalidate_step_one", (data) => {
            return modifyCollaborator(data);
        });

    const onSubmit = (values) => {
        const { email, last_name, name, telephone, used_name } = values;
        const context_step_personal_informations =
            useStepper.getState().step_personal_informations;
        if (!context_step_personal_informations) {
            add_new_employee(
                {
                    first_name: last_name, // prenom
                    last_name: used_name, // nom usage
                    birth_name: name, // nom
                    email,
                    phone_number: telephone,
                },
                {
                    onSuccess: (data) => {
                        if (data?.success) {
                            store_set_step_informations({
                                email,
                                last_name,
                                name,
                                telephone,
                                used_name,
                                id_user: data?.id,
                            });
                            navigate(
                                `${ROUTES.employee_add}/second${
                                    paramsString ? "?" + paramsString : ""
                                }`
                            );
                        } else {
                            setConfigToast((state) => {
                                return {
                                    ...state,
                                    visible: true,
                                    level: "danger",
                                    content: data?.message,
                                };
                            });
                        }
                    },
                }
            );
        } else {
            update_employee(
                {
                    first_name: last_name, // prenom
                    last_name: used_name, // nom usage
                    birth_name: name, // nom
                    email,
                    phone_number: telephone,
                },
                {
                    onSuccess: (data) => {
                        if (data?.success) {
                            store_set_step_informations({
                                email,
                                last_name,
                                name,
                                telephone,
                                used_name,
                                id_user: data?.user?.id,
                            });
                            navigate(
                                `${ROUTES.employee_add}/second${
                                    paramsString ? "?" + paramsString : ""
                                }`
                            );
                        } else {
                            setConfigToast((state) => {
                                return {
                                    ...state,
                                    visible: true,
                                    level: "danger",
                                    content: data?.message,
                                };
                            });
                        }
                    },
                }
            );
        }
        return;
    };

    return (
        <>
            <Toast
                {...configToast}
                onStatusChange={() =>
                    setConfigToast({
                        visible: false,
                        content: "",
                        level: "danger",
                    })
                }
            />
            <Flex vAlign="center" gap="gap.small">
                <Ball
                    active={"true"}
                    already={"true"}
                    vAlign="center"
                    hAlign="center"
                >
                    <Number active={"true"} already={"true"}>
                        1
                    </Number>
                </Ball>
                <TextPrimary>
                    {t("pages.add_employee.step_info.title")}
                </TextPrimary>
                <Text styles={{ color: "red" }}>*</Text>
            </Flex>
            {/* FORMS INFORMATION GENERALE */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flex gap="gap.medium" styles={{ marginTop: "21px" }} column>
                    <Box>
                        <Controller
                            control={control}
                            name="last_name"
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <InputStyle
                                        label={t(
                                            "pages.add_employee.step_info.form.labels.last_name"
                                        )}
                                        defaultValue={
                                            context_step_informations?.last_name ??
                                            ""
                                        }
                                        value={value}
                                        onChange={onChange}
                                        type="text"
                                        inverted
                                        fluid
                                        // {...register("last_name")}
                                        error={errors?.last_name ? true : false}
                                    />
                                );
                            }}
                        />

                        {errors?.last_name && (
                            <ErrorMessage
                                content={t(errors.last_name.message)}
                            />
                        )}
                    </Box>
                    <Box>
                        <Controller
                            control={control}
                            name="name"
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <InputStyle
                                    defaultValue={
                                        context_step_informations?.name ?? ""
                                    }
                                    inverted
                                    fluid
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={(e) => {
                                        setValue("used_name", e.target.value);
                                        trigger("used_name");
                                        return;
                                    }}
                                    label={t(
                                        "pages.add_employee.step_info.form.labels.name"
                                    )}
                                    error={errors?.name ? true : false}
                                />
                            )}
                        />

                        {errors?.name && (
                            <ErrorMessage content={t(errors.name.message)} />
                        )}
                    </Box>

                    <Box>
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <InputStyle
                                    label={t(
                                        "pages.add_employee.step_info.form.labels.used_name"
                                    )}
                                    type="text"
                                    inverted
                                    fluid
                                    error={errors?.used_name ? true : false}
                                    {...field}
                                />
                            )}
                            name="used_name"
                        />
                        {errors?.used_name && (
                            <ErrorMessage
                                content={t(errors.used_name.message)}
                            />
                        )}
                    </Box>
                    <Box>
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <InputStyle
                                        label={t(
                                            "pages.add_employee.step_info.form.labels.email"
                                        )}
                                        type="email"
                                        inverted
                                        fluid
                                        value={value}
                                        defaultValue={
                                            context_step_informations?.email ??
                                            ""
                                        }
                                        onChange={onChange}
                                        error={errors?.email ? true : false}
                                    />
                                );
                            }}
                        />
                        {errors?.email && (
                            <ErrorMessage content={t(errors.email.message)} />
                        )}
                    </Box>
                    <Box>
                        <InputLabel
                            content={t(
                                "pages.add_employee.step_info.form.labels.telephone"
                            )}
                        />
                        <Controller
                            control={control}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <MuiPhoneNumberStyle
                                    value={context_step_informations?.telephone}
                                    defaultCountry={
                                        auth_detail_store?.agency
                                            ?.country_code ?? "fr"
                                    }
                                    disableAreaCodes={true}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    name="telephone"
                                    error={errors?.telephone ? true : false}
                                />
                            )}
                            name="telephone"
                        />
                        {errors?.telephone && (
                            <ErrorMessage
                                content={t(errors.telephone.message)}
                            />
                        )}
                    </Box>
                    <Flex
                        fill
                        space="between"
                        styles={{
                            marginTop: "160px",
                        }}
                    >
                        {/* cacher ça lorsqu'on est entrain de modifier un profil collab onboarding */}
                        {/* et afficher a la place un autre bouton retour en onboarding */}
                        <Flex>
                            {!IS_UPDATE_USER_ONBOARDING && (
                                <DialogCancelConfirmation
                                    open={openDialogConfirmation}
                                    cancelButton={{
                                        content: "Annuler",
                                        flat: true,
                                    }}
                                    confirmButton={{
                                        content:
                                            "Annuler l’ajout d’un collaborateur",
                                        flat: true,
                                        isLoading: is_mutate_user,
                                        styles: {
                                            backgroundColor:
                                                "var(--color-danger)",
                                        },
                                    }}
                                    content={
                                        "Vous allez perdre toutes les données saisies sur l’ajout du collaborateur."
                                    }
                                    trigger={
                                        <Button
                                            content={{
                                                content: "Annuler l'ajout",
                                                styles: {
                                                    color: "var(--color-danger)",
                                                    textDecoration: "underline",
                                                },
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setOpenDialogConfirmation(true);
                                            }}
                                            text
                                        />
                                    }
                                    header="Êtes-vous sûr(e) d'annuler l’ajout d’un collaborateur ?"
                                    onClose={() =>
                                        setOpenDialogConfirmation(false)
                                    }
                                    onConfirm={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        // reset the context and other data.
                                        const id_user =
                                            context_step_informations?.id_user;
                                        if (id_user) {
                                            mutate_delete_user(
                                                {
                                                    id_user:
                                                        context_step_informations?.id_user,
                                                },
                                                {
                                                    onSuccess: (data) => {
                                                        if (data?.success) {
                                                            f_resetStepperContext();
                                                            setOpenDialogConfirmation(
                                                                false
                                                            );
                                                            navigate(
                                                                is_refer_onboarding
                                                                    ? ROUTES
                                                                          .onboarding
                                                                          .home
                                                                    : "/dashboard"
                                                            );
                                                            return;
                                                        } else {
                                                            console.log(
                                                                "Une erreur est survenue !"
                                                            );
                                                        }
                                                    },
                                                    onError: (err) => {
                                                        return console.log({
                                                            err,
                                                        });
                                                    },
                                                }
                                            );
                                        } else {
                                            setOpenDialogConfirmation(false);
                                            f_resetStepperContext();
                                            navigate(
                                                is_refer_onboarding
                                                    ? ROUTES.onboarding.home
                                                    : "/dashboard"
                                            );
                                        }
                                        return;
                                    }}
                                />
                            )}
                            {IS_UPDATE_USER_ONBOARDING && (
                                <Button
                                    content={{
                                        content: "Retour à l'onboarding",
                                        styles: {
                                            color: "var(--color-danger)",
                                            textDecoration: "underline",
                                        },
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        navigate(ROUTES.onboarding.home);
                                    }}
                                    text
                                />
                            )}
                        </Flex>
                        <Flex gap="gap.small">
                            <Button
                                content="Revenir à l'étape précédente"
                                secondary
                                flat
                                disabled
                            />
                            <Button
                                content="Passer à l'étape suivante"
                                disabled={!isValid}
                                loading={isLoading}
                                primary
                                flat
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </form>
        </>
    );
};

export default StepGeneralInformation;

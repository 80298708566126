import React from "react";
import { Avatar, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { LEVELS_BACKEND } from "../../components/Badges/Status";
import { I18N_NS_EXPENSE } from "../../constants";
import { Trans, useTranslation } from "react-i18next";

// "created",
// LEVELS.approuve,
// LEVELS.pending,
// LEVELS.recognised,
// LEVELS.refuse,

const ChatMessage = ({
    avatar,
    full_name,
    date,
    status,
    comment,
    amount_approved,
    currency,
}) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    const FIXED_SENTENCES = [
        "/",
        <Text>
            <Trans t={t} i18nKey={"report_detail.messages.approbation"}>
                <strong>Approbation</strong> de la note pour un montant de
            </Trans>
        </Text>,
        <Text>
            <Trans t={t} i18nKey={"report_detail.messages.sent"}>
                <strong>Envoi</strong> de la note pour approbation
            </Trans>
        </Text>,
        <Text>
            <Trans t={t} i18nKey={"report_detail.messages.validation"}>
                <strong>Validation</strong> de la note
            </Trans>
        </Text>,
        <Text>
            <Trans t={t} i18nKey={"report_detail.messages.refused"}>
                <strong>Refus</strong> de la note
            </Trans>
        </Text>,
    ];

    const _get_sentence_depending_status = ({ status }) => {
        return FIXED_SENTENCES[status];
    };

    const sentence = _get_sentence_depending_status({
        status,
    });

    return (
        <Flex gap="gap.small">
            <div>
                <Avatar size="small" image={avatar} name={full_name} />
            </div>
            <Message fill>
                <MessageText
                    content={
                        <>
                            <Text content={date + " : "} />
                            <Trans t={t} i18nKey={sentence}>
                                {sentence}{" "}
                            </Trans>
                            {amount_approved && (
                                <i>{amount_approved + " " + currency}</i>
                            )}
                            {comment && (
                                <Flex styles={{ marginTop: "15px" }}>
                                    <Text>
                                        <u>
                                            {t(
                                                "forms.classic.fields.comment.label"
                                            )}
                                        </u>{" "}
                                        : {comment}
                                    </Text>
                                </Flex>
                            )}
                        </>
                    }
                />
            </Message>
        </Flex>
    );
};

export default ChatMessage;

export const MessageText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

export const Message = styled(Flex)`
    background: #f0f0f0;
    padding: 6px 12px;
    border-radius: 2px;
`;

import React, { useReducer, useState } from "react";
import {
    Button,
    ChevronEndMediumIcon,
    ChevronStartIcon,
    Flex,
    Input,
    SearchIcon,
    Text,
} from "@fluentui/react-northstar";
import moment from "moment";
import { useQuery } from "react-query";

import { Container, ContainerFluid } from "../../common/styles";
import { _str_capitalize } from "../../utils/functions";
import { _api_get_timeline } from "../../features/leave/api";
import { getISOWeeksAndDays } from "./functions";
import RowsIsoWeekNumber from "./components/RowsIsoWeekNumber";
import {
    CalendarTimeline,
    CellTitle,
    LeftColumn,
    LeftColumnCell,
    TextMonth,
    WeeksHeader,
} from "./styles";
import RowsDepartmentsUsers from "./components/RowsDepartmentsUsers";
import RowsDaysName from "./components/RowsDaysName";
import RowsFillTimelineWithData from "./components/RowsFillTimelineWithData";
import { useTranslation } from "react-i18next";
import { I18N_NS_LEAVE } from "../../features/leave/constants";
import { debounce } from "../../utils/global";
import DialogSoldeEmployee from "./components/Dialogs/DialogSoldeEmployee";
import SkeletonUsers from "./components/Skeletons/SkeletonUsers";
import SkeletonRows from "./components/Skeletons/SkeletonRows";
import Toast from "../Alerts";
import styled from "styled-components/macro";
import { DEVICE } from "../../constants/responsive";

const INIT_USER_DATA = {
    id: null,
    name: null,
    avatar: null,
};

const REDUCER_ACTIONS = {
    increment: "INCREMENT_MONTH",
    decrement: "DECREMENT_MONTH",
    search_employee_or_teams: "SEARCH_EMPLOYEE_OR_TEAMS",
    show_dialog_employee: "SHOW_DIALOG_EMPLOYEE",
    goto_today: "GOTO_TODAY",
};

const initialCalendar = {
    year: moment().get("year"),
    month: moment().get("month"),
    month_name: _str_capitalize(moment().format("MMMM")),
    search: "",
    show_dialog_employee: {
        user: INIT_USER_DATA,
        visible: false,
    },
};

const reducer = (state, action) => {
    switch (action.type) {
        case REDUCER_ACTIONS.increment: {
            const moment_next_month = moment()
                .set("year", state.year)
                .set("months", state.month + 1);

            const next_month = moment_next_month.get("month");
            const next_year = moment_next_month.year();

            return {
                ...state,
                month: next_month,
                year: next_year,
                month_name: _str_capitalize(moment_next_month.format("MMMM")),
            };
        }
        case REDUCER_ACTIONS.decrement: {
            const moment_next_month = moment()
                .set("year", state.year)
                .set("months", state.month - 1);

            const next_month = moment_next_month.get("month");
            const next_year = moment_next_month.year();

            return {
                ...state,
                month: next_month,
                year: next_year,
                month_name: _str_capitalize(moment_next_month.format("MMMM")),
            };
        }
        case REDUCER_ACTIONS.search_employee_or_teams: {
            return { ...state, search: action.payload };
        }
        case REDUCER_ACTIONS.show_dialog_employee: {
            return {
                ...state,
                show_dialog_employee: {
                    visible: action.payload.visible,
                    user: {
                        id: action.payload.user.id,
                        name: action.payload.user.name,
                        avatar: action.payload.user.avatar,
                    },
                },
            };
        }
        case REDUCER_ACTIONS.goto_today: {
            const today_year = moment().year();
            const today_month = moment().get("month");
            return {
                ...state,
                month: today_month,
                year: today_year,
                month_name: _str_capitalize(moment().format("MMMM")),
            };
        }

        default:
            return state;
    }
};

const Timeline = ({ isOnManagerPage = false, isOnHrPage = false }) => {
    const { t } = useTranslation(I18N_NS_LEAVE);

    const [calendar, dispatch] = useReducer(reducer, initialCalendar);

    const [rowsData, setRowsData] = useState([]);
    const [configToast, setConfigToast] = useState({
        visible: false,
        level: "success",
        content: "",
    });

    const { data: timeline, isLoading: isLoadingTimeline } = useQuery(
        ["timeline::get", [calendar.month + 1, calendar.year, calendar.search]],
        () =>
            _api_get_timeline({
                ...calendar,
                role: isOnManagerPage ? "manager" : "hr",
            }),
        {
            refetchOnWindowFocus: false,
            onSuccess: (response) => {
                // format rowsData
                const departments = response.departments;
                const formated_rows = [];

                for (let i = 0; i < departments?.length; i++) {
                    const department = departments[i];
                    // insert empty elements
                    formated_rows.push({
                        rowsCounter: null,
                        collapsed: null,
                        department_id: department.id,
                        events: [],
                        users: null,
                    });
                    // insert the right data
                    formated_rows.push({
                        rowsCounter: department.users_count,
                        collapsed: false,
                        department_id: department.id,
                        events: department.users.map((user) => user.leaves),
                        users: department.users.map((user) => {
                            return {
                                user: {
                                    id: user.id,
                                    name: user.name,
                                    avatar: user.avatar,
                                },
                                leaves: user.leaves,
                            };
                        }),
                    });
                }
                setRowsData(formated_rows);
            },
        }
    );

    const onChangeSearchInput = debounce((value) => {
        return dispatch({
            type: REDUCER_ACTIONS.search_employee_or_teams,
            payload: value,
        });
    }, 400);

    const hideOrShowModalEmployeeSolde = ({ visible, user }) => {
        return dispatch({
            type: REDUCER_ACTIONS.show_dialog_employee,
            payload: {
                visible,
                user,
            },
        });
    };

    const collapseDepartment = (department_id) => {
        return setRowsData((old_state) => {
            const new_state = old_state?.map((row) => {
                return {
                    ...row,
                    collapsed:
                        department_id === row.department_id
                            ? !row.collapsed
                            : row.collapsed,
                };
            });
            return new_state;
        });
    };

    let { isoWeeksAndDays, last_day, weekends } = getISOWeeksAndDays(
        calendar.year,
        calendar.month
    );

    const column_length = last_day;

    return (
        <ContainerFluid>
            <ContainerStyle
                column
                styles={{
                    width: "1335px",
                    paddingTop: "26px",
                    marginTop: "24px !important",
                }}
            >
                <Toast
                    onStatusChange={() =>
                        setConfigToast({
                            visible: false,
                            content: "",
                            level: "warning",
                        })
                    }
                    level={configToast.level}
                    content={configToast.content}
                    visible={configToast.visible}
                />
                <Flex
                    styles={{
                        padding: "px 18px",
                    }}
                >
                    {/* solde de congé d'un employee */}
                    <DialogSoldeEmployee
                        open={calendar.show_dialog_employee.visible}
                        id_employee={calendar.show_dialog_employee.user.id}
                        user={calendar.show_dialog_employee.user}
                        is_manager={isOnManagerPage}
                        onCancelDialog={() =>
                            hideOrShowModalEmployeeSolde({
                                visible: false,
                                user: INIT_USER_DATA,
                            })
                        }
                    />
                    <WrapperSearchInput>
                        <Input
                            icon={<SearchIcon />}
                            placeholder={t(
                                "timeline.search_employee.placeholder"
                            )}
                            onChange={(e, i) => onChangeSearchInput(i?.value)}
                            defaultValue={calendar.search}
                            fluid
                        />
                    </WrapperSearchInput>
                    <Flex fill gap="gap.large" hAlign="center" vAlign="center">
                        <Flex>
                            <ButtonToday
                                content="Aujourd'hui"
                                size="medium"
                                secondary
                                flat
                                onClick={() =>
                                    dispatch({
                                        type: REDUCER_ACTIONS.goto_today,
                                    })
                                }
                            />
                        </Flex>
                        <Flex gap="gap.small">
                            <Button
                                icon={<ChevronStartIcon />}
                                iconOnly
                                flat
                                size="medium"
                                onClick={() =>
                                    dispatch({
                                        type: REDUCER_ACTIONS.decrement,
                                    })
                                }
                            />
                            <Button
                                icon={<ChevronEndMediumIcon />}
                                iconOnly
                                flat
                                size="medium"
                                onClick={() =>
                                    dispatch({
                                        type: REDUCER_ACTIONS.increment,
                                    })
                                }
                            />
                        </Flex>
                        <Flex>
                            <TextMonth
                                content={`${calendar.month_name} ${calendar.year}`}
                            />
                        </Flex>
                    </Flex>
                    <Flex fill styles={{ flex: 1 }}></Flex>
                </Flex>
                <CalendarTimeline column>
                    <WeeksHeader>
                        <LeftColumn>
                            <LeftColumnCell
                                vAlign="center"
                                hAlign="center"
                            ></LeftColumnCell>
                        </LeftColumn>
                        {/* show rows of badges */}
                        <RowsIsoWeekNumber isoWeeksAndDays={isoWeeksAndDays} />
                    </WeeksHeader>
                    {/* left column */}
                    <Flex>
                        {/* FIRST COLUMN DEPARTMENT */}
                        <LeftColumn column>
                            <LeftColumnCell vAlign="center" hAlign="center">
                                <CellTitle content={t("timeline.department")} />
                            </LeftColumnCell>
                            {/* loop here department */}
                            {isLoadingTimeline ? (
                                <SkeletonUsers />
                            ) : (
                                <RowsDepartmentsUsers
                                    timeline={timeline}
                                    onClickUser={hideOrShowModalEmployeeSolde}
                                    onCollapseDepartment={(department_id) =>
                                        collapseDepartment(department_id)
                                    }
                                />
                            )}
                        </LeftColumn>
                        {/* FIRST ROW OF WEEKS */}
                        <Flex column>
                            <RowsDaysName
                                isoWeeksAndDays={isoWeeksAndDays}
                                currentMonth={calendar.month}
                                currentYear={calendar.year}
                            />
                            {/* fill data on timeline */}
                            {isLoadingTimeline ? (
                                <SkeletonRows />
                            ) : (
                                <RowsFillTimelineWithData
                                    calendar={calendar}
                                    column_length={column_length}
                                    rowsData={rowsData}
                                    weekends={weekends}
                                    setConfigToast={setConfigToast}
                                />
                            )}
                        </Flex>
                    </Flex>
                </CalendarTimeline>
            </ContainerStyle>
        </ContainerFluid>
    );
};

export default Timeline;

const ContainerStyle = styled(Container)`
    border-top: 4px solid #9299f7;
    background: white;

    /* shadow-16 */
    box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
        0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11);

    @media ${DEVICE.mobileS} {
        overflow-x: scroll;
    }
    @media ${DEVICE.laptop} {
        overflow-x: visible;
    }
`;

const WrapperSearchInput = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        width: 290px;
        display: flex;
    }
`;

export const ButtonToday = styled(Button)`
    @media ${DEVICE.mobileS} {
        min-width: 62px;
        padding: 0px;
        width: 62px;
        height: 32px;
        span {
            font-size: 10px;
            font-weight: 400;
        }
    }
    @media ${DEVICE.laptop} {
        span {
            font-size: 14px;
            font-weight: 600;
        }
        padding: 0px 20px;
        width: fit-content;
        height: 32px;
    }
`;

import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, ErrorDescription, ErrorTitle, TextDesc } from "../style";

import Error400 from "../../../assets/illustrations/time_reporting/400-error-bad-request.svg";
import { I18N_NS_LEAVE } from "../../leave/constants";

const ValidateError = () => {
    const { t } = useTranslation(I18N_NS_LEAVE);
    return (
        <Container
            vAlign="center"
            hAlign="center"
            column
            gap="gap.small"
            styles={{ zIndex: 3 }}
        >
            <Image
                src={Error400}
                alt=""
                style={{ height: "250px", marginBottom: "30px" }}
            />
            <ErrorTitle
                content={t("extern_manager_validation_leave.error.title")}
            />
            <ErrorDescription
                content={
                    <Flex column>
                        <TextDesc
                            content={t(
                                "extern_manager_validation_leave.error.first_description"
                            )}
                        />
                        <TextDesc
                            content={t(
                                "extern_manager_validation_leave.error.second_description"
                            )}
                        />
                    </Flex>
                }
            />
        </Container>
    );
};

export default ValidateError;

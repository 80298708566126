import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE } from "../../constants";

const RowTotalApproved = ({ total, currency }) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    return (
        <Flex fill vAlign="center" space="between">
            <TotalTextFooter content={t("report.total.approved.text")} />
            <TotalFooter content={total + " " + currency} />
        </Flex>
    );
};

export default RowTotalApproved;

export const TotalTextFooter = styled(Text)`
    color: #237b4b;
    font-family: Segoe UI;
    font-size: clamp(14px, 2vw, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: clamp(20px, 2vw, 24px);
`;

export const TotalFooter = styled(TotalTextFooter)`
    color: #237b4b;
    font-family: Segoe UI;
    font-size: clamp(14px, 2vw, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: clamp(20px, 2vw, 24px);
`;

import React from "react";
import styled from "styled-components";
import {
    Attachment,
    Button,
    Checkbox,
    Divider,
    Dropdown,
    FilesPdfIcon,
    FilesUploadIcon,
    Flex,
    Input,
    Text,
    TextArea,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import { DEVICE } from "../../../constants/responsive";
import { Controller, useFormContext } from "react-hook-form";

import { ErrorMessage } from "../../../common/styles";
import { useQuery } from "react-query";
import {
    getTaskCategory,
    getTaskResponsable,
    getTaskResponsables,
    getTaskValidators,
} from "../../../api/tasks";
import {
    BASE_URL_ILLUSTRATIONS,
    I18N_NS_TRANSLATION,
} from "../../../constants";
import { useState } from "react";
import { TASK_ACTIONS } from "../../../constants/tasks";
import { file_size_format } from "../../../constants/maths";
import { uploadedFile } from "../../../utils/global";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { DIALOG_ADD_TASK_RULES } from "../../../features/addEmployee/constants/validators";
import SliderTabs from "../../../components/shared/SliderTabs";
import { useBenefeciaryStore } from "./store/index.store";
import { DropdownStyle } from "./DialogAddIndividualTaskContent";

const DialogUpdateManualTaskConfirmationTemplate = () => {
    const { t } = useTranslation(I18N_NS_TRANSLATION);
    const refButtonUpload = useRef(null);
    const benefeciary = useBenefeciaryStore((state) => state.benefeciary);

    const {
        register,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        getValues,
        watch,
    } = useFormContext(); // retrieve all hook methods

    const [taskRequiredValidation, setTaskRequiredValidation] = useState(
        getValues("task_to_validate")
    );

    const [sliderTab, setSliderTab] = useState(watch("type") - 1);

    const [refresh, setRefresh] = useState(null);

    // get all categories
    const { data: dataCategory = [], isLoading: isLoadingCategory } = useQuery(
        ["getCategory"],
        () => getTaskCategory(),
        {
            refetchOnWindowFocus: false,
        }
    );

    // get all collaborateur users.
    let { data: dataCollaborator = [], isLoading: isLoadingCollaborator } =
        useQuery(["getCollaborator"], () => getTaskResponsables(), {
            refetchOnWindowFocus: false,
        });

    // get all validators users.
    let { data: dataValidators = [], isLoading: isLoadingValidators } =
        useQuery(
            ["getValidatorsModifyModel"],
            () =>
                getTaskValidators({
                    id_user: benefeciary?.id,
                }),
            {
                refetchOnWindowFocus: false,
            }
        );

    if (isLoadingCategory || isLoadingCollaborator || isLoadingValidators)
        return <p>Loading data ...</p>;

    const watch_assigned_to = watch("assigned_to");
    const watch_who_validate = watch("who_validate");

    const default_assigned_to_id = watch_assigned_to?.id;
    const default_validator_id = watch_who_validate?.id;

    // format the data of the collaborator for fluentui
    dataValidators = dataValidators.map((el) => {
        return {
            ...el,
            id: el?.id,
            header: el.name,
            image: el.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: el.job_title,
            selected: default_validator_id === el?.id,
            username: el?.username,
        };
    });

    dataCollaborator = dataCollaborator.map((el) => {
        return {
            ...el,
            id: el?.id,
            header: el.name,
            image: el.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: el.job_title,
            selected: default_assigned_to_id === el?.id,
            username: el?.username,
        };
    });

    const default_assigned_to = dataCollaborator?.find(
        (el) => el?.id === getValues("assigned_to")?.id
    );

    const default_validator = dataValidators?.find(
        (el) => el?.id === watch_who_validate?.id
    );

    const watch_document_action = watch("document_action");

    const default_document_action =
        TASK_ACTIONS[
            watch_document_action === -1 ? 4 : watch_document_action - 1
        ];

    return (
        <Flex column gap="gap.medium">
            <SliderTabs
                tabs={["onboarding", "suivi", "offboarding"]}
                // value={sliderTab}
                onChange={(value) => {
                    setSliderTab(value);
                    setValue("type", parseInt(value + 1));
                }}
                // defaultActiveIndex={getValues("type") - 1}
                defaultActiveIndex={sliderTab}
            />
            <Flex.Item>
                <Text>
                    Pour ajouter une tâche, remplissez les informations
                    suivantes :
                </Text>
            </Flex.Item>
            <Flex>
                <TextTitle>Consignes</TextTitle>
            </Flex>
            <Flex column>
                <FlexDescription>
                    <Flex fill column>
                        <Input
                            {...register("name", DIALOG_ADD_TASK_RULES.title)}
                            label="Titre de la tâche"
                            placeholder="Donnez un titre évocateur"
                            required
                            error={errors?.name ? true : false}
                            defaultValue={getValues("name")}
                            styles={{ marginBottom: "2px" }}
                            fluid
                        />
                        {errors?.name && (
                            <ErrorMessage content={t(errors?.name?.message)} />
                        )}
                    </Flex>
                    <Flex
                        fill
                        vAlign="center"
                        styles={{ alignSelf: "center" }}
                        column
                    >
                        <Label>Catégorie de la tâche*</Label>
                        <Controller
                            control={control}
                            rules={DIALOG_ADD_TASK_RULES.category}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                    invalid,
                                    isTouched,
                                    isDirty,
                                    error,
                                },
                                formState,
                            }) => (
                                <DropdownStyle
                                    items={dataCategory?.map((el) => el?.name)}
                                    placeholder="Catégorie de la tâche"
                                    defaultValue={
                                        dataCategory?.find(
                                            (el) =>
                                                el.id === getValues("category")
                                        )?.name
                                    }
                                    checkable
                                    getA11ySelectionMessage={{
                                        onAdd: (item) =>
                                            `${item} has been selected.`,
                                    }}
                                    fluid
                                    onBlur={onBlur} // notify when input is touched
                                    onChange={(_, option) =>
                                        onChange(
                                            dataCategory[
                                                option.highlightedIndex
                                            ].id
                                        )
                                    } // send value to hook form
                                    checked={value}
                                    error={errors?.category ? true : false}
                                    ref={ref}
                                    styles={{
                                        width: "100%",
                                    }}
                                />
                            )}
                            name="category"
                        />
                        {errors?.category && (
                            <ErrorMessage
                                content={t(errors?.category.message)}
                            />
                        )}
                    </Flex>
                </FlexDescription>
            </Flex>
            <Flex gap="gap.large" vAlign="end">
                <Flex.Item>
                    <Flex column fill>
                        <Input.Label>Descriptif de la tâche*</Input.Label>
                        <TextArea
                            label="Descriptif de la tâche"
                            placeholder="Ajouter des indications ici"
                            fluid
                            {...register(
                                "description",
                                DIALOG_ADD_TASK_RULES.description
                            )}
                            error={errors?.description ? true : false}
                            defaultValue={getValues("description")}
                        />
                        {errors?.description && (
                            <ErrorMessage
                                content={t(errors?.description.message)}
                            />
                        )}
                    </Flex>
                </Flex.Item>
            </Flex>
            <Divider />
            <Flex>
                <TextTitle>Document (facultatif)</TextTitle>
            </Flex>
            <Flex column gap="gap.small">
                <Input.Label>Action collaborateur sur un document</Input.Label>
                <Controller
                    control={control}
                    defaultValue={default_document_action}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                    }) => (
                        <DropdownStyle
                            items={TASK_ACTIONS}
                            placeholder="Action à réaliser"
                            defaultValue={default_document_action}
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onChange={(_, i) => {
                                const {
                                    hr_document_required,
                                    id: document_action,
                                } = i?.value;

                                setValue(
                                    "document_required",
                                    hr_document_required
                                );
                                setValue("document_action", document_action);
                                setValue("saved_files", []);
                                clearErrors("document_action");
                                // onChange(document_action);
                                setRefresh(new Date().getTime());
                                return;
                            }}
                            ref={ref}
                            onBlur={onBlur}
                            error={errors?.document_action ? true : false}
                            checkable
                        />
                    )}
                    name="document_required"
                />
                {errors?.document_action && (
                    <ErrorMessage
                        content={t(errors?.document_action.message)}
                    />
                )}
                {default_document_action?.hr_document_required &&
                    getValues("saved_files").length === 0 && (
                        <div>
                            <Button
                                type="file"
                                icon={<FilesUploadIcon size="small" />}
                                content="Ajouter la pièce-jointe"
                                iconPosition="before"
                                onClick={() => refButtonUpload.current.click()}
                                tinted
                            />
                            <Input
                                hidden
                                ref={refButtonUpload}
                                type="file"
                                icon={<FilesUploadIcon size="small" />}
                                content="Ajouter la pièce-jointe"
                                iconPosition="before"
                                onChange={(e) =>
                                    uploadedFile(e.target.files, (args) => {
                                        setValue("saved_files", args);
                                        setValue("template_doc_ids", []); // reset to [] when a new document was uploaded !
                                        clearErrors("document_action");

                                        setRefresh(new Date().getTime());
                                        return;
                                    })
                                }
                            />
                        </div>
                    )}
                {/* view the document */}
                {getValues("saved_files")?.map((el) => {
                    return (
                        <Attachment
                            icon={<FilesPdfIcon />}
                            header={el.filename || el.name}
                            description={file_size_format(el.filesize)}
                            actionable
                            action={{
                                icon: <TrashCanIcon />,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    setValue("saved_files", []);
                                    setRefresh(new Date().getTime());
                                },
                                title: "Remove",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(
                                    el?.attached_doc || el?.uploaded_doc,
                                    "_blank"
                                );
                            }}
                            style={{ maxWidth: "100%" }}
                        />
                    );
                })}
            </Flex>
            <Divider />
            <Flex>
                <TextTitle>Dates*</TextTitle>
            </Flex>
            <Flex column>
                <Input
                    {...register("due_date", {
                        required: {
                            value: true,
                            message: "ce champ est obligatoire",
                        },
                    })}
                    type="date"
                    label="Date d'échéance"
                    placeholder="due_date"
                    error={errors?.due_date ? true : false}
                    min={new Date().toJSON().split("T")[0]}
                    defaultValue={getValues("due_date")}
                    required
                />
                {errors?.due_date && (
                    <ErrorMessage content={t(errors?.due_date.message)} />
                )}
            </Flex>
            <Divider />
            <Flex>
                <TextTitle>Collaborateurs concernés</TextTitle>
            </Flex>
            <WrapperConcernetEmployee vAlign="start" gap="gap.medium">
                <Flex column fill>
                    <Controller
                        control={control}
                        rules={DIALOG_ADD_TASK_RULES.assigned_to}
                        defaultValue={default_assigned_to}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => (
                            <DropdownStyle
                                search
                                defaultSearchQuery={default_assigned_to?.header}
                                items={dataCollaborator}
                                placeholder="Qui va effectuer la tâche ?"
                                checkable
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                defaultValue={default_assigned_to}
                                onChange={(_, option) => {
                                    onChange(option.value);
                                }}
                                onBlur={onBlur}
                                name={name}
                                error={errors?.assigned_to ? true : false}
                                styles={{
                                    width: "100%",
                                }}
                                fluid
                                ref={ref}
                            />
                        )}
                        name="assigned_to"
                    />
                    {errors?.assigned_to && (
                        <ErrorMessage
                            content={t(errors?.assigned_to.message)}
                        />
                    )}
                </Flex>
                <FlexFor>
                    <Text>pour</Text>
                </FlexFor>
                <Flex column fill>
                    <Controller
                        control={control}
                        rules={DIALOG_ADD_TASK_RULES.related_to}
                        defaultValue={default_assigned_to}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                search
                                defaultSearchQuery={default_assigned_to?.header}
                                items={dataCollaborator}
                                placeholder="Personne impactée"
                                checkable
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                fluid
                                onChange={(_, option) => {
                                    onChange(
                                        option && option.value
                                            ? option.value.username
                                            : ""
                                    );
                                }}
                                defaultValue={default_assigned_to}
                                onBlur={onBlur}
                                name={name}
                                ref={ref}
                                error={errors?.related_to ? true : false}
                            />
                        )}
                        name="related_to"
                    />
                    {errors?.related_to && (
                        <ErrorMessage content={t(errors?.related_to.message)} />
                    )}
                </Flex>
            </WrapperConcernetEmployee>
            <Flex>
                <Controller
                    control={control}
                    defaultValue={getValues("task_to_validate")}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <Checkbox
                            label="Tâche à faire valider"
                            onChange={(e, { checked }) => {
                                setTaskRequiredValidation(checked);
                                setValue("task_to_validate", checked);
                                // onChange(checked);
                            }}
                            toggle
                            onBlur={onBlur}
                            name={name}
                            ref={ref}
                            defaultChecked={getValues("task_to_validate")}
                        />
                    )}
                    name="task_to_validate"
                />
            </Flex>
            {taskRequiredValidation && (
                <Flex column>
                    <Controller
                        control={control}
                        rules={DIALOG_ADD_TASK_RULES.who_validate}
                        defaultValue={default_validator}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                items={dataValidators}
                                defaultValue={default_validator}
                                placeholder="Qui va valider la tâche ?"
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                onChange={(_, i) => onChange(i.value)}
                                onBlur={onBlur}
                                name={name}
                                ref={ref}
                                checkable
                                error={errors?.who_validate ? true : false}
                            />
                        )}
                        name="who_validate"
                    />
                    {errors?.who_validate && (
                        <ErrorMessage
                            content={t(errors?.who_validate.message)}
                        />
                    )}
                </Flex>
            )}
        </Flex>
    );
};

export default DialogUpdateManualTaskConfirmationTemplate;

const Label = styled(Text)`
    -webkit-text-size-adjust: 100%;
    font-size: 0.875rem;
    text-align: left;
    color: rgb(17, 16, 15);
    unicode-bidi: isolate;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    display: block;
    transition: all 0.2s ease 0s;
    line-height: 1rem;
    margin-bottom: 0.25rem;
`;

const FlexFor = styled(Flex)`
    @media ${DEVICE.mobileS} {
        margin-top: 0px;
        margin-block: 8px;
    }
    @media ${DEVICE.tablet} {
        margin-top: 6px;
        margin-block: 0px;
    }
    @media ${DEVICE.laptop} {
        margin-top: 6px;
        margin-block: 0px;
    }
`;

const WrapperConcernetEmployee = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
        align-items: center;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const FlexDescription = styled(Flex)`
    gap: 16px;
    align-items: flex-start;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const TextTitle = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
`;

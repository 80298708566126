import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    Flex,
    Text,
    Input,
    Dropdown,
    Checkbox,
    Divider,
    TextArea,
    Button,
    FilesUploadIcon,
    FlexItem,
    Attachment,
    FilesPdfIcon,
    TrashCanIcon,
    InputLabel,
} from "@fluentui/react-northstar";
import { useRef } from "react";
import { useQuery } from "react-query";

import { DEVICE } from "../../../../../constants/responsive";

import {
    getTaskCategory,
    getTaskResponsable,
    _upload_file,
    getTaskResponsables,
    getTaskValidators,
} from "../../../../../api/tasks";

import SliderTabs from "../../../../../components/shared/SliderTabs";
import { file_size_format } from "../../../../../constants/maths";
import { TASK_ACTIONS } from "../../../../../constants/tasks";
import { FILE_SIZE_LIMIT } from "../../../../../features/documents/constants";
import { BASE_URL_ILLUSTRATIONS } from "../../../../../constants";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "../../../styles";
import { DIALOG_ADD_TASK_RULES } from "../../../constants/validators";
import { useTranslation } from "react-i18next";
import { I18N_NS_ADD_EMPLOYEE } from "../../../constants";
import {
    RequiredStar,
    TitleGroup,
} from "../../../../../pages/DashboardTasks/TasksModels/style";
import { useStepper } from "../../../stores";

export const uploadedFile = async (files, cb) => {
    if (!files?.length) {
        return console.log("ya aucun fichier !!");
    }

    // limit the import to 5 files at once.
    if (files.length > 1) {
        alert("Vous avez dépasser la limite autorisée ( 1 fichier )");
        return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > FILE_SIZE_LIMIT) {
            console.log(
                "'" + file.name + "' à été retirer a cause de sa taille !"
            );
            return false;
        }
        formData.append("uploaded_files", file);
    }
    const savedFiles = await _upload_file(formData);
    cb(savedFiles.files); // save the values on the useForm
};

const DialogAddTask = ({ generatedId }) => {
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    const refButtonUpload = useRef(null);
    const {
        email: emailCurrentUser,
        last_name,
        name,
    } = useStepper((state) => state.step_personal_informations);
    const {
        register,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        getValues,
    } = useFormContext(); // retrieve all hook methods

    const [taskRequiredValidation, setTaskRequiredValidation] = useState(false);
    const [sliderTab, setSliderTab] = useState(0);
    const [refresh, setRefresh] = useState(null);

    // get all categories
    const { data: dataCategory = [] } = useQuery(["getCategory"], () =>
        getTaskCategory()
    );

    // get all collaborateur users.
    let { data: dataCollaborator = [] } = useQuery(["getCollaborator"], () =>
        getTaskResponsables()
    );

    // get all validators users.
    let { data: dataValidators = [], isLoading: isLoadingValidators } =
        useQuery(["getValidatorsAddTaskForEmployee"], () =>
            getTaskValidators()
        );

    // format the data of the collaborator for fluentui
    dataCollaborator = dataCollaborator.map((el) => {
        return {
            header: el.name,
            email: el?.username,
            image: el.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: el.job_title,
        };
    });

    dataValidators = dataValidators.map((el) => {
        return {
            header: el.name,
            email: el?.username,
            image: el.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: el.job_title,
        };
    });
    const nameCurrentUser = `${last_name} ${name}`;

    // format the data and add to it the current user :D
    let dataCollaboratorWithCurrentUser = [...dataCollaborator];
    dataCollaboratorWithCurrentUser = [
        {
            header: nameCurrentUser,
            email: emailCurrentUser,
            image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: t("pages.add_employee.step_tasks.shared.current_user"),
        },
        ...dataCollaboratorWithCurrentUser,
    ];

    return (
        <Flex column gap="gap.medium">
            <FlexItemSlider>
                <SliderTabs
                    tabs={["onboarding", "suivi", "offboarding"]}
                    onChange={(value) => {
                        setSliderTab(value);
                        setValue("type", parseInt(value + 1));
                    }}
                    defaultActiveIndex={sliderTab}
                />
            </FlexItemSlider>
            <Flex.Item>
                <Text>
                    Pour ajouter une tâche, remplissez les informations
                    suivantes :
                </Text>
            </Flex.Item>
            <Flex>
                <TitleGroup content={`1 - Informations sur la tâche`} />
                <RequiredStar />
            </Flex>
            <Flex column gap="gap.small">
                <Flex>
                    <Input
                        type="text"
                        name="id"
                        {...register("id", { value: generatedId })}
                        hidden
                    />
                </Flex>
                <Flex column>
                    <Input
                        {...register("name", DIALOG_ADD_TASK_RULES.title)}
                        label="Titre de la tâche"
                        placeholder="Donnez un titre évocateur"
                        error={errors?.name ? true : false}
                        styles={{ marginBottom: "2px" }}
                        fluid
                    />
                    {errors?.name && (
                        <ErrorMessage content={t(errors?.name?.message)} />
                    )}
                </Flex>
                <Flex column>
                    <Label>Catégorie de la tâche</Label>
                    <Controller
                        control={control}
                        rules={DIALOG_ADD_TASK_RULES.category}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                items={dataCategory?.map((el) => el?.name)}
                                placeholder="Catégorie de la tâche"
                                checkable
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                onBlur={onBlur} // notify when input is touched
                                onChange={(_, option) =>
                                    onChange(
                                        dataCategory[option.highlightedIndex].id
                                    )
                                } // send value to hook form
                                checked={value}
                                error={errors?.category ? true : false}
                                ref={ref}
                                styles={{
                                    width: "100%",
                                }}
                            />
                        )}
                        name="category"
                    />
                    {errors?.category && (
                        <ErrorMessage content={t(errors?.category.message)} />
                    )}
                </Flex>
            </Flex>
            <Flex column>
                <Input.Label>Consignes pour accomplir la tâche</Input.Label>
                <TextArea
                    label="Consignes pour accomplir la tâche"
                    placeholder="Ajouter des indications ici"
                    fluid
                    {...register(
                        "description",
                        DIALOG_ADD_TASK_RULES.description
                    )}
                    error={errors?.description ? true : false}
                />
                {errors?.description && (
                    <ErrorMessage content={t(errors?.description.message)} />
                )}
            </Flex>
            <Divider />
            <Flex>
                <TitleGroup
                    content={`2 - Que doit faire le collaborateur sur un document ? (facultatif)`}
                />
            </Flex>
            <Flex column gap="gap.small">
                <Input.Label>Action demandée au collaborateur</Input.Label>
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <DropdownStyle
                            items={TASK_ACTIONS}
                            placeholder="Aucune action demandée"
                            defaultValue={TASK_ACTIONS[4].header}
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onChange={(_, i) => {
                                const {
                                    document_required,
                                    id: document_action,
                                } = i?.value;
                                setValue(
                                    "document_required",
                                    document_required
                                );
                                setValue("document_action", document_action);
                                setValue("saved_files", []);
                                clearErrors("document_action");
                                // onChange(document_action);
                                setRefresh(new Date().getTime());
                                return;
                            }}
                            ref={ref}
                            onBlur={onBlur}
                            error={errors?.document_action ? true : false}
                            checkable
                        />
                    )}
                    name="document_required"
                />
                {errors?.document_action && (
                    <ErrorMessage
                        content={t(errors?.document_action.message)}
                    />
                )}
                {getValues("document_required") &&
                    getValues("saved_files").length === 0 && (
                        <div>
                            <Button
                                type="file"
                                icon={<FilesUploadIcon size="small" />}
                                content="Ajouter la pièce-jointe"
                                iconPosition="before"
                                onClick={() => refButtonUpload.current.click()}
                                tinted
                            />
                            <Input
                                hidden
                                ref={refButtonUpload}
                                type="file"
                                icon={<FilesUploadIcon size="small" />}
                                content="Ajouter la pièce-jointe"
                                iconPosition="before"
                                onChange={(e) =>
                                    uploadedFile(e.target.files, (args) => {
                                        setValue("saved_files", args);
                                        clearErrors("document_action");
                                        setRefresh(new Date().getTime());
                                        return;
                                    })
                                }
                            />
                        </div>
                    )}
                {/* view the document */}
                {getValues("saved_files")?.map((el) => {
                    return (
                        <Attachment
                            icon={<FilesPdfIcon />}
                            header={el.filename || el.name}
                            description={file_size_format(el.filesize)}
                            actionable
                            action={{
                                icon: <TrashCanIcon />,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    setValue("saved_files", []);
                                    setRefresh(new Date().getTime());
                                },
                                title: "Remove",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(el?.uploaded_doc);
                            }}
                            style={{ maxWidth: "100%" }}
                        />
                    );
                })}
            </Flex>
            <Divider />
            <Flex>
                <TitleGroup content={`3 - Date planifiée`} />
                <RequiredStar />
            </Flex>
            <FlexDates>
                <Flex column>
                    <Input
                        {...register(
                            "due_date",
                            DIALOG_ADD_TASK_RULES.due_date
                        )}
                        type="date"
                        label="Date d'échéance"
                        placeholder="due_date"
                        error={errors?.due_date ? true : false}
                        min={new Date().toJSON().split("T")[0]}
                    />
                    {errors?.due_date && (
                        <ErrorMessage content={t(errors?.due_date.message)} />
                    )}
                </Flex>
            </FlexDates>
            <Divider />
            <Flex>
                <TitleGroup content={`4 - Personnes concernées`} />
                <RequiredStar />
            </Flex>
            <FlexConcerne vAlign="start">
                <Flex column fill>
                    <InputLabel content="Qui va effectuer la tâche ?" />
                    <Controller
                        control={control}
                        rules={DIALOG_ADD_TASK_RULES.assigned_to}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                items={dataCollaboratorWithCurrentUser}
                                placeholder="Qui va effectuer la tâche ?"
                                checkable
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                onChange={(_, option) => {
                                    onChange(option.value);
                                }}
                                onBlur={onBlur}
                                name={name}
                                error={errors?.assigned_to ? true : false}
                                styles={{
                                    width: "100%",
                                }}
                            />
                        )}
                        name="assigned_to"
                    />
                    {errors?.assigned_to && (
                        <ErrorMessage
                            content={t(errors?.assigned_to.message)}
                        />
                    )}
                </Flex>
                <Flex column fill>
                    <InputLabel content="À qui cette tâche va profiter ?" />
                    <Controller
                        control={control}
                        rules={DIALOG_ADD_TASK_RULES.related_to}
                        defaultValue={{
                            header: nameCurrentUser,
                            email: emailCurrentUser,
                            image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
                            content: t(
                                "pages.add_employee.step_tasks.shared.current_user"
                            ),
                            selected: true,
                        }}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                items={[
                                    {
                                        header: nameCurrentUser,
                                        email: emailCurrentUser,
                                        image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
                                        content: t(
                                            "pages.add_employee.step_tasks.shared.current_user"
                                        ),
                                        selected: true,
                                    },
                                ]}
                                defaultValue={{
                                    header: nameCurrentUser,
                                    image: `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
                                    email: emailCurrentUser,
                                    content: t(
                                        "pages.add_employee.step_tasks.shared.current_user"
                                    ),
                                    selected: true,
                                }}
                                placeholder="Personne impactée"
                                checkable
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                onChange={(_, option) => {
                                    onChange(option.value);
                                }}
                                onBlur={onBlur}
                                name={name}
                                ref={ref}
                                error={errors?.related_to ? true : false}
                            />
                        )}
                        name="related_to"
                    />
                    {errors?.related_to && (
                        <ErrorMessage content={t(errors?.related_to.message)} />
                    )}
                </Flex>
            </FlexConcerne>
            <Flex>
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <Checkbox
                            label="Tâche à faire valider"
                            onChange={(e, { checked }) => {
                                setTaskRequiredValidation(checked);
                                onChange(checked);
                            }}
                            toggle
                            onBlur={onBlur}
                            name={name}
                            ref={ref}
                        />
                    )}
                    name="task_to_validate"
                />
            </Flex>
            {taskRequiredValidation && (
                <Flex column>
                    <InputLabel content="Qui va valider la réalisation de la tâche ?" />
                    <Controller
                        rules={DIALOG_ADD_TASK_RULES.who_validate}
                        control={control}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                items={dataValidators}
                                placeholder="Qui va valider la tâche ?"
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                onChange={(_, i) => onChange(i.value)}
                                onBlur={onBlur}
                                name={name}
                                ref={ref}
                                checkable
                                error={errors?.who_validate ? true : false}
                            />
                        )}
                        name="who_validate"
                    />
                    {errors?.who_validate && (
                        <ErrorMessage
                            content={t(errors?.who_validate.message)}
                        />
                    )}
                </Flex>
            )}
        </Flex>
    );
};

export default DialogAddTask;

const FlexDropzoneMobile = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: flex;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
`;
const FlexDropzone = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: flex;
    }
`;

const Label = styled(Text)`
    -webkit-text-size-adjust: 100%;
    font-size: 0.875rem;
    text-align: left;
    color: rgb(17, 16, 15);
    unicode-bidi: isolate;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;

    display: block;
    transition: all 0.2s ease 0s;
    line-height: 1rem;
    margin-bottom: 0.25rem;
`;

const DropdownStyle = styled(Dropdown)`
    @media ${DEVICE.mobileS} {
        width: 100%;
    }
    @media ${DEVICE.tablet} {
        width: auto;
    }
    @media ${DEVICE.laptop} {
        width: 100%;
        img {
            width: 34px;
            height: 34px;
        }
    }
`;

const FlexConcerne = styled(Flex)`
    /* align-items: center; */
    gap: 16px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        ${DropdownStyle} {
            width: 100%;
        }
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: column;
    }
`;

const FlexDates = styled(Flex)`
    gap: 16px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const FlexItemSlider = styled(FlexItem)`
    @media ${DEVICE.mobileS} {
        margin-top: 16px;
    }
    @media ${DEVICE.tablet} {
        margin-top: 0px;
    }
    @media ${DEVICE.laptop} {
        margin-top: 0px;
    }
`;

const FlexDescription = styled(Flex)`
    gap: 16px;
    align-items: flex-start;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const TextTitle = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
`;

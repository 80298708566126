import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE } from "../../constants";
import moment from "moment";

export const LEVELS = {
    refuse: "refuse",
    approuve: "approuve",
    pending: "pending",
    recognised: "recognised",
    to_validate: "to_validate",
};

export const LEVELS_BACKEND = [
    "created",
    LEVELS.approuve,
    LEVELS.pending,
    LEVELS.recognised,
    LEVELS.refuse,
];

const THEME_LEVELS_COLORS = {
    [LEVELS.approuve]: {
        borderColor: "#BDDA9B",
        backgroundColor: "#E7F2DA",
        textColor: "#13A10E",
        text: "home.badge_status.approuve",
    },
    [LEVELS.pending]: {
        borderColor: "#EDC2A7",
        backgroundColor: "#FBF6D9",
        textColor: "#CC4A31",
        text: "home.badge_status.pending",
    },
    [LEVELS.recognised]: {
        borderColor: " #B842AA",
        backgroundColor: "#E1BEDD",
        textColor: "#B4009E",
        text: "home.badge_status.recognised",
    },
    [LEVELS.refuse]: {
        borderColor: "#F3D6D8",
        backgroundColor: "#FCF4F6",
        textColor: "#C4314B",
        text: "home.badge_status.refuse",
    },
    [LEVELS.to_validate]: {
        borderColor: "#d1d1d1",
        backgroundColor: "#EBEBEB",
        textColor: "#424242",
        text: "À valider",
    },
};

export const get_theme_levels_color = (level) => {
    return THEME_LEVELS_COLORS[level];
};

const Status = ({ date = "", level, content = null }) => {
    const config = get_theme_levels_color(level);
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const isPending = level === LEVELS.pending;
    const formated_date = moment(date).format("DD-MM-YYYY");
    return (
        <Container {...config}>
            <Text {...config}>
                {content !== "" && content !== null && content !== undefined
                    ? content
                    : t(config?.text)}
                {isPending ? null : ": " + formated_date}
            </Text>
        </Container>
    );
};

export default Status;

Status.propTypes = {
    /** show this date */
    date: PropTypes.string,
    /** choose level, for theming, use GLOBAL: **LEVELS** object */
    level: PropTypes.oneOf([
        LEVELS.approuve,
        LEVELS.refuse,
        LEVELS.pending,
        LEVELS.recognised,
        LEVELS.to_validate,
    ]),
};

const Container = styled(Flex)`
    border-radius: 4px;
    width: fit-content;
    height: fit-content;
    border: 1px solid ${({ borderColor }) => borderColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ textColor }) => textColor};
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 8px;
`;

import React, { useState } from "react";
import { Button, Dialog } from "@fluentui/react-northstar";
import DialogCreateExpenseContent from "./DialogCreateExpenseContent";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { _api_add_note } from "../../../api";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../constants/routes";
import { I18N_NS_EXPENSE } from "../../../constants";
import { _str_capitalize } from "../../../../../utils/functions";
import moment from "moment";

const DialogCreateExpense = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    const [dialogShowToggle, setDialogShowToggle] = useState(false);
    const navigate = useNavigate();
    const default_title_note = _str_capitalize(moment().format("MMMM YYYY"));
    const { mutate: mutate_add_note, isLoading: is_loading_adding_note } =
        useMutation(
            (data) => {
                // API function
                return _api_add_note({
                    name: data?.title,
                    reinvoiceable: data?.rebillable,
                    client_id: data?.client?.id,
                });
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        const route_detail = ROUTES.expenses.detail;
                        return navigate(
                            `${route_detail.custom_route(
                                route_detail.route,
                                data?.expense_report?.id
                            )}`
                        );
                    } else {
                        alert("Error when create the Expense Report !");
                    }
                },
            }
        );

    const onSubmit = (data) => {
        mutate_add_note(data);
        return;
    };

    const methods = useForm({
        mode: "onSubmit",
        defaultValues: {
            title: default_title_note,
            rebillable: false,
            client: null,
        },
    });

    const onCancel = () => {
        methods.reset();
        hideDialog();
        return;
    };

    const onConfirm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        methods.handleSubmit(onSubmit)();
        return;
    };

    const openDialog = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return setDialogShowToggle(true);
    };

    const hideDialog = () => {
        return setDialogShowToggle(false);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Dialog
                    open={dialogShowToggle}
                    header={t("dialogs.dialog_create_report.title")}
                    confirmButton={t(
                        "dialogs.dialog_create_report.confirm_button"
                    )}
                    cancelButton={t(
                        "dialogs.dialog_create_report.cancel_button"
                    )}
                    content={<DialogCreateExpenseContent />}
                    trigger={
                        <Button
                            loader={is_loading_adding_note}
                            content={t("home.button_text")}
                            onClick={openDialog}
                            primary
                            flat
                        />
                    }
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />
            </form>
        </FormProvider>
    );
};

export default DialogCreateExpense;

import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const ContainerFluid = styled(Flex)`
    width: 100%;
    /* flex: 1; */
`;

export const Container = styled(Flex)`
    max-width: 1440px;
    margin: 0 auto;
`;

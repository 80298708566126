import React, { useState, useRef } from "react";
import {
    Button,
    CloseIcon,
    Dialog,
    Flex,
    Input,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE } from "../../../../constants";
import { useMutation } from "react-query";
import {
    _api_refuse_expense,
    _api_refuse_expense_accounting_manager,
} from "../../../../api";

const RefuseExpense = ({
    isMobile,
    id_report,
    id_user,
    onAccept = () => {},
    isAccountingManager = false,
}) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const commentRef = useRef(null);
    const [open, setOpen] = useState(false);

    const _openDialog = () => {
        return setOpen(true);
    };

    const _closeDialog = () => {
        return setOpen(false);
    };

    const _confirmRefuseExpense = () => {
        const comment = commentRef.current.value;
        if (isAccountingManager) {
            mutate_refuse_accounting_manager({
                id_expense_report: id_report,
                comment,
            });
        } else {
            mutate({
                id_user,
                id_expense_report: id_report,
                comment,
            });
        }
        return;
    };

    const { mutate, isLoading } = useMutation(
        (data) => _api_refuse_expense(data),
        {
            onSuccess: (data) => {
                if (data?.success) {
                    return onAccept([data, null]);
                } else {
                    return onAccept([null, data?.message]);
                }
            },
        }
    );

    const {
        mutate: mutate_refuse_accounting_manager,
        isLoading: isLoadingRefuseAccountingManager,
    } = useMutation((data) => _api_refuse_expense_accounting_manager(data), {
        onSuccess: (data) => {
            if (data?.success) {
                return onAccept([data, null]);
            } else {
                return onAccept([null, data?.message]);
            }
        },
    });

    return (
        <Dialog
            open={open}
            cancelButton={t("common.cancel")}
            confirmButton={{
                loading: isLoading || isLoadingRefuseAccountingManager,
                content: t("manager.detail.dialogs.refuse.validate"),
            }}
            content={
                <Flex fill>
                    <Input
                        label={t("manager.detail.dialogs.refuse.comment")}
                        ref={commentRef}
                        fluid
                        clearable
                    />
                </Flex>
            }
            header={t("manager.detail.dialogs.refuse.title")}
            headerAction={{
                icon: <CloseIcon />,
                title: t("common.close"),
                onClick: _closeDialog,
            }}
            onCancel={_closeDialog}
            onConfirm={_confirmRefuseExpense}
            trigger={
                <Button
                    size={isMobile ? "small" : "medium"}
                    content={t("manager.detail.approbation.refuse")}
                    onClick={_openDialog}
                    secondary
                    flat
                />
            }
        />
    );
};

export default RefuseExpense;

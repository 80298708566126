import {
    AcceptIcon,
    Dialog,
    Flex,
    Image,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";

export const DialogCategoryExpense = styled(Dialog)`
    width: 674px;
`;
export const ListCategories = styled(Flex)`
    max-height: 382px;
    margin-top: 18px;
    overflow-y: scroll;
`;

export const AcceptIconStyle = styled(AcceptIcon)`
    margin-left: auto;
`;

export const CategoryContent = styled(Flex)`
    padding: 4px;
    border-radius: 3px;
    height: fit-content;
    overflow-y: scroll;
    transition: background-color 0.2s;
    :hover {
        cursor: pointer;
        background-color: #e8ebfa;
        transition: background-color 0.2s;
    }
    ${({ $selected }) => ($selected ? "background-color: #e8ebfa" : "")}
`;

export const WrapperCategory = styled(Flex)`
    padding-block: 8px;
    border-top: 1px solid #e0e0e0;
`;

export const TextCategory = styled(Text)`
    color: #242424;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;

export const ImageStyle = styled(Image)`
    width: 32px;
    height: 32px;
`;

import {
    AddIcon,
    Box,
    Button,
    CalendarIcon,
    CloseIcon,
    Dialog,
    Flex,
    Loader,
    NotepadPersonIcon,
    ShiftActivityIcon,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import FullCalendar from "@fullcalendar/react";
import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import frLocale from "@fullcalendar/core/locales/fr";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { createRef } from "react";
import { ContainerFluid } from "../../../../common/styles";
import SliderTabs from "../../../../components/shared/SliderTabs";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
    event_content_function,
    format_events,
    refetchTeamBadge,
} from "../../utils";
import {
    _api_cancel_leave,
    api_balances_detail,
    api_personal_calendar_detail,
} from "../../api";

import moment from "moment";
import CollabList from "./CollabList";
import Projection from "../balances/Projection";
import BalanceSection from "../balances/BalanceSection";

import AbsenceRequest from "./AbsenceRequest";
import DialogLeaveDetail from "../../../../components/Timeline/components/Dialogs/DialogLeaveDetail";
import Toast from "../../../../components/Alerts";
import { DEVICE, SIZES } from "../../../../constants/responsive";

const INIT_CONFIG_DATA = {
    visible: false,
    data: null,
};

const CollabCalendar = ({ holidayList, subHeader, showDialoge }) => {
    // const calendarRef = createRef();
    const calendarRef = useRef(null);

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    const isMobile = useMediaQuery({
        query: `(max-width: ${SIZES.laptop})`,
    });
    const isLargeScreen = useMediaQuery({
        query: `(min-width: 1024px)`,
    });

    const [currentMonth, setCurrentMonth] = useState({
        month: "",
        year: "",
    });

    const [configDetail, setConfigDetail] = useState(INIT_CONFIG_DATA);
    const [cancelLeavId, setCancelLeaveId] = useState(null);
    const [openDialogSoldeDeConges, setOpenDialogSoldeDeConges] =
        useState(false);
    const [showDialogAddLeave, setShowDialogAddLeave] = useState({
        visible: false,
    });
    const [toastConfig, setToastConfig] = useState({
        content: "",
        visible: false,
        level: "warning",
    });

    const [activeTab, setActiveTab] = useState(0);

    const {
        data: myCalendar,
        isLoading: loading_my_personal_detail,
        isFetching: fetching_my_personal_detail,
        refetch: refetchPersonnalCall,
    } = useQuery(
        [
            "myPersonalCalendar",
            activeTab,
            currentMonth.month,
            currentMonth.year,
        ],
        () =>
            api_personal_calendar_detail(currentMonth.month, currentMonth.year),
        {
            refetchOnMount: true,
            refetchOnWindowFocus: true,
            onSuccess: () => {},
        }
    );

    // Cancel leave request api
    const { mutate: mutate_cancel_leave, isLoading: loading_cancel_leave } =
        useMutation(
            (data) => {
                return _api_cancel_leave(data);
            },
            {
                onSuccess: () => {
                    refetchPersonnalCall();
                    return;
                },
            }
        );

    const showDialog = (e, events) => {
        const { id } = e.event;

        const current_data_event = events?.find(
            (el) => el?.id === parseInt(id)
        );
        // setCurrentEvent(current_data_event);
        setConfigDetail({ visible: true, data: current_data_event?.data });

        // setOpen(true);
    };

    // Select startDate & EndDate from the fullcalendar
    const selectableDate = (arg) => {
        const get_start_date = arg.startStr;
        const get_end_date = arg.endStr;

        return showDialoge({
            start_date: get_start_date,
            end_date: get_end_date,
        });
    };

    const format_events_full_calendar = () =>
        format_events(myCalendar?.leaves || []);

    let events_calendar = format_events_full_calendar();

    const renderDayCellContent = (info) => {
        const currentCalendarDate = moment(info?.date).format("YYYY-MM-DD");
        const isHoliday = holidayList?.find(
            (holiday) => holiday?.date === currentCalendarDate
        );

        const TodayDate =
            moment(new Date()).format("YYYY-MM-DD") === currentCalendarDate;

        if (isHoliday) {
            if (TodayDate) {
                return;
            }
            return <div className="holiday">Jour férié</div>;
        }
    };

    const _hide_modal = () => {
        setConfigDetail(INIT_CONFIG_DATA);
        return;
    };

    const cancel_leave = (leave_id) => {
        mutate_cancel_leave(leave_id, {
            onSuccess: () => {
                setToastConfig({
                    content: "Votre demande a bien été annulée",
                    visible: true,
                    level: "info",
                });
                setCancelLeaveId(leave_id);
                _hide_modal();
                return;
            },
        });
        return;
    };

    const { data, refetch: refetchSoldeConges } = useQuery(
        ["api-balances-details"],
        () => {
            return api_balances_detail();
        },
        {
            refetchOnWindowFocus: false,
        }
    );

    const DisplayDataIntoCalendar = (data) => {
        setCurrentMonth({
            month: data.view.currentStart.getMonth() + 1,
            year: data.view.currentStart.getFullYear(),
        });
    };

    const queryClient = useQueryClient();

    return (
        <ContainerFluid fill column>
            <Toast
                onStatusChange={() =>
                    setToastConfig({
                        visible: false,
                        content: "",
                        level: "warning",
                    })
                }
                level={toastConfig.level}
                content={toastConfig.content}
                visible={toastConfig.visible}
            />
            <BoxStyle gap="gap.medium" column>
                <Container gap="gap.large" column>
                    <DialogLeaveDetail
                        open={configDetail?.visible}
                        leave_details={{
                            id: configDetail?.data?.id,
                            authenticated_user_can_validate:
                                configDetail?.data
                                    ?.authenticated_user_can_validate,
                            approved_by: configDetail?.data?.approved_by,
                            requested_by: {
                                email: configDetail?.data?.requested_by?.email,
                            },
                            authenticated_user_can_delete:
                                configDetail?.data
                                    ?.authenticated_user_can_delete,
                            balance_before_leave:
                                configDetail?.data?.balance_before_leave,
                            balance_after_leave:
                                configDetail?.data?.balance_after_leave,
                            start_date: configDetail?.data?.start_date,
                            end_date: configDetail?.data?.end_date,
                            balance_count_to_remove:
                                configDetail?.data?.balance_count_to_remove,
                            comment: configDetail?.data?.comment,
                            documents: configDetail?.data?.documents,
                            type: {
                                color: configDetail?.data?.type?.color,
                                name: configDetail?.data?.type?.name,
                                has_balance:
                                    configDetail?.data?.type?.has_balance,
                            },
                            status: {
                                id: configDetail?.data?.status?.id,
                            },
                            request_can_be_canceled:
                                configDetail?.data?.request_can_be_canceled,
                            requested_on: configDetail?.data?.requested_on,
                            waiting_approval_by:
                                configDetail?.data?.waiting_approval_by,
                            last_action: {
                                date: configDetail?.data?.last_action?.date,
                                status: configDetail?.data?.last_action?.status,
                            },
                            authenticated_user_can_remind_external_manager:
                                configDetail?.data
                                    ?.authenticated_user_can_remind_external_manager,
                        }}
                        user_details={{
                            name: configDetail?.data?.user?.name,
                            id: configDetail?.data?.user?.id,
                            avatar: configDetail?.data?.user?.avatar,
                        }}
                        cbOnApproveLeave={(data) => {
                            setToastConfig({
                                visible: true,
                                content:
                                    data?.success === true
                                        ? "La demande a bien été acceptée "
                                        : data?.message,
                                level:
                                    data?.success === true
                                        ? "success"
                                        : "danger",
                            });
                            refetchTeamBadge(queryClient);
                            refetchPersonnalCall();
                            return;
                        }}
                        cbOnCancelLeave={(data) => {
                            setToastConfig({
                                visible: true,
                                content:
                                    data?.success === true
                                        ? "Votre demande a bien été annulée"
                                        : data?.message,
                                level:
                                    data?.success === true
                                        ? "success"
                                        : "danger",
                            });
                            refetchTeamBadge(queryClient);
                            refetchPersonnalCall();
                            return;
                        }}
                        cbOnDeleteLeave={(data) => {
                            setToastConfig({
                                visible: true,
                                content:
                                    data?.success === true
                                        ? "La demande a bien été supprimée"
                                        : data?.message,
                                level:
                                    data?.success === true
                                        ? "success"
                                        : "danger",
                            });
                            refetchTeamBadge(queryClient);
                            refetchPersonnalCall();
                            return;
                        }}
                        cbOnRefuseLeave={(data) => {
                            setToastConfig({
                                visible: true,
                                content:
                                    data?.success === true
                                        ? "Votre demande a bien été refusée "
                                        : data?.message,
                                level:
                                    data?.success === true
                                        ? "success"
                                        : "danger",
                            });
                            refetchTeamBadge(queryClient);
                            refetchPersonnalCall();
                            return;
                        }}
                        cbOnHideDialog={() => {
                            refetchTeamBadge(queryClient);
                            setConfigDetail(INIT_CONFIG_DATA);
                        }}
                    />
                    <Flex space="between" vAlign="center">
                        <SliderTabs
                            tabs={["Calendrier", "Liste des demandes"]}
                            defaultActiveIndex={0}
                            value={activeTab || cancelLeavId !== null}
                            onChange={(e) => {
                                setActiveTab(e);
                                // return refetchPersonnalCall();
                            }}
                        />
                        {/* New design of my absence that can hide the balance section   */}
                        {isLargeScreen && (
                            <WrapperCTA vAlign="center" gap="gap.large">
                                <Dialog trigger={<Projection />} />
                                <DialogSeeMySolde
                                    open={openDialogSoldeDeConges}
                                    onCancel={() =>
                                        setOpenDialogSoldeDeConges(false)
                                    }
                                    cancelButton={{
                                        content: "Fermer",
                                        flat: true,
                                        onClick: () =>
                                            setOpenDialogSoldeDeConges(false),
                                    }}
                                    header={"Mes soldes de congés"}
                                    content={<BalanceSection balances={data} />}
                                    trigger={
                                        <Button
                                            onClick={() => {
                                                refetchSoldeConges();
                                                setOpenDialogSoldeDeConges(
                                                    true
                                                );
                                            }}
                                            icon={<ShiftActivityIcon />}
                                            content={"Voir mon solde de congés"}
                                        />
                                    }
                                    headerAction={{
                                        icon: <CloseIcon />,
                                        title: "Close",
                                        onClick: () =>
                                            setOpenDialogSoldeDeConges(false),
                                    }}
                                />
                                <AbsenceRequest
                                    isForTeams={false}
                                    showDialogAddLeave={showDialogAddLeave}
                                    setShowDialogAddLeave={
                                        setShowDialogAddLeave
                                    }
                                    refetchPersonnalCall={refetchPersonnalCall}
                                />
                            </WrapperCTA>
                        )}
                    </Flex>
                    {!activeTab && (
                        <WrapperCalendar>
                            <FullCalendarStyle
                                plugins={[
                                    dayGridPlugin,
                                    interactionPlugin,
                                    timeGridPlugin,
                                ]}
                                headerToolbar={{
                                    left: "today,prev,next",
                                    center: "title",
                                    right: "",
                                }}
                                locale={frLocale}
                                editable={false}
                                selectable
                                select={selectableDate}
                                aspectRatio={3}
                                dayMaxEvents
                                initialView="dayGridMonth"
                                eventContent={event_content_function}
                                eventClick={(e) => {
                                    showDialog(e, events_calendar);
                                }}
                                dayCellContent={renderDayCellContent}
                                events={events_calendar}
                                ref={calendarRef}
                                firstDay={1}
                                nowIndicator={true}
                                datesSet={(arg) => {
                                    DisplayDataIntoCalendar(arg);
                                }}
                                // viewDidMount={(arg) => {
                                //     console.log(
                                //         "Initial month:",
                                //         arg.view.currentStart.getMonth() + 1
                                //     );
                                // }}
                            />
                        </WrapperCalendar>
                    )}
                    {activeTab === 1 && (
                        <CollabList
                            isMobileS={isMobileS}
                            cancelLeavId={cancelLeavId}
                            loading_cancel_leave={loading_cancel_leave}
                            subHeader={subHeader}
                            cancel_leave={cancel_leave}
                            showDialog={showDialog}
                            _hide_modal={_hide_modal}
                            configDetail={configDetail}
                            setConfigDetail={setConfigDetail}
                            myCalendar={myCalendar}
                            setCancelLeaveId={setCancelLeaveId}
                            refetchPersonnalCall={refetchPersonnalCall}
                        />
                    )}
                </Container>
                {/* action for mobile device */}
                {isMobile && (
                    <WrapperCTA__Mobile
                        hAlign="center"
                        vAlign="center"
                        gap="gap.large"
                    >
                        <Dialog
                            key="dialog_projection"
                            trigger={<Projection />}
                        />

                        <DialogSeeMySolde
                            key="dialog_see_solde"
                            open={openDialogSoldeDeConges}
                            onCancel={() => setOpenDialogSoldeDeConges(false)}
                            cancelButton={{
                                content: "Fermer",
                                flat: true,
                                onClick: () =>
                                    setOpenDialogSoldeDeConges(false),
                            }}
                            header={"Mes soldes de congés"}
                            content={<BalanceSection balances={data} />}
                            trigger={
                                <Button
                                    onClick={() => {
                                        setOpenDialogSoldeDeConges(true);
                                    }}
                                    icon={<ShiftActivityIcon />}
                                    content={"Voir mon solde de congés"}
                                />
                            }
                            headerAction={{
                                icon: <CloseIcon />,
                                title: "Close",
                                onClick: () =>
                                    setOpenDialogSoldeDeConges(false),
                            }}
                        />

                        <AbsenceRequest
                            key="leave_request"
                            isForTeams={false}
                            showDialogAddLeave={showDialogAddLeave}
                            setShowDialogAddLeave={setShowDialogAddLeave}
                            refetchPersonnalCall={refetchPersonnalCall}
                        />
                    </WrapperCTA__Mobile>
                )}
            </BoxStyle>
        </ContainerFluid>
    );
};

export default CollabCalendar;

const DialogSeeMySolde = styled(Dialog)`
    @media ${DEVICE.mobileS} {
        width: 90%;
    }
    @media ${DEVICE.laptop} {
        width: 773px;
    }
`;

const WrapperCTA__Mobile = styled(Flex)`
    padding-block: 15px;

    @media ${DEVICE.mobileS} {
        display: flex;
        flex-direction: column-reverse;
        gap: 15px;
        button {
            margin-right: 0 !important;
            min-width: 100%;
        }
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
`;

const WrapperCTA = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
    @media ${DEVICE.laptopL} {
        display: flex;
    }
    @media (width: 2561px) {
        display: flex;
    }
`;

const WrapperCalendar = styled.div`
    td {
        cursor: pointer;
    }

    .fc-daygrid-day-frame:has(.holiday) {
        background-color: #fbf6d9 !important;
    }
    border-top: 3px solid #9299f7;
    padding: 15px;
    box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
        0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11);
`;

const Container = styled(Flex)`
    padding: 15px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11),
        0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
`;

const FullCalendarStyle = styled(FullCalendar)``;

export const BoxStyle = styled(Flex)`
    /* height: calc(100% - 50px); */
    padding-bottom: 12px;
    /* aspect-ratio: 16/9; */
    .events_pending {
        .fc-event-title.fc-sticky:before {
            display: inline-block;
            content: " ";
            background-image: url("https://asenditbackenddevstorage.blob.core.windows.net/static/illustration/Clock.svg");
            background-size: 12px 12px;
            height: 12px;
            width: 12px;
            margin-right: 5px;
            position: relative;
            top: 1px;
        }
    }
    .fc-toolbar {
        text-transform: capitalize;
    }
    .fc-button-primary {
        /* background-color: #d1d1d1; */

        border-top-right-radius: 4px 4px;
        border-top-left-radius: 4px 4px;
        /* margin-left: 250px; */
    }
    .fc-button-primary:hover {
        background-color: #d1d1d1;
        color: black;
    }
    .fc .fc-button-primary:not(:disabled):active {
    }
    .fc .fc-button-primary:not(:disabled).fc-button-active {
        background-color: #5b5fc7;
        border: none;
    }
    .fc-day-today .fc-scrollgrid-sync-inner {
        background-color: white;
    }
    .fc-day-today .fc-daygrid-day-number {
        border: 1px solid white;
        border-radius: 50%;
        width: 30px;
        margin: 5px;
        background-color: #5b5fc7;
        color: white;
        text-align: center;
    }
    .fc-event-title-container {
        cursor: pointer;
        color: black;
    }

    .fc-today-button .fc-button .fc-button-primary {
        margin-left: 925px;
    }
    .fc-day-sun {
        background-color: #f0f0f0;
    }
    .fc-day-sat {
        background-color: #f0f0f0;
    }
    .fc-prev-button {
        background-color: #5b5fc7;
    }
    .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
        margin-left: 120px;
        background-color: #5b5fc7;
        border: none;
    }
    .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #ebebeb;
        color: black;
        border: white;
    }
    .fc-prev-button {
        border: 1px solid #d1d1d1 !important;
        background-color: white !important;
    }
    .fc-next-button {
        background-color: #5b5fc7;
        border: 1px solid #d1d1d1 !important;
        background-color: white !important;
    }
    .fc-toolbar-ltr {
        justify-content: center;
    }
    .fc-button-group {
        gap: 5px;
    }
    .fc-view-harness {
        background-color: white;
        border-radius: 8px;
    }

    @media ${DEVICE.mobileS} {
        div.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard
            > div.fc-view-harness.fc-view-harness-active {
            height: 415px !important;
        }
        div.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
            justify-content: flex-start;
            gap: 15px;
        }
        button.fc-prev-button.fc-button.fc-button-primary {
            margin-left: 10px;
        }
        .fc-today-button {
            background-color: white !important;
            color: black !important;
            font-weight: 600;
            cursor: pointer;
            border: 1px solid #d1d1d1 !important;
            width: 72px;
            font-size: 11px;
        }
        .fc-toolbar {
            gap: 15px;
        }
        .fc-toolbar-title {
            font-size: 12px;
            margin: 0;
        }
        .fc-prev-button {
            width: 32px;
            height: 32px;
            padding: 0px;
        }
        .fc-next-button {
            width: 32px;
            height: 32px;
            padding: 0px;
        }
        td.fc-daygrid-day {
            height: 64px;
        }
    }
    @media ${DEVICE.laptop} {
        div.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard
            > div.fc-view-harness.fc-view-harness-active {
            height: 600px !important;
        }
        div.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
            justify-content: center;
            gap: 85px;
        }
        button.fc-prev-button.fc-button.fc-button-primary {
            margin-left: 80px;
        }
        .fc-today-button {
            background-color: white !important;
            color: black !important;
            font-weight: 600;
            cursor: pointer;
            border: 1px solid #d1d1d1 !important;
            width: auto;
            font-size: inherit;
        }
        .fc-toolbar {
            gap: 54px;
        }
        .fc-toolbar-title {
            font-size: 1.75em;
            margin: 0;
        }
        .fc-prev-button {
            width: auto;
            height: auto;
            padding: 0.4em 0.65em;
        }
        .fc-next-button {
            width: auto;
            height: auto;
            padding: 0.4em 0.65em;
        }
        td.fc-daygrid-day {
            height: auto;
        }
    }
`;

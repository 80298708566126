import React, { useState } from "react";
import {
    Divider,
    Flex,
    Input,
    InputLabel,
    Text,
} from "@fluentui/react-northstar";
import {
    Amount,
    AmountExpense,
    Container,
    CustomInput,
    CustomInputAmount,
    CustomInputCurrency,
    Rate,
    RateExpenseTitle,
    WrapperAmount,
    WrapperCurrency,
} from "./style";

const DisabledAmount = ({ currency, amount }) => {
    return (
        <Flex column>
            <InputLabel content="Montant en devise locale" />
            <CustomInput>
                <WrapperCurrency vAlign="center">
                    <CustomInputCurrency content={currency} />
                </WrapperCurrency>
                <WrapperAmount vAlign="center">
                    <CustomInputAmount content={amount} />
                </WrapperAmount>
            </CustomInput>
        </Flex>
    );
};

const RateExchange = ({ default_rate, onChangeNewRate }) => {
    const [value, setValue] = useState(default_rate);
    return (
        <Flex column gap="gap.small">
            <Input
                type="tel"
                onChange={(e, i) => {
                    let value = i.value.replace(/[^0-9,.]/g, "");
                    if ((i.value.match(/[.,]/g) || []).length > 1) {
                        // Retirer le dernier point décimal ou la dernière virgule s'il y en a plus d'un
                        value = i.value.slice(0, -1);
                    }
                    onChangeNewRate(Number(value.toString().replace(",", ".")));
                    return setValue(value);
                }}
                label="Taux de change"
                clearable
                defaultValue={default_rate}
                value={value}
            />
        </Flex>
    );
};

const ModalContentRateExchange = ({
    currency,
    local_currency,
    amount_expense = 0,
    rate_exchange_reference,
    rate_currency_local,
    rate_change_input,
    onChangeNewRate,
}) => {
    return (
        <Container column gap="gap.small">
            <Flex vAlign="center" gap="gap.small">
                <AmountExpense content={"Montant de la dépense"} />
                <Amount content={`${amount_expense} ${currency}`} />
            </Flex>
            <Flex vAlign="center" gap="gap.small">
                <RateExpenseTitle content={"Taux de change de référence"} />
                <Rate content={rate_exchange_reference} />
            </Flex>
            <Divider />
            <Flex gap="gap.large" hAlign="center">
                <DisabledAmount
                    currency={local_currency}
                    amount={rate_currency_local}
                />
                <RateExchange
                    default_rate={rate_change_input}
                    onChangeNewRate={onChangeNewRate}
                />
            </Flex>
        </Container>
    );
};

export default ModalContentRateExchange;

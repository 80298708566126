import { app, authentication } from "@microsoft/teams-js";
import React, { useEffect } from "react";
import { BASE_URL_API } from "../../constants";
import { useAuthDetailStore, useAuthStore } from "../../stores/useAuthStore";
import { Flex, Image, Loader } from "@fluentui/react-northstar";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

const TeamsConnection = () => {
    //**** Implement SSO with teams ****//
    const navigate = useNavigate();
    const setUser = useAuthStore((state) => state.setUser);
    const setDetailUser = useAuthDetailStore((state) => state.setDetailUser);

    useEffect(() => {
        // Ne sera jamais appelé en dehors de Teams
        async function initSSO() {
            try {
                await app.initialize();
                const token = await authentication.getAuthToken();
                const response = await fetch(
                    BASE_URL_API + "/microsoft_sso_sign_in",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            token: token,
                        }),
                        headers: {
                            "Content-type": "application/json",
                        },
                        credentials: "include",
                    }
                );
                const data = await response.json();

                if (data?.success === true) {
                    setUser({
                        access: data.access_token,
                    });
                    setDetailUser(data.user);
                    navigate(ROUTES.home);
                } else {
                    // Erreur de consentement ou user not found
                    alert(data.message);
                    console.error("SSO initialization failed", data.message);
                    navigate(ROUTES.login + "?error=consentement");
                }
            } catch (err) {
                console.error("SSO initialization failed", err);
                return navigate(ROUTES.login);
            }
        }
        initSSO();
        //**** End - Implement SSO with teams ****//
    }, []);

    return (
        <Flex
            hAlign="center"
            vAlign="center"
            column
            gap="gap.large"
            styles={{
                height: "100dvh",
                background:
                    "var(--Light-theme-Rest-Background-Brand-Background-1, #E8EBFA)",
            }}
        >
            <Image src="/logo/Logo_yeetch.svg" width="560px" />
            <Loader size="large" />
        </Flex>
    );
};

export default TeamsConnection;

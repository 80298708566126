import React from "react";
import { useState } from "react";
import { Divider, Flex, Loader, Text } from "@fluentui/react-northstar";
import { Tab, TabList } from "@fluentui/react-tabs";
import { useMutation, useQuery } from "react-query";

import DropzoneDocument from "../dropzone";
import {
    _api_get_documents,
    _api_list_my_folders,
    _api_upload_document,
} from "../../api";
import { AllDocuments, _str_capitalize } from "../../functions";
import { Container, ContainerFluid } from "../../../../common/styles";

import styled from "styled-components";
import { CustomBadge } from "../../../../components/Badge";
import { useSearchParams } from "react-router-dom";

const Content = () => {
    const [activeTab, setActiveTab] = useState(null);
    const [params, setParams] = useSearchParams();
    const {
        data: my_folders,
        isLoading,
        refetch: refetch_my_folders,
    } = useQuery("ListMyFolders", () => _api_list_my_folders(), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (activeTab === null) {
                const default_doc = params.get("doc");
                let filter = data[0];
                if (default_doc) {
                    filter = data.find((doc) => doc.id === default_doc);
                }
                setActiveTab(filter);
            }
        },
    });

    const {
        data: all_documents,
        isLoading: isLoadingDocuments,
        refetch,
    } = useQuery(
        ["ListAllDocuments", activeTab?.id],
        ({ queryKey }) => {
            const [_, folder] = queryKey;
            return _api_get_documents(folder);
        },
        {
            enabled: activeTab !== null,
            refetchOnMount: true,
        }
    );

    const { mutate: mutate_upload_file, isLoading: isUploadingFile } =
        useMutation((data) => _api_upload_document(data), {
            onSuccess: () => {
                refetch();
                refetch_my_folders();
            },
        });

    if (isLoading || activeTab === null) {
        return <Loader />;
    }

    return (
        <ContainerFluid>
            <ContainerStyle>
                <Left column gap="gap.medium">
                    <LeftTitle>Dossiers</LeftTitle>
                    <LeftSubTitle>Personnels</LeftSubTitle>
                    <Flex column gap="gap.medium">
                        {my_folders
                            ?.filter((el) => !el?.common_tab)
                            .map((el) => (
                                <Nav
                                    vAlign="center"
                                    gap="gap.small"
                                    onClick={() => setActiveTab(el)}
                                    className={
                                        activeTab?.name === el?.name
                                            ? "active"
                                            : ""
                                    }
                                    key={el?.id}
                                >
                                    <Flex fill gap="gap.small" vAlign="center">
                                        <img src={el?.icon_url} alt="" />
                                        <NavText
                                            key={el?.id}
                                            value={el}
                                            truncated
                                        >
                                            {_str_capitalize(el?.name)}
                                        </NavText>
                                    </Flex>
                                    <Flex>
                                        {el?.new_documents_count > 0 && (
                                            <CustomBadge
                                                appearence="filled"
                                                bg="black"
                                                color="white"
                                                label={
                                                    <b>
                                                        {
                                                            el?.new_documents_count
                                                        }
                                                    </b>
                                                }
                                                size="smaller"
                                            />
                                        )}
                                    </Flex>
                                </Nav>
                            ))}
                    </Flex>
                    <Divider />
                    <LeftSubTitle>Communs</LeftSubTitle>
                    <Flex column gap="gap.medium">
                        {my_folders
                            ?.filter((el) => el?.common_tab)
                            .map((el) => (
                                <Nav
                                    vAlign="center"
                                    gap="gap.small"
                                    space="between"
                                    className={
                                        activeTab?.name === el?.name
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => setActiveTab(el)}
                                >
                                    <Flex fill vAlign="center" gap="gap.small">
                                        <img src={el?.icon_url} alt="" />
                                        <NavText
                                            key={el?.id}
                                            value={el}
                                            truncated
                                        >
                                            {_str_capitalize(el?.name)}
                                        </NavText>
                                    </Flex>
                                    <Flex>
                                        {el?.new_documents_count > 0 && (
                                            <CustomBadge
                                                appearence="filled"
                                                bg="black"
                                                color="white"
                                                label={
                                                    <b>
                                                        {
                                                            el?.new_documents_count
                                                        }
                                                    </b>
                                                }
                                                size="smaller"
                                            />
                                        )}
                                    </Flex>
                                </Nav>
                            ))}
                    </Flex>
                </Left>
                <Right column fill>
                    <Flex gap="gap.medium" fill>
                        <Flex styles={{ flex: 2 }} hAlign="center">
                            {isLoadingDocuments ? (
                                <Loader
                                    label={
                                        "Chargement des données de " +
                                        activeTab?.id
                                    }
                                />
                            ) : (
                                <AllDocuments
                                    activeTab={activeTab}
                                    all_documents={all_documents}
                                    refetch={() => {
                                        refetch();
                                        refetch_my_folders();
                                    }}
                                />
                            )}
                        </Flex>
                        <Flex styles={{ marginTop: "15px" }}>
                            <DropzoneDocument
                                isLoading={isUploadingFile}
                                folder_name={activeTab?.id}
                                update_authorized={activeTab?.update_authorized}
                                mutate_upload_file={mutate_upload_file}
                            />
                        </Flex>
                    </Flex>
                </Right>
            </ContainerStyle>
        </ContainerFluid>
    );
};

export default Content;

const Right = styled(Flex)`
    padding: 24px 30px;
`;

const NavText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    width: 90px;
`;

const Nav = styled(Flex)`
    border-radius: 4px;
    padding: 10px 14px;
    cursor: pointer;
    &:hover {
        background: #e8ebfa;
    }
    &.active {
        background: #e8ebfa;
        ${NavText} {
            font-weight: 600;
        }
    }
`;

const LeftSubTitle = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #424242;
`;

const LeftTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #424242;
`;

const Left = styled(Flex)`
    width: 215px;
    box-shadow: 25px 17px 13px -33px rgba(68, 71, 193, 1);
    border-right: 1px solid rgba(68, 71, 193, 0.1);
    padding: 25px 12px 25px 24px;
`;

const ContainerStyle = styled(Container)`
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    /* padding: 25px; */
    width: 100%;
    min-height: 500px;
`;

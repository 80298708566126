import { create } from "zustand";
import produce from "immer";

export const useStepsLogin = create((set) => ({
    INIT: true,
    CONNECT_WITH_MICROSOFT: false,
    ASK_PASSWORD: false,
    ASK_ADD_MICROSOFT_ACCOUNT: false,
    codeForMs: null,
    showInit: () => {
        set(
            produce((prevState) => {
                prevState.INIT = true;
                prevState.CONNECT_WITH_MICROSOFT = false;
                prevState.ASK_PASSWORD = false;
                prevState.ASK_ADD_MICROSOFT_ACCOUNT = false;
            })
        );
    },
    showConnectMS: () => {
        set(
            produce((prevState) => {
                prevState.INIT = false;
                prevState.CONNECT_WITH_MICROSOFT = true;
                prevState.ASK_PASSWORD = false;
                prevState.ASK_ADD_MICROSOFT_ACCOUNT = false;
            })
        );
    },
    showAskPassword: () => {
        set(
            produce((prevState) => {
                prevState.INIT = false;
                prevState.CONNECT_WITH_MICROSOFT = false;
                prevState.ASK_PASSWORD = true;
                prevState.ASK_ADD_MICROSOFT_ACCOUNT = false;
            })
        );
    },
    showAddMicrosoftAccount: (code) => {
        set(
            produce((prevState) => {
                prevState.INIT = false;
                prevState.CONNECT_WITH_MICROSOFT = false;
                prevState.ASK_PASSWORD = false;
                prevState.ASK_ADD_MICROSOFT_ACCOUNT = true;
                prevState.codeForMs = code;
            })
        );
    },
}));

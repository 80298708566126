/**
 * FOR ACCORDION TEMPLATES RESOURCES
 */
export const _checkedHeaderListTemplate = (_, i) => {
    _.stopPropagation();
    _.preventDefault();
    const { data: dataElement, checked, templates, setTemplates } = i;

    const new_state = templates?.map((el) => {
        return el?.id === dataElement?.id
            ? {
                  ...el,
                  checked: checked,
                  tasks: el?.tasks?.map((el) => {
                      return { ...el, checked: checked };
                  }),
              }
            : el;
    });
    return setTemplates(new_state);
};

export const _checkedChildrensTemplate = (_, i) => {
    _.stopPropagation();
    _.preventDefault();

    try {
        const {
            "data-parent": parent,
            checked,
            "data-children": child,
            templates,
            setTemplates,
        } = i;
        const { id } = parent;

        // check or uncheck child element.
        const new_state = templates.map((el) => {
            return el?.id === id
                ? {
                      ...el,
                      tasks: el?.tasks?.map((el) => {
                          if (child?.id === el?.id) {
                              return { ...el, checked: checked };
                          } else {
                              return el;
                          }
                      }),
                  }
                : el;
        });

        // get the current new tasks of children.
        const filter = new_state?.filter((el) => {
            return el?.id === id;
        });

        // verify if is all checked or not.
        const isAllChecked = filter[0]?.tasks?.every(
            (el) => el?.checked === true
        );
        const isSomeChecked = filter[0]?.tasks?.some(
            (el) => el?.checked === true
        );

        // render the final data there.
        const last_state = templates.map((el) => {
            return el?.id === id
                ? {
                      ...el,
                      checked: isAllChecked
                          ? true
                          : isSomeChecked
                          ? "mixed"
                          : false,
                      tasks: el?.tasks?.map((el) => {
                          if (child?.id === el?.id) {
                              return { ...el, checked: checked };
                          } else {
                              return el;
                          }
                      }),
                  }
                : el;
        });

        // save this data into the context.
        return setTemplates(last_state);
    } catch (error) {
        console.log({ error });
    }
};

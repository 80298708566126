import React from "react";
import { Wrapper } from "./index.style";
import { AddIcon, Button } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";

const AddSlot = ({ onClick, disabled }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    return (
        <Wrapper vAlign="center">
            <Button
                icon={<AddIcon />}
                content={t("v2.home.buttons.add_slot")}
                text
                primary
                disabled={disabled}
                onClick={onClick}
            />
        </Wrapper>
    );
};

export default AddSlot;

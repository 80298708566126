import { Flex, Text } from "@fluentui/react-northstar";
import styled, { css } from "styled-components";

export const Container = styled(Flex)`
    padding: 8px 12px;
    gap: 8px;
    height: fit-content;
    width: 140px;
    border-radius: 16px;
    background: ${({ bg }) => (bg ? bg : "none")};
`;
export const Hour = styled(Text)`
    color: ${({ fontColor }) => fontColor};
    /* color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        ${(fontColor) => fontColor}
    ); */
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
`;
export const Duration = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

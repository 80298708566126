import { Text } from "@fluentui/react-northstar";
import { EmptyField } from "../styles";
import { ROLES_CODE } from "../../../constants/roles";

export const QUERY_KEY = "EMPLOYEE_FOLDER";
export const BREADCRUMB_EMPLOYEE_FOLDER = [
    {
        href: "/",
        label: "Accueil",
    },
    {
        href: "/annuaire",
        label: "Organisation",
    },
    {
        href: "#",
        label: "Collaborateur",
    },
];

const PANELS_EMPLOYEE = [
    {
        key: "employee",
        title: "Collaborateur",
        content:
            "N’importe quelle utilisateur ajouté dans l’outil dispose de ce rôle, cela lui permet d’apparaître dans l’organisation et de saisir du temps à minima",
    },
];

const PANELS_MANAGER = [
    {
        key: "manager",
        title: "Manager",
        content:
            "Un manager peut modifier certaines informations des collaborateurs dont il est le manager. Il peut également créer et assigner des tâches ou encore gérer les absences, les notes de frais ou le portage salarial",
    },
];

const PANELS_HR = [
    {
        key: "hr",
        title: "HR",
        content:
            "Le RH peut voir toutes les informations de tous les profils, il peut également créer des tâches et déposer des documents dans l’espace documentaire de tous les collaborateurs",
    },
];

const PANELS_ADMIN = [
    {
        key: "admin",
        title: "Administrateur",
        content: "ADMIN",
    },
];

export const PANELS_ROLES = {
    [ROLES_CODE.employee]: PANELS_EMPLOYEE,
    [ROLES_CODE.hr]: PANELS_HR,
    [ROLES_CODE.manager]: PANELS_MANAGER,
    [ROLES_CODE.admin]: PANELS_ADMIN,
};

export const EMPTY_FIELD = <EmptyField content="Non renseigné" />;

const SETUP_PROFILE = {
    MARITAL_STATUS: [
        {
            id: 1,
            name: "Célibataire",
        },
        {
            id: 2,
            name: "Marié(e)",
        },
        {
            id: 3,
            name: "Veuf(ve)",
        },
        {
            id: 4,
            name: "Divorcé(e)",
        },
    ],
    IDENTITY_CARD_TYPE: [
        {
            id: 1,
            name: "Carte nationale d'identité",
        },
        {
            id: 2,
            name: "Passport",
        },
        {
            id: 3,
            name: "Titre de séjour",
        },
    ],
    STATUS: [
        {
            id: 0,
            key: 1,
            name: "Autre",
        },
        {
            id: 1,
            key: 2,
            name: "Cadre",
        },
        {
            id: 2,
            key: 3,
            name: "Etam",
        },
    ],
    ROLE: [
        {
            id: 1,
            name: "Interne",
        },
        { id: 2, name: "Externe" },
        { id: 3, name: "Consultant" },
    ],
    GENDER: [
        {
            id: 1,
            name: "Homme",
        },
        {
            id: 2,
            name: "Femme",
        },
        {
            id: 3,
            name: "Autre",
        },
    ],
};

export default SETUP_PROFILE;

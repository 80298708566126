import moment from "moment";
import { _str_capitalize } from "../../utils/functions";
import { COLUMN_WIDTH } from "./constants";

export function getISOWeeksAndDays(year, month) {
    const isoWeeksAndDays = [];

    // Create a moment object for the first day of the specified month
    const startDate = moment(`${year}-${month + 1}-01`, "YYYY-MM-DD");

    // Get the number of days in the month
    const daysInMonth = startDate.daysInMonth();
    let last_isoWeek = null;
    // Loop through the days of the month
    let last_day = 1;
    const weekends = [];
    for (let day = 1; day <= daysInMonth; day++) {
        const date = startDate.date(day);
        const isoWeek = date.isoWeek();
        const weekday = _str_capitalize(date.format("ddd"));
        const index_day = date.get("day");
        if (index_day === 6 || index_day === 0) {
            weekends.push(day);
        }
        if (last_isoWeek === isoWeek) {
            isoWeeksAndDays[isoWeeksAndDays.length - 1].columns.push({
                day,
                weekday,
                start_day_px: day * COLUMN_WIDTH,
                is_weekend: index_day === 6 || index_day === 0 ? true : false,
            });
        } else {
            isoWeeksAndDays.push({
                isoWeek,
                columns: [
                    {
                        day,
                        weekday,
                    },
                ],
            });
            last_isoWeek = isoWeek;
        }
        last_day = day;
    }

    return { isoWeeksAndDays, last_day, weekends };
}

import React, { useCallback } from "react";
import { useState } from "react";
import {
    Accordion,
    Button,
    Flex,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import moment from "moment/moment";
import styled from "styled-components";

import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import Header from "./Accordions/Header";
import SliderYears from "../../../components/shared/SliderYears";
import { Container, ContainerFluid } from "../../../common/styles";
import { I18N_NS_EXPENSE, QUERY_KEY } from "../constants";
import { _api_download_excel_of_year, _api_get_my_history } from "../api";
import ContentEmpty from "./Accordions/ContentEmpty";
import Content from "./Accordions/Content";
import { Save16Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { debounce } from "../../../utils/global";
// import { debounce } from "@mui/material";

/**
 * GET ARRAY OF ALL MONTHS OF THE YEAR
 */
const get_all_months = () => {
    const array_months = [];
    for (let i = 0; i < 12; i++) {
        const current_month = moment()
            .startOf("year")
            .add(i, "M")
            .format("MMMM");
        array_months.push({
            month_idx: i + 1,
            month_name: current_month,
        });
    }
    return array_months;
};
/**
 * FORMAT DATA FOR ACCORDION COMPONENT
 */
const get_content_panel = ({ currentYear, data }) => {
    return data?.months?.map((el) => {
        return {
            key: el?.month_idx,
            title: (
                <Header
                    month={el?.month_name}
                    month_index={el.month_idx}
                    year={currentYear}
                    currency={el?.currency}
                    total={el?.total_approved_amount}
                    isMonthDownloadable={false}
                    showButtonDownloadByMonth={false}
                />
            ),
            content: el?.expense_reports?.length ? (
                <Content content={el?.expense_reports} />
            ) : (
                <ContentEmpty />
            ),
        };
    });
};

const ExpensesReportHistory = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const navigate = useNavigate();
    const array_months = get_all_months();

    const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));

    const {
        data = [],
        isLoading,
        isFetching,
    } = useQuery([QUERY_KEY + "::history", currentYear], ({ queryKey }) => {
        const year = queryKey[1];
        return _api_get_my_history({ year });
    });

    const _goback = () => {
        return navigate(-1);
    };

    const _onChangeSliderYear = debounce((year) => {
        setCurrentYear(year);
    }, 500);

    const { isLoading: is_loading_excel, refetch: refetch_export_excel } =
        useQuery(
            [QUERY_KEY + "::export_year", currentYear],
            ({ queryKey }) => {
                const year = queryKey[1];
                return _api_download_excel_of_year({ year });
            },
            {
                enabled: false,
            }
        );

    const content_panel = get_content_panel({
        currentYear,
        array_months,
        data,
    });

    return (
        <ContainerFluid>
            <Container
                gap="gap.large"
                style={{
                    marginTop: "24px",
                }}
            >
                <Wrapper fill column gap="gap.medium">
                    <Flex vAlign="center" fill space="between">
                        <Title>{t("history.title")}</Title>
                        <Button
                            text
                            primary
                            content={t("common.back")}
                            onClick={_goback}
                        />
                    </Flex>
                    <WhiteBoard fill column gap="gap.large">
                        <Flex column gap="gap.small">
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    <SliderYears
                                        onChangeYear={_onChangeSliderYear}
                                        hideDownloadButton={true}
                                    />
                                    <AccordionStyle panels={content_panel} />
                                </>
                            )}
                        </Flex>
                    </WhiteBoard>
                </Wrapper>
            </Container>
        </ContainerFluid>
    );
};

export default ExpensesReportHistory;

export const AccordionStyle = styled(Accordion)`
    .ui-accordion__title {
        padding-bottom: 0px;
        padding-top: 0px;
    }
    .Accordion__checkbox--header {
        padding: 12px 8px 12px 12px;
    }
    .ui-accordion__title__content-wrapper > .ui-box {
        background-repeat: no-repeat;
        /* background-position-y: 5px; */
    }
    .ui-accordion__title__content-wrapper {
        background-color: #e8ebfa;
    }
`;

const WhiteBoard = styled(Flex)`
    background-color: white;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    padding: 16px;
`;

const Wrapper = styled(Flex)`
    width: 1307px;
    padding-bottom: 40px;
    padding-inline: 8px;
`;

const Title = styled(Text)`
    font-weight: 700;
    line-height: clamp(20px, 2vw, 32px);
    color: #242424;
    font-size: clamp(14px, 2vw, 24px);
`;

import React, { useState } from "react";
import {
    Cell,
    CellFirstColumn,
    CellHead,
    CollapsableRow,
    Username,
    Wrapper,
} from "./index.style";
import { Avatar, Flex } from "@fluentui/react-northstar";
import Event from "../Event";
import DepartmentCollapse from "../DepartmentCollapse/DepartmentCollapse";
import DialogDeclareRemoteDay from "../DialogDeclareRemoteDay/DialogDeclareRemoteDay";
import { useAuthDetailStore } from "../../../../stores/useAuthStore";
import SkeletonRemoteRowEvents from "../SkeletonRemoteRowEvents/SkeletonRemoteRowEvents";
import { useShowRemoteWeekend } from "../../store/useShowRemoteWeekend";
import { useToastRemote } from "../../store/useToastRemote";
import Toast from "../../../../components/Alerts";

/**
 * @typedef {Object} PropsType
 * @property {Array<{id : number, label : string, is_today : boolean}>} days - les jours a afficher a coté des départements
 * @property {Object} rows - les lignes a afficher
 * @property {boolean} [isLoading] - true/false fetch data list from Backend
 *
 * @param {PropsType} Props
 * @returns
 */
const CalendarRemoteJob = ({
    days,
    rows,
    isLoading = false,
    onAddEventSuccess = () => null,
}) => {
    // contexts
    const detail = useAuthDetailStore((state) => state.detail);
    const ctx_show_weekend = useShowRemoteWeekend((state) => state.show);
    const ctx_toast = useToastRemote((state) => state);

    // states
    const [closedPanels, setClosedPanels] = useState([]);

    // selection of cells with longPress
    const [longPress, setLongPress] = useState(false);
    const [selectedCells, setSelectedCells] = useState([]);
    const [startCell, setStartCell] = useState(null);
    const [openDialogMultipleSelection, setOpenDialogMultipleSelection] =
        useState(false);

    // permet de selectionner ou de deselectionner des cellules
    const handle_mouse_enter = (index_cells, is_me) => {
        if (longPress && is_me) {
            const start = Math.min(startCell, index_cells);
            const end = Math.max(startCell, index_cells);

            const newSelectedCells = [];
            for (let i = start; i <= end; i++) {
                newSelectedCells.push(i);
            }
            setSelectedCells(newSelectedCells);
        }
    };

    const handle_mouse_down_cell = (index_cells) => {
        setStartCell(index_cells);
        return;
    };

    // permet d'ouvrir ou de fermer l'accordion
    const open_close_panel = (event_id) => {
        const is_includes = closedPanels.includes(event_id);
        if (is_includes) {
            setClosedPanels((prev) => {
                return prev.filter((item) => item !== event_id);
            });
        } else {
            setClosedPanels((prev) => {
                return [...prev, event_id];
            });
        }
        return;
    };
    // handle la fermeture de l'ajout remote job
    const onCloseModalAddRemote = () => {
        setOpenDialogMultipleSelection(false);
        setSelectedCells([]);
        return;
    };

    const getUpdateDataProps = () => {
        const start_date = days[selectedCells.at(0)]?.date ?? null;
        const end_date = days[selectedCells.at(-1)]?.date ?? null;

        return {
            start_date,
            end_date,
            start_time: null,
            end_time: null,
            reason: 1,
        };
    };

    return (
        <Wrapper column>
            <Toast
                level={ctx_toast.level}
                content={ctx_toast.content}
                visible={ctx_toast.visible}
                onStatusChange={() => ctx_toast.onChangeStatus()}
            />
            {/* <pre>test code :{longPress.toString()}</pre>
            <pre>cells selected :{JSON.stringify(selectedCells)}</pre> */}
            {/* dialog lors multiple selection */}
            <DialogDeclareRemoteDay
                open={openDialogMultipleSelection}
                onClose={onCloseModalAddRemote}
                can_i_create_event={true}
                updateData={selectedCells.length ? getUpdateDataProps() : null}
                onAddSuccess={onAddEventSuccess}
            />
            {/* header */}
            <Flex>
                <CellFirstColumn styles={{ backgroundColor: "#FAFAFA" }}>
                    Départements
                </CellFirstColumn>
                {/* render days Cell */}
                {days.slice(0, ctx_show_weekend ? 7 : 5).map((day, index) => {
                    return (
                        <CellHead
                            key={index}
                            $today={day.is_today}
                            $ctx_show_weekend={ctx_show_weekend}
                        >
                            {day.label}
                        </CellHead>
                    );
                })}
            </Flex>
            {/* tbody */}
            {/* MAP */}

            {/* ONE ROW */}
            {isLoading ? (
                <SkeletonRemoteRowEvents />
            ) : (
                rows.map((row) => {
                    const isClosedPanel = closedPanels.includes(row.id);
                    return (
                        <Flex column key={row.id}>
                            <DepartmentCollapse
                                event_id={row.id}
                                title={row.name}
                                counter={row.users?.length}
                                closedPanel={isClosedPanel}
                                onClickChevron={open_close_panel}
                            />
                            <CollapsableRow column $closed={isClosedPanel}>
                                {row.users.map((user) => {
                                    const is_me = user.id === detail?.id;
                                    return (
                                        <Flex key={user.id}>
                                            <CellFirstColumn
                                                gap="gap.small"
                                                $active={is_me}
                                            >
                                                <Avatar
                                                    name={user.name}
                                                    image={user.avatar}
                                                    size="smallest"
                                                />
                                                <Username>{user.name}</Username>
                                            </CellFirstColumn>
                                            <Flex
                                                styles={{
                                                    position: "relative",
                                                }}
                                                onMouseDown={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    const is_clicked_cell =
                                                        e.target.classList.contains(
                                                            "cell"
                                                        );
                                                    if (
                                                        is_me &&
                                                        is_clicked_cell
                                                    ) {
                                                        // long press
                                                        // add current cell click
                                                        const dataset_index_cell =
                                                            Number(
                                                                e.target.dataset
                                                                    ?.indexcell
                                                            );
                                                        setLongPress(true);
                                                        setSelectedCells([
                                                            dataset_index_cell,
                                                        ]);
                                                    }
                                                }}
                                                onMouseUp={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    const is_clicked_cell =
                                                        e.target.classList.contains(
                                                            "cell"
                                                        );
                                                    if (
                                                        is_me &&
                                                        is_clicked_cell
                                                    ) {
                                                        setOpenDialogMultipleSelection(
                                                            true
                                                        );
                                                        setLongPress(false);
                                                    }
                                                }}
                                            >
                                                {user?.out_of_office_items
                                                    .slice(
                                                        0,
                                                        ctx_show_weekend ? 7 : 5
                                                    )
                                                    .map(
                                                        (
                                                            event,
                                                            index_cells
                                                        ) => {
                                                            if (
                                                                Array.isArray(
                                                                    event
                                                                )
                                                            ) {
                                                                return (
                                                                    <Cell
                                                                        column
                                                                        $isClickable={
                                                                            is_me
                                                                        }
                                                                        $ctx_show_weekend={
                                                                            ctx_show_weekend
                                                                        }
                                                                        $holiday={
                                                                            event
                                                                                ?.formated
                                                                                ?.$holiday
                                                                        }
                                                                        $leave={
                                                                            event
                                                                                ?.formated
                                                                                ?.$leave
                                                                        }
                                                                        $selected={
                                                                            selectedCells.includes(
                                                                                index_cells
                                                                            ) &&
                                                                            is_me
                                                                        }
                                                                        $leaveColor={
                                                                            event
                                                                                ?.formated
                                                                                ?.$leave_color
                                                                        }
                                                                        onMouseDown={() =>
                                                                            handle_mouse_down_cell(
                                                                                index_cells
                                                                            )
                                                                        }
                                                                        onMouseEnter={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            handle_mouse_enter(
                                                                                index_cells,
                                                                                is_me
                                                                            );
                                                                        }}
                                                                        className="cell"
                                                                        data-indexcell={
                                                                            index_cells
                                                                        }
                                                                    >
                                                                        <Event
                                                                            type={
                                                                                event.at(
                                                                                    0
                                                                                )
                                                                                    .formated
                                                                                    .type
                                                                            }
                                                                            nbr_days={
                                                                                event.at(
                                                                                    0
                                                                                )
                                                                                    .formated
                                                                                    .nbr_days
                                                                            }
                                                                            popover_detail={event.at(
                                                                                0
                                                                            )}
                                                                            can_update_or_delete={
                                                                                event.at(
                                                                                    0
                                                                                )
                                                                                    .can_update_or_delete
                                                                            }
                                                                        />
                                                                        <Event
                                                                            type={
                                                                                event.at(
                                                                                    1
                                                                                )
                                                                                    .formated
                                                                                    .type
                                                                            }
                                                                            nbr_days={
                                                                                event.at(
                                                                                    1
                                                                                )
                                                                                    .formated
                                                                                    .nbr_days
                                                                            }
                                                                            popover_detail={event.at(
                                                                                1
                                                                            )}
                                                                            can_update_or_delete={
                                                                                event.at(
                                                                                    1
                                                                                )
                                                                                    .can_update_or_delete
                                                                            }
                                                                        />
                                                                    </Cell>
                                                                );
                                                            } else {
                                                                return (
                                                                    <Cell
                                                                        className="cell"
                                                                        data-indexcell={
                                                                            index_cells
                                                                        }
                                                                        column
                                                                        $isClickable={
                                                                            is_me
                                                                        }
                                                                        $ctx_show_weekend={
                                                                            ctx_show_weekend
                                                                        }
                                                                        $holiday={
                                                                            event
                                                                                ?.formated
                                                                                ?.$holiday
                                                                        }
                                                                        $leave={
                                                                            event
                                                                                ?.formated
                                                                                ?.$leave
                                                                        }
                                                                        $leaveColor={
                                                                            event
                                                                                ?.formated
                                                                                ?.$leave_color
                                                                        }
                                                                        $selected={
                                                                            selectedCells.includes(
                                                                                index_cells
                                                                            ) &&
                                                                            is_me
                                                                        }
                                                                        onMouseDown={() =>
                                                                            handle_mouse_down_cell(
                                                                                index_cells
                                                                            )
                                                                        }
                                                                        onMouseEnter={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            handle_mouse_enter(
                                                                                index_cells,
                                                                                is_me
                                                                            );
                                                                        }}
                                                                    >
                                                                        {event
                                                                            .formated
                                                                            ?.position
                                                                            ?.bottom && (
                                                                            <Event
                                                                                type="blank"
                                                                                nbr_days={
                                                                                    0
                                                                                }
                                                                                can_update_or_delete={
                                                                                    false
                                                                                }
                                                                            />
                                                                        )}
                                                                        {event
                                                                            .formated
                                                                            ?.type && (
                                                                            <Event
                                                                                type={
                                                                                    event
                                                                                        .formated
                                                                                        .type
                                                                                }
                                                                                nbr_days={
                                                                                    event
                                                                                        .formated
                                                                                        .nbr_days
                                                                                }
                                                                                popover_detail={
                                                                                    event
                                                                                }
                                                                                can_update_or_delete={
                                                                                    event.can_update_or_delete
                                                                                }
                                                                            />
                                                                        )}
                                                                        {event
                                                                            .formated
                                                                            ?.position
                                                                            ?.top && (
                                                                            <Event
                                                                                type="blank"
                                                                                nbr_days={
                                                                                    0
                                                                                }
                                                                                can_update_or_delete={
                                                                                    false
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Cell>
                                                                );
                                                            }
                                                        }
                                                    )}
                                            </Flex>
                                        </Flex>
                                    );
                                })}
                            </CollapsableRow>
                        </Flex>
                    );
                })
            )}
        </Wrapper>
    );
};

export default CalendarRemoteJob;

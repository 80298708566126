import React from "react";
import { Avatar, Flex, Text } from "@fluentui/react-northstar";

const AvatarUser = ({ name, image, showName = true }) => {
    return (
        <Flex vAlign="center" gap="gap.small">
            <Flex>
                <Avatar image={image || null} name={name} />
            </Flex>
            {showName && (
                <Flex>
                    <Text truncated>{name}</Text>
                </Flex>
            )}
        </Flex>
    );
};

export default AvatarUser;

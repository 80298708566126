import {
    Avatar,
    Flex,
    Table,
    TableCell,
    TableRow,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import { GlobalLabel, TextDate, TextLabel } from "./index.style";
import { CustomBadge } from "../../../../../components/Badge";
import { LeaveStatusBadge } from "../LeaveStatusBadge";
import Pagination from "../../../../../components/Pagination/Pagination";
import DialogFilterAjustment from "../FilterHistoryAjustment/DialogFilterAjustment";
import {
    MobileText,
    TextTitle,
    TitleFlex,
} from "../ajustementSolde/SoldeAjustement";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { _api_get_list_leave_as_manager, _get_types_leave } from "../../../api";
import DialogLeaveDetail from "../../../../../components/Timeline/components/Dialogs/DialogLeaveDetail";
import useToast from "../../../../../hooks/useToast";
import Toast from "../../../../../components/Alerts";
import { _get_all_employees_and_departments } from "../../../../../api/user";
import DefaultAvatarEmployee from "../../../../../assets/icons/avatar.svg";
import { Container, ContainerCard } from "../../../../../common/styles";
import { AVATAR_DEPARTMENT_DEFAULT } from "../../../../../constants";

import SkeletonLeaveList from "../SkeletonListLeave/SkeletonLeaveList";

import { SIZES } from "../../../../../constants/responsive";
import { useMediaQuery } from "react-responsive";
import EmptyDemande from "../../EmptyDemande";
import { refetchTeamBadge } from "../../../utils";

const LeaveRequestList = () => {
    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileS})`,
    });
    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    const [toast, setToast] = useToast();

    const header = {
        key: "header",
        style: {
            backgroundColor: "#F5F5F5",
        },
        items: [
            {
                key: "name",
                content: (
                    <Flex vAlign="center" gap="gap.small">
                        <Avatar image={DefaultAvatarEmployee} size="smaller" />
                        {!isMobileM ? (
                            <TextLabel content={"Nom Prénom"} />
                        ) : (
                            <Text
                                styles={{
                                    fontSize: "12px",
                                    whiteSpace: "nowrap",
                                }}
                                content={"Type d'absence"}
                            />
                        )}
                        {/* <TextLabel content={"Nom Prénom"} /> */}
                    </Flex>
                ),
            },
            {
                key: "leave_type",
                content: !isMobileM && (
                    <Flex vAlign="center" gap="gap.small">
                        <GlobalLabel content={"Type d'absence"} />
                        <CustomBadge
                            bg="black"
                            color="white"
                            size="small"
                            label="Durée"
                            rectangular={true}
                            style={{
                                borderRadius: "4px",
                            }}
                        />
                    </Flex>
                ),
            },
            {
                key: "start_date",
                content: !isMobileM && <GlobalLabel content={"Début"} />,
                style: {
                    justifyContent: "end",
                    alignItems: "center",
                },
            },
            {
                key: "end_date",
                content: !isMobileM && <GlobalLabel content={"Fin"} />,
                style: {
                    justifyContent: "start",
                    alignItems: "center",
                },
            },
            {
                key: "status",
                content: <GlobalLabel content={"Statut"} />,
                style: {
                    justifyContent: "center",
                    alignItems: "center",
                },
            },
        ],
    };

    const [searchParams, setSearchParams] = useSearchParams({
        employee: [],
        type: [],
        checkboxes: [],
        checkboxes_status: [],
        start_date: "",
        end_date: "",
    });

    const methodsAjustment = useForm({
        defaultValues: {
            checkboxes: searchParams.getAll("a") ?? [],
            checkboxes_status: searchParams.getAll("s") ?? [],
            start_date: searchParams.get("start_date")
                ? new Date(searchParams.get("start_date"))
                : new Date(
                      moment()
                          .subtract(1, "months")
                          .startOf("month")
                          .format("YYYY-MM-DD")
                  ),
            end_date: searchParams.get("end_date")
                ? new Date(searchParams.get("end_date"))
                : new Date(moment().format("YYYY-MM-DD")),
            employee: [],
            type: searchParams.getAll("t") ?? [],
        },
    });

    // QUERIES
    const {
        data,
        fetchNextPage,
        isLoading,
        isFetching,
        isFetchingNextPage,
        refetch,
    } = useInfiniteQuery({
        queryKey: [
            "leave::getManagerListLeaveRow",
            {
                params_string: searchParams.toString(),
            },
        ],
        queryFn: (state) => {
            return _api_get_list_leave_as_manager({
                page: state?.pageParam,
                ...state.queryKey.at(1),
            });
        },
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.next_page_value;
        },
    });

    const { data: employees, isLoading: is_loading_employees } = useQuery(
        ["leave::get_list_employee"],
        () => {
            return _get_all_employees_and_departments();
        },
        {
            onSuccess: (data) => {
                const ud = searchParams.getAll("ud");
                const selected_employee_params = data?.filter((d) =>
                    ud?.includes(d?.id)
                );
                methodsAjustment.setValue(
                    "employee",
                    selected_employee_params?.map((element) => ({
                        id: element?.id,
                        header: element?.name,
                        image: element?.avatar ?? AVATAR_DEPARTMENT_DEFAULT,
                        content: element.job_title,
                    }))
                );
            },
        }
    );

    const { data: leave_types, isLoading: is_loading_types } = useQuery(
        ["leave::get_list_types_leave"],
        () => {
            return _get_types_leave();
        },
        {
            onSuccess: (data) => {
                const t = searchParams.getAll("t");
                const selected_type = data?.filter((d) =>
                    t?.includes(d?.id + "")
                );
                methodsAjustment.setValue(
                    "type",
                    selected_type?.map((element) => ({
                        id: element?.id,
                        header: element?.name,
                        color: element?.color,
                    }))
                );
            },
        }
    );

    // FUNCTIONS
    const onSubmitAjustmentFilter = (data) => {
        const new_params = {};

        if (data?.start_date)
            new_params.start_date = moment(data?.start_date).format(
                "YYYY-MM-DD"
            );
        if (data?.end_date)
            new_params.end_date = moment(data?.end_date).format("YYYY-MM-DD");

        console.log({ data });

        setSearchParams({
            ...new_params,
            ud: data?.employee?.map((u) => u.id),
            a: data?.checkboxes?.map((cb) => cb),
            t: data?.type?.map((t) => t.id),
            s: data?.checkboxes_status,
        });
        // refetch();
        return;
    };

    const successRequest = ({ toastMessage = "", level = "success" }) => {
        // show toast.
        setToast({
            visible: true,
            content: toastMessage,
            level,
        });
        refetch();
        return;
    };

    // VALUES
    const last_page = data?.pages[data?.pages?.length - 1];
    const currentPageToShow = last_page?.next_page_value
        ? (last_page?.next_page_value - 1) * 10
        : data?.pages[0]?.all_leaves_count;
    const is_more_rows = last_page?.next_page_value;

    const ROWS =
        data?.pages
            ?.map((pages, index) => {
                return pages?.leaves?.map((leave, i) => {
                    const username = leave?.user?.name;
                    const avatar = leave?.user?.avatar;
                    const status_id = leave?.status?.id;
                    const end_date = moment(leave?.end_date).format(
                        "DD/MM/YYYY"
                    );

                    const start_date = moment(leave?.start_date).format(
                        "DD/MM/YYYY"
                    );
                    const mobile_end_date = moment(leave?.end_date).format(
                        "DD/MM/YY"
                    );
                    const mobile_start_date = moment(leave?.start_date).format(
                        "DD/MM/YY"
                    );
                    const balance_count_to_remove =
                        leave?.balance_count_to_remove;
                    const { id: type_id, name: type_name, color } = leave?.type;
                    return {
                        key: leave?.id,
                        items: [
                            {
                                key: `${leave?.id}-1`,
                                content: (
                                    <Flex vAlign="center" gap="gap.small">
                                        <Avatar size="smaller" image={avatar} />
                                        {!isMobileM && (
                                            <TextLabel content={username} />
                                        )}
                                    </Flex>
                                ),
                                style: {
                                    Padding: "0px",
                                    justifyContent: "start",
                                    alignItems: "center",
                                },
                            },
                            {
                                truncateContent: true,
                                key: `${leave?.id}-2`,
                                content: (
                                    <CustomBadge
                                        bg={color}
                                        color="black"
                                        size="medium"
                                        label={
                                            <Flex
                                                vAlign="center"
                                                gap="gap.small"
                                            >
                                                <Text
                                                    style={{ fontSize: "12px" }}
                                                    content={type_name}
                                                />
                                                {!isMobileM && (
                                                    <CustomBadge
                                                        bg="black"
                                                        color="white"
                                                        size="smaller"
                                                        label={`${balance_count_to_remove} j`}
                                                        rectangular={true}
                                                        style={{
                                                            borderRadius: "4px",
                                                        }}
                                                    />
                                                )}
                                            </Flex>
                                        }
                                        rectangular={true}
                                        style={{
                                            borderRadius: "4px",

                                            marginLeft: isMobileS
                                                ? "-30px"
                                                : isMobileM
                                                ? "-45px"
                                                : "0px",
                                            whiteSpace: "nowrap",
                                        }}
                                    />
                                ),
                            },
                            {
                                key: `${leave?.id}-3`,
                                content: (
                                    <TextDate
                                        content={
                                            isMobileS || isMobileM
                                                ? mobile_start_date
                                                : start_date
                                        }
                                    />
                                ),
                                style: {
                                    justifyContent: "end",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                },
                            },
                            {
                                key: `${leave?.id}-4`,
                                content: (
                                    <TextDate
                                        content={
                                            isMobileS || isMobileM
                                                ? mobile_end_date
                                                : end_date
                                        }
                                    />
                                ),
                                style: {
                                    justifyContent: "start",
                                    alignItems: "center",
                                },
                            },
                            {
                                key: `${leave?.id}-5`,
                                content: LeaveStatusBadge(status_id),
                                style: {
                                    justifyContent: "center",
                                    alignItems: "center",
                                },
                            },
                        ],
                        data_row: leave,
                        style: {
                            cursor: "pointer",
                        },
                    };
                });
            })
            ?.flat() ?? [];

    const EmptyList = data?.pages?.at(0)?.leaves?.length;
    const queryClient = useQueryClient();
    return (
        <ContainerCard column>
            <Toast
                {...toast}
                onStatusChange={() =>
                    setToast((state) => ({ ...state, visible: false }))
                }
            />
            {/* TITLE + FILTER */}
            <TitleFlex vAlign="center" gap="gap.medium">
                {isMobileS || isMobileM ? (
                    <MobileText content="Liste des demandes" />
                ) : (
                    <TextTitle content={"Liste des demandes d'absences"} />
                )}
                <FormProvider {...methodsAjustment}>
                    <form
                        onSubmit={methodsAjustment.handleSubmit(
                            onSubmitAjustmentFilter
                        )}
                    >
                        <DialogFilterAjustment
                            isMobile={isMobileS || isMobileM}
                            onSubmitAjustmentFilter={onSubmitAjustmentFilter}
                            employees={employees}
                            is_loading_employees={is_loading_employees}
                            leave_types={leave_types}
                            is_loading_types={is_loading_types}
                            refetch={() => refetch()}
                            isHR={false}
                            isManager={true}
                        />
                    </form>
                </FormProvider>
            </TitleFlex>
            {/* LEAVE LIST */}
            {EmptyList === 0 && <EmptyDemande forLeave={true} />}
            {EmptyList > 0 && (
                <Table>
                    {/* header */}
                    <TableRow key={header.key} styles={header?.style ?? {}}>
                        {header.items.map((head) => {
                            return (
                                <TableCell key={head.key} styles={head?.style}>
                                    {head.content}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                    {isLoading && <SkeletonLeaveList containAvatarColumn />}
                    {EmptyList === 0 && <EmptyDemande forLeave={true} />}
                    {ROWS.flat().map((row) => {
                        return (
                            <DialogLeaveDetail
                                leave_details={{ ...row?.data_row }}
                                user_details={{ ...row?.data_row?.user }}
                                content={
                                    <TableRow key={row.key} styles={row.style}>
                                        {row.items.map((item) => {
                                            return (
                                                <TableCell
                                                    key={item.key}
                                                    styles={item.style}
                                                >
                                                    {item.content}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                }
                                cbOnApproveLeave={(data) => {
                                    const isSuccess = data?.success;
                                    refetchTeamBadge(queryClient);
                                    successRequest({
                                        level: isSuccess ? "success" : "danger",
                                        toastMessage: isSuccess
                                            ? "La demande a bien été acceptée"
                                            : data?.message,
                                    });
                                }}
                                cbOnRefuseLeave={(data) => {
                                    const isSuccess = data?.success;
                                    refetchTeamBadge(queryClient);
                                    successRequest({
                                        level: isSuccess ? "success" : "danger",
                                        toastMessage: isSuccess
                                            ? "La demande a bien été refusée"
                                            : data?.message,
                                    });
                                }}
                                cbOnCancelLeave={(data) => {
                                    const isSuccess = data?.success;
                                    refetchTeamBadge(queryClient);
                                    successRequest({
                                        level: isSuccess ? "success" : "danger",
                                        toastMessage: isSuccess
                                            ? "La demande a bien été Annulée"
                                            : data?.message,
                                    });
                                }}
                                cbOnDeleteLeave={(data) => {
                                    const isSuccess = data?.success;
                                    refetchTeamBadge(queryClient);
                                    successRequest({
                                        level: isSuccess ? "success" : "danger",
                                        toastMessage: isSuccess
                                            ? "La demande a bien été Supprimée"
                                            : data?.message,
                                    });
                                }}
                            />
                        );
                    })}
                </Table>
            )}

            {/* PAGINATION */}
            <Pagination
                buttonContentSeeMore={"Afficher plus de demandes"}
                countMessage={"demandes affichées sur"}
                currentCountRows={currentPageToShow}
                isMore={is_more_rows}
                isLoading={isLoading}
                totalRows={data?.pages?.at(0).all_leaves_count}
                isFetchingNextPage={isFetchingNextPage}
                funcFetchNextPage={() => fetchNextPage()}
            />
        </ContainerCard>
    );
};

export default LeaveRequestList;

import React from "react";
import { Power20Regular } from "@fluentui/react-icons";
import { logout } from "../../../api/auth";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";
import { Height } from "@mui/icons-material";
const MobileFooter = ({ hideLogout = false }) => {
    const navigate = useNavigate();
    return (
        <MobileContent column fill space="between" vAlign="center">
            <Flex fill column>
                <PolitiqueYeetchFlex
                    column
                    fill

                    // space="between"
                >
                    <Flex space="between">
                        <LinkStyle to="#">
                            <TextLink
                                href="#"
                                content="Politique de confidentialité"
                            />
                        </LinkStyle>
                        {!hideLogout && (
                            <ButtonStyle
                                text
                                iconPosition="after"
                                content={
                                    <Flex>
                                        <Text
                                            styles={{
                                                fontSize: "12px",
                                                paddingRight: "8px",
                                                color: "#C4314B",
                                            }}
                                        >
                                            Déconnexion
                                        </Text>
                                        <Power20Regular color="var(--color-danger)" />
                                    </Flex>
                                }
                                onClick={async () => {
                                    await logout();
                                    navigate("/login");
                                }}
                            />
                        )}
                    </Flex>
                    <LinkStyle>
                        <TextLink content="Cookies" />
                    </LinkStyle>
                </PolitiqueYeetchFlex>
            </Flex>
            <Flex
                space="between"
                styles={{ paddingInline: "16px", marginTop: "16px" }}
                fill
                hAlign="center"
            >
                <LinkStyle to="#">
                    <TextLink content="Mentions légales" />
                </LinkStyle>
                <Text content="© Yeetch 2023" />
            </Flex>
        </MobileContent>
    );
};

export default MobileFooter;

const ButtonStyle = styled(Button)`
    border-radius: 4px;
    border: 1px solid var(--Light-theme-Rest-Border-Default-Border, #d1d1d1);
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);
    padding-inline: 8px;
    width: 100%;
    max-width: 125px;
    height: 25px;
`;

const MobileContent = styled(Flex)`
    border-top: 2px solid #d1d1d1;
    background-color: #fafafa;
    padding: 16px 0;
`;

const LinkStyle = styled(Link)`
    text-decoration: none;
`;

const TextLink = styled(Text)`
    color: #0067b8;
    text-align: center;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
`;

const PolitiqueYeetchFlex = styled(Flex)`
    width: 100%;
    gap: 12px;
    @media ${DEVICE.mobileS} {
        justify-content: space-between;
        padding-inline: 16px;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
    }
`;

import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const EventText = styled.span`
    color: #242424;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;
export const Event = styled(Flex)`
    padding-left: 4px;

    /* shadow-2 */
    &:hover {
        box-shadow: 0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11),
            0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13);

        ${EventText} {
            font-weight: bold;
        }
    }
`;

import { Button, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const HeaderText = styled(Text)`
    color: #242424;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`;
export const BackToText = styled(Button)`
    color: #5b5fc7;
    text-align: right;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;
export const Body = styled(Flex)`
    padding: 16px 24px 24px 0px;
    background-color: #ffffff;
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
    z-index: 2;
`;
export const Header = styled(Flex)`
    padding: 16px;
`;
export const TextStyle = styled(Text)`
    padding: 16px 24px 24px 24px;
`;
export const HeaderBody = styled(Flex)`
    padding: 8px 24px 8px 16px;
    gap: 40px;
`;

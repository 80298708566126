import React from "react";
import LabelView from "../../LabelView";
import { Flex, Text } from "@fluentui/react-northstar";
import CopyText from "../../CopyText";
import CallMobile from "../../CallMobile";
import { useState } from "react";

const GENDERS = ["Homme", "Femme", "Autres"];

const PersonnalInformation = ({
    gender,
    first_name,
    last_name,
    birth_name,
    address,
    personal_email,
    email,
}) => {
    return (
        <Flex fill column gap="gap.medium">
            <LabelView label="Civilité" content={GENDERS[gender - 1] ?? "-"} />
            <LabelView label="Prénom" content={first_name} />
            <LabelView label="Nom" content={birth_name} />
            <LabelView label="Nom d’usage" content={last_name} />
            <LabelView label="Adresse" content={address} />
            <LabelView
                label="Email personnel"
                content={
                    <CopyText
                        content={personal_email}
                        width="100%"
                        noBorder={true}
                        noAlert
                    />
                }
            />
            <LabelView
                label="Email professionnel"
                content={
                    <CopyText
                        content={email}
                        width="100%"
                        noBorder={true}
                        noAlert
                    />
                }
            />
        </Flex>
    );
};

export default PersonnalInformation;

import React from "react";
import { FilesPdfIcon, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { ACTIONS } from "../../constants";

const render_badge_action_document_by_id = (action) => {
    const array = ACTIONS.filter((el) => el.id === action);
    return array[0]?.header;
};

const TasksActions = ({ action }) => {
    const content = render_badge_action_document_by_id(action);
    return (
        <Flex vAlign="center" gap="gap.smaller">
            {action !== 0 && <FilesPdfIcon />}
            <Content content={content} />
        </Flex>
    );
};

export default TasksActions;

const Content = styled(Text)`
    color: #252525;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

import React from "react";
import { Avatar, Flex, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CardProfileImage from "./cards";
import MessageSent from "./common/MessageSent";
import { Trans } from "react-i18next";
import {
    useGetAboutMe,
    useGetEmergencyContact,
    useGetSkippedAboutMe,
    useOnboarding,
    useUploadImage,
} from "../stores/useOnboarding";
import CardEditAboutMe from "./cards/CardEditAboutMe";
import CardEmergencyContact from "./cards/CardEmergencyContact";
import ProcessCongratulation from "./ProcessCongratulation";
import { useAuthDetailStore } from "../../../stores/useAuthStore";
import ProcessCGU from "./ProcessCGU";

const ProcessCompletion = () => {
    const { t } = useTranslation("onboarding");

    const store_obj_upload_image = useUploadImage();
    const store_skipped_about_me = useGetSkippedAboutMe();
    const store_obj_emergency_contact = useGetEmergencyContact();
    const store_get_about_me = useGetAboutMe();
    const { detail: current_user } = useAuthDetailStore();
    const { clicked: clicked_goto_dashboard } = useOnboarding(
        (state) => state.goto_dashboard
    );

    const {
        welcome_screen_from_back: { data },
    } = useOnboarding();

    if (!data?.cgu_read && clicked_goto_dashboard) {
        return <ProcessCGU />;
    }

    if (store_obj_emergency_contact.sent && !clicked_goto_dashboard) {
        return <ProcessCongratulation />;
    }

    return (
        <Flex column gap="gap.medium">
            <Row gap="gap.large">
                <Avatar
                    image={data?.hr_avatar}
                    name={data?.hr_fullname}
                    size="largest"
                />
                <Message>
                    <Trans t={t} i18nKey={"section_upload_image.welcome"}>
                        Bonjour {{ name: current_user?.first_name }}, bienvenue
                        dans l’entreprise ! <br /> Je m’appelle{" "}
                        <strong>{{ rh_name: data?.hr_fullname }}</strong>, je
                        suis votre responsable RH. <br /> <br /> Je serais votre
                        principale correspondance dans cet outil{" "}
                        {data?.manager_fullname && {
                            prefix_manager: "avec ton manager ",
                        }}
                        <b>
                            {data?.manager_fullname && {
                                manager_fullname: data?.manager_fullname,
                            }}
                        </b>
                        <br /> <br /> Je vais maintenant vous demander quelques
                        informations que vous pourrez modifier par la suite afin
                        de compléter votre profil.
                    </Trans>
                </Message>
            </Row>
            <Row gap="gap.small">
                <MessageSent
                    AvatarComponent={
                        <Avatar
                            image={data?.hr_avatar}
                            name={data?.hr_fullname}
                            size="medium"
                        />
                    }
                    message={t("section_upload_image.rh_message.add_image")}
                />
            </Row>
            <Row hAlign="end">
                <CardProfileImage />
            </Row>
            {(store_obj_upload_image.validate_image ||
                store_obj_upload_image.skiped) && (
                <>
                    <Row gap="gap.small">
                        <MessageSent
                            AvatarComponent={
                                <Avatar
                                    image={data?.hr_avatar}
                                    name={data?.hr_fullname}
                                    size="medium"
                                />
                            }
                            message={
                                <Text>
                                    <Trans
                                        t={t}
                                        i18nKey={"section_about_me.rh_message"}
                                    >
                                        Merci, passons à l’étape suivante.{" "}
                                        <br />
                                        Pouvez-vous vous présenter en quelques
                                        mots à vos collègues ?
                                    </Trans>
                                </Text>
                            }
                        />
                    </Row>
                    <Row hAlign="end">
                        <CardEditAboutMe />
                    </Row>
                </>
            )}
            {(store_skipped_about_me || store_get_about_me.sent) && (
                <>
                    <Row gap="gap.small">
                        <MessageSent
                            AvatarComponent={
                                <Avatar
                                    image={data?.hr_avatar}
                                    name={data?.hr_fullname}
                                    size="medium"
                                />
                            }
                            message={
                                <Text>
                                    <Trans
                                        t={t}
                                        i18nKey={
                                            "section_emergency_contact.rh_message"
                                        }
                                    >
                                        Merci, passons à la dernière étape.
                                        <br />
                                        Pouvez-vous nous fournir un contact
                                        d’urgence à prévenir en cas de problème
                                        ?
                                    </Trans>
                                </Text>
                            }
                        />
                    </Row>
                    <Row hAlign="end">
                        <CardEmergencyContact />
                    </Row>
                </>
            )}
        </Flex>
    );
};

export default ProcessCompletion;

const Message = styled(Text)`
    background-color: white;
    border-radius: 4px;
    padding: 8px 16px;
    max-width: 480px;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #252525;
`;

const Row = styled(Flex)``;

import moment from "moment";

// CONSTANTS ISO_WEEK
export const CURRENT_ISOWEEK = moment().isoWeek();
export const CURRENT_YEAR = moment().year();
const CURRENT_MOMENT_WEEK = moment().isoWeek(CURRENT_ISOWEEK);
export const DEFAULT_START_DATE = CURRENT_MOMENT_WEEK.isoWeekday(1);
export const DEFAULT_TXT_WEEK_FROM_TO = `${DEFAULT_START_DATE.format(
    "DD MMMM"
)} - ${CURRENT_MOMENT_WEEK.isoWeekday(7).format("DD MMMM")}`;

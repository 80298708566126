import React from "react";
import {
    Alert,
    Divider,
    Flex,
    Input,
    InputLabel,
    Text,
    TextArea,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SliderTabs from "../../../components/shared/SliderTabs";
import CustomEmptyModelTask from "./CustomEmptyModelTask";

import { FromTitle } from "./style";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "../../../common/styles";
import { PT_CREATE_TEMPLATE } from "../constants";
import TableOfTaksAdded from "./TableOfTaksAdded";
import FormDialogAddManualTaskTemplate from "./FormDialogAddManualTaskTemplate";
import { I18N_NS_TRANSLATION } from "../../../constants";
import { DEVICE } from "../../../constants/responsive";
import useToast from "../../../hooks/useToast";
import Toast from "../../../components/Alerts";

const DialogCreateTemplate = ({
    templateTasks,
    setTemplateTasks,
    setSlider,
    showAlert,
    setShowAlert,
    setOpenDialogModifyTask,
}) => {
    const { t } = useTranslation(I18N_NS_TRANSLATION);
    const [toastConfig, setToastConfig] = useToast();

    const {
        register,
        formState: { errors },
        setValue,
    } = useFormContext();

    // FUNCTIONS
    const onModifyTask = (data) => {
        setToastConfig((state) => ({
            ...state,
            visible: true,
            content: "La tâche a bien été ajoutée",
            level: "success",
        }));
        setOpenDialogModifyTask(data);
        return;
    };

    return (
        <>
            {showAlert?.visible && (
                <Alert
                    content={showAlert?.message}
                    visible={true}
                    success={showAlert?.level === "success" ? true : false}
                    warning={showAlert?.level === "warning" ? true : false}
                    danger={showAlert?.level === "danger" ? true : false}
                    dismissible
                    onVisibleChange={() =>
                        setShowAlert({
                            visible: false,
                            message: "",
                        })
                    }
                />
            )}
            <Toast
                {...toastConfig}
                onStatusChange={() =>
                    setToastConfig((state) => ({ ...state, visible: false }))
                }
            />
            <Flex column fill gap="gap.medium">
                <Flex styles={{ marginTop: "10px" }}>
                    <SliderTabs
                        tabs={["Onboarding", "Suivi", "Offboarding"]}
                        onChange={(v) => setValue("type", parseInt(v) + 1)}
                        defaultActiveIndex={0}
                    />
                </Flex>
                <Flex>
                    <TextDescription>
                        Pour ajouter un modèle de tâches, remplissez les
                        informations suivantes :
                    </TextDescription>
                </Flex>
                <Flex>
                    <FromTitle>Définition du modèle</FromTitle>
                </Flex>
                {/* forms */}
                <Flex column gap="gap.medium">
                    <div>
                        <InputStyle
                            {...register("name", {
                                minLength: {
                                    value: 2,
                                    message:
                                        "Le nom doit contenir au minimum 2 caractères.",
                                },
                                required: {
                                    value: true,
                                    message: "Ce champ est obligatoire",
                                },
                            })}
                            label="Nom du modèle"
                            required
                            clearable
                            styles={{ position: "relative" }}
                            error={errors?.name ? true : false}
                        />
                        <ErrorMessage content={errors?.name?.message} />
                    </div>
                    <Flex column>
                        <InputLabel content={"Descriptif du modèle*"} />
                        <TextArea
                            {...register("description", {
                                minLength: {
                                    value: 2,
                                    message:
                                        "Le descriptif doit contenir au minimum 2 caractères.",
                                },
                                required: {
                                    value: true,
                                    message: "Ce champ est obligatoire",
                                },
                            })}
                            aria-label="description model"
                            error={errors?.description ? true : false}
                        />
                        <ErrorMessage content={errors?.description?.message} />
                    </Flex>
                </Flex>

                <Divider />
                <WrapperAddTask fill space="between" vAlign="center">
                    <FromTitle>Tâches comprises dans le modèle</FromTitle>
                    {/* ajouter la tâche manuelle */}
                    <FormDialogAddManualTaskTemplate
                        setTemplateTasks={setTemplateTasks}
                        setToastConfig={setToastConfig}
                    />
                </WrapperAddTask>
                <Flex>
                    {templateTasks?.length > 0 ? (
                        <TableOfTaksAdded
                            tasks={templateTasks}
                            setTemplateTasks={setTemplateTasks}
                            setOpenDialogModifyTask={onModifyTask}
                        />
                    ) : (
                        <CustomEmptyModelTask
                            title={t(
                                PT_CREATE_TEMPLATE + ".empty_illustration.title"
                            )}
                            description={t(
                                PT_CREATE_TEMPLATE +
                                    ".empty_illustration.description"
                            )}
                        />
                    )}
                </Flex>
            </Flex>
        </>
    );
};

export default DialogCreateTemplate;

export const WrapperAddTask = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 15px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
        gap: 15px;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        gap: 15px;
    }
`;

const TextDescription = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #424242;
`;
export const InputStyle = styled(Input)`
    @media ${DEVICE.mobileS} {
        width: 100%;
        input {
            width: 100%;
        }
    }
    @media ${DEVICE.tablet} {
        width: auto;
        input {
            width: auto;
        }
    }
    @media ${DEVICE.laptop} {
        width: auto;
        input {
            width: auto;
        }
    }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { Button, CloseIcon, Dialog } from "@fluentui/react-northstar";
import { Comment16Filled } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";

const CommentDialog = ({ comment }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const [open, setOpen] = useState(false);

    return (
        <Dialog
            open={open}
            header={t("home.dialog.title")}
            confirmButton={t("common.close")}
            content={comment}
            onConfirm={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            headerAction={{
                icon: <CloseIcon />,
                onClick: () => {
                    return setOpen(false);
                },
            }}
            trigger={
                <ButtonComment
                    onClick={() => setOpen(true)}
                    icon={<Comment16Filled />}
                    content={`1 ${t("home.card.comment")}`}
                    iconPosition="before"
                    primary
                    text
                />
            }
        />
    );
};

export default CommentDialog;

const ButtonComment = styled(Button)`
    width: 100%;
    height: 32px;
    background-color: #fafafa;
    margin-top: 4px;
`;

import { useMutation } from "react-query";
import { _api_read_document } from "../api";

const useMutationReadDocument = () => {
    const { mutate, isLoading } = useMutation((data) =>
        _api_read_document(data)
    );

    return {
        mutate,
        isLoading,
    };
};

export default useMutationReadDocument;

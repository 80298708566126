/* eslint-disable multiline-ternary */
import React from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthDetailStore, useAuthStore } from "../../stores/useAuthStore";

const RedirectToLoginIfConnected = ({ children }) => {
    const { infos: user } = useAuthStore();
    const detail = useAuthDetailStore((state) => state.detail);
    const location = useLocation();

    return !user ? (
        children
    ) : (
        <Navigate
            to={
                detail?.is_in_preonboarding
                    ? "/welcome-preonboarding"
                    : "/dashboard"
            }
            replace
            state={{ path: location.pathname }}
        />
    );
};

RedirectToLoginIfConnected.propTypes = {
    children: PropTypes.element,
};

export default RedirectToLoginIfConnected;

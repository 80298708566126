import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components/macro";

export const Container = styled(Flex)`
    width: 1363px;
    min-height: 723px;
    padding: 24px 16px;

    background: var(--light-theme-rest-background-default-background, #fff);

    /* shadow-4 */
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
    margin-top: 26px;
`;
export const Title = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    /* Larger/700 */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
`;
export const HoursPlanning = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
`;
export const ProgressBar = styled(Flex)`
    width: 340px;
    height: 22px;
    background: var(--light-theme-rest-background-brand-background-1, #e8ebfa);
    ::after {
        content: "";
        width: ${({ progress }) => progress + "%"};
        background: var(--color-primary);
    }
`;

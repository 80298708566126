/* eslint-disable no-unreachable */
import {
    AddIcon,
    Alert,
    CloseIcon,
    Dialog,
    ExclamationTriangleIcon,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import Content from "./Content";
import { DialogDeclareRemote } from "./index.style";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    _api_add_day_remote,
    _api_get_data_event_by_id,
    _api_update_day_remote,
} from "../../api";
import useToast from "../../../../hooks/useToast";
import { useToastRemote } from "../../store/useToastRemote";
import moment from "moment";
import { TIMES_SPLIT_DAY_TO_INDEX } from "../../constants";

const schema = yup.object().shape({
    start_date: yup
        .string()
        .nullable()
        .test(
            "start_date_before_end_date",
            "La date de début doit être avant la date de fin",
            function (value) {
                const { end_date } = this.parent;
                if (!value) {
                    return true;
                }
                return !end_date || value <= end_date;
            }
        ),
    end_date: yup
        .string()
        .nullable()
        .test(
            "end_date_is_required_when_filled",
            "Ce champ est obligatoire",
            function (value) {
                if (value !== null) {
                    return yup
                        .date()
                        .required("End date is required")
                        .isValidSync(value);
                }
                return true;
            }
        )
        .test(
            "end_date_after_start_date",
            "La date de début doit être antérieure à la date de fin",
            function (value) {
                const { start_date } = this.parent;
                if (!value) {
                    return true;
                }
                return !start_date || value >= start_date;
            }
        ),
});

const DialogDeclareRemoteDay = ({
    open = false,
    trigger,
    can_i_create_event = true,
    isForUpdatingDay = false,
    event_id = null,
    updateData,
    onClose = () => null,
    onAddSuccess = () => null,
}) => {
    const [openDialog, setOpen] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState({
        visible: false,
        content: "",
    });

    const ctx_toast = useToastRemote((state) => state);

    const queryClient = useQueryClient();

    // open dialog or not
    useEffect(() => {
        setOpen(open);
    }, [open]);

    // formulaire
    const methods = useForm({
        defaultValues: {
            start_date: null,
            end_date: null,
            start_time: 0,
            end_time: 1,
            reason: 1,
        },
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    // pour les valeurs par defaut
    useEffect(() => {
        if (updateData) {
            methods.setValue("start_date", updateData?.start_date);
            methods.setValue("end_date", updateData?.end_date);
            methods.setValue("reason", updateData?.reason ?? 1);
            if (isForUpdatingDay) {
                // je recoi du back PM-E etc..., faut formater
                methods.setValue(
                    "start_time",
                    TIMES_SPLIT_DAY_TO_INDEX[updateData?.start_time]
                );
                methods.setValue(
                    "end_time",
                    TIMES_SPLIT_DAY_TO_INDEX[updateData?.end_time]
                );
            } else {
                methods.setValue("start_time", updateData?.start_time ?? 0);
                methods.setValue("end_time", updateData?.end_time ?? 1);
            }
            methods.trigger();
        }
    }, [updateData]);

    // modifier le remote
    const { isLoading: is_updating_remote, mutate: api_update_remote } =
        useMutation((data) => _api_update_day_remote(data));
    // ajouter le remote
    const { isLoading, mutate } = useMutation((data) =>
        _api_add_day_remote(data)
    );

    // envoyer le formulaire
    const onSubmit = (data) => {
        console.log({ data });
        // return;
        if (isForUpdatingDay) {
            const data_with_event_id = {
                ...data,
                event_id: event_id,
            };
            api_update_remote(data_with_event_id, {
                onSuccess: (response) => {
                    if (response.success) {
                        // success alert
                        ctx_toast.setShow({
                            visible: true,
                            content: "Votre jour a bien été modifié",
                            level: "success",
                        });
                        // refetch the data
                        queryClient.refetchQueries(
                            "remote::get_all_remote_data"
                        );
                        // fermer la modale
                        closeModal();
                    } else {
                        setShowErrorAlert({
                            visible: true,
                            content: response.message,
                        });
                    }
                    return console.log({ response });
                },
            });
        } else {
            mutate(data, {
                onError: (error) => {
                    console.log({ error });
                },
                onSuccess: (response) => {
                    // return;
                    if (response.success) {
                        // success alert
                        ctx_toast.setShow({
                            visible: true,
                            content: "Votre jour a bien été déclaré",
                            level: "success",
                        });
                        const m_start_date = moment(data?.start_date).format(
                            "YYYY-MM-DD"
                        );
                        onAddSuccess({ start_date: m_start_date });
                        closeModal();
                    } else {
                        setShowErrorAlert({
                            visible: true,
                            content: response.message,
                        });
                    }
                    return console.log({ response });
                },
            });
        }
    };

    // fermer la modale
    const closeModal = () => {
        methods.reset();
        setShowErrorAlert({
            content: "",
            visible: false,
        });
        setOpen(false);
        onClose();
        return;
    };

    const watch_start_date = methods.watch("start_date");
    const watch_end_date = methods.watch("end_date");

    return (
        <DialogDeclareRemote
            open={openDialog}
            trigger={
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (can_i_create_event) {
                            return setOpen(true);
                        }
                    }}
                >
                    {trigger}
                </div>
            }
            header="Déclarer un jour"
            content={
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        {showErrorAlert.visible && (
                            <Alert
                                content={showErrorAlert.content}
                                icon={<ExclamationTriangleIcon />}
                                visible={showErrorAlert.visible}
                                dismissAction={{
                                    onClick: (e, i) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setShowErrorAlert({
                                            visible: false,
                                            content: "",
                                        });
                                    },
                                }}
                                danger
                                dismissible
                            />
                        )}
                        <Content />
                    </form>
                </FormProvider>
            }
            confirmButton={{
                content: "Enregistrer les modifications",
                flat: true,
                disabled:
                    !methods.formState.isValid ||
                    !watch_start_date ||
                    !watch_end_date,
                loading: isLoading || is_updating_remote,
                onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return methods.handleSubmit(onSubmit)();
                },
            }}
            cancelButton={{
                content: "Annuler",
                flat: true,
                onClick: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    return closeModal();
                },
            }}
            onCancel={(e) => {
                e.stopPropagation();
                e.preventDefault();
                return closeModal();
            }}
            headerAction={{
                icon: <CloseIcon size="large" />,
                onClick: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    return closeModal();
                },
            }}
        />
    );
};

export default DialogDeclareRemoteDay;

import { DrawShape20Regular } from "@fluentui/react-icons";
import { Flex, Text } from "@fluentui/react-northstar";
import moment from "moment";
import React from "react";
import { ROUTES } from "../../../../constants/routes";
import { get_symbol_currency } from "../../functions";
import { ColumnDate, ColumnLinkTo, ColumnTitle } from "../../styles/Rows";
import Counter from "../Badges/Counter";
import Status, { LEVELS_BACKEND } from "../Badges/Status";
import TotalExpense from "../Texts/TotalExpense";
import StatusActionsDependRole, {
    STATUS_ROLE,
} from "../Badges/StatusActionsDependRole";
import { useMediaQuery } from "react-responsive";
import { SIZES } from "../../../../constants/responsive";

const Content = ({ content }) => {
    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileS})`,
    });
    const isMobileM = useMediaQuery({
        query: "(max-width: 600px)",
    });

    const isMobile = isMobileS || isMobileM;

    return (
        <Flex column>
            {content?.map((el, index) => {
                const currency_symbol = get_symbol_currency(el?.currency);
                return (
                    <Flex
                        fill
                        space="between"
                        vAlign="center"
                        styles={{
                            borderBottom: "1px solid #D1D1D1",
                            paddingBlock: "8px",
                            paddingRight: "8px",
                        }}
                        key={`ROW_${index}`}
                    >
                        {/* date */}
                        {!isMobile && (
                            <Flex
                                styles={{
                                    flex: 1.5,
                                    maxWidth: "50px",
                                }}
                            >
                                <ColumnDate>
                                    {moment(el?.created_date).format("DD/MM")}
                                </ColumnDate>
                            </Flex>
                        )}
                        <Flex vAlign="center" styles={{ flex: 4 }}>
                            <ColumnLinkTo
                                to={ROUTES.expenses.detail_status_expense.custom_route(
                                    el?.id
                                )}
                            >
                                <ColumnTitle truncated>{el?.name}</ColumnTitle>
                            </ColumnLinkTo>
                            {!isMobile && (
                                <Flex
                                    styles={{
                                        // flex: 1,
                                        Width: "30px",
                                        maxWidth: "200px",
                                    }}
                                >
                                    <Counter>{el?.expenses_count}</Counter>
                                </Flex>
                            )}
                        </Flex>
                        <Flex vAlign="center" gap="gap.large">
                            {!isMobile && (
                                <Flex
                                    styles={{
                                        width: "150px",
                                    }}
                                    gap="gap.small"
                                >
                                    {el?.client?.name && (
                                        <>
                                            <DrawShape20Regular />
                                            <Text>{el?.client?.name}</Text>
                                        </>
                                    )}
                                </Flex>
                            )}
                        </Flex>
                        {!isMobile && (
                            <Flex
                                styles={{
                                    flex: 6,
                                    maxWidth: "100px",
                                }}
                            >
                                <StatusActionsDependRole
                                    role={STATUS_ROLE.employee}
                                    status={el?.status?.value}
                                />
                            </Flex>
                        )}
                        {!isMobile && (
                            <Flex
                                styles={{
                                    flex: 2,
                                    width: "100px",
                                }}
                                hAlign="end"
                            >
                                <TotalExpense
                                    content={`${el?.amount_total} ${currency_symbol}`}
                                />
                            </Flex>
                        )}
                        <Flex styles={{ flex: 1.5 }} hAlign="end">
                            <TotalExpense
                                content={`${
                                    el?.amount_approved ?? "--,-- "
                                } ${currency_symbol}`}
                                level={LEVELS_BACKEND[el?.status?.value]}
                            />
                        </Flex>
                    </Flex>
                );
            })}
        </Flex>
    );
};

export default Content;

import { useState } from "react";
import axios from "axios";
import { useAuthStore } from "../stores/useAuthStore";

let ourRequest;
export const useUploadForm = (url, cb, cbAborted) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [progress, setProgress] = useState(0);

    const user_access = useAuthStore.getState().infos;

    const uploadForm = async (formData) => {
        ourRequest = axios.CancelToken.source();
        setIsSuccess(false);

        await axios
            .post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${user_access?.access}`,
                },
                withCredentials: true,
                cancelToken: ourRequest.token,
                // signal: controller.signal,
                onUploadProgress: (progressEvent) => {
                    const progress =
                        50 + (progressEvent.loaded / progressEvent.total) * 50;
                    setProgress(progress);
                },
                onDownloadProgress: (progressEvent) => {
                    const progress =
                        50 + (progressEvent.loaded / progressEvent.total) * 50;
                    setProgress(progress);
                },
            })
            .then(
                (data) => {
                    setIsSuccess(true);
                    cb(data);
                },
                (error) => {
                    cbAborted();
                }
            );
    };

    const abortUpload = () => {
        setIsSuccess(false);
        return ourRequest.cancel("canceled by user !");
    };

    const resetProgress = () => {
        setProgress(0);
        return;
    };

    return {
        isSuccess,
        progress,
        resetProgress,
        uploadForm,
        controller: abortUpload,
    };
};

import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import PrivateRoute from "./components/auth/PrivateRoute";
import PublicRoute from "./components/auth/PublicRoute";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import CallbackMs from "./pages/CallbackMs";
import UpdatePassword from "./pages/UpdatePassword";
import AddCollaborator from "./pages/Dashboard/AddCollaborator";

import Profile from "./pages/OnBoarding/OnBordingProfile";
import RedirectToLoginIfConnected from "./components/auth/RedirectToLoginIfConnected";
// import ManageDocumentPage from "./pages/ManageDocument";
import HomePage from "./pages/Greeting/HomePage";
import Main from "./pages/OnBoarding/onboardingPage/Main";
import DashboardTasks from "./pages/DashboardTasks";
import Annuaire from "./pages/Annuaire";
import NotFound from "./pages/404";
import AddEmployee from "./features/addEmployee";

import StepGeneralInformation from "./features/addEmployees/components/StepGeneralInformation";
import StepRole from "./features/addEmployees/components/StepRole";
import StepRessources from "./features/addEmployees/components/StepRessources";
import StepTasksAndModal from "./features/addEmployees/components/StepTasksAndModal";

import ShowProfil from "./pages/Profil/ForCollaborator/ShowProfil/ShowProfil";

import ModifyProfileEmployee from "./pages/Profil/employee/EditProfil";
import EditProfil from "./pages/Profil/ForCollaborator/EditProfil/EditProfil";

// import Onboarding from "./features/onboarding";

import Manager from "./pages/Profil/manager/Manager";

import HrDocuments from "./features/hr_documents";

// import Home from "./features/home/Home";

import { ROUTES } from "./constants/routes";

import Expenses from "./features/expenses";
import ExpensesHome from "./features/expenses/components/ExpenseHome";
import ExpensesDetail from "./features/expenses/components/ExpensesDetail";
import ExpensesAddCategory from "./features/expenses/components/ExpensesAddCategory";
import ExpensesReportHistory from "./features/expenses/components/ExpensesReportHistory";
import ExpensesDetailReport from "./features/expenses/pages/ExpensesDetailReport";
import ExpensesAddForm from "./features/expenses/components/ExpensesAddForm";
import ExpensesUpdateForm from "./features/expenses/components/ExpensesUpdateForm";
import ExpensesManagerMyTeams from "./features/expenses/pages/ExpensesManagerMyTeams";
import ExpensesDetailReportManager from "./features/expenses/pages/ExpensesManagerMyTeams/ExpensesDetailReportManager";
import ExpensesReportHistoryTeams from "./features/expenses/pages/ExpensesManagerMyTeams/ExpensesReportHistoryTeams";

import Leave from "./features/leave/components/absences/Parent";

// import TimeReporting from "./features/time_reporting/routes";
// import TimeReportingHome from "./features/time_reporting/pages/home";
import TimeReportingManager from "./features/time_reporting/pages/manager";
import WeekDetail from "./features/time_reporting/pages/manager/WeekDetail";
import ManagerValidationTimeReporting from "./features/validate_time_reporting";
import { Loader } from "@fluentui/react-northstar";
import EmployeeFolder from "./features/employee_folder";
import NewDashboard from "./features/new_dashboard";
import LoginPage from "./pages/Login/LoginPage";
import ManagerValidationLeaveRequest from "./features/validate_leave_request";
import Timeline from "./components/Timeline";
import PreOnboarding from "./features/pre_onboarding";
import WelcomePreonboarding from "./features/pre_onboarding/components/WelcomePreonboarding";
// import TimeReporting from "./features/new_time_reporting";
import Components from "./features/new_time_reporting/components";
import TimeReportingHome from "./features/new_time_reporting/pages/Home";
import Week from "./features/new_time_reporting/pages/Week";
import AnnualTracking from "./features/new_time_reporting/components/AnnualTraking";
import MyTeam from "./features/new_time_reporting/components/MyTeam";
import LoaderPage from "./components/loaders/LoaderPage";
import AddEmployees from "./features/addEmployees";
import StepContract from "./features/addEmployees/components/StepContract";
import Denied from "./pages/Denied";
import RenderMenu from "./utils/PdfReader";
import { new_fetcher } from "./utils/fetcher";
import { BASE_URL_API } from "./constants";
import GeneralDetailTask from "./pages/DashboardTasks/Pages/GeneralDetailTask";
import ExpensesAccountingManagerHome from "./features/expenses/pages/ExpensesAccountingManagerHome/ExpensesAccountingManagerHome";
import ExpensesDetailReportAccountingManager from "./features/expenses/pages/ExpensesManagerMyTeams/ExpensesDetailReportAccountingManager";
import RemoteJob from "./features/remote_job";
import Toast from "./components/Alerts";
import { useToastGlobal } from "./stores/useToastGlobal";
import ForgetPassword from "./features/forgetPassword";
import TimeReportingSociety from "./features/new_time_reporting/components/TimeRertingSociety";
import WeekRH from "./features/new_time_reporting/pages/WeekRH";
import AccessHR from "./components/auth/AccessHR";
import PreonboardingTaskDetail from "./features/pre_onboarding/components/PreonboardingTaskDetail";
import NewOnboarding from "./pages/New_Onboarding";
import ToStartDetail from "./pages/New_Onboarding/components/ToStartDetail";
import Suivi from "./pages/New_Onboarding/components/suivi";
import { PAGES } from "./hooks/useAccessTo";
import { ROLES_CODE } from "./constants/roles";
import TeamsConnection from "./features/teamsConnection/TeamsConnection";
import DelegationRoles from "./features/delegation_roles";
import DelegationHome from "./features/delegation_roles/pages/Home/DelegationHome";
import DelegationForm from "./features/delegation_roles/pages/DelegationForm/DelegationForm";

// import ProfileRh from "./pages/Profil/Rh/ProfileRh";
// import Modifier from "./pages/Profil/collabarator/modify";

const TimeReporting = React.lazy(() => import("./features/new_time_reporting"));

function App() {
    const toastConfig = useToastGlobal();
    // protected route HR
    return (
        <>
            {/* global Toast */}
            <Toast
                {...toastConfig}
                onStatusChange={toastConfig.onChangeStatus}
            />
            <BrowserRouter>
                <Routes>
                    {/* DELEGATION */}
                    <Route
                        path={ROUTES.delegation_roles.home}
                        element={
                            <Suspense fallback={<LoaderPage />}>
                                <PrivateRoute>
                                    <DelegationRoles />
                                </PrivateRoute>
                            </Suspense>
                        }
                    >
                        <Route index path="" element={<DelegationHome />} />
                        <Route
                            index
                            path="assign/:type_form"
                            element={<DelegationForm />}
                        />
                    </Route>
                    {/* TEAMS SSO */}
                    <Route path={ROUTES.teams} element={<TeamsConnection />} />
                    {/* EMPLOYEE FOLDER */}
                    <Route
                        path={ROUTES.employee_folder.route}
                        element={
                            <PrivateRoute>
                                <EmployeeFolder />
                            </PrivateRoute>
                        }
                    />
                    {/* TIME REPORTING */}
                    <Route
                        path={ROUTES.time_reporting.home}
                        element={
                            <Suspense fallback={<LoaderPage />}>
                                <PrivateRoute>
                                    <TimeReporting />
                                </PrivateRoute>
                            </Suspense>
                        }
                    >
                        <Route index path="" element={<TimeReportingHome />} />
                        <Route path="components" element={<Components />} />

                        <Route
                            path={ROUTES.time_reporting.week}
                            element={<Week />}
                        />
                        <Route
                            path={ROUTES.time_reporting.week_rh}
                            element={
                                <AccessHR>
                                    <WeekRH />
                                </AccessHR>
                            }
                        />
                        <Route
                            path={ROUTES.time_reporting.annual_tracking}
                            element={<AnnualTracking />}
                        />
                        <Route
                            path={ROUTES.time_reporting.team_tracking}
                            element={<MyTeam />}
                        />
                        <Route
                            path={ROUTES.time_reporting.entreprise}
                            element={
                                <AccessHR>
                                    <TimeReportingSociety />
                                </AccessHR>
                            }
                        />
                    </Route>
                    {/* REMOTE JOB */}
                    <Route
                        path={ROUTES.remote_job.home}
                        element={
                            <PrivateRoute>
                                <RemoteJob />
                            </PrivateRoute>
                        }
                    />
                    {/* note de frais, expenses */}
                    <Route
                        path={ROUTES.expenses.home}
                        element={
                            <PrivateRoute>
                                <Expenses />
                            </PrivateRoute>
                        }
                    >
                        <Route index path="" element={<ExpensesHome />} />
                        {/* detail expense on creation. */}
                        <Route
                            path={ROUTES.expenses.detail.route}
                            element={<ExpensesDetail />}
                        />
                        {/* add expense category page */}
                        <Route
                            path={ROUTES.expenses.add_expense.route}
                            element={<ExpensesAddCategory />}
                        />
                        {/* index route, redirect to the right UPDATE form classic or other */}
                        <Route
                            path={ROUTES.expenses.update_form.route}
                            element={<ExpensesUpdateForm />}
                        />
                        {/* index route, redirect to the right ADD form classic or other */}
                        <Route
                            path={ROUTES.expenses.add_form.route}
                            element={<ExpensesAddForm />}
                        />
                        {/* history */}
                        <Route
                            path={ROUTES.expenses.my_history.route}
                            element={<ExpensesReportHistory />}
                        />
                        {/* detail of any report */}
                        <Route
                            path={ROUTES.expenses.detail_status_expense.route}
                            element={<ExpensesDetailReport />}
                        />
                        {/* home page of manager */}
                        <Route
                            path={ROUTES.expenses.manager.home.route}
                            element={<ExpensesManagerMyTeams />}
                        />
                        {/* detail any report manager side */}
                        <Route
                            path={ROUTES.expenses.manager.detail.route}
                            element={<ExpensesDetailReportManager />}
                        />
                        {/* detail any report accounting manager side */}
                        <Route
                            path={
                                ROUTES.expenses.accounting_manager.detail.route
                            }
                            element={<ExpensesDetailReportAccountingManager />}
                        />
                        {/* history of manager list by years */}
                        <Route
                            path={ROUTES.expenses.manager.history.route}
                            element={<ExpensesReportHistoryTeams />}
                        />
                        {/* ACCOUNTING MANAGER */}
                        <Route
                            path={ROUTES.expenses.accounting_manager.home.route}
                            element={<ExpensesAccountingManagerHome />}
                        />
                    </Route>

                    {/* add collaborateur with new design of fluent ! */}
                    <Route
                        path={ROUTES.employee_add}
                        element={<AddEmployees />}
                    >
                        <Route
                            index
                            path=""
                            element={<StepGeneralInformation />}
                        />
                        <Route
                            path="first"
                            element={<StepGeneralInformation />}
                        />
                        <Route path="second" element={<StepContract />} />
                        <Route path="third" element={<StepRole />} />
                        <Route path="fourth" element={<StepRessources />} />
                        <Route path="fifth" element={<StepTasksAndModal />} />
                    </Route>
                    <Route path={ROUTES.annuaire} element={<Annuaire />} />

                    {/* forget password */}
                    <Route
                        path={ROUTES.forget_password}
                        element={<ForgetPassword />}
                    />
                    {/* en of forget password */}
                    <Route
                        path={ROUTES.task_manager}
                        element={
                            <PrivateRoute>
                                <DashboardTasks />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/dashboard/tasks/:id_task/user/:id_user/detail"
                        element={
                            // <PrivateRoute>
                            <GeneralDetailTask />
                            // </PrivateRoute>
                        }
                    />

                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                {/* <Home /> */}
                                <NewDashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/hr/documents"
                        element={
                            <PrivateRoute>
                                <HrDocuments />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/"
                        element={
                            <PublicRoute>
                                <Login />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <PublicRoute>
                                <Login />
                                {/* <LoginPage /> */}
                            </PublicRoute>
                        }
                    />

                    {/* preonboarding  */}
                    <Route path="/preonboarding" element={<PreOnboarding />} />
                    <Route
                        path="/welcome-preonboarding"
                        element={<WelcomePreonboarding />}
                    />

                    {/* end preonboarding */}
                    <Route path="/callback_ms" element={<CallbackMs />} />
                    <Route
                        path={ROUTES.detail_task}
                        element={
                            // <PublicRoute>
                            <PreonboardingTaskDetail />
                            // </PublicRoute>
                        }
                    />
                    <Route
                        path="/updatePassword"
                        element={
                            <RedirectToLoginIfConnected>
                                <UpdatePassword />
                                {/* <PreOnboarding /> */}
                            </RedirectToLoginIfConnected>
                        }
                    />
                    <Route
                        path="/timesheet_approval/:hash"
                        element={
                            <RedirectToLoginIfConnected>
                                <ManagerValidationTimeReporting />
                            </RedirectToLoginIfConnected>
                        }
                    />
                    <Route
                        path="/leave_request_approval/:hash"
                        element={
                            // <RedirectToLoginIfConnected>
                            <ManagerValidationLeaveRequest />
                            // </RedirectToLoginIfConnected>
                        }
                    />
                    {/* LEAVE */}
                    <Route
                        path="/leave"
                        element={
                            <PrivateRoute>
                                <Leave />
                            </PrivateRoute>
                        }
                    />
                    {/* new onboarding page  */}
                    <Route
                        path={ROUTES.onboarding.home}
                        element={
                            <PrivateRoute hasAccess={[ROLES_CODE.hr]}>
                                <NewOnboarding />
                            </PrivateRoute>
                        }
                    />
                    {/* préonboarding task detail page  */}
                    <Route
                        path={ROUTES.onboarding.detail}
                        element={
                            <PrivateRoute hasAccess={[ROLES_CODE.hr]}>
                                <ToStartDetail />
                            </PrivateRoute>
                        }
                    />
                    {/* suivi page  */}
                    <Route
                        path={ROUTES.onboarding.suivi}
                        element={
                            <PrivateRoute hasAccess={[ROLES_CODE.hr]}>
                                <Suivi />
                            </PrivateRoute>
                        }
                    />
                    <Route path="/denied" element={<Denied />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;

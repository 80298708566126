import React from "react";
import { string } from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import DialogUpdateTaskOnModifyTemplate from "./DialogUpdateTaskOnModifyTemplate";
import { Dialog } from "@fluentui/react-northstar";
import { DialogStyle } from "../style";

const FormDialogUpdateManualTaskTemplate = ({
    setTemplateTasks,
    default_task,
    setOpenDialogUpdateTask,
    openDialogUpdateTask,
    methods: parentMethod,
}) => {
    const methods = useForm({
        mode: "onChange",
        defaultValues: {},
    });

    /**
     *
     * Modification d'une tâche !!
     */
    const onSubmit = (data) => {
        const current_method = methods.getValues();
        const {
            id,
            name,
            description,
            category_id,
            duedate_duration,
            duedate_operator,
            duedate_event,
            task_to_validate,
            document_action,
            saved_files,
            document_required,
            assigned_to,
            validator,
        } = data;

        // if (saved_files?.length === 0 && document_required) {
        // 	methods.setError(
        // 		"document_action",
        // 		{
        // 			type: string,
        // 			message:
        // 				"in this case the document is required, please add a document.",
        // 		},
        // 		{
        // 			shouldFocus: true,
        // 		}
        // 	);
        // 	return;
        // }
        // save this into the state
        const format_data = {
            id,
            name,
            description,
            category_id,
            duedate_duration,
            duedate_operator,
            duedate_event: duedate_event?.id,
            task_to_validate,
            validator_type: validator
                ? validator?.id < 0
                    ? validator?.id
                    : -4
                : null,
            validator_user_id: validator
                ? validator?.id < 0
                    ? 0
                    : validator?.id
                : null,
            assigned_to_type: assigned_to?.id < 0 ? assigned_to?.id : -4, // les 3 premieres, et le 4 c'est pour les autres collaborateurs.
            assigned_to_user_id: assigned_to?.id < 0 ? 0 : assigned_to?.id, // id user choisit sauf les 3 premiers c'est 0.
            document_action,
            new_doc_ids: saved_files.map((el) => el.id),
            temp: data,
            manual: true,
        };
        const tasks = parentMethod.getValues("tasks");
        const new_tasks = tasks?.map((el) => {
            if (el?.id === data?.id) {
                return format_data;
            }
            return el;
        });
        parentMethod.setValue("tasks", new_tasks);
        setTemplateTasks(new_tasks);
        hideDialog();
    };

    const hideDialog = () => {
        setOpenDialogUpdateTask(false);
        methods.reset();
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <DialogStyle
                    open={openDialogUpdateTask}
                    header={"Modifier une tâche"}
                    confirmButton={"Modifier la tâche"}
                    onConfirm={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        methods.handleSubmit(onSubmit)();
                    }}
                    onCancel={hideDialog}
                    cancelButton={"Annuler"}
                    content={
                        <DialogUpdateTaskOnModifyTemplate
                            default_task={default_task}
                        />
                    }
                    styles={{ width: "764px" }}
                />
            </form>
        </FormProvider>
    );
};

export default FormDialogUpdateManualTaskTemplate;

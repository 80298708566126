import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import UserSelection from "../../Cards/UserSelection";

const DialogUpdateHrContent = ({ list_hr = [], default_hr, setSelectedRh }) => {
    return (
        <Flex fill column>
            <SubHeader content={"Liste des personnes avec le rôle de RH"} />
            <Wrapper column>
                {list_hr?.map((hr) => {
                    return (
                        <UserSelection
                            avatar={hr?.avatar}
                            fullName={hr?.first_name + " " + hr?.last_name}
                            jobTitle={hr?.job_title ?? "pas de KEY job_title"}
                            selected={default_hr?.email === hr?.email}
                            onClick={() => setSelectedRh(hr)}
                        />
                    );
                })}
            </Wrapper>
        </Flex>
    );
};

export default DialogUpdateHrContent;

const Wrapper = styled(Flex)`
    height: 300px;
    overflow-y: scroll;
    border-bottom: 1px solid #d1d1d1;
`;

const SubHeader = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    padding-block: 16px;
    border-bottom: 1px solid #d1d1d1;
`;

import { Flex, Loader, Text } from "@fluentui/react-northstar";
import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import * as animationData from "../../../../assets/lotties/ocr_loading.json";

const DialogLoaderUploadingOCR = () => {
    return (
        <Flex gap="gap.large" hAlign="center" vAlign="center" column>
            <Flex column>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                        },
                    }}
                    height={100}
                    width={100}
                />
                <LoadingText content={"Chargement en cours ..."} />
            </Flex>
            <TextDescription
                content={
                    "Nous tentons de récupérer des informations sur le justificatif, cela peut prendre jusqu’à 5 secondes"
                }
            />
        </Flex>
    );
};

export default DialogLoaderUploadingOCR;

const LoadingText = styled(Text)`
    color: #424242;
    /* Large/400 */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;

const TextDescription = styled(Text)`
    max-width: 435px;
    color: #616161;
    text-align: center;
    /* Medium/400 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

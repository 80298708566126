import React from "react";
import {
    Avatar,
    Button,
    EditIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../../../constants/responsive";

const CardUser = ({
    title = "",
    full_name = "",
    job_title = "",
    hideTitle = false,
    avatar = "",
    canModify = false,
    onClick = () => console.log("clicked"),
    onModify = () => console.log("modify"),
}) => {
    return (
        <Flex column gap="gap.small" styles={{ marginBottom: "16px" }}>
            <Flex space="between" vAlign="center">
                {!hideTitle ? <Title content={title} /> : <Title content="" />}
                {!hideTitle && canModify && (
                    <Button
                        primary
                        text
                        iconOnly
                        icon={<EditIcon />}
                        flat
                        onClick={onModify}
                    />
                )}
            </Flex>
            <WrapperCard gap="gap.medium" vAlign="center" onClick={onClick}>
                <Flex>
                    <Avatar size="large" name={full_name} image={avatar} />
                </Flex>
                <Flex
                    column
                    styles={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <FullName truncated content={full_name} />
                    <JobTitle truncated content={job_title} />
                </Flex>
            </WrapperCard>
        </Flex>
    );
};

export default CardUser;

const JobTitle = styled(Text)`
    color: #616161;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`;

const FullName = styled(Text)`
    color: #242424;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

const WrapperCard = styled(Flex)`
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    height: 72px;
    padding: 16px 20px;
    background: #ffffff;
    transition: background-color 0.2s;
    :hover {
        transition: background-color 0.2s;
        /* border: 1px solid #9299f7; */
        background: #f5f5f5;
        cursor: pointer;
        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
            0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
        border-radius: 4px;
        ${FullName} {
            color: #4f52b2;
        }
    }
    @media ${DEVICE.laptop} {
        width: 240px;
    }
    @media ${DEVICE.mobileS} {
        width: 210px;
    }
`;

const Title = styled(Text)`
    color: #242424;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
`;

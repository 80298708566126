import { Box, Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import CustomBreadCrumb from "../../../../components/breadcrumb";
import {
    CalendarPerson20Regular,
    CalendarStar20Regular,
} from "@fluentui/react-icons";

import { BREADCRUMB_LEAVE } from "../../constants";
import { Container, ContainerFluid } from "../../../../common/styles";
import MyAbsence from "./MyAbsence";
import ManagerAbsence from "./ManagerAbsence";
import RhAbsence from "./RhAbsence";
import { useQuery } from "react-query";
import { getMyInformations } from "../../../../api/user";
import { ROLES_CODE } from "../../../../constants/roles";
import {
    api_holidays_list,
    api_team_calendar_detail,
    manager_pending_leaves,
} from "../../api";

import { Tab, TabList } from "@fluentui/react-components";
import { useSearchParams } from "react-router-dom";
import LoaderPage from "../../../../components/loaders/LoaderPage";
import { isDelegatedToRole } from "../../../../utils/permissions";

const TABS_LEAVE = {
    absences: "Mes absences",
    teamCalendar: "Mon équipe",
    societyCalendar: "Entreprise",
    // soldeAjustement: "Ajustement des soldes",
};

const TABS_LEAVE_ARRAY = [
    TABS_LEAVE.absences,
    TABS_LEAVE.teamCalendar,
    TABS_LEAVE.societyCalendar,
];

const _renderTab = (
    activeTab,
    isRH,
    isManager,
    subHeader,
    holiday_list
    // teamData,
    // loadingTeamData,
    // fetchingTeamData,
    // refetchTeamCall
) => {
    switch (activeTab) {
        case TABS_LEAVE.absences:
            return (
                <MyAbsence
                    holidayList={holiday_list}
                    isRH={isRH}
                    subHeader={0}
                />
            );
        case TABS_LEAVE.teamCalendar:
            return (
                <ManagerAbsence
                    isManager={true}
                    subHeader={1}
                    holidayList={holiday_list}
                    // teamData={teamData}
                    // loadingTeamData={loadingTeamData}
                    // fetchingTeamData={fetchingTeamData}
                    // refetchTeamCall={refetchTeamCall}
                />
            );
        case TABS_LEAVE.societyCalendar:
            return (
                <RhAbsence
                    isRH={isRH}
                    isManager={isManager}
                    subHeader={2}
                    holidayList={holiday_list}
                />
            );
        // case TABS_LEAVE.soldeAjustement:
        //     return <SoldeAjustement isRH={isRH} subHeader={3} />;
        default:
            break;
    }
};

const Parent = () => {
    const [params, setParams] = useSearchParams();
    const [cleanParams, setCleanParams] = useSearchParams();

    const [activeTab, setActiveTab] = React.useState(
        TABS_LEAVE_ARRAY[params.get("tab")] ?? TABS_LEAVE.absences
    );
    const [subHeader, setSubHeader] = React.useState(0);

    useEffect(() => {
        document.title = "Gestionnaire des absences";
    }, []);

    // holidays list
    const {
        data: holiday_list,
        isLoading: isLoadingHolidayList,
        isFetching: isFetchingHolidayList,
    } = useQuery(["holidays_list"], () => api_holidays_list(), {
        onSuccess: () => {
            return;
        },
    });

    const {
        isLoading: loadingMyInformation,
        isFetching: fetchingMyInformation,
        data: myInfo,
    } = useQuery(["getMyInformations"], () => getMyInformations());

    // team data

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const {
        data: teamData,
        isLoading: loadingTeamData,
        isFetching: fetchingTeamData,
        refetch: refetchTeamCall,
    } = useQuery(
        ["myCalendarTeam", currentMonth, currentYear],
        () => api_team_calendar_detail(currentMonth, currentYear),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
        }
    );

    const {
        data: teamBadge,
        isLoading: isLoadingTeamBadge,
        isFetching: isFetchingTeamBadge,
        refetch: refetchTeamBadge,
    } = useQuery(["teamBadge"], () => manager_pending_leaves(), {
        onSuccess: () => {},
    });

    if (isLoadingTeamBadge || isFetchingTeamBadge) {
        <Loader size="smallest" />;
    }

    if (isLoadingHolidayList) {
        return <LoaderPage />;
    }

    const Roles_management = myInfo?.user?.roles;
    const isManager = Roles_management?.map((el) => el?.code).includes(
        ROLES_CODE?.manager
    );
    const isRH = Roles_management?.map((el) => el?.code).includes(
        ROLES_CODE?.hr
    );
    // voir s'il est delegue
    const isDelegatedForLeave = isDelegatedToRole(["leave"]);

    return (
        <ContainerFluid styles={{ minHeight: "100vh" }} fill column>
            <CustomBreadCrumb navList={BREADCRUMB_LEAVE} />
            {/* tabs */}
            <Box
                style={{
                    margin: "8px 0px 0px 0px",
                }}
            >
                <Container>
                    <TabListStyle
                        defaultSelectedValue={activeTab}
                        appearance="transparent"
                    >
                        <Tab
                            value={TABS_LEAVE.absences}
                            icon={<CalendarStar20Regular />}
                            content={TABS_LEAVE.absences}
                            text
                            onClick={() => {
                                setActiveTab(TABS_LEAVE.absences);
                                setSubHeader(0);
                                setCleanParams("");
                                return;
                            }}
                        />
                        {/* si manager ou bien ils lui ont attribués un ROLE manager */}
                        {(isManager || isDelegatedForLeave) && (
                            <Tab
                                icon={<CalendarPerson20Regular />}
                                value={TABS_LEAVE.teamCalendar}
                                text
                                onClick={() => {
                                    setActiveTab(TABS_LEAVE.teamCalendar);
                                    setSubHeader(1);
                                    // refetchTeamCall();
                                    setCleanParams("");
                                    return;
                                }}
                            >
                                <Flex gap="gap.small">
                                    {TABS_LEAVE.teamCalendar}
                                    {activeTab === TABS_LEAVE.teamCalendar ? (
                                        <TeamBadge
                                            content={
                                                teamBadge
                                                    ? teamBadge?.leaves_to_approve
                                                    : "0"
                                            }
                                        />
                                    ) : (
                                        <TeamBadge
                                            styles={{
                                                backgroundColor:
                                                    "#d1d1d1 !important",
                                            }}
                                            content={
                                                teamBadge
                                                    ? teamBadge?.leaves_to_approve
                                                    : "0"
                                            }
                                        />
                                    )}
                                </Flex>
                            </Tab>
                        )}

                        {isRH && (
                            <Tab
                                value={TABS_LEAVE.societyCalendar}
                                icon={<CalendarPerson20Regular />}
                                content={TABS_LEAVE.societyCalendar}
                                text
                                onClick={() => {
                                    setActiveTab(TABS_LEAVE.societyCalendar);
                                    setSubHeader(2);
                                    setCleanParams("");
                                }}
                            />
                        )}
                    </TabListStyle>
                </Container>
            </Box>
            {/* render depending current tab */}
            <Flex
                style={{
                    backgroundColor: "#f5f5f5",
                    flex: 1,
                }}
            >
                {_renderTab(
                    activeTab,
                    isRH,
                    isManager,
                    subHeader,
                    holiday_list,
                    teamData,
                    loadingTeamData,
                    fetchingTeamData,
                    refetchTeamCall
                )}
            </Flex>
        </ContainerFluid>
    );
};

export default Parent;

export const TabListStyle = styled(TabList)`
    overflow-x: scroll;
    width: 100%;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

const ButtonToolbar = styled(Button)`
    ${({ active }) =>
        active === "true"
            ? css`
                  background-color: #ebebeb;
              `
            : ""};
`;

export const TeamBadge = styled(Text)`
    background-color: #ffb900;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white;
    text-align: center;
    margin-left: 5px;
`;

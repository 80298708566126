import { useEffect, useRef } from "react";
import {
    PSPDFKIT_LICENSE_KEY,
    PSPDFKIT_DOCUMENT_ENGINE_URL,
} from "../constants/index";

export default function PdfViewerComponent(props) {
    const containerRef = useRef(null);
    useEffect(() => {
        const container = containerRef.current; // This `useRef` instance will render the PDF.

        let PSPDFKit, instance;

        (async function () {
            PSPDFKit = await import("pspdfkit");

            PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.
            const initialViewState = new PSPDFKit.ViewState({
                readOnly: !props.enableAnnotation,
            });

            instance = await PSPDFKit.load({
                licenseKey: PSPDFKIT_LICENSE_KEY,
                container,
                serverUrl: PSPDFKIT_DOCUMENT_ENGINE_URL,
                documentId: props.documentId,
                authPayload: { jwt: props.jwt },
                instant: false,

                // The document to open.
                // document: props.document,
                // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
                baseUrl: `${window.location.protocol}//${window.location.host}/`,
                initialViewState: initialViewState,
            });
        })();

        return () => PSPDFKit && PSPDFKit.unload(container);
    }, [props.enableAnnotation, props.documentId, props.jwt]);

    // This div element will render the document to the DOM.
    return (
        <div
            id="pspdfkit"
            ref={containerRef}
            style={{ width: "100%", height: "100vh" }}
        />
    );
}

import React from "react";
import styled from "styled-components";
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
} from "@fluentui/react-components";

import Rows from "./Rows";
import { Flex, Text, UserFriendsIcon } from "@fluentui/react-northstar";

const AccordionList = ({ panels, query = "" }) => {
    return (
        <Flex column gap="gap.small">
            {query !== "" && panels?.length > 0 && (
                <Flex vAlign="center" hAlign="center">
                    <TextCategory>Services</TextCategory>
                </Flex>
            )}
            <Accordion
                multiple={true}
                defaultOpenItems={panels?.map((_, i) => i)}
                collapsible={true}
            >
                {panels?.map((el, index) => {
                    return (
                        <AccordionItem value={index} key={index}>
                            <AccordionHeader
                                expandIconPosition="end"
                                style={{
                                    backgroundColor: "#E8EBFA",
                                    paddingInline: "16px",
                                }}
                            >
                                <Flex gap="gap.small">
                                    <TextTitle>{el.title}</TextTitle>
                                    <Flex vAlign="center" gap="gap.smaller">
                                        <UserFriendsIcon outline size="small" />
                                        <TextCountUsers>
                                            {el?.users_count}
                                        </TextCountUsers>
                                    </Flex>
                                </Flex>
                            </AccordionHeader>
                            <AccordionPanel
                                style={{ margin: 0, paddingInline: "16px" }}
                            >
                                {el.data.map((el, index) => {
                                    return <Rows key={index} data={el} />;
                                })}
                            </AccordionPanel>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </Flex>
    );
};

export default AccordionList;

export const TextCategory = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #605e5c;
    margin-top: 8px;
`;

const TextTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: #424242;
`;

const TextCountUsers = styled(Flex)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #424242;
`;

import { Button, CloseIcon } from "@fluentui/react-northstar";
import { Ball, Buttons, Hr, TooltipTitle, WrapperBalls, WrapperBallsAndNext, WrapperTitle, WrapperTooltip } from "./style";
import { steps } from "./steps";
import {
    ChevronRight20Regular,
    ChevronLeft20Regular,
} from "@fluentui/react-icons";

export const Tooltips = ({
    continuous,
    index,
    step,
    size,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
}) => {
    return (
        <WrapperTooltip {...tooltipProps}>
            {step.title && (
                <WrapperTitle>
                    <TooltipTitle>{step.title}</TooltipTitle>{" "}
                    <Button
                        {...closeProps}
                        iconOnly
                        text
                        icon={<CloseIcon styles={{ color: "white" }} />}
                    />
                </WrapperTitle>
            )}
            <div>{step.content}</div>
            <Hr />
            <WrapperBallsAndNext>
                <div style={{ minWidth: "30px" }}>
                    {index > 0 && (
                        <Button
                            {...backProps}
                            id="back"
                            iconOnly
                            text
                            icon={
                                <ChevronLeft20Regular
                                    style={{ color: "white" }}
                                />
                            }
                        />
                    )}
                </div>
                <WrapperBalls>
                    {new Array(steps.length).fill("").map((e, i) => {
                        return <Ball $active={i === index}>•</Ball>;
                    })}
                </WrapperBalls>
                <div style={{ minWidth: "30px" }}>
                    {index < steps.length - 1 && (
                        <Button
                            {...primaryProps}
                            id="next"
                            iconOnly
                            text
                            icon={
                                <ChevronRight20Regular
                                    style={{ color: "white" }}
                                />
                            }
                        />
                    )}
                </div>
            </WrapperBallsAndNext>
            <Buttons>
                <Button
                    {...closeProps}
                    id="close"
                    content={
                        index === steps.length - 1
                            ? "Commencez votre déclaration !"
                            : "Quitter le tutoriel"
                    }
                    text
                    size="small"
                    inverted
                    styles={{
                        border: 0,
                        textDecoration: "underline",
                    }}
                />
            </Buttons>
        </WrapperTooltip>
    );
};
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import {
    AcceptIcon,
    Flex,
    Image,
    Label,
    Text,
} from "@fluentui/react-northstar";
import { ChevronUp16Filled, ChevronDown16Filled } from "@fluentui/react-icons";
import { LANGUES } from "../../constants/locales";
import { useTranslation } from "react-i18next";
import { CustomBadge } from "../Badge";

const SwitchLang = () => {
    const [open, setOpen] = useState(false);
    // const { i18n } = useTranslation();

    const _close = (lng) => {
        // i18n?.changeLanguage(lng);
        return setOpen((state) => !state);
    };

    return (
        <Wrapper
            column
            styles={{
                paddingLeft: "17px",
            }}
        >
            {open && <DropdownList onClickItem={_close} />}
            <Dropdown open={open} onClick={_close} />
        </Wrapper>
    );
};

const Dropdown = ({ onClick, open }) => {
    const { i18n } = useTranslation();

    const selected_lang = i18n.resolvedLanguage;
    const array_selected_lang = LANGUES.find(
        (lng) => lng?.code === selected_lang
    );

    return (
        <Wrapper
            space="between"
            vAlign="center"
            onClick={() => onClick(selected_lang)}
            styles={{
                cursor: "pointer",
                width: "100%",
            }}
            fill
        >
            <Image
                src={`https://flagcdn.com/${array_selected_lang?.flag_code}.svg`}
                width={"24px"}
            />
            <SelectedLang content={selected_lang.toUpperCase()} />
            {open ? <ChevronDown16Filled /> : <ChevronUp16Filled />}
        </Wrapper>
    );
};

const DropdownList = ({ onClickItem }) => {
    const { i18n } = useTranslation();
    return (
        <WrapperList column>
            {LANGUES.map((lng) => {
                return (
                    <CardLang
                        key={lng.code}
                        styles={{
                            padding: "16px 18px",
                        }}
                        gap="gap.small"
                        vAlign="center"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            onClickItem(lng.code);
                            return;
                        }}
                    >
                        <Image
                            src={`https://flagcdn.com/${lng.flag_code}.svg`}
                            width={"24px"}
                        />
                        <SelectedLang
                            content={lng.code.toUpperCase()}
                            selected={i18n.resolvedLanguage === lng.code}
                        />
                        {i18n.resolvedLanguage === lng.code && (
                            <AcceptIcon
                                styles={{ color: "var(--color-primary)" }}
                            />
                        )}
                        {lng.soon && <Label color={"grey"} content={"soon"} />}
                    </CardLang>
                );
            })}
        </WrapperList>
    );
};

export default SwitchLang;

const CardLang = styled(Flex)`
    cursor: pointer;
`;

const WrapperList = styled(Flex)`
    position: absolute;
    width: 177px;
    left: 0px;
    bottom: 50px;
    background-color: white;

    filter: drop-shadow(0px 0.15px 0.45px rgba(0, 0, 0, 0.11))
        drop-shadow(0px 0.8px 1.8px rgba(0, 0, 0, 0.13));
`;

const SelectedLang = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ selected }) => (selected ? "var(--color-primary)" : "#242424")};
`;

const Wrapper = styled(Flex)`
    width: 76px;
`;

import { create } from "zustand";
import { persist } from "zustand/middleware";
import produce from "immer";

export const useOnboarding = create(
    persist(
        (set) => ({
            welcome_screen_from_back: {
                data: null,
            },
            getStarted: {
                clicked: false,
            },
            upload_image: {
                skiped: false,
                uploaded_image: null,
                validate_image: false,
            },
            about_me: {
                skiped: false,
                sent: false,
                value: null,
            },
            emergency_contact: {
                name: null,
                phone: null,
                link: null,
                sent: false,
            },
            goto_dashboard: {
                clicked: false,
            },
            setClickedGetStarted: (bool) => {
                set(
                    produce((state) => {
                        state.getStarted.clicked = bool;
                    })
                );
            },
            setSkipUploadImage: () => {
                set(
                    produce((state) => {
                        state.upload_image.skiped = true;
                    })
                );
            },
            setImageUploaded: (base64) => {
                set(
                    produce((state) => {
                        state.upload_image.uploaded_image = base64;
                    })
                );
            },
            setValidateImage: () => {
                set(
                    produce((state) => {
                        state.upload_image.validate_image = true;
                    })
                );
            },
            setSkippedAboutMe: () => {
                set(
                    produce((state) => {
                        state.about_me.skiped = true;
                    })
                );
            },
            setSentAboutMe: (about_me) => {
                set(
                    produce((state) => {
                        state.about_me.sent = true;
                        state.about_me.value = about_me;
                    })
                );
            },
            setEmergencyContact: (contact) => {
                set(
                    produce((state) => {
                        state.emergency_contact = contact;
                    })
                );
            },
            setWelcomeScreenFromBack: (data) => {
                set(
                    produce((state) => {
                        state.welcome_screen_from_back.data = data;
                    })
                );
            },
            setWelcomeScreenShowToFalse: () => {
                set(
                    produce((state) => {
                        state.welcome_screen_from_back.data.display_welcome_screen = false;
                    })
                );
            },
            setGotoClicked: () => {
                set(
                    produce((state) => {
                        state.goto_dashboard.clicked = true;
                    })
                );
            },
            reset: () => {
                set(() => {
                    return null;
                }, true);
            },
        }),
        {
            name: "onboarding",
        }
    )
);

export const useIsClickGetStarted = () =>
    useOnboarding((state) => state.getStarted);

export const useClickGetStarted = () =>
    useOnboarding((state) => state.setClickedGetStarted);

export const useUploadImage = () =>
    useOnboarding((state) => state.upload_image);

export const useSetSkipUploadImage = () =>
    useOnboarding((state) => state.setSkipUploadImage);

export const useSetImageUploaded = () =>
    useOnboarding((state) => state.setImageUploaded);

export const useGetImageUploaded = () =>
    useOnboarding((state) => state.upload_image.uploaded_image);

export const useSetValidateImage = () =>
    useOnboarding((state) => state.setValidateImage);

export const useSetSkippedAboutMe = () =>
    useOnboarding((state) => state.setSkippedAboutMe);

export const useGetSkippedAboutMe = () =>
    useOnboarding((state) => state.about_me.skiped);

export const useGetAboutMe = () => useOnboarding((state) => state.about_me);
export const useSetAboutMe = () =>
    useOnboarding((state) => state.setSentAboutMe);

export const useGetEmergencyContact = () =>
    useOnboarding((state) => state.emergency_contact);

export const useSetEmergencyContact = () =>
    useOnboarding((state) => state.setEmergencyContact);

export const useSetWelcomeScreenFromBack = () =>
    useOnboarding((state) => state.setWelcomeScreenFromBack);

export const useSetWelcomeScreenShowToFalse = () =>
    useOnboarding((state) => state.setWelcomeScreenShowToFalse);

export const useSetGotoClicked = () =>
    useOnboarding((state) => state.setGotoClicked);

export const useReset = () => useOnboarding((state) => state.reset);

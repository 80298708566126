import {
    AccessibilityIcon,
    Button,
    Dialog,
    Flex,
    Image,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import LoadingExportImg from "./LoadingExport.png";
import Media from "./Media.gif";
import docs from "./docs.png";
import profilanime from "./profilanime.png";
import DownloadFile from "./DownloadFile.png";
import Excel from "./Excel.png";
import styled, { keyframes } from "styled-components";
import Lottie from "react-lottie";
import animationData from "./Animation_Lotties_For_export_leave.json";
import { Toast } from "@fluentui/react-components";

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: relative; */
`;

export default function LoadingExport() {
    const [configToast, setConfigToast] = React.useState({
        visible: false,
        content: "",
        level: "warning",
    });

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <FlexContainer>
            <Flex hAlign="center" vAlign="center">
                <Lottie options={defaultOptions} />
            </Flex>
            <Flex gap="gap.medium" column hAlign="center">
                <TitleStyled content="Export en cours" />
                <DescriptionStyled content="Nous sommes en train de générer votre export au format Excel, cela peut prendre quelques secondes" />
            </Flex>
        </FlexContainer>
    );
}

const TitleStyled = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;

const DescriptionStyled = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Default-Foreground-2, #616161);
    text-align: center;

    /* Medium/400 */
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

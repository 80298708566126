import React from "react";
import { useState } from "react";
import moment from "moment";
import styled, { css } from "styled-components";

import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Flex, Image, Loader, Text } from "@fluentui/react-northstar";
import {
    ArrowSortDown16Filled,
    ArrowSortUp16Filled,
} from "@fluentui/react-icons";

import Status, { LEVELS_BACKEND } from "./Badges/Status";
import Counter from "./Badges/Counter";
import TotalExpense from "./Texts/TotalExpense";

import { _api_get_my_last_expenses_report } from "../api";
import { I18N_NS_EXPENSE, QUERY_KEY } from "../constants";
import { ROUTES } from "../../../constants/routes";
import { get_symbol_currency } from "../functions";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ExpenseEmptyIcon from "../../../assets/illustrations/expenses/expense_empty.svg";
import StatusActionsDependRole, {
    STATUS_ROLE,
} from "./Badges/StatusActionsDependRole";
import { DEVICE, SIZES } from "../../../constants/responsive";
import { useMediaQuery } from "react-responsive";
import { FlexRow } from "./Tables/RowExpense/RowExpense";

const ArrowSortDown = (
    <ArrowSortDown16Filled style={{ color: "var(--color-primary)" }} />
);

const ArrowSortUp = (
    <ArrowSortUp16Filled style={{ color: "var(--color-primary)" }} />
);

const sortByDate = ({ data, dateSorted, setDateSorted }) => {
    let sortedList = data;
    if (!dateSorted) {
        setDateSorted(true);
        sortedList = data?.sort(
            (a, b) => moment(a.created_date) - moment(b.created_date)
        );
    } else {
        setDateSorted(false);
        sortedList = data?.sort((a, b) => {
            return moment(b.created_date) - moment(a.created_date);
        });
    }
    return sortedList;
};

const ExpensesRows = () => {
    const ROUTE_DETAIL_STATUS = ROUTES.expenses.detail_status_expense;

    const { t } = useTranslation(I18N_NS_EXPENSE);

    const [dateSorted, setDateSorted] = useState(0);
    const [sortedTask, setSortedTask] = useState([]);

    const { data: my_last_expenses_report, isLoading } = useQuery(
        [QUERY_KEY + "::my_last_expense_report"],
        () => _api_get_my_last_expenses_report()
    );

    useEffect(() => {
        setSortedTask(my_last_expenses_report);
    }, [my_last_expenses_report]);

    const len_tasks = sortedTask?.length;

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileS})`,
    });
    const isMobileM = useMediaQuery({
        query: "(max-width: 586px)",
    });

    const isMobile = isMobileS || isMobileM;
    if (isLoading) {
        return <Loader />;
    }
    return (
        <Flex fill column>
            <Flex
                fill
                space="between"
                vAlign="center"
                styles={{
                    borderBottom: "1px solid #D1D1D1",
                    paddingBlock: "8px",
                }}
            >
                <Flex styles={{ flex: 1.15 }}>
                    <ColumnName
                        content={
                            <Flex
                                vAlign="center"
                                styles={{ paddingLeft: "10px" }}
                                onClick={() =>
                                    sortByDate({
                                        data: sortedTask,
                                        dateSorted,
                                        setDateSorted,
                                    })
                                }
                            >
                                {isMobile ? (
                                    <MobileColumnName
                                        content={t("home.columns.date")}
                                    />
                                ) : (
                                    <DateText
                                        content={t("home.columns.date")}
                                    />
                                )}
                                {!dateSorted ? ArrowSortDown : ArrowSortUp}
                            </Flex>
                        }
                        styles={{
                            cursor: "pointer",
                        }}
                    />
                </Flex>
                <Flex styles={{ flex: 5 }}>
                    {isMobile ? (
                        <MobileColumnName
                            content={t("home.columns.expense_name")}
                        />
                    ) : (
                        <ColumnName content={t("home.columns.expense_name")} />
                    )}
                </Flex>
                <Flex styles={{ flex: 2 }}>
                    <ColumnName content={""} />
                </Flex>
                <Flex styles={{ flex: 4 }}>
                    <ColumnName content="" />
                </Flex>
                {isMobile ? null : (
                    <Flex styles={{ flex: 2 }} hAlign="end">
                        <ColumnName content={t("home.columns.demande")} />
                    </Flex>
                )}
                <Flex styles={{ flex: 2 }} hAlign="end">
                    {isMobile ? (
                        <MobileColumnName content="Montant" />
                    ) : (
                        <ColumnName
                            bolder
                            content={t("home.columns.rembourse")}
                        />
                    )}
                </Flex>
            </Flex>
            {len_tasks === 0 ? (
                <Flex
                    hAlign="center"
                    fill
                    column
                    gap="gap.small"
                    styles={{ marginTop: "25px" }}
                >
                    <Image src={ExpenseEmptyIcon} />
                    <Text content="Vous n’avez pas encore de notes de frais." />
                    <Flex gap="gap.small">
                        <Text
                            content={
                                "Pour ajouter une nouvelle note de frais, cliquez sur le bouton "
                            }
                        />
                        <Text
                            content={"« Créer une note de frais »"}
                            weight="bold"
                        />
                    </Flex>
                </Flex>
            ) : (
                sortedTask?.map((el, index) => {
                    const currency = get_symbol_currency(el?.currency);
                    return (
                        <LinkTo to={ROUTE_DETAIL_STATUS.custom_route(el?.id)}>
                            <FlexRow
                                fill
                                space="between"
                                vAlign="center"
                                styles={{
                                    paddingBlock: "16px",
                                    paddingRight: "8px",
                                }}
                                key={`ROW_${index}`}
                            >
                                <Flex styles={{ flex: 1 }}>
                                    <Date>
                                        {moment(el?.created_date).format(
                                            "DD/MM"
                                        )}
                                    </Date>
                                </Flex>
                                <Flex styles={{ flex: 5 }}>
                                    <LinkTo
                                        to={ROUTE_DETAIL_STATUS.custom_route(
                                            el?.id
                                        )}
                                    >
                                        <Title content={el?.name} />
                                    </LinkTo>
                                </Flex>
                                {isMobile ? null : (
                                    <Flex styles={{ flex: 2 }}>
                                        <Counter>{el?.expenses_count}</Counter>
                                    </Flex>
                                )}
                                {isMobile ? null : (
                                    <Flex styles={{ flex: 4 }}>
                                        <StatusActionsDependRole
                                            role={STATUS_ROLE.employee}
                                            status={el?.status?.value}
                                        />
                                    </Flex>
                                )}
                                {isMobile ? null : (
                                    <Flex styles={{ flex: 2 }} hAlign="end">
                                        <TotalExpense
                                            content={`${el?.amount_total} ${currency}`}
                                        />
                                    </Flex>
                                )}
                                <Flex styles={{ flex: 2 }} hAlign="end">
                                    <TotalExpense
                                        content={`${
                                            el?.amount_approved ?? "--,--"
                                        } ${currency}`}
                                        level={
                                            LEVELS_BACKEND[el?.status?.value]
                                        }
                                    />
                                </Flex>
                            </FlexRow>
                        </LinkTo>
                    );
                })
            )}
        </Flex>
    );
};

export default ExpensesRows;

export const ColumnName = styled(Text)`
    color: #616161;
    font-size: clamp(12px, 1.5vw, 14px);
    font-style: normal;
    line-height: 20px;

    ${({ bolder }) =>
        bolder
            ? css`
                  font-weight: 700;
              `
            : ""}
    ${({ badge }) =>
        badge
            ? css`
                  border-radius: 16px;
                  background: #2f2f4a;
                  padding: 3px 5px;

                  color: #fff;
                  text-align: center;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 10px;
              `
            : ""}
`;

const LinkTo = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    color: #5b5fc7;
    align-items: center;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 110px; /* valeur par défaut pour les écrans plus grands */

    @media ${DEVICE.mobileS} {
        max-width: 120px; /* pour les mobiles moyens */
    }
    @media ${DEVICE.mobileM} {
        max-width: 170px; /* pour les mobiles moyens */
    }
    @media ${DEVICE.mobileL} {
        max-width: 200px; /* pour les mobiles moyens */
    }
    @media ${DEVICE.tablet} {
        max-width: 150px; /* pour les mobiles moyens */
    }
    @media ${DEVICE.laptop} {
        max-width: 110px; /* pour les mobiles moyens */
    }
    @media ${DEVICE.laptopL} {
        max-width: 250px; /* pour les mobiles moyens */
    }
`;

const Date = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #424242;
`;

const DateText = styled(Text)`
    font-size: clamp(12px, 1.5vw, 14px);
`;
const MobileColumnName = styled(Text)`
    font-size: 12px;
    font-weight: 700;
    color: #424242;
`;

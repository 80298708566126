import { CloseIcon, Dialog, Text } from "@fluentui/react-northstar";
import { DialogStyle } from "../../pages/DashboardTasks/style";

export const DialogCancelConfirmation = ({
    open,
    header = "",
    content = "",
    confirmButton = "Confirm",
    cancelButton = "Cancel",
    trigger,
    width = "764",
    onClose = () => null,
    onConfirm = () => null,
}) => {
    return (
        <DialogStyle
            open={open}
            header={header}
            content={content}
            trigger={trigger}
            confirmButton={confirmButton}
            onConfirm={onConfirm}
            cancelButton={cancelButton}
            onCancel={onClose}
            headerAction={<CloseIcon onClick={onClose} />}
            style={{
                width: width + "px",
            }}
        />
    );
};

import React, { useState } from "react";
import { Button, CloseIcon, Dialog } from "@fluentui/react-northstar";
import { FormProvider, useForm } from "react-hook-form";
import { ClipboardTaskListLtr20Regular } from "@fluentui/react-icons";
import { ContentImportTemplatesWithCollaborator } from "./ContentImportTemplatesWithCollaborator";
import { useMutation } from "react-query";
import { _api_post_task_definition } from "../../../features/addEmployee/api";
import DialogConfirmationTaskAttribution from "./DialogConfirmationTaskAttribution";
import ButtonBreadCrumb from "../../../components/buttons";
import { DialogStyle } from "../style";

const DialogUseModalTask = ({ setShowAlert, open, setOpen }) => {
    // const [open, setOpen] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [templates, setTemplates] = useState([]); // templates first step
    const [tasks, setTasks] = useState([]); // tasks selected and calculated from back - second step

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            beneficiary: null,
        },
    });

    const {
        mutate: mutate_user_task_definition,
        isLoading: isLoadingMutation,
    } = useMutation(
        (data) => {
            // API function
            return _api_post_task_definition({
                id_user: data?.beneficiary?.id,
                ids: templates
                    ?.map((el) => el?.tasks)
                    .flat()
                    .filter((el) => el?.checked)
                    .map((el) => el.id),
            });
        },
        {
            onSuccess: (data) => {
                setTasks(data);
                setTemplates([]);
                setOpenConfirmationDialog(true);
                closeDialog();
            },
            onError: (err) => {
                setTemplates([]);
                alert("Une erreur est survenue lors de l'envoie !");
            },
        }
    );

    const onSubmit = (data) => {
        mutate_user_task_definition(data);
        methods.reset();
        return;
    };

    const openDialog = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
        setTemplates([]);
        methods.clearErrors();
    };

    const closeDialog = () => {
        setOpen(false);
        // setOpenConfirmationDialog(false);
        setTemplates([]);
        methods.clearErrors();
        methods.reset();
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <DialogStyle
                        open={open}
                        header={"Utiliser un modèle de tâches"}
                        headerAction={<CloseIcon onClick={closeDialog} />}
                        onCancel={closeDialog}
                        cancelButton="Annuler"
                        confirmButton={{
                            content: "Attribuer les tâches",
                            disabled:
                                !methods.formState.isValid ||
                                templates?.filter((el) => el?.checked)
                                    .length === 0,
                            loading: isLoadingMutation,
                        }}
                        onConfirm={() => {
                            return methods.handleSubmit(onSubmit)();
                        }}
                        content={
                            <ContentImportTemplatesWithCollaborator
                                templates={templates}
                                setTemplates={setTemplates}
                            />
                        }
                    />
                </form>
            </FormProvider>
            {openConfirmationDialog && (
                <DialogConfirmationTaskAttribution
                    tasks={tasks}
                    setTasks={setTasks}
                    setShowAlert={setShowAlert}
                    setOpenConfirmationDialog={setOpenConfirmationDialog}
                />
            )}
        </>
    );
};

export default DialogUseModalTask;

import React from "react";
import { EditIcon, Text, TrashCanIcon } from "@fluentui/react-northstar";
import { MoreVertical24Filled } from "@fluentui/react-icons";
import { MenuStyle } from "../../../common/styles";

const remove_task_from_list = (id, setTemplateTasks, setToastConfig) => {
    setTemplateTasks((state) => {
        const filter_task = state?.filter((el) => el?.id !== id);
        return filter_task;
    });
    setToastConfig((state) => ({
        ...state,
        visible: true,
        content: "La tâche a bien été supprimée",
        level: "success",
    }));
};

const MoreMenuItemModifyTemplate = ({
    task,
    setTemplateTasks,
    setOpenDialogModifyTask,
    setToastConfig,
}) => {
    return (
        <MenuStyle
            padding={"0px"}
            items={[
                {
                    key: "manage models",
                    icon: <MoreVertical24Filled />,
                    menu: [
                        {
                            key: "modify",
                            content: "modifier la tâche",
                            icon: <EditIcon />,
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                return setOpenDialogModifyTask({
                                    visible: true,
                                    task: task,
                                });
                            },
                        },
                        {
                            key: "remove",
                            content: (
                                <Text styles={{ color: "var(--color-danger)" }}>
                                    supprimer la tâche
                                </Text>
                            ),
                            icon: (
                                <TrashCanIcon
                                    styles={{ color: "var(--color-danger)" }}
                                />
                            ),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                return remove_task_from_list(
                                    task?.id,
                                    setTemplateTasks,
                                    setToastConfig
                                );
                            },
                        },
                    ],
                },
            ]}
        />
    );
};

export default MoreMenuItemModifyTemplate;

const SETUP_PROFILE = {
    MARITAL_STATUS: [
        {
            id: 1,
            name: "Célibataire",
        },
        {
            id: 2,
            name: "Marié(e)",
        },
        {
            id: 3,
            name: "Veuf(ve)",
        },
        {
            id: 4,
            name: "Divorcé(e)",
        },
    ],
    IDENTITY_CARD_TYPE: [
        {
            id: 1,
            name: "Carte nationale d'identité",
        },
        {
            id: 2,
            name: "Passport",
        },
        {
            id: 3,
            name: "Titre de séjour",
        },
    ],
    STATUS: [
        {
            id: 0,
            key: 1,
            name: "Autre",
        },
        {
            id: 1,
            key: 2,
            name: "Cadre",
        },
        {
            id: 2,
            key: 3,
            name: "Etam",
        },
    ],
    ROLE: [
        {
            id: 1,
            name: "Interne",
        },
        { id: 2, name: "Externe" },
        { id: 3, name: "Consultant" },
    ],
    GENDER: [
        {
            id: 1,
            name: "Homme",
        },
        {
            id: 2,
            name: "Femme",
        },
        {
            id: 3,
            name: "Autre",
        },
    ],
};

export default SETUP_PROFILE;

import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { I18N_NS_LEAVE } from "../../../../../features/leave/constants";

const TypeLeave = ({ name, days, color }) => {
    const { t } = useTranslation(I18N_NS_LEAVE);
    return (
        <Wrapper vAlign="center" color={color}>
            <Flex>
                <Leave content={name} />
            </Flex>
            <Flex>
                <Badge
                    content={t("timeline.dialogs.leave_request.days", {
                        days,
                    })}
                />
            </Flex>
        </Wrapper>
    );
};

export default TypeLeave;

const Badge = styled(Text)`
    color: #fff;

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;

    border-radius: 100px;
    background: rgba(41, 40, 40, 0.9);

    height: 16px;
    padding: 2px 6px;
    gap: 2px;
`;

const Leave = styled(Text)`
    color: #242424;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const Wrapper = styled(Flex)`
    border-radius: 6px;
    background: ${({ color }) => color};

    padding: 4px 8px;
    gap: 8px;
    width: fit-content;
`;

import React, { useState } from "react";
import {
    Button,
    Dropdown,
    Flex,
    Input,
    InputLabel,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import Card from "..";

import Img from "../../../../../assets/illustrations/employee_folder/contrat_qualif_job.svg";
import QualificationAndJobContent from "./Components/QualificationAndJobContent";
import { QUERY_KEY } from "../../../constants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    _api_get_contract_status,
    _api_get_type_contracts,
    _api_update_personal_information,
    _api_update_qualification_job,
} from "../../../api";
import { Controller, useForm } from "react-hook-form";
import { ROLES_CODE, roles_access } from "../../../../../constants/roles";
import {
    CONTRACT_STATUS,
    CONTRACT_TYPES,
} from "../../../../../constants/lists";
import { ErrorMessage } from "../../../../../common/styles";

const QualificationAndJob = ({ contract_data }) => {
    const clientQuery = useQueryClient();

    const is_fr = contract_data?.contract_country?.toLowerCase() === "fr";

    const isHR = roles_access({
        usersPrivileges: [ROLES_CODE.hr],
    });

    const [modeUpdate, setModeUpdate] = useState(false);

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        reset,
    } = useForm({
        mode: "onChange",
        defaultValues: {},
    });

    const { data: data_contract, isLoading: is_loading_contract } = useQuery(
        [QUERY_KEY + "::get_list_type_contracts"],
        () => _api_get_type_contracts(),
        {
            refetchOnWindowFocus: false,
        }
    );

    const { data: data_status, isLoading: is_loading_status } = useQuery(
        [QUERY_KEY + "::get_list_status"],
        () => _api_get_contract_status(),
        {
            refetchOnWindowFocus: false,
        }
    );

    const {
        mutate: mutate_update_qualification_and_job,
        isLoading: is_loading_mutate,
    } = useMutation((data) => _api_update_qualification_job(data));

    const _setModeUpdate = (bool) => {
        reset();
        return setModeUpdate(bool);
    };

    const onSubmit = (data) => {
        mutate_update_qualification_and_job(
            {
                job_title: data?.job_title,
                contract_type: data?.contract_type?.id,
                contract_status: data?.contract_status?.id,
                position: data?.position,
                coefficient: data?.coefficient,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        clientQuery.resetQueries([QUERY_KEY + "::contract"]);
                        _setModeUpdate(false);
                    }
                    return;
                },
            }
        );
        return;
    };

    if (is_loading_contract || is_loading_status) {
        return <Loader />;
    }

    if (modeUpdate) {
        const format_contract_types = data_contract?.map((ct) => {
            return {
                id: ct?.id,
                header: ct?.name,
            };
        });
        const format_contract_status = data_status?.map((cs) => {
            return {
                id: cs?.id,
                header: cs?.name,
            };
        });
        const default_contract_type = format_contract_types?.find(
            (ct) => ct?.id === contract_data?.contract_type?.id
        );
        const default_contract_status = format_contract_status?.find(
            (cs) => cs?.id === contract_data?.contract_status?.id
        );

        return (
            <Flex fill styles={{ maxWidth: "440px" }} column>
                <Card column>
                    <Card.EditTitle
                        content={"Modification de l’Emploi & qualification"}
                        onClose={() => _setModeUpdate(false)}
                    />
                    <Card.Body
                        content={
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Flex fill column gap="gap.medium">
                                    <Flex column>
                                        <Input
                                            {...register("job_title", {
                                                required: {
                                                    value: true,
                                                    message:
                                                        "Ce champ est obligatoire",
                                                },
                                            })}
                                            error={errors?.job_title?.message}
                                            label={"Poste"}
                                            defaultValue={
                                                contract_data?.job_title
                                            }
                                            fluid
                                        />
                                        <ErrorMessage
                                            content={errors?.job_title?.message}
                                        />
                                    </Flex>
                                    <Flex column>
                                        <InputLabel
                                            content={"Type de contrat"}
                                        />
                                        <Controller
                                            control={control}
                                            name="contract_type"
                                            defaultValue={default_contract_type}
                                            render={({
                                                field: { onChange },
                                            }) => (
                                                <Dropdown
                                                    items={
                                                        format_contract_types
                                                    }
                                                    defaultValue={
                                                        default_contract_type
                                                    }
                                                    onChange={(_, i) => {
                                                        return onChange(
                                                            i?.value
                                                        );
                                                    }}
                                                    error={
                                                        errors?.contract_type
                                                            ?.message
                                                    }
                                                    checkable
                                                    fluid
                                                />
                                            )}
                                        />
                                    </Flex>
                                    <Flex column>
                                        <InputLabel
                                            content={is_fr ? "Statut" : "CSP"}
                                        />
                                        <Controller
                                            control={control}
                                            name="contract_status"
                                            defaultValue={
                                                default_contract_status
                                            }
                                            render={({
                                                field: { onChange },
                                            }) => (
                                                <Dropdown
                                                    items={
                                                        format_contract_status
                                                    }
                                                    defaultValue={
                                                        default_contract_status
                                                    }
                                                    onChange={(_, i) => {
                                                        return onChange(
                                                            i?.value
                                                        );
                                                    }}
                                                    error={
                                                        errors?.contract_status
                                                            ?.message
                                                    }
                                                    checkable
                                                    fluid
                                                />
                                            )}
                                        />
                                    </Flex>
                                    {is_fr && (
                                        <>
                                            <Flex column>
                                                <Input
                                                    {...register("position", {
                                                        required: {
                                                            value: true,
                                                            message:
                                                                "Ce champ est obligatoire",
                                                        },
                                                    })}
                                                    error={
                                                        errors?.position
                                                            ?.message
                                                    }
                                                    label={"Position"}
                                                    defaultValue={
                                                        contract_data?.position
                                                    }
                                                    type="number"
                                                    fluid
                                                />
                                                <ErrorMessage
                                                    content={
                                                        errors?.position
                                                            ?.message
                                                    }
                                                />
                                            </Flex>
                                            <Flex column>
                                                <Input
                                                    {...register(
                                                        "coefficient",
                                                        {
                                                            required: {
                                                                value: true,
                                                                message:
                                                                    "Ce champ est obligatoire",
                                                            },
                                                        }
                                                    )}
                                                    type="number"
                                                    error={
                                                        errors?.coefficient
                                                            ?.message
                                                    }
                                                    label={"Coeifficient"}
                                                    defaultValue={
                                                        contract_data?.coefficient
                                                    }
                                                    fluid
                                                />
                                                <ErrorMessage
                                                    content={
                                                        errors?.coefficient
                                                            ?.message
                                                    }
                                                />
                                            </Flex>
                                        </>
                                    )}
                                    <Flex hAlign="center" gap="gap.medium">
                                        <Button
                                            content={"Annuler"}
                                            onClick={() =>
                                                _setModeUpdate(false)
                                            }
                                            flat
                                        />
                                        <Button
                                            content={
                                                "Enregistrer les modifications"
                                            }
                                            loading={is_loading_mutate}
                                            primary
                                            flat
                                        />
                                    </Flex>
                                </Flex>
                            </form>
                        }
                    />
                </Card>
            </Flex>
        );
    }

    return (
        <Flex fill styles={{ maxWidth: "440px" }} column>
            <Card column>
                {/* <Card.Illustration
                    image={Img}
                    canEdit={isHR}
                    
                /> */}
                <Card.Title
                    content={"Emploi & qualification"}
                    canEdit={isHR}
                    onEdit={() => {
                        if (!isHR) {
                            return;
                        }
                        return setModeUpdate(true);
                    }}
                />
                {/* emploi & qualification */}
                <Card.Body
                    content={
                        <QualificationAndJobContent
                            job_title={contract_data?.job_title}
                            contract_type={contract_data?.contract_type}
                            contract_status={contract_data?.contract_status}
                            coefficient={contract_data?.coefficient}
                            position={contract_data?.position}
                            contract_country={contract_data?.contract_country}
                        />
                    }
                />
            </Card>
        </Flex>
    );
};

export default QualificationAndJob;

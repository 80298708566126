import { BASE_URL_API } from "../../../constants";
import { useAuthDetailStore, useAuthStore } from "../../../stores/useAuthStore";
import fetcher from "../../../utils/fetcher";

export const _api_send_note = ({ id_expense_report }) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    const url = `${BASE_URL_API}/user/${id_user}/expense_report/${id_expense_report}/send_for_approval`;
    return fetcher(url, {
        method: "PUT",
        credentials: "include",
    });
};

export const _api_cancel_note = ({ id_expense_report }) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    const url = `${BASE_URL_API}/user/${id_user}/expense_report/${id_expense_report}/delete`;
    return fetcher(url, {
        method: "DELETE",
        credentials: "include",
    });
};

export const _api_get_my_chart = () => {
    const url = `${BASE_URL_API}/expense_report/current_year/expenses/chart`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_my_teams_chart = () => {
    const url = `${BASE_URL_API}/expense_report/current_year/manager/expenses/chart`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_my_history = ({ year }) => {
    const url = `${BASE_URL_API}/expense_report/for_myself/year/${year}/history`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_code_budget = ({ id_user }) => {
    const url = `${BASE_URL_API}/expense_report/user/${id_user}/object_code/list`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_history_teams = ({ year }) => {
    const url = `${BASE_URL_API}/expense_report/as_manager/year/${year}/history`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_export_excel_for_year = ({ year }) => {
    const url = `${BASE_URL_API}/expense_report/year/${year}/excel`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_download_excel_of_year = async ({ year }) => {
    const user = useAuthStore.getState().infos;
    return fetch(`${BASE_URL_API}/expense_report/year/${year}/month/0/export`, {
        headers: {
            Authorization: `Bearer ${user?.access}`,
        },
        credentials: "include",
    })
        .then((res) => {
            return res.blob();
        })
        .then((data) => {
            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(data);
            a.download = year + ".xlsx";
            a.click();
        });
};

export const _api_download_excel_of_month = async ({ month_index, year }) => {
    const user = useAuthStore.getState().infos;
    return fetch(
        `${BASE_URL_API}/expense_report/year/${year}/month/${month_index}/export`,
        {
            headers: {
                Authorization: `Bearer ${user?.access}`,
            },
            credentials: "include",
        }
    )
        .then((res) => {
            return res.blob();
        })
        .then((data) => {
            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(data);
            a.download = month_index + "_" + year + ".xlsx";
            a.click();
        });
};

export const _api_get_clients = () => {
    const url = `${BASE_URL_API}/expense_report/client/list`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_my_last_expenses_report = () => {
    const url = `${BASE_URL_API}/expense_report/list_latest`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_pending_expenses_manager = () => {
    const url = `${BASE_URL_API}/expense_report/to_approve/list`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_count_expenses_company = () => {
    const url = `${BASE_URL_API}/expense_report/company/count`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_delete_expense = ({ id_expense_report, id_expense }) => {
    const url = `${BASE_URL_API}/expense_report/${id_expense_report}/expense/${id_expense}/delete`;
    return fetcher(url, {
        method: "DELETE",
        credentials: "include",
    });
};

export const _api_get_categories = () => {
    const url = `${BASE_URL_API}/expense_report/category/list`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_vats = () => {
    const url = `${BASE_URL_API}/expense_report/default_vats/list`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_currencies = async () => {
    const url = `${BASE_URL_API}/expense_report/currencies/list`;
    const response = await fetcher(url, {
        method: "GET",
        credentials: "include",
    });
    return response;
};

export const _api_get_expense = ({ id_expense_report, id_expense }) => {
    const url = `${BASE_URL_API}/expense_report/${id_expense_report}/expense/${id_expense}/details`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_detail_report = ({ id_expense_report }) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    const url = `${BASE_URL_API}/user/${id_user}/expense_report/${id_expense_report}/details`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_bareme = ({
    power_type,
    vehicle_type,
    distance,
    power_value,
}) => {
    let query = ``;
    if (distance > 0) {
        query += `&distance=${distance}`;
    }
    if (power_value > 0) {
        query += `&power_value=${power_value}`;
    }

    const url = `${BASE_URL_API}/expense_report/mileage_km?power_type=${power_type}&vehicle_type=${vehicle_type}${query}`;

    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_detail_report_manager = ({
    id_expense_report,
    id_user,
}) => {
    const url = `${BASE_URL_API}/user/${id_user}/expense_report/${id_expense_report}/details`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};
// ***** ACCOUNTING MANAGER
export const _api_get_list_to_verify_expenses = () => {
    const url = `${BASE_URL_API}/expense_report/company/list`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};
export const _api_get_list_history_paid_expenses = ({ month_index, year }) => {
    const url = `${BASE_URL_API}/expense_report/company/year/${year}/month/${month_index}/history/list`;
    return fetcher(url, {
        method: "GET",
        credentials: "include",
    });
};
// ***** END ACCOUNTING MANAGER

export const _api_add_note = ({ name, reinvoiceable, client_id }) => {
    const url = `${BASE_URL_API}/expense_report/add`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify({
            name,
            reinvoiceable,
            client_id,
        }),
        credentials: "include",
    });
};

export const _api_add_expense = ({
    id_expense_report,
    name,
    expense_category_id,
    expense_date,
    amount,
    currency,
    comment,
    document_ids,
    vats,
    rate,
}) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;

    const url = `${BASE_URL_API}/expense_report/${id_expense_report}/user/${id_user}/expense/add`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify({
            name,
            expense_category_id,
            expense_date,
            amount,
            currency,
            comment,
            document_ids,
            vats,
            rate,
        }),
        credentials: "include",
    });
};

export const _api_update_expense = ({
    id_user: user_id,
    id_expense,
    rate,
    expense_date,
    amount,
    currency,
    comment,
    new_document_ids,
    vats,
    category_id,
}) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;

    const url = `${BASE_URL_API}/expense/${id_expense}/user/${
        user_id ? user_id : id_user
    }/expense/edit`;
    return fetcher(url, {
        method: "PUT",
        body: JSON.stringify({
            expense_date,
            amount,
            currency,
            comment,
            new_document_ids,
            vats,
            rate,
            category_id,
        }),
        credentials: "include",
    });
};

export const _api_add_expense_km_ch = (data) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    const id_expense_report = data?.param_id_expense;
    delete data?.param_id_expense;
    const url = `${BASE_URL_API}/expense_report/${id_expense_report}/user/${id_user}/expense/add`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
    });
};

export const _api_update_expense_km_ch = (data) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    const id_expense_report = data?.param_id_expense;
    delete data?.param_id_expense;
    const url = `${BASE_URL_API}/expense/${id_expense_report}/user/${id_user}/expense/edit`;
    return fetcher(url, {
        method: "PUT",
        body: JSON.stringify(data),
        credentials: "include",
    });
};

export const _api_add_expense_km_fr = (data) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    const id_expense_report = data?.param_id_expense;
    delete data?.param_id_expense;
    const url = `${BASE_URL_API}/expense_report/${id_expense_report}/user/${id_user}/expense/add`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
    });
};

export const _api_update_expense_km_fr = (data) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    const id_expense_report = data?.param_id_expense;
    delete data?.param_id_expense;
    const url = `${BASE_URL_API}/expense/${id_expense_report}/user/${id_user}/expense/edit`;
    return fetcher(url, {
        method: "PUT",
        body: JSON.stringify(data),
        credentials: "include",
    });
};

export const _api_refuse_expense = ({
    id_user,
    id_expense_report,
    comment,
}) => {
    const url = `${BASE_URL_API}/user/${id_user}/expense_report/${id_expense_report}/update/reject`;
    return fetcher(url, {
        method: "PUT",
        body: JSON.stringify({
            comment,
        }),
        credentials: "include",
    });
};

export const _api_approve_expense = ({
    id_user,
    id_expense_report,
    comment,
    code_budget,
    amount_to_approve,
    reinvoiceable = null,
    client_id = null,
}) => {
    const url = `${BASE_URL_API}/user/${id_user}/expense_report/${id_expense_report}/update/approve`;
    return fetcher(url, {
        method: "PUT",
        body: JSON.stringify({
            comment,
            object_code_id: code_budget,
            amount_to_approve,
            reinvoiceable,
            client_id: reinvoiceable ? client_id : null,
        }),
        credentials: "include",
    });
};

export const _api_approve_expense_accounting_manager = ({
    id_expense_report,
    comment,
    amount_to_approve,
}) => {
    const url = `${BASE_URL_API}/expense_report/company/view/${id_expense_report}/approve`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify({
            comment,
            amount_to_approve,
        }),
        credentials: "include",
    });
};

export const _api_refuse_expense_accounting_manager = ({
    id_expense_report,
    comment,
}) => {
    const url = `${BASE_URL_API}/expense_report/company/view/${id_expense_report}/reject`;
    return fetcher(url, {
        method: "POST",
        body: JSON.stringify({
            comment,
        }),
        credentials: "include",
    });
};

// ACCOUNTING MANAGER DOWNLOAD EXCEL FILE HISTORY
/**
 *
 * @param {{ year : number, month_index: number}} props
 * @returns
 */
export const _api_export_excel_history_accounting_manager = async ({
    year,
    month_index,
}) => {
    const user = useAuthStore.getState().infos;

    try {
        const fetchData = await fetch(
            `${BASE_URL_API}/expense_report/company/year/${year}/month/${month_index}/history/export`,
            {
                headers: {
                    Authorization: `Bearer ${user?.access}`,
                },
                method: "GET",
                credentials: "include",
            }
        );
        const contentDisposition = fetchData.headers.get("Content-Disposition");

        // Extract filename from Content-Disposition header
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        let filename = "user_format_export.xlsx";

        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
        }

        const blob = await fetchData.blob();

        let a = document.createElement("a");
        if (window.URL && window.URL.createObjectURL) {
            let objectUrl = window.URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(objectUrl);
            return true;
        } else {
            // Fallback for browsers that do not support createObjectURL
            // You may need to handle this case differently based on your requirements
            alert(
                "Your browser does not support this feature. Please try another browser."
            );
            return false;
        }
    } catch (error) {
        console.error({ error });
        return false;
    }
};

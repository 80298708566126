import React from "react";
import { Container, ContainerFluid } from "../../../../common/styles";
import { Button, Flex, Text } from "@fluentui/react-northstar";

import SuiviUserDetail from "./SuiviUserDetail";
import HistoriqueSuiviCollab from "./HistoriqueSuiviCollab";
import { useParams } from "react-router-dom";
import { _getSuiviUserDetail } from "../../api";
import { useQuery } from "react-query";

export default function ContentSuiviPage() {
    const id = useParams();
    const { isLoading: loadingSuiviData, data: dataSuivi } = useQuery(
        ["userdetail", id],
        (args) => {
            const id = args.queryKey.at(1).id_user;
            return _getSuiviUserDetail(id);
        },
        {}
    );
    return (
        // <Flex column>
        <Flex styles={{ gap: "32px" }}>
            {/* suivi user detail  */}
            <SuiviUserDetail dataSuivi={dataSuivi} />
            {/* historique  */}
            <HistoriqueSuiviCollab dataSuivi={dataSuivi} />
            {/* </Flex> */}
            {/* footer actuin  */}
        </Flex>
    );
}

import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";

import IllustrationIcon from "../../assets/illustrations/expenses/upload.svg";
import { _api_upload_document } from "../../api/manage_document";
import {
    EXPENSES_ACCEPT_EXTENSIONS,
    FILE_SIZE_LIMIT,
} from "../../features/documents/constants";

import {
    _api_delete_document,
    _api_upload_ocr,
    _upload_file,
} from "../../api/tasks";
import { Flex, InfoIcon, Text, Tooltip } from "@fluentui/react-northstar";
import { getExtensionDocument } from "../../utils/functions";

const DropzoneExpense = ({
    uploadFile,
    onSizeError = () => null,
    onExtensionError = () => null,
}) => {
    const onDrop = useCallback(async (acceptedFiles) => {
        if (!acceptedFiles?.length) {
            return;
        }
        // limit the import to 5 files at once.
        if (acceptedFiles.length > 1) {
            console.log({ error: "un seul fichier a la fois " });
            return;
        }
        // Do something with the files
        let formData = new FormData();

        const file = acceptedFiles.at(0);

        const ext_file = getExtensionDocument(file?.name);
        const is_valid_document = EXPENSES_ACCEPT_EXTENSIONS.includes(ext_file);

        if (file.size > FILE_SIZE_LIMIT) {
            onSizeError();
            return false;
        }
        if (!is_valid_document) {
            onExtensionError();
            return false;
        }
        formData.append("uploaded_files", file);
        // envoyer coté back
        uploadFile(formData);
    }, []);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: {
            "application/pdf": [".pdf"],
            "image/*": [
                ".jpg",
                ".jpeg",
                ".png",
                ".tiff",
                ".webp",
                ".heic",
                ".tiff",
            ],
        },
        onDrop: onDrop,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <div
            className="container"
            style={{ width: "633px", cursor: "pointer" }}
        >
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <img src={IllustrationIcon} alt="illustration dropzone" />
                <Flex gap="gap.smaller" column hAlign="center">
                    <DragDropText>Ajouter un justificatif</DragDropText>
                    <DragDropTextLimit>5 Mo max.</DragDropTextLimit>
                </Flex>
            </div>
            <Flex
                gap="gap.smaller"
                hAlign="end"
                vAlign="center"
                styles={{ marginTop: "8px" }}
            >
                <Information content="Formats autorisés" />
                <Tooltip
                    pointing
                    subtle={false}
                    content=".pdf, .jpg, .png, .tiff, .webp, .heic, .tiff"
                    trigger={<InfoIcon size="small" />}
                    position="below"
                />
            </Flex>
        </div>
    );
};

// DropzoneExpense.propTypes = {

// };

export default DropzoneExpense;

const Information = styled(Text)`
    color: #424242;
    /* Small/400 */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

const baseStyle = {
    display: "flex",
    padding: "40px 0px",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "stretch",

    borderRadius: "8px",

    borderWidth: "2px",
    borderStyle: "dashed",
    borderColor: "#9299F7",

    background: "#FFF",

    /* shadow-4 */
    boxShadow:
        "0px 1.600000023841858px 3.5999999046325684px 0px rgba(0, 0, 0, 0.11), 0px 0.30000001192092896px 0.8999999761581421px 0px rgba(0, 0, 0, 0.07)",
};

const focusedStyle = {
    borderColor: "#9299F7",
};

const acceptStyle = {
    borderColor: "#9299F7",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

const DragDropTextLimit = styled(Text)`
    color: #424242;
    /* Smaller/400 */
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
`;

const DragDropText = styled.p`
    color: #5b5fc7;
    /* Large/400 */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;

export const CloseIcon = styled.div`
    position: absolute;
    right: -10px;
    top: -8px;
    z-index: 5;
`;

export const PreviewFile = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    width: 90px;
    border-radius: 5px;
    padding: 15px 8px;
    background-color: white;
    box-shadow: 2px 2px 0px 0px #eeeeee;
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.3s;
    &:hover {
        opacity: 1;
    }
    span {
        color: black;
        font-size: 12px;
        width: 100%;
        word-wrap: break-word;
        text-align: center;
    }
`;

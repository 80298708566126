import { Button, ExcelColorIcon } from "@fluentui/react-northstar";
import React from "react";

const ExportButton = ({
    onClick = () => null,
    isLoading = false,
    disabled,
}) => {
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            iconPosition="before"
            icon={<ExcelColorIcon />}
            flat
            text
            content="Exporter pour Excel"
            loading={isLoading}
            style={{
                marginRight: "auto",
            }}
        />
    );
};

export default ExportButton;

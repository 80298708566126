/* eslint-disable no-undef */
let BASE_URL = "";

let PSPDFKIT_LICENSE_KEY = "ox_CrE6jlfSa9-AOcVN-XqycTnDBXIHFhCqjUY-cOvL3HlFKi-pHxaTUdwMVwgaP5EDa5QQH1OsbJggFG1EUaimAx5pEryTcvK1rkMtFDrjnTt5V47rGtU0BhCXDINoRt2hRi7YM54B91ygRSFr6PtB0yPkgtm2fZ0mNC3pwngoFyzbZgakKYcGMnVQ7ij3JK_7HcVYslzXygc9frzqN_GEjZ4WUWgOp2TPLM9OVSsu3T2z_VS0B-uomNr1qqACRYXlBRkanNnezvH_gG9DvgEWXL5fdt8xgQk2haiyGzoEOx-zNjVMMBZYIXN0Lqgo5O1vV_N3kTH_OMIevs6CxwYLSuv6pY380-7weXwHbwFNPVQ-DHsggWPm2iotprXAXlV6M2eJv4lyiCz-ZxXXUjvkJE30lnhrw8vpxteLHjIq80aZ-IxH6DM1XO4iSu97Hthlx7y9q6auxDOVD-eNa7f80_rvvCq5vCOUzdSXQjRfSiHTm8oSfcaCf7RU0qN-A8xp5ylncBrVdXGrhZ1ixlb3hNBgb-L5WjEyYER-HV_M=";
let PSPDFKIT_DOCUMENT_ENGINE_URL = "https://yeetchpspdfkitstaging.azurewebsites.net/";
switch (process.env.NODE_ENV) {
    case "production":
        BASE_URL = process.env.REACT_APP_API_URL;
        PSPDFKIT_LICENSE_KEY = process.env.REACT_APP_PSPDFKIT_LICENSE_KEY;
        PSPDFKIT_DOCUMENT_ENGINE_URL = process.env.REACT_APP_PSPDFKIT_DOCUMENT_ENGINE_URL;
        break;
    case "development":
        BASE_URL = "https://yeetch-dev.westeurope.cloudapp.azure.com";
        break;
    default:
        BASE_URL = "http://localhost:8080";
}

const BASE_URL_API = BASE_URL + "/api";

const BASE_URL_ILLUSTRATIONS =
    "https://yeetchstorage.blob.core.windows.net/static/illustration";

const AVATAR_DEFAULT = `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`;
const AVATAR_DEPARTMENT_DEFAULT = `${BASE_URL_ILLUSTRATIONS}/department.svg`;

const I18N_NS_TRANSLATION = "translation";

export {
    BASE_URL_API,
    BASE_URL,
    BASE_URL_ILLUSTRATIONS,
    AVATAR_DEFAULT,
    I18N_NS_TRANSLATION,
    AVATAR_DEPARTMENT_DEFAULT,
    PSPDFKIT_LICENSE_KEY,
    PSPDFKIT_DOCUMENT_ENGINE_URL
};

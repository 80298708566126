import { Avatar, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const TeamCard = ({ image, name, job_title }) => {
    return (
        <TeamCardStyle>
            <Avatar image={image} name={name} />
            <Flex column>
                <Name content={name} />
                <JobTitleTeamCard content={job_title} />
            </Flex>
        </TeamCardStyle>
    );
};

const TeamCardStyle = styled(Flex)`
    padding: 16px 20px;
    gap: 16px;
    min-width: 240px;
    height: 72px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
`;

const Name = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

const JobTitleTeamCard = styled(Text)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
`;

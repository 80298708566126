import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const ViewCard = styled(Flex)`
    border-radius: 8px;
    background: #fff;
    padding: 16px 24px;

    /* shadow-8 */
    box-shadow: 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13),
        0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11);
`;

export const Code = styled.code`
    color: #242424;
    background-color: #f1f1f1;
    padding: 8px 16px;
`;

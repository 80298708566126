import { Flex, Image, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const TextName = styled(Text)`
    color: #424242;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

export const TextTotal = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #424242;
`;

export const TextDays = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

export const TextMonth = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    text-transform: capitalize;
`;

export const TextLight = styled(Text)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #484644;
`;

export const TextBold = styled(Text)`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #252423;
`;

export const RowRight = styled(Flex)`
    flex: 2;
`;
export const RowLeft = styled(Flex)`
    flex: 1;
`;
export const Row = styled(Flex)`
    padding-block: 12px;
    border-bottom: 1px solid #e1dfdd;
`;

export const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
`;

export const Container = styled(Flex)`
    width: 600px;
    height: 418px;
    background: #ffffff;
    padding: 43px 32px 56px 32px;
    box-shadow: 0px 16px 18px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
`;

export const ErrorTitle = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
`;

export const ErrorDescription = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
`;

export const ValueBold = styled(Text)`
    color: #424242;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const Wrapper = styled(Flex)`
    height: 100vh;
    background-color: lightgray;
    position: relative;
`;

export const ImageClock = styled(Image)`
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 15%;
`;

export const TextDesc = styled(Text)`
    color: #252525;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 135.714% */
`;

import React from "react";
import CustomBreadCrumb from "../../../components/breadcrumb";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../constants/routes";
import GeneralDetailTaskContent from "./components/GeneralDetailTaskContent";
import { useLocation } from "react-router-dom";

const GeneralDetailTask = () => {
    const { t } = useTranslation();

    const location = useLocation();

    const accessStorage = localStorage.getItem("detail");
    const storageObj = JSON.parse(accessStorage);

    const is_in_preonboarding = storageObj.state.detail.is_in_preonboarding;

    const NAV_LIST = [
        {
            label: t("pages.manage_modal_tasks.breadcrumb.nav_manage_task"),
            href: ROUTES.task_manager,
        },
        {
            label: t("pages.manage_modal_tasks.breadcrumb.nav_detail_task"),
            href: "#",
        },
    ];

    if (location.state?.referrer?.includes("onboarding")) {
        NAV_LIST.unshift({
            label: "Onboarding",
            href: ROUTES.onboarding.home,
        });
    }

    return (
        <>
            {!is_in_preonboarding && <CustomBreadCrumb navList={NAV_LIST} />}

            <GeneralDetailTaskContent
                is_in_preonboarding={is_in_preonboarding}
            />
        </>
    );
};

export default GeneralDetailTask;

import React, { useState, useEffect } from "react";
import { Button, Flex, Image, Input, Text } from "@fluentui/react-northstar";
import moment from "moment";
import styled from "styled-components";

import Lottie from "react-lottie";
import * as animationData from "../../../../../../assets/lotties/birthday.json";

import LabelView from "../../../LabelView";
import IconBirthday from "../../../../../../assets/icons/employee_folder/Birthday.svg";
import { useMutation, useQueryClient } from "react-query";
import {
    _api_update_dates,
    _api_update_personal_information,
} from "../../../../api";
import { useForm } from "react-hook-form";
import { ROLES_CODE, roles_access } from "../../../../../../constants/roles";
import { QUERY_KEY } from "../../../../constants";
import { ErrorMessage } from "../../../../../../common/styles";

const DatesContractContent = ({
    start_date,
    departure_date,
    probationary_period_end_date,
    seniority,
    isCDI = false,
}) => {
    const clientQuery = useQueryClient();

    const [modeUpdate, setModeUpdate] = useState(false);
    const [startAnimationConfetti, setStartAnimationConfetti] = useState(false);
    const isHR = roles_access({
        usersPrivileges: [ROLES_CODE.hr],
    });

    useEffect(() => {
        if (startAnimationConfetti) {
            const id = setTimeout(() => {
                setStartAnimationConfetti(false);
            }, 5000);
            return () => {
                clearTimeout(id);
                return;
            };
        }
    }, [startAnimationConfetti]);

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        reset,
        getValues,
        setValue,
        trigger,
    } = useForm({
        mode: "onChange",
        defaultValues: {},
    });

    const { mutate: mutate_update_dates, isLoading: is_loading_mutate } =
        useMutation((data) => _api_update_dates(data));

    const _setModeUpdate = (bool) => {
        reset();
        return setModeUpdate(bool);
    };

    const onSubmit = (data) => {
        mutate_update_dates(
            {
                probationary_period_end_date:
                    data?.probationary_period_end_date,
                start_date: data?.start_date,
                end_date: data?.departure_date,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        clientQuery.resetQueries([QUERY_KEY + "::contract"]);
                        _setModeUpdate(false);
                    }
                    return;
                },
            }
        );
        return console.log({ data });
    };

    if (modeUpdate) {
        return (
            <Flex column gap="gap.medium">
                <Title
                    content={"Modification des dates"}
                    onClose={() => _setModeUpdate(false)}
                />
                <Flex fill>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ width: "100%" }}
                    >
                        <Flex column gap="gap.medium">
                            <Flex column>
                                <Input
                                    {...register("start_date", {
                                        required: "Ce champ est obligatoire",
                                    })}
                                    error={errors?.start_date?.message}
                                    label="Date d’embauche"
                                    defaultValue={start_date}
                                    type="date"
                                    fluid
                                />
                                <ErrorMessage
                                    content={errors?.start_date?.message}
                                />
                            </Flex>
                            <Flex gap="gap.small">
                                <Flex fill column>
                                    <Input
                                        {...register(
                                            "probationary_period_end_date",
                                            {
                                                required:
                                                    "Ce champ est obligatoire",
                                            }
                                        )}
                                        error={
                                            errors?.probationary_period_end_date
                                                ?.message
                                        }
                                        label="Fin de période d’essai"
                                        defaultValue={
                                            probationary_period_end_date
                                        }
                                        type="date"
                                        fluid
                                    />
                                    <ErrorMessage
                                        content={
                                            errors?.probationary_period_end_date
                                                ?.message
                                        }
                                    />
                                </Flex>
                                {!isCDI && (
                                    <Flex fill>
                                        <Input
                                            {...register("departure_date")}
                                            error={
                                                errors?.departure_date?.message
                                            }
                                            label="Fin de contrat"
                                            defaultValue={departure_date}
                                            type="date"
                                            fluid
                                        />
                                        <ErrorMessage
                                            content={
                                                errors?.departure_date?.message
                                            }
                                        />
                                    </Flex>
                                )}
                            </Flex>
                            <Flex hAlign="center" gap="gap.medium">
                                <Button
                                    content={"Annuler"}
                                    onClick={() => _setModeUpdate(false)}
                                    flat
                                />
                                <Button
                                    content={"Enregistrer les modifications"}
                                    // onClick={
                                    //     mutate_update_personnal_information
                                    // }
                                    loading={is_loading_mutate}
                                    primary
                                    flat
                                />
                            </Flex>
                        </Flex>
                    </form>
                </Flex>
            </Flex>
        );
    }

    const array_begin = start_date?.split("-");
    const array_begin_day = array_begin?.at(2);
    const array_begin_month = moment()
        .month(array_begin?.at(1) - 1)
        .format("MMM");

    const this_month = moment().month() + 1;

    const moment_end_test_period = probationary_period_end_date
        ? moment(probationary_period_end_date).format("DD/MM/YYYY")
        : "-";
    const moment_end_contract = departure_date
        ? moment(departure_date).format("DD/MM/YYYY")
        : "-";

    return (
        <Flex column gap="gap.large">
            {startAnimationConfetti && (
                <Lottie
                    options={{
                        loop: false,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                        },
                    }}
                    height={"100vh"}
                    width={400}
                    style={{
                        position: "absolute",
                        inset: "0",
                    }}
                />
            )}
            <Flex space="between">
                <Title content="Début du contrat en cours" />
                {isHR && (
                    <Button
                        size="small"
                        primary
                        text
                        content="Modifier"
                        onClick={() => _setModeUpdate(true)}
                    />
                )}
            </Flex>
            <Flex>
                <Flex fill>
                    <WrapperBeginDate column>
                        <Day content={array_begin_day} />
                        <Month
                            content={
                                array_begin_month + " " + array_begin?.at(0)
                            }
                        />
                    </WrapperBeginDate>
                </Flex>
                <Flex fill>
                    <LabelView
                        label="Ancienneté"
                        content={seniority?.seniority_text}
                    />
                    {parseInt(array_begin?.at(1)) === this_month && (
                        <Image
                            src={IconBirthday}
                            onClick={() => setStartAnimationConfetti(true)}
                            styles={{ cursor: "pointer" }}
                        />
                    )}
                </Flex>
            </Flex>
            <Flex>
                <LabelView
                    label="Fin de période d’essai"
                    content={moment_end_test_period}
                />
                {!isCDI && (
                    <LabelView
                        label="Fin de contrat"
                        content={moment_end_contract}
                    />
                )}
            </Flex>
        </Flex>
    );
};

export default DatesContractContent;

const Month = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #242424;
`;

const Day = styled(Text)`
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;

    color: #4f52b2;
`;

const WrapperBeginDate = styled(Flex)`
    background: #e8ebfa;
    border-radius: 2px;
    padding: 8px 12px;
    width: 120px;
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    color: #242424;
`;

import { Dialog, Loader } from "@fluentui/react-northstar";
import React from "react";
import DialogUpdateHrContent from "../DialogUpdateRhContent";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERY_KEY } from "../../../constants";
import { _helper_api_get_list_hr } from "../../../../../api/helpers";
import { _api_update_hr } from "../../../api";

const DialogUpdateHr = ({
    open_default = false,
    default_hr,
    onClose = () => console.log("Closed modal"),
}) => {
    const [selectedRh, setSelectedRh] = useState(default_hr);

    const clientQuery = useQueryClient();

    const reset = () => {
        setSelectedRh(default_hr);
        onClose();
        refetchListHr();
        return;
    };

    const {
        data: list_hr,
        isLoading,
        refetch: refetchListHr,
    } = useQuery([QUERY_KEY + "::list_hr"], () => _helper_api_get_list_hr(), {
        refetchOnWindowFocus: false,
    });

    const { isLoading: is_loading_mutate, mutate: mutate_update_rh } =
        useMutation((data) => _api_update_hr(data), {
            onSuccess: (data) => {
                if (data?.success) {
                    clientQuery.refetchQueries(QUERY_KEY + "::about");
                    reset();
                    return;
                }
            },
        });

    if (isLoading) {
        return <Loader content="Chargement de la liste HR" />;
    }

    return (
        <Dialog
            open={open_default}
            header="Modifier le RH"
            content={
                <DialogUpdateHrContent
                    list_hr={list_hr}
                    default_hr={selectedRh ? selectedRh : default_hr}
                    setSelectedRh={setSelectedRh}
                />
            }
            onCancel={reset}
            confirmButton={{
                content: "Valider le RH",
                flat: true,
                disabled: selectedRh?.email === default_hr?.email,
                loading: is_loading_mutate,
                onClick: () =>
                    mutate_update_rh({
                        hr_email: selectedRh?.email,
                    }),
            }}
            cancelButton={{
                content: "Annuler",
                flat: true,
            }}
            styles={{
                width: "391px",
            }}
        />
    );
};

export default DialogUpdateHr;

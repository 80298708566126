import { Flex, FlexItem, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { BASE_URL_ILLUSTRATIONS } from "../../../constants";
import EmptyAbsence from "../../../assets/illustrations/leave/EmptyAbsence.png";

function EmptyDemande({ MsgForManager, forLeave, forTeamSolde }) {
    return (
        <Flex gap="gap.medium" column vAlign="center" hAlign="center">
            {!forLeave ? (
                <>
                    <FlexItem>
                        <Image
                            style={{ opacity: "0.7" }}
                            width={"500px"}
                            src={`${BASE_URL_ILLUSTRATIONS}/Pas de vacs.svg`}
                        />
                    </FlexItem>
                    <Flex column styles={{ paddingBottom: "55px" }}>
                        {MsgForManager ? (
                            <TextStyle content={MsgForManager} />
                        ) : (
                            <TextStyle content="Vous n’avez pas encore pris de congés" />
                        )}
                        <DescriptionStyle content="Le repos est important pour la santé !" />
                    </Flex>
                </>
            ) : (
                <Flex
                    column
                    hAlign="center"
                    styles={{
                        marginTop: "55px",
                        gap: "25px",
                    }}
                >
                    <Image
                        style={{
                            // opacity: "0.7",
                            width: "240px",
                            height: "251.89px",
                        }}
                        // width={"400px"}
                        src={EmptyAbsence}
                    />
                    {!forTeamSolde ? (
                        <EmptyListText content="Aucune demande dans la liste" />
                    ) : null}
                </Flex>
            )}
        </Flex>
    );
}

export default EmptyDemande;

const TextStyle = styled(Text)`
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    width: 285px;
    height: 48px;
    text-align: center;
`;
const DescriptionStyle = styled(Text)`
    /* width: 285px; */
    height: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #242424;
    padding-bottom: 45px;
    /* margin-bottom: 15vh; */
`;

const EmptyListText = styled(Text)`
    color: #616161;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

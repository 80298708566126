import { Avatar, Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import { AppsListDetail20Filled } from "@fluentui/react-icons";
import styled from "styled-components";
import moment from "moment";
import EmptyHistory from "./EmptyHistory.svg";

export default function HistoriqueSuiviCollab({ dataSuivi }) {
    // const historyData = [
    //     {
    //         date: "30/01/2025",
    //         avatar: "https://randomuser.me/api/portraits",
    //         cardTitle: "Fin de la période d'essai",
    //         cardDescription: "Cloture de l'onboarding",
    //         color: "white",
    //     },
    //     {
    //         date: "12/12/2024",
    //         avatar: "https://randomuser.me/api/portraits",
    //         cardTitle: "Jour d'arrivée ",
    //         cardDescription: "Démarrage de l'onboarding",
    //     },
    //     {
    //         date: "01/12/2024",
    //         avatar: "https://randomuser.me/api/portraits",
    //         cardTitle: "Lancement du Pré-onboarding",
    //         cardDescription: "Administratif avant le jour d'arrivée",
    //     },
    //     {
    //         date: "01/12/2024",
    //         avatar: "https://randomuser.me/api/portraits",
    //         cardTitle: "Lancement du Pré-onboarding",
    //         cardDescription: "Administratif avant le jour d'arrivée",
    //     },
    // ];

    const history = [
        {
            date: "2024-09-22",
            status: 0,
            status_text: "En pré-onboarding",
            processed_by_name: "Ali ben salah",
            processed_by_avatar:
                "https://asenditbackenddevstorage.blob.core.windows.net/documents/asendit/users/16/profile_photo/profile.jpeg?se=2024-09-28T09%3A43%3A34Z&sp=r&sv=2021-06-08&sr=b&sig=0EKZ2tPnWREWN3QJ2gWX7ekkV2iiYAGG2wrp4FKB6f8%3D",
            color: "white",
        },
        {
            date: "2024-09-22",
            status: 1,
            status_text: "Actif",
            processed_by_name: "Foued SAIDANE",
            processed_by_avatar:
                "https://asenditbackenddevstorage.blob.core.windows.net/documents/asendit/users/16/profile_photo/profile.jpeg?se=2024-09-28T09%3A43%3A34Z&sp=r&sv=2021-06-08&sr=b&sig=0EKZ2tPnWREWN3QJ2gWX7ekkV2iiYAGG2wrp4FKB6f8%3D",
        },
    ];

    console.log("dataSuivi", dataSuivi);
    console.log("history", dataSuivi?.history);
    return (
        <HistoryContainer column>
            <HistoriqueHeader>
                <AppsListDetail20Filled />
                <HeaderText content="Historique du collaborateur" />
            </HistoriqueHeader>
            <HistoryFlex column>
                {dataSuivi?.history?.length === 0 && (
                    <Flex
                        gap="gap.large"
                        fill
                        hAlign="center"
                        vAlign="center"
                        column
                    >
                        <Image width={200} height={200} src={EmptyHistory} />
                        <EmptyHistoryText content="Aucun historique à afficher" />
                    </Flex>
                )}
                {dataSuivi?.history?.map((history) => (
                    <HistoriqueCard
                        date={history?.date}
                        avatar={history?.processed_by_avatar}
                        cardTitle={history?.status_text}
                        cardDescription={history?.status_description}
                        userName={history?.processed_by_name}
                        // color={history.color}
                    />
                ))}
            </HistoryFlex>
        </HistoryContainer>
    );
}

const HistoriqueCard = ({
    date,
    cardTitle,
    cardDescription,
    avatar,
    userName,
    color,
}) => {
    const formattedDate = moment(date).format("DD/MM/YYYY");
    return (
        <Flex gap="gap.medium" column>
            <DateFlex color={color}>
                <Text>{formattedDate}</Text>
            </DateFlex>
            <Card gap="gap.small">
                <Avatar
                    circular
                    avatar
                    image={avatar}
                    name={userName ? userName : "Amanda Bradi"}
                />
                <Flex column>
                    <Text>{cardTitle}</Text>
                    <Text>{cardDescription}</Text>
                </Flex>
            </Card>
        </Flex>
    );
};

const EmptyHistoryText = styled(Text)`
    color: #616161;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

const HistoryFlex = styled(Flex)`
    border-radius: 0 0 10px 10px;
    background: #fff;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
    padding: 24px 16px;
    gap: 24px;
    height: 417px;
    overflow-y: scroll;
`;

const DateFlex = styled(Flex)`
    width: fit-content;
    padding: 4px 8px;
    background-color: ${({ color }) => (color ? "#5B5FC7" : "#E8EBFA")};
    color: ${({ color }) => (color ? color : "black")};
    border-radius: 4px;
`;

const Card = styled(Flex)`
    padding: 12px 16px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13);
`;

const HistoriqueHeader = styled(Flex)`
    border-radius: 8px 8px 0px 0px;
    background: #e8ebfa;
    width: 418px;
    /* height: 24px; */
    padding: 12px 20px;
`;

const HeaderText = styled(Text)`
    color: #424242;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

const HistoryContainer = styled(Flex)`
    /* gap: 24px; */
`;

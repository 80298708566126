import React, { memo, useEffect } from "react";
import styled from "styled-components";
import {
    AddIcon,
    Alert,
    CheckmarkCircleIcon,
    ExclamationTriangleIcon,
    InfoIcon,
} from "@fluentui/react-northstar";

const renderIconDependingLevel = (level) => {
    switch (level) {
        case "success":
            return <CheckmarkCircleIcon />;
        case "warning":
            return <ExclamationTriangleIcon />;
        case "danger":
            return <ExclamationTriangleIcon />;
        case "info":
            return <InfoIcon outline />;

        default:
            return false;
    }
};

const Toast = ({
    content = "Chargement ...",
    visible,
    level = "warning",
    actions = [],
    auto_hide_timeout = 3000,
    dismissible = false,
    onStatusChange = () => {},
    ...rest
}) => {
    useEffect(() => {
        console.log("called Toast component !");
        if ((visible && !dismissible) || (visible && auto_hide_timeout)) {
            const timeout_id = setTimeout(() => {
                onStatusChange();
            }, auto_hide_timeout);
            return () => clearTimeout(timeout_id);
        }
    }, [visible]);

    return (
        <AlertStyle
            fitted
            content={content}
            visible={visible}
            success={level === "success" ? true : false}
            danger={level === "danger" ? true : false}
            warning={level === "warning" ? true : false}
            info={level === "info" ? true : false}
            icon={renderIconDependingLevel(level)}
            actions={actions}
            dismissible={dismissible}
            dismissAction={{
                "aria-label": "close",
                onClick: () => onStatusChange(),
            }}
            {...rest}
        />
    );
};
export default Toast;

const AlertStyle = styled(Alert)`
    position: fixed;
    top: 110px;
    width: fit-content;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10000000000000000000000000000000000000000;
`;

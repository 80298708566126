import {
    ArrowLeftIcon,
    Button,
    CloseIcon,
    Divider,
    EyeSlashIcon,
    Flex,
    Image,
    Input,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import Logo_yeetch from "./img/Logo_yeetch.svg";
import Signin from "./img/Signin.svg";
import { DEVICE } from "../../constants/responsive";

const PasswordConnect = () => {
    return (
        <RightContent vAlign="center" column hAlign="center">
            <PasswoordConnectBody column hAlign="center" vAlign="center">
                <LogoFlex gap="gap.medium">
                    <Image src={Logo_yeetch} alt="logo yeetch" />
                    <LogoText content="Yeetch" />
                </LogoFlex>
                <Flex>
                    <Image src={Signin} />
                </Flex>
                <Flex hAlign="start" column styles={{ gap: "16px" }}>
                    <ConnectText content="Se connecter avec son email" />
                    <form>
                        <PasswordBlock
                            gap="gap.medium"
                            column
                            // onSubmit={handleClick}
                        >
                            <Flex gap="gap.medium" column>
                                {/* email */}
                                <Flex fill>
                                    <Input
                                        label="Email"
                                        // value={email}
                                        value={"email"}
                                        fluid
                                        icon={
                                            <CloseIcon
                                                size="small"
                                                // onClick={GoBack}
                                            />
                                        }
                                        // content={email}
                                        content={"email"}
                                    />
                                </Flex>
                                {/* mdp */}
                                <Flex column>
                                    <Input
                                        fluid
                                        label="Mot de passe"
                                        placeholder="Votre mot de passe"
                                        // type={
                                        //     values.showPassword
                                        //         ? "text"
                                        //         : "password"
                                        // }
                                        type={"text"}
                                        // value={values.password}
                                        // onChange={handleChange("password")}
                                        icon={
                                            <Button
                                                iconOnly
                                                text
                                                flat
                                                // onClick={handleClickShowPassword}
                                                // onMouseDown={
                                                //     handleMouseDownPassword
                                                // }
                                            >
                                                {/* {values.showPassword ? (
                                        <EyeIcon />
                                    ) : (
                                        <EyeSlashIcon />
                                        )} */}
                                                <EyeSlashIcon />
                                            </Button>
                                        }
                                    />
                                </Flex>
                            </Flex>
                            {/* Button */}
                            <Flex
                                // space="between"

                                // hAlign="center"
                                // vAlign="center"
                                styles={{ gap: "16px" }}
                                column
                                fluid
                            >
                                <Button
                                    // disabled={values.password ? false : true}
                                    // loading={isLoading || isFetching ? true : false}
                                    // onClick={handleClick}
                                    flat
                                    primary
                                    content={
                                        <ConnectButtonText
                                            style={{
                                                textTransform: "uppercase",
                                            }}
                                            content="se connecter avec son email"
                                        />
                                    }
                                    // error={error ? { error } : null}
                                />
                                <Button
                                    icon={<ArrowLeftIcon size="small" />}
                                    // onClick={GoBack}
                                    flat
                                    content={
                                        <ChangeModeText content="Changer de mode de connexion" />
                                    }
                                    text
                                />
                            </Flex>
                        </PasswordBlock>
                    </form>
                </Flex>
            </PasswoordConnectBody>
            <Footer>
                <Flex>
                    <FooterText content="Politique de confidentialité" />
                    <Divider vertical size={1} styles={{ height: "16px" }} />
                    <FooterText content="Assistance" />
                </Flex>
                <FooterSign content="© YEETCH 2023" />
            </Footer>
        </RightContent>
    );
};

export default PasswordConnect;

const Footer = styled(Flex)`
    gap: 40px;
`;
const FooterSign = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #616161;
`;

const FooterText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0067b8;
`;

const ChangeModeText = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #242424;
`;

const ConnectButtonText = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
`;

const ConnectText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #424242;
`;

const RightContent = styled(Flex)`
    min-width: 50vw;
    height: 100vh;
    gap: 40px;
`;

const PasswoordConnectBody = styled(Flex)`
    gap: 45px;
`;
const LogoFlex = styled(Flex)``;
const LogoText = styled(Text)`
    font-family: "Fredoka";
    font-style: normal;
    font-weight: 500;
    font-size: 33px;
    line-height: 40px;
    letter-spacing: -0.085em;
    color: #000000;
`;

const PasswordBlock = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 250px;
    }
    @media ${DEVICE.mobileM} {
        width: 100%;
    }
    @media ${DEVICE.mobileL} {
        /* width: 320px; */
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: 100%;
    }
`;

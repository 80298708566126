import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Flex, Skeleton, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";

// for status of months
export const STATUS_MONTH = {
    in_progress: {
        type: "in_progress",
        content: "home.sections.month.status.in_progress",
        style: {
            backgroundColor: "#5B5FC7",
            color: "#fff",
        },
    },
    validated: {
        type: "validated",
        content: "home.sections.month.status.validated",
        style: {
            backgroundColor: "#E7F2DA",
            color: "#13A10E",
        },
    },
    rectification: {
        type: "rectification",
        content: "home.sections.month.status.rectification",
        style: {
            backgroundColor: "#FFAA44",
            color: "#fff",
        },
    },
    sended: {
        type: "sended",
        content: "home.sections.month.status.sended",
        style: {
            backgroundColor: "#EBEBEB",
            color: "#424242",
        },
    },
    default: {
        type: "default",
        content: "/",
        style: {
            backgroundColor: "white",
            color: "white",
        },
    },
};

const BadgeMonthStatusSkeleton = () => {
    return (
        <Container>
            <Skeleton animation="pulse">
                <Skeleton.Line width="78px" height="16px" />
            </Skeleton>
        </Container>
    );
};

const BadgeMonthStatus = ({ level, isLoading = false }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const { content, style } = STATUS_MONTH[level];

    if (isLoading) {
        return <BadgeMonthStatusSkeleton />;
    }

    return (
        <Container styles={style}>
            <ContainerText content={t(content)} />
        </Container>
    );
};

export default BadgeMonthStatus;

BadgeMonthStatus.propTypes = {
    level: PropTypes.oneOf([
        "in_progress",
        "validated",
        "rectification",
        "sended",
    ]).isRequired,
};

const ContainerText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
`;

const Container = styled(Flex)`
    width: fit-content;
    border-radius: 100px;
    padding: 2px 6px;
`;

import React from "react";
import { useParams } from "react-router-dom";

import { TYPE_FORM } from "../constants";

import ExpensesUpdateFormClassic from "./ExpensesUpdateFormClassic";
import ExpensesUpdateFormKmFr from "./ExpensesUpdateFormKmFr";
import ExpensesUpdateFormKmCh from "./ExpensesUpdateFormKmCh";

const ExpensesAddForm = () => {
    const {
        id_category: param_id_category,
        id_expense: param_id_expense,
        type_form: param_type_form,
    } = useParams();

    switch (param_type_form) {
        case TYPE_FORM.classic:
            return <ExpensesUpdateFormClassic />;
        case TYPE_FORM.km_fr:
            return <ExpensesUpdateFormKmFr />;
        case TYPE_FORM.km_ch:
            return <ExpensesUpdateFormKmCh />;
        default:
            return <ExpensesUpdateFormClassic />;
    }
};

export default ExpensesAddForm;

import { BASE_URL_API } from "../../constants";
import { useAuthStore } from "../../stores/useAuthStore";
import fetcher from "../../utils/fetcher";

export const getAllFolders = async () => {
    return fetcher(BASE_URL_API + "/document/folder/list", {
        method: "GET",
        credentials: "include",
    });
};

export const getDocumentsByFolderName = async (folder_name) => {
    return fetcher(`${BASE_URL_API}/document/${folder_name}`, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_delete_document = async ({ id_document }) => {
    return fetcher(`${BASE_URL_API}/document/${id_document}/delete`, {
        method: "DELETE",
        credentials: "include",
    });
};

export const _api_upload_document = async ({
    uploaded_files,
    folder_name = "",
}) => {
    const fd = new FormData();
    fd.append("uploaded_files", ...uploaded_files);
    fd.append("folder_name", folder_name);

    const user = useAuthStore.getState().infos;

    return fetch(BASE_URL_API + "/document/upload", {
        method: "POST",
        body: fd,
        headers: {
            Authorization: `Bearer ${user?.access}`,
        },
        credentials: "include",
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (!data.success) {
                throw new Error(data.message);
            }
            return data;
        })
        .catch((error) => {
            // console.log(error);
            return {
                success: false,
                message: error,
                files: [],
            };
        });
};

export const _api_download_file = async ({ id, filename }) => {
    const user = useAuthStore.getState().infos;
    return fetch(`${BASE_URL_API}/document/${id}/download`, {
        headers: {
            Authorization: `Bearer ${user?.access}`,
        },
    })
        .then((res) => {
            return res.blob();
        })
        .then((data) => {
            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(data);
            a.download = filename.toLowerCase();
            a.click();
        });
};

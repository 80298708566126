import React from "react";
import {
    Avatar,
    Checkbox,
    Dropdown,
    Flex,
    Input,
    InputLabel,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import CardUser from "../../Cards/CardPositionEntreprise/CardUser";

const DialogUpdateSupervisorManager = () => {
    return (
        <Flex fill column>
            <Wrapper fill column gap="gap.medium">
                <Header column fill>
                    <InputLabel content="Manager d'origine" fluid />
                    <CardUser
                        avatar={"employee?.avatar"}
                        full_name={`${"employee?.first_name"} ${"employee?.last_name"}`}
                        job_title={"employee?.job_title"}
                        hideTitle={true}
                        // onClick={() =>
                        //     _navigateToEmployee(employee?.id)
                        // }
                    />
                </Header>
                <Flex column fill>
                    <InputLabel content="Manager destinataire" fluid />
                    <Dropdown
                        items={[
                            "qlkdjsfkljdqsf",
                            "qmldksjflmqkdjsf",
                            "ldkjfkqldjsfqsdf",
                        ]}
                        placeholder="Select your hero"
                        checkable
                        fluid
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                    />
                </Flex>
                <Flex
                    column
                    gap="gap.medium"
                    styles={{ paddingBottom: "30px" }}
                >
                    <InputLabel content="Personnes qui changeront de manager" />
                    <Flex column gap="gap.small">
                        <Checkbox label="Toutes" />
                        <CheckboxStyle
                            label={
                                <Flex gap="gap.small" vAlign="center">
                                    <Avatar avatar="" name="kqjsd hfkjqsh df" />
                                    <Text content="Andy coucou" />
                                </Flex>
                            }
                        />
                        <CheckboxStyle
                            label={
                                <Flex gap="gap.small" vAlign="center">
                                    <Avatar avatar="" name="kqjsd hfkjqsh df" />
                                    <Text content="Andy coucou" />
                                </Flex>
                            }
                        />
                        <CheckboxStyle
                            label={
                                <Flex gap="gap.small" vAlign="center">
                                    <Avatar avatar="" name="kqjsd hfkjqsh df" />
                                    <Text content="Andy coucou" />
                                </Flex>
                            }
                        />
                        <CheckboxStyle
                            label={
                                <Flex gap="gap.small" vAlign="center">
                                    <Avatar avatar="" name="kqjsd hfkjqsh df" />
                                    <Text content="Andy coucou" />
                                </Flex>
                            }
                        />
                        <CheckboxStyle
                            label={
                                <Flex gap="gap.small" vAlign="center">
                                    <Avatar avatar="" name="kqjsd hfkjqsh df" />
                                    <Text content="Andy coucou" />
                                </Flex>
                            }
                        />
                    </Flex>
                </Flex>
            </Wrapper>
        </Flex>
    );
};

export default DialogUpdateSupervisorManager;

const CheckboxStyle = styled(Checkbox)`
    align-items: center;
`;

const Header = styled(Flex)`
    padding-top: 35px;
`;

const Wrapper = styled(Flex)`
    /* height: 300px; */
    overflow-y: scroll;
    overflow-x: hidden;
    border-bottom: 1px solid #d1d1d1;
`;

import React from "react";
import {
    AddIcon,
    Button,
    Divider,
    Flex,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { ContainerFluid, Container } from "../../../common/styles";
import { I18N_NS_EXPENSE, QUERY_KEY } from "../constants";
import { useQuery } from "react-query";
import { _api_get_categories } from "../api";
import Categories from "./Category";
import { useNavigate, useParams } from "react-router-dom";

const ExpensesAddCategory = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const navigate = useNavigate();
    const { id_expense: id_report } = useParams();

    const _goback = () => {
        return navigate(-1);
    };

    const { data: categories, isLoading } = useQuery(
        QUERY_KEY + "::categories",
        () => _api_get_categories(),
        {
            refetchOnWindowFocus: false,
        }
    );

    return (
        <ContainerFluid>
            <Container gap="gap.large" style={{ marginTop: "24px" }}>
                <Wrapper fill column gap="gap.medium">
                    <Flex fill space="between">
                        <Title>{t("add_category.title")}</Title>

                        <Button
                            text
                            primary
                            content={t("common.back")}
                            onClick={_goback}
                        />
                    </Flex>
                    <Flex fill wrap>
                        <WhiteBoard fill gap="gap.large" wrap>
                            {isLoading ? (
                                <Loader content="Chargement des catégories" />
                            ) : (
                                <Categories
                                    categories={categories}
                                    id_report={id_report}
                                />
                            )}
                        </WhiteBoard>
                    </Flex>
                </Wrapper>
            </Container>
        </ContainerFluid>
    );
};

export default ExpensesAddCategory;

const WhiteBoard = styled(Flex)`
    background-color: white;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    /* padding: 16px 16px 32px 36px; */
`;

const Wrapper = styled(Flex)`
    width: 850px;
`;

const Title = styled(Text)`
    font-weight: 700;
    font-size: clamp(18px, 2vw, 24px);
    line-height: clamp(24px, 2vw, 32px);
    color: #242424;
    margin-left: 16px;
`;

export const ACTION_TYPES_TEMPLATE = {
    init: "init",
    update_slots_day: "update_slot_day",
    set_display_weekend: "set_display_weekend",
    set_is_dirty: "set_is_dirty",
};

// id: 2576
// start_time: "08:15:00"
// end_time: "12:47:43"
// duration_str: "04h32"

export const reducerTypeTemplate = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES_TEMPLATE.init: {
            return {
                data: action.payload.data,
                is_dirty: false,
                display_weekend: action.payload?.display_weekend,
            };
        }
        case ACTION_TYPES_TEMPLATE.update_slots_day: {
            const { id, slots, duration_total } = action.payload;
            const new_state = state.data.map((day) => {
                if (day.id === id) {
                    return {
                        ...day,
                        id: id,
                        slots,
                        duration: duration_total,
                    };
                }
                return day;
            });
            return {
                ...state,
                data: new_state,
                is_dirty: true,
            };
        }
        case ACTION_TYPES_TEMPLATE.set_display_weekend: {
            return {
                ...state,
                display_weekend: action.payload.value,
                is_dirty: true,
            };
        }
        case ACTION_TYPES_TEMPLATE.set_is_dirty: {
            return {
                ...state,
                is_dirty: action.payload.value,
            };
        }
        default:
            return state;
    }
};

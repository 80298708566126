import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import DependentsPersons from "./components/DependentsPersons";
import EmergencyContacts from "./components/EmergencyContacts";

const SurroundingPeople = ({ emergency_contacts, dependents_person }) => {
    return (
        <>
            <Flex column gap="gap.large">
                <DependentsPersons dependents_person={dependents_person} />
                <EmergencyContacts emergency_contacts={emergency_contacts} />
            </Flex>
        </>
    );
};

export default SurroundingPeople;

import React, { useEffect } from "react";
import { ContainerFluid, Container } from "../../../../common/styles";
import {
    api_personal_calendar_detail,
    api_personal_list_leave,
} from "../../api";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { useCallback } from "react";
import { format_events, refetchTeamBadge } from "../../utils";
import styled from "styled-components/macro";
import {
    Avatar,
    ChevronDownIcon,
    CloseIcon,
    Dialog,
    Dropdown,
    DropdownItem,
    Flex,
    Loader,
    Skeleton,
    Table,
    TableCell,
    TableRow,
    Text,
} from "@fluentui/react-northstar";
import moment from "moment";
import { LeaveStatusBadge } from "./LeaveStatusBadge";
import { useState } from "react";

import EmptyDemande from "../EmptyDemande";
import FilterList from "./FilterList";
import DialogLeaveDetail from "../../../../components/Timeline/components/Dialogs/DialogLeaveDetail";
import Toast from "../../../../components/Alerts";
import { DEVICE, SIZES } from "../../../../constants/responsive";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../../../components/Pagination/Pagination";
import {
    GlobalLabel,
    TextDate,
    TextType,
} from "./LeaveRequestList/index.style";
import { CustomBadge } from "../../../../components/Badge";

import SkeletonLeaveList from "./SkeletonListLeave/SkeletonLeaveList";

const INIT_CONFIG_DATA = {
    visible: false,
    data: null,
};

const CollabList = ({
    setConfigDetail,
    cancelLeavId,
    setCancelLeaveId,

    isMobileS,
}) => {
    const [searchParams, setSearchParams] = useSearchParams({
        s: null,
    });

    const [toastConfig, setToastConfig] = useState({
        content: "",
        visible: false,
        level: "warning",
    });

    const {
        data: myPersonalLeave,
        isLoading: loadingMyPersonalLeave,
        isFetching: FetchingMypersonalLeave,
        refetch: refetchmypersonalData,
    } = useQuery(["myPersonalCalendar"], () => api_personal_calendar_detail(), {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });

    // QUERIES
    const {
        data,
        fetchNextPage,
        isLoading,
        isFetching,
        isFetchingNextPage,
        refetch,
    } = useInfiniteQuery({
        queryKey: [
            "leave::getMyListLeaveRow",
            {
                params_string: searchParams.toString(),
            },
        ],
        queryFn: (state) => {
            return api_personal_list_leave({
                page: state?.pageParam,
                ...state.queryKey.at(1),
            });
        },
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.next_page_value;
        },
    });

    const [sortedData, setSortedData] = useState({
        direction: "desc",
        leaves: [],
    });

    const format_events_full_calendar = useCallback(() => {
        return format_events(myPersonalLeave?.leaves || []);
    }, [myPersonalLeave]);

    const [leaveListCol, setLeaveListCol] = useState([]);
    const [filterId, setFilterId] = useState(4);

    const inputItems = [
        {
            id: 0,
            header: isMobileS ? "Toutes" : "Toutes les absences",
        },
        { id: 1, header: "En attente" },
        { id: 2, header: "Validées" },
    ];

    const header = {
        key: "header",
        style: {
            backgroundColor: "#F5F5F5",
            width: "100%",
        },
        items: [
            {
                key: "leave_type",
                content: (
                    <Flex vAlign="center" gap="gap.small">
                        <GlobalLabel content={"Type d'absence"} />
                        {!isMobileS && (
                            <CustomBadge
                                bg="black"
                                color="white"
                                size="small"
                                label={"Durée"}
                                rectangular={true}
                                style={{
                                    borderRadius: "4px",
                                }}
                            />
                        )}
                    </Flex>
                ),
            },
            {
                key: "start_date",
                content: !isMobileS && <GlobalLabel content={"Début"} />,
                style: {
                    justifyContent: "end",
                    alignItems: "center",
                    textAlign: "end",
                },
            },
            {
                key: "end_date",
                content: !isMobileS && <GlobalLabel content={"Fin"} />,
                style: {
                    justifyContent: "start",
                    alignItems: "center",
                    textAlign: "start",
                },
            },
            {
                key: "status",
                content: (
                    <DropdownStyle
                        className="dropdown_styled"
                        items={inputItems}
                        defaultValue={inputItems.at(0)}
                        checkable
                        fluid
                        onChange={(e, i) => {
                            const value = i?.value;

                            setSearchParams(
                                value.id === 0
                                    ? ""
                                    : {
                                          s: value.id,
                                      }
                            );
                            return;
                        }}
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                        styles={{
                            borderRadius: "4px",
                            border: "1px solid #D1D1D1",
                            backgroundColor: "#F5F5F5",
                            textAlign: "start",
                            width: isMobileS ? "100px" : "190px",
                        }}
                    />
                ),
                style: {
                    color: "red",
                    justifyContent: "end",
                    alignItems: "center",
                    textAlign: "start",
                },
            },
        ],
    };

    const successRequest = ({ toastMessage = "", level = "success" }) => {
        // show toast.
        setToastConfig({
            visible: true,
            content: toastMessage,
            level,
        });
        return;
    };

    const onFilter = ({ filteredId }) => {
        setFilterId(filteredId);
        let old_data = format_events_full_calendar();

        console.log({ filteredId });

        let statusIds = [filteredId];
        if (filteredId === 2) {
            statusIds.push(3);
        }

        if (filteredId === 4) {
            // refetchTeamCall();
            setLeaveListCol(old_data);
            return setSortedData((state) => {
                return {
                    ...state,
                    leaves: old_data,
                };
            });
        }

        const allFiltered = old_data.filter((el) =>
            statusIds.includes(el?.data?.status?.id)
        );

        setLeaveListCol(allFiltered);
        // here
        setSortedData((state) => {
            return {
                ...state,
                leaves: allFiltered,
            };
        });
    };

    useEffect(() => {
        let collabLeaveList = format_events_full_calendar();
        // setLeaveListCol(collabLeaveList);

        if (cancelLeavId) {
            onFilter({ filteredId: 1 });
            setCancelLeaveId(null);
        }
        // here
        setSortedData((state) => {
            return {
                ...state,
                leaves: collabLeaveList,
            };
        });

        return;
    }, [myPersonalLeave]);

    // here

    // VALUES
    const last_page = data?.pages[data?.pages?.length - 1];
    const currentPageToShow = last_page?.next_page_value
        ? (last_page?.next_page_value - 1) * 10
        : data?.pages[0]?.all_leaves_count;
    const is_more_rows = last_page?.next_page_value;

    const ROWS =
        data?.pages
            ?.map((pages, index) => {
                return pages?.leaves?.map((leave, i) => {
                    const username = leave?.user?.name;
                    const avatar = leave?.user?.avatar;
                    const status_id = leave?.status?.id;
                    const end_date = moment(leave?.end_date).format(
                        "DD/MM/YYYY"
                    );
                    const Mobile_end_date = moment(leave?.end_date).format(
                        "DD/MM/YY"
                    );
                    const start_date = moment(leave?.start_date).format(
                        "DD/MM/YYYY"
                    );
                    const Mobile_start_date = moment(leave?.start_date).format(
                        "DD/MM/YY"
                    );
                    const balance_count_to_remove =
                        leave?.balance_count_to_remove;
                    const { id: type_id, name: type_name, color } = leave?.type;

                    return {
                        key: leave?.id,
                        items: [
                            {
                                truncateContent: true,
                                key: `${leave?.id}-2`,
                                content: (
                                    <CustomBadge
                                        isMobileS={isMobileS}
                                        bg={color}
                                        color="black"
                                        size="medium"
                                        label={
                                            <Flex
                                                hAlign="center"
                                                vAlign="center"
                                                styles={{
                                                    gap: "18px !important",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <TextType content={type_name} />
                                                {!isMobileS && (
                                                    <CustomBadge
                                                        isMobileS={isMobileS}
                                                        bg="black"
                                                        color="white"
                                                        size="smaller"
                                                        label={`${balance_count_to_remove} j`}
                                                        rectangular={true}
                                                        style={{
                                                            borderRadius: "4px",
                                                        }}
                                                    />
                                                )}
                                            </Flex>
                                        }
                                        rectangular={true}
                                        style={{
                                            borderRadius: "4px",
                                        }}
                                    />
                                ),
                                style: {
                                    justifyContent: "start",
                                    alignItems: "center",
                                },
                            },
                            {
                                key: `${leave?.id}-3`,
                                content: (
                                    <TextDate
                                        isMobileS={isMobileS}
                                        content={
                                            isMobileS
                                                ? Mobile_start_date
                                                : start_date
                                        }
                                    />
                                ),
                                style: {
                                    justifyContent: "end",
                                    alignItems: "center",
                                    maxWidth: isMobileS
                                        ? "50px !important"
                                        : "",
                                },
                            },
                            {
                                key: `${leave?.id}-4`,
                                content: (
                                    <TextDate
                                        isMobileS={isMobileS}
                                        content={
                                            isMobileS
                                                ? Mobile_end_date
                                                : end_date
                                        }
                                    />
                                ),
                                style: {
                                    justifyContent: "start",
                                    alignItems: "center",

                                    maxWidth: isMobileS
                                        ? "80px !important"
                                        : "",
                                },
                            },
                            {
                                key: `${leave?.id}-5`,
                                content: LeaveStatusBadge(status_id),
                                style: {
                                    justifyContent: "end",
                                    alignItems: "center",

                                    maxWidth: isMobileS
                                        ? "50px !important"
                                        : "",
                                },
                            },
                        ],
                        data_row: leave,
                        style: {
                            cursor: "pointer",
                        },
                    };
                });
            })
            ?.flat() ?? [];

    const queryClient = useQueryClient();

    return (
        <ContainerFluid>
            <Toast
                onStatusChange={() =>
                    setToastConfig({
                        visible: false,
                        content: "",
                        level: "warning",
                    })
                }
                level={toastConfig.level}
                content={toastConfig.content}
                visible={toastConfig.visible}
            />

            {myPersonalLeave?.leaves?.length === 0 ? (
                <Container column gap="gap.large">
                    <EmptyDemande forLeave={true} />
                </Container>
            ) : (
                <Container
                    styles={{ paddingTop: "24px" }}
                    column
                    gap="gap.large"
                >
                    <Table>
                        {/* header */}
                        <TableRow key={header.key} styles={header?.style}>
                            {header.items.map((head) => {
                                return (
                                    // console.log({ head });

                                    <TableCell
                                        key={head.key}
                                        styles={head?.style}
                                    >
                                        {head.content}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        {data?.pages[0]?.all_leaves_count === 0 ? (
                            <Container column>
                                <EmptyDemande forLeave={true} />
                            </Container>
                        ) : isLoading || isFetching ? (
                            <SkeletonLeaveList />
                        ) : (
                            ROWS.flat().map((row) => {
                                return (
                                    <DialogLeaveDetail
                                        leave_details={{ ...row?.data_row }}
                                        user_details={{
                                            ...row?.data_row?.user,
                                        }}
                                        content={
                                            <TableRow
                                                key={row.key}
                                                styles={row?.style}
                                            >
                                                {row?.items?.map((item) => {
                                                    return (
                                                        <TableCell
                                                            key={item.key}
                                                            vAlign="center"
                                                            styles={item.style}
                                                        >
                                                            {item.content}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        }
                                        cbOnApproveLeave={(data) => {
                                            const isSuccess = data?.success;
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été acceptée"
                                                    : data?.message,
                                            });
                                            refetchTeamBadge(queryClient);
                                            refetch();
                                            return;
                                        }}
                                        cbOnRefuseLeave={(data) => {
                                            const isSuccess = data?.success;
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été refusée"
                                                    : data?.message,
                                            });
                                            refetchTeamBadge(queryClient);
                                            refetch();
                                            return;
                                        }}
                                        cbOnCancelLeave={(data) => {
                                            const isSuccess = data?.success;
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été Annulée"
                                                    : data?.message,
                                            });
                                            refetchTeamBadge(queryClient);
                                            refetch();
                                            return;
                                        }}
                                        cbOnDeleteLeave={(data) => {
                                            const isSuccess = data?.success;
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été Supprimée"
                                                    : data?.message,
                                            });
                                            refetchTeamBadge(queryClient);
                                            refetch();
                                            return;
                                        }}
                                    />
                                );
                            })
                        )}
                    </Table>
                    <Pagination
                        buttonContentSeeMore={"Afficher plus de demandes"}
                        countMessage={"demandes affichées sur"}
                        currentCountRows={currentPageToShow}
                        isMore={is_more_rows}
                        isLoading={loadingMyPersonalLeave}
                        totalRows={data?.pages?.at(0).all_leaves_count}
                        isFetchingNextPage={isFetchingNextPage}
                        funcFetchNextPage={() => fetchNextPage()}
                    />
                </Container>
            )}
        </ContainerFluid>
    );
};

export default CollabList;

const DropdownStyle = styled(Dropdown)`
    @media ${DEVICE.mobileS} {
        ul {
            width: 200px;
            transform: translate(-150px, 32px);
        }
    }
    @media ${DEVICE.tablet} {
        ul {
            width: 100%;
            transform: auto;
        }
    }
`;

export const TextSeparator = styled(Text)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: block;
    }
`;

export const ContainerBadge = styled(Flex)`
    @media ${DEVICE.mobileS} {
        max-width: 100px;
        min-width: 97px;
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        max-width: 140px;
        min-width: 140px;
    }
`;

export const CellDates = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        width: 240px;
        gap: 8px;
        display: flex;
    }
`;

export const CellEmployee = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
        width: auto;
    }
    @media ${DEVICE.laptop} {
        display: flex;
        width: 230px;
    }
`;

export const CellTypeLeave = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: fit-content;
        gap: 8px;
    }
    @media ${DEVICE.laptop} {
        width: 200px;
        gap: 8px;
    }
`;

export const TableHeader = styled(Flex)`
    /* @media ${DEVICE.mobileS} {
        width: 260px;
    }
    @media ${DEVICE.laptop} {
        width: 1400px;
    } */
`;

export const Content = styled(Flex)`
    @media ${DEVICE.mobileS} {
        height: fit-content;
    }
    @media ${DEVICE.laptop} {
        height: 50vh;
        overflow-y: scroll;
    }
`;
export const CellFullName = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;
export const AvatarStyle = styled(Avatar)`
    @media ${DEVICE.mobileS} {
        display: inline-block;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
`;

export const NbreJour = styled(Text)`
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: rgba(41, 40, 40, 0.9);
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: block;
    }
`;

export const HeaderText = styled(Text)`
    color: #424242;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

export const Header = styled(Flex)`
    padding: 10px;
    background-color: #f5f5f5;
    margin-bottom: 8px;
`;

export const DateText = styled(Text)`
    color: #242424;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    @media ${DEVICE.mobileS} {
        width: 49px;
        overflow: hidden;
        font-size: 12px;
    }
    @media ${DEVICE.laptop} {
        width: fit-content;
        font-size: 14px;
    }
`;

export const isMorning = (time_start) => {
    // Convertir la chaîne de temps en objet Date
    const date = new Date(`01/01/2000 ${time_start}`);

    // Définir les heures de début et de fin pour la matinée
    const startMorningTime = new Date(`01/01/2000 06:00 AM`);
    const endMorningTime = new Date(`01/01/2000 12:00 PM`);

    // Vérifier si l'heure est dans la matinée
    const isInMorning = date >= startMorningTime && date < endMorningTime;

    return isInMorning;
};

import moment from "moment";
import { ColumnTitle } from "./index.style";

export const constants = {
    WIDTH_COLUMN_DATE: "80px",
    WIDTH_COLUMN_TITLE: "227px",
    WIDTH_COLUMN_COUNT_EXPENSES: "150px",
    WIDTH_COLUMN_REBILL: "183px",
    WIDTH_COLUMN_AMOUNT: "128px",
};

// constants
export const CURRENT_YEAR = moment().year();
export const CURRENT_MONTH_INDEX = moment().month() + 1; // * BASE INDEX => 1 = Janvier
export const TYPE_NAVIGATION_MONTH = {
    forward: "FORWARD",
    backward: "BACKWARD",
};

export const INIT_MONTH_NAVIGATION = {
    month_index: CURRENT_MONTH_INDEX,
    year: CURRENT_YEAR,
};

export const header = {
    items: [
        {
            content: <ColumnTitle>Date</ColumnTitle>,
            styles: {
                maxWidth: constants.WIDTH_COLUMN_DATE,
            },
        },
        {
            content: <ColumnTitle>Titre de la note</ColumnTitle>,
            styles: {
                maxWidth: constants.WIDTH_COLUMN_TITLE,
            },
        },
        {
            content: <ColumnTitle>Nb de dépenses</ColumnTitle>,
            styles: {
                maxWidth: constants.WIDTH_COLUMN_COUNT_EXPENSES,
                justifyContent: "end",
            },
        },
        {
            content: "",
            styles: {
                maxWidth: constants.WIDTH_COLUMN_REBILL,
            },
        },
        {
            content: <ColumnTitle>Montant</ColumnTitle>,
            styles: {
                maxWidth: constants.WIDTH_COLUMN_AMOUNT,
                justifyContent: "end",
            },
        },
    ],
};

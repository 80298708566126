import React from "react";
import { Badge } from "./index.style";

const TYPE_BADGES = {
    positive: {
        color: "#13a10e",
        border_color: "#bdda9b",
        bg: "#e7f2da",
    },
    negative: {
        color: "#c4314b",
        border_color: "#f3d6d8",
        bg: " #fcf4f6",
    },
    zero: {
        color: "#242424",
        border_color: "#242424",
        bg: " #f0f0f0",
    },
};
/**
 * @typedef {Object} PropsType
 * @property {("zero"|"positive"|"negative")} status
 * @property {number} hourWorked
 *
 */
/**
 * HourlyBadge c'est le badge des heures.
 * @param {PropsType} props
 * @returns {ReactNode}
 */
const HourlyBadge = ({ hourWorked, status }) => {
    const { color, border_color, bg } = TYPE_BADGES[status];

    // hourWorked = Number(hourWorked);
    // console.log({ hourWorked2: typeof hourWorked });

    return (
        <Badge
            color={color}
            bg={bg}
            border_color={border_color}
            content={hourWorked}
        >
            {hourWorked}
            {/* {hourWorked >= 0 ? `+ ${hourWorked} h` : `${hourWorked} h`} */}
        </Badge>
    );
};

export default HourlyBadge;

import React from "react";
import {
    Button,
    Flex,
    Image,
    Input,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import {
    useGetImageUploaded,
    useSetImageUploaded,
    useSetValidateImage,
    useUploadImage,
} from "../../stores/useOnboarding";
import { useMutation } from "react-query";
import { _api_onboarding_upload_avatar } from "../../api";
import { useRef } from "react";
import { useAuthDetailStore } from "../../../../stores/useAuthStore";

const ShowAvatarWithName = ({ isLoadingAvatar }) => {
    const refUploadImage = useRef(null);
    const store_image_uploaded = useGetImageUploaded();
    const store_set_image = useSetImageUploaded();
    const store_obj_uploaded_image = useUploadImage();
    const store_set_validate_image = useSetValidateImage();
    const { detail: current_user } = useAuthDetailStore();

    /**
     * POST THE AVATAR
     */
    const { mutate: mutate_put_avatar, isLoading } = useMutation(
        ({ uploaded_files }) => {
            return _api_onboarding_upload_avatar({ uploaded_files });
        }
    );

    const _update_my_avatar = async (e) => {
        const files = e.target.files;
        if (!files.length) return;
        const file = files[0];
        mutate_put_avatar(
            {
                uploaded_files: file,
            },
            {
                onSuccess: (data) => {
                    if (data.success) {
                        store_set_image(data.avatar);
                    }
                },
            }
        );
    };
    return (
        <Flex column gap="gap.medium" fill>
            <Flex gap="gap.medium" vAlign="center">
                <div>
                    {isLoadingAvatar || isLoading ? (
                        <Loader size="medium" />
                    ) : (
                        <Image
                            src={store_image_uploaded}
                            width={"64px"}
                            circular
                            styles={{
                                height: "64px",
                            }}
                        />
                    )}
                </div>
                <Flex column>
                    <Name>
                        {current_user?.first_name +
                            " " +
                            current_user?.last_name}
                    </Name>
                    <Poste>{current_user?.job_title}</Poste>
                </Flex>
            </Flex>
            <Flex gap="gap.small" hAlign="end" fill>
                {!store_obj_uploaded_image.validate_image && (
                    <>
                        <Button
                            content={"Recharger une image"}
                            secondary
                            onClick={() => refUploadImage.current.click()}
                        />
                        <Button
                            content={"Envoyer"}
                            primary
                            onClick={() => store_set_validate_image()}
                        />
                        <Input
                            type="file"
                            ref={refUploadImage}
                            accept={"image/*"}
                            onChange={_update_my_avatar}
                            hidden
                        />
                    </>
                )}
            </Flex>
        </Flex>
    );
};

export default ShowAvatarWithName;

const Poste = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #616161;
`;

const Name = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: #242424;
`;

import { BASE_URL_API } from "../../constants";
import fetcher from "../../utils/fetcher";

export const _api_send_feedback = async (data) => {
    let url = BASE_URL_API + "/send_feedback";
    return fetcher(url, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(data),
    });
};

import React from "react";
import { Button } from "@fluentui/react-northstar";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { DEVICE } from "../../constants/responsive";

const ButtonBreadCrumb = ({
    content = "",
    onClick = () => null,
    color = "white",
    ...props
}) => {
    return (
        <ButtonStyle
            text
            content={content}
            styles={{ color }}
            onClick={onClick}
            {...props}
        />
    );
};

ButtonBreadCrumb.propTypes = {
    content: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
};

export default ButtonBreadCrumb;

const ButtonStyle = styled(Button)`
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    &:hover {
        color: white;
        opacity: 0.5;
    }
    ${({ $hideOnMobile }) =>
        $hideOnMobile
            ? css`
                  @media ${DEVICE.mobileS} {
                      display: none;
                  }
                  @media ${DEVICE.mobileL} {
                      display: none;
                  }
                  @media ${DEVICE.tablet} {
                      display: flex;
                  }
                  @media ${DEVICE.laptop} {
                      display: flex;
                  }
              `
            : css``}
`;

import { CloseIcon } from "@fluentui/react-northstar";
import React from "react";
import { DialogStyle } from "../../../../pages/DashboardTasks/style";

export const DialogConfirmationUpdateHoursWorked = ({
    openConfirmationDialog,
    content = "",
    onConfirm = () => console.log("confirm"),
    onCancel = () => console.log("cancel"),
}) => {
    return (
        <DialogStyle
            open={openConfirmationDialog}
            onCancel={onCancel}
            onConfirm={onConfirm}
            confirmButton={{
                content: "Confirmer",
                flat: true,
            }}
            cancelButton={{
                content: "Annuler",
                flat: true,
            }}
            content={content}
            header="Êtes-vous sûr(e) de vouloir appliquer cette modification ?"
            headerAction={{
                icon: <CloseIcon />,
                title: "Close",
                onClick: onCancel,
            }}
        />
    );
};

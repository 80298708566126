import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const EmptyHistoryAccountingManager = () => {
    return (
        <Flex fill>
            <TextStyle>Aucune note disponible</TextStyle>
        </Flex>
    );
};

export default EmptyHistoryAccountingManager;

const TextStyle = styled(Text)`
    color: #616161;

    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import EmptyTableImg from "../../../../../assets/illustrations/hibou.png";

const EmptyTable = ({ actionText }) => {
    return (
        <Flex space="between">
            <EmptyLeftColumn />
            <Flex vAlign="center" column gap="gap.small">
                <Image src={EmptyTableImg} />
                <TextStyle content={`Aucune feuille de temps ${actionText}`} />
            </Flex>
            <EmptyTotal />
        </Flex>
    );
};

export default EmptyTable;

const EmptyLeftColumn = styled(Flex)`
    min-width: 328px;
    background-color: white;
    min-height: 320px;
    overflow: hidden;
`;
const EmptyTotal = styled(Flex)`
    min-width: 138px;
    background-color: white;
    min-height: 320px;
    overflow: hidden;
`;

const TextStyle = styled(Text)`
    color: #000;
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
    border-radius: 4px 4px 0px 0px;
    padding: 8px;
    background: var(--color-primary);
`;
export const TextType = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Default-Foreground-3, #fff);

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;

import React from "react";
import { Flex, InfoIcon, Text, Tooltip } from "@fluentui/react-northstar";
import styled from "styled-components";
import moment from "moment";

const BalanceSection = ({ balances }) => {
    const emptyBalance = balances?.length === 0;
    return (
        <Flex
            fill
            styles={{
                marginTop: "12px",
                flexWrap: "wrap",
                gap: "24px",
            }}
        >
            {emptyBalance ? (
                <Flex
                    fill
                    vAlign="center"
                    hAlign="center"
                    style={{
                        fontWeight: "bold",
                        color: "#5b5fc7",
                    }}
                >
                    <Text content="Aucun solde disponible" />
                </Flex>
            ) : (
                balances?.map((el) => {
                    return (
                        <Card key={el?.name} column gap="gap.small">
                            <Flex vAlign="center" gap="gap.smaller">
                                <Title content={`${el?.current_value}`} />
                                <Tooltip
                                    align="start"
                                    position="above"
                                    // autoSize="always"
                                    pointing
                                    subtle={false}
                                    unstable_pinned
                                    content={
                                        <Flex column>
                                            <Text
                                                content={
                                                    <p>
                                                        Période d’acquisition :
                                                        du{" "}
                                                        <b>
                                                            {moment(
                                                                el?.period_start_date
                                                            ).format("L")}
                                                        </b>{" "}
                                                        au{" "}
                                                        <b>
                                                            {moment(
                                                                el?.period_end_date
                                                            ).format("L")}
                                                        </b>
                                                    </p>
                                                }
                                            />
                                            {el?.balance_on_end_period ? (
                                                <Text
                                                    content={
                                                        <p>
                                                            {" "}
                                                            Solde prévu au{" "}
                                                            <b>
                                                                {" "}
                                                                {moment(
                                                                    el?.period_end_date
                                                                ).format("L")}
                                                            </b>{" "}
                                                            (dernier jour de la
                                                            période) :
                                                            <b>
                                                                {" "}
                                                                {
                                                                    el?.balance_on_end_period
                                                                }{" "}
                                                                j
                                                            </b>
                                                        </p>
                                                    }
                                                />
                                            ) : null}
                                        </Flex>
                                    }
                                    trigger={
                                        <InfoIcon
                                            style={{ color: "#5b5fc7" }}
                                            outline
                                        />
                                    }
                                />
                            </Flex>
                            <Description content={el?.name} />
                            <AttenteText
                                content={
                                    el?.balance_value_to_remove !== 0
                                        ? `Dont ${el?.balance_value_to_remove}j en attente`
                                        : null
                                }
                            />
                        </Card>
                    );
                })
            )}
        </Flex>
    );
};
export const BalanceManagerSection = ({ totalTeamBalance }) => {
    return (
        <Flex gap="gap.large">
            {totalTeamBalance
                ? totalTeamBalance?.map((el) => {
                      <Card key={el?.name} column fill gap="gap.small">
                          <Flex gap="gap.medium" vAlign="center">
                              <Title content={`${el?.value} j`} />
                              <Tooltip
                                  align="start"
                                  position="above"
                                  autoSize="always"
                                  pointing
                                  subtle={false}
                                  unstable_pinned
                                  content={
                                      <Flex column>
                                          <Text
                                              content={
                                                  <p>
                                                      Période d’acquisition : du{" "}
                                                      <b>
                                                          {moment(
                                                              el?.period_start_date
                                                          ).format("L")}
                                                      </b>{" "}
                                                      au{" "}
                                                      <b>
                                                          {moment(
                                                              el?.period_end_date
                                                          ).format("L")}
                                                      </b>
                                                  </p>
                                              }
                                          />
                                          {el?.balance_on_end_period ? (
                                              <Text
                                                  content={
                                                      <p>
                                                          {" "}
                                                          Solde prévu au{" "}
                                                          <b>
                                                              {" "}
                                                              {moment(
                                                                  el?.period_end_date
                                                              ).format("L")}
                                                          </b>{" "}
                                                          (dernier jour de la
                                                          période) :
                                                          <b>
                                                              {" "}
                                                              {
                                                                  el?.balance_on_end_period
                                                              }{" "}
                                                              j
                                                          </b>
                                                      </p>
                                                  }
                                              />
                                          ) : null}
                                      </Flex>
                                  }
                                  trigger={<InfoIcon outline />}
                              />
                          </Flex>
                          <Description content={el?.name} />
                      </Card>;
                  })
                : null}
        </Flex>
    );
};

export default BalanceSection;

const Description = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    min-width: 110px;
    color: #424242;
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    width: fit-content;
    color: #4f52b2;
`;

const Card = styled(Flex)`
    border-left: 2px solid #f0f0f0;
    padding: 8px 4px 8px 16px;

    width: 116px;

    /* width: fit-content; */
    /* margin-right: 24px; */
`;

const AttenteText = styled(Text)`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #cc4a31;
`;

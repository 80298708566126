import React from "react";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import {
    ChevronRight16Filled,
    ChevronLeft16Regular,
} from "@fluentui/react-icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { CustomBadge } from "../../../../components/Badge";

const SliderWeek = ({ start, end, onDecrement, onIncrement, iso_week }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    return (
        <Flex hAlign="center" vAlign="center" column>
            <Flex gap="gap.small" vAlign="center">
                <Button
                    icon={<ChevronLeft16Regular />}
                    iconOnly
                    flat
                    text
                    onClick={onDecrement}
                />
                <TextWeek content={`${start} - ${end}`} />
                <Button
                    icon={<ChevronRight16Filled />}
                    iconOnly
                    flat
                    text
                    onClick={onIncrement}
                />
            </Flex>
            <CustomBadge
                appearence="filled"
                bg="#EBEBEB"
                color="black"
                label={`${t("home.sections.week.text_week")} ${iso_week}`}
                size="small"
                rectangular
            />
        </Flex>
    );
};

export default SliderWeek;

const TextWeek = styled(Text)`
    color: #242424;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
`;

import React from "react";
import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

const Counter = ({ children }) => {
    return (
        <Container vAlign="center" hAlign="center">
            {children}
        </Container>
    );
};

export default Counter;

const Container = styled(Flex)`
    width: 16px;
    height: 16px;

    /* aspect-ratio: 1/1; */

    background: #2f2f4a;
    border-radius: 16px;
    color: white;
    font-size: 10px;
    font-weight: 700;
`;

import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

/**
 *
 *
 * @property {string} bg
 * @property {number} content
 *
 */
/**
 * it's a badge with custom background and that contain  a number
 *
 * props :
 * - bg : color of the badge's background
 * - content : its a  number for exple (3)
 * @param {PropsType} props
 * @returns {ReactNode}
 */

const TimeReportingCounterBadge = ({ bg, content }) => {
    return (
        <Badge bg={bg} vAlign="center" hAlign="center">
            <BadgeText content={content} />
        </Badge>
    );
};

export default TimeReportingCounterBadge;

const Badge = styled(Flex)`
    border-radius: 52px;
    background-color: ${({ bg }) => (bg ? bg : "yellow")};
    width: 20px;
    color: white;
`;
const BadgeText = styled(Text)`
    font-weight: 700;
`;

export const CONTRACT_TYPES = [
    {
        id: 0,
        name: "CDI: Contrat de travail à Durée Indéterminée",
    },
    {
        id: 1,
        name: "CDD: Contrat de travail à Durée Déterminée",
    },
    {
        id: 2,
        name: "CTT: Contrat de Travail Temporaire ou Intérimaire ",
    },
    {
        id: 3,
        name: "Contrat d'apprentissage",
    },
    {
        id: 4,
        name: "Contrat de professionnalisation",
    },
    {
        id: 5,
        name: "CUI: Contrat Unique d'Insertion ",
    },
    {
        id: 6,
        name: "CAE: Contrat d'Accompagnement dans l'Emploi",
    },
    {
        id: 7,
        name: "CIE: Contrat Initiative Emploi",
    },
    {
        id: 8,
        name: "Stage non rémunéré",
    },
    {
        id: 9,
        name: "Stage rémunéré",
    },
    {
        id: 10,
        name: "POEI: Pole emploi - Préparation Opérationnelle à l'Emploi Individuelle",
    },
];

export const CONTRACT_STATUS = [
    {
        id: 0,
        name: "Autre",
    },
    {
        id: 1,
        name: "Cadre",
    },
    {
        id: 2,
        name: "Etam",
    },
];

export const ACTIVITY_RATE = [
    {
        id: 1,
        name: "Temps plein",
    },
    {
        id: 2,
        name: "Temps partiel",
    },
    {
        id: 3,
        name: "Temps occasionnel",
    },
];
export const PACKAGE_TYPE = [
    {
        id: 0,
        name: "Autre",
    },
    {
        id: 1,
        name: "Heure",
    },
    {
        id: 2,
        name: "Jour",
    },
];
export const RTT_POLICY = [
    {
        id: 0,
        name: "Pas de RTT",
    },
    {
        id: 1,
        name: "RTT fixe",
    },
    {
        id: 2,
        name: "RTT fixe par année (forfait jour)",
    },
    {
        id: 3,
        name: "Variable (forfait heure) ",
    },
];
export const SALARY_TYPE = [
    {
        id: 1,
        name: "Brut",
    },
    {
        id: 2,
        name: "Net",
    },
];

export const SALARY_PERIOD = [
    {
        id: 1,
        name: "Par heure",
    },
    {
        id: 2,
        name: "Par jour",
    },
    {
        id: 3,
        name: "Par mois",
    },
    {
        id: 4,
        name: "Par année",
    },
];

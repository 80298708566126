/* eslint-disable multiline-ternary */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthDetailStore, useAuthStore } from "../../stores/useAuthStore";
import { refreshToken } from "../../api/auth";
import LoaderPage from "../loaders/LoaderPage";
// import LoaderPage from "../loaders/LoaderPage";

const PublicRoute = ({ children }) => {
    const { infos: user } = useAuthStore();
    const detail = useAuthDetailStore((state) => state.detail);
    const location = useLocation();

    const [reload, setReload] = useState(false);
    const [reloadOnce, setReloadOnce] = useState(false);

    // const isLoged = user ? true : false;
    if (!user && !reload && !reloadOnce) {
        // get new token of the user there.
        setReload(true);
        refreshToken().then(() => {
            setReloadOnce(true);
            setReload(false);
        });
    }

    if (reload)
        return <LoaderPage label="Chargement des données de la page." />;

    if (!reload) {
        return !user ? (
            children
        ) : (
            <Navigate
                to={
                    detail?.is_in_preonboarding
                        ? "/welcome-preonboarding"
                        : "/dashboard"
                }
                replace
                state={{ path: location.pathname }}
            />
        );
    }
};

PublicRoute.propTypes = {
    children: PropTypes.element,
};

export default PublicRoute;

import React from "react";
import { Avatar, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components/macro";
import { CalendarChat20Regular } from "@fluentui/react-icons";
import { I18N_NS_EXPENSE } from "../../constants";
import { useTranslation } from "react-i18next";

const EvolutionComment = ({ comments = [] }) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    return (
        <Container>
            <Flex column hAlign="center" gap="gap.medium">
                <Flex column gap="gap.small" hAlign="center">
                    <Flex gap="gap.small" vAlign="center">
                        <CalendarChat20Regular />
                        <EvolutionTitleComment
                            content={t("report.evolution.comment.title")}
                        />
                    </Flex>
                </Flex>
                <Flex column gap="gap.small">
                    {comments.map((comment, index) => {
                        return (
                            <Flex
                                vAlign="center"
                                fill
                                gap="gap.medium"
                                key={index}
                            >
                                <div>
                                    <Avatar
                                        name={comment.user_fullname}
                                        image={comment.user_avatar}
                                    />
                                </div>
                                <WrapperComment fill>
                                    <Comment
                                        content={`${comment.date} : ${
                                            comment.type === "expense_edited"
                                                ? "Modification"
                                                : ""
                                        } ${comment.description}`}
                                    />
                                </WrapperComment>
                            </Flex>
                        );
                    })}
                </Flex>
            </Flex>
        </Container>
    );
};

export default EvolutionComment;

const Comment = styled(Text)`
    color: #242424;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

const WrapperComment = styled(Flex)`
    background: #f0f0f0;
    padding: 6px 12px 10px 12px;
`;

const EvolutionTitleComment = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: clamp(14px, 2vw, 18px);
    line-height: clamp(18px, 2vw, 24px);
    color: #424242;
`;

const Container = styled(Flex)`
    width: clamp(100%, 2vw, 479px);
    /* height: fit-content; */
    padding: 32px 16px 40px;
    /* background: #ffffff; */
    /* background: red; */
`;

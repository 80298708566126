import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import ImgForEmptyData from "./ImgForEmptyData.svg";
import styled from "styled-components";

export default function EmptyData({ activeTab }) {
    const EmptyDataText = () => {
        switch (activeTab) {
            case 0:
                return "Aucun Processus de pré-onboarding à démarrer";
            case 1:
                return "Aucun Processus de pré-onboarding en cours";
            case 2:
                return "Aucun Processus d'onboarding en cours";
            case 3:
                return "Aucun processus d'onboarding à clôturer";
            case 4:
                return "Aucun Processus d'onboarding actif";
            default:
                return "Aucun Processus de pré-onboarding à démarrer";
        }
    };
    return (
        <EmptyFlex gap={"gap.medium"} column fill hAlign="center">
            <Image src={ImgForEmptyData} alt="empty data" />
            <TextStyle content={EmptyDataText(activeTab)} />
        </EmptyFlex>
    );
}

const TextStyle = styled(Text)`
    color: #000;
    text-align: center;

    /* Title/Title2 */
    font-family: "Segoe UI";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;

const EmptyFlex = styled(Flex)`
    padding-block: 26px 70px;
`;

import React from "react";
import { useQuery } from "react-query";
import { Flex, Loader } from "@fluentui/react-northstar";
import Card from "../../../Cards";
import CardPositionEntreprise from "../../../Cards/CardPositionEntreprise";
import { QUERY_KEY } from "../../../../constants";
import { _api_get_about_informations } from "../../../../api";
import CardAboutMe from "../../../Cards/CardAboutMe";
import { useEmployeeFolderIdUser } from "../../../../store";
import CardRoles from "../../../Cards/CardRoles";
import { ROLES_CODE, roles_access } from "../../../../../../constants/roles";
import CardDelegation from "../../../Cards/CardDelegation/CardDelegation";

const TabAbout = ({ isLoading, isFetching, data }) => {
    if (isLoading || isFetching) {
        return <Loader size="medium" />;
    }
    /**
     * for check the default values on Edit Roles.
     */
    const isEmployeeManager = data?.roles?.some(
        (role) => role.code === ROLES_CODE.manager
    );
    const isEmployeeHR = data?.roles?.some(
        (role) => role.code === ROLES_CODE.hr
    );

    return (
        <Flex fill gap="gap.large">
            <Flex fill styles={{ maxWidth: "440px" }} gap="gap.large" column>
                {/* about me */}
                <CardAboutMe about_me={data?.about_me} />
                {/* roles */}
                <CardRoles
                    roles={data?.roles}
                    isEmployeeHR={isEmployeeHR}
                    isEmployeeManager={isEmployeeManager}
                />
                {/* delegation navigation button */}
                <CardDelegation />
            </Flex>
            {/* Organigram */}
            <Flex fill>
                <Card
                    column
                    fill
                    styles={{
                        height: "fit-content",
                    }}
                >
                    <Card.Body
                        content={
                            <CardPositionEntreprise
                                manager={data?.manager}
                                rh={data?.hr}
                                teamHr={data?.team_as_hr}
                                teamManager={data?.team_as_manager}
                                default_agency_id={data?.agency?.id}
                                default_service_id={data?.department?.id}
                                agency_name={data?.agency?.name}
                                department_name={data?.department?.name}
                                isEmployeeManager={isEmployeeManager}
                                isEmployeeHR={isEmployeeHR}
                            />
                        }
                    />
                </Card>
            </Flex>
        </Flex>
    );
};

export default TabAbout;

import React, { useState } from "react";
import { EventText, EventWrapper, PopupStyled } from "./index.style";
import {
    STYLES_EVENTS,
    TYPE_EVENT,
    WIDTH_COLUMN,
    WIDTH_COLUMN_LARGE,
} from "../../constants";
import { Popup } from "@fluentui/react-northstar";
import PopupContent from "../PopupContent";
import { useShowRemoteWeekend } from "../../store/useShowRemoteWeekend";

/**
 * @typedef {Object} PropsType
 * @property {keyof typeof TYPE_EVENT} type - use TYPE_EVENT constant
 * @property {number} [nbr_days] - nombre de jours d'event
 * @property {Object} [popover_detail] - json du popover pour affichage
 * @property {boolean} [can_update_or_delete] - si j'affiche le button modifier ou supprimer
 *
 * @param {PropsType} props
 * @returns {React.JSX}
 */
const Event = ({
    type,
    nbr_days = 1,
    can_update_or_delete = false,
    popover_detail,
}) => {
    const [open, setOpen] = useState(false);

    const ctx_show_weekend = useShowRemoteWeekend((state) => state.show);

    let style = STYLES_EVENTS[type];
    const width_event = ctx_show_weekend
        ? WIDTH_COLUMN * nbr_days + 1.5 * nbr_days
        : WIDTH_COLUMN_LARGE * nbr_days + 1.5 * nbr_days;

    const is_openable_popup = type !== TYPE_EVENT.blank;

    const closePopup = () => {
        return setOpen(false);
    };

    return (
        <Popup
            open={open}
            align="center"
            position="after"
            pointing
            onOpenChange={(e, i) => {
                e.stopPropagation();
                e.preventDefault();

                if (is_openable_popup) {
                    return setOpen(i.open);
                }
                if (!is_openable_popup) return;
            }}
            offset={() => [0, -width_event + WIDTH_COLUMN - 20]}
            trigger={
                <EventWrapper
                    data-name="event"
                    vAlign="center"
                    gap="gap.smaller"
                    $bg={style.bg}
                    $color={style.color}
                    $width={width_event}
                    $cursor={is_openable_popup}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpen(true);
                    }}
                >
                    {style.icon}
                    <EventText>{style.content}</EventText>
                </EventWrapper>
            }
            content={
                <PopupContent
                    closePopup={closePopup}
                    event_id={popover_detail?.id}
                    title={{
                        icon: style.icon_white,
                        content: style.content,
                        reason_id: style.reason_id,
                    }}
                    user={{
                        avatar: popover_detail?.user?.avatar,
                        name: popover_detail?.user?.name,
                    }}
                    dates={{
                        start: popover_detail?.start?.date,
                        end: popover_detail?.end?.date,
                    }}
                    times={{
                        start: popover_detail?.start?.time,
                        end: popover_detail?.end?.time,
                    }}
                    can_update_or_delete={can_update_or_delete}
                />
            }
        />
    );
};

export default Event;

import { Dialog, Loader } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import DialogUpdateManagerContent from ".";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERY_KEY } from "../../../constants";
import { getManagers } from "../../../../../api/user";
import { _api_update_manager } from "../../../api";

const DialogUpdateManager = ({
    open_default = false,
    default_manager,
    onClose = () => console.log("Closed modal"),
}) => {
    const [managers, setManagers] = useState([]);

    const clientQuery = useQueryClient();

    const reset = () => {
        setManagers([]);
        onClose();
        refetchManagers();
        return;
    };

    const { isLoading: is_loading_managers, refetch: refetchManagers } =
        useQuery([QUERY_KEY + "::managers"], () => getManagers(), {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                return setManagers(() => {
                    return data.map((manager) => {
                        return {
                            ...manager,
                            selected: manager?.email === default_manager?.email,
                        };
                    });
                });
            },
        });

    const {
        mutate: mutate_update_manager,
        isLoading: is_loading_update_manager,
    } = useMutation(
        (manager_email) =>
            _api_update_manager({
                manager_email,
            }),
        {
            onSuccess: (data) => {
                if (data?.success) {
                    clientQuery.refetchQueries(QUERY_KEY + "::about");
                    return reset();
                }
            },
        }
    );

    if (is_loading_managers && !managers?.length) {
        return <Loader content="chargement des managers ..." />;
    }

    const selected_manager = managers?.find(
        (manager) => manager.selected
    )?.email;
    const is_same_manager = selected_manager === default_manager?.email;

    return (
        <Dialog
            open={open_default}
            onOpen={() => refetchManagers()}
            header="Modifier le Manager"
            content={
                <DialogUpdateManagerContent
                    managers={managers}
                    setManagers={setManagers}
                />
            }
            onCancel={reset}
            confirmButton={{
                content: "Valider le manager",
                flat: true,
                disabled: is_same_manager,
                loading: is_loading_update_manager,
                onClick: () => mutate_update_manager(selected_manager),
            }}
            cancelButton={{
                content: "Annuler",
                flat: true,
            }}
            styles={{
                width: "391px",
            }}
        />
    );
};

export default DialogUpdateManager;

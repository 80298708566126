import React from "react";
import { Button, DownloadIcon, ExcelIcon } from "@fluentui/react-northstar";
import { useSearchParams } from "react-router-dom";

export default function ExportAllSoldeTrigger({
    setOpenDialog,
    isMobile,
    searchParams,
}) {
    const [CleanParams, setCleanParams] = useSearchParams();
    const CleanUrl = () => {
        setCleanParams("");
    };
    return (
        <Button
            text
            icon={<ExcelIcon />}
            content={"Exporter pour Excel "}
            flat
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                CleanUrl();
                setOpenDialog(true);
            }}
        />
    );
}

import React from "react";
import { AcceptIcon, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";

const WeekStatus = ({ status }) => {
    let content = "";
    switch (status) {
        case 0:
            return null;
        case 1:
            content = "home.sections.week.status.saved";
            break;
        case 2:
            content = "home.sections.week.status.validated";
            break;
        default:
            return null;
    }

    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    return (
        <Container vAlign="center" gap="gap.small">
            <ContainerText content={t(content)} />
            <AcceptIcon />
        </Container>
    );
};

export default WeekStatus;

const ContainerText = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    border-radius: 4px;
`;

const Container = styled(Flex)`
    padding: 6.5px 12px;
    color: #13a10e;
    background-color: #ebebeb;
`;

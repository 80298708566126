import { Flex } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const CustomBadge = ({ content }) => {
    return (
        <FlexStyle>
            <TextStyle>{content}</TextStyle>
        </FlexStyle>
    );
};

export default CustomBadge;

const FlexStyle = styled(Flex)`
    border: 2px solid white;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #e8ebfa;
    width: fit-content;
`;

const TextStyle = styled(Flex)`
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    color: var(--color-primary);
    border-radius: 4px;
`;

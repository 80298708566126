import React from "react";
import { Divider, Flex, Image, Text, Tooltip } from "@fluentui/react-northstar";
import { useNavigate } from "react-router-dom";
import { DEVICE, SIZES } from "../../../../constants/responsive";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

/**
 * function return string navigation URL.
 */
const redirect_form_depending_type_form = ({
    category_id,
    category_name,
    category_icon,
    category_type_form,
    report_id,
    navigate,
    default_tva = null,
    default_mileage_allowance_rate,
}) => {
    let url =
        "/expenses/add/" +
        report_id +
        "/category/" +
        category_id +
        "/" +
        category_type_form;

    // add search param d_tva to the URL.
    if (default_tva) {
        url = `${url}?d_tva=${default_tva}`;
    }
    return navigate(url, {
        state: {
            default_mileage_allowance_rate,
            category: {
                id: category_id,
                name: category_name,
                icon: category_icon,
            },
        },
    });
};

const Categories = ({ categories, id_report }) => {
    const navigate = useNavigate();

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileM})`,
    });
    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });

    const isMobile = isMobileS || isMobileM;

    return (
        <>
            {isMobile && (
                <CategoriesList>
                    {categories?.map((el) => {
                        return (
                            <Flex
                                fill
                                column
                                key={el?.id}
                                vAlign="start"
                                hAlign="center"
                                styles={{
                                    width: "100%",
                                    padding: "8px",
                                }}
                            >
                                <Tooltip
                                    content={el?.name}
                                    trigger={
                                        <Flex
                                            column
                                            fill
                                            styles={{
                                                cursor: "pointer",
                                                minWidth: "100%",
                                            }}
                                            onClick={() =>
                                                redirect_form_depending_type_form(
                                                    {
                                                        category_id: el?.id,
                                                        category_type_form:
                                                            el?.form_type,
                                                        report_id: id_report,
                                                        navigate,
                                                        default_tva:
                                                            el?.default_vat,
                                                        default_mileage_allowance_rate:
                                                            el?.default_mileage_allowance_rate,
                                                        category_name: el?.name,
                                                        category_icon:
                                                            el?.icon_url,
                                                    }
                                                )
                                            }
                                        >
                                            <Flex gap="gap.small" row>
                                                <ImageStyle
                                                    src={el?.icon_url}
                                                    alt="icon"
                                                />
                                                <MobileCategorieText truncated>
                                                    {el?.name}
                                                </MobileCategorieText>
                                            </Flex>
                                            <div
                                                style={{
                                                    color: "red",
                                                    height: "3px",
                                                    width: "100%",
                                                    marginTop: "8px",
                                                }}
                                            >
                                                <Divider />
                                            </div>
                                        </Flex>
                                    }
                                />
                            </Flex>
                        );
                    })}
                </CategoriesList>
            )}
            {!isMobile && (
                <Flex
                    gap="gap.large"
                    wrap
                    styles={{
                        rowGap: "15px",
                        columnGap: "5px",
                        padding: "16px 16px 32px 36px",
                    }}
                >
                    {categories?.map((el) => {
                        return (
                            <Flex
                                column
                                key={el?.id}
                                vAlign="center"
                                hAlign="center"
                            >
                                <Tooltip
                                    content={el?.name}
                                    trigger={
                                        <div
                                            style={{
                                                width: "72px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                redirect_form_depending_type_form(
                                                    {
                                                        category_id: el?.id,
                                                        category_type_form:
                                                            el?.form_type,
                                                        report_id: id_report,
                                                        navigate,
                                                        default_tva:
                                                            el?.default_vat,
                                                        default_mileage_allowance_rate:
                                                            el?.default_mileage_allowance_rate,
                                                        category_name: el?.name,
                                                        category_icon:
                                                            el?.icon_url,
                                                    }
                                                )
                                            }
                                        >
                                            <Image
                                                src={el?.icon_url}
                                                alt="icon"
                                                width="72px"
                                            />
                                            <Text truncated>{el?.name}</Text>
                                        </div>
                                    }
                                />
                            </Flex>
                        );
                    })}
                </Flex>
            )}
        </>
    );
};

export default Categories;

const CategoriesList = styled(Flex)`
    flex-direction: column;
    width: 100%;
`;

const ImageStyle = styled(Image)`
    border-radius: 3px;
    width: 23px;
    height: 23px;
`;

const MobileCategorieText = styled(Text)`
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

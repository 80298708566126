import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../../../constants/responsive";

export const WrapperForm = styled(Flex)`
    /* box-sizing: border-box; */
    padding: var(--spacing-l-24, 24px) 16px;
    gap: var(--spacing-xxl-40, 40px);

    border-radius: 4px;
    border: 1px solid var(--Light-theme-Rest-Border-Default-Border-3, #f0f0f0);
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);

    /* shadow-2 */
    box-shadow: 0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11),
        0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13);

    width: clamp(250px, 100%, 600px);
    @media ${DEVICE.mobileS} {
        margin-inline: 16px;
    }
    @media ${DEVICE.laptop} {
        margin-inline: 0px;
    }
`;

export const ToggleDelegationTitle = styled(Text)`
    color: var(
        --Light-theme-Rest-Foreground-Brand-Foreground,
        var(--Colors-Main-Violet, #5b5fc7)
    );

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;

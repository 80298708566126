import { ChevronEndMediumIcon, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { TeamCard } from "./TeamCard";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CardUser from "../../../features/employee_folder/components/Cards/CardPositionEntreprise/CardUser";
import { DEVICE } from "../../../constants/responsive";
import { isMobile } from "react-device-detect";

export const ContactKey = ({ key_contacts, is_pre_onboarding }) => {
    const redirectToAnnuaire = useNavigate();
    // * navigate to any employee
    const navigate = useNavigate();
    const _navigateToEmployee = (id) => {
        if (isNaN(id)) {
            return console.log("is not a valid ID employee");
        }

        return navigate(ROUTES.employee_folder.custom_route(id));
    };

    return (
        <FlexContainer column gap="gap.medium">
            <SectionName content="Mes contacts clés" />
            <MainContactKey
                vAlign="center"
                space="between"
                column={isMobile}
                hAlign={isMobile ? "center" : "left"}
            >
                {key_contacts?.length === 0 ? (
                    <Text
                        styles={{ color: "#616161" }}
                        content="Aucun contact clé renseigné"
                    />
                ) : (
                    <Flex
                        gap="gap.large"
                        column={isMobile}
                        hAlign={isMobile ? "center" : "left"}
                    >
                        {key_contacts?.map((el, loopIndex) => {
                            return (
                                <CardUser
                                    key={"key-" + loopIndex}
                                    avatar={el?.avatar}
                                    full_name={`${el?.first_name} ${el?.last_name}`}
                                    job_title={el?.job_title}
                                    hideTitle={false}
                                    onClick={() => _navigateToEmployee(el?.id)}
                                />
                            );
                        })}
                    </Flex>
                )}

                {!is_pre_onboarding && (
                    <ShowAllCompanyBox
                        vAlign="center"
                        hAlign="center"
                        onClick={() => {
                            redirectToAnnuaire("/annuaire");
                        }}
                    >
                        <ContactKeyLink content="Voir tout l'établissement" />
                        <ChevronEndMediumIcon size="small" />
                    </ShowAllCompanyBox>
                )}
            </MainContactKey>
        </FlexContainer>
    );
};

const FlexContainer = styled(Flex)`
    @media ${DEVICE.mobileS} {
        margin-top: 25px;
        margin-bottom: 24px;
        margin-inline: 16px;
    }
    @media ${DEVICE.laptop} {
        margin-bottom: 55px;
        margin-inline: 0px;
    }
`;

const MainContactKey = styled(Flex)`
    padding: 24px 16px;
    gap: 24px;
    background: #ffffff;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
`;

const ContactKeyLink = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #0067b8;
`;

const ShowAllCompanyBox = styled(Flex)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;
    width: 285px;
    height: 20px;
    color: #0067b8;

    gap: 6px;
    cursor: pointer;
`;

const SectionName = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #242424;
    @media ${DEVICE.laptop} {
        padding: 0px 0px 0px 12px;
    }
    @media ${DEVICE.mobileS} {
        padding: 0px 0px 0px 0px;
    }
`;

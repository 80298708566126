import { Flex } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const Badge = ({ content }) => {
    return (
        <FlexStyle>
            <TextStyle>{content}</TextStyle>
        </FlexStyle>
    );
};

export default Badge;

const FlexStyle = styled(Flex)`
    border: 2px solid white;
    padding: 4px 8px;
    border-radius: 4px;
`;

const TextStyle = styled(Flex)`
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    color: var(--color-primary);
`;

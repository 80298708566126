import React from "react";
import { Avatar, Button, Flex } from "@fluentui/react-northstar";
import {
    Container,
    Row,
    RowLeft,
    RowRight,
    TextBold,
    TextLight,
    TextName,
    Title,
    ValueBold,
} from "../style";
import { useTranslation } from "react-i18next";
import { STATUS } from "..";
import { I18N_NS_LEAVE } from "../../leave/constants";

const Validate = ({
    data,
    isLoadingMutate,
    status,
    setStatus,
    _validate_or_not_request_leave,
}) => {
    const { t } = useTranslation(I18N_NS_LEAVE);

    return (
        <Container column fill styles={{ zIndex: 3 }}>
            <Flex>
                <Title content={t("extern_manager_validation_leave.title")} />
            </Flex>
            <Flex styles={{ marginTop: "48px" }} column>
                <Row fill>
                    <RowLeft column>
                        <TextBold
                            content={t(
                                "extern_manager_validation_leave.assigned_to"
                            )}
                        />
                    </RowLeft>
                    <RowRight vAlign="center" gap="gap.smaller">
                        <Avatar
                            name={data?.user_full_name}
                            image={data?.user_avatar}
                            size="small"
                        />
                        <TextName content={data?.user_full_name} />
                    </RowRight>
                </Row>
                <Row fill>
                    <RowLeft column>
                        <TextBold
                            content={t(
                                "extern_manager_validation_leave.type_leave"
                            )}
                        />
                    </RowLeft>
                    <RowRight vAlign="center" space="between">
                        <ValueBold content={data?.leave_type_name} />
                    </RowRight>
                </Row>
                <Row fill>
                    <RowLeft column>
                        <TextBold
                            content={t(
                                "extern_manager_validation_leave.leave_period"
                            )}
                        />
                    </RowLeft>
                    <RowRight vAlign="center" space="between">
                        <Flex flex="2" space="between" fill gap="gap.medium">
                            <Flex>
                                <ValueBold content={data?.start_date} />
                            </Flex>
                            <Flex>
                                <ValueBold content="-" />
                            </Flex>
                            <Flex>
                                <ValueBold content={data?.end_date} />
                            </Flex>
                        </Flex>
                        <Flex flex="1" fill hAlign="center">
                            <TextLight
                                content={t(
                                    "extern_manager_validation.leave_balance_count",
                                    {
                                        leave_balance_count:
                                            data?.leave_balance_count,
                                    }
                                )}
                            />
                        </Flex>
                    </RowRight>
                </Row>
                <Flex fill styles={{ paddingBlock: "12px" }}>
                    <TextLight
                        content={t("extern_manager_validation.request_date", {
                            date: data?.request_date,
                        })}
                    />
                </Flex>
                <Flex
                    hAlign="center"
                    gap="gap.small"
                    styles={{ marginTop: "28px" }}
                >
                    <Button
                        flat
                        content={t(
                            "extern_manager_validation_leave.button.refuse"
                        )}
                        variables={{ color: "#C4314B" }}
                        loading={isLoadingMutate && status === STATUS.reject}
                        onClick={() => {
                            setStatus(STATUS.reject);
                            _validate_or_not_request_leave(STATUS.reject);
                            return;
                        }}
                    />
                    <Button
                        primary
                        flat
                        loading={isLoadingMutate && status === STATUS.approve}
                        content={t(
                            "extern_manager_validation_leave.button.accept"
                        )}
                        onClick={() => {
                            setStatus(STATUS.approve);
                            _validate_or_not_request_leave(STATUS.approve);
                            return;
                        }}
                    />
                </Flex>
            </Flex>
        </Container>
    );
};

export default Validate;

import React from "react";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Illustration from "../../../../assets/illustrations/Processing_animated.svg";
import { SvgLoader } from "./SvgLoader";
import { I18N_NS_ADD_EMPLOYEE } from "../../constants";

const LoaderMan = () => {
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    return (
        <Flex column vAlign="center" hAlign="center" gap="gap.medium">
            <Flex>
                {/* <IllustrationStyle /> */}
                <SvgLoader />
            </Flex>
            <Flex column hAlign="center" gap="gap.small">
                <TextTitle>
                    {t("pages.add_employee.step_tasks.dialog_loader.title")}
                </TextTitle>
                <TextDesc>
                    {t(
                        "pages.add_employee.step_tasks.dialog_loader.description"
                    )}
                </TextDesc>
            </Flex>
        </Flex>
    );
};

export default LoaderMan;

const TextTitle = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
`;

const TextDesc = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

const IllustrationStyle = styled(Illustration)`
    #eEdaeCoyVOA14_tr {
        animation: eEdaeCoyVOA14_tr__tr 3000ms linear infinite normal forwards;
    }
    @keyframes eEdaeCoyVOA14_tr__tr {
        0% {
            transform: translate(136.950001px, 99.264004px) rotate(0deg);
        }
        100% {
            transform: translate(136.950001px, 99.264004px) rotate(-180deg);
        }
    }
    #eEdaeCoyVOA19_tr {
        animation: eEdaeCoyVOA19_tr__tr 3000ms linear infinite normal forwards;
    }
    @keyframes eEdaeCoyVOA19_tr__tr {
        0% {
            transform: translate(227.236992px, 153.881996px) rotate(0deg);
        }
        100% {
            transform: translate(227.236992px, 153.881996px) rotate(180deg);
        }
    }
`;

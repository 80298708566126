import React, { useRef, useState } from "react";
import { Container, FeedbackButton, TextFeedback, Wrapper } from "./style";
import { CommentEdit24Regular } from "@fluentui/react-icons";
import {
    Button,
    Flex,
    InputLabel,
    Menu,
    TextArea,
} from "@fluentui/react-northstar";
import { useMutation } from "react-query";
import { _api_send_feedback } from "../../api/feedback";

const FeedbackCard = ({ onSentFeedback }) => {
    const [message, setMessage] = useState("");
    const [type, setType] = useState(1);
    const [success, setSuccess] = useState(null);
    const { mutate, isLoading } = useMutation((data) =>
        _api_send_feedback(data)
    );

    const handleSubmit = () => {
        mutate(
            {
                type,
                message,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        setSuccess(true);
                        return setTimeout(() => {
                            setSuccess(null);
                            setMessage(null);
                            setType(1);
                            return onSentFeedback(data);
                        }, 2000);
                    }
                },
            }
        );
    };

    return (
        <Container column>
            <Flex column gap="gap.medium">
                <Flex column>
                    <InputLabel content="Type de Feedback" />
                    <Menu
                        defaultActiveIndex={0}
                        onItemClick={(e, i) => {
                            return setType(i.value);
                        }}
                        items={[
                            {
                                key: "general",
                                content: "Général",
                                value: 1,
                                styles: {
                                    width: "100%",
                                },
                            },
                            {
                                key: "bug",
                                content: "Bug",
                                value: 2,
                                styles: {
                                    width: "100%",
                                },
                            },
                            {
                                key: "idea",
                                content: "Idée",
                                value: 3,
                                styles: {
                                    width: "100%",
                                },
                            },
                        ]}
                        styles={{
                            width: "fit-content",
                        }}
                    />
                </Flex>
                <Flex column>
                    <InputLabel content="Message *" />
                    <TextArea
                        fluid
                        placeholder="Entrez votre feedback ..."
                        rows={8}
                        onChange={(_, i) => {
                            return setMessage(i?.value);
                        }}
                    />
                </Flex>
                <Button
                    content={
                        success
                            ? "👌 Merci pour votre feedback 👌"
                            : "Envoyer le feedback 🚀"
                    }
                    fluid
                    primary
                    size="medium"
                    disabled={message === ""}
                    loading={isLoading}
                    onClick={handleSubmit}
                />
            </Flex>
        </Container>
    );
};

const Feedback = () => {
    const [hover, setHover] = useState(false);
    const [showcard, setShowCard] = useState(false);

    const onMouseLeave = () => {
        if (!showcard) {
            setHover(false);
        }
        return;
    };

    return (
        <Wrapper>
            {showcard && (
                <FeedbackCard
                    onSentFeedback={(data) => {
                        setShowCard(false);
                        setHover(false);
                        return;
                    }}
                />
            )}
            <FeedbackButton
                vAlign="center"
                hAlign="center"
                gap="gap.small"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={onMouseLeave}
                onClick={() => setShowCard((state) => !state)}
                showcard={showcard}
            >
                <CommentEdit24Regular />
                {(hover || showcard) && (
                    <TextFeedback
                        truncated
                        content={showcard ? "fermer" : "faire un feedback"}
                    />
                )}
            </FeedbackButton>
        </Wrapper>
    );
};

export default Feedback;

import React from "react";

import {
    BackArrow,
    Container,
    InformationalText,
    Mail,
    MSButton,
    MSLogoStyle,
    WrapperClose,
    WrapperLeft,
    WrapperMail,
} from "./index.style";

import MSLogo from "../../assets/icons/microsoft.svg";

import { BiArrowBack } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { GoBack } from "./index.logic";
import { BASE_URL } from "../../constants";

const ConnectMs = ({ email = "" }) => {
    return (
        <Container>
            <BackArrow onClick={GoBack}>
                <BiArrowBack size={19} />
            </BackArrow>
            <WrapperMail>
                <WrapperLeft>
                    <FaUserCircle size={20} color={"grey"} />
                </WrapperLeft>
                <Mail>{email}</Mail>
                <WrapperClose>
                    <AiFillCloseCircle onClick={GoBack} />
                </WrapperClose>
            </WrapperMail>
            <InformationalText>
                Votre compte administrateur vous invite à vous connecter avec
                votre compte organisationnel
            </InformationalText>
            <MSButton href={BASE_URL + "/microsoft_sign_in"}>
                <MSLogoStyle src={MSLogo} alt="MS icon" />
                <span>Se connecter avec microsoft</span>
            </MSButton>
        </Container>
    );
};

export default ConnectMs;

import React from "react";
import BackHeader from "../../../../components/shared/BackHeader";
import { ExclamationCircleIcon, Flex, Loader } from "@fluentui/react-northstar";
import {
    Body,
    BorderInfo,
    Detail,
    DetailTitle,
    UL,
    WrapperCards,
} from "./styles/Home.style";
import { ContainerFluid } from "../../../../common/styles";
import useLogicDelegationHome from "../../hooks/useLogicDelegationHome";
import EachListCards from "../../components/EachListCards/EachListCards";
import { Trans } from "react-i18next";
import Toast from "../../../../components/Alerts";

const DelegationHome = () => {
    const { query, words, toast } = useLogicDelegationHome();

    return (
        <ContainerFluid column gap="gap.large">
            {/* Toast */}
            <Toast {...toast} onStatusChange={toast.onStatusChange} />
            {/* sub back header */}
            <Flex fill>
                <BackHeader title={words.back_header} />
            </Flex>
            {/* DETAIL SECTION */}
            <Body gap="gap.medium">
                <BorderInfo vAlign="center" hAlign="center">
                    <ExclamationCircleIcon size="large" />
                </BorderInfo>
                <Detail column gap="gap.smaller">
                    <DetailTitle content={words.home_detail_title} />
                    <UL>
                        <Trans
                            t={words.t}
                            i18nKey={"home.details.description"}
                            components={{
                                li: <li />,
                                u: <u />,
                                strong: <strong />,
                            }}
                        />
                    </UL>
                </Detail>
            </Body>
            {/* CARDS */}
            <WrapperCards wrap>
                <EachListCards
                    isLoading={query.isLoading}
                    data={query.data?.records}
                />
            </WrapperCards>
        </ContainerFluid>
    );
};

export default DelegationHome;

export const NAV_LIST = [
    {
        label: "Onboarding",
        href: "/onboarding",
    },
];

export function generate_tabs_onboarding(data = null) {
    const tabs = {
        waiting_preonboarding: {
            id: 0,
            label: "A démarrer",
            description: data?.waiting_preonboarding_users?.length ?? 0,
            // description: 0,
            backend_string: "waiting_preonboarding",
        },
        preonboarding: {
            id: 1,
            label: "Pré-onboarding",
            description: null,
            backend_string: "preonboarding",
        },
        onboarding: {
            id: 2,
            label: "En cours",
            description: null,
            backend_string: "onboarding",
        },
        end_onboarding: {
            id: 3,
            label: "À clôturer",
            description: data?.end_onboarding_users?.length ?? 0,
            backend_string: "end_onboarding",
        },
        suivi: {
            id: 4,
            label: "Suivi",
            description: null,
            backend_string: "suivi",
        },
    };
    const json = {
        tabs,
        tabs_array: Object.values(tabs),
    };
    return json;
}

export const DEBOUNCE_DELAY = 700;

export const TABVIEW = 2;

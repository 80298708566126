import {
    AddIcon,
    Avatar,
    Button,
    Dialog,
    Flex,
    Skeleton,
    Table,
    TableCell,
    TableRow,
    Text,
} from "@fluentui/react-northstar";
import React, { useEffect } from "react";
import styled from "styled-components";
import { LeaveStatusBadge } from "./LeaveStatusBadge";

import {
    Container,
    ContainerCard,
    ContainerFluid,
} from "../../../../common/styles";
import EmptyDemande from "../EmptyDemande";
import moment from "moment";
import { useState } from "react";
import { format_events_HR, refetchTeamBadge } from "../../utils";
import DialogLeaveDetail from "../../../../components/Timeline/components/Dialogs/DialogLeaveDetail";
import Toast from "../../../../components/Alerts";
import {
    AvatarStyle,
    CellFullName,
    DateText,
    TextSeparator,
} from "./CollabList";
import { CellWrapperBadge, ListBadge } from "./ManagerListLeave";
import {
    GlobalLabel,
    TextDate,
    TextLabel,
} from "./LeaveRequestList/index.style";
import { CustomBadge } from "../../../../components/Badge";
import {
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from "react-query";
import {
    _get_types_leave,
    _get_types_leave_for_filter,
    allSocietyLeave,
    Export_data_for_manager,
} from "../../api";
import { useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import Pagination from "../../../../components/Pagination/Pagination";
import {
    MobileText,
    TextTitle,
    TitleFlex,
} from "./ajustementSolde/SoldeAjustement";
import DialogFilterAjustment from "./FilterHistoryAjustment/DialogFilterAjustment";
import { _get_all_employees_and_departments } from "../../../../api/user";
import DefaultAvatarEmployee from "../../../../assets/icons/avatar.svg";
import SkeletonLeaveList from "./SkeletonListLeave/SkeletonLeaveList";
import { SIZES } from "../../../../constants/responsive";
import { useMediaQuery } from "react-responsive";
import { TYPES_STATUS_LEAVE } from "./FilterHistoryAjustment/constants";
const INIT_CONFIG_DATA = {
    visible: false,
    data: null,
};

const filter_or_export = [
    {
        id: 1,
        action: "Filter",
    },
    {
        id: 2,
        action: "Export",
    },
];

const RhLeaveList = ({
    approuveLeaveId,
    isLoadingDataSociety,
    refetchAllSocietyLeave,
    allSocietyDataLeave,
}) => {
    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileS})`,
    });
    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    const [RhAction, setRhAction] = useState(filter_or_export);
    // const [openDialog, setOpenDialog] = useState(false);
    const [leaveList, setLeaveList] = useState([]);
    const [configDetail, setConfigDetail] = useState(INIT_CONFIG_DATA);
    const [toastConfig, setToastConfig] = useState({
        content: "",
        visible: false,
        level: "warning",
    });
    const [searchParams, setSearchParams] = useSearchParams({
        employee: [],
        type: [],
        checkboxes: [],
        checkboxes_status: [],
        start_date: "",
        end_date: "",
    });
    const [sortedData, setSortedData] = useState({
        direction: "desc",
        leaves: [],
    });

    const header = {
        key: "header",
        style: {
            backgroundColor: "#F5F5F5",
        },
        items: [
            {
                key: "name",
                content: (
                    <Flex vAlign="center" gap="gap.small">
                        <Avatar image={DefaultAvatarEmployee} size="smaller" />
                        {!isMobileM ? (
                            <TextLabel content={"Nom Prénom"} />
                        ) : (
                            <Text
                                styles={{
                                    fontSize: "12px",
                                    whiteSpace: "nowrap",
                                }}
                                content={"Type d'absence"}
                            />
                        )}
                    </Flex>
                ),
            },
            {
                key: "leave_type",
                content: !isMobileM && (
                    <Flex vAlign="center" gap="gap.small">
                        <GlobalLabel content={"Type d'absence"} />
                        <CustomBadge
                            bg="black"
                            color="white"
                            size="small"
                            label="Durée"
                            rectangular={true}
                            style={{
                                borderRadius: "4px",
                            }}
                        />
                    </Flex>
                ),
            },
            {
                key: "start_date",
                content: !isMobileM && <GlobalLabel content={"Début"} />,
                style: {
                    justifyContent: "end",
                    alignItems: "center",
                },
            },
            {
                key: "end_date",
                content: !isMobileM && <GlobalLabel content={"Fin"} />,
                style: {
                    justifyContent: "start",
                    alignItems: "center",
                },
            },
            {
                key: "status",
                content: <GlobalLabel content={"Statut"} />,
                style: {
                    justifyContent: "center",
                    alignItems: "center",
                },
            },
        ],
    };

    const defaultCheckboxesStatus =
        searchParams.getAll("s").length > 0
            ? searchParams.getAll("s")
            : TYPES_STATUS_LEAVE.map((e) => e.id);

    const methodsAjustment = useForm({
        defaultValues: {
            checkboxes: searchParams.getAll("a") ?? [],
            // checkboxes_status: searchParams.getAll("s") ?? [],
            checkboxes_status: defaultCheckboxesStatus,
            start_date: null,
            end_date: searchParams.get("end_date")
                ? new Date(searchParams.get("end_date"))
                : null,
            employee: [],
            type: searchParams.getAll("t") ?? [],
        },
    });
    const format_events_full_calendar = () => {
        return format_events_HR(allSocietyDataLeave?.users);
    };

    const onFilter = ({ filteredId }) => {
        let old_data = format_events_full_calendar();
        let statusIds = [filteredId];
        if (filteredId === 2) {
            statusIds.push(3);
        }
        if (filteredId === 4) {
            // refetchTeamCall();
            setLeaveList(old_data);
            return setSortedData((state) => {
                return {
                    ...state,
                    leaves: old_data,
                };
            });
        }

        const allFiltered = old_data?.filter((el) =>
            statusIds.includes(el?.data?.status?.id)
        );

        // refetchTeamCall();
        setLeaveList(allFiltered);
        setSortedData((state) => {
            return {
                ...state,
                leaves: allFiltered,
            };
        });
    };

    useEffect(() => {
        let RhLeaveList = format_events_full_calendar();
        setLeaveList(RhLeaveList);
        setSortedData((state) => {
            return {
                ...state,
                leaves: RhLeaveList,
            };
        });
    }, []);

    useEffect(() => {
        if (!approuveLeaveId) return;
        return onFilter({ filteredId: 1 });
    }, [approuveLeaveId]);

    const successRequest = ({ toastMessage = "", level = "success" }) => {
        // show toast.
        setToastConfig({
            visible: true,
            content: toastMessage,
            level,
        });
        refetch();
        return;
    };

    // QUERIES
    const { data, fetchNextPage, isLoading, isFetchingNextPage, refetch } =
        useInfiniteQuery({
            queryKey: [
                "leave::getHRListLeaveRow",
                {
                    params_string: searchParams.toString(),
                },
            ],
            queryFn: (state) => {
                return allSocietyLeave({
                    page: state?.pageParam,
                    ...state.queryKey.at(1),
                });
            },
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            getNextPageParam: (lastPage, pages) => {
                return lastPage.next_page_value;
            },
        });

    const { data: employees, isLoading: is_loading_employees } = useQuery(
        ["leave::get_list_employee"],
        () => {
            return _get_all_employees_and_departments();
        },
        {
            onSuccess: (data) => {
                const ud = searchParams.getAll("ud");
                const selected_employee_params = data?.filter((d) =>
                    ud?.includes(d?.id)
                );
                methodsAjustment.setValue(
                    "employee",
                    selected_employee_params?.map((element) => ({
                        id: element?.id,
                        header: element?.name,
                        image: element?.avatar ?? <AddIcon />,
                        content: element.job_title,
                    }))
                );
            },
        }
    );

    const { data: leave_types, isLoading: is_loading_types } = useQuery(
        ["leave::get_list_types_leave"],
        () => {
            return _get_types_leave_for_filter();
        },
        {
            onSuccess: (data) => {
                console.log({ data3: data });
                const t = searchParams.getAll("t");
                const selected_type = data?.filter((d) =>
                    t?.includes(d?.id + "")
                );
                methodsAjustment.setValue(
                    "type",
                    selected_type?.map((element) => ({
                        id: element?.id,
                        header: element?.name,
                        color: element?.color,
                    }))
                );
            },
        }
    );

    // FUNCTIONS
    const onSubmitAjustmentFilter = (data) => {
        console.log({ data, searchParams });
        const new_params = {};

        if (data?.start_date)
            new_params.start_date = moment(data?.start_date).format(
                "YYYY-MM-DD"
            );
        if (data?.end_date)
            new_params.end_date = moment(data?.end_date).format("YYYY-MM-DD");

        setSearchParams({
            ...new_params,
            ud: data?.employee?.map((u) => u.id),
            s: data?.checkboxes_status?.map((cb) => cb),
            t: data?.type?.map((t) => t.id),
        });
        // refetch();

        return;
    };

    const loading = isLoadingDataSociety;

    // VALUES
    const last_page = data?.pages[data?.pages?.length - 1];
    const currentPageToShow = last_page?.next_page_value
        ? (last_page?.next_page_value - 1) * 10
        : data?.pages[0]?.all_leaves_count;
    const is_more_rows = last_page?.next_page_value;

    const ROWS =
        data?.pages
            ?.map((pages, index) => {
                return pages?.leaves?.map((leave, i) => {
                    const username = leave?.user?.name;
                    const avatar = leave?.user?.avatar;
                    const status_id = leave?.status?.id;
                    const end_date = moment(leave?.end_date).format(
                        "DD/MM/YYYY"
                    );
                    const start_date = moment(leave?.start_date).format(
                        "DD/MM/YYYY"
                    );
                    const mobile_end_date = moment(leave?.end_date).format(
                        "DD/MM/YY"
                    );
                    const mobile_start_date = moment(leave?.start_date).format(
                        "DD/MM/YY"
                    );
                    const balance_count_to_remove =
                        leave?.balance_count_to_remove;
                    const { id: type_id, name: type_name, color } = leave?.type;
                    return {
                        key: leave?.id,
                        items: [
                            {
                                key: `${leave?.id}-1`,
                                content: (
                                    <Flex vAlign="center" gap="gap.small">
                                        <Avatar size="smaller" image={avatar} />
                                        {!isMobileM && (
                                            <TextLabel content={username} />
                                        )}
                                    </Flex>
                                ),
                                style: {
                                    Padding: "0px",
                                    justifyContent: "start",
                                    alignItems: "center",
                                },
                            },
                            {
                                truncateContent: true,
                                key: `${leave?.id}-2`,
                                content: (
                                    <CustomBadge
                                        bg={color}
                                        color="black"
                                        size="medium"
                                        label={
                                            <Flex
                                                vAlign="center"
                                                gap="gap.small"
                                            >
                                                <Text content={type_name} />
                                                {!isMobileM && (
                                                    <CustomBadge
                                                        bg="black"
                                                        color="white"
                                                        size="smaller"
                                                        label={`${balance_count_to_remove} j`}
                                                        rectangular={true}
                                                        style={{
                                                            borderRadius: "4px",
                                                        }}
                                                    />
                                                )}
                                            </Flex>
                                        }
                                        rectangular={true}
                                        style={{
                                            borderRadius: "4px",
                                            marginLeft: isMobileS
                                                ? "-30px"
                                                : isMobileM
                                                ? "-45px"
                                                : "0px",
                                            whiteSpace: "nowrap",
                                        }}
                                    />
                                ),
                            },
                            {
                                key: `${leave?.id}-3`,
                                content: (
                                    <TextDate
                                        content={
                                            isMobileS || isMobileM
                                                ? mobile_start_date
                                                : start_date
                                        }
                                    />
                                ),
                                style: {
                                    justifyContent: "end",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                },
                            },
                            {
                                key: `${leave?.id}-4`,
                                content: (
                                    <TextDate
                                        content={
                                            isMobileS || isMobileM
                                                ? mobile_end_date
                                                : end_date
                                        }
                                    />
                                ),
                                style: {
                                    justifyContent: "start",
                                    alignItems: "center",
                                },
                            },
                            {
                                key: `${leave?.id}-5`,
                                content: LeaveStatusBadge(status_id),
                                style: {
                                    justifyContent: "center",
                                    alignItems: "center",
                                },
                            },
                        ],
                        data_row: leave,
                        style: {
                            cursor: "pointer",
                        },
                    };
                });
            })
            ?.flat() ?? [];

    const queryClient = useQueryClient();

    const { isLoading: mutate_is_loading, mutate } = useMutation((data) =>
        Export_data_for_manager(data)
    );

    const onSubmitExportLeaves = (data) => {
        mutate(data);
        return;
    };

    return (
        <ContainerFluid>
            <Container>
                <Toast
                    onStatusChange={() =>
                        setToastConfig({
                            visible: false,
                            content: "",
                            level: "warning",
                        })
                    }
                    level={toastConfig.level}
                    content={toastConfig.content}
                    visible={toastConfig.visible}
                />
                <ContainerCard column fill>
                    {/* TITLE + FILTER */}
                    <Flex vAlign="center" space="between">
                        <TitleFlex vAlign="center" gap="gap.large">
                            {isMobileS || isMobileM ? (
                                <MobileText content="Liste des demandes" />
                            ) : (
                                <TextTitle
                                    content={"Liste des demandes d'absences"}
                                />
                            )}
                            {/* filtrer les resultats  */}
                            <FormProvider {...methodsAjustment}>
                                <form
                                    onSubmit={methodsAjustment.handleSubmit(
                                        onSubmitAjustmentFilter
                                    )}
                                >
                                    <DialogFilterAjustment
                                        // openDialog={openDialog}
                                        // setOpenDialog={setOpenDialog}
                                        filterData={true}
                                        exportData={false}
                                        isMobile={isMobileS || isMobileM}
                                        onSubmitAjustmentFilter={
                                            onSubmitAjustmentFilter
                                        }
                                        employees={employees}
                                        is_loading_employees={
                                            is_loading_employees
                                        }
                                        leave_types={leave_types}
                                        is_loading_types={is_loading_types}
                                        refetch={() => refetch()}
                                        isHR={true}
                                        isManager={true}
                                        header={"Filtrer les données"}
                                    />
                                </form>
                            </FormProvider>
                        </TitleFlex>
                        {/* export dialog */}
                        <FormProvider {...methodsAjustment}>
                            <form
                                onSubmit={methodsAjustment.handleSubmit(
                                    onSubmitExportLeaves
                                )}
                            >
                                <DialogFilterAjustment
                                    filterData={false}
                                    exportData={true}
                                    // openDialog={openDialog}
                                    // setOpenDialog={setOpenDialog}
                                    isDownloadingExcel={mutate_is_loading}
                                    isMobile={isMobileS || isMobileM}
                                    onSubmitAjustmentFilter={
                                        onSubmitExportLeaves
                                    }
                                    employees={employees}
                                    is_loading_employees={is_loading_employees}
                                    leave_types={leave_types}
                                    is_loading_types={is_loading_types}
                                    refetch={() => refetch()}
                                    isHR={true}
                                    isManager={true}
                                    header={"Exporter les données pour Excel"}
                                />
                            </form>
                        </FormProvider>
                    </Flex>
                    <Table>
                        {/* header */}
                        <TableRow key={header.key} styles={header?.style}>
                            {header.items.map((head) => {
                                return (
                                    <TableCell
                                        key={head.key}
                                        styles={head?.style}
                                    >
                                        {head.content}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        {isLoading && <SkeletonLeaveList containAvatarColumn />}

                        {data?.pages[0]?.all_leaves_count === 0 ? (
                            <Container column>
                                <EmptyDemande forLeave={true} />
                            </Container>
                        ) : (
                            ROWS.flat().map((row) => {
                                return (
                                    <DialogLeaveDetail
                                        leave_details={{ ...row?.data_row }}
                                        user_details={{
                                            ...row?.data_row?.user,
                                        }}
                                        content={
                                            <TableRow
                                                key={row.key}
                                                styles={row.style}
                                            >
                                                {row.items.map((item) => {
                                                    return (
                                                        <TableCell
                                                            key={item.key}
                                                            styles={item.style}
                                                        >
                                                            {item.content}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        }
                                        cbOnApproveLeave={(data) => {
                                            const isSuccess = data?.success;

                                            refetchTeamBadge(queryClient);
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été acceptée"
                                                    : data?.message,
                                            });
                                        }}
                                        cbOnRefuseLeave={(data) => {
                                            const isSuccess = data?.success;
                                            refetchTeamBadge(queryClient);
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été refusée"
                                                    : data?.message,
                                            });
                                        }}
                                        cbOnCancelLeave={(data) => {
                                            const isSuccess = data?.success;
                                            refetchTeamBadge(queryClient);
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été Annulée"
                                                    : data?.message,
                                            });
                                        }}
                                        cbOnDeleteLeave={(data) => {
                                            const isSuccess = data?.success;
                                            refetchTeamBadge(queryClient);
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été Supprimée"
                                                    : data?.message,
                                            });
                                        }}
                                    />
                                );
                            })
                        )}
                    </Table>
                    <Pagination
                        buttonContentSeeMore={"Afficher plus de demandes"}
                        countMessage={"demandes affichées sur"}
                        currentCountRows={currentPageToShow}
                        isMore={is_more_rows}
                        isLoading={isLoading}
                        totalRows={data?.pages?.at(0).all_leaves_count}
                        isFetchingNextPage={isFetchingNextPage}
                        funcFetchNextPage={() => fetchNextPage()}
                    />
                    <Content column>
                        {leaveList?.map(({ data: el }) => {
                            return (
                                <DialogLeaveDetail
                                    content={
                                        <Row
                                            column
                                            key={el?.id}
                                            style={{
                                                cursor: "pointer",
                                                width: "100%",
                                            }}
                                        >
                                            <TableContent
                                                space="between"
                                                vAlign="center"
                                                gap="gap.small"
                                            >
                                                <CellFullName>
                                                    {loading ? (
                                                        <Skeleton animation="wave">
                                                            <Flex
                                                                vAlign="center"
                                                                style={{
                                                                    width: "200px",
                                                                }}
                                                                gap="gap.small"
                                                            >
                                                                <Skeleton.Shape
                                                                    round
                                                                    width="32px"
                                                                    height="32px"
                                                                />

                                                                <Skeleton.Line
                                                                    width="100px"
                                                                    height="24px"
                                                                />
                                                            </Flex>
                                                        </Skeleton>
                                                    ) : (
                                                        <AvatarSection vAlign="center">
                                                            <Avatar
                                                                image={
                                                                    el?.user
                                                                        ?.avatar
                                                                }
                                                                name={
                                                                    el?.user
                                                                        ?.name
                                                                }
                                                            />
                                                            <CollaboratorName
                                                                content={
                                                                    el?.user
                                                                        ?.name
                                                                }
                                                            />
                                                        </AvatarSection>
                                                    )}
                                                </CellFullName>

                                                {loading ? (
                                                    <Skeleton
                                                        animation="wave"
                                                        styles={{
                                                            width: "200px",
                                                        }}
                                                    >
                                                        <Skeleton.Line
                                                            width="150px"
                                                            height="24px"
                                                        />
                                                    </Skeleton>
                                                ) : (
                                                    <CellWrapperBadge gap="gap.smaller">
                                                        <AvatarStyle
                                                            image={
                                                                el?.data?.user
                                                                    ?.avatar
                                                            }
                                                            name={
                                                                el?.data?.user
                                                                    ?.name
                                                            }
                                                            size="small"
                                                        />
                                                        <ListBadge
                                                            name={
                                                                el?.type?.name
                                                            }
                                                            date={`${el?.balance_count_to_remove}j`}
                                                            background={
                                                                el?.type?.color
                                                            }
                                                        />
                                                    </CellWrapperBadge>
                                                )}

                                                <Flex
                                                    vAlign="center"
                                                    styles={{
                                                        gap: "5px",
                                                    }}
                                                >
                                                    <Flex>
                                                        {loading ? (
                                                            <Skeleton
                                                                animation="wave"
                                                                styles={{
                                                                    width: "112px",
                                                                }}
                                                            >
                                                                <Skeleton.Line
                                                                    width="100px"
                                                                    height="30px"
                                                                />
                                                            </Skeleton>
                                                        ) : (
                                                            <DateText
                                                                content={` ${moment(
                                                                    el?.start_date
                                                                ).format(
                                                                    "DD/MM/YYYY"
                                                                )}`}
                                                            />
                                                        )}
                                                    </Flex>
                                                    <TextSeparator content="-" />
                                                    <Flex
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        {loading ? (
                                                            <Skeleton
                                                                animation="wave"
                                                                styles={{
                                                                    width: "112px",
                                                                }}
                                                            >
                                                                <Skeleton.Line
                                                                    width="100px"
                                                                    height="30px"
                                                                />
                                                            </Skeleton>
                                                        ) : (
                                                            <DateText
                                                                content={`${moment(
                                                                    el?.end_date
                                                                ).format(
                                                                    "DD/MM/YYYY"
                                                                )}`}
                                                            />
                                                        )}
                                                    </Flex>
                                                </Flex>
                                                {loading ? (
                                                    <Skeleton
                                                        animation="wave"
                                                        styles={{
                                                            width: "112px",
                                                        }}
                                                    >
                                                        <Skeleton.Line
                                                            width="100px"
                                                            height="30px"
                                                        />
                                                    </Skeleton>
                                                ) : (
                                                    LeaveStatusBadge(
                                                        el?.status?.id
                                                    )
                                                )}
                                            </TableContent>
                                        </Row>
                                    }
                                    leave_details={{
                                        id: el?.id,
                                        approved_by: el?.approved_by,
                                        requested_by: el?.requested_by,
                                        authenticated_user_can_delete:
                                            el?.authenticated_user_can_delete,
                                        authenticated_user_can_validate:
                                            el?.authenticated_user_can_validate,
                                        balance_before_leave:
                                            el?.balance_before_leave,
                                        balance_after_leave:
                                            el?.balance_after_leave,
                                        start_date: el?.start_date,
                                        end_date: el?.end_date,
                                        balance_count_to_remove:
                                            el?.balance_count_to_remove,

                                        comment: el?.comment,
                                        documents: el?.documents,
                                        type: {
                                            color: el?.type?.color,
                                            name: el?.type?.name,
                                            has_balance: el?.type?.has_balance,
                                        },
                                        status: {
                                            id: el?.status?.id,
                                        },
                                        request_can_be_canceled:
                                            el?.request_can_be_canceled,
                                        requested_on: el?.requested_on,
                                        waiting_approval_by:
                                            el?.waiting_approval_by,
                                        last_action: {
                                            date: el.last_action?.date,
                                            status: el.last_action?.status,
                                        },
                                    }}
                                    user_details={{
                                        name: el?.user?.name,
                                        id: el?.user?.id,
                                        avatar: el?.user?.avatar,
                                    }}
                                    cbOnApproveLeave={(data) => {
                                        console.log({ data });
                                        refetchAllSocietyLeave();
                                        return setToastConfig({
                                            visible: true,
                                            content:
                                                data?.success === true
                                                    ? "La demande a bien été acceptée  "
                                                    : data?.message,
                                            level:
                                                data?.success === true
                                                    ? "success"
                                                    : "danger",
                                        });
                                    }}
                                    cbOnCancelLeave={(data) => {
                                        const leave_request_id = el?.id;
                                        const new_leave_list = leaveList.filter(
                                            (leave) =>
                                                leave.id !== leave_request_id
                                        );

                                        setLeaveList(new_leave_list);
                                        refetchAllSocietyLeave();
                                        setToastConfig({
                                            visible: true,
                                            content:
                                                data?.success === true
                                                    ? "Votre demande a bien été annulée"
                                                    : data?.message,
                                            level:
                                                data?.success === true
                                                    ? "success"
                                                    : "danger",
                                        });

                                        return;
                                    }}
                                    cbOnDeleteLeave={(data) => {
                                        const leave_request_id = el?.id;
                                        const new_leave_list = leaveList.filter(
                                            (leave) =>
                                                leave.id !== leave_request_id
                                        );

                                        setLeaveList(new_leave_list);
                                        refetchAllSocietyLeave();
                                        setToastConfig({
                                            visible: true,
                                            content:
                                                data?.success === true
                                                    ? "La demande a bien été supprimée  "
                                                    : data?.message,
                                            level:
                                                data?.success === true
                                                    ? "success"
                                                    : "danger",
                                        });

                                        return;
                                    }}
                                    cbOnRefuseLeave={(data) => {
                                        const leave_request_id = el?.id;
                                        const new_leave_list = leaveList.filter(
                                            (leave) =>
                                                leave.id !== leave_request_id
                                        );

                                        setLeaveList(new_leave_list);
                                        refetchAllSocietyLeave();
                                        setToastConfig({
                                            visible: true,
                                            content:
                                                data?.success === true
                                                    ? "Votre demande a bien été refusée"
                                                    : data?.message,
                                            level:
                                                data?.success === true
                                                    ? "success"
                                                    : "danger",
                                        });
                                        // refetchTeamCall();
                                        return;
                                    }}
                                    cbOnHideDialog={() =>
                                        setConfigDetail(INIT_CONFIG_DATA)
                                    }
                                />
                            );
                        })}
                    </Content>
                </ContainerCard>
            </Container>
        </ContainerFluid>
    );
};

export default RhLeaveList;

const Content = styled(Flex)`
    height: 50vh;
    overflow-y: scroll;
`;

const CollaboratorName = styled(Text)`
    color: #424242;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

const Row = styled(Flex)`
    border-radius: 4px;
    border-bottom: 1px solid #d1d1d1;
    padding: 10px;
    &:hover {
        background-color: #ededed;
    }
`;

const AvatarSection = styled(Flex)`
    gap: 8px;
    width: 200px;
`;
const TableContent = styled(Flex)``;

import fetcher from "../../utils/fetcher";
import { BASE_URL_API } from "../../constants";
import { useStepper } from "../../features/addEmployees/stores";

// Modifier un collaborateur

export const modifyCollaborator = async (data) => {
    const { id_user } = useStepper.getState().step_personal_informations;
    // sending data to the server
    return fetcher(`${BASE_URL_API}/onboarding/user/create/${id_user}`, {
        method: "PATCH",
        body: JSON.stringify({
            step: "update_personal_infos",
            ...data,
        }), // The data
        credentials: "include",
    });
};

export const createUser = async (data) => {
    // sending data to the server
    return fetcher(`${BASE_URL_API}/onboarding/user/create`, {
        method: "POST",
        body: JSON.stringify(data), // The data
        credentials: "include",
    });
};

export const getManagers = async () => {
    return fetcher(BASE_URL_API + "/manager/list", {
        method: "GET",
        credentials: "include",
    });
};

export const getServices = async () => {
    return fetcher(BASE_URL_API + "/services/list", {
        method: "GET",
        headers: {
            "Content-type": "application/json",
        },
        credentials: "include",
    });
};

export const getAgencies = async () => {
    return fetcher(BASE_URL_API + "/agencies/list", {
        method: "GET",
        headers: {
            "Content-type": "application/json",
        },
        credentials: "include",
    });
};

// get all collaborattor
export const getAllCollaborator = async (page) => {
    return fetcher(`${BASE_URL_API}/onboarding/user/list?page=${page + 1}`, {
        method: "GET",
        headers: {
            "Content-type": "application/json", // The type of data you're sending
        },
        credentials: "include",
    });
};

export const _get_user_informations = async (id) => {
    return fetcher(BASE_URL_API + "/onboarding/user/create/" + id, {
        method: "GET",
        credentials: "include",
    });
};

export const _get_valid_user_informations = async (id) => {
    return fetcher(BASE_URL_API + "/user/" + id + "/profile", {
        method: "GET",
        credentials: "include",
    });
};

export const getMyInformations = () => {
    return fetcher(BASE_URL_API + "/me", {
        method: "GET",
        credentials: "include",
    });
};

// collaborateur interrompu

export const interruptCollaborator = async (id) => {
    // sending interrupting data to the server
    return fetcher(`${BASE_URL_API}/onboarding/user/${id}/interrupt`, {
        method: "PATCH",
        credentials: "include",
    });
};

// get profil employee by user id
export const getEmployeeById = ({ id_user }) => {
    return fetcher(`${BASE_URL_API}/user/${id_user}/profile`, {
        method: "GET",
        credentials: "include",
    });
};

export const _getAllUsers = () => {
    return fetcher(`${BASE_URL_API}/user/list`, {
        method: "GET",
        credentials: "include",
    });
};

export const _get_all_employees_and_departments = () => {
    return fetcher(`${BASE_URL_API}/leave/user/list`, {
        method: "GET",
        credentials: "include",
    });
};

export const LANGUES = [
    {
        nativeName: "English",
        code: "en",
        flag: `https://flagcdn.com/gb-eng.svg`,
        flag_code: "gb-eng",
        soon: true,
    },
    {
        nativeName: "Français",
        code: "fr",
        flag: `https://flagcdn.com/fr.svg`,
        flag_code: "fr",
        soon: false,
    },
    {
        nativeName: "Deutsch",
        code: "de",
        flag: `https://flagcdn.com/de.svg`,
        flag_code: "de",
        soon: true,
    },
    {
        nativeName: "Italien",
        code: "it",
        flag: `https://flagcdn.com/it.svg`,
        flag_code: "it",
        soon: true,
    },
];

import React from "react";
import { useState } from "react";
import {
    Avatar,
    Button,
    CloseIcon,
    Dialog,
    EditIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import CardUser from "./CardUser";
import { CustomBadge } from "../../../../../components/Badge";
import {
    PeopleTeam16Filled,
    BuildingBank16Filled,
} from "@fluentui/react-icons";
import BadgeIcon from "../../BadgeIcon";
import Supervise from "../../Dialogs/Supervise";
import { ROUTES } from "../../../../../constants/routes";
import { ROLES_CODE, roles_access } from "../../../../../constants/roles";
import DialogUpdateManagerContent from "../../Dialogs/DialogUpdateManagerContent";
import DialogUpdateRhContent from "../../Dialogs/DialogUpdateRhContent";
import DialogUpdateSupervisorManager from "../../Dialogs/DialogUpdateSupervisorManager";
import DialogUpdateManager from "../../Dialogs/DialogUpdateManagerContent/DialogUpdateManager";
import DialogUpdateHr from "../../Dialogs/DialogUpdateHr";
import DialogUpdateAgencyDepartment from "../../Dialogs/DialogUpdateAgencyDepartmentContent/DialogUpdateAgencyDepartment";

const CardPositionEntreprise = ({
    teamManager,
    teamHr,
    manager,
    rh,
    default_agency_id,
    default_service_id,
    agency_name,
    department_name,
    isEmployeeManager,
    isEmployeeHR,
}) => {
    const navigate = useNavigate();

    const [openDialogModifyManager, setOpenDialogModifymanager] =
        useState(false);

    const [openDialogModifyHR, setOpenDialogModifyHR] = useState(false);
    const [
        openDialogModifySupervisorManager,
        setOpenDialogModifySupervisorManager,
    ] = useState(false);

    const [
        openDialogModifyAgencyDepartment,
        setOpenDialogModifyAgencyDepartment,
    ] = useState(false);

    const len_team_manager = teamManager?.length;
    const len_team_hr = teamHr?.length;

    const isHR = roles_access({
        usersPrivileges: [ROLES_CODE.hr],
    });

    // * open or close dialogs functions
    const _openOrCloseDialogModifyManager = (bool) => {
        return setOpenDialogModifymanager(bool);
    };

    const _openOrCloseDialogModifyHR = (bool) => {
        return setOpenDialogModifyHR(bool);
    };

    const _openOrCloseDialogModifyAgencyDepartment = (bool) => {
        return setOpenDialogModifyAgencyDepartment(bool);
    };

    const _openOrCloseDialogModifySupervisorManager = (bool) => {
        return setOpenDialogModifySupervisorManager(bool);
    };

    // * navigate to any employee
    const _navigateToEmployee = (id) => {
        if (isNaN(id)) {
            return console.log("is not a valid ID employee");
        }

        return navigate(ROUTES.employee_folder.custom_route(id));
    };

    return (
        <Flex column gap="gap.large">
            {/* dialogs */}
            <DialogUpdateManager
                open_default={openDialogModifyManager}
                onClose={() => _openOrCloseDialogModifyManager(false)}
                default_manager={manager}
            />
            <DialogUpdateHr
                default_hr={rh}
                open_default={openDialogModifyHR}
                onClose={() => _openOrCloseDialogModifyHR(false)}
            />
            <DialogUpdateAgencyDepartment
                open_default={openDialogModifyAgencyDepartment}
                default_agency_id={default_agency_id}
                default_service_id={default_service_id}
                onClose={() => _openOrCloseDialogModifyAgencyDepartment(false)}
            />
            {/* dialog for modify Supervisor of manager team */}
            <Dialog
                open={openDialogModifySupervisorManager}
                header="Modifier la supervision Manager"
                content={<DialogUpdateSupervisorManager />}
                onCancel={() =>
                    _openOrCloseDialogModifySupervisorManager(false)
                }
                confirmButton={{
                    content: "Confirmer",
                    flat: true,
                }}
                cancelButton={{
                    content: "Annuler",
                    flat: true,
                }}
                styles={{
                    width: "391px",
                }}
            />

            <Flex>
                <Title content={"Position dans l’entreprise"} />
            </Flex>
            {/* manager & HR */}
            <Flex space="between">
                <CardUser
                    full_name={`${manager?.first_name} ${manager?.last_name}`}
                    job_title={manager?.job_title}
                    title="Manager"
                    avatar={manager?.avatar}
                    canModify={isHR}
                    onClick={() => _navigateToEmployee(manager?.id)}
                    onModify={() => _openOrCloseDialogModifyManager(true)}
                />
                <CardUser
                    full_name={`${rh?.first_name} ${rh?.last_name}`}
                    job_title={rh?.job_title}
                    title="RH"
                    avatar={rh?.avatar}
                    onClick={() => _navigateToEmployee(rh?.id)}
                    canModify={isHR}
                    onModify={() => _openOrCloseDialogModifyHR(true)}
                />
                <Flex
                    column
                    gap="gap.small"
                    hAlign="start"
                    fill
                    styles={{ maxWidth: "240px" }}
                >
                    <Flex space="between" fill vAlign="center">
                        <Title content={"Agence & département"} />
                        {isHR && (
                            <Button
                                primary
                                text
                                iconOnly
                                icon={<EditIcon />}
                                onClick={() =>
                                    _openOrCloseDialogModifyAgencyDepartment(
                                        true
                                    )
                                }
                                flat
                            />
                        )}
                    </Flex>
                    <BadgeIcon
                        icon={<PeopleTeam16Filled color=" #616161" />}
                        content={agency_name}
                    />
                    <BadgeIcon
                        icon={<BuildingBank16Filled color=" #616161" />}
                        content={department_name}
                    />
                </Flex>
            </Flex>
            {/* Team Manager */}
            {isEmployeeManager && (
                <Flex column>
                    <Flex column gap="gap.medium">
                        <Flex space="between">
                            <Text
                                content={
                                    <SubTitle>
                                        Supervise en tant que <b>Manager</b>{" "}
                                        <CustomBadge
                                            bg="orange"
                                            color="white"
                                            label={`${len_team_manager}`}
                                            size="smaller"
                                        />
                                    </SubTitle>
                                }
                            />
                            {/* {isHR && (
                                <Button
                                    tinted
                                    content="Modifier"
                                    flat
                                    onClick={() =>
                                        _openOrCloseDialogModifySupervisorManager(
                                            true
                                        )
                                    }
                                />
                            )} */}
                        </Flex>
                        <Flex space="between" wrap>
                            {!len_team_manager && (
                                <EmptyEmployees content="Aucune personne trouvée" />
                            )}
                            {teamManager?.slice(0, 6)?.map((employee) => {
                                return (
                                    <CardUser
                                        key={employee?.id}
                                        avatar={employee?.avatar}
                                        full_name={`${employee?.first_name} ${employee?.last_name}`}
                                        job_title={employee?.job_title}
                                        hideTitle={true}
                                        onClick={() =>
                                            _navigateToEmployee(employee?.id)
                                        }
                                    />
                                );
                            })}
                        </Flex>
                    </Flex>
                    {len_team_manager > 6 && (
                        <Supervise
                            header="Supervise en tant que Manager"
                            users={teamManager}
                        />
                    )}
                </Flex>
            )}
            {/* Team HR */}
            {isEmployeeHR && (
                <Flex column>
                    <Flex column gap="gap.medium">
                        <Flex space="between">
                            <Text
                                content={
                                    <SubTitle>
                                        Supervise en tant que <b>RH</b>{" "}
                                        <CustomBadge
                                            bg="orange"
                                            color="white"
                                            label={`${len_team_hr}`}
                                            size="smaller"
                                        />
                                    </SubTitle>
                                }
                            />
                            {/* {isHR && <Button tinted content="Modifier" flat />} */}
                        </Flex>
                        <Flex space="between" wrap>
                            {!len_team_hr && (
                                <EmptyEmployees content="Aucune personne trouvée" />
                            )}
                            {teamHr?.slice(0, 6)?.map((employee) => {
                                return (
                                    <CardUser
                                        avatar={employee?.avatar}
                                        full_name={`${employee?.first_name} ${employee?.last_name}`}
                                        job_title={employee?.job_title}
                                        hideTitle={true}
                                        onClick={() =>
                                            _navigateToEmployee(employee?.id)
                                        }
                                    />
                                );
                            })}
                        </Flex>
                    </Flex>
                    {len_team_hr > 6 && (
                        <Supervise
                            header="Supervise en tant que RH"
                            users={teamHr}
                        />
                    )}
                </Flex>
            )}
        </Flex>
    );
};

export default CardPositionEntreprise;

const EmptyEmployees = styled(Text)`
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
    margin-bottom: 16px;
`;

const SubTitle = styled(Text)`
    color: #242424;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

const Title = styled(Text)`
    color: #242424;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
`;

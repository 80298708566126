import React from "react";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ChevronCircleRight28Regular } from "@fluentui/react-icons";
import { I18N_NS_EXPENSE } from "../constants";
import IllustrationPana from "../../../assets/illustrations/expenses/pana.svg";
import { useWhereIamExpenses } from "../store/useWhereIam.store";
import { useMediaQuery } from "react-responsive";
import { DEVICE, SIZES } from "../../../constants/responsive";

const ExpenseGoHistoryCard = ({ route }) => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    const navigate = useNavigate();
    const whereIam = useWhereIamExpenses();

    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    return (
        <GoToHistory fill to={route}>
            <Container gap="gap.medium" fill space="between" vAlign="center">
                <ImageStyle src={IllustrationPana} />

                <GoToHistoryLink>
                    {whereIam.isEmployeePage ? (
                        <GoHistoryText content={t("home.button_history")} />
                    ) : (
                        <GoHistoryText
                            content={t("home.button_history_manager")}
                        />
                    )}
                </GoToHistoryLink>
                <ChevronCircleRight28Regular className="icon-chevron" />
            </Container>
        </GoToHistory>
    );
};

export default ExpenseGoHistoryCard;

const ImageStyle = styled(Image)`
    width: clamp(80px, 2vw, 140px);
`;

const GoToHistory = styled(Link)`
    width: 100%;
    text-decoration: none;
    color: #242424;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    padding-inline: 6px;
`;
const GoToHistoryLink = styled.span``;

const GoHistoryText = styled(Text)`
    font-size: clamp(14px, 2vw, 18px);
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    width: 100%;

    @media ${DEVICE.mobileS} {
        white-space: wrap;
    }
    @media ${DEVICE.mobileM} {
        white-space: nowrap;
    }
`;

const Container = styled(Flex)`
    border-radius: 8px;
    background: #fff;
    padding: 24px;
    box-shadow: 0px 3.200000047683716px 7.199999809265137px 0px
            rgba(0, 0, 0, 0.13),
        0px 0.6000000238418579px 1.7999999523162842px 0px rgba(0, 0, 0, 0.11);
    /* min-width: fit-content;
    max-width: fit-content; */

    :hover {
        cursor: pointer;
        border-radius: 8px;
        outline: 1px solid #5b5fc7;
        background: #fff;

        /* shadow-16 */
        box-shadow: 0px 6.400000095367432px 14.399999618530273px 0px
                rgba(0, 0, 0, 0.13),
            0px 1.2000000476837158px 3.5999999046325684px 0px
                rgba(0, 0, 0, 0.11);
        ${GoToHistoryLink} {
            color: #5b5fc7;
        }
        .icon-chevron {
            color: var(--color-primary);
        }
    }
`;

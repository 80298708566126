import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { CommentRegular } from "@fluentui/react-icons";

const BadgeCounterCommentaire = ({ count = 0 }) => {
    if (count === 0) return null;
    return (
        <Wrapper hAlign="center" vAlign="center">
            <CommentRegular />
            <Content content={count} />
        </Wrapper>
    );
};

export default BadgeCounterCommentaire;

const Wrapper = styled(Flex)`
    height: 24px;
    padding: 4px 8px;
    gap: 4px;

    border-radius: 50px;
    background: #e8ebfa;
`;

const Content = styled(Text)`
    color: #242424;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

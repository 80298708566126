import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import Logo_yeetch from "./logo.svg";
import {
    LockClosed16Regular,
    ShieldKeyhole16Regular,
    Settings16Filled,
} from "@fluentui/react-icons";

const HeaderBadge = [
    {
        icon: <LockClosed16Regular />,
        name: "Sécurisé",
    },
    {
        icon: <ShieldKeyhole16Regular />,
        name: "Confidentiel",
    },
    {
        icon: <Settings16Filled />,
        name: "Evolutif",
    },
];

const svgBackground = `
<svg xmlns="http://www.w3.org/2000/svg" width="360" height="128" viewBox="0 0 360 128" fill="none">
  <path opacity="0.15" d="M-79.875 101.145C-70.002 80.4014 -27.2234 39.6151 64.907 42.4183C180.07 45.9223 240.087 123.29 330.698 115.441C421.309 107.592 393.833 11.303 426.375 12.0038" stroke="url(#paint0_linear_12133_27059)" stroke-width="23" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_12133_27059" x1="-79.875" y1="64.0004" x2="468.465" y2="64.0004" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#33EACE"/>
      <stop offset="0.94" stop-color="#7F5BC4"/>
    </linearGradient>
  </defs>
</svg>
`;

export default function MobileResetPassword() {
    return (
        <MobileContent fill column vAlign="center" hAlign="center">
            <ImageStyle src={Logo_yeetch} />
            <HeaderText content="Votre espace d’accueil et d’échange documentaire" />
            <BadgeFlex hAlign="center" fill>
                {HeaderBadge.map((item, index) => {
                    return (
                        <Badge vAlign="center" key={index}>
                            {item.icon}
                            <Text content={item.name} />
                        </Badge>
                    );
                })}
            </BadgeFlex>
        </MobileContent>
    );
}

const BadgeFlex = styled(Flex)`
    gap: 16px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    background: url("data:image/svg+xml;base64,${btoa(svgBackground)}")
        no-repeat center;
    background-size: cover;
    width: 100%; // Adjust as needed
    height: 158px; // Adjust as needed
`;

const Badge = styled(Flex)`
    border-radius: 4px;
    background: #2f2f4a;
    color: white;
    gap: 8px;
    padding: 8px;
`;

const MobileContent = styled(Flex)`
    flex: 1;
    gap: 16px;
`;

const ImageStyle = styled(Image)`
    width: 152px;
    height: 46px;
`;

const HeaderText = styled(Text)`
    width: 277px;
    color: #242424;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
`;

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthDetailStore } from "../../../../../stores/useAuthStore";
import { roles_access, ROLES_CODE } from "../../../../../constants/roles";
import { ChevronEndIcon, Flex, Image, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import IconDelegation from "../../../../../assets/illustrations/delegation/button-goto-delegation.svg";
import { ROUTES } from "../../../../../constants/routes";

const CardDelegation = () => {
    const params = useParams();
    const detail = useAuthDetailStore((state) => state.detail);
    const navigate = useNavigate();

    const isMe = detail.id === Number(params?.id_employee);
    const isManager = roles_access({
        usersPrivileges: [ROLES_CODE.manager],
    });

    if (!isMe || !isManager) {
        return null;
    }

    return (
        <Wrapper onClick={() => navigate(ROUTES.delegation_roles.home)}>
            <Flex vAlign="center">
                <Image src={IconDelegation} />
            </Flex>
            <Flex column fill vAlign="center">
                <Title>Déléguer un rôle</Title>
                <Description>Partager vos droits de validation</Description>
            </Flex>
            <Flex vAlign="center">
                <ChevronEndIcon />
            </Flex>
        </Wrapper>
    );
};

export default CardDelegation;

const Description = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

const Title = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Brand-Foreground-2, #4f52b2);
    font-family: "Baloo Bhaijaan 2";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const Wrapper = styled(Flex)`
    padding: 12px;
    gap: 12px;

    border-radius: 8px;
    background: var(--Color-background-hover, #e8ebfa);

    /* shadow-4 */
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);

    cursor: pointer;
`;

import React, { useState } from "react";
import { CloseIcon, Dialog } from "@fluentui/react-northstar";
import { FormProvider, useForm } from "react-hook-form";

import ContentConfirmationTaskList from "./ContentConfirmationTaskList";
import DialogModifyTaskConfirmationTasks from "./DialogModifyTaskConfirmationTasks";
import { useMutation, useQueryClient } from "react-query";
import { _api_assignation_tasks_user } from "../../../api/tasks/templates";
import { TASK_ACTIONS } from "../../../constants/tasks";
import { useBenefeciaryStore } from "./store/index.store";
import { DialogStyle } from "../style";
import { queryClient } from "../../..";

const INITIAL_TOAST = {
    visible: false,
    content: "",
    level: "danger",
    onStatusChange: () => {
        return console.log("DEFAULT => status Toast changed !");
    },
};

const DialogConfirmationTaskAttribution = ({
    tasks,
    setTasks,
    setShowAlert,
    setOpenConfirmationDialog,
}) => {
    const setBenefeciary = useBenefeciaryStore((state) => state.setBenefeciary);

    const [open, setOpen] = useState(true);

    const [openDialogModifyTask, setOpenDialogModifyTask] = useState({
        visible: false,
        task: null,
    });
    const [showToast, setShowToast] = useState(INITIAL_TOAST);

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            beneficiary: null,
        },
    });

    const {
        mutate: mutate_assign_tasks_to_user,
        isLoading: isLoadingAssignationTasks,
    } = useMutation((data) => _api_assignation_tasks_user(data), {
        onSuccess: (data) => {
            if (data?.success) {
                setShowAlert({
                    visible: true,
                    message: "Tâches attribuées au collaborateur.",
                    level: "success",
                });
                closeDialog();
            }
        },
    });

    const hideToast = () => {
        setShowToast(INITIAL_TOAST);
        return;
    };

    // submit the form for confirmation task attribution.
    const onSubmit = () => {
        // format the data to send to back.
        const tasks_formated = tasks?.map((el) => {
            if (el?.useform_data) {
                // the task is modified
                return {
                    name: el?.useform_data?.name,
                    description: el?.useform_data?.description,
                    due_date: el?.useform_data?.due_date,
                    assigned_to: el?.useform_data?.assigned_to?.username
                        ? el?.useform_data?.assigned_to?.username
                        : el?.useform_data?.assigned_to?.header,
                    related_to: el?.useform_data?.related_to?.username
                        ? el?.useform_data?.related_to?.username
                        : el?.useform_data?.related_to?.header,
                    task_to_validate: el?.useform_data?.task_to_validate,
                    validator_email: el?.useform_data?.who_validate?.username
                        ? el?.useform_data?.who_validate?.username
                        : el?.useform_data?.who_validate?.email ?? "",
                    type: el?.useform_data?.type,
                    category_id: el?.useform_data?.category,
                    document_required: el?.useform_data?.document_required,
                    document_action:
                        el?.useform_data?.document_action === 5
                            ? 0
                            : el?.useform_data?.document_action,
                    template_doc_ids:
                        el?.useform_data?.saved_files?.length !== 0
                            ? []
                            : el?.useform_data?.template_doc_ids,
                    current_doc_ids: [], // send empty
                    new_doc_ids: el?.useform_data?.saved_files?.map(
                        (el) => el?.id
                    ),
                };
            }
            // the task is by default.
            return {
                name: el?.name,
                description: el?.description,
                due_date: el?.due_date,
                assigned_to: el?.assigned_to_user?.email,
                related_to: el?.assigned_to_user?.email,
                task_to_validate: el?.task_to_validate,
                validator_email: el?.validator?.email || "",
                type: el?.type,
                category_id: el?.category_id,
                document_required: el?.document_required,
                document_action: el?.document_action,
                template_doc_ids: el?.documents_template?.map((el) => el?.id),
                current_doc_ids: [], // send empty
                new_doc_ids: [],
            };
        });
        console.log({ tasks_formated });
        // check if all task with required_document has minimum one document
        // eslint-disable-next-line no-unreachable
        let oneTaskRequireMinimumOneDocument = false;
        for (let i = 0; i < tasks_formated.length; i++) {
            const task = tasks_formated[i];

            const document_action_rh =
                TASK_ACTIONS[task?.document_action - 1]?.hr_document_required;
            if (
                document_action_rh &&
                task?.template_doc_ids?.length === 0 &&
                task?.new_doc_ids?.length === 0
            ) {
                oneTaskRequireMinimumOneDocument = true;
                break;
            }
        }

        if (oneTaskRequireMinimumOneDocument) {
            return setShowToast({
                visible: true,
                content:
                    "Vous avez oublié de fournir le document requis pour l'une des tâches séléctionnée !",
                level: "warning",
                icon: "",
                onStatusChange: hideToast,
            });
        }
        mutate_assign_tasks_to_user(tasks_formated);
        methods.reset();
        return;
    };

    const closeDialog = () => {
        setOpen(false);
        setOpenConfirmationDialog(false);
        setBenefeciary(null); // remettre le store benefeciary to null
        methods.clearErrors();
        methods.reset();
        queryClient.refetchQueries(["getAllTasksOfEmployee"]); // pour l'onboarding
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <DialogStyle
                        open={open}
                        header={"Confirmer les tâches à attribuer"}
                        headerAction={<CloseIcon onClick={closeDialog} />}
                        onCancel={closeDialog}
                        cancelButton="Annuler"
                        confirmButton={{
                            content: "Ajouter les tâches",
                            disabled: !methods.formState.isValid,
                            loading: isLoadingAssignationTasks,
                        }}
                        onConfirm={() => {
                            return methods.handleSubmit(onSubmit)();
                        }}
                        content={
                            <ContentConfirmationTaskList
                                tasks={tasks}
                                setTemplateTasks={setTasks}
                                setOpenDialogModifyTask={
                                    setOpenDialogModifyTask
                                }
                                showToast={showToast}
                            />
                        }
                        styles={{
                            width: "860px",
                        }}
                    />
                </form>
            </FormProvider>
            {openDialogModifyTask.visible && (
                <DialogModifyTaskConfirmationTasks
                    default_task={openDialogModifyTask?.task}
                    setTemplateTasks={setTasks}
                    setOpenDialogUpdateTask={setOpenDialogModifyTask}
                    openDialogUpdateTask={openDialogModifyTask?.visible}
                />
            )}
        </>
    );
};

export default DialogConfirmationTaskAttribution;

import React from "react";
import { Dropdown, Flex, InputLabel, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

const DialogUpdateAgencyDepartmentContent = ({
    services,
    agencies,
    isLoadingAgencies,
    isLoadingServices,
    default_agency_id,
    default_service_id,
    onChangeDropdownSelection,
}) => {
    // format list of agencies and services.
    const formated_services = services?.map((service) => {
        return {
            id: service?.id,
            header: service?.name,
        };
    });
    const formated_agencies = agencies?.map((agence) => {
        return {
            id: agence?.id,
            header: agence?.name,
        };
    });

    const _onChangeAgency = (_, i) => {
        const value = i?.value?.id;
        return onChangeDropdownSelection((state) => {
            return {
                ...state,
                agency_id: value,
            };
        });
    };
    const _onChangeDepartment = (_, i) => {
        const value = i?.value?.id;
        return onChangeDropdownSelection((state) => {
            return {
                ...state,
                department_id: value,
            };
        });
    };

    return (
        <Flex fill column>
            <Wrapper column gap="gap.large">
                <Flex column>
                    <InputLabel content={"Agence"} />
                    <Dropdown
                        items={formated_agencies}
                        placeholder=""
                        checkable
                        loading={isLoadingAgencies}
                        onChange={_onChangeAgency}
                        fluid
                        defaultValue={formated_agencies?.filter(
                            (agency) => agency?.id === default_agency_id
                        )}
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                    />
                </Flex>
                <Flex column>
                    <InputLabel content={"Département"} />
                    <Dropdown
                        items={formated_services}
                        loading={isLoadingServices}
                        placeholder=""
                        defaultValue={formated_services?.filter(
                            (service) => service?.id === default_service_id
                        )}
                        onChange={_onChangeDepartment}
                        checkable
                        fluid
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                        position="above"
                    />
                </Flex>
            </Wrapper>
        </Flex>
    );
};

export default DialogUpdateAgencyDepartmentContent;

const Wrapper = styled(Flex)`
    border-bottom: 1px solid #d1d1d1;
    padding-block: 24px;
`;

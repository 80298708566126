import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    IconButton,
    Toolbar,
    Typography,
} from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import styled from "styled-components";
// import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { _api_download_file } from "../../../api/manage_document";

function FileDocuments({
    handleClose,
    Transition,
    open,
    data,
    filename,
    typeDocument,
    idFile,
}) {
    return (
        <div>
            <DialogStyle
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar
                    sx={{
                        position: "relative",
                        backgroundColor: "red",
                        boxShadow: 0,
                    }}
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div"
                        >
                            Retour
                        </Typography>
                        <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div"
                        >
                            {filename}
                        </Typography>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                            onClick={() =>
                                _api_download_file({
                                    id: idFile,
                                    filename: filename,
                                })
                            }
                        >
                            {/* <a href={data.url} target="_blank" download> */}
                            <Button autoFocus color="inherit">
                                <DownloadIcon style={{ marginRight: "5px" }} />
                                Télécharger
                            </Button>
                            {/* </a> */}
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContentStyle>
                    {typeDocument === "image" ? (
                        <ImageStyle src={data} alt="the document" />
                    ) : (
                        <Object
                            data={data}
                            alt="this is a shit"
                            aria-label="this is a shit also"
                        />
                    )}
                </DialogContentStyle>
            </DialogStyle>
        </div>
    );
}

export default FileDocuments;

const ImageStyle = styled.img`
    border: 1px solid black;
    height: 90%;
    object-fit: cover;
    object-position: center;
`;

const DialogContentStyle = styled(DialogContent)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Object = styled.object`
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
`;

const DialogStyle = styled(Dialog)`
    .MuiPaper-root {
        background-color: #404040db;
    }
`;

import {
    Avatar,
    IconButton,
    styled,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import { green } from "@mui/material/colors";
import React, { useEffect } from "react";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { getAllCollaborator } from "../../../api/user";
import { useQuery } from "react-query";

export default function DetailsTable() {
    const navigate = useNavigate();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    //get all collaborator
    const { data, isLoading, refetch } = useQuery(["getCollaborator"], () =>
        getAllCollaborator(page)
    );

    useEffect(() => {
        refetch();
    }, [page]);

    if (isLoading) return <p>Chargement...</p>;

    const handleChangePage = (event, newPage) => {
        // change array
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <TableHeadStyle>
                <TableRowStyle>
                    <TableCellStyle
                        key={1}
                        align={"left"}
                        padding={"normal"}
                        // sortDirection={false}
                    >
                        <TableSortLabel
                            active={true}
                            direction={"desc"}
                            onClick={() => alert("bienvenue")}
                        >
                            {"Collaborateur"}
                        </TableSortLabel>
                    </TableCellStyle>
                    <TableCellStyle
                        key={2}
                        align={"left"}
                        padding={"normal"}
                        // sortDirection={false}
                    >
                        <TableSortLabel onClick={() => alert("bienvenue")}>
                            {"Matériel"}
                        </TableSortLabel>
                    </TableCellStyle>
                    <TableCellStyle
                        key={3}
                        align={"left"}
                        padding={"center"}
                        // sortDirection={false}
                    >
                        <TableSortLabel
                            active={true}
                            direction={"desc"}
                            onClick={() => alert("bienvenue")}
                        >
                            {"Statut"}
                        </TableSortLabel>
                    </TableCellStyle>
                </TableRowStyle>
            </TableHeadStyle>
            {/* Start from here */}

            <div style={{ height: "650px", overflowY: "scroll" }}>
                {data?.results?.map((res, i) => (
                    <div
                        key={i}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            height: "82px",
                            border: "1px solid #F5F5F5",
                            justifyContent: "space-around",
                            margin: "18px 0 8px 0",
                            borderRadius: "8px",
                            boxShadow:
                                "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
                        }}
                    >
                        <div
                            style={{ margin: "16px 16px 16px 16px" }}
                            elevation={1}
                        >
                            <Avatar
                                width="40px"
                                height="40px"
                                sx={{ bgcolor: green[500] }}
                                variant="rounded"
                                src="https://thispersondoesnotexist.com/image"
                                alt="I am collaborator"
                            />
                        </div>

                        <div>
                            <Typography variant="body1">
                                {res.first_name} {res.last_name}
                            </Typography>
                            <Typography
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    color: "rgba(0, 0, 0, 0.6)",
                                }}
                                variant="body2"
                            >
                                Arrivée prévue le {res.arrival_date}
                            </Typography>
                        </div>
                        <div
                            style={{
                                marginLeft: "55px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {res?.resources_count === 0 ? (
                                <>
                                    <DevicesOtherIcon
                                        style={{
                                            visibility: "hidden",
                                            color: "rgba(0, 0, 0, 0.6)",
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            color: "rgba(0, 0, 0, 0.6)",
                                            marginLeft: "10px",
                                            visibility: "hidden",
                                        }}
                                        variant="body2"
                                    >
                                        Dispose de Matériel
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <DevicesOtherIcon
                                        style={{ color: "rgba(0, 0, 0, 0.6)" }}
                                    />
                                    <Typography
                                        style={{
                                            color: "rgba(0, 0, 0, 0.6)",
                                            marginLeft: "10px",
                                        }}
                                        variant="body2"
                                    >
                                        Dispose de Matériel
                                    </Typography>
                                </>
                            )}
                        </div>
                        <Typography
                            variant="body2"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap="10px"
                            width={"100px"}
                            marginLeft={"80px"}
                        >
                            {res?.status === -1 ? (
                                <div
                                    style={{
                                        // marginLeft: "85px",
                                        display: "flex",
                                        alignItems: "center",

                                        // width: "100px",
                                    }}
                                >
                                    <PendingActionsIcon
                                        style={{
                                            width: "21px",
                                            height: "21px",

                                            color: "#ED6C02",
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            color: "#ED6C02",
                                            marginLeft: "10px",
                                        }}
                                        variant="body2"
                                    >
                                        En attente
                                    </Typography>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <CheckCircleIcon
                                        style={{
                                            width: "21px",
                                            height: "21px",
                                            color: "green",
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            color: "green",
                                            marginLeft: "10px",
                                        }}
                                        variant="body2"
                                    >
                                        validé
                                    </Typography>
                                </div>
                            )}
                        </Typography>

                        <div style={{ marginLeft: "105px" }}>
                            <IconButton
                                onClick={() =>
                                    res.status === -1
                                        ? navigate(
                                              "/addCollaborator?id=" + res.id
                                          )
                                        : navigate(
                                              `/profileOnboarding/${res.id}`
                                          )
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        </div>
                    </div>
                ))}
            </div>
            <TablePagination
                component="div"
                count={data?.count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

const TableCellStyle = styled(TableCell)(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {
        display: "flex",
        flex: 1,
    },
}));

const TableRowStyle = styled(TableRow)(({ theme }) => ({
    backgroundColor: "#f5f5f5",
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: "100%",
        backgroundColor: "white",
    },
}));
const TableHeadStyle = styled(TableHead)(({ theme }) => ({
    backgroundColor: "#f5f5f5",

    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        borderRadius: "8px 8px 0px 0px",
        overflow: "hidden",
    },
}));

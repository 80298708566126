import React, { useState } from "react";
import {
    Alert,
    Button,
    CheckmarkCircleIcon,
    CloseIcon,
    Dialog,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { PATH_TRANSLATION, PT_CREATE_TEMPLATE } from "../constants";
import DialogCreateTemplate from "./DialogCreateTemplate";
import { TaskListAdd24Regular } from "@fluentui/react-icons";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { _api_create_template } from "../../../api/tasks/templates";
import FormDialogUpdateManualTaskTemplate from "./FormDialogUpdateManualTaskTemplate";
import { useEffect } from "react";
import { I18N_NS_TRANSLATION } from "../../../constants";
import { DialogCancelConfirmation } from "../../../components/Dialogs/DialogCancelConfirmation";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";
import { DialogStyle } from "../style";

const FormDialogCreateTemplate = ({
    setShowAlert: setShowAlertAdded,
    refetch_get_list_templates,
}) => {
    const { t } = useTranslation(I18N_NS_TRANSLATION);

    const [open, setOpen] = useState(false);
    const [OpenConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const [openDialogModifyTask, setOpenDialogModifyTask] = useState({
        visible: false,
        task: null,
    });

    const [showAlert, setShowAlert] = useState({
        visible: false,
        message: "",
        level: "warning",
    });
    const [templateTasks, setTemplateTasks] = useState([]);

    const [slider, setSlider] = useState(1);

    // for creation of template model tasks.
    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            type: "1",
            tasks: [],
        },
    });

    useEffect(() => {
        // templates tasks changes.
        methods.setValue("tasks", templateTasks);
    }, [templateTasks, methods]);

    // use mutation to send data.
    const {
        mutate: APICallCreateTemplate,
        isLoading: isLoadingCreationTemplate,
    } = useMutation(
        (data) => {
            // API function
            return _api_create_template(data);
        },
        {
            onSuccess: (data) => {
                if (data?.success) {
                    setOpen(false);
                    setTemplateTasks([]);
                    setShowAlertAdded({
                        level: "success",
                        visible: true,
                        message: "Le modèle de tâches a bien été créé !",
                        icon: <CheckmarkCircleIcon />,
                    });
                    refetch_get_list_templates();
                    methods.reset();
                }
                return;
            },
            onError: (data) => {
                return alert(data);
            },
        }
    );

    const onSubmit = async (data) => {
        if (templateTasks?.length === 0) {
            return setShowAlert({
                visible: true,
                message: "il faut au minimum une tâche !",
            });
        }

        const formated_tasks = templateTasks.map((el) => {
            delete el.id;
            return {
                ...el,
                document_action:
                    el?.document_action === 5 ? 0 : el?.document_action,
            };
        });

        const formated_data = {
            name: methods.getValues("name"),
            description: methods.getValues("description"),
            type: parseInt(methods.getValues("type")) || 1,
            task_definitions: formated_tasks,
        };

        APICallCreateTemplate(formated_data);
    };

    const values = methods.watch();

    return (
        <Flex>
            <DialogCancelConfirmation
                open={OpenConfirmationDialog}
                header="Annuler la création du modèle de tâches"
                content="Êtes-vous sûr(e) de vouloir annuler ? Toutes les données seront perdues"
                confirmButton={{
                    content: "Annuler le modèle",
                    styles: {
                        backgroundColor: "#C4314B",
                    },
                }}
                cancelButton="Poursuivre la saisie"
                width="700"
                onClose={() => setOpenConfirmationDialog(false)}
                onConfirm={() => {
                    setOpenConfirmationDialog(false);
                    setOpen(false);
                    return;
                }}
            />
            <FormDialogUpdateManualTaskTemplate
                default_task={openDialogModifyTask?.task}
                setTemplateTasks={setTemplateTasks}
                openDialogUpdateTask={openDialogModifyTask?.visible}
                setOpenDialogUpdateTask={setOpenDialogModifyTask}
                methods={methods}
                styles={{ position: "absolute" }}
            />
            <DialogStyle
                open={open}
                header={<Text>{t(PT_CREATE_TEMPLATE + ".title")}</Text>}
                content={
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <DialogCreateTemplate
                                setTemplateTasks={setTemplateTasks}
                                setSlider={setSlider}
                                templateTasks={templateTasks}
                                showAlert={showAlert}
                                setShowAlert={setShowAlert}
                                setOpenDialogModifyTask={
                                    setOpenDialogModifyTask
                                }
                            />
                        </form>
                    </FormProvider>
                }
                confirmButton={{
                    disabled:
                        !methods.formState.isValid ||
                        templateTasks?.length === 0,
                    content: t(PT_CREATE_TEMPLATE + ".buttons.create"),
                }}
                onConfirm={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (templateTasks?.length === 0) {
                        return setShowAlert({
                            visible: true,
                            message: "il faut au minimum une tâche !",
                        });
                    }
                    onSubmit();
                    return;
                }}
                cancelButton={t(PT_CREATE_TEMPLATE + ".buttons.cancel")}
                onCancel={() => {
                    return setOpenConfirmationDialog(true);
                }}
                headerAction={<CloseIcon onClick={() => setOpen(false)} />}
                trigger={
                    <ButtonCreateModalTask
                        size="medium"
                        icon={<TaskListAdd24Regular />}
                        content={t(
                            PATH_TRANSLATION + ".btn_text_create_new_model"
                        )}
                        iconPosition="before"
                        primary
                        flat
                        onClick={() => setOpen(true)}
                        loading={isLoadingCreationTemplate}
                    />
                }
            />
        </Flex>
    );
};

export default FormDialogCreateTemplate;

export const ButtonCreateModalTask = styled(Button)`
    @media ${DEVICE.mobileS} {
        min-width: 100%;
    }
    @media ${DEVICE.tablet} {
        min-width: fit-content;
    }
    @media ${DEVICE.laptop} {
        min-width: fit-content;
    }
`;

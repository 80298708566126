import React, { useState } from "react";
import {
    Button,
    Flex,
    Image,
    InfoIcon,
    Input,
    InputLabel,
    Text,
    TextArea,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import {
    Container,
    ContainerFluid,
    ErrorMessage,
    LabelRequired,
} from "../../../common/styles";
import {
    CURRENCIES,
    I18N_NS_DOCUMENTS,
    I18N_NS_EXPENSE,
    TYPE_FORM,
    VALIDATORS_ADD_EXPENSE_KM_CH,
} from "../constants";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { _api_add_expense_km_ch } from "../api";
import { ROUTES } from "../../../constants/routes";
import { CardTitle, TitlePage } from "./ExpensesAddFormKmFr";
import { TextCategory } from "./ExpensesAddFormClassic";
import CategoryExpense from "./CategoryExpense/CategoryExpense";
import { DEVICE } from "../../../constants/responsive";

const ExpensesAddFormKmCh = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const navigate = useNavigate();

    const {
        state: { default_mileage_allowance_rate, category },
    } = useLocation();

    const {
        id_category: param_id_category,
        id_expense: param_id_expense,
        type_form: param_type_form,
    } = useParams();

    const path_expense = ROUTES.expenses.home;
    const path_detail = ROUTES.expenses.detail;

    const _goback = () => {
        return navigate(-1);
    };

    const { mutate, isLoading: isMutating } = useMutation((data) => {
        const { arrival, comment, date, departure, distance, title } = data;
        return _api_add_expense_km_ch({
            param_id_expense: param_id_expense,
            name: title,
            expense_category_id: param_id_category,
            expense_date: date,
            comment: comment,
            rate: 1,
            custom_fields: [
                {
                    code: "departure-place",
                    value: departure,
                },
                {
                    code: "arrival-place",
                    value: arrival,
                },
                {
                    code: "distance",
                    value: parseFloat(distance),
                },
            ],
        });
    });

    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: {},
    });

    /**
     * submit add expense
     */
    const onSubmit = (data) => {
        console.log({ data });
        mutate(data, {
            onSuccess: (data) => {
                if (data?.success) {
                    const path_detail_params = path_detail.custom_route(
                        path_detail.route,
                        param_id_expense
                    );
                    return navigate(
                        path_expense +
                            "/" +
                            path_detail_params +
                            "?toast=expense_added"
                    );
                } else {
                    alert("error onsubmit");
                }
            },
        });
        return;
    };

    console.log({ category: category.icon });

    return (
        <ContainerFluid>
            <Container gap="gap.large" style={{ marginBlock: "24px" }}>
                <Wrapper fill column gap="gap.medium">
                    <Flex vAlign="center" space="between">
                        <HeaderFlex gap="gap.large">
                            <TitlePage>{t("forms.km_fr.title")}</TitlePage>
                            {/* show/update category */}
                            <CategoryExpense
                                state={{
                                    category: {
                                        icon: category?.icon,
                                        name: category?.name,
                                    },
                                }}
                            />
                        </HeaderFlex>
                        <Text
                            styles={{
                                color: "#5B5FC7",
                                fontWeight: 600,
                                cursor: "pointer",
                            }}
                            text
                            primary
                            content={t("common.cancel")}
                            onClick={_goback}
                        />
                    </Flex>
                    <WhiteBoard fill column gap="gap.large">
                        <Flex
                            column
                            gap="gap.large"
                            as="form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Flex>
                                <CardTitle
                                    content={t("forms.km_fr.card_title")}
                                />
                            </Flex>
                            <Flex column gap="gap.medium">
                                <Flex gap="gap.large">
                                    <Flex column>
                                        <Input
                                            label={
                                                <>
                                                    {t(
                                                        "forms.km_ch.fields.date.label"
                                                    )}
                                                    <LabelRequired
                                                        content={"*"}
                                                    />
                                                </>
                                            }
                                            type="date"
                                            {...register(
                                                "date",
                                                VALIDATORS_ADD_EXPENSE_KM_CH.date
                                            )}
                                            error={!!errors?.date?.message}
                                            fluid
                                            styles={{
                                                width: "212px",
                                            }}
                                        />
                                        <ErrorMessage
                                            content={t(errors?.date?.message)}
                                        />
                                    </Flex>
                                </Flex>
                                <Flex fill gap="gap.medium">
                                    <Flex column fill>
                                        <Input
                                            label={t(
                                                "forms.km_ch.fields.departure.label"
                                            )}
                                            {...register("departure")}
                                            fluid
                                            clearable
                                        />
                                        <ErrorMessage
                                            content={t(
                                                errors?.departure?.message
                                            )}
                                        />
                                    </Flex>
                                    <Flex column fill>
                                        <Input
                                            label={t(
                                                "forms.km_ch.fields.arrival.label"
                                            )}
                                            {...register("arrival")}
                                            clearable
                                            fluid
                                        />
                                        <ErrorMessage
                                            content={t(
                                                errors?.arrival?.message
                                            )}
                                        />
                                    </Flex>
                                </Flex>
                                <Flex column>
                                    <Flex vAlign="end">
                                        <Input
                                            label={
                                                <>
                                                    {t(
                                                        "forms.km_fr.distance.label"
                                                    )}
                                                    <LabelRequired
                                                        content={"*"}
                                                    />
                                                </>
                                            }
                                            type="number"
                                            {...register(
                                                "distance",
                                                VALIDATORS_ADD_EXPENSE_KM_CH.distance
                                            )}
                                            error={!!errors?.distance?.message}
                                            clearable
                                            errorIndicator={false}
                                            fluid
                                            styles={{
                                                borderRadius:
                                                    "4px 0px 0px 4px!important",
                                                width: "212px",
                                            }}
                                        />
                                        <Button
                                            disabled
                                            content={
                                                <Text
                                                    styles={{
                                                        color: "#242424",
                                                    }}
                                                    content={"Km"}
                                                />
                                            }
                                            flat
                                            fluid
                                            styles={{
                                                borderRadius: "0px 4px 4px 0px",
                                                width: "fit-content!important",
                                                backgroundColor: "#C7C7C7",
                                                padding: 0,
                                                minWidth: "36px",
                                                // width: "40px";
                                            }}
                                        />
                                    </Flex>
                                    <Flex>
                                        <ErrorMessage
                                            content={t(
                                                errors?.distance?.message
                                            )}
                                        />
                                    </Flex>
                                </Flex>
                                <Flex column>
                                    <InputLabel
                                        content={
                                            <Text>
                                                {t(
                                                    "forms.km_ch.fields.bareme.label"
                                                )}
                                            </Text>
                                        }
                                    />
                                    <BaremeKm>
                                        {default_mileage_allowance_rate}
                                    </BaremeKm>
                                </Flex>
                                <Flex column>
                                    <InputLabel
                                        content={t(
                                            "forms.km_ch.fields.comment.label"
                                        )}
                                    />
                                    <TextArea
                                        {...register(
                                            "comment",
                                            VALIDATORS_ADD_EXPENSE_KM_CH.comment
                                        )}
                                        styles={{
                                            height: "107px",
                                        }}
                                        clearable
                                        fluid
                                        error={!!errors?.comment?.message}
                                    />
                                    <ErrorMessage
                                        content={t(errors?.comment?.message)}
                                    />
                                </Flex>
                            </Flex>
                            <Flex
                                fill
                                vAlign="center"
                                hAlign="center"
                                gap="gap.medium"
                            >
                                <Button
                                    content={t("common.cancel")}
                                    flat
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        return _goback();
                                    }}
                                />
                                <Button
                                    loading={isMutating}
                                    content={t("forms.km_ch.fields.button_add")}
                                    onClick={() =>
                                        console.log({ g: getValues() })
                                    }
                                    primary
                                    flat
                                />
                            </Flex>
                        </Flex>
                    </WhiteBoard>
                </Wrapper>
            </Container>
        </ContainerFluid>
    );
};

export default ExpensesAddFormKmCh;

const HeaderFlex = styled(Flex)`
    align-items: center;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        /* align-items: flex-start; */
        /* justify-content: space-between; */
        /* gap: 8px; */
        /* margin-bottom: 16px; */
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
`;

const BaremeKm = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

const WhiteBoard = styled(Flex)`
    background-color: white;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    padding: 16px 16px 32px 36px;
`;

const Wrapper = styled(Flex)`
    max-width: 850px;
    /* padding-bottom: 24px; */
    @media ${DEVICE.mobileS} {
        padding: 0 16px;
    }
    @media ${DEVICE.mobileL} {
        padding: 0 16px;
    }
    @media ${DEVICE.tablet} {
        padding: 0 16px;
    }
    @media ${DEVICE.laptop} {
        padding: 0 16px;
    }
    @media ${DEVICE.laptopL} {
        padding: 0px;
    }
`;

const Title = styled(Text)`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #242424;
`;

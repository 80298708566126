import { Button, Flex, Image, Text } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as animationData from "../../../../assets/lotties/birthday.json";
import Lottie from "react-lottie";
import celebration from "./celebration.svg";
import Sharepoint from "./Sharepoit.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function ContractData({ dataSuivi }) {
    return (
        <Flex gap="gap.large" hAlign="center" column>
            <CardContainer column>
                <Title content={"Début du contrat en cours"} />
                <Flex gap="gap.large" column hAlign="center">
                    <SmallCard dataSuivi={dataSuivi} />
                </Flex>
            </CardContainer>
            <Button
                styles={{ marginBottom: "40px" }}
                size="medium"
                icon={<Image src={Sharepoint} />}
                content="Accéder aux documents"
                onClick={() =>
                    window.open(`${dataSuivi?.sharepoint_url}`, "_blank")
                }
            />
        </Flex>
    );
}

const SmallCard = ({ dataSuivi }) => {
    const [startAnimationConfetti, setStartAnimationConfetti] = useState(false);
    useEffect(() => {
        if (startAnimationConfetti) {
            const id = setTimeout(() => {
                setStartAnimationConfetti(false);
            }, 5000);
            return () => {
                clearTimeout(id);
                return;
            };
        }
    }, [startAnimationConfetti]);

    const start_date = dataSuivi?.start_date;
    const day = moment(start_date).format("DD");
    const dateFormated = moment(start_date).format("MMM.YYYY");
    const dateFormatedCapitalized =
        dateFormated.charAt(0).toUpperCase() + dateFormated.slice(1);

    return (
        <Flex column gap="gap.medium">
            <Flex gap="gap.large">
                <Card background={"#e8ebfa"} column>
                    <Label fontSize={"18px"} content={day} />
                    <Description content={dateFormatedCapitalized} />
                </Card>
                <Card column>
                    <Label content="Ancienneté" />
                    <Description
                        content={dataSuivi?.seniority?.seniority_text}
                    />
                </Card>
                <Flex>
                    <CelebrationCercle
                        vAlign="center"
                        hAlign="center"
                        onClick={() => setStartAnimationConfetti(true)}
                    >
                        <Image
                            // styles={{ width: "30px", height: "30px" }}
                            src={celebration}
                        />
                    </CelebrationCercle>

                    {startAnimationConfetti && (
                        <Lottie
                            options={{
                                loop: false,
                                autoplay: true,
                                animationData: animationData,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                },
                            }}
                            height={"100vh"}
                            width={400}
                            style={{
                                position: "absolute",
                                inset: "0",
                            }}
                        />
                    )}
                </Flex>
            </Flex>
            <Flex gap="gap.large">
                <Card column>
                    <Label content="Fin de période d'essai" />
                    <Description
                        content={
                            dataSuivi?.probationary_period_end_date
                                ? dataSuivi?.probationary_period_end_date
                                : "Non renseigné"
                        }
                    />
                </Card>
                <Card column>
                    <Label content="Fin du contrat" />
                    <Description
                        content={
                            dataSuivi?.end_date
                                ? dataSuivi?.end_date
                                : "Non renseigné"
                        }
                    />
                </Card>
            </Flex>
        </Flex>
    );
};

const CelebrationCercle = styled(Flex)`
    cursor: pointer;
    border: 2px solid #c5cbfa;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
`;

const Title = styled(Text)`
    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;
const Description = styled(Text)`
    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const Label = styled(Text)`
    color: #616161;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
    width: 115px;
    font-style: normal;
    font-weight: ${({ fontSize }) => (fontSize ? 700 : 400)};
    line-height: 16px;
`;

const CardContainer = styled(Flex)`
    gap: 16px;
    padding: 24px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13);
    /* width: 388px; */
`;

const Card = styled(Flex)`
    width: 120px;
    padding: 8px 12px;
    gap: 8px;
    height: 54px;
    border-radius: 2px;
    background: ${({ background }) => (background ? background : "#fff")};
`;

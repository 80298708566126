import React from "react";
import { useIsClickGetStarted, useOnboarding } from "../stores/useOnboarding";
import ProcessCGU from "./ProcessCGU";
import ProcessCompletion from "./ProcessCompletion";
import ProcessWelcome from "./ProcessWelcome";

// put here all the process.
const Process = () => {
    const store_is_clicked_get_started = useIsClickGetStarted();
    const {
        welcome_screen_from_back: { data },
    } = useOnboarding();

    if (!data?.cgu_read && data?.emergency_contacts_set) {
        return <ProcessCGU />;
    }

    return (
        <>
            {!store_is_clicked_get_started.clicked ? (
                <ProcessWelcome />
            ) : (
                <ProcessCompletion />
            )}
        </>
    );
};

export default Process;

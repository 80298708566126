import styled from "styled-components";
import { Container } from "../../common/styles";
import { Flex, Text } from "@fluentui/react-northstar";

export const HeaderContainer = styled(Flex)`
    padding-inline: 40px;
`;

export const BodyContainer = styled(Container)`
    border-radius: 8px;
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);

    /* shadow-4 */
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);

    min-height: 500px;
    padding: 24px var(--spacing-m-16, 16px);

    gap: 40px;
`;

export const Title = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
`;

export const Body = styled(Flex)`
    padding-inline: 16px;
`;

export const Section = styled(Flex)`
    border-top: 4px solid var(--Light-theme-Rest-Border-Brand-Border-1, #9299f7);
    background: #fff;

    /* shadow-4 */
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);

    padding: 46px;
`;
export const WrapperCalendarWeek = styled(Flex)`
    border: 2px solid transparent;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 0.3s;
    cursor: pointer;
    :hover {
        transition: all 0.3s;
        border: 2px solid
            var(--Light-theme-Rest-Foreground-Brand-Foreground, #5b5fc7);
    }
`;
export const TextSelectedWeek = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;

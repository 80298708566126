import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
    Alert,
    Button,
    CheckmarkCircleIcon,
    CloseIcon,
    Dialog,
    FilterIcon,
    Flex,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";

import CardModelTask from "./CardModelTask";
import { api_get_template_list } from "../../../api/manage_model_tasks";
import { PATH_TRANSLATION, PT_CREATE_TEMPLATE } from "../constants";
import CustomEmptyModelTask from "./CustomEmptyModelTask";
import DialogModifyTemplate from "./DialogModifyTemplate";
import FormDialogCreateTemplate from "./FormDialogCreateTemplate";
import { _api_delete_template } from "../../../api/tasks/templates";
import FormDialogModifyTemplate from "./FormDialogModifyTemplate";
import { I18N_NS_TRANSLATION } from "../../../constants";
import { DialogCancelConfirmation } from "../../../components/Dialogs/DialogCancelConfirmation";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";
import Toast from "../../../components/Alerts";
import useToast from "../../../hooks/useToast";

const DialogManageTasksModel = () => {
    const { t } = useTranslation(I18N_NS_TRANSLATION);
    const [openCancelConfirmationDialog, setOpenCancelConfirmation] =
        useState(false);

    const [configToast, setToastConfig] = useToast();
    const [showAlert, setShowAlert] = useState({
        visible: false,
        message: "",
        level: "warning",
        icon: "",
    });
    const [id_template_to_modify, setIdTemplateToModify] = useState(null);
    const [openDialogModifyTemplate, setOpenDialogModifyTemplate] =
        useState(null);

    const {
        data: template_list,
        isLoading: is_loading_template_list,
        refetch: refetch_get_list_templates,
    } = useQuery(["api_get_template_list"], () => api_get_template_list(), {
        refetchOnWindowFocus: true,
    });

    // delete template model task
    const {
        mutate: _mutate_delete_template_task,
        isLoading: isLoadingDeleteTemplate,
    } = useMutation(
        (id) => {
            // API function
            return _api_delete_template(id);
        },
        {
            onSuccess: () => {
                setShowAlert({
                    message: "Le modèle de tâches a bien été supprimé !",
                    visible: true,
                    level: "success",
                    icon: <CheckmarkCircleIcon />,
                });
                return refetch_get_list_templates();
            },
        }
    );

    const onOpenDialogModify = (id) => {
        setIdTemplateToModify(id);
        setOpenDialogModifyTemplate(true);
    };

    const hideDialogModify = () => {
        setOpenDialogModifyTemplate(false);
    };

    // if (isLoading) return <p>chargement ...</p>;

    return (
        <Flex column gap="gap.medium">
            {showAlert?.visible && (
                <Alert
                    visible={true}
                    content={showAlert?.message}
                    success={showAlert?.level === "success" ? true : false}
                    warning={showAlert?.level === "warning" ? true : false}
                    danger={showAlert?.level === "danger" ? true : false}
                    icon={showAlert?.icon}
                    onVisibleChange={() =>
                        setShowAlert({
                            visible: false,
                            message: "",
                        })
                    }
                    dismissible
                />
            )}
            <Toast
                {...configToast}
                onStatusChange={() =>
                    setToastConfig((state) => ({
                        ...state,
                        visible: false,
                        content: "",
                        level: "success",
                    }))
                }
            />
            {/* DIALOG for updating template tasks */}
            <FormDialogModifyTemplate
                hideDialogModify={hideDialogModify}
                id_template_to_modify={id_template_to_modify}
                openDialogModifyTemplate={openDialogModifyTemplate}
                refetch_get_list_templates={refetch_get_list_templates}
                setShowAlert={setShowAlert}
            />

            <Flex column gap="gap.medium">
                <Text>
                    {t(
                        "pages.manage_modal_tasks.dialogs.manage_models.description"
                    )}
                </Text>
                <FlexWrapperButtons space="between">
                    {/* DIALOG add template tasks */}
                    <FormDialogCreateTemplate
                        setShowAlert={setShowAlert}
                        refetch_get_list_templates={refetch_get_list_templates}
                    />
                    {/* filter by categories */}
                    <ButtonFilter
                        size="medium"
                        icon={<FilterIcon />}
                        content={t(PATH_TRANSLATION + ".filter_by_category")}
                        iconPosition="before"
                        disabled={template_list?.length > 0 ? false : true}
                        flat
                        text
                    />
                </FlexWrapperButtons>
            </Flex>
            {isLoadingDeleteTemplate && <Text>Suppression en cours ...</Text>}
            {/* show the list of templates here ! */}
            {is_loading_template_list ? (
                <Loader label="Chargement des templates ..." />
            ) : template_list?.length > 0 ? (
                <WrapperCardsTemplates wrap gap="gap.medium">
                    {template_list?.map((el) => {
                        return (
                            <CardModelTask
                                key={el?.id}
                                template={el}
                                onOpenDialogModify={onOpenDialogModify}
                                _mutate_delete_template_task={
                                    _mutate_delete_template_task
                                }
                                isLoadingDeleteTemplate={
                                    isLoadingDeleteTemplate
                                }
                            />
                        );
                    })}
                </WrapperCardsTemplates>
            ) : (
                <CustomEmptyModelTask
                    title={t(PATH_TRANSLATION + ".empty_illustration.title")}
                    description={t(
                        PATH_TRANSLATION + ".empty_illustration.description"
                    )}
                />
            )}
        </Flex>
    );
};

export default DialogManageTasksModel;

const ButtonFilter = styled(Button)`
    @media ${DEVICE.mobileS} {
        min-width: 100%;
    }
    @media ${DEVICE.tablet} {
        min-width: fit-content;
    }
    @media ${DEVICE.laptop} {
        min-width: fit-content;
    }
`;

const FlexWrapperButtons = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 12px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;
const WrapperCardsTemplates = styled(Flex)`
    row-gap: 24px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.mobileL} {
        flex-direction: row;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

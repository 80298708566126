import { Flex } from "@fluentui/react-northstar";
import styled, { css } from "styled-components";
import { DEVICE } from "../../constants/responsive";

export const ContainerIcon = styled(Flex)`
    background-color: var(--color-primary);
    border-radius: 50%;
    transition: all 0.3s;
    box-shadow: 0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11),
        0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13);
    ${({ $idle }) =>
        $idle
            ? css`
                  border: 2px solid #e8ebfa;
              `
            : css``}
    ${({ $paused }) =>
        $paused
            ? css`
                  background-color: #e8ebfa;
              `
            : css``}

    @media ${DEVICE.mobileS} {
        width: 50px;
        height: 50px;
    }

    @media ${DEVICE.laptop} {
        width: 70.228px;
        height: 70.228px;
    }
`;

export const Container = styled(Flex)`
    position: fixed;
    cursor: pointer;
    transition: all 0.3s;
    z-index: 6;

    @media ${DEVICE.mobileS} {
        bottom: 23px;
        right: 24px;
    }

    @media ${DEVICE.laptop} {
        bottom: 23px;
        right: 68px;
    }
`;

export const Overlay = styled(Flex)`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    inset: 0;
    z-index: 5;
`;

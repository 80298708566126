import React from "react";
import {
    Checkbox,
    Dropdown,
    Flex,
    Input,
    InputLabel,
    Loader,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import {
    I18N_NS_EXPENSE,
    QUERY_KEY,
    VALIDATORS_ADD_NOTE_REPORT,
} from "../../../constants";
import { ErrorMessage } from "../../../../../common/styles";
import { useQuery } from "react-query";
import { _api_get_clients } from "../../../api";
import moment from "moment";
import { _str_capitalize } from "../../../../../utils/functions";

const DialogCreateExpenseContent = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const default_title_note = _str_capitalize(moment().format("MMMM YYYY"));

    const {
        register,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();

    const {
        data: clients,
        isLoading,
        isFetching,
    } = useQuery(QUERY_KEY + "::client", () => _api_get_clients(), {
        refetchOnWindowFocus: false,
    });

    if (isLoading || isFetching) {
        return <Loader content={"chargement des clients"} />;
    }

    // format for UI fluent.
    const clients_list = clients?.map((el) => {
        return {
            id: el?.id,
            header: el?.name,
        };
    });

    const _watch = watch("rebillable");

    return (
        <Flex fill column gap="gap.medium">
            <Flex column>
                <Controller
                    control={control}
                    name="title"
                    defaultValue={default_title_note}
                    render={({ field: { onChange, name, ref } }) => {
                        return (
                            <Input
                                label={t(
                                    "dialogs.dialog_create_report.form.title.label"
                                )}
                                onChange={(e, i) => {
                                    return onChange(i?.value);
                                }}
                                name={name}
                                ref={ref}
                                defaultValue={default_title_note}
                                error={errors?.title?.message}
                                clearable
                                fluid
                            />
                        );
                    }}
                />
                <ErrorMessage content={t(errors?.title?.message)} />
            </Flex>
            {clients_list?.length > 0 && (
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                    }) => (
                        <Checkbox
                            label={t(
                                "dialogs.dialog_create_report.form.rebill.label"
                            )}
                            toggle
                            onChange={(e, i) => {
                                return setValue("rebillable", i?.checked);
                            }}
                            name={name}
                            ref={ref}
                        />
                    )}
                    name="rebillable"
                />
            )}
            {_watch && (
                <Flex column>
                    <InputLabel
                        content={t(
                            "dialogs.dialog_create_report.form.clients.label"
                        )}
                    />
                    <Controller
                        control={control}
                        rules={VALIDATORS_ADD_NOTE_REPORT.client}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => (
                            <Dropdown
                                autoSize={true}
                                position="above"
                                items={clients_list}
                                placeholder={t(
                                    "dialogs.dialog_create_report.form.clients.placeholder"
                                )}
                                onChange={(e, i) => {
                                    return setValue("client", i?.value);
                                }}
                                checkable
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                ref={ref}
                                name={name}
                                error={errors?.client?.message}
                            />
                        )}
                        name="client"
                    />
                    <ErrorMessage content={t(errors?.client?.message)} />
                </Flex>
            )}
        </Flex>
    );
};

export default DialogCreateExpenseContent;

export const NAVLIST_SUIVI = [
    {
        label: "Onboarding",
        href: "/onboarding/",
    },
    {
        label: "Détails du collaborateur",
        href: "/onboarding/suivi/:id",
    },
];

import React from "react";
import { Divider } from "@fluentui/react-northstar";
import { Container, Content } from "./style";

const BadgeIcon = ({ icon, content }) => {
    return (
        <Container vAlign="center">
            {icon}
            <Divider vertical={true} styles={{ height: "16px" }} />
            <Content content={content} />
        </Container>
    );
};

export default BadgeIcon;

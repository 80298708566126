import { Flex, Text, Tooltip } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

const WeekSecondRow = ({ timesheet }) => {
    if (timesheet?.id === 0) {
        return null;
    }
    const { id, code, name, total, values } = timesheet;
    return (
        <Flex vAlign="center" gap="gap.large">
            {id === -1 ? (
                <CodeProjet content={"Absences"} />
            ) : (
                <CodeProjet content={code + " " + name} />
            )}
            {values?.map((value) => {
                return (
                    <Tooltip
                        align="start"
                        position="after"
                        autoSize="always"
                        pointing
                        subtle={false}
                        content={
                            <Flex
                                styles={{
                                    width: "104px",
                                    maxHeight: "104px",
                                }}
                                column
                                gap="gap.small"
                            >
                                <Text content="Saisie modifiée" />
                                <Flex column>
                                    {value?.details?.map((el) => {
                                        return <Text content={el} />;
                                    })}
                                </Flex>
                            </Flex>
                        }
                        trigger={<TextStyle content={`${value?.total} h`} />}
                    />
                );
            })}
            <TotalTextStyle content={`${total} h`} />
        </Flex>
    );
};

export default WeekSecondRow;

const CodeProjet = styled(Text)`
    width: 200px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #424242;
`;

const TextStyle = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #424242;

    width: 120px;
`;
const TotalTextStyle = styled(Text)`
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #424242;
`;

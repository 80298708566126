import React, { useRef } from "react";
import styled from "styled-components";
import { Button, Flex, Input, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import {
    useGetImageUploaded,
    useSetImageUploaded,
    useSetSkipUploadImage,
    useUploadImage,
} from "../../stores/useOnboarding";
import { useMutation } from "react-query";
import { _api_onboarding_upload_avatar } from "../../api";
import ShowAvatarWithName from "../avatars";
import { Card, Title, Wrapper } from "../../styles";
import Toast from "../../../../components/Alerts";
import useToast from "../../../../hooks/useToast";

const CardProfileImage = () => {
    const { t } = useTranslation("onboarding");
    const refUploadImage = useRef(null);

    const store_set_skip_upload_image = useSetSkipUploadImage();
    const store_is_skiped_upload_image = useUploadImage();
    const store_set_image = useSetImageUploaded();
    const store_get_image = useGetImageUploaded();

    const [toast, setToast] = useToast();
    /**
     * POST THE AVATAR
     */
    const { mutate: mutate_put_avatar, isLoading: isLoadingAvatar } =
        useMutation(({ uploaded_files }) => {
            return _api_onboarding_upload_avatar({ uploaded_files });
        });

    const _update_my_avatar = async (e) => {
        const files = e.target.files;
        if (!files.length) return;
        const file = files[0];
        const type = file.type.startsWith("image/");

        if (!file.type.startsWith("image/")) {
            return setToast((state) => ({
                ...state,
                visible: true,
                level: "danger",
                content:
                    "Format non autorisé. Seuls sont autorisés les formats d'image tels que .png, .jpg, .jpeg, .webp, .gif, .bmp",
            }));
        }
        if (file?.size > 2_500_000) {
            return setToast((state) => ({
                ...state,
                visible: true,
                level: "danger",
                content:
                    "La taille de l'image téléchargée doit être inférieure à 2.5 Mo",
            }));
        }
        mutate_put_avatar(
            {
                uploaded_files: file,
            },
            {
                onSuccess: (data) => {
                    if (data.success) {
                        store_set_image(data.avatar);
                    }
                },
            }
        );
    };

    return (
        <Wrapper column>
            <Toast
                {...toast}
                onStatusChange={() =>
                    setToast((state) => ({ ...state, visible: false }))
                }
            />
            <Card column gap="gap.medium">
                <Flex column gap="gap.small">
                    <Flex column>
                        <Title
                            content={t(
                                "section_upload_image.title_profile_image"
                            )}
                        />
                        <SubTitle
                            content={t("section_upload_image.maximal_size")}
                        />
                    </Flex>
                    <Flex column>
                        <Descriptif
                            content={t(
                                "section_upload_image.description_profile_image"
                            )}
                        />
                    </Flex>
                </Flex>
                <Flex
                    hAlign={
                        isLoadingAvatar || store_get_image ? "start" : "end"
                    }
                    gap="gap.small"
                >
                    {isLoadingAvatar ? (
                        <ShowAvatarWithName isLoadingAvatar={isLoadingAvatar} />
                    ) : (
                        <>
                            {store_get_image ? (
                                <ShowAvatarWithName
                                    isLoadingAvatar={isLoadingAvatar}
                                />
                            ) : (
                                <>
                                    {store_is_skiped_upload_image.skiped && (
                                        <Button
                                            disabledFocusable
                                            content={t(
                                                "section_upload_image.buttons.skipped_step"
                                            )}
                                        />
                                    )}
                                    {!store_is_skiped_upload_image.skiped && (
                                        <>
                                            <Button
                                                onClick={() =>
                                                    store_set_skip_upload_image()
                                                }
                                                content={t(
                                                    "section_upload_image.buttons.skip_step"
                                                )}
                                                secondary
                                            />
                                            <Button
                                                onClick={() =>
                                                    refUploadImage.current.click()
                                                }
                                                content={t(
                                                    "section_upload_image.buttons.choose_image"
                                                )}
                                                primary
                                            />
                                            <Input
                                                type="file"
                                                ref={refUploadImage}
                                                accept={"image/*"}
                                                onChange={_update_my_avatar}
                                                hidden
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Flex>
            </Card>
        </Wrapper>
    );
};

export default CardProfileImage;

const Descriptif = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #252423;
`;

const SubTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #484644;
`;

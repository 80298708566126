import React from "react";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import { BASE_URL_ILLUSTRATIONS } from "../../../constants";

import {
    EmptyTaskDescription,
    EmptyTasksTitle,
    TitleMyTask,
    WrapperMytask,
} from "../style";
import ViewTable from "../ViewTable";
import ViewTableHR from "../MyCompanyList/components/ViewTableHR";

const MyTasks = ({
    TabView,
    onChange,
    tasks = [],
    refetchGetAllTasks,
    isPageOnboarding = false,
}) => {
    return (
        <>
            {tasks && tasks?.length > 0 ? (
                <>
                    {/* tabs */}
                    {/* array list */}
                    <WrapperMytask column fill>
                        {/* <TitleMyTask content="Liste de mes tâches" /> */}
                        <ViewTable
                            tasksProps={{ tasks: tasks }}
                            isPageOnboarding={isPageOnboarding}
                        />
                    </WrapperMytask>
                </>
            ) : isPageOnboarding ? (
                <Flex
                    column
                    fill
                    vAlign="center"
                    hAlign="center"
                    gap="gap.large"
                >
                    <Flex>
                        <Image
                            src={`${BASE_URL_ILLUSTRATIONS}/Tasks-No_task.svg`}
                            alt="empty tasks"
                        />
                    </Flex>
                    <Flex
                        column
                        vAlign="center"
                        hAlign="center"
                        gap="gap.small"
                    >
                        <EmptyTasksTitle>
                            Aucune tâche à réaliser par le collaborateur
                        </EmptyTasksTitle>
                    </Flex>
                </Flex>
            ) : (
                <Flex
                    column
                    fill
                    vAlign="center"
                    hAlign="center"
                    gap="gap.large"
                >
                    <Flex>
                        <Image
                            src={`${BASE_URL_ILLUSTRATIONS}/No-task-todo.svg`}
                            alt="empty tasks"
                        />
                    </Flex>
                    <Flex
                        column
                        vAlign="center"
                        hAlign="center"
                        gap="gap.small"
                    >
                        <EmptyTasksTitle>
                            Aucune tâche à effectuer
                        </EmptyTasksTitle>
                        <EmptyTaskDescription>
                            Vous n’avez pas de tâche à faire,
                            <br />
                            c’est parfait !
                        </EmptyTaskDescription>
                    </Flex>
                </Flex>
            )}
        </>
    );
};

export default MyTasks;

import { Box, Flex, Skeleton, Text } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import {
    BackToText,
    HeaderText,
    Body,
    Header,
    TextStyle,
    HeaderBody,
} from "./index.style";
import SearchBarEmployeeList from "../../components/SearchBarEmployee";
import WeekTableHeader from "../../components/WeekTableHeader";
import WeekRow from "../../components/WeekRow/WeekRow";
import styled from "styled-components";
import { useQuery } from "react-query";
import {
    _api_get_weekly_tracked_data,
    _api_get_weekly_tracked_data_rh,
} from "../../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import SliderWeeks from "../../components/Sliders/SliderWeeks";
import moment from "moment";
import LoadingData from "./LoadingData";
import WeekRowRH from "../../components/WeekRowRH/WeekRowRH";
import { _get_all_employees_and_departments } from "../../../../api/user";

const WeekRH = () => {
    const [params, setParams] = useSearchParams();
    const default_iso_week = params.get("week");
    const default_year = params.get("year") ?? moment().get("year");
    const start_date_week = moment()
        .set("year", Number(default_year))
        .isoWeek(default_iso_week)
        .isoWeekday(1)
        .format("YYYY-MM-DD");

    const [state, setState] = useState({
        iso_week: default_iso_week,
        year: default_year,
        start_date_week,
    });

    const [filtredRowsByUser, setFiltredRowsByUser] = useState([]);
    const [collapsedDepartments, setCollapsedDepartments] = useState([]);

    const navigate = useNavigate();

    const {
        isFetching,
        isLoading,
        data: weeklydata,
    } = useQuery(
        ["get_weekly_tracked_data", state?.start_date_week ?? start_date_week],
        () =>
            _api_get_weekly_tracked_data_rh({
                date: state.start_date_week,
            }),
        {
            refetchOnWindowFocus: false,
            onSuccess: (response) => {
                const id = params.get("id");
                const type = params.get("type");
                setFiltredRowsByUser(response);
                return setSelectedUser(id, type);
            },
        }
    );

    // fetch les utilisateurs et départements du dropdown
    const {
        data: employees_and_departments,
        isLoading: is_loading_employees_departments,
    } = useQuery(
        ["time_reporting::RH::get_list_employee_and_departments"],
        () => {
            return _get_all_employees_and_departments();
        }
    );

    const isLoadingData = isLoading || isFetching;
    const HeaderWeek = weeklydata?.days;
    const monthIndex = params.get("month");
    const yearNumber = params.get("year");

    const backLinkClick = () => {
        return navigate(-1);
        // return navigate(
        //     `/time_reporting/team_tracking?month=${monthIndex}&year=${yearNumber}`
        // );
    };

    // on fait appel lorsque je récupere les données depuis les PARAMS URL
    const setSelectedUser = (id_selected, type = "user") => {
        // reset tout
        if (!id_selected) {
            return setFiltredRowsByUser(weeklydata);
        }
        // sinon on cherche, selon si c'est département ou employé
        if (type === "user") {
            return setFiltredRowsByUser((state) => {
                console.log({ state });
                const filtredData = findUserById(
                    state.departments,
                    id_selected
                );
                return { ...state, departments: filtredData };
            });
        } else {
            // c'est département
            return setFiltredRowsByUser((state) => {
                const filtredData = findDepartmentById(
                    state.departments,
                    id_selected
                );
                return { ...state, departments: filtredData };
            });
        }
    };

    const findDepartmentById = (data, departmentId) => {
        let result = [];

        for (let department of data) {
            if ("d-" + department.id === departmentId) {
                result.push(department);
                break;
            }
        }

        return result;
    };
    const findUserById = (data, userId) => {
        let result = [];

        for (let department of data) {
            let filteredUsers = department.users.filter(
                (user) => "u-" + user.id === userId
            );

            if (filteredUsers.length > 0) {
                result.push({
                    ...department,
                    users: filteredUsers,
                });
            }
        }

        return result;
    };

    // lorqu'on change le User or departement dans le dropdown
    const onChangeSelectedUser = (id_selected, type = null) => {
        // reset tout
        if (!id_selected) {
            return setFiltredRowsByUser(weeklydata);
        }
        // sinon on cherche, selon si c'est département ou employé
        if (type === "user") {
            setFiltredRowsByUser((state) => {
                const filtredData = findUserById(
                    state.departments,
                    id_selected
                );
                return { ...state, departments: filtredData };
            });
        } else {
            // c'est département
            setFiltredRowsByUser((state) => {
                const filtredData = findDepartmentById(
                    state.departments,
                    id_selected
                );
                return { ...state, departments: filtredData };
            });
        }
    };

    return (
        <Flex fill column>
            {/* header */}
            <Header fill vAlign="center" space="between">
                <Flex>
                    <HeaderText content="Suivi hebdomadaire" />
                </Flex>
                <Flex>
                    <BackToText
                        onClick={backLinkClick}
                        text
                        content="Retour vue mensuelle"
                    />
                </Flex>
            </Header>
            <Body fill column>
                {/* slider week */}
                <Flex>
                    <SliderWeeks
                        default_iso_week={default_iso_week}
                        default_year={default_year}
                        onChangeWeek={(state) => {
                            setState(state);
                            return;
                        }}
                    />
                </Flex>
                <HeaderBody vAlign="center">
                    <Flex styles={{ gap: "35px" }}>
                        {/* search bar  */}
                        <Flex styles={{ minWidth: "280px" }}>
                            {/* <SearchBarEmployeeList
                                users={weeklydata?.users}
                                onChangeUser={(id_user) => {
                                    return onUserSelected(id_user);
                                }}
                            /> */}
                            <SearchBarEmployeeList
                                users={employees_and_departments}
                                isLoading={is_loading_employees_departments}
                                onChangeUser={(id_user, type) => {
                                    // addNewParam({
                                    //     id: id_user,
                                    //     type,
                                    // });
                                    onChangeSelectedUser(id_user, type);
                                    return;
                                }}
                            />
                        </Flex>
                        {/* week date  */}
                        <Flex
                            hAlign="center"
                            vAlign="center"
                            space="between"
                            styles={{
                                width: "902px",
                                // gap: "8px",
                            }}
                            fill
                        >
                            {isLoadingData ? (
                                <LoadingData />
                            ) : (
                                HeaderWeek?.map((day) => {
                                    return (
                                        <WeekTableHeader
                                            day={day}
                                            // loading={true}
                                            $noStyle={true}
                                        />
                                    );
                                })
                            )}
                        </Flex>
                    </Flex>
                    <TotalText content="Total effectif" />
                </HeaderBody>
            </Body>
            <BodyWeekFlex>
                <WeekRowRH
                    collapsedDepartments={collapsedDepartments}
                    setCollapsedDepartments={setCollapsedDepartments}
                    weeklydata={filtredRowsByUser}
                />
            </BodyWeekFlex>
        </Flex>
    );
};

export default WeekRH;

const TotalText = styled(Text)`
    color: #616161;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const BodyWeekFlex = styled(Box)`
    max-height: 390px;
    overflow-x: hidden;
    overflow-y: scroll;
`;

import React, { useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import { useQuery } from "react-query";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller } from "react-hook-form";

import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import Alert from "@mui/material/Alert";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import {
    getManagers,
    getServices,
    _get_user_informations,
} from "../../../api/user";

import { useStepperContext } from "./ContextAddCollaborateur";
import moment from "moment";
import { useEffect } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import { useNavigate } from "react-router-dom";

const YUP_SCHEMA = yup.object().shape(
    {
        nom: yup.string().min(3).required(),
        prenom: yup.string().min(3).required(),
        email: yup.string().email().required(),
        telephone: yup.string().min(8).required(),
        date_depart: yup
            .string()
            .nullable()
            .ensure()
            .when(["date_depart", "date_arrivee"], {
                is: (date_depart, date_arrivee) => date_depart && date_arrivee,
                then: yup
                    .string()
                    .test(
                        "date_depart",
                        "La date de départ ne doit pas être inferieur a celle d'arrivée !",
                        (value, props) => {
                            return (
                                moment(value).diff(
                                    moment(
                                        props?.from[0].value?.date_arrivee ||
                                            new Date()
                                    ),
                                    "day"
                                ) > 0
                            );
                        }
                    )
                    .required(),
            }),
        manager: yup.string().email().required(),
        service: yup.number().required().min(1),
        job_title: yup.string().when(["job_title"], {
            is: (job_title) => job_title !== "" && job_title !== null,
            then: yup.string().nullable().notRequired(),
        }),
    },
    [
        ["date_arrivee", "date_depart"],
        ["date_depart", "date_arrivee"],
        ["date_arrivee", "date_arrivee"],
        ["date_depart", "date_depart"],
        ["job_title", "job_title"],
        ["type_contrat", "type_contrat"],
    ]
);

const GeneralInformationsWithIdUser = ({ idUser }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { setFirstStep, setResourceStep, setTasksStep, setSettingsStep } =
        useStepperContext((state) => state);

    useEffect(() => {
        // get informations of the User.
        _get_user_informations(idUser).then((data) => {
            if (data?.success) {
                // assigné les informations pour la 1ere étape de informations générales
                setFirstStep({
                    nom: data.user.last_name,
                    prenom: data.user.first_name,
                    email: data.user.email,
                    telephone: data.user.phone_number,
                    date_arrivee:
                        data.user.arrival_date &&
                        moment(data.user.arrival_date),
                    date_depart:
                        data.user.departure_date &&
                        moment(data.user.departure_date),
                    service: data.user.department.id,
                    manager: data.user.manager.email,
                    type_contrat: data.user.contract.id,
                    job_title: data.user.job_title,
                    globalError: "",
                    successData: data.user,
                    isUserFromId: true,
                });
                // assigner a la 2eme étape "ressources"
                setResourceStep({
                    resource: data.user.resources,
                });
                // assigner a la 3eme page "Tâches"
                const formatTasksCollaborator = data.user.tasks
                    .filter((el) => el.from_template)
                    .map((el) => {
                        return {
                            ...el,
                            id: el.id,
                            name: el.name,
                            description: el.description,
                            category_id: el?.category?.id || null,
                            category_name: el?.category?.name || "Equipements",
                            category_bg_color:
                                el?.category?.bg_color || "#2ECC71",
                            checked: true,
                            assigned_to: el.assigned_to_user?.email,
                            due_date: el.due_date,
                            starting_date: el.starting_date,
                            documents_template: el.documents_template, // juste pour voir les documents associé a la tache si y'en a biensur.
                            current_doc_ids: el?.documents_template?.map(
                                (el) => el.id
                            ), // c'est documents_template formated
                            new_doc_ids: [], // envoyer ici les nouveau documents.
                            document_required: el.document_required,
                            related_to: el.related_to_user?.email,
                            validator_email: el.validator?.email,
                        };
                    });
                const formatManualTasks = data.user.tasks
                    .filter((el) => !el.from_template)
                    .map((el) => {
                        return {
                            ...el,
                            id: el.id,
                            name: el.name,
                            description: el.description,
                            due_date: el.due_date,
                            starting_date: el.starting_date,
                            category_id: el?.category?.id || null,
                            category_name: el?.category?.name || "",
                            category_bg_color: el?.category?.bg_color || "",
                            checked: true,
                            assigned_to: el.assigned_to_user?.email,
                            documents_template: el.documents_template, // juste pour voir les documents associé a la tache si y'en a biensur.
                            current_doc_ids: el?.documents_template?.map(
                                (el) => el.id
                            ), // c'est documents_template formated
                            new_doc_ids: [], // envoyer ici les nouveau documents.
                            document_required: el.document_required,
                            related_to: el.related_to_user?.email,
                            validator_email: el.validator?.email,
                        };
                    });
                setTasksStep({
                    selectedTasks: formatTasksCollaborator,
                    manualTasks: formatManualTasks,
                    templates: data.user.task_templates,
                    isModifyUser: true,
                });
                setSettingsStep({
                    groupeOffice: data.user.office_groups || [],
                    roles: data.user.roles?.map((el) => el.code) || [],
                });
                setLoading(false);
            } else {
                navigate("/404");
            }
        });
    }, [idUser, setFirstStep, setResourceStep, setTasksStep, setSettingsStep]);

    const {
        date_arrivee: ctx_date_arrivee,
        date_depart: ctx_date_depart,
        email: ctx_email,
        job_title: ctx_job_title,
        manager: ctx_manager,
        nom: ctx_nom,
        prenom: ctx_prenom,
        service: ctx_service,
        telephone: ctx_telephone,
        type_contrat: ctx_type_contrat,
        globalError,
        isUserFromId,
    } = useStepperContext((state) => state.firstStep);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        trigger,
        getValues,
        control,
        watch,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(YUP_SCHEMA),
    });

    const onSubmit = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (isValid) {
            setFirstStep({
                isValidForm: isValid,
            });
        }
    }, [isValid, setFirstStep]);

    const _handleChange = (newState) => {
        console.log({ newState });
        setFirstStep(newState);
    };

    // fetch manager
    const {
        data: dataManager,
        isLoading: isLoadingManagers,
        isFetching: isFetchingManagers,
    } = useQuery(["getManagers"], () => getManagers(), {
        staleTime: 60000,
    });

    // fetch service
    const {
        data: dataService,
        isLoading: isLoadingServices,
        isFetching: isFetchingServices,
    } = useQuery(["getServices"], () => getServices(), {
        staleTime: 60000,
    });

    useEffect(() => {
        if (
            isUserFromId &&
            !loading &&
            !isLoadingManagers &&
            !isFetchingManagers &&
            !isLoadingServices &&
            !isFetchingServices
        ) {
            trigger();
        }
    }, [
        isUserFromId,
        loading,
        trigger,
        isLoadingManagers,
        isFetchingManagers,
        isLoadingServices,
        isFetchingServices,
    ]);

    if (
        loading ||
        isLoadingManagers ||
        isFetchingManagers ||
        isLoadingServices ||
        isFetchingServices
    ) {
        return <p>Chargement ...</p>;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent="center"
                alignItems={"center"}
            >
                <Stack mb={2} style={{ width: "52ch" }}>
                    {globalError && (
                        <Alert severity="error">{globalError}</Alert>
                    )}
                </Stack>

                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    display={"flex"}
                    flexDirection={"row"}
                    autoComplete="off"
                >
                    {/* forms */}
                    <TextField
                        required
                        id="outlined-nom"
                        type="text"
                        label={"Nom"}
                        variant="outlined"
                        defaultValue={ctx_nom}
                        name="nom"
                        {...register("nom", {
                            onChange: (e) => {
                                setFirstStep({
                                    nom: e.target.value,
                                });
                            },
                        })}
                        error={errors.nom ? true : false}
                        helperText={
                            errors.nom &&
                            "Le nom doit contenir au minimum 3 caractères !"
                        }
                    />
                    <TextField
                        required
                        id="outlined-prenom"
                        type="text"
                        label="Prénom"
                        variant="outlined"
                        name="prenom"
                        {...register("prenom", {
                            onChange: (e) => {
                                setFirstStep({
                                    prenom: e.target.value,
                                });
                            },
                        })}
                        defaultValue={ctx_prenom}
                        error={errors.prenom ? true : false}
                        helperText={
                            errors.prenom &&
                            "Le prénom doit contenir au minimum 3 caractères !"
                        }
                    />
                </Box>
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    display={"flex"}
                    flexDirection={"row"}
                    autoComplete="off"
                >
                    <TextField
                        required
                        id="outlined-email"
                        label="Email personnelle"
                        variant="outlined"
                        type="email"
                        defaultValue={ctx_email}
                        name="email"
                        {...register("email", {
                            onChange: (e) => {
                                setFirstStep({ email: e.target.value });
                            },
                        })}
                        error={errors.email ? true : false}
                        helperText={
                            errors.email &&
                            "Veuillez saisir un 'email' valide !"
                        }
                    />
                    <Controller
                        control={control}
                        name="telephone"
                        defaultValue={ctx_telephone}
                        render={({ field: { ref, ...fieldProps } }) => {
                            return (
                                <MuiPhoneNumber
                                    required
                                    {...register("telephone", {
                                        onChange: (e) => {
                                            setFirstStep({
                                                telephone: e.target.value,
                                            });
                                        },
                                    })}
                                    {...fieldProps}
                                    id="outlined-basic"
                                    label="Numéro de téléphone"
                                    variant="outlined"
                                    type="tel"
                                    defaultCountry={"fr"}
                                    placeholder="Numéro de téléphone..."
                                    error={errors?.telephone ? true : false}
                                    helperText={
                                        errors?.telephone &&
                                        "Veuillez saisir un 'telephone' valide !"
                                    }
                                    autoFormat={false}
                                    defaultValue={ctx_telephone}
                                />
                            );
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    display={"flex"}
                    flexDirection={"row"}
                    autoComplete="off"
                >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Controller
                            control={control}
                            name="date_arrivee"
                            defaultValue={ctx_date_arrivee}
                            render={({ field: { ref, ...fieldProps } }) => {
                                return (
                                    <DesktopDatePicker
                                        {...fieldProps}
                                        inputRef={ref}
                                        label="Date arrivée"
                                        inputFormat="DD/MM/yyyy"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...fieldProps}
                                                {...register("date_arrivee", {
                                                    onChange: (e) => {
                                                        setFirstStep({
                                                            date_arrivee:
                                                                e.target.value,
                                                        });
                                                    },
                                                })}
                                                required
                                                error={
                                                    errors.date_arrivee
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.date_arrivee &&
                                                    errors.date_arrivee.message
                                                }
                                            />
                                        )}
                                    />
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name="date_depart"
                            defaultValue={ctx_date_depart}
                            render={({ field: { ref, ...fieldProps } }) => {
                                return (
                                    <DesktopDatePicker
                                        {...fieldProps}
                                        inputRef={ref}
                                        label="Date de départ"
                                        inputFormat="DD/MM/yyyy"
                                        defaultValue={ctx_date_depart}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...fieldProps}
                                                {...register("date_depart", {
                                                    onChange: (e) => {
                                                        setFirstStep({
                                                            date_depart:
                                                                e.target.value,
                                                        });
                                                    },
                                                })}
                                                error={
                                                    errors.date_depart
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors.date_depart &&
                                                    errors.date_depart.message
                                                }
                                            />
                                        )}
                                    />
                                );
                            }}
                        />
                    </LocalizationProvider>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent="space-between"
                >
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-select-contrat"
                            select
                            label="Type de contrat"
                            name="type_contrat"
                            defaultValue={ctx_type_contrat}
                            {...register("type_contrat", {
                                onChange: (e) => {
                                    setFirstStep({
                                        type_contrat: e.target.value,
                                    });
                                },
                            })}
                            error={errors.type_contrat ? true : false}
                            helperText={
                                errors.type_contrat &&
                                "Veuillez saisir un 'Type de contrat' valide !"
                            }
                        >
                            <MenuItem value={1}>CDI</MenuItem>
                            <MenuItem value={2}>CDD</MenuItem>
                            <MenuItem value={3}>Intérim</MenuItem>
                        </TextField>
                    </Box>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-basic"
                            label="Profession"
                            variant="outlined"
                            type="text"
                            name="job_title"
                            {...register("job_title", {
                                onChange: (e) => {
                                    setFirstStep({
                                        job_title: e.target.value,
                                    });
                                },
                            })}
                            error={errors.job_title ? true : false}
                            defaultValue={ctx_job_title}
                            helperText={
                                errors.job_title &&
                                "Veuillez saisir une 'Profession' valide !"
                            }
                        />
                    </Box>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent="space-between"
                >
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-select-service"
                            select
                            label="Service"
                            name="service"
                            {...register("service", {
                                onChange: (e) => {
                                    setFirstStep({
                                        service: e.target.value,
                                    });
                                },
                            })}
                            error={errors.service ? true : false}
                            helperText={
                                errors.service &&
                                "Veuillez saisir un 'Service' valide !"
                            }
                            defaultValue={ctx_service}
                            required
                        >
                            {dataService?.map((el) => (
                                <MenuItem key={el?.id} value={el?.id}>
                                    {el?.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            name="manager"
                            {...register("manager", {
                                onChange: (e) =>
                                    setFirstStep({
                                        type_contrat: e.target.value,
                                    }),
                            })}
                            required
                            id="outlined-select-manager"
                            select
                            label="Manager"
                            error={errors.manager ? true : false}
                            helperText={
                                errors.manager &&
                                "Veuillez saisir un 'Manager' valide !"
                            }
                            defaultValue={ctx_manager}
                        >
                            {dataManager?.map((el, i) => (
                                <MenuItem value={el.email} key={i}>
                                    {`${el?.first_name} ${el?.last_name} ( ${el.email} )`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Box>
            </Box>
        </form>
    );
};

export default GeneralInformationsWithIdUser;

import { useMutation } from "react-query";
import { _api_delete_employee } from "../api";

const useMutationDeleteEmployee = () => {
    const { data, isLoading, mutate } = useMutation(({ id_user }) => {
        return _api_delete_employee({ id_user });
    });
    return { data, isLoading, mutate };
};

export default useMutationDeleteEmployee;

import React, { useEffect, useReducer, useRef, useState } from "react";
import {
    Button,
    Checkbox,
    Flex,
    Header,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { Container, ContainerFluid } from "../../../../../../common/styles";
import { _api_post_planning, api_planning_manager } from "../../../../api";
import WeekWorkingDefinition from "../../../../../addEmployees/components/WeekWorkingDefinition";
import {
    ACTION_TYPES_WEEK_DIFINITION,
    INIT_REDUCER_WEEK_DIFINITION,
    reducer_week_definition,
} from "../../../../../addEmployees/reducers/reducer.week_definition";
import Tile from "../../../Tile";

import IconSlotHours from "../../../../../../assets/icons/employee_folder/planning/crenaux_hours.svg";
import IconWeather from "../../../../../../assets/icons/employee_folder/planning/weather.svg";
import IconManualReporting from "../../../../../../assets/icons/employee_folder/planning/manual_reporting.svg";
import IconTimer from "../../../../../../assets/icons/employee_folder/planning/timer.svg";
import useToast from "../../../../../../hooks/useToast";
import Toast from "../../../../../../components/Alerts";
import { getMyInformations } from "../../../../../../api/user";
import { useAuthDetailStore } from "../../../../../../stores/useAuthStore";
import { DialogConfirmationUpdateHoursWorked } from "../../../DialogConfirmationUpdateHoursWorked/DialogConfirmationUpdateHoursWorked";

const TabPlanning = () => {
    const [state, dispatch] = useReducer(
        reducer_week_definition,
        INIT_REDUCER_WEEK_DIFINITION
    );
    const setDetailUser = useAuthDetailStore((state) => state.setDetailUser);
    const [toast, setToast] = useToast();
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const [toggleActivateTimeReporting, setToggleActivateTimeReporting] =
        useState(null);

    const {
        isLoading: isLoadingPlanningData,
        data: planningData,
        refetch: refetchPlanningData,
    } = useQuery(["get_planning_data"], () => api_planning_manager(), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data?.timetable) {
                setToggleActivateTimeReporting(data?.time_reporting_activated);
                dispatch({
                    type: ACTION_TYPES_WEEK_DIFINITION.init_planning,
                    payload: {
                        planning: data?.timetable?.map((d, index) => ({
                            id: index,
                            day_name: moment()
                                .day(index + 1)
                                .format("dddd"),
                            slots: {
                                morning: d?.am,
                                afternoon: d?.pm,
                            },
                        })),
                    },
                });
            }
        },
    });

    const time_reporting_activated = planningData?.time_reporting_activated;
    const isDirtyPlanning =
        planningData?.timetable?.reduce(
            (acc, curr) => acc + (curr.am + "" + curr.pm),
            ""
        ) !==
        state.planning?.reduce(
            (acc, curr) =>
                acc + (curr.slots.morning + "" + curr.slots.afternoon),
            ""
        );
    const isDirtyForm =
        isDirtyPlanning ||
        toggleActivateTimeReporting !== time_reporting_activated;

    const { mutate: mutate_planning_template, isLoading: is_saving_planning } =
        useMutation((data) => _api_post_planning(data), {
            onSuccess: (data) => {
                if (data?.success) {
                    setToast((state) => ({
                        ...state,
                        visible: true,
                        level: "success",
                        dismissible: true,
                        content: "Modifications enregistrées",
                        auto_hide_timeout: 3000,
                    }));

                    refetchPlanningData();
                    // TODO : refetch the /me query.
                    getMyInformations().then((data) => {
                        if (data?.success) {
                            return setDetailUser(data?.user);
                        }
                    });
                    return;
                }
            },
            onError: (err) => {
                return setToast((state) => ({
                    ...state,
                    visible: true,
                    level: "danger",
                    content: err?.message,
                }));
            },
        });

    const sendRequest = () => {
        const timetable = state?.planning?.map((day) => ({
            am: day?.slots?.morning,
            pm: day?.slots?.afternoon,
        }));

        mutate_planning_template({
            timetable,
            time_reporting_activated: toggleActivateTimeReporting,
            planning_updated: isDirtyPlanning,
        });
    };

    const _handleSubmit = () => {
        if (isDirtyPlanning) {
            return setOpenConfirmationDialog(true);
        }
        sendRequest();
    };

    if (isLoadingPlanningData) {
        return <Loader content={"chargement ..."} />;
    }

    return (
        <ContainerFluid
            styles={{
                backgroundColor: "#F0F0F0",
            }}
        >
            <Container column>
                <Toast
                    {...toast}
                    onStatusChange={() => {
                        return setToast((state) => ({
                            ...state,
                            visible: false,
                        }));
                    }}
                />
                <DialogConfirmationUpdateHoursWorked
                    content="La modification d'un emploi du temps impactera la déclaration du temps future de la personne concernée."
                    openConfirmationDialog={openConfirmationDialog}
                    onCancel={() => setOpenConfirmationDialog(false)}
                    onConfirm={() => {
                        sendRequest();
                        setOpenConfirmationDialog(false);
                        return;
                    }}
                />
                <Wrapper column gap="gap.large">
                    <Flex column gap="gap.medium">
                        <Flex space="between">
                            <Header
                                as="h3"
                                content="Jour de travail et de repos"
                            />
                            <Button
                                content="Enregistrer les modifications"
                                onClick={_handleSubmit}
                                loading={is_saving_planning}
                                disabled={!isDirtyForm}
                                primary
                                flat
                            />
                        </Flex>
                        <WrapperWWD>
                            <WeekWorkingDefinition
                                state={state}
                                dispatch={dispatch}
                                show_legend={false}
                            />
                        </WrapperWWD>
                    </Flex>
                    <Flex column gap="gap.small">
                        <Flex space="between">
                            <Header as="h3" content="Saisie du temps" />
                        </Flex>
                        <Flex>
                            {isLoadingPlanningData ? (
                                <p>skeleton</p>
                            ) : (
                                <Checkbox
                                    label="Activer la saisie du temps"
                                    toggle
                                    defaultChecked={time_reporting_activated}
                                    onChange={(e, { checked }) =>
                                        setToggleActivateTimeReporting(checked)
                                    }
                                />
                            )}
                        </Flex>
                    </Flex>
                    {toggleActivateTimeReporting && (
                        <Flex styles={{ gap: "104px" }}>
                            <Flex column gap="gap.medium">
                                <Flex space="between">
                                    <Header
                                        as="h4"
                                        content="Format de saisie"
                                    />
                                </Flex>
                                <Flex gap="gap.large">
                                    <Flex gap="gap.large">
                                        <Tile
                                            content={"Créneaux horaires"}
                                            desc="Avec emploi du temps"
                                            icon={IconSlotHours}
                                            $selected
                                        />
                                        <Tile
                                            content={"Volume horaires"}
                                            desc="Heures quotidiennes à déclarer"
                                            icon={IconWeather}
                                            $disabled
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex column gap="gap.medium">
                                <Flex space="between">
                                    <Header as="h4" content="Moyen de saisie" />
                                </Flex>
                                <Flex gap="gap.large">
                                    <Flex gap="gap.large">
                                        <Tile
                                            content={"Saisie manuelle"}
                                            desc="Avec aide au remplissage"
                                            icon={IconManualReporting}
                                            $selected
                                        />
                                        <Tile
                                            content={"Timbreuse"}
                                            desc="Timbreuse digitale"
                                            icon={IconTimer}
                                            $disabled
                                            $showBadge
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    )}
                </Wrapper>
            </Container>
        </ContainerFluid>
    );
};

export default TabPlanning;
const WrapperWWD = styled(Flex)`
    width: 908px;
`;
const Wrapper = styled(Flex)`
    border-radius: 4px;
    background: var(--light-theme-rest-background-default-background, #fff);

    /* shadow-8 */
    box-shadow: 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13),
        0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11);

    padding: 16px 24px 40px 24px;
`;

const HeaderStyled = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
`;

import React, { useEffect, useState } from "react";
import { Button, Flex, Loader } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getResources } from "../../../api/helpers";

import { Ball, Number, TextPrimary } from "../styles";
import { I18N_NS_ADD_EMPLOYEE } from "../constants";
import RessourceRow from "./RessourceRow";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { _api_save_resources } from "../api";
import { f_resetStepperContext, useSetResources, useStepper } from "../stores";
import { DialogCancelConfirmation } from "../../../components/Dialogs/DialogCancelConfirmation";
import useMutationDeleteEmployee from "../hooks/useMutationDeleteEmployee";

const StepRessources = () => {
    const [params] = useSearchParams();
    const paramsString = Array.from(params.entries())
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

    const IS_UPDATE_USER_ONBOARDING =
        params.get("t") === "update_onboarding" && params.get("id") !== null;

    const context_get_resources = useStepper((state) => state.step_resources);
    const context_step_informations = useStepper(
        (state) => state.step_personal_informations
    );
    const { isLoading: is_mutate_user, mutate: mutate_delete_user } =
        useMutationDeleteEmployee();
    const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);

    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const is_updating_user_onboarding =
        params.get("t") === "update_onboarding" && params.get("id") !== null;

    const is_refer_onboarding = params.get("refer") === "onboarding";

    // get type de contrats API.
    const {
        data: resources,
        isLoading: isLoadingResources,
        isFetching: isFetchingResources,
    } = useQuery(["getResources"], () => getResources(), {
        refetchOnWindowFocus: false,
        enabled: context_get_resources === null || is_updating_user_onboarding,
        onSuccess: (data) => {
            // formater la data si y'a les params URL
            if (is_updating_user_onboarding) {
                queryClient.setQueriesData("getResources", (data) => {
                    const new_state = data?.map((category, cat_index) => {
                        const ctx_cat = context_get_resources?.at(cat_index);
                        const ctx_resources_checked = ctx_cat?.resources
                            ?.filter((el) => el?.checked)
                            .map((el) => el?.id);

                        return {
                            ...category,
                            checked: ctx_cat?.checked ?? false,
                            resources: category.resources?.map((resource) => {
                                return {
                                    ...resource,
                                    checked: ctx_resources_checked?.includes(
                                        resource?.id
                                    ),
                                };
                            }),
                        };
                    });
                    console.log({ new_state });
                    return new_state;
                });
            }
            return;
        },
    });

    const { mutate: mutate_resources, loading: is_saving_resources } =
        useMutation((data) => _api_save_resources(data));

    const _handleSubmit = () => {
        const cache_resources = queryClient.getQueryData("getResources");

        const checked_list = cache_resources
            ?.map((resource) => resource?.resources)
            .flat()
            .filter((res) => res?.checked);

        const checked_list_ids = checked_list?.map((el) => el?.id);

        mutate_resources(
            {
                resources: checked_list_ids,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        // context_set_resources(checked_list_ids);
                        // si on est entrain de faire un update d'un user on onboarding
                        // y'a que 4 steps et non 5
                        // donc on le redirige vers la vue ONBOARDING et non pas la 5eme étape
                        if (is_updating_user_onboarding) {
                            navigate(
                                ROUTES.onboarding.home +
                                    "?alert=success_update" +
                                    (paramsString ? "&" + paramsString : "")
                            );
                            return;
                        }

                        navigate(
                            `${ROUTES.employee_add}/fifth${
                                paramsString ? "?" + paramsString : ""
                            }`
                        );
                        return;
                    }
                },
            }
        );
        return;
    };

    return (
        <Flex column>
            <Flex vAlign="center" gap="gap.small">
                <Ball
                    active={"true"}
                    already={"true"}
                    vAlign="center"
                    hAlign="center"
                >
                    <Number active={"true"} already={"true"}>
                        4
                    </Number>
                </Ball>
                <TextPrimary>
                    {t("pages.add_employee.step_resources.title")}
                </TextPrimary>
            </Flex>
            {isLoadingResources || isFetchingResources ? (
                <Flex gap="gap.medium" hAlign="start">
                    <Loader
                        label={t(
                            "pages.add_employee.step_resources.loader.messages.type_resources"
                        )}
                        size="medium"
                    />
                </Flex>
            ) : (
                // list des rows
                <RessourceRow resources={resources} />
            )}
            {/* link annuler l'ajout d'un collaborateur */}
            <Flex
                fill
                space="between"
                styles={{
                    marginTop: "40px",
                }}
            >
                <Flex>
                    {!IS_UPDATE_USER_ONBOARDING && (
                        <DialogCancelConfirmation
                            open={openDialogConfirmation}
                            cancelButton={{
                                content: "Annuler",
                                flat: true,
                            }}
                            confirmButton={{
                                content: "Annuler l’ajout d’un collaborateur",
                                flat: true,
                                isLoading: is_mutate_user,
                                styles: {
                                    backgroundColor: "var(--color-danger)",
                                },
                            }}
                            content={
                                "Vous allez perdre toutes les données saisies sur l’ajout du collaborateur."
                            }
                            trigger={
                                <Button
                                    content={{
                                        content: "Annuler l'ajout",
                                        styles: {
                                            color: "var(--color-danger)",
                                            textDecoration: "underline",
                                        },
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setOpenDialogConfirmation(true);
                                    }}
                                    text
                                />
                            }
                            header="Êtes-vous sûr(e) d'annuler l’ajout d’un collaborateur ?"
                            onClose={() => setOpenDialogConfirmation(false)}
                            onConfirm={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                // reset the context and other data.
                                const id_user =
                                    context_step_informations?.id_user;
                                if (id_user) {
                                    mutate_delete_user(
                                        {
                                            id_user:
                                                context_step_informations?.id_user,
                                        },
                                        {
                                            onSuccess: (data) => {
                                                if (data?.success) {
                                                    f_resetStepperContext();
                                                    setOpenDialogConfirmation(
                                                        false
                                                    );
                                                    navigate(
                                                        is_refer_onboarding
                                                            ? ROUTES.onboarding
                                                                  .home
                                                            : "/dashboard"
                                                    );
                                                    return;
                                                } else {
                                                    console.log(
                                                        "Une erreur est survenue !"
                                                    );
                                                }
                                            },
                                            onError: (err) => {
                                                return console.log({ err });
                                            },
                                        }
                                    );
                                } else {
                                    setOpenDialogConfirmation(false);
                                    f_resetStepperContext();
                                    navigate(
                                        is_refer_onboarding
                                            ? ROUTES.onboarding.home
                                            : "/dashboard"
                                    );
                                }
                                return;
                            }}
                        />
                    )}
                    {IS_UPDATE_USER_ONBOARDING && (
                        <Button
                            content={{
                                content: "Retour à l'onboarding",
                                styles: {
                                    color: "var(--color-danger)",
                                    textDecoration: "underline",
                                },
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate(ROUTES.onboarding.home);
                            }}
                            text
                        />
                    )}
                </Flex>
                <Flex gap="gap.small">
                    <Button
                        content="Revenir à l'étape précédente"
                        onClick={() =>
                            navigate(
                                `${ROUTES.employee_add}/third${
                                    paramsString ? "?" + paramsString : ""
                                }`
                            )
                        }
                        secondary
                        flat
                    />
                    <Button
                        content="Passer à l'étape suivante"
                        onClick={() => _handleSubmit()}
                        loading={is_saving_resources}
                        primary
                        flat
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default StepRessources;

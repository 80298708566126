import { ROUTES } from "../../../constants/routes";
import {
    BREADCRUMB_EXPENSE_DEFAULT,
    BREADCRUMB_EXPENSE_DETAIL_PAGE,
} from "../constants";

const ROUTE = {
    [ROUTES.expenses.home]: BREADCRUMB_EXPENSE_DEFAULT,
    [ROUTES.expenses.detail]: BREADCRUMB_EXPENSE_DETAIL_PAGE,
};

export const get_breadcrumb_depending_page = (location) => {
    let { pathname } = location;
    if (pathname.includes("detail")) {
        pathname = ROUTES.expenses.detail;
    }
    return ROUTE[pathname] || BREADCRUMB_EXPENSE_DEFAULT;
};

const CURRENCIES = new Map([
    ["EUR", "€"],
    ["CHF", "CHF"],
    ["USD", "$"],
]);

export const get_symbol_currency = (currency_code) => {
    return CURRENCIES.get(currency_code);
};

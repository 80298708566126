import React from "react";
import CustomBreadCrumb from "../../components/breadcrumb";
import { ROUTES } from "../../constants/routes";
import { Container, ContainerFluid } from "../../common/styles";
import {
    Body,
    BodyContainer,
    HeaderContainer,
    Section,
    Title,
} from "./index.style";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import { CalendarAddRegular } from "@fluentui/react-icons";
import CalendarRemoteJob from "./components/CalendarRemoteJob";
import useLogicRemote from "./hooks/useLogicRemote";
import CalendarActionsHeader from "./components/CalendarActionsHeader/CalendarActionsHeader";
import DialogDeclareRemoteDay from "./components/DialogDeclareRemoteDay/DialogDeclareRemoteDay";
import { Tab, TabList } from "@fluentui/react-components";

const RemoteJob = () => {
    const { query, week, calendar, agency } = useLogicRemote();

    return (
        <ContainerFluid
            column
            gap="gap.medium"
            styles={{ backgroundColor: "#F5F5F5" }}
        >
            <Flex column styles={{ backgroundColor: "white" }}>
                {/* NABIGATION HEADER PAGE */}
                <CustomBreadCrumb
                    navList={[
                        {
                            label: "Télétravail",
                            href: ROUTES.remote_job.home,
                        },
                    ]}
                />
                {/* sub header agency */}
                <Container>
                    <TabList
                        defaultSelectedValue={"agency"}
                        selectedValue={"agency"}
                        appearance="transparent"
                    >
                        <Tab value={"agency"}>
                            <Flex gap="gap.small" vAlign="center">
                                <Text content={agency.name} />
                            </Flex>
                        </Tab>
                    </TabList>
                </Container>
            </Flex>
            {/* ODY PAGE */}
            <BodyContainer column>
                {/* title */}
                <HeaderContainer space="between" vAlign="center" fill>
                    <Title>Calendrier de l'équipe</Title>
                    <DialogDeclareRemoteDay
                        onAddSuccess={({ start_date }) => {
                            return week.set_week({ start_date });
                        }}
                        trigger={
                            <Button
                                content={"Déclarer un jour"}
                                icon={<CalendarAddRegular />}
                                primary
                                flat
                            />
                        }
                    />
                </HeaderContainer>
                {/* body */}
                <Body column>
                    <Section column>
                        <Flex
                            column
                            styles={{ width: "fit-content" }}
                            gap="gap.large"
                        >
                            {/* sliders */}
                            <CalendarActionsHeader
                                labelDropdown={week.week_text_from_to}
                                isoWeekNumber={week.iso_week}
                                onIncrementWeek={week.increment_week}
                                onDecrementWeek={week.decrement_week}
                                onDatePickerChange={week.on_datepicker_change}
                                onAddSuccess={(isoweek) =>
                                    week.set_week(isoweek)
                                }
                            />
                            {/* calendar */}
                            <CalendarRemoteJob
                                days={calendar.list_days}
                                rows={calendar.rows}
                                isLoading={query.isLoading || query.isFetching}
                                onAddEventSuccess={({ start_date }) => {
                                    return week.set_week({ start_date });
                                }}
                            />
                        </Flex>
                    </Section>
                </Body>
            </BodyContainer>
        </ContainerFluid>
    );
};

export default RemoteJob;

import { Text } from "@fluentui/react-northstar";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const ColumnLinkTo = styled(Link)`
    text-decoration: none;
    /* width: 100%; */
    /* width: 200px; */
    width: clamp(150px, 80%, 250px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ColumnTitle = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    color: #5b5fc7;
`;

export const ColumnDate = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #424242;
`;

import React from "react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { AddIcon, Button, Flex, Text } from "@fluentui/react-northstar";
import { useMutation, useQueryClient } from "react-query";

import Card from "../..";
import SurroundingPeople from "../SurroundingPeople";
import { QUERY_KEY } from "../../../../constants";
import { _api_update_surrounding_people } from "../../../../api";
import { useEmployeeFolderIsMe } from "../../../../store";

import IllustrationEntourage from "../../../../../../assets/illustrations/employee_folder/entourage.svg";
import DependentsPersonsFields from "../components/DependentsPersonsFields";
import styled from "styled-components";
import EmergencyContactFields from "../components/EmergencyContactFields";
import { ROLES_CODE, roles_access } from "../../../../../../constants/roles";

const CardSurroundingPeople = ({ identity_data }) => {
    const clientQuery = useQueryClient();

    const is_me = useEmployeeFolderIsMe();
    const isRh = roles_access({
        usersPrivileges: [ROLES_CODE?.hr],
    });

    const [modeUpdate, setModeUpdate] = useState(false);

    const {
        handleSubmit,
        register,
        formState,
        control,
        reset,
        watch,
        resetField,
        getValues,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            dependents_person: identity_data?.dependents_person,
            emergency_contacts:
                identity_data?.emergency_contacts?.length === 2
                    ? identity_data?.emergency_contacts
                    : identity_data?.emergency_contacts?.length === 1
                    ? [
                          ...identity_data?.emergency_contacts,
                          {
                              first_name: "",
                              last_name: "",
                              relationship_with_user: "",
                              phone_number: "",
                          },
                      ]
                    : [
                          {
                              first_name: "",
                              last_name: "",
                              relationship_with_user: "",
                              phone_number: "",
                          },
                          {
                              first_name: "",
                              last_name: "",
                              relationship_with_user: "",
                              phone_number: "",
                          },
                      ],
        },
    });

    const {
        fields: fields_dependents_person,
        append: append_dependents_person,
        remove: remove_dependents_person,
    } = useFieldArray({
        control,
        name: "dependents_person",
    });

    const {
        fields: fields_emergency_contacts,
        append: append_emergency_contact,
        remove: remove_emergency_contact,
    } = useFieldArray({
        control,
        name: "emergency_contacts",
        rules: {
            length: 2,
        },
    });

    const {
        mutate: mutate_update_surrounding_people,
        isLoading: is_loading_mutate,
    } = useMutation((data) => _api_update_surrounding_people(data));

    const _setModeUpdate = (bool) => {
        reset();
        return setModeUpdate(bool);
    };

    const onSubmit = (data) => {
        const { first_name, last_name, phone_number, relationship_with_user } =
            getValues("emergency_contacts.0");
        if (
            !first_name ||
            !last_name ||
            !phone_number ||
            !relationship_with_user
        ) {
            return;
        }
        mutate_update_surrounding_people(
            {
                dependents_person: data?.dependents_person ?? [],
                emergency_contacts: data?.emergency_contacts ?? [],
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        clientQuery.resetQueries([QUERY_KEY + "::identity"]);
                        _setModeUpdate(false);
                    }
                    return;
                },
            }
        );
        return console.log({ data });
    };

    if (modeUpdate) {
        return (
            <Card column>
                <Card.EditTitle
                    content={"Modification de l'Entourage"}
                    onClose={() => _setModeUpdate(false)}
                />
                <Card.Body
                    content={
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex column gap="gap.medium">
                                <Flex gap="gap.medium" column>
                                    <Title content="Personnes à charge" />

                                    {fields_dependents_person?.map(
                                        (person, index) => (
                                            <DependentsPersonsFields
                                                index={index}
                                                person={person}
                                                register={register}
                                                remove_dependents_person={
                                                    remove_dependents_person
                                                }
                                                key={index}
                                            />
                                        )
                                    )}
                                    <Button
                                        content="Ajouter une personne à charge"
                                        icon={<AddIcon />}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            append_dependents_person({
                                                first_name: "",
                                                last_name: "",
                                                birthdate: "",
                                            });
                                            return;
                                        }}
                                        primary
                                        text
                                    />
                                </Flex>
                                <Flex gap="gap.medium" column>
                                    <Title content="Contacts d’urgence" />
                                    {fields_emergency_contacts?.map(
                                        (person, index) => (
                                            <EmergencyContactFields
                                                register={register}
                                                index={index}
                                                watch={watch}
                                                person={person}
                                                resetField={resetField}
                                                removeContact={
                                                    remove_emergency_contact
                                                }
                                                key={index}
                                            />
                                        )
                                    )}
                                    {fields_emergency_contacts?.length < 2 && (
                                        <Button
                                            content="Ajouter un contact d'urgence"
                                            icon={<AddIcon />}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                append_emergency_contact({
                                                    first_name: "",
                                                    last_name: "",
                                                    relationship_with_user: "",
                                                    phone_number: "",
                                                });
                                                return;
                                            }}
                                            primary
                                            text
                                        />
                                    )}
                                </Flex>
                                <Flex hAlign="center" gap="gap.medium">
                                    <Button
                                        content={"Annuler"}
                                        onClick={() => _setModeUpdate(false)}
                                        flat
                                    />
                                    <Button
                                        content={
                                            "Enregistrer les modifications"
                                        }
                                        loading={is_loading_mutate}
                                        primary
                                        flat
                                    />
                                </Flex>
                            </Flex>
                        </form>
                    }
                />
            </Card>
        );
    }

    return (
        <Card column>
            {/* <Card.Illustration
                image={IllustrationEntourage}
                canEdit={is_me || isRh}
            /> */}
            <Card.Title
                content={"Entourage"}
                canEdit={is_me || isRh}
                onEdit={() => {
                    if (!is_me && !isRh) {
                        return;
                    }
                    return _setModeUpdate(true);
                }}
            />
            {/* entourage */}
            <Card.Body
                content={
                    <SurroundingPeople
                        dependents_person={identity_data?.dependents_person}
                        emergency_contacts={identity_data?.emergency_contacts}
                    />
                }
            />
        </Card>
    );
};

export default CardSurroundingPeople;

const Title = styled(Text)`
    color: #242424;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
`;

import { Button, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ROUTES } from "../../../../constants/routes";
import { useTranslation } from "react-i18next";
import { I18N_NS_EXPENSE } from "../../constants";
import Illustration from "../../../../components/shared/Illustration";

const EmptyExpensesToPaid = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);

    return (
        <Flex
            fill
            hAlign="center"
            column
            gap="gap.small"
            styles={{ marginTop: "15px" }}
        >
            <Illustration
                filename={"expense/Note-de-frais-vide.svg"}
                width="200px"
            />
            <EmptyTitle content={"Aucune note à payer"} />
            <EmptySubTitle
                content={"Vous n'avez aucune note de frais à payer"}
            />
        </Flex>
    );
};

export default EmptyExpensesToPaid;

const EmptyTitle = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
`;

const EmptySubTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 210px;
    text-align: center;
    color: #242424;
`;

import {
    AddIcon,
    Alert,
    Button,
    Divider,
    Flex,
    Input,
    InputLabel,
    Text,
    TextArea,
} from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SliderTabs from "../../../components/shared/SliderTabs";
import CustomEmptyModelTask from "./CustomEmptyModelTask";
import { FromTitle } from "./style";

import { PT_CREATE_TEMPLATE } from "../constants";
import { useQuery } from "react-query";
import { api_get_tasks_of_template } from "../../../api/manage_model_tasks";
import LoaderPage from "../../../components/loaders/LoaderPage";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "../../../common/styles";
import TableOfTasksForModify from "./TableOfTasksForModify";
import { useState } from "react";
import FormDialogAddManualTaskTemplate from "./FormDialogAddManualTaskTemplate";
import { useEffect } from "react";
import { I18N_NS_TRANSLATION } from "../../../constants";
import { InputStyle, WrapperAddTask } from "./DialogCreateTemplate";
import useToast from "../../../hooks/useToast";
import Toast from "../../../components/Alerts";

const DialogModifyTemplate = ({
    task_template_id,
    showAlert,
    setShowAlert,
    setOpenDialogModifyTask,
}) => {
    const { t } = useTranslation(I18N_NS_TRANSLATION);
    const [templateTasks, setTemplateTasks] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [toastConfig, setToastConfig] = useToast();

    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        watch,
    } = useFormContext();

    const { data, isLoading, isFetching } = useQuery(
        ["api_get_tasks_of_template", task_template_id],
        () => api_get_tasks_of_template({ task_template_id }),
        {
            enabled: !!task_template_id && refresh === null,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setValue("id", data?.task_template?.id);
                setValue("name", data?.task_template?.name);
                setValue("description", data?.task_template?.description);
                setValue("tasks", data?.task_template?.task_definitions);
                setValue("type", data?.task_template?.type?.id);
                setTemplateTasks(data?.task_template?.task_definitions);
            },
        }
    );

    useEffect(() => {
        setValue("tasks", templateTasks);
    }, [setValue, templateTasks]);

    // FUNCTIONS
    const onModifyTask = (data) => {
        setToastConfig((state) => ({
            ...state,
            visible: true,
            content: "La tâche a bien été modifiée",
            level: "success",
        }));
        setOpenDialogModifyTask(data);
        return;
    };

    if (isLoading || isFetching) return <LoaderPage />;

    const { type, tasks, name, description } = watch();

    return (
        <>
            {showAlert?.visible && (
                <Alert
                    content={showAlert?.message}
                    visible={true}
                    success={showAlert?.level === "success" ? true : false}
                    warning={showAlert?.level === "warning" ? true : false}
                    danger={showAlert?.level === "danger" ? true : false}
                    dismissible
                    onVisibleChange={() =>
                        setShowAlert({
                            visible: false,
                            message: "",
                        })
                    }
                />
            )}
            <Toast
                {...toastConfig}
                onStatusChange={() =>
                    setToastConfig((state) => ({ ...state, visible: false }))
                }
            />
            <Flex column fill gap="gap.medium">
                <Flex styles={{ marginTop: "10px" }}>
                    <SliderTabs
                        tabs={["Onboarding", "Suivi", "Offboarding"]}
                        onChange={(v) => setValue("type", parseInt(v) + 1)}
                        defaultActiveIndex={type - 1}
                    />
                </Flex>
                <Flex>
                    <TextDescription>
                        Pour ajouter un modèles de tâche, remplissez les
                        informations suivantes :
                    </TextDescription>
                </Flex>
                <Flex>
                    <FromTitle>Définition du modèle</FromTitle>
                </Flex>
                {/* forms */}
                <Flex column gap="gap.medium">
                    <div>
                        <InputStyle
                            {...register("name", {
                                minLength: {
                                    value: 2,
                                    message:
                                        "Le nom doit contenir au minimum 2 caractères.",
                                },
                                required: {
                                    value: true,
                                    message: "Ce champ est obligatoire",
                                },
                            })}
                            label="Nom du modèle"
                            styles={{ position: "relative" }}
                            error={errors?.name ? true : false}
                            defaultValue={name}
                            required
                            clearable
                        />
                        <ErrorMessage content={errors?.name?.message} />
                    </div>
                    <Flex column>
                        <InputLabel content={"Descriptif du modèle*"} />
                        <TextArea
                            {...register("description", {
                                minLength: {
                                    value: 2,
                                    message:
                                        "Le descriptif doit contenir au minimum 2 caractères.",
                                },
                                required: {
                                    value: true,
                                    message: "Ce champ est obligatoire",
                                },
                            })}
                            defaultValue={description}
                            aria-label="description model"
                            error={errors?.description ? true : false}
                        />
                        <ErrorMessage content={errors?.description?.message} />
                    </Flex>
                </Flex>

                <Divider />
                <WrapperAddTask fill space="between" vAlign="center">
                    <FromTitle>Tâches comprises dans le modèle</FromTitle>
                    {/* ajouter la tâche manuelle */}
                    <FormDialogAddManualTaskTemplate
                        setTemplateTasks={setTemplateTasks}
                        setToastConfig={setToastConfig}
                    />
                </WrapperAddTask>
                <Flex fill>
                    {tasks?.length > 0 ? (
                        <TableOfTasksForModify
                            tasks={tasks}
                            setTemplateTasks={setTemplateTasks}
                            setOpenDialogModifyTask={onModifyTask}
                            setToastConfig={setToastConfig}
                        />
                    ) : (
                        <CustomEmptyModelTask
                            title={t(
                                PT_CREATE_TEMPLATE + ".empty_illustration.title"
                            )}
                            description={t(
                                PT_CREATE_TEMPLATE +
                                    ".empty_illustration.description"
                            )}
                        />
                    )}
                </Flex>
            </Flex>
        </>
    );
};

export default DialogModifyTemplate;

const TextDescription = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #424242;
`;

import { Divider, Flex, Text } from "@fluentui/react-northstar";
import moment from "moment";
import React from "react";
import styled from "styled-components";

export default function HeaderDates({ contractStartDate, trialPeriodEndDate }) {
    const startDateFormatted = moment(contractStartDate).format("DD/MM/YYYY");

    const trialPeriodEndDateFormatted =
        moment(trialPeriodEndDate).format("DD/MM/YYYY");
    return (
        <Flex gap="gap.medium" vAlign="center">
            <Flex column>
                <Label content="Début de contrat" />
                <DateText content={startDateFormatted} />
            </Flex>
            <Divider size={1} vertical />
            <Flex column>
                <Label content="Fin de période d'essai" />
                <DateText content={trialPeriodEndDateFormatted} />
            </Flex>
        </Flex>
    );
}

const Label = styled(Text)`
    color: #424242;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const DateText = styled(Text)`
    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

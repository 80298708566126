import moment from "moment";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import DialogFilterAjustment from "./DialogFilterAjustment";
import { _get_all_employees_and_departments } from "../../../../../api/user";
import { AddIcon, Flex, Text } from "@fluentui/react-northstar";
import { _get_types_leave, ExportAllSoldeForRH } from "../../../api";
import { TYPES_STATUS_LEAVE } from "./constants";

export default function ExportAllSoldeRH({
    exportAllSolde,
    setConfigToast,
    openDialog,
    setOpenDialog,
}) {
    const [searchParams, setSearchParams] = useSearchParams({
        employee: [],
        type: [],
        // checkboxes: [],
        checkboxes_status: [],
        start_date: "",
        end_date: "",
    });

    // const defaultCheckboxesStatus =
    //     searchParams.getAll("s").length > 0
    //         ? searchParams.getAll("s")
    //         : TYPES_STATUS_LEAVE.map((e) => e.id);
    const methodsAjustment = useForm({
        defaultValues: {
            // checkboxes: searchParams.getAll("a") ?? [],
            // checkboxes_status: searchParams.getAll("s") ?? [],
            // checkboxes_status: defaultCheckboxesStatus,
            start_date: null,
            end_date: searchParams.get("end_date")
                ? new Date(searchParams.get("end_date"))
                : null,
            employee: [],
            type: searchParams.getAll("t") ?? [],
        },
    });
    const onSubmitAjustmentFilter = (data) => {
        const new_params = {};
        console.log({ data17: data });
        if (data?.start_date)
            new_params.start_date = moment(data?.start_date).format(
                "YYYY-MM-DD"
            );
        if (data?.end_date)
            new_params.end_date = moment(data?.end_date).format("YYYY-MM-DD");

        setSearchParams({
            ...new_params,
            ud: data?.employee?.map((u) => u.id),
            s: data?.checkboxes_status?.map((cb) => cb),
            t: data?.type?.map((t) => t.id),
        });
        // refetch();

        return;
    };

    const { isLoading: mutate_is_loading, mutate } = useMutation(
        (data) => ExportAllSoldeForRH(data),
        {
            onSuccess: async (data) => {
                setConfigToast({
                    visible: true,
                    content: "Export réussi",
                    level: "success",
                });

                return setOpenDialog(false);
            },
            onError: (error) => {
                setConfigToast({
                    visible: true,
                    content: "Erreur lors de l'export",
                    level: "error",
                });
            },
        }
    );
    const { data: employees, isLoading: is_loading_employees } = useQuery(
        ["leave::get_list_employee"],
        () => {
            return _get_all_employees_and_departments();
        },
        {
            onSuccess: (data) => {
                const ud = searchParams.getAll("ud");
                const selected_employee_params = data?.filter((d) =>
                    ud?.includes(d?.id)
                );
                methodsAjustment.setValue(
                    "employee",
                    selected_employee_params?.map((element) => ({
                        id: element?.id,
                        header: element?.name,
                        image: element?.avatar ?? <AddIcon />,
                        content: element.job_title,
                    }))
                );
            },
        }
    );
    const { data: leave_types, isLoading: is_loading_types } = useQuery(
        ["leave::get_list_types_leave"],
        () => {
            return _get_types_leave();
        },
        {
            onSuccess: (data) => {
                const t = searchParams.getAll("t");
                const selected_type = data?.filter((d) =>
                    t?.includes(d?.id + "")
                );
                methodsAjustment.setValue(
                    "type",
                    selected_type?.map((element) => ({
                        id: element?.id,
                        header: element?.name,
                        color: element?.color,
                    }))
                );
            },
        }
    );
    const onSubmitExportLeaves = (data) => {
        // console.log({ data666: data });
        mutate(data);
        return;
    };

    return (
        <FormProvider {...methodsAjustment}>
            <form
                onSubmit={methodsAjustment.handleSubmit(
                    onSubmitAjustmentFilter
                )}
            >
                <DialogFilterAjustment
                    // openDialog={openDialog}
                    // setOpenDialog={setOpenDialog}
                    exportAllSolde={exportAllSolde}
                    // filterData={false}
                    // exportData={true}
                    isDownloadingExcel={mutate_is_loading}
                    isMobile={false}
                    onSubmitAjustmentFilter={onSubmitExportLeaves}
                    employees={employees}
                    is_loading_employees={is_loading_employees}
                    leave_types={leave_types}
                    is_loading_types={is_loading_types}
                    refetch={() => console.log("Refetching")}
                    isHR={true}
                    isManager={false}
                    header={"Exporter les données pour Excel"}
                />
            </form>
        </FormProvider>
    );
}

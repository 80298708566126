import React, { useEffect, useState } from "react";
import { QUERY_KEY } from "../../constants";
import { _api_get_categories } from "../../api";
import { useQuery } from "react-query";

const useLogicCategoryExpense = ({ selected_category, open = false }) => {
    const [activeCategory, setActiveCategory] = useState(selected_category);
    const [openDialogCategory, setOpenDialogCategory] = useState(open);

    const { data: categories, isLoading } = useQuery(
        QUERY_KEY + "::categories",
        () => _api_get_categories(),
        {
            refetchOnWindowFocus: false,
        }
    );
    // fermer le dialog
    const closeDialog = () => {
        return setOpenDialogCategory(false);
    };
    // ouvrir dialog
    const openDialog = () => {
        return setOpenDialogCategory(true);
    };

    return {
        query: {
            isLoading,
            categories: categories ?? [],
        },
        selected_category: {
            id: activeCategory?.id,
            icon_url: activeCategory?.icon_url,
            name: activeCategory?.name,
            update_category: (new_active_category) => {
                return setActiveCategory(new_active_category);
            },
        },
        dialog: {
            open: openDialogCategory,
            setClose: closeDialog,
            setOpen: openDialog,
        },
    };
};

export default useLogicCategoryExpense;

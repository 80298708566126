import { Accordion, Box, Button, teamsTheme } from "@fluentui/react-northstar";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import { PANELS_FOR_MANAGER } from "./Panels";

const SCHEMA = yup.object({
    phone_number: yup.string().nullable(),

    // Voir les derniers lien que hajer m'a envoyé, il ya quelque bug de message d'erreur a retravaillé !!!
});

export default function TabsContentManager() {
    const methods = useForm({
        resolver: yupResolver(SCHEMA),
        mode: "all",
    });

    const navigate = useNavigate();

    const { control, data } = methods;

    const onSubmit = (data) => {
        console.log({ data });

        return navigate("/me");
    };
    const BackToProfile = (data) => {
        console.log("Back to profile navigate");
        return navigate("/me");
    };

    return (
        <FormProvider {...methods} theme={teamsTheme}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Accordionn
                    defaultActiveIndex={[0, 1, 2]}
                    panels={PANELS_FOR_MANAGER}
                    expandiconposition="end"
                />
                <Action>
                    <Button onClick={BackToProfile} tinted content="Annuler" />

                    <Button
                        type="submit"
                        content="Enregistrer"
                        primary
                        // loading={isLoadingMutation}
                    />
                </Action>
            </form>
        </FormProvider>
    );
}

const Accordionn = styled(Accordion)`
    background-color: #ebebeb;
    > .ui-box {
        background-image: none;
    }
    .ui-accordion__title__content-wrapper {
        grid-template-columns: 1fr;
        > .ui-box:first-child {
            display: none;
        }
    }
    background-color: #ebebeb;
    max-width: 875px;
    margin: 0 auto;
`;

const Action = styled(Box)`
    display: flex;
    gap: 16px;
    justify-content: center;
    background-color: #ebebeb;
    max-width: 875px;
    margin: 0 auto;
    padding: 20px;
`;

import styled from "styled-components";
import { Input, Text } from "@fluentui/react-northstar";

export const Title = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    /* Medium/600 */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;

export const InputTimePicker = styled(Input)`
    width: 96px;
`;

export const TextDeleteSlot = styled(Text)`
    color: var(--light-theme-rest-foreground-red-foreground, #c4314b);

    /* Small/400 underlined */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    text-decoration-line: underline;
`;

import * as React from "react";
import { styled } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import { logout } from "../../../api/auth";
// import SideBar from "../SideBar/SideBar";
import useSidebar from "../SideBar/Context.sideBar";

const Header = ({ user }) => {
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const settings = [
        {
            id: 1,
            name: "Profile",
            onClick: () => navigate("/me"),
        },
        {
            id: 2,
            name: "Todo",
            onClick: () => alert("Todo"),
        },
        {
            id: 1,
            name: "Se déconnecter",
            onClick: () => {
                logout();
            },
        },
    ];

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const toggleSideBar = useSidebar((state) => state.toggleSideBar);
    const setToggleActiveNav = useSidebar((state) => state.setToggleActiveNav);

    const navigate = useNavigate();

    return (
        <AppBarStyle position="fixed">
            <Container maxWidth="false">
                <Toolbar disableGutters>
                    <AdbIcon sx={{ mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        LOGO
                    </Typography>

                    <Box sx={{ flexGrow: 0 }}>
                        <Responsive>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() =>
                                    setToggleActiveNav(!toggleSideBar)
                                }
                                color="inherit"
                            >
                                <MenuIcon sx={{ mr: 2 }} />
                            </IconButton>
                        </Responsive>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div>
                            <Button
                                style={{ color: "white" }}
                                id="basic-button"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-haspopup="false"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick}
                            >
                                <EmojiObjectsOutlinedIcon />
                                Solution
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                }}
                            >
                                <MenuItem onClick={handleClose}>
                                    Solution 1{" "}
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    Solution 2{" "}
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    Solution 3{" "}
                                </MenuItem>
                            </Menu>
                        </div>
                        <Button
                            //   onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: "white" }}
                        >
                            Collaborator
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 0,
                            display: "flex",
                            gap: "15px",
                            flexDirection: "row",
                        }}
                    >
                        <IconButton title="Information ?" color="inherit">
                            <Badge>
                                <InfoOutlinedIcon
                                    onClick={() => {
                                        alert("Info Icon");
                                    }}
                                />
                            </Badge>
                        </IconButton>

                        <IconButton color="inherit">
                            <Badge title="Réglage" color="error">
                                <SettingsOutlinedIcon
                                    onClick={() => alert("Setting")}
                                />
                            </Badge>
                        </IconButton>

                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                            title="Notification"
                        >
                            <Badge badgeContent={2} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <Tooltip title="Mon profil">
                            <IconButton
                                onClick={handleOpenUserMenu}
                                sx={{ p: 0 }}
                            >
                                <Avatar alt="Remy Sharp" src={user?.avatar} />
                            </IconButton>
                        </Tooltip>

                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map(({ id, name, onClick }, setting) => (
                                <MenuItem key={setting} onClick={onClick}>
                                    <Typography textAlign="center">
                                        {name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBarStyle>
    );
};
export default Header;

const AppBarStyle = styled(AppBar)`
    z-index: 1;
`;

const Responsive = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        visibility: "visible",
    },
    [theme.breakpoints.up("md")]: {
        visibility: "hidden",
    },
    [theme.breakpoints.up("lg")]: {
        visibility: "hidden",
    },
}));

import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Content = styled(Flex)`
    width: 262px;
    gap: 8px;
    background-color: #e8ebfa;
    padding: 8px;
    border-radius: 4px;
`;
export const AnnualRecoveryText = styled(Text)`
    color: #4f52b2;

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
export const AnnualRecoveryDesc = styled(Text)`
    color: #424242;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

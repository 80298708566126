import { Avatar, Flex, Image, Text } from "@fluentui/react-northstar";
import { TeamCard } from "./TeamCard";
import styled from "styled-components";
import Solid from "../emojis/Solid.svg";
import Sad from "../emojis/Sad.svg";
import heart from "../emojis/heart.svg";
import like from "../emojis/like.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CardUser from "../../../features/employee_folder/components/Cards/CardPositionEntreprise/CardUser";
import { DEVICE } from "../../../constants/responsive";
import { isMobile } from "react-device-detect";

export const ActivitySection = ({ team_activities }) => {
    const birthdays = team_activities?.birthdays;
    const leaves = team_activities?.leaves;
    const new_arrivals = team_activities?.new_arrivals;
    const work_birthdays = team_activities?.work_birthdays;

    // * navigate to any employee
    const navigate = useNavigate();
    const _navigateToEmployee = (id) => {
        if (isNaN(id)) {
            return console.log("is not a valid ID employee");
        }

        return navigate(ROUTES.employee_folder.custom_route(id));
    };

    const leaveFiltered = leaves?.filter(
        (value, index, array) =>
            array.findIndex((a) => a.id === value.id) === index
    );

    return (
        <Flex column gap="gap.medium">
            <ActivityTitle content="Activités de mon équipe" />
            <ActivityCard column>
                {birthdays?.length > 0 && (
                    <Flex column gap="gap.medium">
                        <Flex vAlign="center" gap="gap.medium">
                            {/* celbration img */}
                            <EmojisBox vAlign="center">
                                <Image src={Solid} />
                            </EmojisBox>
                            <Flex column>
                                <BirthDayText content="Anniversaires du jour" />
                                <ActivityDescriptionText content="Une bougie de plus !" />
                            </Flex>
                        </Flex>
                        <CardStyle gap="gap.small" column>
                            {birthdays?.map((el) => {
                                return (
                                    <CardUser
                                        key={el?.id}
                                        avatar={el?.avatar}
                                        full_name={`${el?.first_name} ${el?.last_name}`}
                                        job_title={el?.job_title}
                                        hideTitle={false}
                                        onClick={() =>
                                            _navigateToEmployee(el?.id)
                                        }
                                    />
                                );
                            })}
                        </CardStyle>
                    </Flex>
                )}
                {birthdays?.length === 0 && (
                    <EmptyDataText content="Aucune activité aujourd'hui" />
                )}

                {leaves?.length > 0 && (
                    <Flex column gap="gap.medium">
                        <Flex vAlign="center" gap="gap.medium">
                            <EmojisBox vAlign="center">
                                <Image src={Sad} />
                            </EmojisBox>
                            <Flex column>
                                <BirthDayText content="Absences" />
                                <ActivityDescriptionText content="Impossible de les croiser aujourd'hui !" />
                            </Flex>
                        </Flex>
                        <CardStyle gap="gap.small" column>
                            {leaveFiltered?.map((el) => {
                                return (
                                    <CardUser
                                        key={el?.id}
                                        avatar={el?.avatar}
                                        full_name={`${el?.first_name} ${el?.last_name}`}
                                        job_title={el?.job_title}
                                        hideTitle={false}
                                        onClick={() =>
                                            _navigateToEmployee(el?.id)
                                        }
                                    />
                                );
                            })}
                        </CardStyle>
                    </Flex>
                )}

                {new_arrivals?.length > 0 && (
                    <Flex column gap="gap.medium">
                        <Flex vAlign="center" gap="gap.medium">
                            {/* Love img */}
                            <EmojisBox vAlign="center">
                                <Image src={heart} />
                            </EmojisBox>
                            <Flex column>
                                <BirthDayText content="Nouvelles arrivées" />
                                <ActivityDescriptionText content="Ils démarrent ce mois-ci !" />
                            </Flex>
                        </Flex>
                        <CardStyle gap="gap.small" column>
                            {new_arrivals?.map((el) => {
                                return (
                                    <CardUser
                                        key={el?.id}
                                        avatar={el?.avatar}
                                        full_name={`${el?.first_name} ${el?.last_name}`}
                                        job_title={el?.job_title}
                                        hideTitle={false}
                                        onClick={() =>
                                            _navigateToEmployee(el?.id)
                                        }
                                    />
                                );
                            })}
                        </CardStyle>
                    </Flex>
                )}

                {work_birthdays?.length > 0 && (
                    <Flex column gap="gap.medium">
                        <Flex vAlign="center" gap="gap.medium">
                            {/* celbration img */}
                            <EmojisBox vAlign="center">
                                <Image src={like} />
                            </EmojisBox>
                            <Flex column>
                                <BirthDayText content="Anniversaires pro du mois" />
                                <ActivityDescriptionText content="L’ancienneté va faire un +1 !" />
                            </Flex>
                        </Flex>
                        <CardStyle gap="gap.small" column>
                            {work_birthdays?.map((el) => {
                                return (
                                    <CardUser
                                        key={el?.id}
                                        avatar={el?.avatar}
                                        full_name={`${el?.first_name} ${el?.last_name}`}
                                        job_title={el?.job_title}
                                        hideTitle={false}
                                        onClick={() =>
                                            _navigateToEmployee(el?.id)
                                        }
                                    />
                                );
                            })}
                        </CardStyle>
                    </Flex>
                )}
            </ActivityCard>
        </Flex>
    );
};

const EmptyDataText = styled(Text)`
    color: #616161;
    text-align: center;
`;

const CardStyle = styled(Flex)`
    margin-left: 55px;
`;

const EmojisBox = styled(Flex)`
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 22px;
    background-color: #f5f5f5;
`;

const ActivityTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #242424;
`;

const ActivityCard = styled(Flex)`
    padding: 24px 16px;
    gap: 40px;
    background: #ffffff;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    @media ${DEVICE.mobileS} {
        min-width: 100%;
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: 445px;
    }
`;
const ActivityDescriptionText = styled(Text)`
    min-width: 129px;
    height: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

const BirthDayText = styled(Text)`
    height: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4f52b2;
    @media ${DEVICE.mobileS} {
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: 341px;
    }
`;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Flex, Skeleton, Text } from "@fluentui/react-northstar";
import { I18N_NS_TIME_REPORTING, STATUS_MONTH } from "../../constants";
import { useTranslation } from "react-i18next";

const BadgeMonthStatusSkeleton = () => {
    return (
        <Container>
            <Skeleton animation="pulse">
                <Skeleton.Line width="78px" height="16px" />
            </Skeleton>
        </Container>
    );
};

const BadgeMonthStatus = ({ level, isLoading = false }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const { content, style } = STATUS_MONTH[level];

    if (isLoading) {
        return <BadgeMonthStatusSkeleton />;
    }

    return (
        <Container styles={style}>
            <ContainerText content={t(content)} />
        </Container>
    );
};

export default BadgeMonthStatus;

BadgeMonthStatus.propTypes = {
    level: PropTypes.oneOf([
        "in_progress",
        "validated",
        "rectification",
        "sended",
    ]).isRequired,
};

const ContainerText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
`;

const Container = styled(Flex)`
    width: fit-content;
    border-radius: 100px;
    padding: 2px 6px;
`;

import React from "react";
import { Flex, InfoIcon, Text, Tooltip } from "@fluentui/react-northstar";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { I18N_NS_LEAVE } from "../../../../../features/leave/constants";

const Content = ({ employee_data = [] }) => {
    const { t } = useTranslation(I18N_NS_LEAVE);
    const isEmptyData = employee_data?.length === 0;
    return (
        <Flex column gap="gap.large">
            <Flex styles={{ marginTop: "24px" }}>
                <Title content={"Solde actuel"} />
            </Flex>

            {isEmptyData ? (
                <Flex
                    style={{
                        marginBottom: "25px",
                        color: "#5B5FC7",
                        fontWeight: "bold",
                    }}
                    hAlign="center"
                >
                    <Text content="Aucun solde disponible" />
                </Flex>
            ) : (
                <Flex gap="gap.large" wrap>
                    {employee_data.map(
                        ({
                            current_value,
                            name,
                            balance_value_to_remove,
                            period_end_date,
                            period_start_date,
                            balance_on_end_period,
                        }) => {
                            return (
                                <Card vAlign="center">
                                    <Flex gap="gap.small" vAlign="center">
                                        <CardCurrentValue
                                            content={current_value}
                                        />
                                        <Tooltip
                                            autoSize="width-always"
                                            content={
                                                <Flex column>
                                                    <Text
                                                        content={
                                                            <Trans
                                                                i18nKey={
                                                                    "timeline.tooltips.acquisition"
                                                                }
                                                            >
                                                                Période
                                                                d’acquisition :
                                                                du{" "}
                                                                <b>
                                                                    {{
                                                                        start_date:
                                                                            period_start_date,
                                                                    }}
                                                                </b>{" "}
                                                                au{" "}
                                                                <b>
                                                                    {{
                                                                        end_date:
                                                                            period_end_date,
                                                                    }}
                                                                </b>
                                                            </Trans>
                                                        }
                                                    />
                                                    <Text
                                                        content={
                                                            <Trans
                                                                i18nKey={
                                                                    "timeline.tooltips.prevision_solde"
                                                                }
                                                            >
                                                                Solde prévu au{" "}
                                                                <b>
                                                                    {{
                                                                        end_date:
                                                                            period_end_date,
                                                                    }}
                                                                </b>{" "}
                                                                (dernier jour de
                                                                la période) :{" "}
                                                                <b>
                                                                    {{
                                                                        days: balance_on_end_period,
                                                                    }}{" "}
                                                                    j
                                                                </b>
                                                            </Trans>
                                                        }
                                                    />
                                                </Flex>
                                            }
                                            trigger={
                                                <InfoIcon
                                                    outline
                                                    variables={{
                                                        color: "var(--color-primary)",
                                                        style: {
                                                            cursor: "pointer",
                                                        },
                                                    }}
                                                    size="large"
                                                />
                                            }
                                            subtle={false}
                                            pointing
                                        />
                                    </Flex>
                                    <Flex>
                                        <LeaveTypeName content={name} />
                                    </Flex>
                                    <Flex>
                                        {balance_value_to_remove !== 0 && (
                                            <PendingDays
                                                content={t(
                                                    "timeline.pending_days",
                                                    {
                                                        balance_value_to_remove,
                                                    }
                                                )}
                                            />
                                        )}
                                    </Flex>
                                </Card>
                            );
                        }
                    )}
                </Flex>
            )}
        </Flex>
    );
};

export default Content;

const PendingDays = styled(Text)`
    color: #cc4a31;

    /* Small/400 */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

const LeaveTypeName = styled(Text)`
    color: #424242;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
`;

const CardCurrentValue = styled(Text)`
    color: #5b5fc7;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
`;

const Title = styled(Text)`
    color: #242424;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;

const Card = styled(Flex)`
    border-left: 2px solid #f0f0f0;
    display: flex;
    width: 116px;
    padding: 8px 0px 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
`;

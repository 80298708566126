import { ROUTES } from "../../../constants/routes";

export const BREADCRUMB_TIME_REPORTING_DEFAULT = [
    {
        label: "breadcrumb.home",
        href: ROUTES.home,
    },
    {
        label: "breadcrumb.time_reporting",
        href: ROUTES.time_reporting.home,
    },
];

export const I18N_NS_TIME_REPORTING = "time_reporting";
export const LAYOUT_MAX_WIDTH = "1363px";
export const BAR_GRAPH_MONTH_SUBMIT = 150;

import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
    height: 24px;
    padding: 4px 8px;

    border-radius: 4px;
    background: ${({ background }) => (background ? background : "#ededed")};
`;

export const WeekText = styled(Text)`
    color: #242424;

    /* Small/400 */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

import React from "react";
import { AddIcon, Button, Dialog } from "@fluentui/react-northstar";
import DialogAddManualTaskTemplate from "./DialogAddManualTaskTemplate";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { string } from "prop-types";
import { DialogStyle } from "../style";

const FormDialogAddManualTaskTemplate = ({
    setTemplateTasks,
    setToastConfig,
}) => {
    const [open, setOpen] = useState(false);

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            saved_files: [],
            task_to_validate: false,
            document_action: 5,
            duedate_operator: 1,
        },
    });

    const onSubmit = (data) => {
        const {
            name,
            description,
            category_id,
            duedate_duration,
            duedate_operator,
            duedate_event,
            task_to_validate,
            document_action,
            saved_files,
            document_required,
            assigned_to,
            validator,
        } = data;

        // save this into the state
        const format_data = {
            id: new Date().getTime(),
            name,
            description,
            category_id,
            duedate_duration,
            duedate_operator,
            duedate_event: duedate_event?.id,
            task_to_validate,
            validator_type: validator
                ? validator?.id < 0
                    ? validator?.id
                    : -4
                : null,
            validator_user_id: validator
                ? validator?.id < 0
                    ? 0
                    : validator?.id
                : null,
            assigned_to_type: assigned_to?.id < 0 ? assigned_to?.id : -4, // les 3 premieres, et le 4 c'est pour les autres collaborateurs.
            assigned_to_user_id: assigned_to?.id < 0 ? 0 : assigned_to?.id, // id user choisit sauf les 3 premiers c'est 0.
            document_action,
            new_doc_ids: saved_files.map((el) => el.id),
            temp: data,
            manual: true,
        };
        setTemplateTasks((state) => [...state, format_data]);
        setToastConfig((state) => ({
            ...state,
            visible: true,
            content: "La tâche a bien été ajoutée",
            level: "success",
        }));
        methods.reset();
        hideDialog();
    };

    const hideDialog = () => {
        setOpen(false);
        methods.reset();
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <DialogStyle
                    open={open}
                    header={"Ajouter une tâche"}
                    confirmButton={"Ajouter la tâche"}
                    onConfirm={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        methods.handleSubmit(onSubmit)();
                    }}
                    onCancel={hideDialog}
                    cancelButton={"Annuler"}
                    content={
                        <DialogAddManualTaskTemplate
                            setTemplateTasks={setTemplateTasks}
                        />
                    }
                    trigger={
                        <Button
                            tinted
                            content="Ajouter une tâche"
                            icon={<AddIcon />}
                            flat
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpen(true);
                            }}
                        />
                    }
                    styles={{ width: "764px" }}
                />
            </form>
        </FormProvider>
    );
};

export default FormDialogAddManualTaskTemplate;

import React from "react";

import Login_page_img from "./img/Login_page_img.svg";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../constants/responsive";
const StaticLeftContent = () => {
    return (
        <LeftContent>
            <Flex column hAlign="center">
                <TitleLoginPage content="Capitalisez sur l’environnement Microsoft 365®" />
                <DescriptionLoginPage content="Profitez d’un outil de gestion RH complet basé sur Microsoft Teams®" />
                <Flex hAlign="center">
                    <Image src={Login_page_img} />
                </Flex>
            </Flex>
        </LeftContent>
    );
};

export default StaticLeftContent;

const TitleLoginPage = styled(Text)`
    min-width: 594px;
    min-height: 106px;
    /* left: 92px;
    top: 126px; */
    padding: 40px 52px 24px 56px;

    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 53px;

    color: #000000;
`;

const DescriptionLoginPage = styled(Text)`
    /* width: 517px;
    height: 64px; */
    padding: 24px 129px 12px 56px;

    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    color: #000000;
`;

const LeftContent = styled(Flex)`
    min-width: 50vw;
    height: 100vh;

    background: linear-gradient(
        180deg,
        #89c6ff 0%,
        rgba(193, 223, 251, 0.1) 100%
    );
    @media ${DEVICE.laptopL} {
        align-items: center;
    }
`;

import React from "react";
import styled from "styled-components";
import {
    Alert,
    Avatar,
    ErrorIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import { DEVICE } from "../../constants/responsive";

const UserAvatar = ({ image = "", content = "" }) => {
    return (
        <Flex fill grow gap="gap.small" style={{ marginTop: "5px" }}>
            <Flex.Item>
                <Avatar size="smallest" image={image} />
            </Flex.Item>
            <Flex.Item>
                <AvatarName>{content}</AvatarName>
            </Flex.Item>
        </Flex>
    );
};

const BolderLabel = ({
    label = "",
    value = "",
    breakLine = false,
    information,
    danger = false, // pour afficher la couleur rouge sur la date d'echeance
}) => {
    return (
        <BolderStyle vAlign="center" gap="gap.small" fill>
            <Flex vAlign="center" fill>
                <Stronger>{label}</Stronger> {information}
            </Flex>
            {breakLine && <br />}
            <Flex vAlign="center" gap="gap.smaller">
                <Text
                    content={value}
                    styles={{
                        color: danger ? "var(--color-danger)" : "inherit",
                    }}
                />
                {danger && <ErrorIcon />}
            </Flex>
        </BolderStyle>
    );
};

const TaskTitle = ({ children }) => {
    return <TextStyle>{children}</TextStyle>;
};

const TaskType = ({ content = "default" }) => {
    return content;
};

const DetailModalTasks = ({ children }) => {
    return (
        <Container fill grow column gap={"gap.medium"}>
            {children}
        </Container>
    );
};

DetailModalTasks.TaskType = TaskType;
DetailModalTasks.TaskTitle = TaskTitle;
DetailModalTasks.BolderLabel = BolderLabel;
DetailModalTasks.UserAvatar = UserAvatar;

export default DetailModalTasks;

const AvatarName = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #252525;
`;
const Stronger = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
`;
const BolderStyle = styled(Flex)`
    color: #424242;
    width: fit-content;
`;
const TextStyle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
`;

const Container = styled(Flex)`
    @media ${DEVICE.mobileS} {
    }
    @media ${DEVICE.laptop} {
    }
`;

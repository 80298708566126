import create from "zustand";

const useSidebar = create((set) => ({
    activeNav: "home",
    toggleSideBar: true,
    setActiveNav: (nav) => set({ activeNav: nav }),
    setToggleActiveNav: () =>
        set((state) => ({ toggleSideBar: !state.toggleSideBar })),
}));

export default useSidebar;

import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components/macro";
import { BASE_URL_ILLUSTRATIONS } from "../../constants";

const EmptyTaskToValidate = () => {
    return (
        <Flex
            column
            vAlign="center"
            hAlign="center"
            gap="gap.medium"
            grow
            fill
            styles={{
                paddingTop: "50px",
            }}
        >
            <Image src={`${BASE_URL_ILLUSTRATIONS}/Tasks-No_task.svg`} />
            <Flex column hAlign="center" gap="gap.small">
                <Label>Aucune tâche à valider</Label>
                <Message>
                    Vous n’avez pas de tâche à valider, <br />
                    sounds good !
                </Message>
            </Flex>
        </Flex>
    );
};

export default EmptyTaskToValidate;

const Message = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #242424;
`;

const Label = styled(Text)`

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
`;

import { Flex, Text } from "@fluentui/react-northstar";
import styled, { css } from "styled-components";

export const Wrapper = styled(Flex)`
    overflow: hidden;
    width: fit-content;
`;

export const Cell = styled(Flex)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    width: ${({ $ctx_show_weekend }) =>
        $ctx_show_weekend ? "141px" : "200px"};
    height: 48px;

    border: 1px solid var(--Light-theme-Rest-Border-Default-Border, #d1d1d1);
    gap: 5px;
    justify-content: center;

    /* selected cell */
    ${({ $selected }) =>
        $selected
            ? css`
                  background-color: #e8ebfa;
              `
            : ""}

    /* hover */
    ${({ $isClickable }) =>
        $isClickable
            ? css`
                  :hover {
                      background-color: #e8ebfa;
                      cursor: pointer;
                  }
              `
            : ""}

    /* jour ferié */
    ${({ $leave, $leaveColor }) =>
        $leave
            ? css`
                  position: relative;
                  background-color: ${$leaveColor ? $leaveColor : "#fcf4f6"};
                  z-index: 0;
                  ::after {
                      content: "Absence";
                      right: 8px;
                      top: 4px;
                      position: absolute;

                      color: var(
                          --Light-theme-Rest-Foreground-Default-Foreground-2,
                          #616161
                      );

                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px; /* 142.857% */
                  }
              `
            : ""}

    /* jour ferié */
    ${({ $holiday }) =>
        $holiday
            ? css`
                  position: relative;
                  background-color: #fbf6d9;
                  z-index: 0;
                  ::after {
                      content: "Ferié";
                      right: 8px;
                      top: 4px;
                      position: absolute;

                      color: var(
                          --Light-theme-Rest-Foreground-Default-Foreground-2,
                          #616161
                      );

                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px; /* 142.857% */
                  }
              `
            : ""}
`;

export const CellHead = styled(Cell)`
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    ${({ $today }) =>
        $today
            ? css`
                  background-color: var(--color-primary);
                  color: white;
              `
            : ""}
`;

export const Username = styled(Text)`
    color: #000;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

export const CellFirstColumn = styled(Cell)`
    width: 230px;
    justify-content: center;
    align-items: center;
    ${({ $active }) =>
        $active
            ? css`
                  border-radius: 1px;
                  border: 2px solid
                      var(--Light-theme-Rest-Border-Brand-Border-1, #9299f7);
                  background: var(
                      --Light-theme-Active-Foreground-Silver-Foreground-Active,
                      #fff
                  );
                  ${Username} {
                      font-weight: 700;
                  }
              `
            : ""}
`;
export const CollapsableRow = styled(Flex)`
    height: ${({ $closed }) => ($closed ? "0px" : "auto")};
    transition: height 0.3s;
`;

import React, { useState } from "react";
import styled from "styled-components";
import {
    Flex,
    Text,
    Input,
    Dropdown,
    Datepicker,
    Tooltip,
    Checkbox,
    Divider,
    TextArea,
    Button,
    FilesUploadIcon,
    FlexItem,
} from "@fluentui/react-northstar";
import { useQuery } from "react-query";

import CustomDropzone from "../../../components/dropzone";
import SliderTabs from "../../../components/shared/SliderTabs";
import { DEVICE } from "../../../constants/responsive";

import {
    getTaskCategory,
    getTaskResponsable,
    getTaskResponsables,
    getTaskValidators,
} from "../../../api/tasks";
import { TASK_ACTIONS } from "../../../constants/tasks";
import moment from "moment";
import { useStepperContext } from "./ContextAddCollaborateur";

const ModalModifyTask = ({ form, setForm, savedFiles, setSavedFiles }) => {
    const { firstStep } = useStepperContext((state) => state);

    const [taskRequiredValidation, setTaskRequiredValidation] = useState(
        form.task_to_validate
    );
    const [taskRequiredAction, setTaskRequiredAction] = useState(
        form.document_required
    );
    const [action, setAction] = useState(form.document_action);
    const [sliderTab, setSliderTab] = useState(form.type);

    // get all categories
    const { data: dataCategory = [] } = useQuery(["getCategory"], () =>
        getTaskCategory()
    );
    // get all collaborateur users.
    let { data: dataCollaborator = [] } = useQuery(["getCollaborator"], () =>
        getTaskResponsables()
    );
    let { data: dataValidators = [] } = useQuery(["getValidators"], () =>
        getTaskValidators()
    );
    // format the data of the collaborator for fluentui
    dataCollaborator = dataCollaborator.map((el) => {
        return {
            header: el.username,
            image:
                el.avatar ||
                "https://asenditbackenddevstorage.blob.core.windows.net/documents/asendit/users/3/profile_photo/profile-pic_JrDx8BL.png?se=2022-08-30T13%3A42%3A00Z&sp=r&sv=2021-06-08&sr=b&sig=HqmF5Vqyiku5yzmLKIpcAViJwice%2BPw42DfQ5YmgLko%3D",
            content: el.job_title,
        };
    });
    dataValidators = dataValidators.map((el) => {
        return {
            header: el.username,
            image:
                el.avatar ||
                "https://asenditbackenddevstorage.blob.core.windows.net/documents/asendit/users/3/profile_photo/profile-pic_JrDx8BL.png?se=2022-08-30T13%3A42%3A00Z&sp=r&sv=2021-06-08&sr=b&sig=HqmF5Vqyiku5yzmLKIpcAViJwice%2BPw42DfQ5YmgLko%3D",
            content: el.job_title,
        };
    });
    // format the data select Collaborator - and add to it the current user :D
    let dataCollaboratorWithCurrentUser = [...dataCollaborator];
    dataCollaboratorWithCurrentUser = [
        {
            header: firstStep.email,
            image: "",
            content: "",
        },
        ...dataCollaboratorWithCurrentUser,
    ];
    // default document action formating informations
    let DEFAULT_ACTION = TASK_ACTIONS.filter(
        (el) => el.id === form.document_action
    );
    DEFAULT_ACTION = DEFAULT_ACTION.length ? DEFAULT_ACTION[0].header : "";
    return (
        <Flex column gap="gap.medium">
            <FlexItemSlider>
                <SliderTabs
                    tabs={["onboarding", "suivi", "offboarding"]}
                    value={sliderTab}
                    onChange={(value) => {
                        setSliderTab(value);
                        setForm({ ...form, type: value });
                    }}
                    defaultActiveIndex={form.type}
                />
            </FlexItemSlider>
            <Flex>
                <TextTitle>Consignes</TextTitle>
            </Flex>
            <Flex.Item>
                <FlexDescription>
                    <Flex.Item>
                        <Input
                            label="Titre de la tâche"
                            placeholder="Donnez un titre évocateur"
                            fluid
                            onChange={(e) =>
                                setForm({ ...form, name: e.target.value })
                            }
                            defaultValue={form.name}
                        />
                    </Flex.Item>
                    <Flex.Item>
                        <DropdownStyle
                            items={dataCategory?.map((el) => el.name)}
                            placeholder="Catégorie de la tâche"
                            defaultValue={form.category_name}
                            checkable
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            fluid
                            styles={{
                                width: "100%",
                            }}
                            onChange={(_, option) => {
                                setForm({
                                    ...form,
                                    category_id:
                                        dataCategory[option.highlightedIndex]
                                            .id,
                                    category_name: option.value,
                                });
                            }}
                        />
                    </Flex.Item>
                </FlexDescription>
            </Flex.Item>
            <Flex gap="gap.large" vAlign="end">
                <Flex.Item>
                    <Flex column fill>
                        <Input.Label>Descriptif de la tâche</Input.Label>
                        <TextArea
                            label="Descriptif de la tâche"
                            placeholder="Ajouter des indications ici"
                            fluid
                            defaultValue={form.description}
                            onChange={(e) =>
                                setForm({
                                    ...form,
                                    description: e.target.value,
                                })
                            }
                        />
                    </Flex>
                </Flex.Item>
            </Flex>
            <Divider />
            <Flex>
                <TextTitle>Dates</TextTitle>
            </Flex>
            <FlexDates>
                <Flex.Item>
                    <Datepicker
                        today={new Date()}
                        defaultSelectedDate={new Date(form.due_date)}
                        calendar={{
                            calendarCell: {
                                children: (ComponentType, props) =>
                                    props.today ? (
                                        <Tooltip
                                            content="TODAY!"
                                            trigger={
                                                <ComponentType {...props} />
                                            }
                                        />
                                    ) : (
                                        <ComponentType {...props} />
                                    ),
                            },
                        }}
                        inputPlaceholder="Date limite"
                        onDateChange={(_, el) =>
                            setForm({
                                ...form,
                                due_date: moment(el.value).format("YYYY-MM-DD"),
                            })
                        }
                    />
                </Flex.Item>
            </FlexDates>
            <Divider />
            <Flex>
                <TextTitle>Personnes concernées</TextTitle>
            </Flex>
            <FlexConcerne>
                <Flex.Item>
                    <DropdownStyle
                        items={dataCollaboratorWithCurrentUser}
                        placeholder="Qui va effectuer la tâche ?"
                        checkable
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                        defaultValue={form.assigned_to}
                        onChange={(_, option) => {
                            setForm({
                                ...form,
                                assigned_to: option.value.header,
                            });
                        }}
                        fluid
                        styles={{
                            width: "100%",
                        }}
                    />
                </Flex.Item>
                <Flex.Item>
                    <Text>pour</Text>
                </Flex.Item>
                <Flex.Item>
                    <DropdownStyle
                        items={[
                            {
                                header: firstStep.email,
                                image: firstStep.email,
                                content: "",
                            },
                        ]}
                        placeholder="Personne impactée"
                        checkable
                        defaultValue={form.related_to}
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                        fluid
                        onChange={(_, option) => {
                            setForm({
                                ...form,
                                related_to: option.value.header,
                            });
                        }}
                    />
                </Flex.Item>
            </FlexConcerne>
            <Flex>
                <Checkbox
                    label="Tâche à faire valider"
                    toggle
                    onChange={(e, { checked }) => {
                        setTaskRequiredValidation(checked);
                        setForm({
                            ...form,
                            task_to_validate: checked,
                            validator_email: "",
                        });
                    }}
                    checked={taskRequiredValidation}
                />
            </Flex>
            {taskRequiredValidation && (
                <Flex>
                    <DropdownStyle
                        items={dataValidators}
                        placeholder="Qui va valider la tâche ?"
                        checkable
                        defaultValue={form.validator_email}
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                        onChange={(_, i) =>
                            setForm({
                                ...form,
                                validator_email: i.value.header,
                            })
                        }
                    />
                </Flex>
            )}
            <Divider />
            <Flex>
                <TextTitle>Réalisation de la tâche</TextTitle>
            </Flex>
            <Flex>
                <Checkbox
                    label="Document requis pour compléter la tâche"
                    toggle
                    onChange={(e, { checked }) => {
                        setTaskRequiredAction(checked);
                        setForm({ ...form, document_required: checked });
                    }}
                    checked={taskRequiredAction}
                />
            </Flex>
            {taskRequiredAction && (
                <Flex>
                    <DropdownStyle
                        items={TASK_ACTIONS}
                        placeholder="Action à réaliser"
                        checkable
                        defaultValue={DEFAULT_ACTION}
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`,
                        }}
                        onChange={(_, i) => {
                            setForm({ ...form, document_action: i.value.id });
                        }}
                        fluid
                    />
                </Flex>
            )}
            {action !== "envoyer un document" && (
                <>
                    <FlexDropzone>
                        <CustomDropzone
                            showInformationFile={false}
                            savedFiles={savedFiles}
                            setSavedFiles={setSavedFiles}
                        />
                    </FlexDropzone>
                    <FlexDropzoneMobile>
                        <Button
                            flat
                            fluid
                            icon={<FilesUploadIcon size="small" />}
                            content="importer un document"
                            iconPosition="before"
                        />
                    </FlexDropzoneMobile>
                </>
            )}
        </Flex>
    );
};

export default ModalModifyTask;

const FlexDropzoneMobile = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: flex;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
`;

const FlexDropzone = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: flex;
    }
`;

const DropdownStyle = styled(Dropdown)`
    @media ${DEVICE.mobileS} {
        width: 100%;
    }
    @media ${DEVICE.tablet} {
        width: auto;
    }
`;

const FlexConcerne = styled(Flex)`
    align-items: center;
    gap: 16px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        ${DropdownStyle} {
            width: 100%;
        }
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const FlexDates = styled(Flex)`
    gap: 16px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const FlexItemSlider = styled(FlexItem)`
    @media ${DEVICE.mobileS} {
        margin-top: 16px;
    }
    @media ${DEVICE.tablet} {
        margin-top: 0px;
    }
    @media ${DEVICE.laptop} {
        margin-top: 0px;
    }
`;

const FlexDescription = styled(Flex)`
    gap: 16px;
    align-items: flex-end;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const TextTitle = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

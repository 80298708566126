import React from "react";
import {
    Accordion,
    Alert,
    Checkbox,
    Flex,
    InfoIcon,
    Loader,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import Badge from "../badges";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { _api_get_model } from "../../api";
import {
    _checkedChildrensTemplate,
    _checkedHeaderListTemplate,
} from "../../utils";
import {
    f_set_templates,
    f_set_templates_last_updated,
    useTemplates,
} from "../../stores";
import { I18N_NS_ADD_EMPLOYEE } from "../../constants";

const _formatJsonTemplateToFuiPanels = (templates = []) => {
    const initJson = templates.map((el) => {
        return {
            key: "key_" + el?.id,
            title: (
                <Flex
                    key={"title_" + el?.id}
                    vAlign="center"
                    styles={{
                        justifyContent: "space-between",
                        padding: "10px 15px 10px 0px",
                    }}
                >
                    <Flex
                        gap="gap.small"
                        vAlign="center"
                        styles={{ marginLeft: "5px" }}
                    >
                        <Checkbox
                            label={
                                <Text styles={{ fontWeight: "bold" }}>
                                    {el?.name}
                                </Text>
                            }
                            onClick={_checkedHeaderListTemplate}
                            styles={{
                                height: "fit-content",
                                padding: "0px!important",
                            }}
                            className="Accordion__checkbox--header"
                            data={el}
                            checked={el?.checked}
                        />
                        <Tooltip
                            content={el?.description}
                            trigger={<InfoIcon outline />}
                        />
                    </Flex>
                    <Badge content={el?.type} />
                </Flex>
            ),
            content: (
                <Flex column key={"content_" + el?.id}>
                    {el?.tasks?.map((child) => {
                        return (
                            <Checkbox
                                key={child?.id}
                                label={<Text>{child?.name}</Text>}
                                onClick={_checkedChildrensTemplate}
                                styles={{
                                    borderBottom:
                                        "1px solid rgba(219, 219, 219, 0.5)",
                                    paddingBlock: "14px",
                                }}
                                className="Accordion__checkbox--children"
                                data-parent={el}
                                data-children={child}
                                checked={child?.checked}
                            />
                        );
                    })}
                </Flex>
            ),
        };
    });

    return initJson;
};

export const ContentImportTemplates = () => {
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    const templates = useTemplates();
    const { isLoading, isFetching } = useQuery(
        "getTemplates",
        () => {
            return _api_get_model(1);
        },
        {
            // enabled: templates?.length === 0,
            onSuccess: (data) => {
                f_set_templates(data);
                f_set_templates_last_updated(data);
            },
        }
    );
    const formatedTemplatesJson = _formatJsonTemplateToFuiPanels(templates);

    return (
        <Flex column gap="gap.medium" styles={{ marginTop: "10px" }}>
            <Flex column gap="gap.medium">
                <Text
                    content={t(
                        "pages.add_employee.step_tasks.dialog.description"
                    )}
                />
                <Alert
                    icon={<InfoIcon />}
                    content={
                        <Text
                            styles={{ fontWeight: "600" }}
                            content={t(
                                "pages.add_employee.step_tasks.dialog.alert"
                            )}
                        />
                    }
                />
            </Flex>
            <Flex column>
                {isLoading || isFetching ? (
                    <Loader label="Chargement des données !" />
                ) : (
                    <AccordionStyle
                        panels={formatedTemplatesJson}
                        key={"accordion"}
                    />
                )}
            </Flex>
        </Flex>
    );
};

export const AccordionStyle = styled(Accordion)`
    .ui-accordion__title {
        padding-bottom: 0px;
    }
    .Accordion__checkbox--header {
        padding-bottom: 10px;
    }
    .ui-accordion__title__content-wrapper > .ui-box {
        background-repeat: no-repeat;
        /* background-position-y: 5px; */
    }
    .ui-accordion__title__content-wrapper {
        background-color: #e8ebfa;
    }
`;

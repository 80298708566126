import React, { useEffect } from "react";
import { CloseIcon, Flex } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { CalendarEdit16Regular } from "@fluentui/react-icons";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { ButtonModify } from "../CardWeek/index.style";
import { DialogStyled } from "./index.style";
import Content from "./Content";
import { useReducer } from "react";
import { ACTION_TYPES_SLOTS, reducerSlots } from "../../reducers/reducer.slots";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { _api_format_slot, _api_update_day_slot } from "../../api";
import { ACTION_TYPES_TEMPLATE } from "../../reducers/reducer.template_week";
import moment from "moment";

function orderSlotsByStartTime(a, b) {
    const timeFormat = "HH:mm:ss";
    const startTimeA = moment(a.start_time, timeFormat);
    const startTimeB = moment(b.start_time, timeFormat);

    if (startTimeA.isBefore(startTimeB)) return -1;
    if (startTimeA.isAfter(startTimeB)) return 1;
    return 0;
}

const DialogModifyWeekTypeTemplate = ({
    shifts,
    openDefault = false,
    day_id,
    dispatchTypeTemplate,
    trigger = null,
    onCancel = () => null,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const [open, setOpen] = useState(openDefault);

    useEffect(() => {
        setOpen(openDefault);
    }, [openDefault]);

    const [state, dispatch] = useReducer(reducerSlots, shifts);

    const { mutate, isLoading } = useMutation((data) => _api_format_slot(data));

    const fields_contains_errors = state
        ?.filter((slot) => slot?.type?.value !== -1)
        .some((slot) => slot?.errors?.f1 || slot?.errors?.f2);

    return (
        <DialogStyled
            open={open}
            cancelButton={{
                content: t("common.cancel"),
                flat: true,
            }}
            confirmButton={{
                content: t("v2.home.dialogs.buttons.save_this_day"),
                flat: true,
                loading: isLoading,
                disabled: fields_contains_errors,
            }}
            onConfirm={(e) => {
                e.preventDefault();
                // reorder the data to send it to the back for format
                // * remove the break elements.
                // * reorder the array slots asc start_time
                const without_breaks = state?.filter(
                    (slot) => slot?.type?.value !== -1
                );

                const asc_slots_by_start_time = without_breaks?.sort(
                    orderSlotsByStartTime
                );
                mutate(
                    { slots: asc_slots_by_start_time, day_id },
                    {
                        onSuccess: (data) => {
                            const new_state = data?.slots?.map(
                                (slot, index) => {
                                    return {
                                        id: index,
                                        day_id: slot.day_id,
                                        start_time: slot.start_time,
                                        end_time: slot.end_time,
                                        duration_str: slot.duration_str,
                                        type: slot?.type ?? null,
                                    };
                                }
                            );
                            dispatch({
                                type: ACTION_TYPES_SLOTS.set_shifts,
                                payload: {
                                    shifts: new_state,
                                },
                            });
                            if (dispatchTypeTemplate) {
                                const format_slots = data?.slots?.map(
                                    (slot, index) => ({
                                        ...slot,
                                        id: index,
                                        start_time: slot.start_time,
                                        end_time: slot.end_time,
                                        duration_str: slot.duration_str,
                                    })
                                );
                                dispatchTypeTemplate({
                                    type: ACTION_TYPES_TEMPLATE.update_slots_day,
                                    payload: {
                                        id: day_id,
                                        slots: format_slots,
                                        duration_total: data?.duration_total,
                                    },
                                });
                            }
                            setOpen(false);
                            onCancel();
                        },
                    }
                );
            }}
            onCancel={() => {
                onCancel();
                return setOpen(false);
            }}
            content={<Content shifts={state} dispatch={dispatch} />}
            header={t("v2.home.dialogs.titles.modify_one_day")}
            headerAction={{
                icon: <CloseIcon />,
                onClick: () => {
                    return setOpen(false);
                },
            }}
            trigger={
                trigger ? (
                    <Flex onClick={() => setOpen(true)}>{trigger}</Flex>
                ) : (
                    <ButtonModify
                        icon={<CalendarEdit16Regular />}
                        iconOnly
                        flat
                        size="small"
                        onClick={() => setOpen(true)}
                    />
                )
            }
        />
    );
};

export default DialogModifyWeekTypeTemplate;

import React from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import {
    COLUMN_LEFT_WIDTH,
    COLUMN_LEFT_WIDTH_MOBILE,
} from "../Timeline/constants";
import { DEVICE } from "../../constants/responsive";

const WeekBadge = ({ content }) => {
    return (
        <Wrapper vAlign="center" hAlign="center">
            <WeekNumber content={content} />
        </Wrapper>
    );
};

export default WeekBadge;

const Wrapper = styled(Flex)`
    height: 16px;
    padding: 2px 6px;
    position: sticky;
    border-radius: 100px;
    background: #ebebeb;
    @media ${DEVICE.mobileS} {
        left: ${COLUMN_LEFT_WIDTH_MOBILE + "px"} !important;
    }
    @media ${DEVICE.laptop} {
        left: ${COLUMN_LEFT_WIDTH + "px"} !important;
    }
`;

const WeekNumber = styled(Text)`
    color: #242424;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
`;

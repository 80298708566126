import { Skeleton } from "@fluentui/react-northstar";
import React from "react";

const LoadingData = () => {
    return new Array(7).fill("")?.map((week) => {
        return (
            <Skeleton animation="pulse">
                <Skeleton.Line width="110px" height="20px" />
            </Skeleton>
        );
    });
};

export default LoadingData;

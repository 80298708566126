import React from "react";
import {
    Button,
    Flex,
    MeetingTimeIcon,
    NotesIcon,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";

function Footer({
    isRefuseDisabled = false,
    isApproveDisabled = false,
    onApprove,
    onReject,
    isApproving,
    isRejecting,
}) {
    return (
        <FooterStyle hAlign="end">
            <Flex gap="gap.medium">
                <ButtonStyle
                    disabled={isRefuseDisabled}
                    iconPosition="after"
                    text
                    flat
                    icon={<MeetingTimeIcon />}
                    content={"Refuser les feuilles de temps"}
                    onClick={onReject}
                    loading={isRejecting}
                />
                <Button
                    iconPosition="after"
                    disabled={isApproveDisabled}
                    icon={<NotesIcon outline />}
                    primary
                    content="Approuver les feuilles de temps"
                    onClick={onApprove}
                    loading={isApproving}
                />
            </Flex>
        </FooterStyle>
    );
}

export default Footer;

const ButtonStyle = styled(Button)`
    color: #c4314b;
    :disabled {
        color: #c7c7c7;
    }
`;

const FooterStyle = styled(Flex)`
    padding: 24px;
`;

import React, { useEffect } from "react";
import { useState } from "react";
import PersonnalInformation from "../PersonnalInformation";
import IllustrationInfoPerso from "../../../../../../assets/illustrations/employee_folder/info_perso_folder_employee.svg";
import Card from "../..";
import {
    Button,
    Flex,
    Input,
    InputLabel,
    RadioGroup,
    TextArea,
} from "@fluentui/react-northstar";
import SETUP_PROFILE, { QUERY_KEY } from "../../../../constants";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "../../../../../addEmployee/styles";
import { useMutation, useQueryClient } from "react-query";
import { _api_update_personal_information } from "../../../../api";
import { ROLES_CODE, roles_access } from "../../../../../../constants/roles";

const CardInformationPersonnal = ({ identity_data }) => {
    const clientQuery = useQueryClient();
    const isHR = roles_access({
        usersPrivileges: [ROLES_CODE.hr],
    });

    const [modeUpdate, setModeUpdate] = useState(false);

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        reset,
        getValues,
        setValue,
        trigger,
    } = useForm({
        mode: "onChange",
        defaultValues: {},
    });

    const {
        mutate: mutate_update_personnal_information,
        isLoading: is_loading_mutate,
    } = useMutation((data) => _api_update_personal_information(data));

    const _setModeUpdate = (bool) => {
        reset();
        return setModeUpdate(bool);
    };

    const onSubmit = (data) => {
        mutate_update_personnal_information(
            {
                gender: data?.gender,
                first_name: data?.first_name,
                birth_name: data?.birth_name,
                last_name: data?.last_name,
                address: data?.address,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        clientQuery.resetQueries([QUERY_KEY + "::identity"]);
                        clientQuery.refetchQueries(QUERY_KEY + "::header");
                        _setModeUpdate(false);
                    }
                    return;
                },
            }
        );
        return console.log({ data });
    };

    if (modeUpdate) {
        return (
            <Card column>
                <Card.EditTitle
                    content={"Modification des informations personnelles"}
                    onClose={() => _setModeUpdate(false)}
                />
                <Card.Body
                    content={
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex column gap="gap.medium">
                                <Flex column gap="gap.small">
                                    <InputLabel content={"Civilité"} />
                                    <Controller
                                        control={control}
                                        name="gender"
                                        defaultValue={identity_data?.gender}
                                        render={({
                                            field: { onChange, onBlur },
                                        }) => (
                                            <RadioGroup
                                                {...register("gender")}
                                                defaultCheckedValue={
                                                    identity_data?.gender
                                                }
                                                items={SETUP_PROFILE.GENDER.map(
                                                    (gender) => {
                                                        return {
                                                            name: gender.name,
                                                            label: gender.name,
                                                            value: gender.id,
                                                        };
                                                    }
                                                )}
                                                onBlur={onBlur}
                                                onCheckedValueChange={(_, i) =>
                                                    onChange(i?.value)
                                                }
                                            />
                                        )}
                                    />
                                </Flex>
                                <Flex column>
                                    <Input
                                        {...register("first_name", {
                                            required:
                                                "Ce champ est obligatoire",
                                        })}
                                        error={errors?.first_name?.message}
                                        label="Prénom*"
                                        defaultValue={identity_data?.first_name}
                                        fluid
                                    />
                                    <ErrorMessage
                                        content={errors?.first_name?.message}
                                    />
                                </Flex>
                                <Flex column>
                                    <Controller
                                        control={control}
                                        name="birth_name"
                                        render={({
                                            field: { onChange, onBlur },
                                        }) => (
                                            <Input
                                                {...register("birth_name", {
                                                    required:
                                                        "Ce champ est obligatoire",
                                                })}
                                                error={
                                                    errors?.birth_name?.message
                                                }
                                                label="Nom*"
                                                onChange={(_, i) => {
                                                    // setValue(
                                                    //     "birth_name",
                                                    //     i?.value
                                                    // );
                                                    onChange(i?.value);
                                                    trigger();
                                                    return;
                                                }}
                                                onBlur={(i) => {
                                                    const last_name =
                                                        getValues("last_name");

                                                    if (!last_name) {
                                                        setValue(
                                                            "last_name",
                                                            i?.target?.value
                                                        );
                                                    }
                                                    return;
                                                }}
                                                defaultValue={
                                                    identity_data?.birth_name
                                                }
                                                fluid
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        content={errors?.birth_name?.message}
                                    />
                                </Flex>
                                <Flex column>
                                    <Controller
                                        control={control}
                                        name="last_name"
                                        render={({
                                            field: { onChange, onBlur },
                                        }) => (
                                            <Input
                                                {...register("last_name", {
                                                    required:
                                                        "Ce champ est obligatoire",
                                                })}
                                                error={
                                                    errors?.last_name?.message
                                                }
                                                label="Nom d'usage*"
                                                defaultValue={
                                                    identity_data?.last_name
                                                }
                                                onChange={(_, i) => {
                                                    return onChange(i?.value);
                                                }}
                                                onFocus={() => {
                                                    return trigger("last_name");
                                                }}
                                                fluid
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        content={errors?.last_name?.message}
                                    />
                                </Flex>
                                <Flex column>
                                    <InputLabel content="Adresse" />
                                    <TextArea
                                        defaultValue={identity_data?.address}
                                        {...register("address")}
                                    />
                                </Flex>
                                <Flex>
                                    <Input
                                        label={{
                                            content: "Email personnel",
                                            styles: {
                                                color: "#C7C7C7",
                                            },
                                        }}
                                        defaultValue={
                                            identity_data?.personal_email
                                        }
                                        fluid
                                        disabled
                                    />
                                </Flex>
                                <Flex>
                                    <Input
                                        label={{
                                            content: "Email professionnel",
                                            styles: {
                                                color: "#C7C7C7",
                                            },
                                        }}
                                        defaultValue={identity_data?.email}
                                        fluid
                                        disabled
                                    />
                                </Flex>
                                <Flex hAlign="center" gap="gap.medium">
                                    <Button
                                        content={"Annuler"}
                                        onClick={() => _setModeUpdate(false)}
                                        flat
                                    />
                                    <Button
                                        content={
                                            "Enregistrer les modifications"
                                        }
                                        // onClick={
                                        //     mutate_update_personnal_information
                                        // }
                                        loading={is_loading_mutate}
                                        primary
                                        flat
                                    />
                                </Flex>
                            </Flex>
                        </form>
                    }
                />
            </Card>
        );
    }

    return (
        <Card column>
            {/* <Card.Illustration
                image={IllustrationInfoPerso}
                canEdit={isHR}
               
            /> */}
            <Card.Title
                content={"Informations personnelles"}
                canEdit={isHR}
                onEdit={() => {
                    if (!isHR) {
                        return;
                    }
                    _setModeUpdate(true);
                }}
            />
            {/* info perso */}
            <Card.Body
                content={
                    <PersonnalInformation
                        gender={identity_data?.gender}
                        first_name={identity_data?.first_name}
                        last_name={identity_data?.last_name}
                        birth_name={identity_data?.birth_name}
                        address={identity_data?.address}
                        personal_phone_number={
                            identity_data?.personal_phone_number
                        }
                        personal_email={identity_data?.personal_email}
                        phone_number={identity_data?.phone_number}
                        email={identity_data?.email}
                        linkedin={identity_data?.linkedin}
                    />
                }
            />
        </Card>
    );
};

export default CardInformationPersonnal;

import create from "zustand";
import produce from "immer";
import { persist } from "zustand/middleware";

export const useEmployeeFolder = create(
    persist(
        (set) => ({
            id_user: null,
            is_me: null,
            setIdUser: (id_user) => {
                set(
                    produce((state) => {
                        state.id_user = id_user;
                    })
                );
            },
            setIsMe: (bool) => {
                set(
                    produce((state) => {
                        state.is_me = bool;
                    })
                );
            },
        }),
        {
            name: "employee_folder",
        }
    )
);

export const useEmployeeFolderIdUser = () =>
    useEmployeeFolder((state) => state.id_user);

export const useEmployeeFolderIsMe = () =>
    useEmployeeFolder((state) => state.is_me);

export const useEmployeeFolderSetIdUser = () =>
    useEmployeeFolder((state) => state.setIdUser);

export const useEmployeeFolderSetIsMe = () =>
    useEmployeeFolder((state) => state.setIsMe);

import moment from "moment";
import { _is_today } from "../../../utils/functions";
import { TYPE_EVENT } from "../constants";
import { useShowRemoteWeekend } from "../store/useShowRemoteWeekend";
import { CURRENT_ISOWEEK, CURRENT_YEAR } from "../constants/logic";

/**
 *
 * @param {Object} prev_state
 * @param {"increment" | "decrement" | "set"} type
 * @param {number} new_iso_week - si type === "set" alors new_iso_week est obligatoire

 */
const increment_or_decrement_week = (
    prev_state,
    type = "increment",
    new_iso_week = null
) => {
    const m = moment()
        .set("year", prev_state.year)
        .isoWeek(
            type === "set"
                ? new_iso_week
                : type === "increment"
                ? prev_state.iso_week + 1
                : prev_state.iso_week - 1
        );

    const month_iso = m.isoWeekday(1);
    const start_date_week = month_iso.format("DD MMMM");
    const week = m.isoWeek();
    const end_date_week = m.isoWeekday(7).format("DD MMMM");
    const year = month_iso.year();

    return {
        week,
        year,
        start_date_week,
        end_date_week,
        month_iso,
    };
};

const set_week = (week, year) => {
    const m = moment().set("year", year).isoWeek(week);

    const month_iso = m.isoWeekday(1);
    const start_date_week = month_iso.format("DD MMMM");
    const new_week = m.isoWeek();
    const end_date_week = m.isoWeekday(7).format("DD MMMM");
    const new_year = month_iso.year();

    return {
        week: new_week,
        year: new_year,
        start_date_week,
        end_date_week,
        month_iso,
    };
};

export const get_days_of_isoweek = ({ year, iso_week }) => {
    const days = [];

    const startOfWeek = moment()
        .isoWeekYear(year)
        .isoWeek(iso_week)
        .startOf("isoWeek");

    for (let i = 0; i < 7; i++) {
        const day = startOfWeek.clone().add(i, "days");
        days.push({
            id: i + 1,
            label: day.format("dddd DD"),
            day: day.format("DD"),
            date: day.format("YYYY-MM-DD"),
            is_today: _is_today({
                day: day.format("DD"),
                year: day.format("YYYY"),
                month: day.format("MM"),
            }),
        });
    }
    // id : number, label : string, is_today : boolean
    return days;
};

function isDateInRange(startDate, endDate, dateToCheck) {
    // Convertir les dates en objets Moment.js
    const start = moment(startDate);
    const end = moment(endDate);
    const check = moment(dateToCheck);

    // Vérifier si la date est entre les deux autres dates
    return check.isBetween(start, end, null, "[]"); // '[]' pour inclure les bords, '()' pour les exclure
}

export const doIHaveLeave = ({ leaves, date_head }) => {
    const found_leave = leaves.filter((leave) => {
        return isDateInRange(leave.start_date, leave.end_date, date_head);
    });
    return {
        value: found_leave.length,
        color: found_leave?.at(0)?.leave_type?.color ?? "inherit",
    };
};

export const functions = {
    increment_week: (setState) => {
        return setState((prev_state) => {
            const { week, year, start_date_week, end_date_week, month_iso } =
                increment_or_decrement_week(prev_state, "increment");

            return {
                ...prev_state,
                iso_week: week,
                year: year,
                week_text_from_to: `${start_date_week} - ${end_date_week}`,
                start_date_week: month_iso.format("YYYY-MM-DD"),
                calendar: {
                    list_days: get_days_of_isoweek({
                        year,
                        iso_week: week,
                    }),
                },
            };
        });
    },
    decrement_week: (setState) => {
        return setState((prev_state) => {
            const { week, year, start_date_week, end_date_week, month_iso } =
                increment_or_decrement_week(prev_state, "decrement");

            return {
                ...prev_state,
                iso_week: week,
                year: year,
                week_text_from_to: `${start_date_week} - ${end_date_week}`,
                start_date_week: month_iso.format("YYYY-MM-DD"),
                calendar: {
                    list_days: get_days_of_isoweek({
                        year,
                        iso_week: week,
                    }),
                },
            };
        });
    },
    on_datepicker_change: (_, i, setState) => {
        const value_date = i.value;
        const new_iso_week = moment(value_date).isoWeek();

        return setState((prevState) => {
            const { week, year, start_date_week, end_date_week, month_iso } =
                increment_or_decrement_week(prevState, "set", new_iso_week);
            return {
                ...prevState,
                iso_week: week,
                year: year,
                week_text_from_to: `${start_date_week} - ${end_date_week}`,
                start_date_week: month_iso.format("YYYY-MM-DD"),
                calendar: {
                    list_days: get_days_of_isoweek({
                        year,
                        iso_week: week,
                    }),
                },
            };
        });
    },
    format_response_to_rows: (response, list_days) => {
        const departments = response.departments;
        const holidays = response.holidays;
        const loop_length = 7;

        const seven_days = new Array(loop_length).fill("");
        const format = departments.map((department) => {
            return {
                ...department,
                users: department.users.map((user) => {
                    const matrix = [
                        new Array(loop_length).fill(null),
                        new Array(loop_length).fill(null),
                    ];
                    return {
                        ...user,
                        out_of_office_items: seven_days.map(
                            (day, day_index) => {
                                const day_number =
                                    list_days?.at(day_index)?.day;
                                const date_head =
                                    list_days?.at(day_index)?.date;
                                const is_holiday = holidays.includes(date_head);
                                // chercher dans la liste du backend
                                const filtred = user.out_of_office_items.filter(
                                    (element) => {
                                        return (
                                            moment(element.start.date).format(
                                                "DD"
                                            ) === day_number
                                        );
                                    }
                                );
                                // voir si j'ai des absences
                                const leave = doIHaveLeave({
                                    leaves: user?.leave_items,
                                    date_head: date_head,
                                });

                                if (filtred?.length > 0) {
                                    const first_line = matrix.at(0);
                                    const second_line = matrix.at(1);

                                    if (filtred?.length === 2) {
                                        // y'a deux event dans la même cellule
                                        const new_first_line = [...first_line];
                                        // new_first_line[day_index] = "X";
                                        // matrix[0] = new_first_line;
                                        const new_second_line = [
                                            ...second_line,
                                        ];
                                        // new_second_line[day_index] = "X";
                                        // matrix[1] = new_second_line;

                                        // NBR DAYS FOR THE FIRST EVENT
                                        const nbr_days_first_event =
                                            moment(filtred.at(0).end.date).diff(
                                                filtred.at(0).start.date,
                                                "days"
                                            ) + 1;

                                        for (
                                            let o = day_index;
                                            o <
                                            day_index + nbr_days_first_event;
                                            o++
                                        ) {
                                            new_first_line[o] = "X";
                                        }
                                        matrix[0] = new_first_line;

                                        // NBR DAYS FOR THE SECOND EVENT
                                        const nbr_days_second_event =
                                            moment(filtred.at(1).end.date).diff(
                                                filtred.at(1).start.date,
                                                "days"
                                            ) + 1;

                                        for (
                                            let o = day_index;
                                            o <
                                            day_index + nbr_days_second_event;
                                            o++
                                        ) {
                                            new_second_line[o] = "X";
                                        }
                                        matrix[1] = new_second_line;

                                        return [
                                            {
                                                ...filtred.at(0),
                                                formated: {
                                                    id_event: filtred.at(0).id,
                                                    nbr_days:
                                                        nbr_days_first_event,
                                                    type:
                                                        filtred.at(0)?.reason
                                                            ?.value === 1
                                                            ? TYPE_EVENT.remote
                                                            : TYPE_EVENT.travelling,
                                                    $holiday: is_holiday,
                                                    $leave:
                                                        leave?.value ?? false,
                                                    $leave_color:
                                                        leave?.color ??
                                                        "inherit",
                                                    position: {
                                                        top: true,
                                                        bottom: false,
                                                    },
                                                },
                                            },
                                            {
                                                ...filtred.at(1),
                                                formated: {
                                                    id_event: filtred.at(1).id,
                                                    nbr_days:
                                                        nbr_days_second_event,
                                                    type:
                                                        filtred.at(1)?.reason
                                                            ?.value === 1
                                                            ? TYPE_EVENT.remote
                                                            : TYPE_EVENT.travelling,
                                                    $holiday: is_holiday,
                                                    $leave:
                                                        leave?.value ?? false,
                                                    $leave_color:
                                                        leave?.color ??
                                                        "inherit",
                                                    position: {
                                                        top: false,
                                                        bottom: true,
                                                    },
                                                },
                                            },
                                        ];
                                    } else {
                                        // Y a un seul event dans une cellule
                                        const nbr_days =
                                            moment(filtred.at(0).end.date).diff(
                                                filtred.at(0).start.date,
                                                "days"
                                            ) + 1;

                                        const current_coordinate = {
                                            top: first_line.at(day_index),
                                            bottom: second_line.at(day_index),
                                        };

                                        let top = false;
                                        let bottom = false;
                                        if (current_coordinate.top !== "X") {
                                            // la premiere ligne est vide
                                            // donc placer directement en haut
                                            top = true;
                                            const new_first_line = [
                                                ...first_line,
                                            ];
                                            for (
                                                let o = day_index;
                                                o < day_index + nbr_days;
                                                o++
                                            ) {
                                                new_first_line[o] = "X";
                                            }
                                            matrix[0] = new_first_line;
                                        } else if (
                                            current_coordinate.bottom !== "X"
                                        ) {
                                            bottom = true;
                                            const new_second_line = [
                                                ...second_line,
                                            ];
                                            for (
                                                let o = day_index;
                                                o < day_index + nbr_days;
                                                o++
                                            ) {
                                                new_second_line[o] = "X";
                                            }
                                            matrix[1] = new_second_line;
                                        }

                                        return {
                                            ...filtred.at(0),
                                            formated: {
                                                id_event: filtred.at(0).id,
                                                nbr_days,
                                                type:
                                                    filtred.at(0)?.reason
                                                        ?.value === 1
                                                        ? TYPE_EVENT.remote
                                                        : TYPE_EVENT.travelling,
                                                $holiday: is_holiday,
                                                $leave: leave?.value ?? false,
                                                $leave_color:
                                                    leave?.color ?? "inherit",
                                                position: {
                                                    top,
                                                    bottom,
                                                },
                                            },
                                        };
                                    }
                                } else {
                                    const calendar_head_date =
                                        list_days?.at(day_index)?.date;
                                    if (day_index === 0) {
                                        // c'est le premier jour
                                        const search_event =
                                            user.out_of_office_items.filter(
                                                (element) => {
                                                    return moment(
                                                        calendar_head_date
                                                    ).isAfter(
                                                        element.start.date
                                                    );
                                                }
                                            );
                                        const first_line = matrix.at(0);
                                        const second_line = matrix.at(1);
                                        if (search_event.length) {
                                            // y'a un event qui vient de la semaine passée
                                            const nbr_days =
                                                moment(
                                                    search_event.at(0).end.date
                                                ).diff(
                                                    calendar_head_date,
                                                    "days"
                                                ) + 1;

                                            const current_coordinate = {
                                                top: first_line.at(day_index),
                                                bottom: second_line.at(
                                                    day_index
                                                ),
                                            };

                                            let top = false;
                                            let bottom = false;
                                            if (
                                                current_coordinate.top !== "X"
                                            ) {
                                                // la premiere ligne est vide
                                                // donc placer directement en haut
                                                top = true;
                                                const new_first_line = [
                                                    ...first_line,
                                                ];
                                                for (
                                                    let o = day_index;
                                                    o < nbr_days + 1;
                                                    o++
                                                ) {
                                                    new_first_line[o] = "X";
                                                }
                                                matrix[0] = new_first_line;
                                            } else if (
                                                current_coordinate.bottom !==
                                                "X"
                                            ) {
                                                bottom = true;
                                                const new_second_line = [
                                                    ...second_line,
                                                ];
                                                for (
                                                    let o = day_index;
                                                    o < nbr_days + 1;
                                                    o++
                                                ) {
                                                    new_second_line[o] = "X";
                                                }
                                                matrix[1] = new_second_line;
                                            }

                                            return {
                                                ...search_event.at(0),
                                                formated: {
                                                    id_event:
                                                        search_event.at(0).id,
                                                    nbr_days,
                                                    type:
                                                        search_event.at(0)
                                                            ?.reason?.value ===
                                                        1
                                                            ? TYPE_EVENT.remote
                                                            : TYPE_EVENT.travelling,
                                                    $holiday: is_holiday,
                                                    $leave:
                                                        leave?.value ?? false,
                                                    $leave_color:
                                                        leave?.color ??
                                                        "inherit",
                                                    position: {
                                                        top,
                                                        bottom,
                                                    },
                                                },
                                            };
                                        }
                                    }
                                    return {
                                        formated: {
                                            $holiday: is_holiday,
                                            $leave: leave?.value ?? false,
                                            $leave_color:
                                                leave?.color ?? "inherit",
                                        },
                                    };
                                }
                            }
                        ),
                    };
                }),
            };
        });

        return format;
    },
    set_week: ({ start_date, current_iso_week, setState, refetch }) => {
        const moment_clone = moment(start_date).clone();
        const new_year = moment_clone.year();
        const start_date_iso_week = moment_clone.isoWeek();
        console.log({ start_date_iso_week, current_iso_week });
        if (start_date_iso_week === current_iso_week) {
            // refetch data directly
            // alert("je refetch");
            refetch();
        } else {
            return setState((prev_state) => {
                const {
                    week,
                    year,
                    start_date_week,
                    end_date_week,
                    month_iso,
                } = set_week(start_date_iso_week, new_year);
                return {
                    ...prev_state,
                    iso_week: week,
                    year: year,
                    week_text_from_to: `${start_date_week} - ${end_date_week}`,
                    start_date_week: month_iso.format("YYYY-MM-DD"),
                    calendar: {
                        list_days: get_days_of_isoweek({
                            year,
                            iso_week: week,
                        }),
                    },
                };
            });
        }
    },
};

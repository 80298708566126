import React from "react";
import { Flex } from "@fluentui/react-northstar";
import LabelView from "../../../LabelView";

const QualificationAndJobContent = ({
    job_title,
    contract_type,
    contract_status,
    coefficient,
    position,
    contract_country,
}) => {
    const is_fr = contract_country?.toLowerCase() === "fr";

    return (
        <Flex fill column gap="gap.medium">
            <LabelView label="Poste" content={job_title} />
            <LabelView label="Type de contrat" content={contract_type?.name} />
            <LabelView
                label={is_fr ? "Statut" : "CSP"}
                content={contract_status?.name}
            />
            {is_fr && (
                <>
                    <LabelView label="Position" content={position} />
                    <LabelView label="Coefficient" content={coefficient} />
                </>
            )}
        </Flex>
    );
};

export default QualificationAndJobContent;

import React, { useState } from "react";
import { Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { ContainerFluid, Container } from "../../../../common/styles";
import Header from "../../components/manager/Weekdetail/Header";
import WeekBody from "../../components/manager/Weekdetail/WeekBody";
import { useNavigate, useParams } from "react-router-dom";
import { INIT_REDUCER, SLIDER_DIRECTION } from "../../constants";
import { useQuery } from "react-query";
import {
    _api_manager_time_reporting_by_week,
    _api_time_reporting,
} from "../../api";
import moment from "moment";
import { getWeekStartAndEnd } from "../../components/calendars/CalendarWeek";

const WeekDetail = () => {
    const navigate = useNavigate();

    const { id_week } = useParams();

    const [month, setMonth] = useState({ ...INIT_REDUCER, iso_week: id_week });

    const { data, isLoading, isFetching, refetch } = useQuery(
        ["TIME_REPORTING_WEEK", month.iso_week],
        () =>
            _api_manager_time_reporting_by_week({
                year: month.year,
                week: month.iso_week,
            }),
        {
            refetchOnWindowFocus: false,
        }
    );

    const { start, end } = getWeekStartAndEnd({
        year: month.year,
        week: month.iso_week,
    });

    const _decrementWeek = () => {
        return setMonth((state) => {
            const m = moment().isoWeek(parseInt(state.iso_week) - 1);
            const start = m.isoWeekday(1).format("DD MMMM YYYY");
            const month = m.month();
            const end = m.isoWeekday(7).format("DD MMMM YYYY");
            const week = m.isoWeek();
            const year = m.year();

            return {
                dates: {
                    start,
                    end,
                },
                iso_week: week,
                month_indexed: month,
                year,
                direction: SLIDER_DIRECTION.backward,
            };
        });
    };

    const _incrementWeek = () => {
        return setMonth((state) => {
            const m = moment().isoWeek(parseInt(state.iso_week) + 1);
            const start = m.isoWeekday(1).format("DD MMMM YYYY");
            const month = m.month();
            const end = m.isoWeekday(7).format("DD MMMM YYYY");
            const week = m.isoWeek();
            const year = m.year();

            return {
                dates: {
                    start,
                    end,
                },
                iso_week: week,
                month_indexed: month,
                year,
                direction: SLIDER_DIRECTION.forward,
            };
        });
    };

    const goBack = () => {
        return navigate(-1);
    };

    return (
        <ContainerFluid>
            <Container fill column>
                <Flex vAlign="center" hAlign="center" space="between">
                    <Title content="Suivi hebdomadaire de mon équipe" />
                    <Button onClick={goBack} primary text content="Retour" />
                </Flex>

                <Content gap="gap.large" column>
                    <Header
                        isLoading={isLoading}
                        week_start={start}
                        week_end={end}
                        iso_week={month.iso_week}
                        onIncrementWeek={_incrementWeek}
                        onDecrementWeek={_decrementWeek}
                    />
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <WeekBody
                            isLoading={isLoading}
                            days={data?.month?.days}
                            users={data?.users}
                        />
                    )}
                </Content>
            </Container>
        </ContainerFluid>
    );
};

export default WeekDetail;

const Title = styled(Text)`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    margin-block: 24px;
    color: #242424;
`;

const Content = styled(Flex)`
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.11),
        0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
    /* border: 1px solid red; */
    background-color: white;
    padding: 24px;
`;

import React, { useState } from "react";

import { Button, CloseIcon, Dialog, InfoIcon } from "@fluentui/react-northstar";
import { FormProvider, useForm } from "react-hook-form";
import { ClipboardTask24Regular } from "@fluentui/react-icons";
import DialogAddIndividualTaskContent from "./DialogAddIndividualTaskContent";
import { useMutation } from "react-query";
import { api_create_individual_task } from "../../../api/tasks";
import { string } from "prop-types";
import ButtonBreadCrumb from "../../../components/buttons";
import { queryClient } from "../../..";
import { DialogStyle } from "../style";

const DialogAddIndividualTask = ({ setShowAlert, open, setOpen }) => {
    // const [open, setOpen] = useState(openDialog);
    const { mutate: api_post_create_task, isLoading: isLoadingCreationTask } =
        useMutation(
            (data) => {
                // format the data before send.
                const format_data = {
                    name: data?.name,
                    description: data?.description,
                    due_date: data?.due_date,
                    assigned_to: data?.assigned_to?.username,
                    related_to: data?.related_to,
                    task_to_validate: data?.task_to_validate,
                    validator_email: data?.who_validate,
                    type: data?.type || 1,
                    category_id: data?.category,
                    document_required: data?.document_required ?? false,
                    document_action:
                        data?.document_action === 5 ? 0 : data?.document_action,
                    new_doc_ids: data?.saved_files.map((el) => el?.id),
                };
                return api_create_individual_task(format_data);
            },
            {
                onSuccess: (data) => {
                    setOpen(false);
                    if (data?.success) {
                        // show success alert
                        queryClient.refetchQueries(["getAllTasksForTable"]);
                        queryClient.refetchQueries(["getAllTasksOfEmployee"]); // pour l'onboarding
                        setShowAlert(() => {
                            return {
                                visible: true,
                                message: "La tâche à bien été ajoutée.",
                                level: "success",
                            };
                        });
                    } else {
                        // show alert error
                        setShowAlert({
                            visible: true,
                            message:
                                "Une erreur est survenue lors de la création de la tâche.",
                            level: "danger",
                        });
                    }
                },
                onError: () => {
                    // show alert error
                    setShowAlert({
                        visible: true,
                        message:
                            "Une erreur est survenue lors de la création de la tâche.",
                        level: "danger",
                        icon: <InfoIcon />,
                    });
                    setOpen(false);
                },
            }
        );

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            saved_files: [],
            task_to_validate: false,
            document_action: 5,
            duedate_operator: 1,
        },
    });

    const onSubmit = (data) => {
        if (data?.document_required && data?.saved_files.length === 0) {
            methods.setError("document_action", {
                type: string,
                message:
                    "Le document est obligatoire. Merci d'ajouter le document associé à cette action.",
            });
            return;
        }
        api_post_create_task(data);
        methods.reset();
        return;
    };

    const openDialog = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
        methods.clearErrors();
    };

    const closeDialog = () => {
        setOpen(false);
        methods.clearErrors();
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <DialogStyle
                    open={open}
                    header={"Ajouter une tâche"}
                    headerAction={<CloseIcon onClick={closeDialog} />}
                    onCancel={closeDialog}
                    cancelButton="Annuler"
                    confirmButton={{
                        content: "Ajouter la tâche",
                        loading: isLoadingCreationTask,
                    }}
                    onConfirm={() => {
                        return methods.handleSubmit(onSubmit)();
                    }}
                    content={<DialogAddIndividualTaskContent />}
                />
            </form>
        </FormProvider>
    );
};

export default DialogAddIndividualTask;

import React, { useEffect, useState } from "react";
import { Badge } from "@fluentui/react-components";
import {
    Flex,
    Table,
    tableHeaderCellBehavior,
    ArrowSortIcon,
    FilterIcon,
    Loader,
    Avatar,
    Button,
    Text,
    Dialog,
} from "@fluentui/react-northstar";
import {
    TextSortAscending16Regular,
    ArrowSortDown16Filled,
} from "@fluentui/react-icons";

import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import {
    BADGE_STATUS_TASKS,
    SORT_BY_STATUS,
    render_badge_action_document_by_id,
} from "../../../../constants/tasks";
import { QUERY_KEY, TABS_SWITCHER } from "../../constants";
import { _api_get_list_tasks_hr } from "../../../../api/tasks";
import moment from "moment";
import { TextCategory } from "../index.style";
import AvatarUser from "../../../../components/Avatars";
import TasksActions from "./TasksActions";
import TaskProcessus from "../../../../components/Badge/TaskProcessus";
import styled from "styled-components";
import DialogDetailtask from "./DialogDetailTask";
import Toast from "../../../../components/Alerts";
import { DialogStyle, TableLabel } from "../../style";
import { DEVICE } from "../../../../constants/responsive";
import { useNavigate } from "react-router-dom";

const ROW_PER_PAGE = 10;

const sortByName = ({ pages, whereSorted, setSortedTasks, setWhereSorted }) => {
    const all_tasks = pages.map((page) => page.tasks).flat();

    let filter_all_tasks = [];
    if (whereSorted?.name === 1) {
        // asc
        filter_all_tasks = all_tasks?.sort((a, b) =>
            b.name.localeCompare(a.name)
        );
    } else {
        filter_all_tasks = all_tasks?.sort((a, b) =>
            a.name.localeCompare(b.name)
        );
    }

    const new_state = pages.map((page, index) => {
        const start = index * ROW_PER_PAGE;
        const end = index * ROW_PER_PAGE + ROW_PER_PAGE;
        return {
            ...page,
            tasks: filter_all_tasks.slice(start, end),
        };
    });

    setSortedTasks(new_state);
    setWhereSorted((state) => {
        const old_name = state?.name;
        return {
            ...state,
            name: old_name === 0 ? 1 : old_name === 1 ? 2 : 1,
        };
    });
    return;
};

const sortByEndDate = ({
    pages,
    whereSorted,
    setSortedTasks,
    setWhereSorted,
}) => {
    const all_tasks = pages.map((page) => page.tasks).flat();
    let filter_all_tasks = [];
    if (whereSorted?.end_date === 1) {
        // asc
        filter_all_tasks = all_tasks?.sort(
            (a, b) => new Date(a.due_date) - new Date(b.due_date)
        );
    } else {
        filter_all_tasks = all_tasks?.sort(
            (a, b) => new Date(b.due_date) - new Date(a.due_date)
        );
    }
    const new_state = pages.map((page, index) => {
        const start = index * ROW_PER_PAGE;
        const end = index * ROW_PER_PAGE + ROW_PER_PAGE;
        return {
            ...page,
            tasks: filter_all_tasks.slice(start, end),
        };
    });

    setSortedTasks(new_state);
    setWhereSorted((state) => {
        const old_end_date = state?.end_date;
        return {
            ...state,
            end_date: old_end_date === 0 ? 1 : old_end_date === 1 ? 2 : 1,
        };
    });
};

const sortByStatus = ({
    pages,
    whereSorted,
    setSortedTasks,
    setWhereSorted,
}) => {
    const all_tasks = pages.map((page) => page.tasks).flat();
    let filter_all_tasks = [];
    if (whereSorted?.status === 1) {
        // asc
        filter_all_tasks = all_tasks?.sort(
            (a, b) => SORT_BY_STATUS[a.status] - SORT_BY_STATUS[b.status]
        );
    } else {
        filter_all_tasks = all_tasks?.sort(
            (a, b) => SORT_BY_STATUS[b.status] - SORT_BY_STATUS[a.status]
        );
    }
    const new_state = pages.map((page, index) => {
        const start = index * ROW_PER_PAGE;
        const end = index * ROW_PER_PAGE + ROW_PER_PAGE;
        return {
            ...page,
            tasks: filter_all_tasks.slice(start, end),
        };
    });

    setSortedTasks(new_state);
    setWhereSorted((state) => {
        const old_status = state?.status;
        return {
            ...state,
            status: old_status === 0 ? 1 : old_status === 1 ? 2 : 1,
        };
    });
};

const ArrowSortDown = (
    <ArrowSortDown16Filled style={{ color: "var(--color-primary)" }} />
);

const ViewTableHR = ({
    isLoading,
    isFetching,
    data,
    isFetchingNextPage,
    fetchNextPage,
    onDeleteTask = () => null,
}) => {
    if (isLoading) {
        return (
            <Flex fill hAlign="center">
                <Loader label="Chargement des tâches" />
            </Flex>
        );
    }

    const navigate = useNavigate();

    const [sortedTasks, setSortedTasks] = useState([]);
    //* SORT TYPE
    //* 0 - no sort,
    //* 1 - ASC,
    //* 2 - DESC.
    const [whereSorted, setWhereSorted] = useState({
        name: 0,
        status: 0,
        end_date: 0,
    });

    useEffect(() => {
        return setSortedTasks(data?.pages);
    }, [data]);

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const queryClient = useQueryClient();

    const last_page = data?.pages[data?.pages?.length - 1];

    const currentPageToShow = last_page?.next_page_value
        ? (last_page?.next_page_value - 1) * ROW_PER_PAGE
        : data?.pages[0]?.all_tasks_count;

    return (
        <WhiteBoard fill column>
            {isFetching && <Text content="chargement des données .." />}

            <div style={{ width: "100%", overflow: "scroll" }}>
                <TableStyle aria-label="table">
                    {/* Head */}
                    <Table.Row header>
                        <Table.Cell
                            content={
                                <Flex
                                    gap="gap.smaller"
                                    vAlign="center"
                                    onClick={() =>
                                        sortByEndDate({
                                            pages: sortedTasks,
                                            setSortedTasks,
                                            whereSorted,
                                            setWhereSorted,
                                        })
                                    }
                                    styles={{ cursor: "pointer" }}
                                >
                                    <Text weight="regular">Échéance</Text>
                                    {ArrowSortDown}
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "126px" }}
                        />
                        <Table.Cell
                            content={
                                <Flex
                                    gap="gap.smaller"
                                    vAlign="center"
                                    onClick={() =>
                                        sortByStatus({
                                            pages: sortedTasks,
                                            setSortedTasks,
                                            whereSorted,
                                            setWhereSorted,
                                        })
                                    }
                                    styles={{ cursor: "pointer" }}
                                >
                                    <Text weight="regular">Statut</Text>
                                    {ArrowSortDown}
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "106px" }}
                        />

                        <Table.Cell
                            content={
                                <Flex gap="gap.smaller" vAlign="center">
                                    <Text weight="regular">À faire</Text>
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "160px" }}
                        />

                        <Table.Cell
                            content={
                                <Flex
                                    gap="gap.smaller"
                                    vAlign="center"
                                    onClick={() =>
                                        sortByName({
                                            pages: sortedTasks,
                                            setSortedTasks,
                                            whereSorted,
                                            setWhereSorted,
                                        })
                                    }
                                    styles={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <TableLabel
                                        bolder
                                        content="Nom de la tâche"
                                    />
                                    <TextSortAscending16Regular />
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "551px" }}
                        />
                        <Table.Cell
                            content={
                                <Flex gap="gap.smaller" vAlign="center">
                                    <Text weight="regular">Processus</Text>
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "171px" }}
                        />
                        <Table.Cell
                            content={
                                <Flex gap="gap.smaller" vAlign="center">
                                    <Text weight="regular">À réaliser par</Text>
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "200px" }}
                        />
                    </Table.Row>

                    {/* body */}
                    {sortedTasks?.map((array) => {
                        return array?.tasks?.map((row) => {
                            return (
                                <Table.Row
                                    styles={{ cursor: "pointer" }}
                                    onClick={() => {
                                        navigate(
                                            `/dashboard/tasks/${row?.id}/user/${row?.assigned_to_user?.id}/detail`,
                                            {
                                                state: {
                                                    referrer: `/dashboard/tasks?tab=${TABS_SWITCHER.MY_COMPANY_LIST}`,
                                                    isHrPage: true,
                                                },
                                            }
                                        );
                                    }}
                                >
                                    <Table.Cell
                                        content={
                                            row.due_date
                                                ? moment(row.due_date).format(
                                                      "DD/MM/YYYY"
                                                  )
                                                : ""
                                        }
                                        style={{ maxWidth: "126px" }}
                                    />

                                    <Table.Cell
                                        content={BADGE_STATUS_TASKS[row.status]}
                                        style={{ maxWidth: "106px" }}
                                    />
                                    <Table.Cell
                                        content={render_badge_action_document_by_id(
                                            row?.document_action
                                        )}
                                        style={{ maxWidth: "160px" }}
                                    />
                                    <Table.Cell
                                        content={
                                            <Flex
                                                gap="gap.smaller"
                                                vAlign="center"
                                            >
                                                <Text
                                                    weight="regular"
                                                    truncate={true}
                                                    wrap={false}
                                                    style={{
                                                        width: "520px",
                                                        fontWeight: 600,
                                                        textTransform:
                                                            "capitalize",
                                                        lineHeight: "20px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    {row.name}
                                                </Text>
                                            </Flex>
                                        }
                                        style={{ maxWidth: "551px" }}
                                    />
                                    <Table.Cell
                                        content={
                                            <TaskProcessus
                                                process_id={row?.type}
                                                minWidth="80px"
                                            />
                                        }
                                        style={{ maxWidth: "171px" }}
                                    />
                                    <Table.Cell
                                        content={
                                            <AvatarUser
                                                image={
                                                    row?.assigned_to_user
                                                        ?.avatar
                                                }
                                                name={
                                                    row?.assigned_to_user?.name
                                                }
                                                showName={true}
                                                size="small"
                                            />
                                        }
                                        style={{ maxWidth: "200px" }}
                                    />
                                </Table.Row>
                            );
                        });
                    })}
                </TableStyle>
            </div>

            <DialogStyle
                open={openDialog}
                header={selectedRowData?.name}
                content={
                    <DialogDetailtask
                        data={selectedRowData}
                        onDelete={() => {
                            setOpenDialog(false);
                            onDeleteTask();
                            queryClient.resetQueries([
                                QUERY_KEY + "::getListTasksHR",
                            ]);
                        }}
                    />
                }
                onCancel={() => setOpenDialog(false)}
            />
            <Footer column gap="gap.medium">
                <TextTasksLength
                    content={`${currentPageToShow} tâches affichées sur ${data?.pages[0].all_tasks_count}`}
                />
                {last_page?.next_page_value && (
                    <div>
                        <ButtonShowMoreTasks
                            content={"Afficher plus de tâches"}
                            loading={isFetchingNextPage}
                            onClick={() => fetchNextPage()}
                            flat
                        />
                    </div>
                )}
            </Footer>
        </WhiteBoard>
    );
};

export default ViewTableHR;

const ButtonShowMoreTasks = styled(Button)`
    background-color: #f0f0f0;
`;

const Footer = styled(Flex)`
    margin-top: 9px;
`;

const WhiteBoard = styled(Flex)`
    @media ${DEVICE.mobileS} {
        padding: 15px 0px 45px 40px;
    }
    @media ${DEVICE.laptop} {
        padding: 15px 40px 45px 40px;
    }
`;

const TextTasksLength = styled(Text)`
    color: #616161;

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
`;
const TableStyle = styled(Table)`
    @media ${DEVICE.mobileS} {
        width: fit-content;
    }
    @media ${DEVICE.mobileL} {
        width: fit-content;
    }
    @media ${DEVICE.laptop} {
        width: 100%;
    }
`;

import { Flex, Text } from "@fluentui/react-northstar";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { _str_capitalize } from "../../../../../utils/functions";

const WeekThead = ({ days }) => {
    return (
        <Flex gap="gap.large">
            <MembreStyle content="Membre" />
            {days?.map((el) => {
                const date = el?.date;
                // eslint-disable-next-line no-unsafe-optional-chaining
                const [year, month, day] = date.split("-");
                const month_indexed = month - 1;
                const moment_month = _str_capitalize(
                    moment()
                        .set("month", month_indexed)
                        .set("date", day)
                        .format("dddd DD MMMM")
                );
                return <DateStyle content={`${moment_month}`} />;
            })}
            <TotalStyle content="Total" />
        </Flex>
    );
};

export default WeekThead;

const DateStyle = styled(Text)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 120px;
    color: #616161;
`;

const MembreStyle = styled(Text)`
    width: 200px !important;
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
`;

const TotalStyle = styled(Text)`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
    width: 120px;
`;

import React from "react";
import Card from "..";
import { EMPTY_FIELD, QUERY_KEY } from "../../../constants";
import Img from "../../../../../assets/illustrations/employee_folder/about_employee_folder.svg";
import { useEmployeeFolderIsMe } from "../../../store";
import { useState } from "react";
import { Button, Flex, InputLabel, TextArea } from "@fluentui/react-northstar";
import { useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { _api_update_about_me } from "../../../api";

const CardAboutMe = ({ about_me }) => {
    const is_me = useEmployeeFolderIsMe();

    const ref_about_me = useRef(null);
    const clientQuery = useQueryClient();
    const [state, setState] = useState({
        edit: false,
        about_me,
    });

    const {
        isLoading: is_loading_save_about_me,
        mutate: mutate_update_about_me,
    } = useMutation((data) => _api_update_about_me(data));

    const _enable_edit = () => {
        if (!is_me) {
            return;
        }
        return setState((state) => {
            return {
                ...state,
                edit: true,
            };
        });
    };

    const _disable_edit = () => {
        return setState((state) => {
            return {
                ...state,
                edit: false,
            };
        });
    };

    const _submit_about_me_section = () => {
        const text = ref_about_me.current.value;
        mutate_update_about_me(
            {
                about_me: text,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        clientQuery.refetchQueries([QUERY_KEY + "::about"]);
                        return _disable_edit();
                    }
                },
            }
        );
        return;
    };

    if (state?.edit) {
        return (
            <Card column>
                <Card.EditTitle
                    content={"Modification du À propos"}
                    onClose={_disable_edit}
                />
                <Card.Body
                    content={
                        <Flex column gap="gap.medium">
                            <Flex column gap="gap.small">
                                <InputLabel content={"À propos"} />
                                <TextArea
                                    defaultValue={about_me}
                                    fluid
                                    ref={ref_about_me}
                                />
                            </Flex>
                            <Flex hAlign="center" gap="gap.medium">
                                <Button
                                    content={"Annuler"}
                                    onClick={_disable_edit}
                                    flat
                                />
                                <Button
                                    content={"Enregistrer les modifications"}
                                    onClick={_submit_about_me_section}
                                    loading={is_loading_save_about_me}
                                    primary
                                    flat
                                />
                            </Flex>
                        </Flex>
                    }
                />
            </Card>
        );
    }

    return (
        <Card column>
            {/* <Card.Illustration
                image={Img}
                positionTop={-55}
                canEdit={is_me}
                onEdit={_enable_edit}
            /> */}
            <Card.Title
                canEdit={is_me}
                content={"À propos"}
                onEdit={_enable_edit}
            />
            <Card.Body content={about_me ?? EMPTY_FIELD} />
        </Card>
    );
};

export default CardAboutMe;

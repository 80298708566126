import {
    HomeRegular,
    VehicleCarProfileRegular,
    VehicleCarProfileFilled,
    HomeFilled,
} from "@fluentui/react-icons";

/**
 * @readonly
 * @enum {string}
 */
export const TYPE_EVENT = {
    remote: "remote",
    travelling: "travelling",
    blank: "blank",
};

export const STYLES_EVENTS = {
    [TYPE_EVENT.remote]: {
        reason_id: 0,
        bg: "#EFF6FC",
        color: "#0078D4",
        icon: <HomeRegular color="var(--color-primary)" />,
        icon_white: <HomeFilled color="white" />,
        content: "Télétravail",
    },
    [TYPE_EVENT.travelling]: {
        reason_id: 1,
        bg: "#FBF6D9",
        color: "#E97548",
        icon: <VehicleCarProfileRegular color="#E97548" />,
        icon_white: <VehicleCarProfileFilled color="white" fontSize={20} />,
        content: "Déplacement",
    },
    [TYPE_EVENT.blank]: {
        reason_id: null,
        bg: "transparent",
        color: "transparent",
        icon: null,
        content: "",
    },
};

// times split day
export const TIMES_SPLIT_DAY = {
    "AM-S": "Début de matinée",
    "AM-E": "Fin de matinée",
    "PM-S": "Début d'après-midi",
    "PM-E": "Fin d'après-midi",
};

export const TIMES_SPLIT_DAY_TO_INDEX = {
    "AM-S": 0,
    "AM-E": 0,
    "PM-S": 1,
    "PM-E": 1,
};

export const WIDTH_COLUMN = 140; // in PX
export const WIDTH_COLUMN_LARGE = 197; // in PX

import React from "react";
import {
    ChatIcon,
    Flex,
    Text as TextTeams,
    Attachment,
    DownloadIcon,
    Text,
    Button,
    Dialog,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import moment from "moment";
import DetailModalTasks from "../../../../components/tasks/DetailModalTasks";
import {
    BADGE_STATUS_TASKS,
    TASK_STATUS,
    render_badge_action_document_by_id,
} from "../../../../constants/tasks";
import { file_size_format } from "../../../../constants/maths";
import { ICON_FROM_EXTENSION } from "../../../../utils/functions";
import { _api_download_file } from "../../../../api/manage_document";
import { DEVICE } from "../../../../constants/responsive";
import { useMutation } from "react-query";
import { _api_delete_task } from "../../../../api/tasks";
import { useState } from "react";

const DialogDetailtask = ({ data, onDelete }) => {
    const [openDialogDeleteConfirmation, setOpenDialogDeleteConfirmation] =
        useState(false);

    const { isLoading, mutate } = useMutation(
        (data) => _api_delete_task(data),
        {
            onSuccess: (data) => {
                if (data?.success) {
                    setOpenDialogDeleteConfirmation(false);
                    onDelete();
                    return;
                }
            },
        }
    );

    return (
        <DetailModalTasks open={"true"}>
            <Flex
                fill
                grow
                vAlign="center"
                gap="gap.small"
                styles={{ marginTop: "15px" }}
            >
                <Text
                    style={{
                        backgroundColor: "black",
                        fontSize: "10px",
                        fontWeight: "normal",
                        color: "white",
                        paddingInline: "4px 8px",
                        borderRadius: "15px",
                    }}
                >
                    {data.category.name}
                </Text>
            </Flex>
            <FlexRowBadge fill grow vAlign="center" gap="gap.large">
                <Flex.Item grow>
                    <DetailModalTasks.TaskType
                        content={BADGE_STATUS_TASKS[data?.status]}
                    />
                </Flex.Item>
                <Flex.Item>
                    {render_badge_action_document_by_id(data?.document_action)}
                </Flex.Item>
                <TextStyle grow>
                    <DetailModalTasks.BolderLabel
                        label="Echéance :"
                        value={data.due_date}
                    />
                </TextStyle>
            </FlexRowBadge>
            <Flex
                fill
                grow
                vAlign="center"
                gap="gap.large"
                style={{ marginBottom: "36px" }}
            >
                <Flex.Item grow>
                    <DetailModalTasks.BolderLabel
                        label="Descriptif :"
                        value={data.description || "/"}
                        breakLine
                    />
                </Flex.Item>
            </Flex>
            <FlexCreationValidation
                gap="gap.large"
                style={{ marginBottom: "0px" }}
            >
                <Flex.Item>
                    <DetailModalTasks.BolderLabel
                        label="Creation :"
                        information={moment(data.created_at).format(
                            "DD-MM-YYYY"
                        )}
                        value={
                            <Flex column>
                                <Flex.Item>
                                    <DetailModalTasks.UserAvatar
                                        image={data.creator.avatar}
                                        content={data.creator?.name}
                                    />
                                </Flex.Item>
                                <Flex
                                    grow
                                    gap="gap.smaller"
                                    justifyContent="center"
                                    vAlign="center"
                                    styles={{
                                        marginTop: "6px",
                                    }}
                                >
                                    <Flex.Item>
                                        <ChatIcon size="medium" outline />
                                    </Flex.Item>
                                    <Flex.Item>
                                        <TextTeams
                                            as="a"
                                            href={`MSTeams:/l/chat/0/0?users=${data.creator.email}`}
                                            color="brand"
                                            content={data.creator?.email}
                                            weight="semibold"
                                            styles={{
                                                outline: "0",
                                            }}
                                        />
                                    </Flex.Item>
                                </Flex>
                            </Flex>
                        }
                        breakLine
                    />
                </Flex.Item>
                {data?.task_to_validate && (
                    <Flex.Item>
                        <DetailModalTasks.BolderLabel
                            label="Validation :"
                            value={
                                <Flex column>
                                    <Flex.Item>
                                        <DetailModalTasks.UserAvatar
                                            image={data.validator.avatar}
                                            content={data.validator?.name}
                                        />
                                    </Flex.Item>
                                    <Flex
                                        grow
                                        gap="gap.smaller"
                                        justifyContent="center"
                                        vAlign="center"
                                        styles={{
                                            marginTop: "6px",
                                        }}
                                    >
                                        <Flex.Item>
                                            <ChatIcon size="medium" outline />
                                        </Flex.Item>
                                        <Flex.Item>
                                            <TextTeams
                                                as="a"
                                                href={`MSTeams:/l/chat/0/0?users=${data.validator.email}`}
                                                color="brand"
                                                content={data.validator?.email}
                                                weight="semibold"
                                                styles={{
                                                    outline: "0",
                                                }}
                                            />
                                        </Flex.Item>
                                    </Flex>
                                </Flex>
                            }
                            breakLine
                        />
                    </Flex.Item>
                )}
            </FlexCreationValidation>
            {/* documents parts */}
            <Flex vAlign="center" gap="gap.small" style={{ marginTop: "18px" }}>
                {data?.documents_template?.map((el) => {
                    return (
                        <Attachment
                            header={
                                <div
                                    style={{
                                        maxWidth: "320px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    <Text truncated content={el?.filename} />
                                </div>
                            }
                            description={file_size_format(el.filesize)}
                            icon={ICON_FROM_EXTENSION(el.filename)}
                            actionable
                            action={{
                                icon: <DownloadIcon />,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    return _api_download_file({
                                        id: el.id,
                                        filename: el.filename,
                                    });
                                },
                                title: "download",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                console.log({ el });
                                window.open(el.attached_doc);
                            }}
                        />
                    );
                })}
            </Flex>
            {/* if the task is required document then show dropzone */}
            {data?.document_required && data.status === TASK_STATUS.DONE && (
                <Flex column gap="gap.small">
                    <Text styles={{ fontWeight: "600" }}>
                        {data?.documents.length > 0 ? "Document envoyé" : ""}
                    </Text>
                    {data?.documents?.map((el) => {
                        return (
                            <Attachment
                                header={
                                    <div
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            width: "300px",
                                        }}
                                    >
                                        <Text
                                            truncated
                                            content={el?.name}
                                            title={el?.name}
                                        />
                                    </div>
                                }
                                description={file_size_format(el.filesize)}
                                icon={ICON_FROM_EXTENSION(el.name)}
                                actionable
                                action={{
                                    icon: <DownloadIcon />,
                                    onClick: (e) => {
                                        e.stopPropagation();
                                        return _api_download_file({
                                            id: el.id,
                                            filename: el.filename,
                                        });
                                    },
                                    title: "download",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(el.attached_doc);
                                }}
                            />
                        );
                    })}
                </Flex>
            )}
            <Flex styles={{ marginTop: "10px" }}>
                <Dialog
                    styles={{
                        width: "700px",
                    }}
                    open={openDialogDeleteConfirmation}
                    header="Êtes-vous sûr(e) de vouloir supprimer la tâche ?"
                    confirmButton={{
                        content: "Supprimer",
                        flat: true,
                        styles: {
                            backgroundColor: "var(--color-danger)",
                        },
                        onClick: (e) => {
                            e.preventDefault();
                            mutate({
                                id_user: data?.assigned_to_user?.id,
                                task_id: data?.id,
                            });
                        },
                        loading: isLoading,
                    }}
                    cancelButton={{
                        content: "Annuler",
                        flat: true,
                    }}
                    content={
                        <Text
                            content={`La tâche “${data?.name}” sera supprimée et les données seront définitivement perdues.`}
                        />
                    }
                    onCancel={() => setOpenDialogDeleteConfirmation(false)}
                    trigger={
                        <ButtonDeleteTask
                            text
                            content="Supprimer la tâche"
                            onClick={() =>
                                setOpenDialogDeleteConfirmation(true)
                            }
                        />
                    }
                />
            </Flex>
        </DetailModalTasks>
    );
};

export default DialogDetailtask;

const ButtonDeleteTask = styled(Button)`
    color: #c4314b;
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration-line: underline;
`;

const FlexCreationValidation = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 20px;
    }
    @media ${DEVICE.mobileM} {
        flex-direction: column;
        gap: 20px;
    }
    @media ${DEVICE.mobileL} {
        flex-direction: column;
        gap: 20px;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        gap: 20px;
    }
`;

const FlexRowBadge = styled(Flex)`
    @media ${DEVICE.mobileS} {
        margin-top: 16px;
    }
    @media ${DEVICE.mobileM} {
        margin-top: 16px;
    }
    @media ${DEVICE.mobileL} {
        margin-top: 16px;
    }
    @media ${DEVICE.laptop} {
        margin-top: 0px;
    }
`;

const TextStyle = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.mobileM} {
        display: none;
    }
    @media ${DEVICE.mobileL} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: flex;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

import React from "react";
import styled from "styled-components";
import {
    AddIcon,
    Attachment,
    Button,
    CanvasAddPageIcon,
    CloseIcon,
    Flex,
    Input,
    Loader,
    Text,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import { ICON_FROM_EXTENSION } from "../../../../../utils/functions";
import { formatFileSize } from "../../../../../constants/maths";
import moment from "moment";
import { useRef } from "react";
import { BASE_URL_API } from "../../../../../constants";
import { useState } from "react";
import { useEmployeeFolderIdUser } from "../../../store";
import { useAuthStore } from "../../../../../stores/useAuthStore";
import { useMutation, useQueryClient } from "react-query";
import { QUERY_KEY } from "../../../constants";
import { ROLES_CODE, roles_access } from "../../../../../constants/roles";
import { useEffect } from "react";
import { useUploadForm } from "../../../../../hooks/useUploadForm";
import { _api_delete_document } from "../../../../../api/manage_document";

const ContractAndAvenant = ({ identity_documents }) => {
    // context
    const id_employee = useEmployeeFolderIdUser();
    // useQuery
    const clientQuery = useQueryClient();
    // format
    const contrats = identity_documents?.contrat ?? [];
    const avenants = identity_documents?.avenant ?? [];
    // refs
    const inputContractFileRef = useRef(null);
    const inputAvenantFileRef = useRef(null);
    // states
    const [loadingContract, setLoadingContract] = useState(null);
    const [loadingAvenant, setLoadingAvenant] = useState(null);
    const [contractIdsDeleted, setContractIdsDeleted] = useState([]);
    const [contractIdsPendingDeleted, setContractIdsPendingDeleted] = useState(
        []
    );
    // function
    const isHR = roles_access({
        usersPrivileges: [ROLES_CODE.hr],
    });
    // * HOOK USEFORM FOR CONTRACT
    const { uploadForm, progress, controller, resetProgress } = useUploadForm(
        `${BASE_URL_API}/user/${id_employee}/profile/contract/contrat/upload`,
        (response) => {
            // cb success
            clientQuery.setQueryData(
                [`${QUERY_KEY}::contract`],
                (cache_data) => {
                    const new_contract = response?.data?.files?.at(0);
                    const old_contract = cache_data?.documents?.contrat;
                    old_contract.push(new_contract);
                    return {
                        ...cache_data,
                        documents: {
                            ...cache_data.documents,
                            contrat: old_contract,
                        },
                    };
                }
            );
            setLoadingContract(null);
            return;
        },
        () => {
            // cb error
            resetProgress();
            setLoadingContract(null);
            return;
        }
    );

    // * HOOK USEFORM FOR AVENANTS
    const {
        uploadForm: uploadFormAvenant,
        progress: progressAvenant,
        controller: controllerAvenant,
        resetProgress: resetProgressAvenant,
    } = useUploadForm(
        `${BASE_URL_API}/user/${id_employee}/profile/contract/avenant/upload`,
        (response) => {
            clientQuery.setQueryData(
                [`${QUERY_KEY}::contract`],
                (cache_data) => {
                    const new_avenant = response?.data?.files?.at(0);
                    const old_avenant = cache_data?.documents?.avenant;
                    old_avenant.push(new_avenant);
                    return {
                        ...cache_data,
                        documents: {
                            ...cache_data.documents,
                            avenant: old_avenant,
                        },
                    };
                }
            );
            setLoadingAvenant(null);
            return;
        },
        () => {
            // cb error
            resetProgressAvenant();
            setLoadingAvenant(null);
            return;
        }
    );
    // * DELETE DOCUMENT
    const { mutate: mutateDeleteDocument } = useMutation(
        (id) =>
            _api_delete_document({
                id_document: id,
            }),
        {
            onSuccess: (data) => {
                const idDocDeleted = data?.res?.url.split("/").at(5);
                if (!idDocDeleted) {
                    return;
                }
                if (data?.success) {
                    setContractIdsDeleted((state) => [
                        ...state,
                        parseInt(idDocDeleted),
                    ]);
                    clientQuery.refetchQueries([QUERY_KEY + "::contract"]);
                    return;
                }
            },
        }
    );

    // useEffect(() => {
    //     if (isSuccess && progress === 100) {
    //         setLoadingContract(null);
    //         clientQuery.refetchQueries([QUERY_KEY + "::contract"]);
    //     }
    //     if (isSuccessAvenant && progressAvenant === 100) {
    //         setLoadingAvenant(null);
    //         clientQuery.refetchQueries([QUERY_KEY + "::contract"]);
    //     }
    // }, [isSuccess, progress, isSuccessAvenant, progressAvenant]);

    return (
        <Flex column gap="gap.medium">
            {/* hidden inputs */}
            <Input
                ref={inputContractFileRef}
                onChange={(e) => {
                    const file_len = e?.target?.files?.length;
                    if (file_len) {
                        const file = e.target.files[0];
                        const formData = new FormData();
                        formData.append("uploaded_files", file);
                        setLoadingContract(file);
                        uploadForm(formData);
                    }
                    return;
                }}
                type="file"
                hidden
                name="contract"
                accept=".pdf"
            />
            <Input
                type="file"
                onChange={(e) => {
                    const file_len = e?.target?.files?.length;
                    if (file_len) {
                        const file = e.target.files[0];
                        const formData = new FormData();
                        formData.append("uploaded_files", file);
                        setLoadingAvenant(file);
                        uploadFormAvenant(formData);
                    }
                    return;
                }}
                hidden
                ref={inputAvenantFileRef}
                name="avenant"
                accept=".pdf"
            />

            <Title content="Contrats & avenants" />
            {/* contrat */}
            <Flex column gap="gap.medium">
                <Flex space="between" vAlign="center">
                    <TitleSection content="contrat" />
                    {progress !== 100 && progress !== 0
                        ? null
                        : isHR && (
                              <Button
                                  icon={<CanvasAddPageIcon />}
                                  size="small"
                                  onClick={() =>
                                      inputContractFileRef?.current.click()
                                  }
                                  primary
                                  iconOnly
                                  text
                              />
                          )}
                </Flex>
                {loadingContract && (
                    <Attachment
                        icon={ICON_FROM_EXTENSION(loadingContract?.name)}
                        header={loadingContract?.name?.toLowerCase()}
                        description={`${formatFileSize(
                            loadingContract?.size,
                            2
                        )} - ${moment(loadingContract?.modified_at).format(
                            "DD/MM/YYYY"
                        )}`}
                        onClick={() =>
                            window.open(loadingContract?.attached_doc, "_blank")
                        }
                        actionable
                        progress={progress !== 100 ? progress : false}
                        action={{
                            icon: <CloseIcon />,
                            title: "stop upload",
                            onClick: (e) => {
                                e.stopPropagation();
                                controller();
                                return;
                            },
                        }}
                    />
                )}
                {!contrats?.length ? (
                    <Text content="-" />
                ) : (
                    contrats?.map((contrat) => {
                        const icon = ICON_FROM_EXTENSION(contrat?.name);
                        const isDeleted = contractIdsDeleted.includes(
                            parseInt(contrat?.id)
                        );
                        const isPending = contractIdsPendingDeleted.includes(
                            parseInt(contrat?.id)
                        );
                        if (isDeleted) return;

                        return (
                            <Attachment
                                icon={icon}
                                header={contrat?.display_name}
                                description={`${formatFileSize(
                                    contrat?.filesize,
                                    2
                                )} - ${moment(contrat?.modified_at).format(
                                    "DD/MM/YYYY"
                                )}`}
                                onClick={() =>
                                    window.open(contrat?.attached_doc, "_blank")
                                }
                                action={
                                    isPending
                                        ? {
                                              icon: <Loader size="small" />,
                                              title: "Chargement ...",
                                          }
                                        : {
                                              icon: <CloseIcon />,
                                              onClick: (e) => {
                                                  e.stopPropagation();
                                                  const id_contract = parseInt(
                                                      contrat.id
                                                  );
                                                  setContractIdsPendingDeleted(
                                                      (state) => [
                                                          ...state,
                                                          id_contract,
                                                      ]
                                                  );
                                                  mutateDeleteDocument(
                                                      id_contract
                                                  );
                                              },
                                              title: "Supprimer",
                                          }
                                }
                                actionable
                            />
                        );
                    })
                )}
            </Flex>
            <Flex column gap="gap.medium">
                <Flex space="between" vAlign="center">
                    <TitleSection content="avenants" />

                    {isHR && (
                        <Button
                            icon={<CanvasAddPageIcon />}
                            size="small"
                            primary
                            iconOnly
                            text
                            onClick={() => inputAvenantFileRef.current.click()}
                        />
                    )}
                </Flex>
                {loadingAvenant && (
                    <Attachment
                        icon={ICON_FROM_EXTENSION(loadingAvenant?.name)}
                        header={loadingAvenant?.name?.toLowerCase()}
                        description={`${formatFileSize(
                            loadingAvenant?.size,
                            2
                        )} - ${moment(loadingAvenant?.modified_at).format(
                            "DD/MM/YYYY"
                        )}`}
                        onClick={() =>
                            window.open(loadingAvenant?.attached_doc, "_blank")
                        }
                        actionable
                        action={{
                            icon: <CloseIcon />,
                            title: "stop upload",
                            onClick: (e) => {
                                e.stopPropagation();
                                controllerAvenant();
                                return;
                            },
                        }}
                        progress={progressAvenant !== 100 ? progressAvenant : 0}
                    />
                )}
                {!avenants?.length ? (
                    <Text content="-" />
                ) : (
                    avenants?.map((avenant) => {
                        const icon = ICON_FROM_EXTENSION(avenant?.name);
                        const isDeleted = contractIdsDeleted.includes(
                            parseInt(avenant?.id)
                        );
                        const isPending = contractIdsPendingDeleted.includes(
                            parseInt(avenant?.id)
                        );
                        if (isDeleted) return;
                        return (
                            <Attachment
                                icon={icon}
                                header={avenant?.display_name}
                                description={`${formatFileSize(
                                    avenant?.filesize,
                                    2
                                )} - ${moment(avenant?.modified_at).format(
                                    "DD/MM/YYYY"
                                )}`}
                                onClick={() =>
                                    window.open(avenant?.attached_doc, "_blank")
                                }
                                actionable
                                action={
                                    isPending
                                        ? {
                                              icon: <Loader size="small" />,
                                              title: "Chargement ...",
                                          }
                                        : {
                                              icon: <CloseIcon />,
                                              onClick: (e) => {
                                                  e.stopPropagation();
                                                  const id_avenant = parseInt(
                                                      avenant.id
                                                  );
                                                  setContractIdsPendingDeleted(
                                                      (state) => [
                                                          ...state,
                                                          id_avenant,
                                                      ]
                                                  );
                                                  mutateDeleteDocument(
                                                      id_avenant
                                                  );
                                              },
                                              title: "Supprimer",
                                          }
                                }
                            />
                        );
                    })
                )}
            </Flex>
        </Flex>
    );
};

export default ContractAndAvenant;

const TitleSection = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #242424;
`;

const Title = styled(Text)`
    color: #242424;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
`;

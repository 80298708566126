import moment from "moment";

export const ACTION_TYPES_SLIDER_YEAR = {
    increment: "increment",
    decrement: "decrement",
};

export const INIT_SLIDE_YEAR = {
    year: moment().format("YYYY"),
};

export const reducerSliderYear = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES_SLIDER_YEAR.increment: {
            return {
                year: Number(state.year) + 1,
            };
        }
        case ACTION_TYPES_SLIDER_YEAR.decrement: {
            return {
                year: Number(state.year) - 1,
            };
        }

        default:
            return state;
    }
};

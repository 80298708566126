import React, { useState } from "react";
import { Box, Dropdown, Loader, SearchIcon } from "@fluentui/react-northstar";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getTaskResponsables } from "../../../api/tasks";
import { AVATAR_DEFAULT } from "../../../constants";

const getA11ySelectionMessage = {
    onAdd: (item) =>
        `${item.header} selected. Press left or right arrow keys to navigate selected items.`,
    onRemove: (item) => `${item.header} has been removed.`,
};

const _formatData = (data) => {
    const new_list = data?.map((el) => {
        return {
            header: el?.name,
            image: el?.avatar || AVATAR_DEFAULT,
            content: el?.job_title || "/",
            detail: el,
        };
    });

    return new_list;
};

const AbsoluteSearchBar = ({ onChangeUser }) => {
    const [querySearchUser, setQuerySearchUser] = useState("");
    const [isTaping, setIsTaping] = useState(false);
    const [searchTapingQuery, setSearchTapingQuery] = useState("");

    const { data: user_list, isLoading } = useQuery(
        "getUserList",
        () => getTaskResponsables(),
        {
            staleTime: 60_000,
        }
    );

    if (isLoading) return <Loader label="Chargement des données !" />;

    const json_data = _formatData(user_list);

    return (
        <BoxStyle>
            <Dropdown
                loading={isLoading}
                search
                items={json_data}
                placeholder="Chercher par nom ou métiers"
                getA11ySelectionMessage={getA11ySelectionMessage}
                noResultsMessage="Aucun résultat trouvé."
                a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                inverted
                clearable
                toggleIndicator={<SearchIcon />}
                onChange={(e, i) => {
                    setIsTaping(false);
                    onChangeUser(i?.value?.detail?.id);
                    setQuerySearchUser(i?.value?.header);
                    return;
                }}
                itemToString={(e) => e?.header + " " + e?.content}
                onSearchQueryChange={(_, i) => {
                    setIsTaping(true);
                    setSearchTapingQuery(i?.searchQuery);
                    return;
                }}
                searchQuery={isTaping ? searchTapingQuery : querySearchUser}
                value={""}
            />
        </BoxStyle>
    );
};

export default AbsoluteSearchBar;

const BoxStyle = styled(Box)`
    position: absolute;
    right: 15px;
    top: 20px;
    z-index: 2;
`;

import { Button, IconButton, styled, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box } from "@mui/system";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";

export default function OnboardingHeader() {
    return (
        <Header>
            <Container>
                <Left>
                    <Box display={"flex"} alignItems={"center"}>
                        <IconButton style={{ color: "black" }}>
                            <ArrowBackIosNewIcon fontSize="small" />
                        </IconButton>
                        <Typography variant={"h5"}>Onboarding</Typography>
                    </Box>
                    <Typography
                        variant="subtitle1"
                        style={{
                            marginLeft: "38px",
                            color: "rgba(0,0,0,0.6)",
                            marginTop: "8px",
                        }}
                    >
                        Gérez les collaborateurs qui viennent d’arriver et
                        assurez le suivi des tâches administratives ici
                    </Typography>
                </Left>
                <div style={{ marginRight: "94px" }}>
                    <Button
                        size="large"
                        variant="contained"
                        startIcon={<PersonAddIcon />}
                    >
                        Ajouter une Ressource
                    </Button>
                </div>
            </Container>
        </Header>
    );
}

const Header = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {
        width: "100%",
        backgroundColor: "white",
    },
}));
const Container = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "36px 50px",
        maxWidth: "1440px",
        margin: "0 auto",
    },
}));
const Left = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
}));
const TypographyTitle = styled("Typography")(({ theme }) => ({
    // fontFamily: "Roboto",
    // fontStyle: "Regular",
    // fontSize: "24px",
    // lineHeight: "32px",
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
}));

import {
    ClockFilled,
    CalendarFilled,
    PaymentFilled,
    DocumentPercentFilled,
} from "@fluentui/react-icons";

export const I18N_NS_DELEGATION = "delegation";

const styled_icon = { color: "var(--color-primary)", zIndex: 1 };
export const DELEGATION_ROLES = {
    time: {
        key: "time",
        title: "Suivi du temps",
        icon: <ClockFilled fontSize={22} style={styled_icon} />,
    },
    leave: {
        key: "leave",
        title: "Absences",
        icon: <CalendarFilled fontSize={22} style={styled_icon} />,
    },
    expense: {
        key: "expense",
        title: "Notes de frais",
        icon: <PaymentFilled fontSize={22} style={styled_icon} />,
    },
    accounting: {
        key: "accounting",
        title: "Comptabilité",
        icon: <DocumentPercentFilled fontSize={22} style={styled_icon} />,
    },
};
export const QUERY_KEY_DELEGATION = "delegation";

import React from "react";
import { Column, Row } from "../../styles";
import { COLUMN_WIDTH } from "../../constants";
import WeekBadge from "../../../Badge/WeekBadge";

const RowsIsoWeekNumber = ({ isoWeeksAndDays }) => {
    return (
        <Row nobackground={true}>
            {isoWeeksAndDays.map((isoweek) => {
                return (
                    <Column
                        key={isoweek.isoWeek}
                        vAlign="center"
                        width={COLUMN_WIDTH * isoweek.columns.length}
                        noBorder
                    >
                        <WeekBadge content={isoweek.isoWeek} />
                    </Column>
                );
            })}
        </Row>
    );
};

export default RowsIsoWeekNumber;

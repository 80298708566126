import React from "react";
import logoYeetch from "../../../assets/logos/logo.svg";
import privacyIcon from "../img/Privacy.svg";
import EvolutionIcon from "../img/Evolution.svg";
import { LockClosed32Filled } from "@fluentui/react-icons";
import styled from "styled-components";
import { Flex, Image, Text } from "@fluentui/react-northstar";

const StaticSide = () => {
    return (
        <StaticSideStyle column>
            <Logo src={logoYeetch} />
            <ContentStaticSide column>
                <HeaderText content="Votre espace d’accueil et d’échange documentaire" />
                <Cards column>
                    <Card
                        icon={
                            <LockClosed32Filled
                                outline
                                filled
                                style={{
                                    color: "white",
                                    width: "48px",
                                    height: "48px",
                                }}
                            />
                        }
                        title={"Il est sécurisé"}
                        descipt={
                            " Nous vous demandons de créer un mot de passe pour"
                        }
                        bolderDescript={
                            "protéger vos documents et données personnelles."
                        }
                    />
                    <Card
                        icon={<Image src={privacyIcon} />}
                        title={"Il est confidentiel"}
                        descipt={
                            " C’est votre espace; les données que vous fournirez seront "
                        }
                        bolderDescript={"uniquement accessibles par le RH."}
                    />
                    <Card
                        icon={<Image src={EvolutionIcon} />}
                        title={"Il est évolutif"}
                        descipt={
                            " Aujourd’hui il vous permet de transmettre des documents. Dès votre arrivée, il vous permettra d’utiliser"
                        }
                        bolderDescript={"de nombreuses fonctionnalités RH !"}
                    />
                </Cards>
            </ContentStaticSide>
        </StaticSideStyle>
    );
};

export default StaticSide;

const Card = ({ icon, title, descipt, bolderDescript }) => {
    return (
        <OneCard>
            <IconFlex vAlign="center" hAlign="center">
                {icon}
            </IconFlex>
            <DescriptionFlex hAlign="start" column>
                <CardTitle content={title} />
                <DescriptionText
                    content={
                        <p>
                            {descipt}
                            <p style={{ fontWeight: "bold" }}>
                                {bolderDescript}
                            </p>
                        </p>
                    }
                />
            </DescriptionFlex>
        </OneCard>
    );
};

const Logo = styled(Image)`
    width: 173px;
    height: 56px;
`;

const StaticSideStyle = styled(Flex)`
    width: fit-content;
    height: 100%;
    margin-top: 24px;
    margin-left: 100px;
    background-size: contain;
`;

const ContentStaticSide = styled(Flex)`
    width: 668px;
    /* height: 894px; */
    height: fit-content;
    padding-block: 28px 52px;
`;

const HeaderText = styled(Text)`
    font-size: 40px;
    font-weight: 350;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    width: 493px;
    height: 106px;
`;

const DescriptionText = styled(Text)`
    color: #424242;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const CardTitle = styled(Text)`
    color: #242424;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;

const IconFlex = styled(Flex)`
    width: 120px;
    height: 120px;
    background-color: #2f2f4a;
    border-radius: 4px;
`;
const DescriptionFlex = styled(Flex)`
    padding: 12px;
    gap: 12px;

    width: 382px;
    height: 96px;
`;

const OneCard = styled(Flex)`
    width: 538px;
    height: 120px;
    gap: 12px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
`;

const Cards = styled(Flex)`
    width: 538px;

    margin-top: 74px;
    gap: 24px;
`;

import {
    Divider,
    Dropdown,
    Flex,
    Input,
    Loader,
    SearchIcon,
    Text,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getAllUser } from "../../../features/new_dashboard/api";
import { debounce } from "../../../utils/global";
import { DEBOUNCE_DELAY } from "../constant";
import { AVATAR_DEFAULT } from "../../../constants";
import { Search28Regular } from "@fluentui/react-icons";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";
import { getAllOnboardingUser } from "../api";

const OnboardingSearchBar = ({ AgencyId, setUserIdSelected }) => {
    const [users, setUsers] = useState("");
    const [items, setItems] = useState([]);
    const [refresh, setRefresh] = useState(null);

    const {
        data: all_users,
        isLoading,
        isFetching,
    } = useQuery(
        ["getAllUser"],
        () => {
            return getAllOnboardingUser(AgencyId);
        },
        {
            enabled: users !== "",
            refetchOnWindowFocus: false,
            onSuccess: () => {
                return setRefresh(new Date().getTime());
            },
        }
    );

    // console.log({ users, all_users });

    const handleChange = debounce((e) => {
        if (e?.target?.value?.length <= 2) {
            return;
        } else {
            setUsers(e.target.value);
        }
        return;
    }, DEBOUNCE_DELAY);

    useEffect(() => {
        const data = all_users?.map((el) => {
            return {
                header: el?.name,
                image: el?.avatar || AVATAR_DEFAULT,
                // content: el?.job_title || "/",
                // detail: el,
                id: el?.id,
            };
        });
        setItems(data ?? []);
    }, [refresh]);

    return (
        <Flex fill hAlign="center">
            <DropdownStyled
                fluid
                search
                items={items}
                placeholder="Rechercher un collaborateur"
                loading={isLoading || isFetching}
                noResultsMessage={
                    all_users ? (
                        "Aucun résultat trouvé."
                    ) : (
                        <Text content="Chargement..." />
                    )
                }
                itemToString={(e) => {
                    if (e) {
                        return e?.header;
                    } else {
                        return "";
                    }
                }}
                a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                inverted
                clearable
                toggleIndicator={
                    <Flex>
                        <Divider
                            styles={{
                                width: "10px",
                                height: "30px",
                                color: "red ",
                                marginRight: "16px",
                            }}
                            vertical

                            // size={3}
                            // colors="brand"
                        />
                        <Search28Regular style={{ color: "#5B5FC7" }} />
                    </Flex>
                }
                onChangeCapture={(e) => handleChange(e)}
                // onChange={() => navigate(`/employee/${all_users[0].id}`)}
                onChange={(e, data) => {
                    console.log({ data90: data });
                    if (data?.value?.id) {
                        // navigate(`/onboarding/detail/${data.value.id}`);
                        setUserIdSelected(data?.value?.id);
                    } else {
                        setUserIdSelected(null);
                    }
                }}
            />
        </Flex>
    );
};

export default OnboardingSearchBar;

const DropdownStyled = styled(Dropdown)`
    border-radius: 16px;
    border: 1px solid #d1d1d1;
    background: #fff;
    /* width: 100%; */
    align-items: center;
    display: flex;
    width: 900px;
    height: 50px;
    padding: 16px;
    /* border: none; */
    background: white;
    .ui-dropdown__container,
    .ui-dropdown__container:active,
    .ui-dropdown__container:hover {
        border-bottom-style: hidden; /* Ou border-bottom: none; */
        background-color: #fff;
    }
    /* .ui-dropdown__container .ui-dropdown__searchinput__input:focus {
        border-bottom-style: hidden;
    } */
`;

import { Flex, Skeleton, Text } from "@fluentui/react-northstar";
import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { AbsenceSectionCard } from "../../new_dashboard/styles/responsive";
import { ROUTES } from "../../../constants/routes";

export const AbsenceSection = ({ balances, isLoading, is_pre_onboarding }) => {
    const navigate = useNavigate();

    return (
        <AbsenceSectionCard
            column
            space="between"
            hAlign="center"
            onClick={() => navigate(`${ROUTES.leave}`)}
        >
            <Header vAlign="center" hAlign="center" gap="gap.small">
                <TitleText content={"Absences"} />
            </Header>
            <AbsenceContent gap="gap.small" hAlign="center">
                {isLoading && (
                    <FlexCard
                        key={"flex-balances"}
                        gap="gap.small"
                        column
                        style={{
                            width: "98px",
                        }}
                    >
                        <Flex>
                            <Skeleton animation="pulse">
                                <Skeleton.Line width="80px" height="20px" />
                            </Skeleton>
                            <Skeleton animation="pulse">
                                <Skeleton.Line width="80px" height="20px" />
                            </Skeleton>
                        </Flex>
                        <Flex>
                            <Skeleton animation="pulse">
                                <Skeleton.Line width="80px" height="20px" />
                            </Skeleton>
                            <Skeleton animation="pulse">
                                <Skeleton.Line width="80px" height="20px" />
                            </Skeleton>
                        </Flex>
                    </FlexCard>
                )}
                <Flex>
                    {!is_pre_onboarding &&
                        balances?.map((el, i) => {
                            return (
                                <FlexCard
                                    key={"flex-balances-" + i}
                                    gap="gap.small"
                                    column
                                    style={{
                                        // maxWidth: "98px!important",
                                        width: "98px",
                                    }}
                                >
                                    {!isLoading && balances ? (
                                        <Text
                                            as="h2"
                                            color="brand"
                                            content={el?.current_value}
                                            styles={{
                                                textAlign: "center",
                                                marginBottom: "8px",
                                            }}
                                        />
                                    ) : (
                                        <Skeleton animation="pulse">
                                            <Skeleton.Line
                                                width="80px"
                                                height="20px"
                                            />
                                        </Skeleton>
                                    )}
                                    {isLoading ? (
                                        <Skeleton animation="pulse">
                                            <Skeleton.Line
                                                width="80px"
                                                height="20px"
                                            />
                                        </Skeleton>
                                    ) : (
                                        <Text
                                            styles={{
                                                textAlign: "center",
                                                width: "198px",
                                            }}
                                            content={el?.name}
                                            title={el?.name}
                                        />
                                    )}
                                </FlexCard>
                            );
                        })}
                </Flex>
            </AbsenceContent>
            <AbsenceAction
                vAlign="center"
                hAlign="center"
                onClick={() => {
                    navigate("/leave");
                }}
            >
                <Text className="link" content="Gérer mes absences >" />
            </AbsenceAction>
        </AbsenceSectionCard>
    );
};

const FlexCard = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`;
const TitleText = styled(Text)`
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #242424;
`;

const AbsenceAction = styled(Flex)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0067b8;
    cursor: pointer;
    /* margin-top: 4px; */
`;

const AbsenceContent = styled(Flex)`
    width: 232px;
`;

const Header = styled(Flex)`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* text-transform: uppercase; */
    color: #242424;
    margin: 16px 0px 4px 0px;
`;

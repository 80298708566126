import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Label = styled(Text)`
    color: #252424;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;
export const Hours = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;
export const DeclaredMonth = styled(Flex)`
    border-left: 4px solid #4f52b2;
    height: 40px;
    padding-left: 16px;
`;
export const ContractualHours = styled(DeclaredMonth)`
    border-color: #e8ebfa;
`;
export const Left = styled(Flex)`
    width: 396px;
`;
export const Right = styled(Flex)``;
export const Title = styled(Text)`
    color: #242424;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
`;
export const CardDetailWeek = styled(Flex)`
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 8px;
    min-width: 165px;

    border-radius: 4px;
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);

    /* shadow-4 */
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07);
`;
export const Dates = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;
export const TotalHours = styled(Text)`
    color: var(--Light-theme-Rest-Foreground-Brand-Foreground-2, #4f52b2);
    text-align: right;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
`;
export const WrapperCards = styled(Flex)`
    row-gap: 1rem;
`;
export const Graph = styled(Flex)`
    width: 88px;
    height: ${({ progress }) => progress + "px"};
    background: ${({ primary }) =>
        primary ? "var(--color-primary)" : " #E8EBFA"};
`;

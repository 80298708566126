import { BASE_URL_API } from "../../../constants";
import { useStepperContext } from "../../../pages/Dashboard/AddCollaborator/ContextAddCollaborateur";
import fetcher from "../../../utils/fetcher";

export const _api_assign_ressource = ({ id_user, email, resource_id }) => {
    return fetcher(`${BASE_URL_API}/onboarding/user/create/${id_user}`, {
        method: "PATCH",
        body: JSON.stringify({
            step: "set_resources",
            email,
            resources: resource_id,
        }),
        credentials: "include",
    });
};

export const _api_save_settings = ({ id_user, email, role }) => {
    return fetcher(`${BASE_URL_API}/onboarding/user/create/${id_user}`, {
        method: "PATCH",
        body: JSON.stringify({
            step: "set_account_params",
            email,
            role_codes: [role],
        }),
        credentials: "include",
    });
};

export const _api_save_tasks = () => {
    const { successData } = useStepperContext.getState().firstStep;
    const { id: idUser, email } = successData;

    const selectedTasks = useStepperContext.getState().tasks.selectedTasks;
    const manualTasks = useStepperContext.getState().tasks.manualTasks;
    // const templates = useStepperContext.getState().tasks.templates;
    // const format_templates = templates.map((el) => el.id);
    // format with the right Keys
    // & filter all checked tasks
    const format_selected_tasks = selectedTasks
        .filter((el) => el.checked === true)
        .map((el) => {
            return {
                ...el,
                documents_to_attach: el?.documents_to_attach?.map(
                    (el) => el.id
                ),
            };
        });

    const format_new_tasks = manualTasks
        .filter((el) => el.checked === true)
        .map((el) => {
            return {
                ...el,
                documents_to_attach: el?.documents_to_attach?.map(
                    (el) => el.id
                ),
            };
        });

    // "name": "badge", 👌
    // "description": "description badge 222", 👌
    // "category_id": 2, 👌
    // "due_date": "2022-09-21", 👌
    // "assigned_to": "test@test.fr", 👌
    // "document_required": true,👌
    // "current_doc_ids": [ 👌
    // 	1,2
    // ],
    // "new_doc_ids": [ 👌
    // 	"bb18b9ab-ac06-4eca-ac78-9702650227bc",
    // 	"50bbc9c4-2ab9-42c6-a847-fae5fdc19564"
    // ],
    // "type": 1, 👌
    // "task_to_validate": false, 👌
    // "document_action": 1 👌

    return fetcher(`${BASE_URL_API}/onboarding/user/create/${idUser}`, {
        method: "PATCH",
        body: JSON.stringify({
            step: "set_onboarding_tasks",
            email,
            tasks: [...format_selected_tasks, ...format_new_tasks],
            // new_tasks: format_new_tasks,
            // selected_task_templates: format_templates,
        }), // The data
        credentials: "include",
    });
};

export const _api_save_steps = () => {
    const { successData } = useStepperContext.getState().firstStep;
    const { id: idUser, email } = successData;

    return fetcher(`${BASE_URL_API}/onboarding/user/create/${idUser}`, {
        method: "PUT",
        body: JSON.stringify({
            email,
        }), // The data
        credentials: "include",
    });
};

import React from "react";
import styled from "styled-components";
import {
    Alert,
    Attachment,
    Button,
    Checkbox,
    Divider,
    Dropdown,
    ExclamationTriangleIcon,
    FilesPdfIcon,
    FilesUploadIcon,
    Flex,
    Input,
    RadioGroup,
    Text,
    TextArea,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import { DEVICE } from "../../../constants/responsive";
import { Controller, useFormContext } from "react-hook-form";
import {
    DATE_REFERENCES,
    DIALOG_ADD_TASK_TO_TEMPLATE__RESPONSABLE_DROPDOWN,
    DIALOG_ADD_TASK_TO_TEMPLATE__VALIDATOR_DROPDOWN,
    VALIDATOR_ADD_TASK_TEMPLATE,
} from "../constants";
import { ErrorMessage } from "../../../common/styles";
import { useQuery } from "react-query";
import {
    getListManagerAndHrOnAgency,
    getTaskCategory,
    getTaskResponsable,
    getTaskResponsables,
    getTaskValidators,
} from "../../../api/tasks";
import { BASE_URL_ILLUSTRATIONS } from "../../../constants";
import { useState } from "react";
import { TASK_ACTIONS } from "../../../constants/tasks";
import { file_size_format } from "../../../constants/maths";
import { uploadedFile } from "../../../utils/global";
import { useRef } from "react";
import { useEffect } from "react";
import { RequiredStar, TitleGroup } from "./style";
import { DropdownStyle, FlexWrapperDates } from "./DialogAddManualTaskTemplate";

const DialogUpdateTaskOnModifyTemplate = ({
    setTemplateTasks,
    default_task,
}) => {
    const refButtonUpload = useRef(null);
    const [_, setRefresh] = useState(false);

    const {
        register,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        getValues,
        watch,
    } = useFormContext();

    // get all categories
    const { data: dataCategory = [], isLoading: isLoadingCategory } = useQuery(
        ["getCategory"],
        () => getTaskCategory(),
        {
            refetchOnWindowFocus: false,
        }
    );
    let { data: dataCollaborator = [], isLoading: isLoadingCollaborator } =
        useQuery(["getCollaborator"], () => getTaskResponsables(), {
            refetchOnWindowFocus: false,
        });
    let { data: dataValidators = [], isLoading: isLoadingValidators } =
        useQuery(["getValidators"], () => getListManagerAndHrOnAgency(), {
            refetchOnWindowFocus: false,
        });

    useEffect(() => {
        setValue("id", default_task?.id);
        setValue("duedate_duration", default_task?.duedate_duration);
        setValue(
            "saved_files",
            default_task?.temp?.saved_files || default_task?.documents_template
        );
        setValue("task_to_validate", default_task?.task_to_validate);
        // setValue(
        // 	"document_action",
        // 	default_task?.document_action === 0
        // 		? 4
        // 		: default_task?.document_action - 1
        // );
        setValue("document_action", default_task?.document_action);
        setValue("category_id", default_task?.category_id);
        setValue("duedate_operator", default_task?.duedate_operator);
        setValue(
            "document_required",
            TASK_ACTIONS.find((el) => el.id === default_task?.document_action)
                ?.document_required
        );
        setValue(
            "assigned_to",
            dataCollaborator?.find(
                (el) =>
                    el?.id === default_task?.assigned_to_user_id ||
                    el?.id === default_task?.assigned_to_type
            )
        );
        setValue(
            "validator",
            dataCollaborator?.find(
                (el) =>
                    el?.id === default_task?.validator_user_id ||
                    el?.id === default_task?.validator_type
            )
        );
        setValue("new_doc_ids", default_task?.documents_template || []);
        setRefresh(new Date().getTime());
    }, [default_task, setValue, dataCollaborator]);

    // format the data of the collaborator for fluentui
    dataCollaborator = dataCollaborator.map((el) => {
        return {
            id: el?.id,
            header: el.name,
            image: el.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: el.job_title,
            username: el?.username,
        };
    });
    dataValidators = dataValidators.map((el) => {
        return {
            id: el?.id,
            header: el.name,
            image: el.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: el.job_title,
            username: el?.username,
        };
    });

    dataCollaborator.unshift(
        ...DIALOG_ADD_TASK_TO_TEMPLATE__RESPONSABLE_DROPDOWN
    );

    dataValidators.unshift(...DIALOG_ADD_TASK_TO_TEMPLATE__VALIDATOR_DROPDOWN);

    const default_document_action =
        TASK_ACTIONS[
            default_task?.document_action === 0
                ? 4
                : default_task?.document_action - 1
        ];

    if (isLoadingCategory || isLoadingCollaborator || isLoadingValidators)
        return <p>loading data ...</p>;

    return (
        <Flex column gap="gap.small">
            <Flex.Item>
                <Text>
                    Pour ajouter une tâche, remplissez les informations
                    suivantes :
                </Text>
            </Flex.Item>
            <Flex>
                <TitleGroup content={`1 - Informations sur la tâche`} />
                <RequiredStar />
            </Flex>
            <Flex fill column>
                <Input
                    label="Titre de la tâche"
                    {...register("name", VALIDATOR_ADD_TASK_TEMPLATE.name)}
                    placeholder="Donnez un titre évocateur"
                    styles={{ marginBottom: "2px" }}
                    fluid
                    defaultValue={default_task?.name}
                    error={errors?.name ? true : false}
                />
                <ErrorMessage content={errors?.name?.message} />
            </Flex>
            <Flex column>
                <Label>Catégorie de la tâche</Label>
                <Controller
                    control={control}
                    rules={VALIDATOR_ADD_TASK_TEMPLATE.category}
                    defaultValue={
                        dataCategory?.find(
                            (el) => el?.id === default_task?.category_id
                        )?.name
                    }
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <DropdownStyle
                            checkable
                            items={dataCategory?.map((el) => el?.name)}
                            placeholder="Catégorie de la tâche"
                            defaultValue={
                                dataCategory?.find(
                                    (el) => el?.id === default_task?.category_id
                                )?.name
                            }
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onBlur={onBlur} // notify when input is touched
                            onChange={(_, option) =>
                                onChange(
                                    dataCategory[option.highlightedIndex].id
                                )
                            } // send value to hook form
                            checked={value}
                            ref={ref}
                            error={errors?.category_id ? true : false}
                            styles={{
                                width: "100%",
                            }}
                        />
                    )}
                    name="category_id"
                />
                {errors?.category_id && (
                    <ErrorMessage content={errors?.category_id.message} />
                )}
            </Flex>
            <Flex gap="gap.large" vAlign="end">
                <Flex.Item>
                    <Flex column fill>
                        <Input.Label>
                            Consignes pour accomplir la tâche
                        </Input.Label>
                        <TextArea
                            label="Consignes pour accomplir la tâche"
                            placeholder="Ajouter des indications ici"
                            {...register(
                                "description",
                                VALIDATOR_ADD_TASK_TEMPLATE.description
                            )}
                            error={errors?.description ? true : false}
                            fluid
                            defaultValue={default_task?.description}
                        />
                        <ErrorMessage content={errors?.description?.message} />
                    </Flex>
                </Flex.Item>
            </Flex>
            <Divider />
            <Flex>
                <TitleGroup
                    content={`2 - Que doit faire le collaborateur sur un document ? (facultatif)`}
                />
            </Flex>
            <Flex column gap="gap.small">
                <Input.Label>Action demandée au collaborateur</Input.Label>
                <Controller
                    control={control}
                    rules={VALIDATOR_ADD_TASK_TEMPLATE?.document_action}
                    defaultValue={default_document_action}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <DropdownStyle
                            items={TASK_ACTIONS}
                            defaultValue={default_document_action}
                            placeholder="Aucune action demandée"
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onChange={(_, i) => {
                                const {
                                    document_required,
                                    id: document_action,
                                } = i?.value;
                                setValue("document_action", document_action);
                                setValue(
                                    "document_required",
                                    document_required
                                );
                                setValue("saved_files", []);
                                clearErrors("document_action");
                                // setRefresh(new Date().getTime());
                                return;
                            }}
                            ref={ref}
                            onBlur={onBlur}
                            error={errors?.document_action ? true : false}
                            checkable
                            name={name}
                        />
                    )}
                    name="document_action"
                />
                {errors?.document_action && (
                    <ErrorMessage content={errors?.document_action.message} />
                )}
                {getValues("document_required") &&
                    getValues("saved_files").length === 0 && (
                        <Flex column gap="gap.medium">
                            <Button
                                type="file"
                                icon={<FilesUploadIcon size="small" />}
                                content="Ajouter la pièce-jointe"
                                iconPosition="before"
                                onClick={() => refButtonUpload.current.click()}
                                tinted
                            />
                            <Input
                                ref={refButtonUpload}
                                type="file"
                                icon={<FilesUploadIcon size="small" />}
                                content="Ajouter la pièce-jointe"
                                iconPosition="before"
                                onChange={(e) =>
                                    uploadedFile(e.target.files, (args) => {
                                        setValue("saved_files", args);
                                        clearErrors("document_action");
                                        // setRefresh(new Date().getTime());
                                        return;
                                    })
                                }
                                hidden
                            />
                            <Alert
                                icon={<ExclamationTriangleIcon />}
                                content="Pour les documents nominatifs, veuillez les ajouter à postériori"
                            />
                        </Flex>
                    )}
                {/* view the document */}
                {getValues("saved_files")?.map((el) => {
                    return (
                        <Attachment
                            icon={<FilesPdfIcon />}
                            header={el.filename || el.name}
                            description={file_size_format(el.filesize)}
                            actionable
                            action={{
                                icon: <TrashCanIcon />,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    setValue("saved_files", []);
                                    setValue("new_doc_ids", []);
                                    setRefresh(new Date().getTime());
                                },
                                title: "Remove",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(
                                    el?.uploaded_doc || el?.attached_doc,
                                    "_blank"
                                );
                            }}
                            style={{ maxWidth: "100%" }}
                        />
                    );
                })}
            </Flex>
            <Divider />
            <Flex>
                <TitleGroup content={`3 - Date planifiée`} />
                <RequiredStar />
            </Flex>
            <FlexWrapperDates space="between" fill>
                <Flex column>
                    <Input.Label>Date de référence</Input.Label>
                    <Controller
                        control={control}
                        rules={VALIDATOR_ADD_TASK_TEMPLATE.duedate_event}
                        defaultValue={DATE_REFERENCES.find(
                            (el) => el?.id === default_task?.duedate_event
                        )}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                checkable
                                items={DATE_REFERENCES}
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                onBlur={onBlur}
                                onChange={(_, option) => {
                                    onChange(option?.value);
                                }}
                                checked={value}
                                ref={ref}
                                error={errors?.duedate_event ? true : false}
                                defaultValue={DATE_REFERENCES.find(
                                    (el) =>
                                        el?.id === default_task?.duedate_event
                                )}
                                styles={{
                                    width: "100%",
                                }}
                                fluid
                            />
                        )}
                        name="duedate_event"
                    />
                    <ErrorMessage content={errors?.duedate_event?.message} />
                </Flex>
                <Flex column>
                    <Input.Label>Lien avec la date de référence</Input.Label>
                    <RadioGroup
                        onCheckedValueChange={(e, { value }) => {
                            setValue("duedate_operator", value);
                        }}
                        fluid
                        items={[
                            {
                                key: "1",
                                label: "Avant",
                                value: "1",
                            },
                            {
                                key: "2",
                                label: "Après",
                                value: "2",
                            },
                        ]}
                        defaultCheckedValue={
                            default_task?.duedate_operator + ""
                        }
                    />
                </Flex>
                <Flex column styles={{ width: "160px" }}>
                    <Controller
                        control={control}
                        rules={VALIDATOR_ADD_TASK_TEMPLATE.duedate_duration}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                        }) => {
                            return (
                                <Input
                                    type="number"
                                    label="Nombre de jours"
                                    min="0"
                                    onChange={(e) => {
                                        const value = parseInt(
                                            getValues("duedate_duration")
                                        );

                                        const char = e.nativeEvent.data;
                                        const pattern = /^\d+\.?\d*$/;
                                        const isNumber = pattern.test(char);
                                        if (
                                            !isNumber &&
                                            (char === "-" || char === "+")
                                        ) {
                                            setValue("duedate_duration", value);
                                            return onChange(value);
                                        } else {
                                            setValue("duedate_duration", value);
                                            return onChange(e.target.value);
                                        }
                                    }}
                                    error={
                                        errors?.duedate_duration ? true : false
                                    }
                                    value={parseInt(value) + ""}
                                    name={name}
                                    ref={ref}
                                    clearable
                                    fluid
                                />
                            );
                        }}
                        name="duedate_duration"
                    />
                    {/* <Input
                        {...register(
                            "duedate_duration",
                            VALIDATOR_ADD_TASK_TEMPLATE.duedate_duration
                        )}
                        type="number"
                        label="Nombre de jours"
                        error={errors?.duedate_duration ? true : false}
                        defaultValue={default_task?.duedate_duration + ""}
                        clearable
                        required
                        fluid
                    /> */}
                    <ErrorMessage content={errors?.duedate_duration?.message} />
                </Flex>
            </FlexWrapperDates>
            <Divider />
            <Flex>
                <TitleGroup content={`4 - Personnes concernées`} />
                <RequiredStar />
            </Flex>
            <Flex column>
                <Input.Label>Qui va effectuer la tâche ?</Input.Label>
                <Controller
                    control={control}
                    rules={VALIDATOR_ADD_TASK_TEMPLATE.assigned_to}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                    }) => (
                        <DropdownStyle
                            items={dataCollaborator}
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onBlur={onBlur}
                            onChange={(_, option) => {
                                setValue("assigned_to", {
                                    ...option?.value,
                                    selected: true,
                                });
                                onChange(option?.value);
                                return;
                            }}
                            value={value}
                            checkable
                            ref={ref}
                            error={errors?.assigned_to ? true : false}
                            styles={{
                                width: "100%",
                            }}
                            name={name}
                        />
                    )}
                    name="assigned_to"
                />
                <ErrorMessage content={errors?.assigned_to?.message} />
            </Flex>
            <Flex>
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <Checkbox
                            label="Tâche à faire valider"
                            onChange={(e, { checked }) => {
                                onChange(checked);
                                setRefresh(new Date().getTime());
                            }}
                            defaultChecked={
                                default_task?.task_to_validate ?? false
                            }
                            toggle
                            ref={ref}
                            onBlur={onBlur}
                        />
                    )}
                    name="task_to_validate"
                />
            </Flex>
            {getValues("task_to_validate") === true && (
                <Flex column>
                    <Input.Label>
                        Qui va valider la réalisation de la tâche ?
                    </Input.Label>
                    <Controller
                        control={control}
                        rules={VALIDATOR_ADD_TASK_TEMPLATE.validator}
                        defaultValue={getValues("validator")}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                defaultValue={getValues("validator")}
                                checkable
                                items={dataValidators.filter(
                                    (el) => el?.id !== -2
                                )}
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                onBlur={onBlur}
                                onChange={(_, option) => {
                                    onChange(option?.value);
                                }}
                                checked={value}
                                ref={ref}
                                error={errors?.validator ? true : false}
                                styles={{
                                    width: "100%",
                                }}
                            />
                        )}
                        name="validator"
                    />
                    <ErrorMessage content={errors?.validator?.message} />
                </Flex>
            )}
        </Flex>
    );
};

export default DialogUpdateTaskOnModifyTemplate;

const Label = styled(Text)`
    -webkit-text-size-adjust: 100%;
    font-size: 0.875rem;
    text-align: left;
    color: rgb(17, 16, 15);
    unicode-bidi: isolate;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    display: block;
    transition: all 0.2s ease 0s;
    line-height: 1rem;
    margin-bottom: 0.25rem;
`;

const FlexDescription = styled(Flex)`
    gap: 16px;
    align-items: flex-start;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const TextTitle = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
`;

import React from "react";
import { Flex } from "@fluentui/react-northstar";
import { TextCategory } from "./AccordionList";
import Rows from "./Rows";

const ListUsers = ({ users }) => {
    return (
        <Flex column gap="gap.small">
            <Flex vAlign="center" hAlign="center">
                <TextCategory>Personnes</TextCategory>
            </Flex>
            {users?.map((el, index) => {
                return <Rows key={index} data={el} />;
            })}
        </Flex>
    );
};

export default ListUsers;

import {
    Box,
    Button,
    Divider,
    Flex,
    FlexItem,
    Text,
} from "@fluentui/react-northstar";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import "moment/locale/fr";
import { useState } from "react";
import { ChevronDown24Filled, ChevronUp24Regular } from "@fluentui/react-icons";

export default function Contrat({ me }) {
    const [qualification, setQualification] = useState(true);
    const [date, setDate] = useState(false);
    const [workingTime, setWorkingTime] = useState(false);
    const [remuneration, setRemuneratione] = useState(false);

    const showRemuneration = () => {
        setRemuneratione(true);
    };

    const hideRemuneration = () => {
        setRemuneratione(false);
    };
    const showWorkingTime = () => {
        setWorkingTime(true);
    };

    const hideWorkingTime = () => {
        setWorkingTime(false);
    };
    const showDate = () => {
        setDate(true);
    };

    const hideDate = () => {
        setDate(false);
    };
    const showQualification = () => {
        setQualification(true);
    };

    const hideQualification = () => {
        setQualification(false);
    };

    const CONTRACT_STATUS = {
        0: "Autre",
        1: "Cadre",
        2: "Etam",
    };

    const CONTRACT_TYPE = {
        0: "CDI: Contrat de travail à Durée Indéterminée",
        1: "CDD: Contrat de travail à Durée Déterminée",
        2: "CTT: Contrat de Travail Temporaire ou Intérimaire ",
        3: "Contrat d'apprentissage",
        4: "Contrat de professionnalisation",
        5: "CUI: Contrat Unique d'Insertion ",
        6: "CAE: Contrat d'Accompagnement dans l'Emploi",
        7: "CIE: Contrat Initiative Emploi",
        8: "Stage non rémunéré",
        9: "Stage rémunéré",
        10: "POEI: Pole emploi - Préparation Opérationnelle à l'Emploi Individuelle",
    };

    const RTT = {
        0: "Pas de RTT",
        1: "Fixe",
        2: "Variable",
    };

    const _calculateDiffDates = (date) => {
        moment().locale("fr");
        const now = moment(date);
        const ago = now.fromNow(false);
        return date + " ( " + ago + " ) ";
    };

    return (
        <Container>
            <Flex
                vAlign="center"
                style={{ width: "100%", justifyContent: "space-between" }}
            >
                <FlexItem>
                    <Title
                        weight="semibold"
                        content="Emploi et qualification"
                    />
                </FlexItem>
                <FlexItem>
                    {qualification ? (
                        <Button
                            content={"Masquer"}
                            iconPosition="after"
                            icon={<ChevronUp24Regular />}
                            text
                            primary
                            onClick={hideQualification}
                            size="medium"
                            inline="true"
                        />
                    ) : (
                        <Button
                            iconPosition="after"
                            icon={<ChevronDown24Filled />}
                            text
                            primary
                            onClick={showQualification}
                            size="medium"
                            inline="true"
                            content={"Afficher"}
                        />
                    )}
                </FlexItem>
            </Flex>
            {qualification ? (
                <>
                    <Label content="Poste" />
                    <Data content={me?.job_title} />
                    <Label content="Type de contrat" />
                    <Data content={CONTRACT_TYPE[me?.contract?.type]} />
                    <Label content="Statut" />
                    <Data content={CONTRACT_STATUS[me?.contract?.status]} />
                    <Label content="Position" />
                    <Data content={me?.contract?.position || " -- "} />
                    <Label content="Coefficient" />
                    <Data content={me?.contract?.coefficient || " -- "} />
                </>
            ) : null}

            <Divider
                style={{
                    width: "100%",
                }}
            />
            <Flex
                vAlign="center"
                style={{ width: "100%", justifyContent: "space-between" }}
            >
                <Title weight="semibold" content="Dates" />
                <FlexItem>
                    {date ? (
                        <Button
                            content={"Masquer"}
                            iconPosition="after"
                            icon={<ChevronUp24Regular />}
                            text
                            primary
                            onClick={hideDate}
                            size="medium"
                            inline="true"
                        />
                    ) : (
                        <Button
                            iconPosition="after"
                            icon={<ChevronDown24Filled />}
                            text
                            primary
                            onClick={showDate}
                            size="medium"
                            inline="true"
                            content={"Afficher"}
                        />
                    )}
                </FlexItem>
            </Flex>
            {date ? (
                <>
                    <Label content="Date d'embauche" />
                    <Data content={_calculateDiffDates(me?.arrival_date)} />
                    <Label content="Date de fin de période d'essai" />
                    <Data
                        content={
                            me?.contract?.probationary_period_end_date != null
                                ? me?.contract?.probationary_period_end_date
                                : "Non renseigné"
                        }
                    />
                    <Label content="Date de fin de contrat" />

                    <Data content={me?.departure_date || " --- "} />
                </>
            ) : null}

            <Divider
                style={{
                    width: "100%",
                }}
            />
            <Flex
                vAlign="center"
                styles={{
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <FlexItem>
                    <Title weight="semibold" content="Temps de travail" />
                </FlexItem>
                <FlexItem>
                    {workingTime ? (
                        <Button
                            content={"Masquer"}
                            iconPosition="after"
                            icon={<ChevronUp24Regular />}
                            text
                            primary
                            onClick={hideWorkingTime}
                            size="medium"
                            inline="true"
                        />
                    ) : (
                        <Button
                            iconPosition="after"
                            icon={<ChevronDown24Filled />}
                            text
                            primary
                            onClick={showWorkingTime}
                            size="medium"
                            inline="true"
                            content={"Afficher"}
                        />
                    )}
                </FlexItem>
            </Flex>
            {workingTime ? (
                <>
                    <Label content="Taux d'activité contractuel" />
                    <Data
                        content={
                            me?.contract?.part_time_percentage != null
                                ? me?.contract?.part_time_percentage + " %"
                                : "Non renseigné"
                        }
                    />

                    {me?.contract?.package_type == 1 ? (
                        <>
                            <Label content="Forfait heure" />
                            <Data
                                content={
                                    me?.contract?.hour_count_per_week != null
                                        ? me?.contract?.hour_count_per_week +
                                          "h travaillées / semaine"
                                        : "--"
                                }
                            />
                            <Data
                                content={
                                    me?.contract?.hour_payed_per_week != null
                                        ? me?.contract?.hour_payed_per_week +
                                          "h payées / semaine"
                                        : "--"
                                }
                            />
                        </>
                    ) : (
                        <>
                            <Label content="Forfait Jour" />
                            <Data
                                content={
                                    me?.contract?.day_count_per_year != null
                                        ? me?.contract?.day_count_per_year +
                                          " jours travaillé par an"
                                        : "--"
                                }
                            />
                        </>
                    )}
                </>
            ) : null}

            <Divider
                style={{
                    width: "100%",
                }}
            />

            <Flex
                vAlign="center"
                style={{ justifyContent: "space-between", width: "100%" }}
            >
                <FlexItem>
                    <Title weight="semibold" content="Rémunération" />
                </FlexItem>
                <FlexItem>
                    {remuneration ? (
                        <Button
                            content={"Masquer"}
                            iconPosition="after"
                            icon={<ChevronUp24Regular />}
                            text
                            primary
                            onClick={hideRemuneration}
                            size="medium"
                            inline="true"
                        />
                    ) : (
                        <Button
                            iconPosition="after"
                            icon={<ChevronDown24Filled />}
                            text
                            primary
                            onClick={showRemuneration}
                            size="medium"
                            inline="true"
                            content={"Afficher"}
                        />
                    )}
                </FlexItem>
            </Flex>

            {remuneration ? (
                <>
                    <Label content="Salaire brut annuel / mensuel" />
                    <Data content={me.contract.gross_salary + " €"} />
                    <Label content="Variable & primes" />
                    <Data content={me.contract.bonus ? " Oui" : " Non"} />
                </>
            ) : null}
        </Container>
    );
}

const Container = styled(Flex)`
    flex-direction: column;
    align-items: flex-start;
    //gap is 16 in figma
    gap: 10px;
    padding-inline: 2px;
    margin: 9px 16px 9px 16px;
    /* border: 1px solid red; */
`;

const Data = styled(Text)`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #252525;
    font-style: normal;
`;

const Label = styled(Text)`
    color: #686868;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
`;

const Title = styled(Text)`
    color: #242424;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
`;

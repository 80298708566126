import React from "react";

const DownloadFunction = (doc) => {
    const fileUrl = doc.at(0).attached_doc;
    const fileName = doc.at(0).name;

    fetch(fileUrl) // download the file
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch((error) =>
            console.error("Erreur lors du téléchargement du fichier :", error)
        );
};

export default DownloadFunction;

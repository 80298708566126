import produce from "immer";
import { create } from "zustand";

/**
 * @typedef {Object} Props
 * @property {boolean} visible
 * @property {"success"|"danger"|"warning"} level - apply style
 * @property {string} content - message to display
 * @property {function} onChangeStatus - function appellée lors du changement de visibility
 * @property {function} setShow - function qui change le visible
 *
 * @return {Props} - les props retournés
 */
export const useToastRemote = create((set) => ({
    visible: false,
    level: "success",
    content: "",
    onChangeStatus: () => {
        set(
            produce((state) => {
                state.visible = false;
            })
        );
    },
    setShow: (json) => {
        set(
            produce((state) => {
                state.visible = json.visible;
                state.content = json.content;
                state.level = json.level;
            })
        );
    },
}));

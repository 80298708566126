import React from "react";
import {
    Accordion,
    Dropdown,
    Flex,
    FlexItem,
    Text,
    TextArea,
} from "@fluentui/react-northstar";
import { RadioGroup, Radio } from "@fluentui/react-components";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";
import MuiPhoneNumber from "material-ui-phone-number";
import SETUP_PROFILE from "../../../constants";
import { getManagers, getServices } from "../../../../../api/user";
import { useQuery } from "react-query";
import { getAgencies } from "../../../../../api/helpers";
import ContactUrgence from "./ContactUrgence";
import { DEVICE } from "../../../../../constants/responsive";
import { AVATAR_DEFAULT } from "../../../../../constants";
import MessageError from "../../components/MessageError";

const template = {
    marginTop: "5px",
    marginBottom: "16px",
    marginRight: "24px",
};

const Resume = ({ user }) => {
    const STALE_TIME = 1000 * 60 * 24; // 24h
    const {
        register,
        control,
        formState: { errors },
        getValues,
        setValue,
    } = useFormContext();

    /**
     * get Manager of the user
     */
    const { data: dataManager, isLoading: isLoadingManager } = useQuery(
        ["getManager"],
        () => getManagers(),
        {
            staleTime: STALE_TIME,
        }
    );

    /**
     * get Service of the user
     */

    const { data: dataService, isLoading: isLoadingService } = useQuery(
        ["getService"],
        () => getServices(),
        {
            staleTime: STALE_TIME,
        }
    );

    /**
     * get Agency of the user
     */

    const { data: dataAgency, isLoading: isLoadingAgency } = useQuery(
        ["getAgency"],
        () => getAgencies(),
        {
            staleTime: STALE_TIME,
        }
    );

    if (isLoadingManager || isLoadingAgency || isLoadingService)
        return <p>Chargement ...</p>;

    return (
        <AccordionStyle>
            {/* information generale */}
            <InformationGeneral column fill>
                <Text
                    style={{ margin: "15px 0" }}
                    weight="bold"
                    content="Informations générales"
                />
                <Flex key={"flex1"} column style={template}>
                    <Label content="Téléphone Pro" />
                    <Controller
                        name="phone_number"
                        control={control}
                        render={({ field: { ...fieldProps } }) => {
                            return (
                                <MuiPhoneNumberStyle
                                    {...fieldProps}
                                    type="tel"
                                    autoFormat={true}
                                    disableAreaCodes={true}
                                    defaultCountry="fr"
                                    onlyCountries={[
                                        "dz",
                                        "fr",
                                        "us",
                                        "ch",
                                        "de",
                                        "it",
                                        "ca",
                                        "lu",
                                        "mc",
                                        "nl",
                                        "no",
                                        "pl",
                                        "pt",
                                        "tr",
                                    ]}
                                    // disableCountryCode={true}
                                />
                            );
                        }}
                        error={errors?.phone_number ? true : false}
                    />

                    {errors?.phone_number && (
                        <MessageError
                            error
                            content={errors?.phone_number?.message}
                        />
                    )}
                </Flex>

                <Flex column key={"flex2"} style={template}>
                    <Label content="À propos" />
                    <TextArea
                        name="about_me"
                        {...register("about_me")}
                        defaultValue={getValues("about_me")}
                        inverted
                        fluid
                        rows="6"
                        // error={errors.about_me ? true : false}
                    />
                    {/* {errors?.about_me && (
            <MessageError error content={errors.about_me?.message} />
          )} */}
                </Flex>
            </InformationGeneral>
            {/* Affiliation */}
            <Affiliation column fill>
                <Title weight="bold" content="Affiliation" />
                {/* Radio group Role */}
                <Role column key={"flex3"}>
                    <Label>Rôle</Label>

                    <Controller
                        control={control}
                        name="contract.role"
                        render={({ field: { onChange, onBlur, ref } }) => (
                            <RadioGroup
                                onChange={(e) =>
                                    onChange(parseInt(e.target.value))
                                }
                            >
                                {SETUP_PROFILE.ROLE.map((el, index) => {
                                    return (
                                        <Radio
                                            checked={
                                                el?.id ===
                                                getValues("contract.role")
                                            }
                                            key={index}
                                            label={el.name}
                                            value={el.id}
                                            onBlur={onBlur}
                                            ref={ref}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        )}
                        error={errors?.contract?.role ? true : false}
                    />
                    {errors?.contract?.role && (
                        <MessageError
                            error
                            content={errors?.contract?.role?.message}
                        />
                    )}
                </Role>
                {/* Manager list */}
                <Manager column key={"flex4"}>
                    <Label>Manager</Label>
                    <Controller
                        name="manager.email"
                        control={control}
                        render={({ field: { onChange, onBlur, ref } }) => {
                            return (
                                <DropdownStyle
                                    defaultValue={getValues("manager.email")}
                                    ref={ref}
                                    onBlur={onBlur}
                                    onChange={(_, i) => onChange(i.value.email)}
                                    key="keydrop"
                                    inverted
                                    items={dataManager?.map((el, index) => {
                                        return {
                                            header:
                                                el?.last_name +
                                                " " +
                                                el?.first_name,
                                            content: "",
                                            email: el?.email,
                                            image: el?.avatar || AVATAR_DEFAULT,
                                            key: index,
                                        };
                                    })}
                                    placeholder="Selectionner un Manager"
                                    fluid
                                    style={template}
                                />
                            );
                        }}
                        error={errors?.manager?.email ? true : false}
                    />
                    <div style={{ marginTop: "-16px" }}>
                        {errors?.manager?.email && (
                            <MessageError
                                error
                                content={errors?.manager?.email?.message}
                            />
                        )}
                    </div>
                </Manager>

                {/* Service list */}
                <Service style={{ marginTop: "13px" }} column key={"flex5"}>
                    <FlexItem>
                        <Label>Département</Label>
                    </FlexItem>

                    <FlexItem>
                        <Controller
                            control={control}
                            name="department.name"
                            render={({ field: { onBlur } }) => {
                                return (
                                    <DropdownStyle
                                        defaultValue={
                                            dataService?.find(
                                                (el) =>
                                                    el?.id ===
                                                    getValues("department.id")
                                            )?.name
                                        }
                                        onChange={(_, i) => {
                                            setValue(
                                                "department.name",
                                                i.value
                                            ); // name
                                            setValue(
                                                "department.id",
                                                dataService?.find(
                                                    (el) =>
                                                        el?.name === i?.value
                                                )?.id
                                            ); // get the current id selected.
                                        }}
                                        onBlur={onBlur}
                                        items={dataService?.map(
                                            (el) => el.name
                                        )}
                                        placeholder="Selectionner un Service"
                                        fluid
                                        inverted
                                        style={template}
                                    />
                                );
                            }}
                            error={errors?.department?.name ? true : false}
                        />
                    </FlexItem>
                </Service>
                <Agency column fill>
                    <Label>Bureau</Label>
                    <Controller
                        control={control}
                        name="agency.name"
                        render={({ field: { onBlur } }) => {
                            return (
                                <DropdownStyle
                                    defaultValue={getValues("agency.name")}
                                    style={template}
                                    onBlur={onBlur}
                                    onChange={(_, i) => {
                                        setValue(
                                            "agency.id",
                                            dataAgency?.find(
                                                (el) => el?.name === i?.value
                                            )?.id
                                        );
                                        setValue("agency.name", i?.value);
                                    }}
                                    inverted
                                    items={dataAgency?.map((el) => el.name)}
                                    placeholder="Selectionner un agency"
                                    fluid
                                />
                            );
                        }}
                        error={errors?.agency?.name ? true : false}
                    />
                    {errors?.agency?.name && (
                        <MessageError content={errors?.agency?.name?.message} />
                    )}
                </Agency>
                <EmergencyContact column fill>
                    <Title weight="bold" content="Contacts d'urgence" />
                    <Responsive gap="gap.small">
                        <ContactUrgence
                            index={"0"}
                            number={"1"}
                            bordercolor={"#09347A"}
                        />
                        <ContactUrgence
                            index={"1"}
                            number={"2"}
                            bordercolor={"#1561D4"}
                        />
                    </Responsive>
                </EmergencyContact>
            </Affiliation>
        </AccordionStyle>
    );
};

export default Resume;

const Label = styled(Text)`
    font-size: 12px;
    font-style: normal;
    padding: 4px;
    color: #616161;
`;
const AccordionStyle = styled(Accordion)`
    .ui-accordion__title {
        padding-bottom: 0px;
    }
    .Accordion__checkbox--header {
        padding-bottom: 10px;
    }
    .ui-accordion__title__content-wrapper > .ui-box {
        background-repeat: no-repeat;
        background-position-y: 5px;
    }
`;
const MuiPhoneNumberStyle = styled(MuiPhoneNumber)`
    background-color: white;
    border-radius: 4px;
    .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
        border-color: rgb(91, 95, 199) !important;
    }
    .MuiInput-underline {
        padding: 2px 8px !important;
    }
    .MuiInput-underline::before {
        border: 0px !important;
    }
`;
const DropdownStyle = styled(Dropdown)``;
const InformationGeneral = styled(Flex)``;
const Affiliation = styled(Flex)``;
const Title = styled(Text)`
    padding-top: 16px;
    padding-bottom: 16px;
`;

const Service = styled(Flex)``;
const Manager = styled(Flex)``;
const Role = styled(Flex)``;
const Agency = styled(Flex)``;
const EmergencyContact = styled(Flex)``;

const Responsive = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

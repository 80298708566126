import React, { useEffect, useRef, useState } from "react";
import {
    Alert,
    Avatar,
    Button,
    CheckmarkCircleIcon,
    Dialog,
    Dropdown,
    EditIcon,
    ExclamationTriangleIcon,
    Flex,
    Image,
    InfoIcon,
    Input,
    InputLabel,
    Loader,
    MenuButton,
    Text,
    TextArea,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import { AnnotationViewer, getImagesFromPDF } from "react-mindee-js";

import {
    Container,
    ContainerFluid,
    ErrorMessage,
} from "../../../common/styles";
import {
    CURRENCIES,
    I18N_NS_EXPENSE,
    QUERY_KEY,
    VALIDATORS_ADD_EXPENSE,
} from "../constants";
import UploadFile from "../../../components/shared/UploadFile";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    _api_add_expense,
    _api_get_currencies,
    _api_get_expense,
    _api_get_vats,
    _api_update_expense,
} from "../api";
import { ROUTES } from "../../../constants/routes";
import { get_symbol_currency } from "../functions";
import { useAuthDetailStore } from "../../../stores/useAuthStore";
import DropzoneExpense from "../../../components/dropzone/DropzoneExpense";
import { _api_upload_ocr } from "../../../api/tasks";
import DialogLoaderUploadingOCR from "./Dialogs/DialogLoaderUploadingOCR";
import {
    EXPENSES_ACCEPT_EXTENSIONS,
    FILE_SIZE_LIMIT,
} from "../../documents/constants";
import { currency_code_to_symbols } from "../../../constants/maths";
import ModalContentListCurrencies from "./Dialogs/ModalContentListCurrencies";
import ModalContentRateExchange from "./Dialogs/ModalContentRateExchange";
import { DropdownStyled } from "./ExpensesAddFormClassic";
import { string } from "prop-types";
import Toast from "../../../components/Alerts";
import { getExtensionDocument, toBase64 } from "../../../utils/functions";
import CarouselOCR from "./Carousel";
import { useWhereIamExpenses } from "../store/useWhereIam.store";

import { CalendarChatFilled } from "@fluentui/react-icons";
import {
    Message,
    MessageText,
} from "../pages/ExpensesDetailReport/ChatMessage";
import moment from "moment";
import CommentSection from "./CommentSection/CommentSection";
import CategoryExpense from "./CategoryExpense/CategoryExpense";
import { DEVICE } from "../../../constants/responsive";

const ExpensesUpdateFormClassic = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const navigate = useNavigate();
    const location = useLocation();

    const { agency } = useAuthDetailStore((state) => state.detail);
    const CURRENCY_AGENCY = agency?.currency;

    const default_currency = CURRENCIES?.find(
        (curr) => curr.value.toLowerCase() === agency?.currency?.toLowerCase()
    );

    const inputFileRef = useRef(null);
    const [currency, setCurrency] = useState(default_currency);
    const [coordinatesShapes, setCoordinatesShapes] = useState([]);
    const [rateExchange, setRateExchange] = useState(1);
    const [currencyChoosedModal, setCurrencyChoosedModal] = useState(null);
    const [openDialogListCurrencies, setOpenDialogListCurrencies] =
        useState(false);
    const [openDialogRateChange, setOpenDialogRateChange] = useState(false);
    const [configToast, setConfigToast] = useState({
        visible: false,
        level: "success",
        content: "",
    });
    const [mindeeImages, setMindeeImages] = useState(null);
    const [shapePageIndex, setShapePageIndex] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(null);
    const [imageSelectedOCR, setImageSelectedOCR] = useState({
        image: null,
        index_image: null,
    });

    const whereIam = useWhereIamExpenses();

    const {
        id_category: param_id_category,
        id_expense: param_id_expense,
        id_report: param_id_report,
        type_form: param_type_form,
    } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const params_tva = searchParams.get("d_tva");

    const path_expense = ROUTES.expenses.home;
    const path_detail = ROUTES.expenses.detail;

    const _goback = () => {
        return navigate(-1);
    };

    const {
        data: vats,
        isLoading: isLoadingVats,
        isFetching: isFetchingVats,
    } = useQuery(QUERY_KEY + "::vats", () => _api_get_vats(), {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
    });

    const {
        data,
        isLoading: isLoadingExpense,
        isFetching: isFetchingExpense,
    } = useQuery(
        [QUERY_KEY + "::expense", param_id_expense],
        () =>
            _api_get_expense({
                id_expense: param_id_expense,
                id_expense_report: param_id_report,
            }),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            enabled: vats?.length !== 0,
            onSuccess: async (data) => {
                // if (!data?.success) {
                //     return navigate(ROUTES.expenses.home);
                // }
                const ext_file = getExtensionDocument(
                    data?.expense?.documents.at(0)?.name
                );
                if (ext_file === "pdf") {
                    const first_document = data?.expense?.documents?.at(0);
                    getImagesFromPDF(first_document?.attached_doc)
                        .then((images) => {
                            setMindeeImages(images);
                            setImageSelectedOCR({
                                image: images?.at(0),
                                index_image: 0,
                            });
                        })
                        .finally(() => {
                            console.log(
                                "remove the loader when PDF is spliting to Images .."
                            );
                        });
                }

                const default_tva = vats?.find(
                    (el) =>
                        parseFloat(el?.value) ===
                        parseFloat(data?.expense?.vats?.at(0)?.value)
                );

                // find the "Autres" element
                const default_tva_other = vats?.find((el) => el?.value === -1);

                const default_currency = data?.expense?.currency;
                setValue("date", data?.expense?.date);
                setValue("category", data?.expense?.category);
                setValue("montant_ttc", data?.expense?.declared_amount);
                setValue(
                    "taux_tva",
                    default_tva?.value >= 0
                        ? {
                              header: default_tva?.name,
                              value: default_tva?.value,
                          }
                        : {
                              header: default_tva_other?.name,
                              value: default_tva_other?.value,
                          }
                );
                setValue(
                    "montant_tva",
                    data?.expense?.vats?.at(0)?.declared_amount?.toString()
                );
                setValue("comment", data?.expense?.comment);
                setValue("currency", default_currency);
                setCurrency({
                    value: default_currency,
                });

                setCurrencyChoosedModal({
                    value: default_currency,
                    rate: data?.expense?.rate,
                });
                setRateExchange(data?.expense?.rate);
                setCoordinatesShapes([]);
                setShapePageIndex(
                    data?.expense?.date?.page_id ??
                        data?.expense?.taxes?.at(0)?.page_id ??
                        data?.expense?.total_amount?.page_id
                );

                setImageSelectedOCR({
                    image: data?.expense?.documents?.at(0)?.attached_doc,
                    index_image:
                        data?.expense?.date?.page_id ??
                        data?.expense?.taxes?.at(0)?.page_id ??
                        data?.expense?.total_amount?.page_id,
                });
            },
        }
    );

    // get list currencies
    const { data: currencies, isLoading: isLoadingCurrencies } = useQuery(
        QUERY_KEY + "::currencies",
        () => _api_get_currencies(),
        {
            refetchOnWindowFocus: false,
        }
    );

    const { mutate, isLoading: isMutating } = useMutation((datas) => {
        return _api_update_expense({
            id_user: data?.expense?.user?.id,
            id_expense: param_id_expense,
            expense_date: datas?.date,
            amount: parseFloat(datas?.montant_ttc),
            currency: currency?.value,
            comment: datas?.comment,
            new_document_ids: datas?.document === null ? [] : [datas?.document],
            rate: rateExchange,
            vats: [
                {
                    value: datas?.taux_tva?.value,
                    amount:
                        datas?.montant_tva < 0
                            ? datas?.montant_tva * -1
                            : parseFloat(datas?.montant_tva),
                },
            ],
            category_id: datas?.category_id,
        });
    });

    const {
        mutate: mutateUploadOCR,
        isLoading: isLoadingOCR,
        data: dataOCR,
    } = useMutation((data) => _api_upload_ocr(data), {
        onSuccess: (data) => {
            return;
        },
    });

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        setValue,
        setError,
        trigger,
        watch,
        getValues,
    } = useForm({
        mode: "all",
        defaultValues: {
            document: null,
            currency: CURRENCIES[0]?.value,
            taux_tva: null,
            category: null,
        },
    });

    let isEmptyOCR = false;

    // si OCR à fonctionné
    useEffect(() => {
        if (dataOCR?.ocr_success) {
            const ext_file = getExtensionDocument(
                dataOCR?.uploaded_file.filename
            );
            if (ext_file === "pdf") {
                getImagesFromPDF(fileUploaded)
                    .then((images) => {
                        setMindeeImages(images);
                        setImageSelectedOCR({
                            image: images?.at(0),
                            index_image: 0,
                        });
                    })
                    .finally(() => {
                        console.log(
                            "remove the loader when PDF is spliting to Images .."
                        );
                    });
            }
            const coordinates_date = {
                id: 1,
                coordinates: dataOCR?.ocr_data.date.coordinates,
            };

            const coordinates_total_amount = {
                id: 2,
                coordinates: dataOCR?.ocr_data.total_amount.coordinates,
            };
            const coordinates_taxes = dataOCR?.ocr_data.taxes.map((tax, i) => {
                return {
                    id: i + 3,
                    coordinates: tax.coordinates,
                };
            });

            isEmptyOCR =
                dataOCR?.ocr_data.taxes.length === 0 &&
                dataOCR?.ocr_data.date.coordinates?.length === 0 &&
                dataOCR?.ocr_data.total_amount.coordinates?.length === 0;

            const all_coordinates = [
                coordinates_date,
                coordinates_total_amount,
                ...coordinates_taxes,
            ];
            setCoordinatesShapes(all_coordinates);
            setShapePageIndex(
                dataOCR?.ocr_data?.date?.page_id ??
                    dataOCR?.ocr_data?.taxes?.at(0)?.page_id ??
                    dataOCR?.ocr_data?.total_amount?.page_id
            );
            setImageSelectedOCR({
                image: dataOCR?.uploaded_file.uploaded_doc,
                index_image:
                    dataOCR?.date?.page_id ??
                    dataOCR?.taxes?.at(0)?.page_id ??
                    dataOCR?.total_amount?.page_id,
            });
            // add values to the form
            setValue("date", dataOCR?.ocr_data.date.value);
            setValue("montant_ttc", dataOCR?.ocr_data.total_amount?.value);
            setValue(
                "montant_tva",
                dataOCR?.ocr_data?.currency !== CURRENCY_AGENCY &&
                    VAT_ZERO_FOREIGN_CURRENCY
                    ? "0"
                    : dataOCR?.ocr_data.tax_total.value?.toString()
            );
            // const ocr_currency = CURRENCIES.find(
            //     (currency) => currency?.value === dataOCR?.ocr_data?.currency
            // );
            setValue("currency", dataOCR?.ocr_data?.currency);
            setCurrency({
                value: dataOCR?.ocr_data?.currency,
            });
            const findTVA = vats?.find(
                (vat) => vat?.value === dataOCR?.ocr_data.tax_total.rate
            );

            const default_tva_other = (vats ?? [])?.find(
                (el) => el?.value === -1
            );

            const tva_zero = (vats ?? [])?.find((el) => el?.value === 0);

            const new_taux_tva = {
                header:
                    dataOCR?.ocr_data?.currency !== CURRENCY_AGENCY &&
                    VAT_ZERO_FOREIGN_CURRENCY
                        ? tva_zero?.name
                        : findTVA !== undefined
                        ? findTVA.name
                        : default_tva_other?.name,
                value:
                    dataOCR?.ocr_data?.currency !== CURRENCY_AGENCY &&
                    VAT_ZERO_FOREIGN_CURRENCY
                        ? tva_zero?.value
                        : findTVA !== undefined
                        ? findTVA?.value
                        : -1,
                rate: dataOCR?.ocr_data.tax_total.rate,
            };
            console.log({ new_taux_tva, CURRENCY_AGENCY });

            setValue("taux_tva", new_taux_tva);

            setValue("document", dataOCR?.uploaded_file?.id);
        }
    }, [dataOCR]);

    /**
     * submit add expense
     */
    const onSubmit = (data) => {
        if (Number(data?.montant_ttc) < Number(data?.montant_tva)) {
            return setConfigToast({
                visible: true,
                content:
                    "Le montant de la TVA doit être inférieur a celui du TTC",
                level: "danger",
            });
        }
        const new_category = getValues("category");
        mutate(
            { ...data, dataOCR, category_id: new_category?.id },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        const path_detail_params = path_detail.custom_route(
                            path_detail.route,
                            param_id_report
                        );
                        return navigate(-1);
                    } else {
                        alert("error onsubmit");
                    }
                },
            }
        );
    };

    const onChangeFile = async (acceptedFiles) => {
        const array_files = Array.from(acceptedFiles);
        if (!array_files?.length) {
            return;
        }
        // limit the import to 1 file.
        if (array_files.length > 1) {
            console.log({ error: "un seul fichier a la fois " });
            return;
        }

        const ext_file = getExtensionDocument(array_files?.at(0)?.name);
        const is_valid_document = EXPENSES_ACCEPT_EXTENSIONS.includes(ext_file);

        const file = array_files.at(0);
        if (file.size > FILE_SIZE_LIMIT) {
            // s'il y a un Snackbar props alors afficher l'alert.
            setConfigToast({
                visible: true,
                content:
                    "Le fichier est trop volumineux, il doit faire moins de 5 Mo",
                level: "danger",
            });
            return false;
        }
        if (!is_valid_document) {
            setConfigToast({
                visible: true,
                content:
                    "Format non autorisé. Seuls sont autorisés les formats: .pdf, .jpg, .png, .tiff, .webp, .heic.",
                level: "danger",
            });
            return false;
        }
        let formData = new FormData();
        formData.append("uploaded_files", file);
        // reset data for slider PDF IMAGES
        setMindeeImages(null);
        setCoordinatesShapes([]);
        setShapePageIndex(null);
        setImageSelectedOCR({
            image: null,
            index_image: null,
        });
        // envoyer coté back
        uploadFile(formData);
    };

    const uploadFile = async (formData) => {
        // save the Base64 file
        const file = formData.get("uploaded_files");
        const ext_file = getExtensionDocument(file?.name);
        const is_valid_document = EXPENSES_ACCEPT_EXTENSIONS.includes(ext_file);

        if (file.size > FILE_SIZE_LIMIT) {
            setConfigToast({
                visible: true,
                content:
                    "Le fichier est trop volumineux, il doit faire moins de 5 Mo",
                level: "danger",
            });
            return false;
        }
        if (!is_valid_document) {
            setConfigToast({
                visible: true,
                content:
                    "Format non autorisé. Seuls sont autorisés les formats: .pdf, .jpg, .png, .tiff, .webp, .heic.",
                level: "danger",
            });
            return false;
        }
        const base64File = await toBase64(file);
        setFileUploaded(base64File);
        mutateUploadOCR({
            formData,
            id_expense: param_id_report,
        });
        return;
    };

    const onSelectCurrency = (currency_code, rateExchange) => {
        setOpenDialogListCurrencies(false);
        setCurrency({
            value: currency_code,
        });
        setCurrencyChoosedModal({
            value: currency_code,
            rate: rateExchange,
        });
        setRateExchange(rateExchange);
        setValue("currency", currency_code);
        if (currency_code !== CURRENCY_AGENCY && VAT_ZERO_FOREIGN_CURRENCY) {
            // chercher la bonne valeur
            const tva_zero = (vats ?? [])?.find((el) => el?.value === 0);
            // mettre la valeur a zero pourcent
            setValue("taux_tva", {
                ...getValues("taux_tva"),
                header: tva_zero?.name,
                value: tva_zero?.value,
            });
        }
        return;
    };

    const all_vats = vats?.map((el) => {
        return {
            header: el?.name,
            value: el?.value,
        };
    });

    if (
        isLoadingVats ||
        isLoadingExpense ||
        isFetchingExpense ||
        isLoadingCurrencies
    ) {
        return <Loader />;
    }
    const _watch = watch();

    let default_vats = all_vats?.filter(
        (el) => el?.value === parseFloat(params_tva)
    );

    default_vats = default_vats?.length
        ? default_vats
        : all_vats?.filter((el) => el?.value === -1);

    if (!dataOCR?.ocr_data?.message) {
        isEmptyOCR =
            dataOCR?.ocr_data?.taxes?.length === 0 &&
            dataOCR?.ocr_data?.date?.coordinates?.length === 0 &&
            dataOCR?.ocr_data?.total_amount?.coordinates?.length === 0;
    }

    const amountExchange =
        _watch?.montant_ttc / Number(rateExchange.toString().replace(",", "."));
    const totalRateExchange = currency_code_to_symbols({
        amount: isNaN(amountExchange) ? 0 : amountExchange,
        currency: CURRENCY_AGENCY,
    });

    const preListCurrencies = currencies?.slice(0, 3).map((cu) => {
        return {
            id: cu.code,
            header: cu.code,
            rate: cu.rate,
            selected: cu.code === currency?.value,
        };
    });

    const isCurrencyOnPreList = preListCurrencies?.filter(
        (e) => e.header === currencyChoosedModal?.value
    )?.length;

    const addClickedCurrencyToPreList =
        currencyChoosedModal === null || isCurrencyOnPreList
            ? preListCurrencies
            : [
                  {
                      id: currencyChoosedModal?.value,
                      header: currencyChoosedModal?.value,
                      rate: currencyChoosedModal?.rate,
                      selected: currencyChoosedModal?.value === currency?.value,
                  },
                  ...preListCurrencies,
              ];
    const default_rate = preListCurrencies?.find(
        (el) => el?.header === currency?.value
    )?.rate;

    const isOnManagerPage = whereIam.isManagerPage;
    const can_update_category =
        whereIam.isManagerPage ||
        whereIam.isAccountingManager ||
        whereIam.isEmployeePage;

    const get_category = getValues("category");

    const VAT_ZERO_FOREIGN_CURRENCY =
        data?.expense?.user?.vat_zero_foreign_currency;

    return (
        <ContainerFluid>
            <Container gap="gap.large" style={{ marginTop: "24px" }}>
                <Wrapper
                    fill
                    column
                    gap="gap.medium"
                    styles={{ paddingInline: "16px" }}
                >
                    {/* TOASTS */}
                    <Toast
                        onStatusChange={() =>
                            setConfigToast({
                                visible: false,
                                content: "",
                                level: "warning",
                            })
                        }
                        level={configToast.level}
                        content={configToast.content}
                        visible={configToast.visible}
                    />
                    {/* DIALOG CURRENCIES LIST */}
                    <Dialog
                        open={openDialogListCurrencies}
                        header={t("dialogs.dialog_list_currencies.title")}
                        content={
                            <ModalContentListCurrencies
                                list_currencies={currencies}
                                onSelectCurrency={onSelectCurrency}
                            />
                        }
                        confirmButton={{
                            content: t("common.save"),
                            flat: true,
                            styles: {
                                width: "160px",
                            },
                        }}
                        cancelButton={{
                            content: t("common.cancel"),
                            flat: true,
                        }}
                        onCancel={() => setOpenDialogListCurrencies(false)}
                    />
                    {/* DIALOG RATE EXCHANGE */}
                    <Dialog
                        open={openDialogRateChange}
                        header={t("detail.exchange_rate_title")}
                        content={
                            <ModalContentRateExchange
                                amount_expense={
                                    isNaN(_watch.montant_ttc)
                                        ? 0
                                        : _watch.montant_ttc ?? 0
                                }
                                local_currency={CURRENCY_AGENCY}
                                rate_exchange_reference={default_rate ?? 1}
                                rate_currency_local={totalRateExchange}
                                rate_change_input={rateExchange ?? default_rate}
                                currency={currency?.value}
                                onChangeNewRate={(new_rate) =>
                                    setRateExchange(new_rate)
                                }
                            />
                        }
                        confirmButton={{
                            content: t("common.save"),
                            flat: true,
                            styles: {
                                width: "160px",
                            },
                        }}
                        cancelButton={{
                            content: t("common.cancel"),
                            flat: true,
                        }}
                        onCancel={() => setOpenDialogRateChange(false)}
                        onConfirm={() => setOpenDialogRateChange(false)}
                    />
                    {/* input file hidden */}
                    <Input
                        ref={inputFileRef}
                        type="file"
                        onChange={(e) => onChangeFile(e.target.files)}
                        hidden
                    />
                    <Input
                        type="text"
                        value={dataOCR?.uploaded_file?.id}
                        {...register("document")}
                        hidden
                    />
                    <Dialog
                        content={<DialogLoaderUploadingOCR />}
                        open={isLoadingOCR}
                        styles={{ width: "515px" }}
                    />
                    <Flex fill>
                        <HeaderUpdateExpenseFlex
                            // hAlign="center"
                            space="between"
                            gap="gap.large"
                            fill
                        >
                            <TitleAddExpense>
                                {t("forms.update.classic.title_update_expense")}
                            </TitleAddExpense>
                            {/* show/update category */}

                            <CategoryExpense
                                state={{
                                    category: {
                                        icon: get_category?.icon_url,
                                        name: get_category?.name,
                                        id: get_category?.id,
                                    },
                                }}
                                can_update_category={can_update_category}
                                cbCategoryChanged={(new_category) => {
                                    return setValue("category", new_category);
                                }}
                            />
                        </HeaderUpdateExpenseFlex>
                        <Button
                            text
                            primary
                            content={t("common.cancel")}
                            onClick={_goback}
                        />
                    </Flex>
                    {/* BODY */}
                    <UpdateExpenseFlex fill>
                        {/* LEFT SECTION  */}
                        <LeftBoard fill column gap="gap.large">
                            <Flex column gap="gap.small">
                                <OCRTitle
                                    content={t("forms.classic.ocr.title")}
                                />
                                <OCRDescription
                                    content={t("forms.classic.ocr.description")}
                                />
                            </Flex>
                            {/* DROPZONE */}
                            <Flex>
                                {data?.expense?.documents?.length > 0 &&
                                !dataOCR ? (
                                    <WrapperOCR fill hAlign="center" column>
                                        <Flex
                                            styles={{
                                                width: "100%",
                                            }}
                                        >
                                            {/* Header */}
                                            {/* success alert */}
                                            <Alert
                                                success
                                                styles={{
                                                    width: "100%",
                                                    padding: "16px",
                                                }}
                                                icon={<CheckmarkCircleIcon />}
                                                content={
                                                    "Document chargé et caractères reconnus"
                                                }
                                                actions={[
                                                    {
                                                        content:
                                                            "Changer de justificatif",
                                                        key: "change",
                                                        primary: true,
                                                        text: true,
                                                        onClick: () => {
                                                            inputFileRef.current.click();
                                                        },
                                                        styles: {
                                                            textDecoration:
                                                                "underline",
                                                        },
                                                    },
                                                ]}
                                            />
                                        </Flex>

                                        <AnnotationViewer
                                            data={{
                                                image: imageSelectedOCR?.image,
                                                shapes:
                                                    mindeeImages?.length > 0
                                                        ? imageSelectedOCR.index_image ===
                                                          shapePageIndex
                                                            ? coordinatesShapes
                                                            : null
                                                        : coordinatesShapes,
                                            }}
                                            style={{
                                                maxWidth: "510px",
                                                width: "100%",
                                                height: "637px",
                                                aspectRatio: "1",
                                            }}
                                        />

                                        {/* show slider gallery */}
                                        {mindeeImages && (
                                            <CarouselOCR
                                                images={mindeeImages}
                                                onSlideImage={(index_image) => {
                                                    const selected_image =
                                                        mindeeImages?.at(
                                                            index_image
                                                        );
                                                    setImageSelectedOCR({
                                                        image: selected_image,
                                                        index_image,
                                                    });
                                                }}
                                            />
                                        )}
                                    </WrapperOCR>
                                ) : dataOCR?.upload_success ? (
                                    <WrapperOCR fill hAlign="center" column>
                                        <Flex
                                            styles={{
                                                width: "100%",
                                            }}
                                        >
                                            {/* Header */}
                                            {/* success alert */}
                                            {dataOCR?.ocr_success &&
                                                !isEmptyOCR && (
                                                    <Alert
                                                        success
                                                        styles={{
                                                            width: "100%",
                                                            padding: "16px",
                                                        }}
                                                        icon={
                                                            <CheckmarkCircleIcon />
                                                        }
                                                        content={
                                                            "Document chargé et caractères reconnus"
                                                        }
                                                        actions={[
                                                            {
                                                                content:
                                                                    "Changer de justificatif",
                                                                key: "change",
                                                                primary: true,
                                                                text: true,
                                                                onClick: () => {
                                                                    inputFileRef.current.click();
                                                                },
                                                                styles: {
                                                                    textDecoration:
                                                                        "underline",
                                                                },
                                                            },
                                                        ]}
                                                    />
                                                )}
                                            {((dataOCR?.ocr_success &&
                                                isEmptyOCR) ||
                                                dataOCR?.ocr_data?.message) && (
                                                <Alert
                                                    warning
                                                    styles={{
                                                        width: "100%",
                                                        padding: "16px",
                                                    }}
                                                    icon={
                                                        <ExclamationTriangleIcon />
                                                    }
                                                    content={
                                                        "Document chargé mais aucun caractère reconnu"
                                                    }
                                                    actions={[
                                                        {
                                                            content:
                                                                "Changer de justificatif",
                                                            key: "change",
                                                            flat: true,
                                                            onClick: () => {
                                                                inputFileRef.current.click();
                                                            },
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </Flex>
                                        <AnnotationViewer
                                            data={{
                                                image: imageSelectedOCR?.image,
                                                shapes:
                                                    mindeeImages?.length > 0
                                                        ? imageSelectedOCR.index_image ===
                                                          shapePageIndex
                                                            ? coordinatesShapes
                                                            : null
                                                        : coordinatesShapes,
                                            }}
                                            style={{
                                                maxWidth: "510px",
                                                height: "637px",
                                                aspectRatio: "1",
                                            }}
                                        />
                                        {/* show slider gallery */}
                                        {mindeeImages && (
                                            <CarouselOCR
                                                images={mindeeImages}
                                                onSlideImage={(index_image) => {
                                                    const selected_image =
                                                        mindeeImages?.at(
                                                            index_image
                                                        );
                                                    setImageSelectedOCR({
                                                        image: selected_image,
                                                        index_image,
                                                    });
                                                }}
                                            />
                                        )}
                                    </WrapperOCR>
                                ) : (
                                    <DropzoneExpense
                                        uploadFile={(data) => uploadFile(data)}
                                        onExtensionError={() => {
                                            return setConfigToast({
                                                visible: true,
                                                content:
                                                    "Format non autorisé. Seuls sont autorisés les formats: .pdf, .jpg, .png, .tiff, .webp, .heic.",
                                                level: "danger",
                                            });
                                        }}
                                        onSizeError={() => {
                                            return setConfigToast({
                                                visible: true,
                                                content:
                                                    "Le fichier est trop volumineux, il doit faire moins de 5 Mo",
                                                level: "danger",
                                            });
                                        }}
                                    />
                                )}
                            </Flex>
                        </LeftBoard>
                        {/* RIGHT SECTION */}
                        <RightBoard column gap="gap.large">
                            <Flex vAlign="center" hAlign="center">
                                <OCRTitle
                                    content={t("forms.classic.ocr.step2")}
                                />
                            </Flex>
                            <Flex
                                column
                                gap="gap.large"
                                as="form"
                                onSubmit={handleSubmit(onSubmit)}
                                fill
                            >
                                <Flex column fill gap="gap.medium">
                                    <Flex column>
                                        <Controller
                                            control={control}
                                            name="date"
                                            rules={VALIDATORS_ADD_EXPENSE.date}
                                            render={({
                                                field: { onChange, ...rest },
                                            }) => (
                                                <Input
                                                    label={t(
                                                        "forms.classic.fields.date.label"
                                                    )}
                                                    onChange={(e, i) =>
                                                        onChange(i?.value)
                                                    }
                                                    fluid
                                                    type="date"
                                                    error={
                                                        !!errors?.date?.message
                                                    }
                                                    {...rest}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            content={t(errors?.date?.message)}
                                        />
                                    </Flex>
                                    <Flex column fill>
                                        <InputLabel
                                            content={t(
                                                "forms.classic.fields.montant_ttc.label"
                                            )}
                                        />
                                        <Flex vAlign="end">
                                            <Controller
                                                control={control}
                                                name="montant_ttc"
                                                rules={
                                                    VALIDATORS_ADD_EXPENSE.montant_ttc
                                                }
                                                render={({
                                                    field: {
                                                        onChange,
                                                        ...rest
                                                    },
                                                }) => (
                                                    <Input
                                                        fluid
                                                        min="0"
                                                        type="number"
                                                        clearable
                                                        onChange={(e) => {
                                                            const taux_tva =
                                                                _watch.taux_tva;
                                                            let value =
                                                                e.target.value
                                                                    .replaceAll(
                                                                        "+",
                                                                        ""
                                                                    )
                                                                    .replaceAll(
                                                                        "-",
                                                                        ""
                                                                    );

                                                            let parsed;
                                                            if (
                                                                value.includes(
                                                                    "."
                                                                ) &&
                                                                value?.at(
                                                                    value?.length -
                                                                        1
                                                                ) === "."
                                                            ) {
                                                                // le point est a la fin
                                                                parsed =
                                                                    value + ".";
                                                            } else {
                                                                parsed = value;
                                                            }

                                                            if (parsed < 0) {
                                                                parsed =
                                                                    parsed * -1;
                                                            }

                                                            const montant_ttc =
                                                                parsed;
                                                            const taux_tvas =
                                                                getValues(
                                                                    "taux_tva"
                                                                );

                                                            const montant_tva =
                                                                taux_tvas?.value ===
                                                                -1
                                                                    ? 0
                                                                    : montant_ttc *
                                                                      (taux_tvas?.value /
                                                                          (100 +
                                                                              taux_tvas?.value));

                                                            if (
                                                                taux_tva?.value !==
                                                                -1
                                                            ) {
                                                                setValue(
                                                                    "montant_tva",
                                                                    parseFloat(
                                                                        montant_tva
                                                                    ).toFixed(2)
                                                                );
                                                            }
                                                            setValue(
                                                                "montant_ttc",
                                                                montant_ttc
                                                            );
                                                            trigger(
                                                                "montant_ttc"
                                                            );
                                                            return;
                                                        }}
                                                        error={
                                                            !!errors
                                                                ?.montant_ttc
                                                                ?.message
                                                        }
                                                        step="any"
                                                        errorIndicator={false}
                                                        {...rest}
                                                    />
                                                )}
                                            />
                                            <DropdownStyled
                                                items={[
                                                    ...addClickedCurrencyToPreList,
                                                    {
                                                        id: 0,
                                                        header: "Autre devise",
                                                        rate: 1,
                                                        selected: false,
                                                    },
                                                ]}
                                                value={currency?.value}
                                                checkable
                                                getA11ySelectionMessage={{
                                                    onAdd: (item) =>
                                                        `${item} has been selected.`,
                                                }}
                                                onChange={(e, i) => {
                                                    const value =
                                                        i?.value?.header;
                                                    if (
                                                        value === "Autre devise"
                                                    ) {
                                                        return setOpenDialogListCurrencies(
                                                            true
                                                        );
                                                    }
                                                    if (
                                                        value !==
                                                            CURRENCY_AGENCY &&
                                                        VAT_ZERO_FOREIGN_CURRENCY
                                                    ) {
                                                        // chercher la bonne valeur
                                                        const tva_zero = (
                                                            vats ?? []
                                                        )?.find(
                                                            (el) =>
                                                                el?.value === 0
                                                        );
                                                        setValue(
                                                            "montant_tva",
                                                            "0"
                                                        );
                                                        // mettre la valeur a zero pourcent
                                                        setValue("taux_tva", {
                                                            ...getValues(
                                                                "taux_tva"
                                                            ),
                                                            header: tva_zero?.name,
                                                            value: tva_zero?.value,
                                                        });
                                                    }
                                                    setRateExchange(
                                                        i?.value?.rate
                                                    );
                                                    return setCurrency({
                                                        value: i?.value?.header,
                                                    });
                                                }}
                                                fluid
                                                styles={{
                                                    maxWidth: "110px",
                                                    width: "110px",
                                                    height: "fit-content",
                                                }}
                                            />
                                        </Flex>
                                        <Flex
                                            gap="gap.small"
                                            vAlign="center"
                                            space="between"
                                            column
                                        >
                                            <ErrorMessage
                                                content={t(
                                                    errors?.montant_ttc?.message
                                                )}
                                            />
                                            {currency?.value !==
                                                CURRENCY_AGENCY && (
                                                <Flex
                                                    fill
                                                    column
                                                    gap="gap.smaller"
                                                >
                                                    <Flex
                                                        gap="gap.small"
                                                        hAlign="end"
                                                        vAlign="center"
                                                        fill
                                                    >
                                                        <Text
                                                            content={`${totalRateExchange} (1 ${CURRENCY_AGENCY} = ${rateExchange} ${currency?.value}) `}
                                                        />
                                                        <EditIcon
                                                            size="small"
                                                            styles={{
                                                                color: "var(--color-primary)",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                                setOpenDialogRateChange(
                                                                    true
                                                                )
                                                            }
                                                        />
                                                    </Flex>
                                                </Flex>
                                            )}
                                            {currency?.value !==
                                                CURRENCY_AGENCY &&
                                                VAT_ZERO_FOREIGN_CURRENCY && (
                                                    <Flex
                                                        fill
                                                        column
                                                        gap="gap.smaller"
                                                    >
                                                        {/* <Flex
                                                                gap="gap.small"
                                                                hAlign="end"
                                                                vAlign="center"
                                                                fill
                                                            >
                                                                <Text
                                                                    content={`${totalRateExchange} (1 ${CURRENCY_AGENCY} = ${rateExchange} ${currency?.value}) `}
                                                                />
                                                                <EditIcon
                                                                    size="small"
                                                                    styles={{
                                                                        color: "var(--color-primary)",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                        setOpenDialogRateChange(
                                                                            true
                                                                        )
                                                                    }
                                                                />
                                                            </Flex> */}
                                                        {/* <Flex fill> */}
                                                        <Alert
                                                            icon={<InfoIcon />}
                                                            content="Taux de TVA réglé à 0% pour cette dépense effectuée à l'étranger"
                                                            styles={{
                                                                width: "100%",
                                                                paddingInline:
                                                                    "5px",
                                                            }}
                                                        />
                                                        {/* </Flex> */}
                                                    </Flex>
                                                )}
                                        </Flex>
                                    </Flex>
                                    <Flex fill gap="gap.medium">
                                        <Flex column fill>
                                            <InputLabel
                                                content={t(
                                                    "forms.classic.fields.taux_tva.label"
                                                )}
                                            />
                                            <Flex column fill>
                                                {!isLoadingVats && (
                                                    <Controller
                                                        control={control}
                                                        name="taux_tva"
                                                        rules={
                                                            VALIDATORS_ADD_EXPENSE.taux_tva
                                                        }
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                onBlur,
                                                                ref,
                                                                name,
                                                                ...rest
                                                            },
                                                        }) => (
                                                            <Dropdown
                                                                items={
                                                                    all_vats ??
                                                                    []
                                                                }
                                                                defaultValue={
                                                                    default_vats[0]
                                                                }
                                                                checkable
                                                                loading={
                                                                    isLoadingVats
                                                                }
                                                                loadingMessage={
                                                                    "chargement"
                                                                }
                                                                name={name}
                                                                ref={ref}
                                                                clearable
                                                                onChange={(
                                                                    e,
                                                                    i
                                                                ) => {
                                                                    const montant_ttc =
                                                                        parseFloat(
                                                                            _watch?.montant_ttc
                                                                        );

                                                                    const taux_tvas =
                                                                        i?.value;

                                                                    const montant_tva =
                                                                        taux_tvas?.value ===
                                                                        -1
                                                                            ? 0
                                                                            : montant_ttc *
                                                                              (taux_tvas?.value /
                                                                                  (100 +
                                                                                      taux_tvas?.value));

                                                                    setValue(
                                                                        "montant_tva",
                                                                        isNaN(
                                                                            montant_tva
                                                                        )
                                                                            ? "0"
                                                                            : montant_tva.toFixed(
                                                                                  2
                                                                              )
                                                                    );
                                                                    setValue(
                                                                        "taux_tva",
                                                                        taux_tvas
                                                                    );
                                                                }}
                                                                error={
                                                                    !!errors
                                                                        ?.taux_tva
                                                                        ?.message
                                                                }
                                                                fluid
                                                                {...rest}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                <ErrorMessage
                                                    content={t(
                                                        errors?.taux_tva
                                                            ?.message
                                                    )}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex column>
                                        <Flex>
                                            <InputLabel
                                                content={t(
                                                    "forms.classic.fields.montant_tva.label"
                                                )}
                                            />
                                        </Flex>
                                        <Flex vAlign="end">
                                            <Controller
                                                control={control}
                                                name="montant_tva"
                                                rules={
                                                    VALIDATORS_ADD_EXPENSE.montant_tva
                                                }
                                                render={({
                                                    field: {
                                                        onChange,
                                                        ...rest
                                                    },
                                                }) => (
                                                    <Input
                                                        type="number"
                                                        min="0"
                                                        step="any"
                                                        onChange={(e) => {
                                                            let value =
                                                                e.target.value
                                                                    .replaceAll(
                                                                        "+",
                                                                        ""
                                                                    )
                                                                    .replaceAll(
                                                                        "-",
                                                                        ""
                                                                    );

                                                            let parsed;
                                                            if (
                                                                value.includes(
                                                                    "."
                                                                ) &&
                                                                value?.at(
                                                                    value?.length -
                                                                        1
                                                                ) === "."
                                                            ) {
                                                                // le point est a la fin
                                                                parsed =
                                                                    value + ".";
                                                            } else {
                                                                parsed = value;
                                                            }

                                                            if (parsed < 0) {
                                                                parsed =
                                                                    parsed * -1;
                                                            }
                                                            setValue(
                                                                "montant_tva",
                                                                parsed
                                                            );
                                                            setValue(
                                                                "taux_tva",
                                                                {
                                                                    value: -1,
                                                                    rate: -1,
                                                                    header: "Autre ou mutli-taux",
                                                                }
                                                            );
                                                            trigger(
                                                                "montant_tva"
                                                            );
                                                        }}
                                                        value={
                                                            _watch?.montant_tva <
                                                            0
                                                                ? "0"
                                                                : _watch?.montant_tva
                                                        }
                                                        defaultValue={
                                                            _watch?.montant_tva
                                                        }
                                                        error={
                                                            !!errors
                                                                ?.montant_tva
                                                                ?.message
                                                        }
                                                        fluid
                                                        errorIndicator={false}
                                                        styles={{
                                                            borderRadius:
                                                                "4px 0px 0px 4px!important",
                                                        }}
                                                        {...rest}
                                                    />
                                                )}
                                            />
                                            <Button
                                                content={currency?.value}
                                                flat
                                                fluid
                                                primary
                                                styles={{
                                                    borderRadius:
                                                        "0px 4px 4px 0px",
                                                    maxWidth: "110px!important",
                                                    border: 0,
                                                }}
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            />
                                        </Flex>
                                        <Flex>
                                            <ErrorMessage
                                                content={t(
                                                    errors?.montant_tva?.message
                                                )}
                                            />
                                        </Flex>
                                    </Flex>
                                    {isOnManagerPage &&
                                        data?.expense?.comment && (
                                            <CommentSection
                                                comment={
                                                    data?.expense?.histories?.at(
                                                        0
                                                    ).description
                                                }
                                                date={
                                                    data?.expense?.histories?.at(
                                                        0
                                                    ).date
                                                }
                                                user={{
                                                    avatar: data?.expense?.user
                                                        ?.avatar,
                                                    name: data?.expense?.user
                                                        ?.name,
                                                }}
                                            />
                                        )}
                                    {!isOnManagerPage && (
                                        <Flex column>
                                            <InputLabel
                                                content={t(
                                                    "forms.classic.fields.comment.label"
                                                )}
                                            />
                                            <TextArea
                                                fluid
                                                error={errors?.comment?.message}
                                                {...register(
                                                    "comment",
                                                    VALIDATORS_ADD_EXPENSE.comment
                                                )}
                                                defaultValue={
                                                    data?.expense?.comment
                                                }
                                                rows={5}
                                            />
                                            <ErrorMessage
                                                content={t(
                                                    errors?.comment?.message
                                                )}
                                            />
                                        </Flex>
                                    )}
                                </Flex>
                                <Flex
                                    fill
                                    vAlign="center"
                                    hAlign="center"
                                    gap="gap.medium"
                                >
                                    <Button
                                        loading={isMutating}
                                        content={t(
                                            "forms.update.classic.save_button"
                                        )}
                                        primary
                                        flat
                                        fluid
                                    />
                                </Flex>
                            </Flex>
                        </RightBoard>
                    </UpdateExpenseFlex>
                </Wrapper>
            </Container>
        </ContainerFluid>
    );
};

export default ExpensesUpdateFormClassic;

const HeaderUpdateExpenseFlex = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.mobileM} {
        flex-direction: column;
    }
    @media ${DEVICE.mobileL} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
    @media ${DEVICE.laptopL} {
        flex-direction: row;
    }
`;

const UpdateExpenseFlex = styled(Flex)`
    margin-top: 15px;
    width: 100%;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        justify-content: space-between;
    }
    @media ${DEVICE.laptopL} {
        flex-direction: row;
        gap: 40px;
        justify-content: start;
    }
`;

const WrapperOCR = styled(Flex)`
    border-radius: 8px;
    border: 1px solid #d1d1d1;
    max-width: 100%;

    /* shadow-4 */
    box-shadow: 0px 1.600000023841858px 3.5999999046325684px 0px
            rgba(0, 0, 0, 0.11),
        0px 0.30000001192092896px 0.8999999761581421px 0px rgba(0, 0, 0, 0.07);
`;

const OCRTitle = styled(Text)`
    color: #424242;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
`;
const OCRDescription = styled(Text)`
    color: #424242;
    /* Medium/400 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const RightBoard = styled(Flex)`
    max-width: 448px;
    height: fit-content;
    background: white;
    padding: 24px 16px 16px 16px;
`;

const LeftBoard = styled(Flex)`
    max-width: 633px;
    padding-bottom: 50px;
`;

const Wrapper = styled(Flex)`
    max-width: 1176px;
    width: 100%;
`;

const Title = styled(Text)`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #242424;
`;

const TitleAddExpense = styled(Text)`
    color: #424242;
    /* Larger/400 */
    white-space: nowrap;
    font-size: clamp(16px, 2vw, 24px);
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
`;

const TextCategory = styled(Text)`
    color: #252423;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
`;

import React from "react";
import { Dialog, Loader } from "@fluentui/react-northstar";
import { useQuery } from "react-query";
import { _api_welcome_screen } from "./api";
import Process from "./components/Process";
import {
    useIsClickGetStarted,
    useSetWelcomeScreenFromBack,
} from "./stores/useOnboarding";

const Onboarding = () => {
    const store_is_clicked_get_started = useIsClickGetStarted();
    const store_set_welcome_from_back = useSetWelcomeScreenFromBack();

    const {
        isLoading,
        isFetching,
        isError,
        data: data_back,
    } = useQuery("welcome_screen", () => _api_welcome_screen(), {
        // refetchOnMount: true,
        refetchOnWindowFocus: false,
        onError: (err) => {
            return console.log({ err });
        },
        onSuccess: (data) => {
            return store_set_welcome_from_back(data);
        },
    });

    if (isLoading || isFetching) {
        return <Loader />;
    }

    return (
        <Dialog
            content={<Process />}
            closeOnOutsideClick={false}
            open={
                (data_back?.display_welcome_screen || !data_back?.cgu_read) &&
                !isError
            }
            styles={{
                minWidth: "764px",
                paddingBottom: "4px",
                backgroundColor: store_is_clicked_get_started.clicked
                    ? "#f5f5f5!important"
                    : "white",
            }}
        />
    );
};

export default Onboarding;

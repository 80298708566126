import React from "react";
import moment from "moment";
import styled from "styled-components";
import { DrawShape20Regular } from "@fluentui/react-icons";
import { Avatar, Flex, Text } from "@fluentui/react-northstar";

import { ROUTES } from "../../../../constants/routes";
import { ColumnDate, ColumnLinkTo, ColumnTitle } from "../../styles/Rows";
import Counter from "../Badges/Counter";
import Status, { LEVELS_BACKEND } from "../Badges/Status";
import TotalExpense from "../Texts/TotalExpense";
import { get_symbol_currency } from "../../functions";
import { str_ellipsis } from "../../../../utils/functions";
import StatusActionsDependRole, {
    STATUS_ROLE,
} from "../Badges/StatusActionsDependRole";
import { useMediaQuery } from "react-responsive";
import { SIZES } from "../../../../constants/responsive";

const ContentManager = ({ content }) => {
    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileM})`,
    });
    const isMobileM = useMediaQuery({
        query: "(max-width: 600px)",
    });
    const isMobile = isMobileS || isMobileM;

    return (
        <Flex column>
            {content?.map((el, index) => {
                const currency_symbol = get_symbol_currency(el?.currency);
                return (
                    <Flex
                        fill
                        space="between"
                        vAlign="center"
                        styles={{
                            borderBottom: "1px solid #D1D1D1",
                            paddingBlock: "8px",
                            paddingRight: "8px",
                        }}
                        key={`ROW_${index}`}
                    >
                        <Flex styles={{ flex: 1.5 }}>
                            <ColumnDate>
                                {moment(el?.created_date).format("DD/MM")}
                            </ColumnDate>
                        </Flex>
                        <Flex
                            vAlign="center"
                            gap="gap.small"
                            styles={{ flex: 4 }}
                        >
                            {isMobile && (
                                <Avatar
                                    image={el?.user?.avatar}
                                    name={
                                        el?.user?.first_name +
                                        " " +
                                        el?.user?.last_name
                                    }
                                    size="smaller"
                                />
                            )}
                            <ColumnLinkTo
                                to={ROUTES.expenses.manager.detail.custom_route(
                                    el?.id,
                                    el?.user?.id
                                )}
                            >
                                <ColumnTitle>
                                    {str_ellipsis({
                                        str: el?.name,
                                        length: 20,
                                    })}
                                </ColumnTitle>
                            </ColumnLinkTo>
                        </Flex>
                        {!isMobile && (
                            <Flex styles={{ flex: 2 }}>
                                <Counter>{el?.expenses_count}</Counter>
                            </Flex>
                        )}
                        {!isMobile && (
                            <Flex
                                styles={{ flex: 6 }}
                                vAlign="center"
                                gap="gap.smaller"
                            >
                                <Avatar
                                    image={el?.user?.avatar}
                                    name={
                                        el?.user?.first_name +
                                        " " +
                                        el?.user?.last_name
                                    }
                                    size="smaller"
                                />
                                <Text
                                    content={
                                        el?.user?.first_name +
                                        " " +
                                        el?.user?.last_name
                                    }
                                />
                            </Flex>
                        )}
                        {!isMobile && (
                            <Flex styles={{ flex: 4 }} gap="gap.small">
                                {el?.client?.name && (
                                    <>
                                        <DrawShape20Regular />
                                        <Text>{el?.client?.name}</Text>
                                    </>
                                )}
                            </Flex>
                        )}
                        {!isMobile && (
                            <Flex styles={{ flex: 6 }}>
                                <StatusActionsDependRole
                                    role={STATUS_ROLE.manager}
                                    status={el?.status?.value}
                                />
                            </Flex>
                        )}
                        {!isMobile && (
                            <Flex styles={{ flex: 2 }} hAlign="center">
                                <Currency content={el?.expense_currencies} />
                            </Flex>
                        )}
                        {!isMobile && (
                            <Flex styles={{ flex: 2 }} hAlign="end">
                                <TotalExpense
                                    content={`${
                                        el?.amount_total ?? "--,-- "
                                    } ${currency_symbol}`}
                                />
                            </Flex>
                        )}

                        <Flex styles={{ flex: 1.5 }} hAlign="end">
                            <TotalExpense
                                content={`${
                                    el?.amount_approved ?? "--,-- "
                                } ${currency_symbol}`}
                                level={LEVELS_BACKEND[el?.status?.value]}
                            />
                        </Flex>
                    </Flex>
                );
            })}
        </Flex>
    );
};

export default ContentManager;

const Currency = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #5b5fc7;
`;

import React, { useMemo } from "react";
import Card from "../..";
import CivilStatus from "../CivilStatus";

import IllustrationCivilStatus from "../../../../../../assets/illustrations/employee_folder/civil_status.svg";
import { ROLES_CODE, roles_access } from "../../../../../../constants/roles";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    _api_update_civil_status,
    _api_update_personal_information,
} from "../../../../api";
import SETUP_PROFILE, { QUERY_KEY } from "../../../../constants";
import {
    Button,
    Dropdown,
    Flex,
    Input,
    InputLabel,
    RadioGroup,
    Text,
    TextArea,
} from "@fluentui/react-northstar";
import { ErrorMessage } from "../../../../../../common/styles";
import {
    DROPDOWN_COUNTRIES_FORMAT,
    getCountryNameByCode,
    getDataCountryByCode,
} from "../../../../../../constants/countries";
import styled from "styled-components";

const CardInformationCivilStatus = ({ identity_data }) => {
    const clientQuery = useQueryClient();
    const isHR = roles_access({
        usersPrivileges: [ROLES_CODE.hr],
    });

    const all_countries = useMemo(() => DROPDOWN_COUNTRIES_FORMAT(), []);

    const [modeUpdate, setModeUpdate] = useState(false);

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        reset,
    } = useForm({
        mode: "onChange",
        defaultValues: {},
    });

    const { mutate: mutate_update_civil_status, isLoading: is_loading_mutate } =
        useMutation((data) => _api_update_civil_status(data));

    const _setModeUpdate = (bool) => {
        if (!bool) {
            reset();
        }
        return setModeUpdate(bool);
    };

    const onSubmit = (data) => {
        mutate_update_civil_status(
            {
                secu: data?.secu,
                avs: data?.avs,
                nationality: data?.nationality,
                birthdate: data?.birthdate,
                place_of_birth: data?.place_of_birth,
                country_of_birth: data?.country_of_birth,
                identity_card_type: data?.identity_card_type,
                identity_card_number: data?.identity_card_number,
                identity_card_expiry_date: data?.identity_card_expiry_date,
                marital_status: data?.marital_status,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        clientQuery.resetQueries([QUERY_KEY + "::identity"]);
                        _setModeUpdate(false);
                    }
                    return;
                },
            }
        );
        return console.log({ data });
    };

    if (modeUpdate) {
        const country_of_birth_data = getDataCountryByCode(
            identity_data?.country_of_birth
        );
        const country_of_birth = all_countries[country_of_birth_data?.index];

        const nationality = getDataCountryByCode(identity_data?.nationality);
        const nationality_data = all_countries[nationality?.index];

        const is_fr = identity_data?.contract_country?.toLowerCase() === "fr";
        const is_ch = identity_data?.contract_country?.toLowerCase() === "ch";

        return (
            <Card column>
                <Card.EditTitle
                    content={"Modification de l’Etat civil"}
                    onClose={() => _setModeUpdate(false)}
                />
                <Card.Body
                    content={
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Flex column gap="gap.medium">
                                {is_fr && (
                                    <>
                                        <Flex column>
                                            <Input
                                                {...register("secu", {
                                                    required:
                                                        "Ce champ est obligatoire",
                                                    minLength: {
                                                        value: 15,
                                                        message:
                                                            "15 caractères obligatoire",
                                                    },
                                                })}
                                                maxLength={15}
                                                error={errors?.secu?.message}
                                                label="N° de sécurité sociale"
                                                defaultValue={
                                                    identity_data?.secu
                                                }
                                                fluid
                                            />
                                            <ErrorMessage
                                                content={errors?.secu?.message}
                                            />
                                        </Flex>
                                    </>
                                )}
                                {is_ch && (
                                    <>
                                        <Flex column>
                                            <Input
                                                {...register("avs", {
                                                    required:
                                                        "Ce champ est obligatoire",
                                                    minLength: {
                                                        value: 13,
                                                        message:
                                                            "13 caractères obligatoire",
                                                    },
                                                })}
                                                maxLength={13}
                                                error={errors?.avs?.message}
                                                label="N° AVS*"
                                                defaultValue={
                                                    identity_data?.avs
                                                }
                                                fluid
                                            />
                                            <ErrorMessage
                                                content={errors?.avs?.message}
                                            />
                                        </Flex>
                                    </>
                                )}

                                <Flex column>
                                    <InputLabel content="Nationalité" />
                                    <Controller
                                        control={control}
                                        name="nationality"
                                        rules={{
                                            required: {
                                                value: true,
                                                message:
                                                    "Ce champ est obligatoire",
                                            },
                                        }}
                                        defaultValue={
                                            nationality_data?.detail?.code
                                        }
                                        render={({ field: { onChange } }) => (
                                            <Dropdown
                                                items={all_countries}
                                                defaultValue={nationality_data}
                                                onChange={(_, i) => {
                                                    return onChange(
                                                        i?.value?.detail?.code
                                                    );
                                                }}
                                                error={
                                                    errors?.nationality?.message
                                                }
                                                checkable
                                                fluid
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        content={errors?.nationality?.message}
                                    />
                                </Flex>
                                <Flex column>
                                    <Input
                                        {...register("birthdate", {
                                            required:
                                                "Ce champ est obligatoire",
                                        })}
                                        type="date"
                                        error={errors?.birthdate?.message}
                                        label="Date de naissance"
                                        defaultValue={identity_data?.birthdate}
                                        fluid
                                    />
                                    <ErrorMessage
                                        content={errors?.birthdate?.message}
                                    />
                                </Flex>
                                <Flex column>
                                    <Input
                                        {...register("place_of_birth")}
                                        error={errors?.place_of_birth?.message}
                                        label="Ville de naissance"
                                        defaultValue={
                                            identity_data?.place_of_birth
                                        }
                                        fluid
                                    />
                                    <ErrorMessage
                                        content={
                                            errors?.place_of_birth?.message
                                        }
                                    />
                                </Flex>
                                <Flex column>
                                    <InputLabel content="Pays de naissance" />
                                    <Controller
                                        control={control}
                                        name="country_of_birth"
                                        defaultValue={
                                            country_of_birth?.detail?.code
                                        }
                                        render={({ field: { onChange } }) => (
                                            <Dropdown
                                                items={all_countries}
                                                defaultValue={country_of_birth}
                                                onChange={(_, i) => {
                                                    return onChange(
                                                        i?.value?.detail?.code
                                                    );
                                                }}
                                                checkable
                                                fluid
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        content={
                                            errors?.country_of_birth?.message
                                        }
                                    />
                                </Flex>
                                <Flex column gap="gap.small">
                                    <InputLabel
                                        content={"Type de pièce d’identité"}
                                    />
                                    <Controller
                                        control={control}
                                        name="identity_card_type"
                                        defaultValue={
                                            identity_data?.identity_card_type
                                        }
                                        render={({
                                            field: { onChange, onBlur },
                                        }) => (
                                            <RadioGroupStyle
                                                {...register(
                                                    "identity_card_type"
                                                )}
                                                defaultCheckedValue={
                                                    identity_data?.identity_card_type
                                                }
                                                items={SETUP_PROFILE.IDENTITY_CARD_TYPE.map(
                                                    (identity_card_type) => {
                                                        return {
                                                            name: identity_card_type.name,
                                                            label: identity_card_type.name,
                                                            value: identity_card_type.id,
                                                            title: identity_card_type.name,
                                                            styles: {
                                                                maxWidth:
                                                                    "150px",
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            },
                                                        };
                                                    }
                                                )}
                                                onBlur={onBlur}
                                                onCheckedValueChange={(_, i) =>
                                                    onChange(i?.value)
                                                }
                                            />
                                        )}
                                    />
                                </Flex>
                                <Flex column>
                                    <Input
                                        {...register("identity_card_number")}
                                        error={
                                            errors?.identity_card_number
                                                ?.message
                                        }
                                        label="Numéro de pièce d’identité"
                                        defaultValue={
                                            identity_data?.identity_card_number
                                        }
                                        fluid
                                    />
                                    <ErrorMessage
                                        content={
                                            errors?.identity_card_number
                                                ?.message
                                        }
                                    />
                                </Flex>
                                <Flex column>
                                    <Input
                                        {...register(
                                            "identity_card_expiry_date"
                                        )}
                                        type="date"
                                        error={
                                            errors?.identity_card_expiry_date
                                                ?.message
                                        }
                                        label="Fin de validité de la pièce d’identité"
                                        defaultValue={
                                            identity_data?.identity_card_expiry_date
                                        }
                                        fluid
                                    />
                                    <ErrorMessage
                                        content={
                                            errors?.identity_card_expiry_date
                                                ?.message
                                        }
                                    />
                                </Flex>
                                <Flex column gap="gap.small">
                                    <InputLabel
                                        content={"Situation familiale"}
                                    />
                                    <Controller
                                        control={control}
                                        name="marital_status"
                                        defaultValue={
                                            identity_data?.marital_status
                                        }
                                        render={({
                                            field: { onChange, onBlur },
                                        }) => (
                                            <RadioGroupStyle
                                                {...register("marital_status")}
                                                defaultCheckedValue={
                                                    identity_data?.marital_status
                                                }
                                                items={SETUP_PROFILE.MARITAL_STATUS.map(
                                                    (marital_status) => {
                                                        return {
                                                            name: marital_status.name,
                                                            label: marital_status.name,
                                                            value: marital_status.id,
                                                            title: marital_status.name,
                                                            styles: {
                                                                maxWidth:
                                                                    "150px",
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            },
                                                        };
                                                    }
                                                )}
                                                onBlur={onBlur}
                                                onCheckedValueChange={(_, i) =>
                                                    onChange(i?.value)
                                                }
                                            />
                                        )}
                                    />
                                </Flex>
                                <Flex hAlign="center" gap="gap.medium">
                                    <Button
                                        content={"Annuler"}
                                        onClick={() => _setModeUpdate(false)}
                                        flat
                                    />
                                    <Button
                                        content={
                                            "Enregistrer les modifications"
                                        }
                                        loading={is_loading_mutate}
                                        primary
                                        flat
                                    />
                                </Flex>
                            </Flex>
                        </form>
                    }
                />
            </Card>
        );
    }

    return (
        <Card column>
            {/* <Card.Illustration
                image={IllustrationCivilStatus}
                canEdit={isHR}
                
            /> */}
            <Card.Title
                content={"Etat civil"}
                canEdit={isHR}
                onEdit={() => {
                    if (!isHR) {
                        return;
                    }
                    return setModeUpdate(true);
                }}
            />
            {/* etat civil */}
            <Card.Body
                content={
                    <CivilStatus
                        secu={identity_data?.secu}
                        nationality={identity_data?.nationality}
                        birthdate={identity_data?.birthdate}
                        place_of_birth={identity_data?.place_of_birth}
                        country_of_birth={identity_data?.country_of_birth}
                        identity_card_type={identity_data?.identity_card_type}
                        identity_card_number={
                            identity_data?.identity_card_number
                        }
                        identity_card_expiry_date={
                            identity_data?.identity_card_expiry_date
                        }
                        marital_status={identity_data?.marital_status}
                        contract_country={identity_data?.contract_country}
                        avs={identity_data?.avs}
                    />
                }
            />
        </Card>
    );
};

export default CardInformationCivilStatus;

const RadioGroupStyle = styled(RadioGroup)`
    .ui-radiogroup__item__label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

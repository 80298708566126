import moment from "moment";
import { _str_capitalize } from "../../../utils/functions";
import { Flex, Tooltip } from "@fluentui/react-northstar";
import { Clock16Regular } from "@fluentui/react-icons";

import { Event, EventText } from "../styles";

import { useQueryClient } from "react-query";

/**
 *
 * @param {number} start is the start time
 * @param {number} end is the end time
 * @returns {object}
 */
export const util_format_start_and_end_time = ({ start, end }) => {
    return {
        start_time: (!start ? "AM" : "PM") + "-S",
        end_time: (!end ? "AM" : "PM") + "-E",
    };
};

export const refetchTeamBadge = (queryClient) => {
    console.log("called !");
    return queryClient.refetchQueries(["teamBadge"]);
};

export const event_content_function = (info) => {
    const isPending = info.event.extendedProps.pending;
    // console.log({ info });
    return (
        <Tooltip
            content="Détail de l'absence"
            pointing
            subtle={false}
            trigger={
                <Event vAlign="center">
                    {isPending && (
                        <Clock16Regular
                            style={{
                                marginRight: "5px",
                            }}
                        />
                    )}
                    <EventText>{info.event.title}</EventText>
                </Event>
            }
        />
    );
};

export const format_events = (back_events = [], bool_title_name = false) => {
    const render_array = back_events?.map((el) => {
        return {
            id: el?.id,
            title: !bool_title_name ? el?.type?.name : el?.name,
            start: el?.start_date,
            end: moment(el?.end_date).add(1, "days").format("YYYY-MM-DD"),
            // end: el?.end_date,
            backgroundColor: el?.type?.color,
            borderColor: el?.type?.color,
            pending: el?.status?.id === 1,
            data: el,
            description: "description",
            className: el?.status?.id === 1 ? "events_pending" : "",
        };
    });
    return render_array;
};

export const format_events_team = (back_events = []) => {
    const flat_arr_formate = back_events
        .map((el) => {
            return {
                ...el,
                name: el?.user?.name,
                leave_user_id: el?.id,
                leave_user_avatar: el?.avatar,
            };
        })
        .flat();

    const render = format_events(flat_arr_formate, true);
    return render;
};
export const format_events_HR = (back_events = []) => {
    const flat_arr_formate = back_events
        .map((el) => {
            return {
                ...el,
                name: el?.user?.name,
                leave_user_id: el?.id,
                leave_user_avatar: el?.avatar,
            };
        })
        .flat();

    const render = format_events(flat_arr_formate, true);
    return render;
};

export const getListMonths = () => {
    const LIMIT_MONTH = 12;
    const list_months = [];
    for (let i = 0; i < LIMIT_MONTH; i++) {
        const month = moment().add(i, "months").format("MMMM YYYY");
        const month_number = moment().add(i, "months").format("M");
        const year_number = moment().add(i, "months").format("YYYY");
        const month_capitalize = _str_capitalize(month);
        list_months.push({
            id: i + 1,
            header: month_capitalize,
            code_month: month_number,
            code_year: year_number,
        });
    }
    return list_months;
};

export function lastDayOfTheMonth(indexMois, annee) {
    // Créer un objet moment pour le premier jour du mois suivant
    const firstDayOfTheMonth = moment({ year: annee, month: indexMois })
        .startOf("month")
        .add(1, "months");

    // Soustraire un jour pour obtenir le dernier jour du mois en cours
    const lastDay_month = firstDayOfTheMonth.subtract(1, "days");

    // Retourner le dernier jour du mois sous forme de chaîne de caractères (ex: "2023-07-31")
    return lastDay_month.format("DD");
}

import React from "react";
import { Button, Flex } from "@fluentui/react-northstar";
import Thumbnail from "./Thumbnail";
import styled from "styled-components";
import { Next24Filled, Previous24Filled } from "@fluentui/react-icons";
import { useState } from "react";
import { useEffect } from "react";

const CarouselOCR = ({ images = [], onSlideImage = () => null }) => {
    const [imageSelectedIndex, setImageSelectedIndex] = useState(0);

    const len_images = images?.length;
    const next_slide = () => {
        setImageSelectedIndex((state) => {
            return state < len_images - 1 ? state + 1 : state;
        });
    };

    const previous_slide = () => {
        setImageSelectedIndex((state) => {
            return state > 0 ? state - 1 : state;
        });
    };

    const click_slide = (index_image) => {
        setImageSelectedIndex(index_image);
    };

    useEffect(() => {
        onSlideImage(imageSelectedIndex);
    }, [imageSelectedIndex]);

    return (
        <Wrapper gap="gap.small">
            <Flex vAlign="center">
                <Button
                    iconOnly
                    icon={<Previous24Filled />}
                    text
                    disabled={imageSelectedIndex === 0}
                    onClick={previous_slide}
                />
            </Flex>
            {images.map((image, index) => {
                return (
                    <Thumbnail
                        key={index}
                        image={image}
                        index={index + 1}
                        selected={imageSelectedIndex === index}
                        onClick={() => click_slide(index)}
                    />
                );
            })}
            <Flex vAlign="center">
                <Button
                    iconOnly
                    icon={<Next24Filled />}
                    text
                    onClick={next_slide}
                    disabled={imageSelectedIndex === len_images - 1}
                />
            </Flex>
        </Wrapper>
    );
};

export default CarouselOCR;

const Wrapper = styled(Flex)`
    margin-block: 24px;
`;

import { Accordion, Flex, Text, TextArea } from "@fluentui/react-northstar";
import MuiPhoneNumber from "material-ui-phone-number";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { DEVICE } from "../../../../constants/responsive";
import MessageError from "../../ForCollaborator/components/MessageError";
import ContactUrgence from "../../ForCollaborator/EditProfil/components/ContactUrgence";

const template = {
    marginTop: "5px",
    marginBottom: "16px",
    marginRight: "24px",
};

export default function ResumeProfilEmployee(props) {
    const {
        register,
        control,
        formState: { errors },
        getValues,
        setValue,
    } = useFormContext();

    const queryClient = useQueryClient();
    const cache_data = queryClient.getQueryData("getUserCurrent");

    React.useEffect(() => {
        if (cache_data?.user) {
            setValue("phone_number", cache_data?.user?.phone_number);
            setValue(
                "personal_phone_number",
                cache_data?.user?.personal_phone_number
            );
            setValue("about_me", cache_data?.user?.about_me);
            setValue(
                "emergency_contacts.0.name",
                cache_data?.user?.emergency_contacts[0]?.name
            );
            setValue(
                "emergency_contacts.1.name",
                cache_data?.user?.emergency_contacts[1]?.name
            );
            setValue(
                "emergency_contacts.0.phone_number",
                cache_data?.user?.emergency_contacts[0]?.phone_number
            );
            setValue(
                "emergency_contacts.1.phone_number",
                cache_data?.user?.emergency_contacts[1]?.phone_number
            );
            setValue(
                "emergency_contacts.0.relationship_with_user",
                cache_data?.user?.emergency_contacts[0]?.relationship_with_user
            );
            setValue(
                "emergency_contacts.1.relationship_with_user",
                cache_data?.user?.emergency_contacts[1]?.relationship_with_user
            );
        }
    }, [cache_data]);

    return (
        <AccordionStyle>
            <InformationGeneral column fill>
                <Text
                    style={{ margin: "15px 0" }}
                    weight="bold"
                    content="Informations générales"
                />

                <PhoneNumber key={"flex1"} column style={template}>
                    <Label content="Téléphone Pro" />
                    <Controller
                        name="phone_number"
                        value={getValues("phone_number")}
                        control={control}
                        render={({ field: { ...fieldProps } }) => {
                            return (
                                <MuiPhoneNumberStyle
                                    {...fieldProps}
                                    type="tel"
                                    defaultCountry={"fr"}
                                    autoFormat={true}
                                    disableAreaCodes={true}
                                    onlyCountries={[
                                        "dz",
                                        "fr",
                                        "us",
                                        "ch",
                                        "de",
                                        "it",
                                        "ca",
                                        "lu",
                                        "mc",
                                        "nl",
                                        "no",
                                        "pl",
                                        "pt",
                                        "tr",
                                    ]}
                                />
                            );
                        }}
                        error={errors?.phone_number ? true : false}
                    />
                    {errors?.phone_number && (
                        <MessageError
                            error
                            content={errors.phone_number?.message}
                        />
                    )}
                </PhoneNumber>

                <Flex column key={"flex2"} style={template}>
                    <Label content="À propos" />
                    <TextArea
                        {...register("about_me")}
                        placeholder={getValues("about_me")}
                        inverted
                        fluid
                        rows="6"
                        error={errors.about_me ? true : false}
                    />
                    {errors?.about_me && (
                        <MessageError
                            error
                            content={errors.about_me?.message}
                        />
                    )}
                </Flex>

                <EmergencyContact column fill>
                    <Title weight="bold" content="Contacts d'urgence" />
                    <Responsive gap="gap.small">
                        <ContactUrgence
                            index={"0"}
                            number={"1"}
                            bordercolor={"#09347A"}
                        />
                        <ContactUrgence
                            index={"1"}
                            number={"2"}
                            bordercolor={"#1561D4"}
                        />
                    </Responsive>
                </EmergencyContact>
            </InformationGeneral>
        </AccordionStyle>
    );
}

const AccordionStyle = styled(Accordion)`
    .ui-accordion__title {
        padding-bottom: 0px;
    }
    .Accordion__checkbox--header {
        padding-bottom: 10px;
    }
    .ui-accordion__title__content-wrapper > .ui-box {
        background-repeat: no-repeat;
        background-position-y: 5px;
    }
`;

const InformationGeneral = styled(Flex)``;
const PhoneNumber = styled(Flex)``;
const EmergencyContact = styled(Flex)``;

const Label = styled(Text)`
    font-size: 12px;
    font-style: normal;
    padding: 4px;
    color: #616161;
`;

const MuiPhoneNumberStyle = styled(MuiPhoneNumber)`
    background-color: white;
    border-radius: 4px;
    .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
        border-color: rgb(91, 95, 199) !important;
    }
    .MuiInput-underline {
        padding: 2px 8px !important;
    }
    .MuiInput-underline::before {
        border: 0px !important;
    }
`;

const Title = styled(Text)`
    padding-top: 16px;
    padding-bottom: 16px;
`;

const Responsive = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

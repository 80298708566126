import { styled } from "@mui/material";
import React from "react";

import OnboardingHeader from "./OnboardingHeader";
import OnBordingContainer from "./OnBordingContainer";

export default function Main() {
    return (
        <Body style={{ width: "100%", minHeight: "100vh" }}>
            <OnboardingHeader />
            <OnBordingContainer />
        </Body>
    );
}

const Body = styled("div")(({ theme }) => ({
    backgroundColor: "#f5f5f5",
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
}));

import React from "react";
import Card from "..";
import { useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import {
    Accordion,
    Button,
    Checkbox,
    Divider,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import { PANELS_ROLES, QUERY_KEY } from "../../../constants";
import { ROLES_CODE, roles_access } from "../../../../../constants/roles";
import { _api_update_roles } from "../../../api";
import IllustrationRoles from "../../../../../assets/illustrations/employee_folder/Role.svg";

const CardRoles = ({ roles, isEmployeeHR, isEmployeeManager }) => {
    const [state, setState] = useState({
        edit: false,
        employee: {
            is_manager: isEmployeeManager,
            is_hr: isEmployeeHR,
        },
    });
    const clientQuery = useQueryClient();

    const { mutate: mutate_update_roles, isLoading: is_loading_mutate_roles } =
        useMutation((data) => _api_update_roles(data));

    const isHR = roles_access({
        usersPrivileges: [ROLES_CODE.hr],
    });

    if (!isHR) {
        return;
    }

    const _update_role = (role, checked) => {
        switch (role) {
            case ROLES_CODE.manager:
                return setState((state) => {
                    return {
                        ...state,
                        employee: {
                            ...state.employee,
                            is_manager: checked,
                        },
                    };
                });
            case ROLES_CODE.hr:
                return setState((state) => {
                    return {
                        ...state,
                        employee: {
                            ...state.employee,
                            is_hr: checked,
                        },
                    };
                });

            default:
                return state;
        }
    };

    const _enable_edit = () => {
        return setState((state) => {
            return {
                ...state,
                edit: true,
            };
        });
    };

    const _disable_edit = () => {
        return setState((state) => {
            return {
                ...state,
                edit: false,
            };
        });
    };

    const _submit_roles = () => {
        const roles = [];
        if (state.employee.is_hr) roles.push(ROLES_CODE.hr);
        if (state.employee.is_manager) roles.push(ROLES_CODE.manager);

        mutate_update_roles(
            { roles },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        clientQuery.refetchQueries([QUERY_KEY + "::about"]);
                        _disable_edit();
                        return;
                    }
                },
            }
        );

        return;
    };

    if (state?.edit) {
        return (
            <Card column>
                <Card.EditTitle
                    content={"Modification de Rôle(s) dans l'outil"}
                    onClose={_disable_edit}
                />
                <Card.Body
                    content={
                        <Flex column gap="gap.small">
                            <Flex>
                                <Title content="Choisissez un rôle pour cet utilisateur" />
                            </Flex>
                            <Flex column gap="gap.medium">
                                <Flex column gap="gap.smaller">
                                    <Divider />
                                    <Checkbox
                                        label="Collaborateur"
                                        checked
                                        disabled
                                    />
                                    <Description content="N’importe quelle utilisateur ajouté dans l’outil dispose de ce rôle, cela lui permet d’apparaître dans l’organisation et de saisir du temps à minima" />
                                </Flex>
                                {/* manager */}
                                <Flex column gap="gap.smaller">
                                    <Divider />
                                    <Checkbox
                                        label="Manager"
                                        defaultChecked={
                                            state.employee.is_manager ?? false
                                        }
                                        user_role={ROLES_CODE.manager}
                                        onChange={(_, { checked, user_role }) =>
                                            _update_role(user_role, checked)
                                        }
                                    />
                                    <Description content="Un manager peut modifier certaines informations des collaborateurs dont il est le manager. Il peut également créer et assigner des tâches ou encore gérer les absences, les notes de frais ou le portage salarial" />
                                </Flex>
                                {/* RH */}
                                <Flex column gap="gap.smaller">
                                    <Divider />
                                    <Checkbox
                                        label={"R.H."}
                                        defaultChecked={
                                            state.employee.is_hr ?? false
                                        }
                                        user_role={ROLES_CODE.hr}
                                        onChange={(_, { checked, user_role }) =>
                                            _update_role(user_role, checked)
                                        }
                                    />
                                    <Description content="Le RH peut voir toutes les informations de tous les profils, il peut également créer des tâches et déposer des documents dans l’espace documentaire de tous les collaborateurs" />
                                </Flex>
                            </Flex>
                            <Flex hAlign="center" gap="gap.medium">
                                <Button
                                    content={"Annuler"}
                                    onClick={_disable_edit}
                                    flat
                                />
                                <Button
                                    content={"Enregistrer les modifications"}
                                    onClick={_submit_roles}
                                    loading={is_loading_mutate_roles}
                                    primary
                                    flat
                                />
                            </Flex>
                        </Flex>
                    }
                />
            </Card>
        );
    }

    return (
        <Card column>
            {/* <Card.Illustration
                image={IllustrationRoles}
                canEdit={true}
                
            /> */}
            <Card.Title
                content={"Rôle(s) dans l’outil"}
                onEdit={_enable_edit}
                canEdit={true}
            />
            <Card.Body
                content={
                    <Flex column gap="gap.large">
                        {roles?.map((role, index) => {
                            return (
                                <>
                                    <Accordion
                                        panels={PANELS_ROLES[role?.code]}
                                    />
                                    {index !== roles?.length - 1 ? (
                                        <Divider />
                                    ) : null}
                                </>
                            );
                        })}
                    </Flex>
                }
            />
        </Card>
    );
};

export default CardRoles;

export const Title = styled(Text)`
    color: #242424;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`;

export const Description = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #605e5c;
`;

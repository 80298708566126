import React, { useState } from "react";
import styled from "styled-components";
import {
    EditIcon,
    Flex,
    Label,
    Text,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import {
    ClipboardTask20Regular,
    MoreVertical24Filled,
} from "@fluentui/react-icons";
import { MenuStyle, WrapperIcon } from "../../../common/styles";
import CustomBadge from "../../../components/Badge/Custom";
import { PATH_TRANSLATION } from "../constants";
import { useTranslation } from "react-i18next";
import { I18N_NS_TRANSLATION } from "../../../constants";
import { DialogCancelConfirmation } from "../../../components/Dialogs/DialogCancelConfirmation";
import { DEVICE } from "../../../constants/responsive";

const CardModelTask = ({
    template,
    onOpenDialogModify,
    _mutate_delete_template_task,
    isLoadingDeleteTemplate,
}) => {
    const { id, name, description, type, task_count } = template;
    const { t } = useTranslation(I18N_NS_TRANSLATION);
    const [openCancelConfirmationDialog, setOpenCancelConfirmationDialog] =
        useState(false);

    async function onConfirm() {
        const response = await _mutate_delete_template_task(id);
    }

    return (
        <Container column gap="gap.small" key={id + "_" + name}>
            {/* dialog confirmation delete */}
            <DialogCancelConfirmation
                open={openCancelConfirmationDialog}
                cancelButton={{
                    content: "Poursuivre",
                    flat: true,
                }}
                confirmButton={{
                    content: "Oui, supprimer",
                    flat: true,
                    loading: isLoadingDeleteTemplate,
                    style: {
                        backgroundColor: "#c4314b",
                    },
                }}
                header="Suppression d'un modèle de tâches"
                content="Êtes-vous sûr(e) de vouloir annuler ? Toutes les données seront perdues"
                onClose={() => setOpenCancelConfirmationDialog(false)}
                onConfirm={onConfirm}
                width="700"
            />
            <Flex gap="gap.small" vAlign="center">
                <Flex>
                    <WrapperIcon vAlign="center" hAlign="center">
                        <ClipboardTask20Regular
                            style={{ color: "var(--color-primary)" }}
                        />
                    </WrapperIcon>
                </Flex>
                <Flex fill column>
                    <Flex vAlign="center" gap="gap.smaller">
                        <Title>{name}</Title>
                        <LabelStyle
                            circular
                            color={"yellow"}
                            content={task_count}
                        />
                    </Flex>
                    <CustomBadge content={type?.name} />
                </Flex>
                <Flex>
                    <MenuStyle
                        padding={"0px"}
                        items={[
                            {
                                key: "manage models",
                                icon: <MoreVertical24Filled />,
                                menu: [
                                    {
                                        key: "modify",
                                        content: t(
                                            PATH_TRANSLATION +
                                                ".btn_text_modify_model"
                                        ),
                                        icon: <EditIcon />,
                                        onClick: (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            return onOpenDialogModify(id);
                                        },
                                    },
                                    {
                                        key: "remove",
                                        content: (
                                            <Text
                                                styles={{
                                                    color: "var(--color-danger)",
                                                }}
                                            >
                                                {t(
                                                    PATH_TRANSLATION +
                                                        ".btn_text_delete_model"
                                                )}
                                            </Text>
                                        ),
                                        icon: (
                                            <TrashCanIcon
                                                styles={{
                                                    color: "var(--color-danger)",
                                                }}
                                            />
                                        ),
                                        onClick: (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setOpenCancelConfirmationDialog(
                                                true
                                            );
                                            // return _mutate_delete_template_task(
                                            //     id
                                            // );
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                </Flex>
            </Flex>
            <Flex
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                <Description truncated>{description}</Description>
            </Flex>
        </Container>
    );
};

export default CardModelTask;

const LabelStyle = styled(Label)`
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    color: white;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
`;

const Description = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
`;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

const Container = styled(Flex)`
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    background: #ffffff;

    @media ${DEVICE.mobileS} {
        width: 100%;
        max-width: auto;
        padding: 8px 12px;
    }
    @media ${DEVICE.mobileL} {
        width: 100%;
        max-width: 275px;
        padding: 8px 12px;
    }
    @media ${DEVICE.tablet} {
        width: 100%;
        max-width: 306px;
        padding: 12px 16px;
    }
    @media ${DEVICE.laptop} {
        width: 100%;
        max-width: 306px;
        padding: 12px 16px;
    }
`;

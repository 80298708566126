import React, { useEffect } from "react";
import styled from "styled-components";
import {
    Box,
    CalendarIcon,
    Flex,
    TaskListIcon,
    Text,
} from "@fluentui/react-northstar";
import { TaskTodoCard } from "./TaskTodoCard";
import {
    ClipboardTask20Regular,
    Check20Regular,
    PeopleTeamToolbox20Regular,
    DocumentMultiple20Regular,
} from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { DEVICE } from "../../../constants/responsive";

export const MyActivitySection = ({ activities, isRH, isManager }) => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Activité mensuelle";
    }, []);

    return (
        <FlexStyle column gap="gap.medium">
            {/* My activity section */}
            <SectionName content="Mon activité" />
            {/* test */}
            <Flex
                fill
                space="between"
                wrap
                styles={{
                    rowGap: "16px",
                    paddingInline: "8px",
                }}
            >
                {/* tache a effectué for all role  */}
                <ActivityCardNavigate
                    onClick={() => {
                        navigate("/dashboard/tasks");
                    }}
                >
                    <TaskTodoCard
                        icon={<TaskListIcon style={{ color: "#4F52B2" }} />}
                        main_text={
                            <Text
                                content={
                                    <p>
                                        Tâches <b>à effectuer</b>{" "}
                                    </p>
                                }
                            />
                        }
                        badge_text={activities?.tasks_to_complete}
                    />
                </ActivityCardNavigate>

                {/* tache à valider only for manager & RH  */}
                {(isRH || isManager) && (
                    <ActivityCardNavigate
                        onClick={() => {
                            navigate(`${ROUTES.task_manager}?tab=1`);
                        }}
                    >
                        <TaskTodoCard
                            icon={
                                <ClipboardTask20Regular
                                    outline="true"
                                    style={{ color: "#4F52B2" }}
                                />
                            }
                            main_text={
                                <Text
                                    content={
                                        <p>
                                            Tâches <b>à valider</b>
                                        </p>
                                    }
                                />
                            }
                            badge_text={activities?.tasks_to_validate}
                        />
                    </ActivityCardNavigate>
                )}

                {/* absence a valider only for manager */}
                {isManager === true && (
                    <ActivityCardNavigate
                        onClick={() => {
                            navigate(`${ROUTES.leave}?tab=1&sub_tab=2`);
                        }}
                    >
                        <TaskTodoCard
                            icon={
                                <CalendarIcon
                                    outline="true"
                                    style={{ color: "#4F52B2" }}
                                />
                            }
                            main_text={
                                <Text
                                    content={
                                        <p>
                                            Absences <b>à valider</b>{" "}
                                        </p>
                                    }
                                />
                            }
                            badge_text={activities?.leaves_to_validate}
                        />
                    </ActivityCardNavigate>
                )}

                {/* nouveaux documents  for all role */}
                <ActivityCardNavigate
                    onClick={() => {
                        navigate("/hr/documents");
                    }}
                >
                    <TaskTodoCard
                        icon={
                            <DocumentMultiple20Regular
                                style={{ color: "#4F52B2" }}
                            />
                        }
                        main_text={
                            <Text
                                content={
                                    <p>
                                        <b>Nouveaux</b> documents
                                    </p>
                                }
                            />
                        }
                        badge_text={activities?.new_documents}
                    />
                </ActivityCardNavigate>

                {/* note de frais a valider only for manager  */}
                {isManager === true && (
                    <ActivityCardNavigate
                        onClick={() => {
                            navigate(
                                ROUTES.expenses.home +
                                    "/" +
                                    ROUTES.expenses.manager.home.route,
                                {
                                    state: {
                                        tab: 1,
                                    },
                                }
                            );
                        }}
                    >
                        <TaskTodoCard
                            icon={
                                <Check20Regular style={{ color: "#4F52B2" }} />
                            }
                            main_text={
                                <Text
                                    content={
                                        <p>
                                            Notes de frais <b>à valider</b>
                                        </p>
                                    }
                                />
                            }
                            badge_text={activities?.expenses_to_validate}
                        />
                    </ActivityCardNavigate>
                )}

                {/* feuille de temps à valider only for manager  */}
                {isManager === true && (
                    <ActivityCardNavigate
                        onClick={() =>
                            navigate(`${ROUTES.time_reporting.team_tracking}`)
                        }
                    >
                        <TaskTodoCard
                            icon={
                                <PeopleTeamToolbox20Regular
                                    style={{ color: "#4F52B2" }}
                                />
                            }
                            main_text={
                                <Text
                                    content={
                                        <p>
                                            Feuilles de temps <b>à valider</b>
                                        </p>
                                    }
                                />
                            }
                            badge_text={activities?.time_reporting_to_validate}
                        />
                    </ActivityCardNavigate>
                )}
            </Flex>
        </FlexStyle>
    );
};
const SectionName = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    padding: 0px 0px 0px 12px;
    color: #242424;
`;

const ActivityCardNavigate = styled(Flex)`
    cursor: pointer;
    @media ${DEVICE.mobileS} {
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: auto;
    }
`;
const FlexStyle = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 100%;
        padding-inline: 8px;
        margin-top: 24px;
    }
    @media ${DEVICE.laptop} {
        width: 852px;
        padding-inline: 0px;
        margin-top: 0px;
    }
`;

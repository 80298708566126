import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
    width: 175px;
    height: 38px;
    padding: 0px;
    gap: 32px;
    position: relative;
`;
export const ListDropdown = styled(Flex)`
    width: 175px;
    /* flex-shrink: 0; */
    border-radius: 8px;
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);

    /* shadow-16 */
    box-shadow: 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13);
    position: absolute;
    top: 38px;
    z-index: 1;
`;
export const Item = styled(Flex)`
    width: 175px;
    height: 38px;
    padding: 0px 8px 0px 5px;
    :hover {
        background-color: #e8ebfa;
        cursor: pointer;
    }
`;
export const TextType = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-1424242,
        #424242
    );

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;

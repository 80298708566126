import { Flex } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
    border-bottom: 1px solid
        var(--light-theme-rest-border-brand-border-1, #9299f7);
    background: var(
        --light-theme-rest-background-default-background-2,
        #f5f5f5
    );
    height: 48px;
`;

import { Button, Flex, Text } from "@fluentui/react-northstar";
import styled, { css } from "styled-components";
import { DEVICE } from "../../../../constants/responsive";

export const ContainerTimer = styled(Flex)`
    gap: 8px;

    border-radius: var(--spacing-xl-32, 32px);

    border: 2px solid
        var(--Light-theme-Rest-Background-Brand-Background-1, #e8ebfa);
    background: var(--Light-theme-Rest-Background-Default-Background, #fff);

    ${({ $isStopped }) =>
        $isStopped
            ? css`
                  border: 2px solid
                      var(
                          --Light-theme-Rest-Background-Brand-Background-1,
                          #e8ebfa
                      );
                  background: var(
                      --Light-theme-Rest-Background-Brand-Background-1,
                      #e8ebfa
                  );
              `
            : css``};
    ${({ $isPaused }) =>
        $isPaused
            ? css`
                  border: 2px solid
                      var(
                          --Light-theme-Rest-Background-Brand-Background-1,
                          #e8ebfa
                      );
                  background: var(
                      --Light-theme-Rest-Background-Brand-Background-1,
                      #e8ebfa
                  );
              `
            : css``};

    @media ${DEVICE.mobileS} {
        height: 52px;
        padding: 12px 14px;
    }
    @media ${DEVICE.laptop} {
        height: 70px;
        padding: 15px 19px;
    }
`;

export const TimerNumbers = styled(Text)`
    color: var(--Light-theme-Rest-Background-Brand-Background, #5b5fc7);
    ${({ $isStopped }) =>
        $isStopped
            ? css`
                  color: var(
                      --Light-theme-Rest-Background-Brand-Background,
                      #9299f7
                  );
              `
            : css``}
    ${({ $isPaused }) =>
        $isPaused
            ? css`
                  color: var(
                      --Light-theme-Rest-Background-Brand-Background,
                      #9299f7
                  );
              `
            : css``}

    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 100% */

    @media ${DEVICE.mobileS} {
        font-size: 16px;
    }
    @media ${DEVICE.laptop} {
        font-size: 24px;
    }
`;

export const ButtonClose = styled(Button)`
    ${({ $isStopped }) =>
        $isStopped
            ? css`
                  color: #9299f7;
              `
            : css``}
    ${({ $isPaused }) =>
        $isPaused
            ? css`
                  color: #9299f7;
              `
            : css``};

    @media ${DEVICE.mobileS} {
        svg {
            width: 15px;
        }
    }
    @media ${DEVICE.laptop} {
        color: var(--color-primary);
        svg {
            width: auto;
        }
    }
`;

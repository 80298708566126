import { Flex } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import Illustration from "../../../components/shared/Illustration";
import { I18N_NS_TIME_REPORTING } from "../../time_reporting/constants";
import { Container, ErrorDescription, ErrorTitle, Title } from "../style";

const ValidateSuccess = () => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    return (
        <Container
            column
            gap="gap.large"
            styles={{ height: "447px!important" }}
        >
            <Flex>
                <Title content={t("extern_manager_validation.title")} />
            </Flex>
            <Flex column hAlign="center" gap="gap.medium">
                <Illustration filename={"Thumb-up.svg"} width="200px" />
                <ErrorTitle
                    content={t("extern_manager_validation.response.sent")}
                />
                <ErrorDescription
                    content={t(
                        "extern_manager_validation.response.description"
                    )}
                />
            </Flex>
        </Container>
    );
};

export default ValidateSuccess;

import React, { useState, ReactNode } from "react";
import { Button, CloseIcon, Dialog, Flex } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { I18N_NS_LEAVE } from "../../../../../features/leave/constants";
import Content from "./Content";
import { useAuthDetailStore } from "../../../../../stores/useAuthStore";
import { useMutation } from "react-query";
import {
    _api_approve_leave_request,
    _api_delete_leave_request,
    _api_refuser_leave_request,
} from "../../../../../features/leave/api";
import { DialogCancelConfirmation } from "../../../../Dialogs/DialogCancelConfirmation";
import { useEffect } from "react";
import styled from "styled-components";
import { DEVICE } from "../../../../../constants/responsive";

/**
 * leave_details
 * @typedef {Object} LeaveDetail
 * @property {number} id
 * @property {boolean} authenticated_user_can_delete
 * @property {boolean} authenticated_user_can_validate
 * @property {boolean} request_can_be_canceled
 * @property {{email: string}} requested_by
 * @property {{id: number}} status
 * @property {{status: string, date: string}} last_action
 * @property {string} requested_on - date not formated with moment
 * @property {Array<{email: string}>} waiting_approval_by
 * @property {Array<{email: string}>} approved_by
 * @property {string} start_date - date not formated with moment
 * @property {string} end_date - date not formated with moment
 * @property {string|number} balance_count_to_remove
 * @property {string} comment
 * @property {string|number} balance_after_leave
 * @property {string|number} balance_before_leave
 * @property {Array<{ name: string, filesize : number}>} documents - liste des documents
 * @property {{name: string, color: string, has_balance: boolean}} type
 */

/**
 * user_details
 * @typedef {Object} UserDetail
 * @property {number} id
 * @property {string} avatar
 * @property {string} name
 */

/**
 * @typedef {Object} PropsType
 * @property {boolean} [open] - if true open the dialog by default
 * @property {ReactNode} [content]
 * @property {LeaveDetail} leave_details
 * @property {UserDetail} user_details
 * @property {((data: Object) => void)} [cbOnApproveLeave] - Callback execute just after approve leave
 * @property {((data: Object) => void)} [cbOnRefuseLeave] - Callback execute just after refuse leave
 * @property {((data: Object) => void)} [cbOnCancelLeave] - Callback execute just after cancel leave
 * @property {((data: Object) => void)} [cbOnDeleteLeave] - Callback execute just after delete leave
 * @property {(() => void)} [cbOnHideDialog] - Callback execute just after hide Dialog
 *
 */

/**
 * DialogLeaveDetail permet de visualiser le detail d'une absence.
 * @param {PropsType} props
 * @returns {ReactNode}
 */
const DialogLeaveDetail = ({
    open = false,
    content,
    leave_details,
    user_details,
    cbOnRefuseLeave = () => null,
    cbOnApproveLeave = () => null,
    cbOnDeleteLeave = () => null,
    cbOnCancelLeave = () => null,
    cbOnHideDialog = () => null,
}) => {
    const { t } = useTranslation(I18N_NS_LEAVE);
    const { detail } = useAuthDetailStore();

    const [openDialog, setOpenDialog] = useState(false);

    const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
    const [
        openDialogConfirmationCancelLeave,
        setOpenDialogConfirmationCancelLeave,
    ] = useState(false);

    const authenticated_user_can_delete =
        leave_details?.authenticated_user_can_delete;

    const authenticated_user_can_validate =
        leave_details?.authenticated_user_can_validate;

    const request_can_be_canceled = leave_details?.request_can_be_canceled;
    const is_my_leave = leave_details?.requested_by?.email === detail.email;

    const status_id = leave_details?.status?.id;
    useEffect(() => {
        setOpenDialog(open);
    }, [open]);

    const showDialog = () => setOpenDialog(true);
    const hideDialog = () => {
        setOpenDialog(false);
        cbOnHideDialog();
    };

    const showConfirmationCancelDialog = () => {
        setOpenDialogConfirmationCancelLeave(true);
    };
    const hideConfirmationCancelDialog = () => {
        setOpenDialogConfirmationCancelLeave(false);
    };

    // MUTATIONS
    // Refuse leave request
    const {
        mutate: mutate_refuse_leave_request,
        isLoading: is_loading_refuse_leave_request,
    } = useMutation(
        ({ leave_request_id, leave_user_id }) =>
            _api_refuser_leave_request({
                leave_request_id,
                leave_user_id,
            }),
        {
            onSuccess: (data) => {
                cbOnRefuseLeave(data);
                // refetch();
                console.log("rfetcg");
                hideDialog();
                return;
            },
        }
    );
    // approuve leave request
    const {
        mutate: mutate_approve_leave_request,
        isLoading: is_loading_approve_leave_request,
    } = useMutation(
        ({ leave_request_id, leave_user_id, approval_type }) =>
            _api_approve_leave_request({
                leave_request_id,
                leave_user_id,
                approval_type,
            }),
        {
            onSuccess: (data) => {
                hideDialog();
                return cbOnApproveLeave(data);
            },
        }
    );
    // delete leave request
    const {
        mutate: mutate_delete_leave_request,
        isLoading: is_loading_delete_leave_request,
    } = useMutation(
        ({ leave_request_id, leave_user_id }) =>
            _api_delete_leave_request({
                leave_request_id,
                leave_user_id,
            }),
        {
            onSuccess: (data) => {
                hideDialog();
                cbOnDeleteLeave(data);
                console.log("supprimer");
                return;
            },
        }
    );
    // delete leave request
    const {
        mutate: mutate_cancel_my_leave_request,
        isLoading: is_loading_cancel_my_leave_request,
    } = useMutation(
        ({ leave_request_id, leave_user_id }) =>
            _api_delete_leave_request({
                leave_request_id,
                leave_user_id,
            }),
        {
            onSuccess: (data) => {
                hideDialog();
                return cbOnCancelLeave(data);
            },
        }
    );

    // console.log({ leaves: leave_details });

    // config all cases confirm button
    const confirmButtons = [
        {
            content: "Accepter la demande",
            flat: true,
            disabled: status_id === 2,
            loading: is_loading_approve_leave_request,
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                mutate_approve_leave_request({
                    leave_request_id: leave_details?.id,
                    leave_user_id: user_details?.id,
                    approval_type: leave_details?.approval_type,
                });
            },
        },
    ];
    // config all cases cancel buttons
    const refuseButton = [
        {
            content: "Refuser la demande",
            flat: true,
            disabled: status_id === 2,
            loading: is_loading_refuse_leave_request,
            onClick: (e, i) => {
                e.stopPropagation();
                e.preventDefault();

                mutate_refuse_leave_request({
                    leave_request_id: leave_details?.id,
                    leave_user_id: user_details?.id,
                });

                return;
            },
        },
    ];

    const cancelButton = [
        {
            content: "Annuler la demande",
            flat: true,
            disabled: status_id === 2,
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                showConfirmationCancelDialog();
            },
        },
    ];

    // console.log({ leave_details: leave_details?.approval_type });

    return (
        <>
            <DialogStyle
                open={openDialog}
                header={t("timeline.dialogs.leave_request.title")}
                content={
                    <Content
                        leave_detail={leave_details}
                        user_details={user_details}
                        hideDialog={hideDialog}
                    />
                }
                trigger={
                    content ? (
                        <Flex onClick={() => showDialog()} vAlign="center">
                            {content}
                        </Flex>
                    ) : null
                }
                headerAction={{
                    icon: <CloseIcon />,
                    title: "Close",
                    onClick: hideDialog,
                }}
                confirmButton={
                    authenticated_user_can_validate && confirmButtons.at(0)
                }
                footer={{
                    children: (Component, props) => {
                        const { styles, ...rest } = props;
                        const buttonRefuse =
                            authenticated_user_can_validate &&
                            !is_my_leave &&
                            refuseButton.at(0);

                        const buttonCanceled =
                            request_can_be_canceled && is_my_leave
                                ? cancelButton.at(0)
                                : null;

                        return (
                            <Footer
                                vAlign="center"
                                styles={styles}
                                space="between"
                                column
                            >
                                {/* supprimer la demande d'absence */}
                                {authenticated_user_can_delete ? (
                                    <DialogCancelConfirmation
                                        open={openDialogConfirmation}
                                        cancelButton="Annuler"
                                        confirmButton={{
                                            content: "Supprimer",
                                            flat: true,
                                            styles: {
                                                backgroundColor:
                                                    "var(--color-danger)",
                                            },
                                        }}
                                        content={
                                            "La demande d’absence sera supprimée et les données seront définitivement perdues."
                                        }
                                        trigger={
                                            <ButtonDeleteLeave
                                                text
                                                content="Supprimer l’absence"
                                                styles={{
                                                    textDecoration: "underline",
                                                    color: "var(--color-danger)",
                                                }}
                                                loading={
                                                    is_loading_delete_leave_request
                                                }
                                                onClick={() =>
                                                    setOpenDialogConfirmation(
                                                        true
                                                    )
                                                }
                                            />
                                        }
                                        header="Êtes-vous sûr.e de vouloir supprimer la demande d’absence ?"
                                        onClose={() =>
                                            setOpenDialogConfirmation(false)
                                        }
                                        onConfirm={() => {
                                            setOpenDialogConfirmation(false);
                                            mutate_delete_leave_request({
                                                leave_request_id:
                                                    leave_details?.id,
                                                leave_user_id: user_details?.id,
                                            });
                                            return;
                                        }}
                                    />
                                ) : (
                                    <Flex fill></Flex>
                                )}
                                <Flex
                                    className="footer_button"
                                    hAlign="center"
                                    gap="gap.small"
                                >
                                    {buttonRefuse?.content && (
                                        <Flex.Item push>
                                            <Button {...buttonRefuse} />
                                        </Flex.Item>
                                    )}
                                    {buttonCanceled?.content && (
                                        <Flex.Item push>
                                            <DialogCancelConfirmation
                                                open={
                                                    openDialogConfirmationCancelLeave
                                                }
                                                cancelButton={{
                                                    content:
                                                        "Retour au détail de la demande",
                                                    flat: true,
                                                    onClick: (e) => {
                                                        e.preventDefault();
                                                        return hideConfirmationCancelDialog();
                                                    },
                                                }}
                                                confirmButton={{
                                                    content:
                                                        "Annuler la demande",
                                                    loading:
                                                        is_loading_cancel_my_leave_request,
                                                    flat: true,
                                                    styles: {
                                                        backgroundColor:
                                                            "var(--color-danger)",
                                                    },
                                                }}
                                                content={
                                                    "Êtes-vous sûr.e de vouloir annuler ? La demande sera supprimée"
                                                }
                                                trigger={
                                                    <Button
                                                        {...buttonCanceled}
                                                    />
                                                }
                                                header="Annuler la demande"
                                                onClose={() =>
                                                    hideConfirmationCancelDialog()
                                                }
                                                onConfirm={() => {
                                                    mutate_cancel_my_leave_request(
                                                        {
                                                            leave_request_id:
                                                                leave_details?.id,
                                                            leave_user_id:
                                                                user_details?.id,
                                                        },
                                                        {
                                                            onSuccess: () => {
                                                                return hideConfirmationCancelDialog();
                                                            },
                                                        }
                                                    );
                                                }}
                                            />
                                        </Flex.Item>
                                    )}
                                    <Flex.Item push>
                                        <Component {...rest} />
                                    </Flex.Item>
                                </Flex>
                            </Footer>
                        );
                    },
                }}
                onCancel={hideDialog}
            />
        </>
    );
};

export default DialogLeaveDetail;

const ButtonDeleteLeave = styled(Button)`
    @media ${DEVICE.mobileS} {
        max-width: 100%;
    }
    @media ${DEVICE.laptop} {
        max-width: auto;
    }
`;

const Footer = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column-reverse;
        .footer_button {
            flex-direction: column-reverse;
            gap: 8px;
            button,
            > div {
                margin: 0px;
                max-width: 100%;
            }
        }
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        .footer_button {
            flex-direction: row;
            button,
            > div {
                margin: auto;
            }
        }
    }
`;

const DialogStyle = styled(Dialog)`
    @media ${DEVICE.mobileS} {
        width: 90%;
    }
    @media ${DEVICE.laptop} {
        width: 764px;
    }
`;

import React from "react";
import { ButtonClose, ContainerTimer, TimerNumbers } from "./Timer.style";
import { CloseIcon } from "@fluentui/react-northstar";

const Timer = ({ onClick, timer, $isStopped, $isPaused }) => {
    return (
        <ContainerTimer
            vAlign="center"
            gap="gap.small"
            $isStopped={$isStopped}
            $isPaused={$isPaused}
        >
            <TimerNumbers
                content={timer.toString()}
                $isStopped={$isStopped}
                $isPaused={$isPaused}
            />
            <ButtonClose
                icon={<CloseIcon size={"large"} />}
                iconOnly
                text
                primary
                onClick={onClick}
                $isStopped={$isStopped}
                $isPaused={$isPaused}
            />
        </ContainerTimer>
    );
};

export default Timer;

import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Header from "../../Dashboard/Header/Header";
import SideBar from "../../Dashboard/SideBar/SideBar";
import HeaderProfile from "./HeaderProfile";
import TabProfile from "./TabProfile";

function Profile(props) {
    return (
        <Stack style={{ height: "100vh" }}>
            <Header />
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    // border: "5px solid blue",
                }}
            >
                <SideBar />
                <Box
                    display="flex"
                    flexDirection="column"
                    style={{
                        width: "100vw",
                        overflowX: "hidden",
                        margin: "30px",
                    }}
                >
                    <HeaderProfile />
                    <TabProfile />
                </Box>
            </Box>
        </Stack>
    );
}

export default Profile;
